import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import BinTrf01Actions, { BinTrf01Types } from '../Stores/BinTrf01/Actions';

const getAppStore = state => state.app;

const getBinTrf01Store = state => state.binTrf01;

export function* binTrf01FetchBinTrf01({ currentPage, sorts, criteria, pageSize }) {
  try {
    yield put(BinTrf01Actions.binTrf01FetchBinTrf01Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    if ('company_ids_select2' in criteria) {
      const values = criteria.company_ids_select2;
      if (values !== null && values.length > 0) {
        const filterVal = values.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data.value;
          }
          return `${lastObj},${data.value}`;
        }, '');

        processedFilters.push(`company_ids:${filterVal}`);
      }
    }

    if ('item_ids_select2' in criteria) {
      const values = criteria.item_ids_select2;
      if (values !== null && values.length > 0) {
        const filterVal = values.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data.value;
          }
          return `${lastObj},${data.value}`;
        }, '');

        processedFilters.push(`item_ids:${filterVal}`);
      }
    }

    if ('item_group_01_ids_select2' in criteria) {
      const values = criteria.item_group_01_ids_select2;
      if (values !== null && values.length > 0) {
        const filterVal = values.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data.value;
          }
          return `${lastObj},${data.value}`;
        }, '');

        processedFilters.push(`item_group_01_ids:${filterVal}`);
      }
    }

    if ('item_group_02_ids_select2' in criteria) {
      const values = criteria.item_group_02_ids_select2;
      if (values !== null && values.length > 0) {
        const filterVal = values.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data.value;
          }
          return `${lastObj},${data.value}`;
        }, '');

        processedFilters.push(`item_group_02_ids:${filterVal}`);
      }
    }

    if ('item_group_03_ids_select2' in criteria) {
      const values = criteria.item_group_03_ids_select2;
      if (values !== null && values.length > 0) {
        const filterVal = values.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data.value;
          }
          return `${lastObj},${data.value}`;
        }, '');

        processedFilters.push(`item_group_03_ids:${filterVal}`);
      }
    }

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `binTrf/indexProcess/BIN_TRF_01/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        BinTrf01Actions.binTrf01FetchBinTrf01Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrf01Actions.binTrf01FetchBinTrf01Loading(false));
  }
}

export function* binTrf01AnalyseBinTrf01({ criteria }) {
  try {
    yield put(BinTrf01Actions.binTrf01FetchBinTrf01Loading(true));
    yield put(BinTrf01Actions.binTrf01BatchJobLoading(1));

    const processedFilters = [];
    if ('company_ids_select2' in criteria) {
      const values = criteria.company_ids_select2;
      if (values !== null && values.length > 0) {
        const filterVal = values.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data.value;
          }
          return `${lastObj},${data.value}`;
        }, '');

        processedFilters.push(`company_ids:${filterVal}`);
      }
    }

    if ('item_ids_select2' in criteria) {
      const values = criteria.item_ids_select2;
      if (values !== null && values.length > 0) {
        const filterVal = values.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data.value;
          }
          return `${lastObj},${data.value}`;
        }, '');

        processedFilters.push(`item_ids:${filterVal}`);
      }
    }

    if ('item_group_01_ids_select2' in criteria) {
      const values = criteria.item_group_01_ids_select2;
      if (values !== null && values.length > 0) {
        const filterVal = values.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data.value;
          }
          return `${lastObj},${data.value}`;
        }, '');

        processedFilters.push(`item_group_01_ids:${filterVal}`);
      }
    }

    if ('item_group_02_ids_select2' in criteria) {
      const values = criteria.item_group_02_ids_select2;
      if (values !== null && values.length > 0) {
        const filterVal = values.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data.value;
          }
          return `${lastObj},${data.value}`;
        }, '');

        processedFilters.push(`item_group_02_ids:${filterVal}`);
      }
    }

    if ('item_group_03_ids_select2' in criteria) {
      const values = criteria.item_group_03_ids_select2;
      if (values !== null && values.length > 0) {
        const filterVal = values.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data.value;
          }
          return `${lastObj},${data.value}`;
        }, '');

        processedFilters.push(`item_group_03_ids:${filterVal}`);
      }
    }

    const app = yield select(getAppStore);
    const postData = {
      filters: processedFilters
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `binTrf/analyseProcess/BIN_TRF_01/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(BinTrf01Actions.binTrf01ResetTimestamp());
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrf01Actions.binTrf01FetchBinTrf01Loading(false));
    yield put(BinTrf01Actions.binTrf01BatchJobLoading(0));
  }
}

export function* binTrf01DownloadBinTrf01({ criteria }) {
  try {
    yield put(BinTrf01Actions.binTrf01FetchBinTrf01Loading(true));
    yield put(BinTrf01Actions.binTrf01BatchJobLoading(2));

    const app = yield select(getAppStore);

    const processedFilters = [];
    if ('company_ids_select2' in criteria) {
      const values = criteria.company_ids_select2;
      if (values !== null && values.length > 0) {
        const filterVal = values.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data.value;
          }
          return `${lastObj},${data.value}`;
        }, '');

        processedFilters.push(`company_ids:${filterVal}`);
      }
    }

    if ('item_ids_select2' in criteria) {
      const values = criteria.item_ids_select2;
      if (values !== null && values.length > 0) {
        const filterVal = values.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data.value;
          }
          return `${lastObj},${data.value}`;
        }, '');

        processedFilters.push(`item_ids:${filterVal}`);
      }
    }

    if ('item_group_01_ids_select2' in criteria) {
      const values = criteria.item_group_01_ids_select2;
      if (values !== null && values.length > 0) {
        const filterVal = values.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data.value;
          }
          return `${lastObj},${data.value}`;
        }, '');

        processedFilters.push(`item_group_01_ids:${filterVal}`);
      }
    }

    if ('item_group_02_ids_select2' in criteria) {
      const values = criteria.item_group_02_ids_select2;
      if (values !== null && values.length > 0) {
        const filterVal = values.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data.value;
          }
          return `${lastObj},${data.value}`;
        }, '');

        processedFilters.push(`item_group_02_ids:${filterVal}`);
      }
    }

    if ('item_group_03_ids_select2' in criteria) {
      const values = criteria.item_group_03_ids_select2;
      if (values !== null && values.length > 0) {
        const filterVal = values.reduce((lastObj, data) => {
          if (lastObj.length === 0) {
            return data.value;
          }
          return `${lastObj},${data.value}`;
        }, '');

        processedFilters.push(`item_group_03_ids:${filterVal}`);
      }
    }

    const getData = {
      filters: processedFilters
    };

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `binTrf/downloadProcess/BIN_TRF_EXCEL_01/${app.curSiteFlowId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, `BIN_TRF_EXCEL_01.XLSX`);
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrf01Actions.binTrf01FetchBinTrf01Loading(false));
    yield put(BinTrf01Actions.binTrf01BatchJobLoading(0));
  }
}

export function* binTrf01GoToResource({ resId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/binTrfDetail/update/${resId}`));
}

export function* binTrf01GoToAudit({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/auditResource/ITEM/${hdrId}/Item`));
}

export function* binTrf01FetchCompanyOptions({ search }) {
  try {
    yield put(BinTrf01Actions.binTrf01FetchCompanyOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `company/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} - ${d.name_01}`
      }));

      yield put(BinTrf01Actions.binTrf01FetchCompanyOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrf01Actions.binTrf01FetchCompanyOptionLoading(false));
  }
}

export function* binTrf01FetchItemOptions({ search }) {
  try {
    yield put(BinTrf01Actions.binTrf01FetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(BinTrf01Actions.binTrf01FetchItemOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrf01Actions.binTrf01FetchItemOptionLoading(false));
  }
}

export function* binTrf01FetchItemGroup01Options({ search }) {
  try {
    yield put(BinTrf01Actions.binTrf01FetchItemGroup01OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(BinTrf01Actions.binTrf01FetchItemGroup01OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrf01Actions.binTrf01FetchItemGroup01OptionLoading(false));
  }
}

export function* binTrf01FetchItemGroup02Options({ search }) {
  try {
    yield put(BinTrf01Actions.binTrf01FetchItemGroup02OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup02/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(BinTrf01Actions.binTrf01FetchItemGroup02OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrf01Actions.binTrf01FetchItemGroup02OptionLoading(false));
  }
}

export function* binTrf01FetchItemGroup03Options({ search }) {
  try {
    yield put(BinTrf01Actions.binTrf01FetchItemGroup03OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup03/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(BinTrf01Actions.binTrf01FetchItemGroup03OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrf01Actions.binTrf01FetchItemGroup03OptionLoading(false));
  }
}

export function* binTrf01ShowBatchJobStatus() {
  try {
    const app = yield select(getAppStore);
    const binTrf01 = yield select(getBinTrf01Store);
    const getData = {};

    let strProcType = 'BIN_TRF_01';
    if (binTrf01.batchJobIsLoading === 2) {
      strProcType = 'BIN_TRF_EXCEL_01';
    }

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `batchJobStatus/showBatchJobStatus/${strProcType}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(BinTrf01Actions.binTrf01ShowBatchJobStatusSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  }
}

export const saga = [
  takeLatest(BinTrf01Types.BIN_TRF01_FETCH_BIN_TRF01, binTrf01FetchBinTrf01),
  takeLatest(BinTrf01Types.BIN_TRF01_ANALYSE_BIN_TRF01, binTrf01AnalyseBinTrf01),
  takeLatest(BinTrf01Types.BIN_TRF01_GO_TO_AUDIT, binTrf01GoToAudit),
  takeLatest(BinTrf01Types.BIN_TRF01_GO_TO_RESOURCE, binTrf01GoToResource),
  takeLatest(BinTrf01Types.BIN_TRF01_FETCH_COMPANY_OPTIONS, binTrf01FetchCompanyOptions),
  takeLatest(BinTrf01Types.BIN_TRF01_FETCH_ITEM_OPTIONS, binTrf01FetchItemOptions),
  takeLatest(BinTrf01Types.BIN_TRF01_FETCH_ITEM_GROUP01_OPTIONS, binTrf01FetchItemGroup01Options),
  takeLatest(BinTrf01Types.BIN_TRF01_FETCH_ITEM_GROUP02_OPTIONS, binTrf01FetchItemGroup02Options),
  takeLatest(BinTrf01Types.BIN_TRF01_FETCH_ITEM_GROUP03_OPTIONS, binTrf01FetchItemGroup03Options),
  takeLatest(BinTrf01Types.BIN_TRF01_DOWNLOAD_BIN_TRF01, binTrf01DownloadBinTrf01),

  takeLatest(BinTrf01Types.BIN_TRF01_SHOW_BATCH_JOB_STATUS, binTrf01ShowBatchJobStatus)
];
