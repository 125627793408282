import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import WhseJob16DetailActions, { WhseJob16DetailTypes } from '../Stores/WhseJob16Detail/Actions';

const getAppStore = state => state.app;

const getWhseJob16DetailStore = state => state.whseJob16Detail;

export function* whseJob16DetailInitHeader() {
  try {
    yield put(WhseJob16DetailActions.whseJob16DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/initHeader/CYCLE_COUNT/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(WhseJob16DetailActions.whseJob16DetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob16DetailActions.whseJob16DetailShowDocumentLoading(false));
  }
}

export function* whseJob16DetailShowHeader({ hdrId }) {
  try {
    yield put(WhseJob16DetailActions.whseJob16DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/showHeader/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(WhseJob16DetailActions.whseJob16DetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    // yield put(WhseJob16DetailActions.whseJob16DetailShowDocumentLoading(false));
  }
}

export function* whseJob16DetailTransitionToStatus({ formikBag, hdrId, docStatus }) {
  formikBag.setSubmitting(true);
  yield put(WhseJob16DetailActions.whseJob16DetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      hdrId,
      docStatus
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'whseJob/transitionToStatus',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const whseJob16Detail = yield select(getWhseJob16DetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = whseJob16Detail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        WhseJob16DetailActions.whseJob16DetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(WhseJob16DetailActions.whseJob16DetailShowDocumentLoading(false));
  }
}

export function* whseJob16DetailUpdateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'whseJob/updateHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const whseJob16Detail = yield select(getWhseJob16DetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = whseJob16Detail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        WhseJob16DetailActions.whseJob16DetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* whseJob16DetailShowDetails({ hdrId }) {
  try {
    yield put(WhseJob16DetailActions.whseJob16DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/showDetails/CYCLE_COUNT/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(WhseJob16DetailActions.whseJob16DetailShowDetailsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob16DetailActions.whseJob16DetailShowDocumentLoading(false));
  }
}

export function* whseJob16DetailUpdateDetails({ formikBag, hdrId, documentDetails }) {
  formikBag.setSubmitting(true);
  yield put(WhseJob16DetailActions.whseJob16DetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetails
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `whseJob/updateDetails/CYCLE_COUNT/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const whseJob16Detail = yield select(getWhseJob16DetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = whseJob16Detail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        WhseJob16DetailActions.whseJob16DetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(WhseJob16DetailActions.whseJob16DetailShowDocumentLoading(false));
  }
}

export function* whseJob16DetailFetchCompanyOptions({ search }) {
  try {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchCompanyOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `company/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(WhseJob16DetailActions.whseJob16DetailFetchCompanyOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchCompanyOptionLoading(false));
  }
}

export function* whseJob16DetailFetchItemOptions({ search }) {
  try {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(WhseJob16DetailActions.whseJob16DetailFetchItemOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchItemOptionLoading(false));
  }
}

export function* whseJob16DetailChangeItem({ formikBag, hdrId, itemId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseJob/changeItem`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });

      // reset the select2 cache
      formikBag.setFieldValue('uom_select2', {
        value: 0,
        label: ''
      });
      formikBag.setFieldValue('uom_rate', 1);

      yield put(WhseJob16DetailActions.whseJob16DetailFetchUomOptionSuccess([]));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* whseJob16DetailFetchItemBatchOptions({ itemId, search }) {
  try {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchItemBatchOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemBatch/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.batch_serial_no} | ${d.expiry_date} | ${d.receipt_date}`
      }));

      yield put(WhseJob16DetailActions.whseJob16DetailFetchItemBatchOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchItemBatchOptionLoading(false));
  }
}

export function* whseJob16DetailChangeItemBatch({ formikBag, hdrId, itemBatchId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemBatchId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseJob/changeItemBatch`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* whseJob16DetailFetchUomOptions({ itemId, search }) {
  try {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchUomOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemUom/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.uom_id, label: `${d.uom_code}` }));

      yield put(WhseJob16DetailActions.whseJob16DetailFetchUomOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchUomOptionLoading(false));
  }
}

export function* whseJob16DetailChangeUom({ formikBag, hdrId, itemId, uomId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId,
      uomId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseJob/changeItemUom`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* whseJob16DetailFetchStorageBinOptions({ search }) {
  try {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchStorageBinOptionLoading(true));

    const whseJob16Detail = yield select(getWhseJob16DetailStore);

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: [
        {
          field: 'whse_job_type',
          value: whseJob16Detail.whseJobType
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(WhseJob16DetailActions.whseJob16DetailFetchStorageBinOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchStorageBinOptionLoading(false));
  }
}

export function* whseJob16DetailFetchHandlingUnitOptions({ search }) {
  try {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchHandlingUnitOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'site_flow_id',
          value: app.curSiteFlowId
        },
        {
          field: 'status',
          value: 4 // NEW
        },
        {
          field: 'handling_type',
          value: 1 // PALLET
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `handlingUnit/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.barcode}` }));

      yield put(WhseJob16DetailActions.whseJob16DetailFetchHandlingUnitOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchHandlingUnitOptionLoading(false));
  }
}

export function* whseJob16DetailCreateDetail({ formikBag, hdrId, documentDetail }) {
  formikBag.setSubmitting(true);
  yield put(WhseJob16DetailActions.whseJob16DetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetail
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseJob/createDetail/CYCLE_COUNT/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const whseJob16Detail = yield select(getWhseJob16DetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = whseJob16Detail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        WhseJob16DetailActions.whseJob16DetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );

      yield put(
        WhseJob16DetailActions.whseJob16DetailSetDocumentDetail(whseJob16Detail.initDocumentDetail)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(WhseJob16DetailActions.whseJob16DetailShowDocumentLoading(false));
  }
}

export function* whseJob16DetailDeleteDetail({ hdrId, documentDetail }) {
  yield put(WhseJob16DetailActions.whseJob16DetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: [{ id: documentDetail.id }]
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `whseJob/deleteDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const whseJob16Detail = yield select(getWhseJob16DetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = whseJob16Detail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        WhseJob16DetailActions.whseJob16DetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob16DetailActions.whseJob16DetailShowDocumentLoading(false));
  }
}

export function* whseJob16DetailCreateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'whseJob/createHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/whseJob16Detail/update/${result.data}`));
      yield put(WhseJob16DetailActions.whseJob16DetailSetHdrId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* whseJob16DetailFetchStorageRowOptions({ search }) {
  try {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchStorageRowOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageRow/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code} ${d.desc_01}` }));

      yield put(WhseJob16DetailActions.whseJob16DetailFetchStorageRowOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchStorageRowOptionLoading(false));
  }
}

export function* whseJob16DetailFetchStorageBayOptions({ search }) {
  try {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchStorageBayOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBay/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code} ${d.desc_01}` }));

      yield put(WhseJob16DetailActions.whseJob16DetailFetchStorageBayOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchStorageBayOptionLoading(false));
  }
}

export function* whseJob16DetailFetchWorkerOptions({ search }) {
  try {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchWorkerOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `user/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.username}` }));

      yield put(WhseJob16DetailActions.whseJob16DetailFetchWorkerOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob16DetailActions.whseJob16DetailFetchWorkerOptionLoading(false));
  }
}

export const saga = [
  takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_INIT_HEADER, whseJob16DetailInitHeader),
  takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_SHOW_HEADER, whseJob16DetailShowHeader),

  takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_UPDATE_HEADER, whseJob16DetailUpdateHeader),
  takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_CREATE_HEADER, whseJob16DetailCreateHeader),

  takeLatest(
    WhseJob16DetailTypes.WHSE_JOB16_DETAIL_TRANSITION_TO_STATUS,
    whseJob16DetailTransitionToStatus
  ),

  takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_SHOW_DETAILS, whseJob16DetailShowDetails),

  takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_UPDATE_DETAILS, whseJob16DetailUpdateDetails),
  takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_DELETE_DETAIL, whseJob16DetailDeleteDetail),
  takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_CREATE_DETAIL, whseJob16DetailCreateDetail),

  takeLatest(
    WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_COMPANY_OPTIONS,
    whseJob16DetailFetchCompanyOptions
  ),

  takeLatest(
    WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_ITEM_OPTIONS,
    whseJob16DetailFetchItemOptions
  ),
  takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_CHANGE_ITEM, whseJob16DetailChangeItem),

  takeLatest(
    WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_ITEM_BATCH_OPTIONS,
    whseJob16DetailFetchItemBatchOptions
  ),
  takeLatest(
    WhseJob16DetailTypes.WHSE_JOB16_DETAIL_CHANGE_ITEM_BATCH,
    whseJob16DetailChangeItemBatch
  ),

  takeLatest(
    WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_UOM_OPTIONS,
    whseJob16DetailFetchUomOptions
  ),
  takeLatest(WhseJob16DetailTypes.WHSE_JOB16_DETAIL_CHANGE_UOM, whseJob16DetailChangeUom),

  takeLatest(
    WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_STORAGE_BIN_OPTIONS,
    whseJob16DetailFetchStorageBinOptions
  ),

  takeLatest(
    WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_HANDLING_UNIT_OPTIONS,
    whseJob16DetailFetchHandlingUnitOptions
  ),

  takeLatest(
    WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_STORAGE_ROW_OPTIONS,
    whseJob16DetailFetchStorageRowOptions
  ),
  takeLatest(
    WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_STORAGE_BAY_OPTIONS,
    whseJob16DetailFetchStorageBayOptions
  ),
  takeLatest(
    WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_WORKER_OPTIONS,
    whseJob16DetailFetchWorkerOptions
  )
];
