import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  rtnRcptProcessFetchRtnRcptProcess: null,
  rtnRcptProcessFetchRtnRcptProcessLoading: ['boolean'],
  rtnRcptProcessFetchRtnRcptProcessSuccess: ['process']
});

export const RtnRcptProcessTypes = Types;
export default Creators;
