import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import './PutAwayDetailBoardKanban.css';

import { List } from 'antd';
import { Card, Badge } from 'react-bootstrap';

import PutAwayDetailBoardActions from '../../Stores/PutAwayDetailBoard/Actions';

class WipGdsRcptTable extends React.PureComponent {
  constructor() {
    super();

    this.renderItem = this.renderItem.bind(this);
    this.useOnListChange = this.useOnListChange.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const { curSiteFlowId, fetchWipGdsRcpt, currentPage, sorts, filters, pageSize } = this.props;
    const { resetTimestamp } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchWipGdsRcpt(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  useOnListChange(page, pageSize) {
    const { resetTimestamp, sorts, filters } = this.props;

    resetTimestamp(page, sorts, filters, pageSize);
  }

  renderItem(record) {
    const { intl, showWipGdsRcptSummary } = this.props;

    let color = 'badge-default'; // red
    if (record.doc_date_aging < 1) {
      color = 'badge-green'; // green
    } else if (record.doc_date_aging < 2) {
      color = 'badge-lime'; // lime
    } else if (record.doc_date_aging < 3) {
      color = 'badge-yellow'; // yellow
    } else if (record.doc_date_aging < 4) {
      color = 'badge-gold'; // gold
    } else if (record.doc_date_aging < 5) {
      color = 'badge-orange'; // orange
    } else if (record.doc_date_aging < 6) {
      color = 'badge-pink'; // pink
    }

    return (
      <Card className="rowcard" key={record.id} onClick={() => showWipGdsRcptSummary(record.id)}>
        <Card.Header>
          {record.doc_code}
          {record.ref_code_01 !== record.doc_code && <>{` ${record.ref_code_01}`}</>}
        </Card.Header>
        <Card.Body>
          <div className="progressinfo">
            <div>
              <h4>
                <Badge className={color}>{record.doc_date_aging}</Badge>
              </h4>
              <h6 className="daypassed">
                day(s)
                <br />
                passed
              </h6>
            </div>
            <div>
              <Card.Title className="infotext" style={{ fontSize: '15px', wordBreak: 'break-all' }}>
                {record.to_storage_bin_code}
              </Card.Title>
              <Card.Text className="infotext">{record.biz_partner_company_name_01}</Card.Text>
            </div>
          </div>
        </Card.Body>
        <Card.Footer>
          <small className="datetime">{record.created_at}</small>
          <small className="lineno">
            {`${record.ttl_line_no} ${intl.formatMessage({ id: 'lines' })}`}
          </small>
        </Card.Footer>
      </Card>
    );
  }

  render() {
    const { intl, currentPage, pageSize, total, documents, fetchIsLoading } = this.props;

    return (
      <Card className="columncard" text="dark">
        <Card.Header className="wipgdsrcpt">
          {`${total} ${intl.formatMessage({ id: 'goods_receipts' })}`}
        </Card.Header>
        <Card.Body className="scr">
          <List
            loading={fetchIsLoading}
            itemLayout="horizontal"
            dataSource={documents}
            renderItem={this.renderItem}
            pagination={{
              current: currentPage,
              pageSize: parseInt(pageSize, 10),
              total,
              size: 'small',
              simple: 'simple',
              onChange: this.useOnListChange
            }}
          />
        </Card.Body>
      </Card>
    );
  }
}

WipGdsRcptTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,

  documents: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchWipGdsRcpt: PropTypes.func,
  showWipGdsRcptSummary: PropTypes.func
};

WipGdsRcptTable.defaultProps = {
  intl: {},
  resetTimestamp() {},
  documents: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchWipGdsRcpt() {},
  showWipGdsRcptSummary() {}
};

const mapStateToProps = state => ({
  timestamp: state.putAwayDetailBoard.wipGdsRcptTimestamp,

  documents: state.putAwayDetailBoard.wipGdsRcpts,

  sorts: state.putAwayDetailBoard.wipGdsRcptSorts,
  filters: state.putAwayDetailBoard.wipGdsRcptFilters,

  currentPage: state.putAwayDetailBoard.wipGdsRcptCurrentPage,
  pageSize: state.putAwayDetailBoard.wipGdsRcptPageSize,
  total: state.putAwayDetailBoard.wipGdsRcptTotal,
  fetchIsLoading: state.putAwayDetailBoard.wipGdsRcptIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PutAwayDetailBoardActions.putAwayDetailBoardResetWipGdsRcptTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),

  fetchWipGdsRcpt: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PutAwayDetailBoardActions.putAwayDetailBoardFetchWipGdsRcpt(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),

  showWipGdsRcptSummary: docId =>
    dispatch(PutAwayDetailBoardActions.putAwayDetailBoardShowWipGdsRcptSummary(docId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WipGdsRcptTable));
