import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import DocTypeDocNoIndexActions, {
  DocTypeDocNoIndexTypes
} from '../Stores/DocTypeDocNoIndex/Actions';

const getAppStore = state => state.app;

export function* docTypeDocNoIndexShowDocNos({ docType }) {
  try {
    yield put(DocTypeDocNoIndexActions.docTypeDocNoIndexShowDocNoLoading(true));

    const app = yield select(getAppStore);
    const getData = {
      divisionId: app.curDivisionId
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `docNo/showDocNos/${docType}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DocTypeDocNoIndexActions.docTypeDocNoIndexShowDocNosSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DocTypeDocNoIndexActions.docTypeDocNoIndexShowDocNoLoading(false));
  }
}

export function* docTypeDocNoIndexUpdateDocNo({ formikBag, docNo }) {
  formikBag.setSubmitting(true);
  yield put(DocTypeDocNoIndexActions.docTypeDocNoIndexShowDocNoLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: docNo,
      divisionId: app.curDivisionId
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `docNo/updateDocNo`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const { model: retModel } = result.data;

      yield put(DocTypeDocNoIndexActions.docTypeDocNoIndexUpdateDocNoSuccess(retModel));

      yield put(DocTypeDocNoIndexActions.docTypeDocNoIndexResetTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(DocTypeDocNoIndexActions.docTypeDocNoIndexShowDocNoLoading(false));
  }
}

export function* docTypeDocNoIndexCreateDocNo({ formikBag, docType, docNo }) {
  formikBag.setSubmitting(true);
  yield put(DocTypeDocNoIndexActions.docTypeDocNoIndexShowDocNoLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: docNo,
      divisionId: app.curDivisionId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `docNo/createDocNo/${docType}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const { model: retModel } = result.data;

      yield put(DocTypeDocNoIndexActions.docTypeDocNoIndexUpdateDocNoSuccess(retModel));

      yield put(DocTypeDocNoIndexActions.docTypeDocNoIndexResetTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(DocTypeDocNoIndexActions.docTypeDocNoIndexShowDocNoLoading(false));
  }
}

export function* docTypeDocNoIndexDeleteDocNo({ docNo }) {
  yield put(DocTypeDocNoIndexActions.docTypeDocNoIndexShowDocNoLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: [{ id: docNo.id }]
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `docNo/deleteDocNos`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(DocTypeDocNoIndexActions.docTypeDocNoIndexResetTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DocTypeDocNoIndexActions.docTypeDocNoIndexShowDocNoLoading(false));
  }
}

export const saga = [
  takeLatest(
    DocTypeDocNoIndexTypes.DOC_TYPE_DOC_NO_INDEX_SHOW_DOC_NOS,
    docTypeDocNoIndexShowDocNos
  ),

  takeLatest(
    DocTypeDocNoIndexTypes.DOC_TYPE_DOC_NO_INDEX_UPDATE_DOC_NO,
    docTypeDocNoIndexUpdateDocNo
  ),
  takeLatest(
    DocTypeDocNoIndexTypes.DOC_TYPE_DOC_NO_INDEX_CREATE_DOC_NO,
    docTypeDocNoIndexCreateDocNo
  ),
  takeLatest(
    DocTypeDocNoIndexTypes.DOC_TYPE_DOC_NO_INDEX_DELETE_DOC_NO,
    docTypeDocNoIndexDeleteDocNo
  )
];
