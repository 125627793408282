import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { ArrowLeftOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import CreditorDetailActions from '../../Stores/CreditorDetail/Actions';
import AppActions from '../../Stores/App/Actions';

class BizPartnerTable extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { resId, resetTimestamp, showBizPartnerSuccess } = this.props;

    if (resId > 0) {
      resetTimestamp();
    } else {
      showBizPartnerSuccess([], 1, 1, 0, 20);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      resId,
      resetTimestamp,
      showBizPartners,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('resId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      showBizPartners(resId, currentPage, sorts, filters, pageSize);
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl, sorts, filters, goToBizPartner } = this.props;

    return [
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        ...AntDesignTable.getColumnSortProps(sorts, 'code'),
        // filter field
        key: 'code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'code' }),
          'code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.code}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'ref_code' }),
        // sort field
        dataIndex: 'ref_code_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'ref_code_01'),
        // filter field
        key: 'ref_code_01',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'ref_code' }),
          'ref_code_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.ref_code_01}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'name' }),
        // sort field
        dataIndex: 'company_name',
        ...AntDesignTable.getColumnSortProps(sorts, 'company_name'),
        // filter field
        key: 'company_name',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'name' }),
          'company_name',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.company_name_01}</>
      },
      {
        width: 50,
        fixed: 'right',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Button
              type="dashed"
              icon={<EditOutlined />}
              onClick={() => goToBizPartner(record.id)}
            />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  render() {
    const {
      intl,
      historyGoBack,
      resId,
      resetTimestamp,
      newBizPartner,
      currentPage,
      pageSize,
      total,
      bizPartners,
      modelIsLoading
    } = this.props;

    return (
      <>
        <Table
          // title={() => intl.formatMessage({ id: 'bizPartners' })}
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={bizPartners}
          loading={modelIsLoading}
          bordered
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={modelIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
                <Col span={5}>
                  <Button
                    type="primary"
                    disabled={resId === 0}
                    loading={modelIsLoading}
                    onClick={() => newBizPartner(resId)}
                    // icon={<PlusOutlined />}
                  >
                    {intl.formatMessage({ id: 'new' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 900 }}
        />
        <Button
          type="primary"
          // disabled={!isValid}
          loading={modelIsLoading}
          onClick={historyGoBack}
          icon={<ArrowLeftOutlined />}
        >
          {intl.formatMessage({
            id: 'back'
          })}
        </Button>
      </>
    );
  }
}

BizPartnerTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToBizPartner: PropTypes.func,
  newBizPartner: PropTypes.func,
  showBizPartners: PropTypes.func,
  historyGoBack: PropTypes.func,
  bizPartners: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  modelIsLoading: PropTypes.bool,

  showBizPartnerSuccess: PropTypes.func,

  resId: PropTypes.number
};

BizPartnerTable.defaultProps = {
  intl: {},

  resetTimestamp() {},
  goToBizPartner() {},
  newBizPartner() {},
  showBizPartners() {},
  historyGoBack() {},
  bizPartners: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  modelIsLoading: false,

  showBizPartnerSuccess() {},

  resId: 0
};

const mapStateToProps = state => ({
  timestamp: state.creditorDetail.bizPartnerTimestamp,
  resId: state.creditorDetail.resId,
  bizPartners: state.creditorDetail.bizPartners,

  sorts: state.creditorDetail.bizPartnerSorts,
  filters: state.creditorDetail.bizPartnerFilters,

  currentPage: state.creditorDetail.bizPartnerCurrentPage,
  pageSize: state.creditorDetail.bizPartnerPageSize,
  total: state.creditorDetail.bizPartnerTotal,
  modelIsLoading: state.creditorDetail.modelIsLoading
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      CreditorDetailActions.creditorDetailResetBizPartnerTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  goToBizPartner: bizPartnerId =>
    dispatch(CreditorDetailActions.creditorDetailGoToBizPartner(bizPartnerId)),
  newBizPartner: debtorId => dispatch(CreditorDetailActions.creditorDetailNewBizPartner(debtorId)),
  showBizPartners: (resId, currentPage, sorts, filters, pageSize) =>
    dispatch(
      CreditorDetailActions.creditorDetailShowBizPartners(
        resId,
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),

  showBizPartnerSuccess: (bizPartners, currentPage, lastPage, total, pageSize) =>
    dispatch(
      CreditorDetailActions.creditorDetailShowBizPartnerSuccess(
        bizPartners,
        currentPage,
        lastPage,
        total,
        pageSize
      )
    ),

  historyGoBack: () => dispatch(AppActions.appHistoryGoBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BizPartnerTable));
