import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { DeleteOutlined } from '@ant-design/icons';

import { Row, Col, Modal, Table, Button, Popconfirm } from 'antd';

import LspDelOrdIndexActions from '../../Stores/LspDelOrdIndex/Actions';

class LspDelOrdIndexDialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  getSelectedDocumentColumns() {
    const { intl, removeSelectedDocuments } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'salesman' }),
        // sort field
        dataIndex: 'salesman_username',
        sorter: (a, b) => `${a.salesman_username}`.localeCompare(b.salesman_username),
        // filter field
        key: 'salesman',
        render: (text, record) => (
          <>
            <b>{record.salesman_username}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'delivery_point' }),
        // sort field
        dataIndex: 'delivery_point_code',
        sorter: (a, b) => `${a.delivery_point_code}`.localeCompare(b.delivery_point_code),
        // filter field
        key: 'delivery_point',
        render: (text, record) => (
          <>
            <b>{record.delivery_point_code}</b>
            <br />
            {record.delivery_point_company_name_01}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
            <br />
            {record.str_doc_status}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'net_amt' }),
        // sort field
        dataIndex: 'net_amt',
        sorter: (a, b) => a.net_amt - b.net_amt,
        // filter field
        key: 'net_amt',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.net_amt)}
            </div>
          </>
        )
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedDocuments([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  render() {
    const { intl, selectedDocuments, workspaceIsVisible, workspaceIsLoading } = this.props;

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'selected_documents' })}
          // style={{top:20}}
          width="80%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocuments}
                loading={workspaceIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_documents' })}</b>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

LspDelOrdIndexDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedDocuments: PropTypes.func,
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  workspaceIsLoading: PropTypes.bool
};

LspDelOrdIndexDialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedDocuments() {},
  selectedDocuments: [],

  workspaceIsLoading: false
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.lspDelOrdIndex.workspaceIsVisible,

  selectedDocuments: state.lspDelOrdIndex.selectedDocuments,

  workspaceIsLoading: state.lspDelOrdIndex.workspaceIsLoading
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean =>
    dispatch(LspDelOrdIndexActions.lspDelOrdIndexSetWorkspaceVisible(boolean)),

  removeSelectedDocuments: selectedDocuments =>
    dispatch(LspDelOrdIndexActions.lspDelOrdIndexRemoveSelectedDocuments(selectedDocuments))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LspDelOrdIndexDialog));
