/**
 * The initial values for the redux state.
 */
export default {
  wipGdsRcptTimestamp: 0,
  wipGdsRcpts: [],
  wipGdsRcptIsLoading: false,
  wipGdsRcptSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  wipGdsRcptFilters: {},
  wipGdsRcptPageSize: '20',
  wipGdsRcptCurrentPage: 1,
  wipGdsRcptLastPage: 10,
  wipGdsRcptTotal: 0,

  wipPutAwayTimestamp: 0,
  wipPutAways: [],
  wipPutAwayIsLoading: false,
  wipPutAwaySorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  wipPutAwayFilters: {},
  wipPutAwayPageSize: '20',
  wipPutAwayCurrentPage: 1,
  wipPutAwayLastPage: 10,
  wipPutAwayTotal: 0,

  wipWhseJobTimestamp: 0,
  wipWhseJobs: [],
  wipWhseJobIsLoading: false,
  wipWhseJobSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  wipWhseJobFilters: {},
  wipWhseJobPageSize: '20',
  wipWhseJobCurrentPage: 1,
  wipWhseJobLastPage: 10,
  wipWhseJobTotal: 0,

  completePutAwayTimestamp: 0,
  completePutAways: [],
  completePutAwayIsLoading: false,
  completePutAwaySorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  completePutAwayFilters: {},
  completePutAwayPageSize: '20',
  completePutAwayCurrentPage: 1,
  completePutAwayLastPage: 10,
  completePutAwayTotal: 0,

  wipGdsRcptDialogIsVisible: false,
  wipGdsRcptDocId: 0,
  wipGdsRcptDocument: { doc_code: '', doc_status: 3 },
  wipGdsRcptDocumentIsLoading: false,

  wipPutAwayDialogIsVisible: false,
  wipPutAwayDocId: 0,
  wipPutAwayDocument: { doc_code: '', doc_status: 3 },
  wipPutAwayDocumentIsLoading: false,

  wipWhseJobDialogIsVisible: false,
  wipWhseJobDocId: 0,
  wipWhseJobDocument: { doc_code: '', doc_status: 3 },
  wipWhseJobDocumentIsLoading: false,

  completePutAwayDialogIsVisible: false,
  completePutAwayDocId: 0,
  completePutAwayDocument: { doc_code: '', doc_status: 3 },
  completePutAwayDocumentIsLoading: false
};
