import { put, call, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
// import ApiService from '../Services/ApiService';
// import AppActions from '../Stores/App/Actions';
import ItemManufactureProcessActions, {
  ItemManufactureProcessTypes
} from '../Stores/ItemManufactureProcess/Actions';

// const getAppStore = state => state.app;

// eslint-disable-next-line import/prefer-default-export
export function* itemManufactureProcessFetchItemProcess() {
  try {
    yield put(ItemManufactureProcessActions.itemManufactureProcessFetchItemProcessLoading(true));

    const result = [
      { proc_type: 'FINISHED_ITEMS', icon: 'fa fa-cubes' },
      { proc_type: 'SEMI_FINISHED_ITEMS', icon: 'fa fa-cubes' },
      { proc_type: 'RAW_MATERIALS', icon: 'fa fa-cubes' },
      { proc_type: 'ITEM_EXCEL_01', icon: 'fa fa-file-excel-o' },
      { proc_type: 'ITEM_DOC_NO_EXCEL_01', icon: 'fa fa-file-excel-o' }
    ];
    yield put(ItemManufactureProcessActions.itemManufactureProcessFetchItemProcessSuccess(result));
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemManufactureProcessActions.itemManufactureProcessFetchItemProcessLoading(false));
  }
}

export const saga = [
  takeLatest(
    ItemManufactureProcessTypes.ITEM_MANUFACTURE_PROCESS_FETCH_ITEM_PROCESS,
    itemManufactureProcessFetchItemProcess
  )
];
