import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import StorageTypeDetailActions, {
  StorageTypeDetailTypes
} from '../Stores/StorageTypeDetail/Actions';

const getAppStore = state => state.app;

export function* storageTypeDetailInitModel({ storageTypeType }) {
  try {
    yield put(StorageTypeDetailActions.storageTypeDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `storageType/initModel/${storageTypeType}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(StorageTypeDetailActions.storageTypeDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StorageTypeDetailActions.storageTypeDetailShowModelLoading(false));
  }
}

export function* storageTypeDetailShowModel({ resId }) {
  try {
    yield put(StorageTypeDetailActions.storageTypeDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `storageType/showModel/${resId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(StorageTypeDetailActions.storageTypeDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StorageTypeDetailActions.storageTypeDetailShowModelLoading(false));
  }
}

export function* storageTypeDetailUpdateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'storageType/updateModel',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const { model: retModel } = result.data;

      yield put(StorageTypeDetailActions.storageTypeDetailUpdateModelSuccess(retModel));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* storageTypeDetailCreateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageType/createModel`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/storageTypeDetail/update/${result.data}`));
      yield put(StorageTypeDetailActions.storageTypeDetailSetResId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* storageTypeDetailFetchStorageRowOptions({ search }) {
  try {
    yield put(StorageTypeDetailActions.storageTypeDetailFetchStorageRowOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageRow/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(StorageTypeDetailActions.storageTypeDetailFetchStorageRowOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StorageTypeDetailActions.storageTypeDetailFetchStorageRowOptionLoading(false));
  }
}

export const saga = [
  takeLatest(StorageTypeDetailTypes.STORAGE_TYPE_DETAIL_INIT_MODEL, storageTypeDetailInitModel),
  takeLatest(StorageTypeDetailTypes.STORAGE_TYPE_DETAIL_SHOW_MODEL, storageTypeDetailShowModel),

  takeLatest(StorageTypeDetailTypes.STORAGE_TYPE_DETAIL_UPDATE_MODEL, storageTypeDetailUpdateModel),
  takeLatest(StorageTypeDetailTypes.STORAGE_TYPE_DETAIL_CREATE_MODEL, storageTypeDetailCreateModel),

  takeLatest(
    StorageTypeDetailTypes.STORAGE_TYPE_DETAIL_FETCH_STORAGE_ROW_OPTIONS,
    storageTypeDetailFetchStorageRowOptions
  )
];
