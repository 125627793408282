/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { TrfFromStoreProcessTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const trfFromStoreProcessFetchTrfFromStoreProcessLoading = (state, { boolean }) => ({
  ...state,
  processIsLoading: boolean
});

export const trfFromStoreProcessFetchTrfFromStoreProcessSuccess = (state, { process }) => ({
  ...state,
  process
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [TrfFromStoreProcessTypes.TRF_FROM_STORE_PROCESS_FETCH_TRF_FROM_STORE_PROCESS_LOADING]: trfFromStoreProcessFetchTrfFromStoreProcessLoading,
  [TrfFromStoreProcessTypes.TRF_FROM_STORE_PROCESS_FETCH_TRF_FROM_STORE_PROCESS_SUCCESS]: trfFromStoreProcessFetchTrfFromStoreProcessSuccess
});
