import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  siteTrfProcessFetchSiteTrfProcess: null,
  siteTrfProcessFetchSiteTrfProcessLoading: ['boolean'],
  siteTrfProcessFetchSiteTrfProcessSuccess: ['process']
});

export const SiteTrfProcessTypes = Types;
export default Creators;
