/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhseJob1502IndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const whseJob1502IndexResetTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  fetchIsLoading: false
});

export const whseJob1502IndexFetchWhseJob1502IndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const whseJob1502IndexFetchWhseJob1502IndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const whseJob1502IndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ].slice(0, 5)
  };
};

export const whseJob1502IndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const whseJob1502IndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhseJob1502IndexTypes.WHSE_JOB1502_INDEX_RESET_TIMESTAMP]: whseJob1502IndexResetTimestamp,
  [WhseJob1502IndexTypes.WHSE_JOB1502_INDEX_FETCH_WHSE_JOB1502_INDEX_LOADING]: whseJob1502IndexFetchWhseJob1502IndexLoading,
  [WhseJob1502IndexTypes.WHSE_JOB1502_INDEX_FETCH_WHSE_JOB1502_INDEX_SUCCESS]: whseJob1502IndexFetchWhseJob1502IndexSuccess,
  [WhseJob1502IndexTypes.WHSE_JOB1502_INDEX_ADD_SELECTED_DOCUMENTS]: whseJob1502IndexAddSelectedDocuments,
  [WhseJob1502IndexTypes.WHSE_JOB1502_INDEX_REMOVE_SELECTED_DOCUMENTS]: whseJob1502IndexRemoveSelectedDocuments,
  [WhseJob1502IndexTypes.WHSE_JOB1502_INDEX_SET_WORKSPACE_VISIBLE]: whseJob1502IndexSetWorkspaceVisible
});
