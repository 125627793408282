import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsIssSync01ShowSyncSetting: null,
  gdsIssSync01ShowSyncSettingLoading: ['boolean'],
  gdsIssSync01ShowSyncSettingSuccess: ['syncSetting'],
  gdsIssSync01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  gdsIssSync01ShowBatchJobStatus: null,
  gdsIssSync01ShowBatchJobStatusSuccess: ['batchJobStatus'],

  gdsIssSync01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsIssSync01GoToDocument: ['hdrId'],
  gdsIssSync01FetchGdsIssSync01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsIssSync01FetchGdsIssSync01Loading: ['boolean'],
  gdsIssSync01FetchGdsIssSync01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  gdsIssSync01AddSelectedDocuments: ['selectedDocuments'],
  gdsIssSync01RemoveSelectedDocuments: ['selectedDocuments'],
  gdsIssSync01SetWorkspaceVisible: ['boolean'],

  gdsIssSync01Export: ['hdrIds'],
  gdsIssSync01ExportSuccess: null,
  gdsIssSync01ExportLoading: ['boolean']
});

export const GdsIssSync01Types = Types;
export default Creators;
