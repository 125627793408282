import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { ArrowLeftOutlined, EditOutlined, ReloadOutlined, AuditOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button, Upload, notification } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import ItemDetailActions from '../../Stores/ItemDetail/Actions';
import AppActions from '../../Stores/App/Actions';
import ItemManufactureExcelActions from '../../Stores/ItemManufactureExcel/Actions';

class ItemBatchTable extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      fileList: []
    };

    this.useOnTableChange = this.useOnTableChange.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);

    this.useOnUpload = this.useOnUpload.bind(this);
    this.useOnDownload = this.useOnDownload.bind(this);
  }

  componentDidMount() {
    const { resId, resetTimestamp, showItemBatchSuccess } = this.props;

    if (resId > 0) {
      resetTimestamp();
    } else {
      showItemBatchSuccess([], 1, 1, 0, 20);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      resId,
      resetTimestamp,
      showItemBatches,
      currentPage,
      sorts,
      filters,
      pageSize,
      uploadIsLoading,
      showBatchJobStatus
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('resId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      showItemBatches(resId, currentPage, sorts, filters, pageSize);
    }

    if (uploadIsLoading) {
      setTimeout(() => {
        showBatchJobStatus();
      }, 10000);
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const { intl, sorts, filters, goToItemBatch, goToAudit } = this.props;

    return [
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'batch_serial_no' }),
        // sort field
        dataIndex: 'batch_serial_no',
        ...AntDesignTable.getColumnMultiSortProps(sorts, 'batch_serial_no', 3),
        // filter field
        key: 'batch_serial_no',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'batch_serial_no' }),
          'batch_serial_no',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.batch_serial_no
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'expiry_date' }),
        // sort field
        dataIndex: 'expiry_date',
        ...AntDesignTable.getColumnMultiSortProps(sorts, 'expiry_date', 2),
        // filter field
        key: 'expiry_date',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'expiry_date' }),
          'expiry_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.expiry_date
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'receipt_date' }),
        // sort field
        dataIndex: 'receipt_date',
        ...AntDesignTable.getColumnMultiSortProps(sorts, 'receipt_date', 1),
        // filter field
        key: 'receipt_date',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'receipt_date' }),
          'receipt_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.receipt_date
      },
      {
        width: 50,
        fixed: 'right',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Button
              type="dashed"
              icon={<EditOutlined />}
              onClick={() => goToItemBatch(record.id)}
            />
            <Button type="dashed" icon={<AuditOutlined />} onClick={() => goToAudit(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useOnUpload() {
    const { uploadExcel } = this.props;
    const { fileList } = this.state;

    if (fileList.length > 0) {
      uploadExcel(fileList[0]);
    }
  }

  useOnDownload() {
    const { downloadExcel, resId } = this.props;

    downloadExcel(resId);
  }

  render() {
    const {
      intl,
      historyGoBack,
      resId,
      resetTimestamp,
      newItemBatch,
      currentPage,
      pageSize,
      total,
      itemBatches,
      modelIsLoading,
      uploadIsLoading
    } = this.props;

    const { fileList } = this.state;

    return (
      <>
        <Table
          // title={() => intl.formatMessage({ id: 'itemBatches' })}
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={itemBatches}
          loading={modelIsLoading}
          bordered
          onChange={this.useOnTableChange}
          title={() => (
            <Row>
              <Col span={24}>
                <Row type="flex" justify="start" gutter={[0, 8]} style={{ marginBottom: 40 }}>
                  <Col span={12}>
                    <Upload
                      disabled={uploadIsLoading}
                      onRemove={() => {
                        this.setState({
                          fileList: []
                        });
                      }}
                      beforeUpload={file => {
                        // const validFileName = 'ITEM_EXCEL_01';
                        const validFileName = 'ITEM_BATCHES';
                        if (file && file.name.toLowerCase().includes(validFileName.toLowerCase())) {
                          this.setState({
                            fileList: [file]
                          });
                        } else {
                          notification.error({
                            message: `${intl.formatMessage({
                              id: 'invalid_file_name_must_contain'
                            })} ${validFileName}`,
                            duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
                          });
                        }

                        return false;
                      }}
                      fileList={fileList}
                    >
                      <Button type="primary" loading={uploadIsLoading}>
                        {intl.formatMessage({ id: 'browse' })}
                      </Button>
                    </Upload>
                  </Col>
                  <Col span={12}>
                    <Button
                      type="primary"
                      onClick={this.useOnUpload}
                      disabled={fileList.length === 0}
                      loading={uploadIsLoading}
                    >
                      {intl.formatMessage({ id: 'upload' })}
                    </Button>
                    <Button type="primary" onClick={this.useOnDownload} loading={uploadIsLoading}>
                      {intl.formatMessage({ id: 'download' })}
                    </Button>
                  </Col>
                </Row>
                <Row type="flex" justify="start" gutter={[0, 16]}>
                  <Col span={1}>
                    <Button
                      type="primary"
                      // disabled={!isValid}
                      loading={modelIsLoading}
                      onClick={() => resetTimestamp()}
                      icon={<ReloadOutlined />}
                    />
                  </Col>
                  <Col span={5}>
                    <Button
                      type="primary"
                      disabled={resId === 0}
                      loading={modelIsLoading}
                      onClick={() => newItemBatch(resId)}
                      // icon={<PlusOutlined />}
                    >
                      {intl.formatMessage({ id: 'new' })}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          scroll={{ x: 900 }}
        />
        <Button
          type="primary"
          // disabled={!isValid}
          loading={modelIsLoading}
          onClick={historyGoBack}
          icon={<ArrowLeftOutlined />}
        >
          {intl.formatMessage({
            id: 'back'
          })}
        </Button>
      </>
    );
  }
}

ItemBatchTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToItemBatch: PropTypes.func,
  goToAudit: PropTypes.func,
  newItemBatch: PropTypes.func,
  showItemBatches: PropTypes.func,
  historyGoBack: PropTypes.func,
  itemBatches: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  modelIsLoading: PropTypes.bool,

  showItemBatchSuccess: PropTypes.func,

  resId: PropTypes.number,

  batchJobStatus: PropTypes.shape({
    status_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  uploadIsLoading: PropTypes.bool,
  showBatchJobStatus: PropTypes.func,

  uploadExcel: PropTypes.func,
  downloadExcel: PropTypes.func
};

ItemBatchTable.defaultProps = {
  intl: {},

  resetTimestamp() {},
  goToItemBatch() {},
  goToAudit() {},
  newItemBatch() {},
  showItemBatches() {},
  historyGoBack() {},
  itemBatches: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  modelIsLoading: false,

  showItemBatchSuccess() {},

  resId: 0,

  batchJobStatus: {
    status_number: '0'
  },
  uploadIsLoading: false,
  showBatchJobStatus() {},

  uploadExcel() {},
  downloadExcel() {}
};

const mapStateToProps = state => ({
  timestamp: state.itemDetail.itemBatchTimestamp,
  resId: state.itemDetail.resId,
  itemBatches: state.itemDetail.itemBatches,

  sorts: state.itemDetail.itemBatchSorts,
  filters: state.itemDetail.itemBatchFilters,

  currentPage: state.itemDetail.itemBatchCurrentPage,
  pageSize: state.itemDetail.itemBatchPageSize,
  total: state.itemDetail.itemBatchTotal,
  modelIsLoading: state.itemDetail.modelIsLoading,

  batchJobStatus: state.itemManufactureExcel.batchJobStatus,
  uploadIsLoading: state.itemManufactureExcel.uploadIsLoading
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      ItemDetailActions.itemDetailResetItemBatchTimestamp(currentPage, sorts, filters, pageSize)
    ),
  goToItemBatch: itemBatchId => dispatch(ItemDetailActions.itemDetailGoToItemBatch(itemBatchId)),
  goToAudit: itemBatchId => dispatch(ItemDetailActions.itemDetailGoToAuditItemBatch(itemBatchId)),
  newItemBatch: itemId => dispatch(ItemDetailActions.itemDetailNewItemBatch(itemId)),
  showItemBatches: (resId, currentPage, sorts, filters, pageSize) =>
    dispatch(
      ItemDetailActions.itemDetailShowItemBatches(resId, currentPage, sorts, filters, pageSize)
    ),

  showItemBatchSuccess: (itemBatches, currentPage, lastPage, total, pageSize) =>
    dispatch(
      ItemDetailActions.itemDetailShowItemBatchSuccess(
        itemBatches,
        currentPage,
        lastPage,
        total,
        pageSize
      )
    ),

  historyGoBack: () => dispatch(AppActions.appHistoryGoBack()),
  showBatchJobStatus: () =>
    dispatch(ItemManufactureExcelActions.itemManufactureExcelShowBatchJobStatus()),

  uploadExcel: file =>
    dispatch(ItemManufactureExcelActions.itemManufactureExcelUploadItemBatches(file)),
  downloadExcel: itemId =>
    dispatch(ItemManufactureExcelActions.itemManufactureExcelDownloadItemBatches(itemId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ItemBatchTable));
