/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PurRtnFServ02Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const purRtnFServ02ShowSyncSettingLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const purRtnFServ02ShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const purRtnFServ02ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const purRtnFServ02ExportLoading = (state, { boolean }) => ({
  ...state,
  exportIsLoading: boolean
});

export const purRtnFServ02ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const purRtnFServ02FetchPurRtnFServ02Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const purRtnFServ02FetchPurRtnFServ02Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const purRtnFServ02AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const purRtnFServ02RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const purRtnFServ02SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const purRtnFServ02ExportSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PurRtnFServ02Types.PUR_RTN_F_SERV02_SHOW_SYNC_SETTING_LOADING]: purRtnFServ02ShowSyncSettingLoading,
  [PurRtnFServ02Types.PUR_RTN_F_SERV02_SHOW_SYNC_SETTING_SUCCESS]: purRtnFServ02ShowSyncSettingSuccess,
  [PurRtnFServ02Types.PUR_RTN_F_SERV02_SHOW_BATCH_JOB_STATUS_SUCCESS]: purRtnFServ02ShowBatchJobStatusSuccess,

  [PurRtnFServ02Types.PUR_RTN_F_SERV02_RESET_TIMESTAMP]: purRtnFServ02ResetTimestamp,
  [PurRtnFServ02Types.PUR_RTN_F_SERV02_FETCH_PUR_RTN_F_SERV02_LOADING]: purRtnFServ02FetchPurRtnFServ02Loading,
  [PurRtnFServ02Types.PUR_RTN_F_SERV02_FETCH_PUR_RTN_F_SERV02_SUCCESS]: purRtnFServ02FetchPurRtnFServ02Success,
  [PurRtnFServ02Types.PUR_RTN_F_SERV02_ADD_SELECTED_DOCUMENTS]: purRtnFServ02AddSelectedDocuments,
  [PurRtnFServ02Types.PUR_RTN_F_SERV02_REMOVE_SELECTED_DOCUMENTS]: purRtnFServ02RemoveSelectedDocuments,
  [PurRtnFServ02Types.PUR_RTN_F_SERV02_SET_WORKSPACE_VISIBLE]: purRtnFServ02SetWorkspaceVisible,
  [PurRtnFServ02Types.PUR_RTN_F_SERV02_EXPORT_LOADING]: purRtnFServ02ExportLoading,
  [PurRtnFServ02Types.PUR_RTN_F_SERV02_EXPORT_SUCCESS]: purRtnFServ02ExportSuccess
});
