import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  prodIssFServ01ShowSyncSetting: null,
  prodIssFServ01ShowSyncSettingLoading: ['boolean'],
  prodIssFServ01ShowSyncSettingSuccess: ['syncSetting'],
  prodIssFServ01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  prodIssFServ01ShowBatchJobStatus: null,
  prodIssFServ01ShowBatchJobStatusSuccess: ['batchJobStatus'],

  prodIssFServ01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prodIssFServ01GoToDocument: ['hdrId'],
  prodIssFServ01FetchProdIssFServ01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prodIssFServ01FetchProdIssFServ01Loading: ['boolean'],
  prodIssFServ01FetchProdIssFServ01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  prodIssFServ01AddSelectedDocuments: ['selectedDocuments'],
  prodIssFServ01RemoveSelectedDocuments: ['selectedDocuments'],
  prodIssFServ01SetWorkspaceVisible: ['boolean'],

  prodIssFServ01Export: ['hdrIds'],
  prodIssFServ01ExportSuccess: null,
  prodIssFServ01ExportLoading: ['boolean']
});

export const ProdIssFServ01Types = Types;
export default Creators;
