import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
// FIX: imported css
import './PickListDetailBoardKanban.css';

// FIX: removed avatar and button  from antd and imported card and badge from react-bootstrap
import { List } from 'antd';
import { Card, Badge } from 'react-bootstrap';

import PickListDetailBoardActions from '../../Stores/PickListDetailBoard/Actions';

class WipOutbOrdTable extends React.PureComponent {
  constructor() {
    super();

    this.renderItem = this.renderItem.bind(this);
    this.useOnListChange = this.useOnListChange.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const { curSiteFlowId, fetchWipOutbOrd, currentPage, sorts, filters, pageSize } = this.props;
    const { resetTimestamp } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchWipOutbOrd(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  useOnListChange(page, pageSize) {
    const { resetTimestamp, sorts, filters } = this.props;

    resetTimestamp(page, sorts, filters, pageSize);
  }

  renderItem(record) {
    const { intl, showWipOutbOrdSummary } = this.props;

    // FIX: change badge color to css type with different font color depend on the background for easy viewing
    let color = 'badge-default'; // red
    if (record.doc_date_aging < 1) {
      color = 'badge-green'; // green
    } else if (record.doc_date_aging < 2) {
      color = 'badge-lime'; // lime
    } else if (record.doc_date_aging < 3) {
      color = 'badge-yellow'; // yellow
    } else if (record.doc_date_aging < 4) {
      color = 'badge-gold'; // gold
    } else if (record.doc_date_aging < 5) {
      color = 'badge-orange'; // orange
    } else if (record.doc_date_aging < 6) {
      color = 'badge-pink'; // pink
    }

    return (
      // FIX: change card style to bootstrap design,
      // FIX: clicking the any part of the card will pop up the modal
      <Card className="rowcard" key={record.id} onClick={() => showWipOutbOrdSummary(record.id)}>
        <Card.Header>{`${record.shipping_doc_code} ${record.doc_code}`}</Card.Header>
        <Card.Body>
          <div className="progressinfo">
            <div>
              <h4>
                <Badge className={color}>{record.doc_date_aging}</Badge>
              </h4>
              <h6 className="daypassed">
                day(s)
                <br />
                passed
              </h6>
            </div>
            <div>
              <Card.Title className="infotext" style={{ fontSize: '15px', wordBreak: 'break-all' }}>
                {record.delivery_point_area_code}
              </Card.Title>
              <Card.Text className="infotext">{record.delivery_point_company_name_01}</Card.Text>
            </div>
          </div>
        </Card.Body>
        <Card.Footer>
          <small className="datetime">{record.created_at}</small>
          <small className="lineno">
            {`${record.ttl_line_no} ${intl.formatMessage({ id: 'lines' })}`}
          </small>
        </Card.Footer>
      </Card>
    );
  }

  render() {
    const { intl, currentPage, pageSize, total, documents, fetchIsLoading } = this.props;

    return (
      <Card className="columncard" text="dark">
        <Card.Header className="outbound">
          {`${total} ${intl.formatMessage({ id: 'outbound_orders' })}`}
        </Card.Header>
        <Card.Body className="scr">
          <List
            loading={fetchIsLoading}
            itemLayout="horizontal"
            dataSource={documents}
            renderItem={this.renderItem}
            pagination={{
              current: currentPage,
              pageSize: parseInt(pageSize, 10),
              total,
              size: 'small',
              simple: 'simple',
              onChange: this.useOnListChange
            }}
          />
        </Card.Body>
      </Card>
    );
  }
}

WipOutbOrdTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,

  documents: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchWipOutbOrd: PropTypes.func,
  showWipOutbOrdSummary: PropTypes.func
};

WipOutbOrdTable.defaultProps = {
  intl: {},
  resetTimestamp() {},
  documents: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchWipOutbOrd() {},
  showWipOutbOrdSummary() {}
};

const mapStateToProps = state => ({
  timestamp: state.pickListDetailBoard.wipOutbOrdTimestamp,

  documents: state.pickListDetailBoard.wipOutbOrds,

  sorts: state.pickListDetailBoard.wipOutbOrdSorts,
  filters: state.pickListDetailBoard.wipOutbOrdFilters,

  currentPage: state.pickListDetailBoard.wipOutbOrdCurrentPage,
  pageSize: state.pickListDetailBoard.wipOutbOrdPageSize,
  total: state.pickListDetailBoard.wipOutbOrdTotal,
  fetchIsLoading: state.pickListDetailBoard.wipOutbOrdIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PickListDetailBoardActions.pickListDetailBoardResetWipOutbOrdTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),

  fetchWipOutbOrd: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PickListDetailBoardActions.pickListDetailBoardFetchWipOutbOrd(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),

  showWipOutbOrdSummary: docId =>
    dispatch(PickListDetailBoardActions.pickListDetailBoardShowWipOutbOrdSummary(docId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WipOutbOrdTable));
