import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Modal, Table } from 'antd';

import WarehouseMapActions from '../../Stores/WarehouseMap/Actions';

class WarehouseMapDialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);

    this.getSelectedColumns = this.getSelectedColumns.bind(this);
    this.convertExpiryDate = this.convertExpiryDate.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  getSelectedColumns() {
    const { intl } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'item' }),
        // sort field
        dataIndex: 'item_code',
        sorter: (a, b) => `${a.item_code}`.localeCompare(b.item_code),
        // filter field
        key: 'item_code',
        render: (text, record) => {
          const backgroundColor = this.convertExpiryDate(new Date(record.expiry_date));
          return (
            <>
              <div style={{ fontWeight: 'bold', backgroundColor }}>{record.item_code}</div>
              <div>{record.item_unit_barcode ? record.item_unit_barcode : ''}</div>
              <div>{record.item_case_barcode ? record.item_case_barcode : ''}</div>
            </>
          );
        }
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'item_desc_01',
        sorter: (a, b) => `${a.item_desc_01}`.localeCompare(b.item_desc_01),
        // filter field
        key: 'item_desc_01'
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'item_batch' }),
        // sort field
        dataIndex: 'expiry_date',
        sorter: (a, b) => new Date(a.expiry_date) - new Date(b.expiry_date),
        defaultSortOrder: 'ascend',
        // filter field
        key: 'expiry_date',
        render: (text, record) => (
          <>
            <div style={{ fontWeight: 'bold' }}>{record.batch_serial_no}</div>
            <div>
              <b>EXP:</b>
              {record.expiry_date ? record.expiry_date : ''}
            </div>
            <div>
              <b>RCP:</b>
              {record.receipt_date ? record.receipt_date : ''}
            </div>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'pallet_id' }),
        // sort field
        dataIndex: 'handling_unit_barcode',
        sorter: (a, b) => `${a.handling_unit_barcode}`.localeCompare(b.handling_unit_barcode),
        // filter field
        key: 'handling_unit_barcode',
        render: (text, record) => (
          <>
            <div style={{ fontWeight: 'bold' }}>{record.handling_unit_barcode}</div>
            <div>{record.handling_unit_ref_code_01 ? record.handling_unit_ref_code_01 : ''}</div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'case' }),
        // sort field
        dataIndex: 'case_qty',
        sorter: (a, b) => a.case_qty - b.case_qty,
        // filter field
        key: 'case_qty',
        render: (text, record) =>
          record.case_qty > 0 ? (
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.case_qty)}
              <br />
              {record.item_case_uom_code}
            </div>
          ) : (
            ''
          )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'loose' }),
        // sort field
        dataIndex: 'loose_qty',
        sorter: (a, b) => a.loose_qty - b.loose_qty,
        // filter field
        key: 'loose_qty',
        render: (text, record) =>
          record.loose_qty > 0 ? (
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.loose_qty)}
              <br />
              {record.item_loose_uom_code}
            </div>
          ) : (
            ''
          )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'gross_weight' }),
        // sort field
        dataIndex: 'gross_weight',
        sorter: (a, b) => a.gross_weight - b.gross_weight,
        // filter field
        key: 'gross_weight',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.gross_weight)}
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'cubic_meter' }),
        // sort field
        dataIndex: 'cubic_meter',
        sorter: (a, b) => a.cubic_meter - b.cubic_meter,
        // filter field
        key: 'cubic_meter',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.cubic_meter)}
          </>
        )
      }
    ];
  }

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  convertExpiryDate(expiryDate) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    let diffDays = 0;
    if (expiryDate !== null) {
      const thisDate = new Date();
      const oneDay = 24 * 60 * 60 * 1000;
      diffDays = Math.round(Math.abs((expiryDate.getTime() - thisDate.getTime()) / oneDay));
    }

    let backgroundColor = 'white';
    if (diffDays <= 90) {
      backgroundColor = 'red';
    } else if (diffDays <= 180) {
      backgroundColor = 'orange';
    } else if (diffDays <= 270) {
      backgroundColor = 'yellow';
    }
    return backgroundColor;
  }

  render() {
    const {
      intl,
      selectedStorageBin,
      selectedQuantBals,
      workspaceIsVisible,
      fetchIsLoading
    } = this.props;

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={selectedStorageBin.code}
          // style={{top:20}}
          width="80%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Table
            // rowSelection={rowSelection}
            rowKey="id"
            pagination={false}
            columns={this.getSelectedColumns()}
            dataSource={selectedQuantBals}
            loading={fetchIsLoading}
            bordered
            summary={pageData => {
              // summarise the table
              const calcRow = pageData.reduce(
                (ttlObj, object) => {
                  return {
                    case_qty:
                      ('case_qty' in ttlObj ? ttlObj.case_qty : 0) + parseFloat(object.case_qty),
                    loose_qty:
                      ('loose_qty' in ttlObj ? ttlObj.loose_qty : 0) + parseFloat(object.loose_qty),
                    gross_weight:
                      ('gross_weight' in ttlObj ? ttlObj.gross_weight : 0) +
                      parseFloat(object.gross_weight),
                    cubic_meter:
                      ('cubic_meter' in ttlObj ? ttlObj.cubic_meter : 0) +
                      parseFloat(object.cubic_meter)
                  };
                },
                { case_qty: 0, loose_qty: 0, gross_weight: 0, cubic_meter: 0 }
              );

              return (
                <>
                  <tr className="ant-table-row">
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={4}>
                      {intl.formatMessage({ id: 'total' })}
                    </td>
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      {new Intl.NumberFormat([], {
                        style: 'decimal',
                        minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                        maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                      }).format(calcRow.case_qty)}
                    </td>
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      {new Intl.NumberFormat([], {
                        style: 'decimal',
                        minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                        maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                      }).format(calcRow.loose_qty)}
                    </td>
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      {new Intl.NumberFormat([], {
                        style: 'decimal',
                        minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                        maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                      }).format(calcRow.gross_weight)}
                    </td>
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      {new Intl.NumberFormat([], {
                        style: 'decimal',
                        minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                        maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                      }).format(calcRow.cubic_meter)}
                    </td>
                  </tr>
                </>
              );
            }}
          />
        </Modal>
      </>
    );
  }
}

WarehouseMapDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  fetchIsLoading: PropTypes.bool,

  selectedStorageBin: PropTypes.shape({
    code: PropTypes.string
  }),

  selectedQuantBals: PropTypes.arrayOf(PropTypes.object)
};

WarehouseMapDialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  fetchIsLoading: false,

  selectedStorageBin: { code: '' },
  selectedQuantBals: []
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.warehouseMap.workspaceIsVisible,

  selectedStorageBin: state.warehouseMap.selectedStorageBin,
  selectedQuantBals: state.warehouseMap.selectedQuantBals,

  createIsLoading: state.warehouseMap.createIsLoading
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean =>
    dispatch(WarehouseMapActions.warehouseMapSetWorkspaceVisible(boolean))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WarehouseMapDialog));
