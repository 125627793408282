/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SiteFlowDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const siteFlowDetailResetModelTimestamp = state => ({
  ...state,
  modelTimestamp: Date.now()
});

export const siteFlowDetailSetResId = (state, { resId }) => ({
  ...state,
  modelTimestamp: Date.now(),
  resId
});

export const siteFlowDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const siteFlowDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const siteFlowDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const siteFlowDetailFetchSiteOptionLoading = (state, { boolean }) => ({
  ...state,
  siteIsLoading: boolean
});

export const siteFlowDetailFetchSiteOptionSuccess = (state, { options }) => ({
  ...state,
  siteOptions: options
});

export const siteFlowDetailResetWhseTxnFlowTimestamp = state => ({
  ...state,
  whseTxnFlowTimestamp: Date.now()
});

export const siteFlowDetailShowWhseTxnFlowsLoading = (state, { boolean }) => ({
  ...state,
  whseTxnFlowsIsLoading: boolean
});

export const siteFlowDetailShowWhseTxnFlowsSuccess = (state, { txnFlows }) => ({
  ...state,
  whseTxnFlows: txnFlows
});

export const siteFlowDetailWhseTxnFlowsUploadLoading = (state, { boolean }) => ({
  ...state,
  whseTxnFlowsUploadIsLoading: boolean
});

export const siteFlowDetailResetPrintDocSettingTimestamp = state => ({
  ...state,
  printDocSettingTimestamp: Date.now()
});

export const siteFlowDetailShowPrintDocSettingsLoading = (state, { boolean }) => ({
  ...state,
  printDocSettingsIsLoading: boolean
});

export const siteFlowDetailShowPrintDocSettingsSuccess = (state, { settings }) => ({
  ...state,
  printDocSettings: settings
});

export const siteFlowDetailPrintDocSettingsUploadLoading = (state, { boolean }) => ({
  ...state,
  printDocSettingsUploadIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SiteFlowDetailTypes.SITE_FLOW_DETAIL_RESET_MODEL_TIMESTAMP]: siteFlowDetailResetModelTimestamp,
  [SiteFlowDetailTypes.SITE_FLOW_DETAIL_SET_RES_ID]: siteFlowDetailSetResId,
  [SiteFlowDetailTypes.SITE_FLOW_DETAIL_SHOW_MODEL_LOADING]: siteFlowDetailShowModelLoading,
  [SiteFlowDetailTypes.SITE_FLOW_DETAIL_SHOW_MODEL_SUCCESS]: siteFlowDetailShowModelSuccess,
  [SiteFlowDetailTypes.SITE_FLOW_DETAIL_UPDATE_MODEL_SUCCESS]: siteFlowDetailUpdateModelSuccess,

  [SiteFlowDetailTypes.SITE_FLOW_DETAIL_FETCH_SITE_OPTION_LOADING]: siteFlowDetailFetchSiteOptionLoading,
  [SiteFlowDetailTypes.SITE_FLOW_DETAIL_FETCH_SITE_OPTION_SUCCESS]: siteFlowDetailFetchSiteOptionSuccess,

  [SiteFlowDetailTypes.SITE_FLOW_DETAIL_RESET_WHSE_TXN_FLOW_TIMESTAMP]: siteFlowDetailResetWhseTxnFlowTimestamp,
  [SiteFlowDetailTypes.SITE_FLOW_DETAIL_SHOW_WHSE_TXN_FLOWS_LOADING]: siteFlowDetailShowWhseTxnFlowsLoading,
  [SiteFlowDetailTypes.SITE_FLOW_DETAIL_SHOW_WHSE_TXN_FLOWS_SUCCESS]: siteFlowDetailShowWhseTxnFlowsSuccess,

  [SiteFlowDetailTypes.SITE_FLOW_DETAIL_WHSE_TXN_FLOWS_UPLOAD_LOADING]: siteFlowDetailWhseTxnFlowsUploadLoading,

  [SiteFlowDetailTypes.SITE_FLOW_DETAIL_RESET_PRINT_DOC_SETTING_TIMESTAMP]: siteFlowDetailResetPrintDocSettingTimestamp,
  [SiteFlowDetailTypes.SITE_FLOW_DETAIL_SHOW_PRINT_DOC_SETTINGS_LOADING]: siteFlowDetailShowPrintDocSettingsLoading,
  [SiteFlowDetailTypes.SITE_FLOW_DETAIL_SHOW_PRINT_DOC_SETTINGS_SUCCESS]: siteFlowDetailShowPrintDocSettingsSuccess,

  [SiteFlowDetailTypes.SITE_FLOW_DETAIL_PRINT_DOC_SETTINGS_UPLOAD_LOADING]: siteFlowDetailPrintDocSettingsUploadLoading
});
