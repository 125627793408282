/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { GdsRcptFServ03Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const gdsRcptFServ03ShowSyncSettingLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const gdsRcptFServ03ShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const gdsRcptFServ03ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const gdsRcptFServ03ExportLoading = (state, { boolean }) => ({
  ...state,
  exportIsLoading: boolean
});

export const gdsRcptFServ03ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const gdsRcptFServ03FetchGdsRcptFServ03Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const gdsRcptFServ03FetchGdsRcptFServ03Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const gdsRcptFServ03AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const gdsRcptFServ03RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const gdsRcptFServ03SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const gdsRcptFServ03ExportSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const gdsRcptFServ03SetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GdsRcptFServ03Types.GDS_RCPT_F_SERV03_SHOW_SYNC_SETTING_LOADING]: gdsRcptFServ03ShowSyncSettingLoading,
  [GdsRcptFServ03Types.GDS_RCPT_F_SERV03_SHOW_SYNC_SETTING_SUCCESS]: gdsRcptFServ03ShowSyncSettingSuccess,
  [GdsRcptFServ03Types.GDS_RCPT_F_SERV03_SHOW_BATCH_JOB_STATUS_SUCCESS]: gdsRcptFServ03ShowBatchJobStatusSuccess,

  [GdsRcptFServ03Types.GDS_RCPT_F_SERV03_RESET_TIMESTAMP]: gdsRcptFServ03ResetTimestamp,
  [GdsRcptFServ03Types.GDS_RCPT_F_SERV03_FETCH_GDS_RCPT_F_SERV03_LOADING]: gdsRcptFServ03FetchGdsRcptFServ03Loading,
  [GdsRcptFServ03Types.GDS_RCPT_F_SERV03_FETCH_GDS_RCPT_F_SERV03_SUCCESS]: gdsRcptFServ03FetchGdsRcptFServ03Success,
  [GdsRcptFServ03Types.GDS_RCPT_F_SERV03_ADD_SELECTED_DOCUMENTS]: gdsRcptFServ03AddSelectedDocuments,
  [GdsRcptFServ03Types.GDS_RCPT_F_SERV03_REMOVE_SELECTED_DOCUMENTS]: gdsRcptFServ03RemoveSelectedDocuments,
  [GdsRcptFServ03Types.GDS_RCPT_F_SERV03_SET_WORKSPACE_VISIBLE]: gdsRcptFServ03SetWorkspaceVisible,
  [GdsRcptFServ03Types.GDS_RCPT_F_SERV03_EXPORT_LOADING]: gdsRcptFServ03ExportLoading,
  [GdsRcptFServ03Types.GDS_RCPT_F_SERV03_EXPORT_SUCCESS]: gdsRcptFServ03ExportSuccess,

  [GdsRcptFServ03Types.GDS_RCPT_F_SERV03_SET_EXPANDED_ROWS]: gdsRcptFServ03SetExpandedRows
});
