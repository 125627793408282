import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  salesProcessFetchSalesProcess: null,
  salesProcessFetchSalesProcessLoading: ['boolean'],
  salesProcessFetchSalesProcessSuccess: ['process']
});

export const SalesProcessTypes = Types;
export default Creators;
