/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CompanyDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const companyDetailResetModelTimestamp = state => ({
  ...state,
  modelTimestamp: Date.now()
});

export const companyDetailSetResId = (state, { resId }) => ({
  ...state,
  modelTimestamp: Date.now(),
  resId
});

export const companyDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const companyDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const companyDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CompanyDetailTypes.COMPANY_DETAIL_RESET_MODEL_TIMESTAMP]: companyDetailResetModelTimestamp,
  [CompanyDetailTypes.COMPANY_DETAIL_SET_RES_ID]: companyDetailSetResId,
  [CompanyDetailTypes.COMPANY_DETAIL_SHOW_MODEL_LOADING]: companyDetailShowModelLoading,
  [CompanyDetailTypes.COMPANY_DETAIL_SHOW_MODEL_SUCCESS]: companyDetailShowModelSuccess,
  [CompanyDetailTypes.COMPANY_DETAIL_UPDATE_MODEL_SUCCESS]: companyDetailUpdateModelSuccess
});
