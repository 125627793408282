import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspCustomerReturnProcessFetchLspCustomerReturnProcess: null,
  lspCustomerReturnProcessFetchLspCustomerReturnProcessLoading: ['boolean'],
  lspCustomerReturnProcessFetchLspCustomerReturnProcessSuccess: ['process']
});

export const LspCustomerReturnProcessTypes = Types;
export default Creators;
