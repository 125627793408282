import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  inbOrd02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrd02GoToDocument: ['hdrId'],
  inbOrd02NewDocument: null,
  inbOrd02FetchInbOrd02: ['currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrd02FetchInbOrd02Loading: ['boolean'],
  inbOrd02FetchInbOrd02Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  inbOrd02AddSelectedDocuments: ['selectedDocuments'],
  inbOrd02RemoveSelectedDocuments: ['selectedDocuments'],
  inbOrd02SetWorkspaceVisible: ['boolean'],
  inbOrd02CreateInbOrd02: ['hdrIds'],
  inbOrd02CreateInbOrd02Loading: ['boolean'],
  inbOrd02CreateInbOrd02Success: ['newDocuments']
});

export const InbOrd02Types = Types;
export default Creators;
