import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  putAwayDetailResetTimestamp: null,
  putAwayDetailSetHdrId: ['hdrId'],

  putAwayDetailInitHeader: null,
  putAwayDetailShowHeader: ['hdrId'],
  putAwayDetailShowHeaderSuccess: ['documentHeader'],

  putAwayDetailUpdateHeader: ['formikBag', 'documentHeader'],
  putAwayDetailCreateHeader: ['formikBag', 'documentHeader'],
  putAwayDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  putAwayDetailShowDocumentLoading: ['boolean'],
  putAwayDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  putAwayDetailShowDetails: ['hdrId'],
  putAwayDetailShowDetailsSuccess: ['documentDetails'],

  putAwayDetailSetDetailVisible: ['boolean'],
  putAwayDetailSetDocumentDetail: ['documentDetail'],

  putAwayDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  putAwayDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  putAwayDetailDeleteDetail: ['hdrId', 'documentDetail'],

  putAwayDetailSetWhseJobType: ['whseJobType'],

  putAwayDetailFetchFrStorageBinOptions: ['search'],
  putAwayDetailFetchFrStorageBinOptionLoading: ['boolean'],
  putAwayDetailFetchFrStorageBinOptionSuccess: ['options'],

  putAwayDetailFetchQuantBalOptions: ['storageBinId', 'search', 'isPallet'],
  putAwayDetailFetchQuantBalOptionLoading: ['boolean'],
  putAwayDetailFetchQuantBalOptionSuccess: ['options'],
  putAwayDetailChangeQuantBal: ['formikBag', 'hdrId', 'quantBalId'],

  putAwayDetailFetchUomOptions: ['itemId', 'search'],
  putAwayDetailFetchUomOptionLoading: ['boolean'],
  putAwayDetailFetchUomOptionSuccess: ['options'],
  putAwayDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  putAwayDetailFetchItemCond01Options: ['search'],
  putAwayDetailFetchItemCond01OptionLoading: ['boolean'],
  putAwayDetailFetchItemCond01OptionSuccess: ['options'],

  putAwayDetailFetchToStorageBinOptions: ['search'],
  putAwayDetailFetchToStorageBinOptionLoading: ['boolean'],
  putAwayDetailFetchToStorageBinOptionSuccess: ['options']
});

export const PutAwayDetailTypes = Types;
export default Creators;
