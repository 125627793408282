/**
 * The initial values for the redux state.
 */

export default {
  lspSlsOrdFulfillmentChartTimestamp: 0,

  lspSlsOrdFulfillmentData: [],
  lspSlsOrdFulfillmentDataIsLoading: false,

  criteria: {
    end_date: new Date(),
    time_period: '7d',
    process: 'daily'
  },

  processOptions: [
    { label: '7 days', value: '7d' },
    { label: '14 days', value: '14d' },
    { label: '21 days', value: '21d' },
    { label: '28 days', value: '28d' }
  ],

  lspSlsOrdFulfillmentChartVisibleAverageKeys: []
};
