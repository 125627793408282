import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspDelOrdDetailResetTimestamp: null,
  lspDelOrdDetailSetHdrId: ['hdrId', 'itemId'],

  lspDelOrdDetailInitHeader: null,
  lspDelOrdDetailShowHeader: ['hdrId'],
  lspDelOrdDetailShowHeaderSuccess: ['documentHeader'],

  lspDelOrdDetailUpdateHeader: ['formikBag', 'documentHeader'],
  lspDelOrdDetailCreateHeader: ['formikBag', 'documentHeader'],
  lspDelOrdDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  lspDelOrdDetailShowDocumentLoading: ['boolean'],
  lspDelOrdDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  lspDelOrdDetailShowDetails: ['hdrId'],
  lspDelOrdDetailShowDetailsSuccess: ['documentDetails'],

  lspDelOrdDetailSetDetailVisible: ['boolean'],
  lspDelOrdDetailSetDocumentDetail: ['documentDetail'],

  lspDelOrdDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  lspDelOrdDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  lspDelOrdDetailDeleteDetail: ['hdrId', 'documentDetail'],

  lspDelOrdDetailFetchSalesmanOptions: ['search'],
  lspDelOrdDetailFetchSalesmanOptionLoading: ['boolean'],
  lspDelOrdDetailFetchSalesmanOptionSuccess: ['options'],

  lspDelOrdDetailFetchDeliveryPointOptions: ['search'],
  lspDelOrdDetailFetchDeliveryPointOptionLoading: ['boolean'],
  lspDelOrdDetailFetchDeliveryPointOptionSuccess: ['options'],
  lspDelOrdDetailChangeDeliveryPoint: ['formikBag', 'deliveryPointId'],

  lspDelOrdDetailFetchCreditTermOptions: ['search'],
  lspDelOrdDetailFetchCreditTermOptionLoading: ['boolean'],
  lspDelOrdDetailFetchCreditTermOptionSuccess: ['options'],

  lspDelOrdDetailFetchCurrencyOptions: ['search'],
  lspDelOrdDetailFetchCurrencyOptionLoading: ['boolean'],
  lspDelOrdDetailFetchCurrencyOptionSuccess: ['options'],
  lspDelOrdDetailChangeCurrency: ['formikBag', 'currencyId'],

  lspDelOrdDetailFetchLocationOptions: ['search'],
  lspDelOrdDetailFetchLocationOptionLoading: ['boolean'],
  lspDelOrdDetailFetchLocationOptionSuccess: ['options'],

  lspDelOrdDetailFetchItemOptions: ['search'],
  lspDelOrdDetailFetchItemOptionLoading: ['boolean'],
  lspDelOrdDetailFetchItemOptionSuccess: ['options'],
  lspDelOrdDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  lspDelOrdDetailFetchUomOptions: ['itemId', 'search'],
  lspDelOrdDetailFetchUomOptionLoading: ['boolean'],
  lspDelOrdDetailFetchUomOptionSuccess: ['options'],
  lspDelOrdDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  lspDelOrdDetailFetchProjectOptions: ['search'],
  lspDelOrdDetailFetchProjectOptionLoading: ['boolean'],
  lspDelOrdDetailFetchProjectOptionSuccess: ['options'],

  lspDelOrdDetailSetPickedIsVisible: ['boolean'],
  lspDelOrdDetailShowPickedLoading: ['boolean'],
  lspDelOrdDetailShowPickedDetails: ['hdrId'],
  lspDelOrdDetailShowPickedDetailsSuccess: ['pickedDetails']
});

export const LspDelOrdDetailTypes = Types;
export default Creators;
