/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { InbOrd05Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const inbOrd05ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
});

export const inbOrd05FetchInbOrd05Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const inbOrd05FetchInbOrd05Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const inbOrd05AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const inbOrd05RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const inbOrd05SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const inbOrd05CreateInbOrd05Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const inbOrd05CreateInbOrd05Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [InbOrd05Types.INB_ORD05_RESET_TIMESTAMP]: inbOrd05ResetTimestamp,
  [InbOrd05Types.INB_ORD05_FETCH_INB_ORD05_LOADING]: inbOrd05FetchInbOrd05Loading,
  [InbOrd05Types.INB_ORD05_FETCH_INB_ORD05_SUCCESS]: inbOrd05FetchInbOrd05Success,
  [InbOrd05Types.INB_ORD05_ADD_SELECTED_DOCUMENTS]: inbOrd05AddSelectedDocuments,
  [InbOrd05Types.INB_ORD05_REMOVE_SELECTED_DOCUMENTS]: inbOrd05RemoveSelectedDocuments,
  [InbOrd05Types.INB_ORD05_SET_WORKSPACE_VISIBLE]: inbOrd05SetWorkspaceVisible,
  [InbOrd05Types.INB_ORD05_CREATE_INB_ORD05_LOADING]: inbOrd05CreateInbOrd05Loading,
  [InbOrd05Types.INB_ORD05_CREATE_INB_ORD05_SUCCESS]: inbOrd05CreateInbOrd05Success
});
