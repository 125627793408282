import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  packListDetailResetTimestamp: null,
  packListDetailSetHdrId: ['hdrId'],

  packListDetailInitHeader: null,
  packListDetailShowHeader: ['hdrId'],
  packListDetailShowHeaderSuccess: ['documentHeader'],

  packListDetailUpdateHeader: ['formikBag', 'documentHeader'],
  packListDetailCreateHeader: ['formikBag', 'documentHeader'],
  packListDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  packListDetailShowDocumentLoading: ['boolean'],
  packListDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus', 'resetMode'],

  packListDetailShowDetails: ['hdrId'],
  packListDetailShowDetailsSuccess: ['documentDetails'],

  packListDetailSetDetailVisible: ['boolean'],
  packListDetailSetDocumentDetail: ['documentDetail'],

  packListDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  packListDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  packListDetailDeleteDetail: ['hdrId', 'documentDetail'],

  packListDetailSetWhseJobType: ['whseJobType'],

  packListDetailFetchCompanyOptions: ['search'],
  packListDetailFetchCompanyOptionLoading: ['boolean'],
  packListDetailFetchCompanyOptionSuccess: ['options'],

  packListDetailFetchFrStorageBinOptions: ['search'],
  packListDetailFetchFrStorageBinOptionLoading: ['boolean'],
  packListDetailFetchFrStorageBinOptionSuccess: ['options'],

  packListDetailFetchQuantBalOptions: ['companyId', 'storageBinId', 'itemId', 'search'],
  packListDetailFetchQuantBalOptionLoading: ['boolean'],
  packListDetailFetchQuantBalOptionSuccess: ['options'],
  packListDetailChangeQuantBal: ['formikBag', 'hdrId', 'quantBalId'],

  packListDetailFetchUomOptions: ['itemId', 'search'],
  packListDetailFetchUomOptionLoading: ['boolean'],
  packListDetailFetchUomOptionSuccess: ['options'],
  packListDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  packListDetailFetchToStorageBinOptions: ['search'],
  packListDetailFetchToStorageBinOptionLoading: ['boolean'],
  packListDetailFetchToStorageBinOptionSuccess: ['options']
});

export const PackListDetailTypes = Types;
export default Creators;
