/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LoadList01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const loadList01FetchLoadList01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const loadList01CreateLoadList01Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const loadList01FetchTransportOptionLoading = (state, { boolean }) => ({
  ...state,
  transportIsLoading: boolean
});

export const loadList01FetchTransportOptionSuccess = (state, { options }) => ({
  ...state,
  transportOptions: options
});

export const loadList01FetchDriver01OptionLoading = (state, { boolean }) => ({
  ...state,
  driver01IsLoading: boolean
});

export const loadList01FetchDriver01OptionSuccess = (state, { options }) => ({
  ...state,
  driver01Options: options
});

export const loadList01FetchDeliveryMan01OptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryMan01IsLoading: boolean
});

export const loadList01FetchDeliveryMan01OptionSuccess = (state, { options }) => ({
  ...state,
  deliveryMan01Options: options
});

export const loadList01FetchDeliveryMan02OptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryMan02IsLoading: boolean
});

export const loadList01FetchDeliveryMan02OptionSuccess = (state, { options }) => ({
  ...state,
  deliveryMan02Options: options
});

export const loadList01SearchDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const loadList01SearchDocumentSuccess = (state, { options }) => ({
  ...state,
  documentOptions: options
});

export const loadList01ScanBarcodeSuccess = (state, { outbOrdHdrs, inbOrdHdrs }) => ({
  ...state,
  outbOrdHdrs,
  inbOrdHdrs
});

export const loadList01UpdateOutbOrds = (state, { outbOrdHdrs }) => ({
  ...state,
  outbOrdTimestamp: Date.now(),
  outbOrdHdrs
});

export const loadList01UpdateInbOrds = (state, { inbOrdHdrs }) => ({
  ...state,
  inbOrdTimestamp: Date.now(),
  inbOrdHdrs
});

export const loadList01CreateLoadList01Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  outbOrdHdrs: [],
  inbOrdHdrs: [],
  newDocuments
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LoadList01Types.LOAD_LIST01_FETCH_LOAD_LIST01_LOADING]: loadList01FetchLoadList01Loading,
  [LoadList01Types.LOAD_LIST01_CREATE_LOAD_LIST01_LOADING]: loadList01CreateLoadList01Loading,
  [LoadList01Types.LOAD_LIST01_CREATE_LOAD_LIST01_SUCCESS]: loadList01CreateLoadList01Success,
  [LoadList01Types.LOAD_LIST01_FETCH_TRANSPORT_OPTION_LOADING]: loadList01FetchTransportOptionLoading,
  [LoadList01Types.LOAD_LIST01_FETCH_TRANSPORT_OPTION_SUCCESS]: loadList01FetchTransportOptionSuccess,
  [LoadList01Types.LOAD_LIST01_FETCH_DRIVER01_OPTION_LOADING]: loadList01FetchDriver01OptionLoading,
  [LoadList01Types.LOAD_LIST01_FETCH_DRIVER01_OPTION_SUCCESS]: loadList01FetchDriver01OptionSuccess,
  [LoadList01Types.LOAD_LIST01_FETCH_DELIVERY_MAN01_OPTION_LOADING]: loadList01FetchDeliveryMan01OptionLoading,
  [LoadList01Types.LOAD_LIST01_FETCH_DELIVERY_MAN01_OPTION_SUCCESS]: loadList01FetchDeliveryMan01OptionSuccess,
  [LoadList01Types.LOAD_LIST01_FETCH_DELIVERY_MAN02_OPTION_LOADING]: loadList01FetchDeliveryMan02OptionLoading,
  [LoadList01Types.LOAD_LIST01_FETCH_DELIVERY_MAN02_OPTION_SUCCESS]: loadList01FetchDeliveryMan02OptionSuccess,
  [LoadList01Types.LOAD_LIST01_SEARCH_DOCUMENT_LOADING]: loadList01SearchDocumentLoading,
  [LoadList01Types.LOAD_LIST01_SEARCH_DOCUMENT_SUCCESS]: loadList01SearchDocumentSuccess,
  [LoadList01Types.LOAD_LIST01_SCAN_BARCODE_SUCCESS]: loadList01ScanBarcodeSuccess,
  [LoadList01Types.LOAD_LIST01_UPDATE_OUTB_ORDS]: loadList01UpdateOutbOrds,
  [LoadList01Types.LOAD_LIST01_UPDATE_INB_ORDS]: loadList01UpdateInbOrds
});
