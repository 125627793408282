import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import PrfDelDetailActions, { PrfDelDetailTypes } from '../Stores/PrfDelDetail/Actions';

const getAppStore = state => state.app;

const getPrfDelDetailStore = state => state.prfDelDetail;

export function* prfDelDetailInitHeader() {
  try {
    yield put(PrfDelDetailActions.prfDelDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `prfDel/initHeader/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(PrfDelDetailActions.prfDelDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PrfDelDetailActions.prfDelDetailShowDocumentLoading(false));
  }
}

export function* prfDelDetailShowHeader({ hdrId }) {
  try {
    yield put(PrfDelDetailActions.prfDelDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `prfDel/showHeader/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(PrfDelDetailActions.prfDelDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PrfDelDetailActions.prfDelDetailShowDocumentLoading(false));
  }
}

export function* prfDelDetailTransitionToStatus({ formikBag, hdrId, docStatus }) {
  formikBag.setSubmitting(true);
  yield put(PrfDelDetailActions.prfDelDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      hdrId,
      docStatus
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'prfDel/transitionToStatus',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const prfDelDetail = yield select(getPrfDelDetailStore);

      const { outbOrdHdrs: oldOutbOrdHdrs } = prfDelDetail;

      const {
        document_header: retPrfDelHdr,
        outb_ord_hdrs: retOutbOrdHdrs,
        deleted_outb_ord_hdrs: retDeletedOutbOrdHdrs
      } = result.data;

      const processedOutbOrd = UtilService.processDetails(
        oldOutbOrdHdrs,
        retOutbOrdHdrs,
        retDeletedOutbOrdHdrs
      );
      const newOutbOrdHdrs = processedOutbOrd.details;

      yield put(
        PrfDelDetailActions.prfDelDetailUpdateDocumentSuccess(retPrfDelHdr, newOutbOrdHdrs)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(PrfDelDetailActions.prfDelDetailShowDocumentLoading(false));
  }
}

export function* prfDelDetailUpdateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'prfDel/updateHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const prfDelDetail = yield select(getPrfDelDetailStore);

      const { outbOrdHdrs: oldOutbOrdHdrs } = prfDelDetail;
      const {
        document_header: retPrfDelHdr,
        outb_ord_hdrs: retOutbOrdHdrs,
        deleted_outb_ord_hdrs: retDeletedOutbOrdHdrs
      } = result.data;

      const processedOutbOrd = UtilService.processDetails(
        oldOutbOrdHdrs,
        retOutbOrdHdrs,
        retDeletedOutbOrdHdrs
      );
      const newOutbOrdHdrs = processedOutbOrd.details;

      yield put(
        PrfDelDetailActions.prfDelDetailUpdateDocumentSuccess(retPrfDelHdr, newOutbOrdHdrs)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* prfDelDetailShowOutbOrdHdrs({ hdrId }) {
  try {
    yield put(PrfDelDetailActions.prfDelDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `prfDel/showOutbOrdHdrs/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(PrfDelDetailActions.prfDelDetailShowOutbOrdHdrsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PrfDelDetailActions.prfDelDetailShowDocumentLoading(false));
  }
}

export function* prfDelDetailCreateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'prfDel/createHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/prfDelDetail/update/${result.data}`));
      yield put(PrfDelDetailActions.prfDelDetailSetHdrId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* prfDelDetailScanBarcode({ hdrId, value }) {
  try {
    yield put(PrfDelDetailActions.prfDelDetailFetchPrfDelDetailLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      hdrId,
      value
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `prfDel/updateDetailsByBarcode`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const prfDelDetail = yield select(getPrfDelDetailStore);

      const { outbOrdHdrs: oldOutbOrdHdrs } = prfDelDetail;
      const {
        document_header: retPrfDelHdr,
        outb_ord_hdrs: retOutbOrdHdrs,
        deleted_outb_ord_hdrs: retDeletedOutbOrdHdrs
      } = result.data;

      const processedOutbOrd = UtilService.processDetails(
        oldOutbOrdHdrs,
        retOutbOrdHdrs,
        retDeletedOutbOrdHdrs
      );
      const newOutbOrdHdrs = processedOutbOrd.details;

      yield put(
        PrfDelDetailActions.prfDelDetailUpdateDocumentSuccess(retPrfDelHdr, newOutbOrdHdrs)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PrfDelDetailActions.prfDelDetailFetchPrfDelDetailLoading(false));
  }
}

export function* prfDelDetailSearchDocument({ search }) {
  if (search.length < 5) {
    return;
  }
  try {
    yield put(PrfDelDetailActions.prfDelDetailSearchDocumentLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `prfDel/searchDocument`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: `${d.res_type}${d.id}`,
        label: `${d.doc_code} ${d.doc_date} ${d.ref_code_01}`,
        docCode: d.doc_code,
        docDate: d.doc_date,
        refCode01: d.ref_code_01
      }));

      yield put(PrfDelDetailActions.prfDelDetailSearchDocumentSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PrfDelDetailActions.prfDelDetailSearchDocumentLoading(false));
  }
}

export function* prfDelDetailUpOutbOrd({ hdrId, outbOrdHdr }) {
  try {
    yield put(PrfDelDetailActions.prfDelDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);

    const prfDelDetail = yield select(getPrfDelDetailStore);
    const { outbOrdHdrs: oldOutbOrdHdrs, inbOrdHdrs: oldInbOrdHdrs } = prfDelDetail;

    const oldIndex = oldOutbOrdHdrs.indexOf(outbOrdHdr);
    const newIndex = oldIndex - 1;
    if (newIndex >= 0) {
      const postData = {
        outbOrdHdrs: [{ outbOrdHdrId: outbOrdHdr.id, lineNo: newIndex + 1, direction: -1 }]
      };

      const result = yield call(
        ApiService.postApi, // function
        app.apiUrl,
        `prfDel/moveOutbOrd/${hdrId}`,
        app.token,
        postData
      );

      if (result.isSuccess === true) {
        const { document_header: retPrfDelHdr, outb_ord_hdrs: retOutbOrdHdrs } = result.data;

        const processedOutbOrd = UtilService.processDetails(oldOutbOrdHdrs, retOutbOrdHdrs, []);
        const newOutbOrdHdrs = processedOutbOrd.details;
        newOutbOrdHdrs.sort((a, b) => (a.line_no < b.line_no ? -1 : 1));

        yield put(
          PrfDelDetailActions.prfDelDetailUpdateDocumentSuccess(
            retPrfDelHdr,
            newOutbOrdHdrs,
            oldInbOrdHdrs
          )
        );
      } else if (result.isTokenExpired === true) {
        yield put(AppActions.appTokenExpired(result.message));
      } else if (result.isPasswordExpired === true) {
        yield put(AppActions.appPasswordExpired(result.message));
      } else {
        yield call(notification.error, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
        });
      }
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PrfDelDetailActions.prfDelDetailShowDocumentLoading(false));
  }
}

export function* prfDelDetailDownOutbOrd({ hdrId, outbOrdHdr }) {
  try {
    yield put(PrfDelDetailActions.prfDelDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);

    const prfDelDetail = yield select(getPrfDelDetailStore);
    const { outbOrdHdrs: oldOutbOrdHdrs, inbOrdHdrs: oldInbOrdHdrs } = prfDelDetail;

    const oldIndex = oldOutbOrdHdrs.indexOf(outbOrdHdr);
    const newIndex = oldIndex + 1;
    if (newIndex < oldOutbOrdHdrs.length) {
      const postData = {
        outbOrdHdrs: [{ outbOrdHdrId: outbOrdHdr.id, lineNo: newIndex + 1, direction: 1 }]
      };

      const result = yield call(
        ApiService.postApi, // function
        app.apiUrl,
        `prfDel/moveOutbOrd/${hdrId}`,
        app.token,
        postData
      );

      if (result.isSuccess === true) {
        const { document_header: retPrfDelHdr, outb_ord_hdrs: retOutbOrdHdrs } = result.data;

        const processedOutbOrd = UtilService.processDetails(oldOutbOrdHdrs, retOutbOrdHdrs, []);
        const newOutbOrdHdrs = processedOutbOrd.details;
        newOutbOrdHdrs.sort((a, b) => (a.line_no < b.line_no ? -1 : 1));

        yield put(
          PrfDelDetailActions.prfDelDetailUpdateDocumentSuccess(
            retPrfDelHdr,
            newOutbOrdHdrs,
            oldInbOrdHdrs
          )
        );
      } else if (result.isTokenExpired === true) {
        yield put(AppActions.appTokenExpired(result.message));
      } else if (result.isPasswordExpired === true) {
        yield put(AppActions.appPasswordExpired(result.message));
      } else {
        yield call(notification.error, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
        });
      }
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PrfDelDetailActions.prfDelDetailShowDocumentLoading(false));
  }
}

export function* prfDelDetailDeleteDetailsByOrdHdrIds({ hdrId, outbOrdHdrIds }) {
  try {
    yield put(PrfDelDetailActions.prfDelDetailFetchPrfDelDetailLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      outbOrdHdrIds
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `prfDel/deleteDetailsByOrdHdrIds`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const prfDelDetail = yield select(getPrfDelDetailStore);

      const { outbOrdHdrs: oldOutbOrdHdrs } = prfDelDetail;
      const {
        document_header: retPrfDelHdr,
        outb_ord_hdrs: retOutbOrdHdrs,
        deleted_outb_ord_hdrs: retDeletedOutbOrdHdrs
      } = result.data;

      const processedOutbOrd = UtilService.processDetails(
        oldOutbOrdHdrs,
        retOutbOrdHdrs,
        retDeletedOutbOrdHdrs
      );
      const newOutbOrdHdrs = processedOutbOrd.details;

      yield put(
        PrfDelDetailActions.prfDelDetailUpdateDocumentSuccess(retPrfDelHdr, newOutbOrdHdrs)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PrfDelDetailActions.prfDelDetailFetchPrfDelDetailLoading(false));
  }
}

export function* prfDelDetailShowDocPhotos({ hdrId }) {
  try {
    yield put(PrfDelDetailActions.prfDelDetailFetchPrfDelDetailLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `prfDel/showDocPhotosNoLogin/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(PrfDelDetailActions.prfDelDetailShowDocPhotosSuccess(result.data.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.warning, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.warning, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PrfDelDetailActions.prfDelDetailFetchPrfDelDetailLoading(false));
  }
}

export function* prfDelDetailDeleteDocPhoto({ hdrId, docPhotoId }) {
  yield put(PrfDelDetailActions.prfDelDetailDocPhotoLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: [{ doc_photo_id: docPhotoId }]
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `prfDel/deleteDocPhotosNoLogin/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const prfDelDetailStore = yield select(PrfDelDetailActions);
      const { deleted_doc_photos: retDeletedDocPhotos } = result.data;

      if (retDeletedDocPhotos.length > 0) {
        yield put(
          PrfDelDetailActions.prfDelDetailShowDocPhotosSuccess(
            prfDelDetailStore.docPhotos.filter(data => data.id !== retDeletedDocPhotos[0].id)
          )
        );
      }

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PrfDelDetailActions.prfDelDetailDocPhotoLoading(false));
  }
}

export const saga = [
  takeLatest(PrfDelDetailTypes.PRF_DEL_DETAIL_INIT_HEADER, prfDelDetailInitHeader),
  takeLatest(PrfDelDetailTypes.PRF_DEL_DETAIL_SHOW_HEADER, prfDelDetailShowHeader),

  takeLatest(PrfDelDetailTypes.PRF_DEL_DETAIL_UPDATE_HEADER, prfDelDetailUpdateHeader),
  takeLatest(PrfDelDetailTypes.PRF_DEL_DETAIL_CREATE_HEADER, prfDelDetailCreateHeader),

  takeLatest(PrfDelDetailTypes.PRF_DEL_DETAIL_TRANSITION_TO_STATUS, prfDelDetailTransitionToStatus),

  takeLatest(PrfDelDetailTypes.PRF_DEL_DETAIL_SHOW_OUTB_ORD_HDRS, prfDelDetailShowOutbOrdHdrs),
  takeLatest(PrfDelDetailTypes.PRF_DEL_DETAIL_SCAN_BARCODE, prfDelDetailScanBarcode),
  takeLatest(PrfDelDetailTypes.PRF_DEL_DETAIL_SEARCH_DOCUMENT, prfDelDetailSearchDocument),

  takeLatest(PrfDelDetailTypes.PRF_DEL_DETAIL_UP_OUTB_ORD, prfDelDetailUpOutbOrd),
  takeLatest(PrfDelDetailTypes.PRF_DEL_DETAIL_DOWN_OUTB_ORD, prfDelDetailDownOutbOrd),

  takeLatest(
    PrfDelDetailTypes.PRF_DEL_DETAIL_DELETE_DETAILS_BY_ORD_HDR_IDS,
    prfDelDetailDeleteDetailsByOrdHdrIds
  ),

  takeLatest(PrfDelDetailTypes.PRF_DEL_DETAIL_SHOW_DOC_PHOTOS, prfDelDetailShowDocPhotos),
  takeLatest(PrfDelDetailTypes.PRF_DEL_DETAIL_DELETE_DOC_PHOTO, prfDelDetailDeleteDocPhoto)
];
