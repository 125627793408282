import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  prodOrdIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prodOrdIndexFetchProdOrdIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prodOrdIndexFetchProdOrdIndexLoading: ['boolean'],
  prodOrdIndexFetchProdOrdIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  prodOrdIndexAddSelectedDocuments: ['selectedDocuments'],
  prodOrdIndexRemoveSelectedDocuments: ['selectedDocuments'],
  prodOrdIndexSetWorkspaceVisible: ['boolean'],
  prodOrdIndexGoToDocument: ['hdrId'],
  prodOrdIndexGoToAudit: ['hdrId'],

  prodOrdIndexWorkspaceLoading: ['boolean'],
  prodOrdIndexResetProdOrdFServ02: ['hdrIds'],
  prodOrdIndexResetProdOrdFServ02Success: null
});

export const ProdOrdIndexTypes = Types;
export default Creators;
