import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsOrdTrackingReportInitSlsOrdTracking: null,
  slsOrdTrackingReportInitSlsOrdTrackingSuccess: ['criteria'],
  slsOrdTrackingReportSlsOrdTracking: ['formikBag', 'criteria'],
  slsOrdTrackingReportSlsOrdTrackingSuccess: ['criteria', 'reportData'],
  slsOrdTrackingReportReportLoading: ['boolean'],

  slsOrdTrackingReportFetchDivisionOptions: ['search'],
  slsOrdTrackingReportFetchDivisionOptionLoading: ['boolean'],
  slsOrdTrackingReportFetchDivisionOptionSuccess: ['options'],

  slsOrdTrackingReportFetchDeliveryPointOptions: ['search'],
  slsOrdTrackingReportFetchDeliveryPointOptionLoading: ['boolean'],
  slsOrdTrackingReportFetchDeliveryPointOptionSuccess: ['options'],

  slsOrdTrackingReportFetchDebtorOptions: ['search'],
  slsOrdTrackingReportFetchDebtorOptionLoading: ['boolean'],
  slsOrdTrackingReportFetchDebtorOptionSuccess: ['options'],

  slsOrdTrackingReportFetchTransportOptions: ['search'],
  slsOrdTrackingReportFetchTransportOptionLoading: ['boolean'],
  slsOrdTrackingReportFetchTransportOptionSuccess: ['options']
});

export const SlsOrdTrackingReportTypes = Types;
export default Creators;
