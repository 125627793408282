/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SummaryBoardTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const slsOrdSummaryBoardResetSlsOrdsTimestamp = (state) => ({
  ...state,
  slsOrdSummarySlsOrdsTimestamp: Date.now()
});
 
export const slsOrdSummaryBoardFetchSlsOrdsLoading = (state, { boolean }) => ({
  ...state,
  slsOrdSummarySlsOrdsIsLoading: boolean
});
 
export const slsOrdSummaryBoardFetchSlsOrdsSuccess = (state, { total, date_oldest }) => ({
  ...state,
  slsOrdSummarySlsOrds: {
    total: total,
    date_oldest: `${date_oldest} days`}
});
 
export const slsOrdSummaryBoardResetOutbOrdsTimestamp = (state) => ({
  ...state,
  slsOrdSummaryOutbOrdsTimestamp: Date.now()
});
 
export const slsOrdSummaryBoardFetchOutbOrdsLoading = (state, { boolean }) => ({
  ...state,
  slsOrdSummaryOutbOrdsIsLoading: boolean
});
  
export const slsOrdSummaryBoardFetchOutbOrdsSuccess = (state, { total, date_oldest } ) => ({
  ...state,
  slsOrdSummaryOutbOrds: {
    total: total,
    date_oldest: `${date_oldest} days`}
});

export const slsOrdSummaryBoardResetInvDocsTimestamp = (state) => ({
  ...state,
  slsOrdSummaryInvDocsTimestamp: Date.now()
});

export const slsOrdSummaryBoardFetchInvDocsLoading = (state, { boolean }) => ({
  ...state,
  slsOrdSummaryInvDocsIsLoading: boolean
});
  
export const slsOrdSummaryBoardFetchInvDocsSuccess = ( state, { total, date_oldest } ) => ({
  ...state,
  slsOrdSummaryInvDocs: {
    total: total,
    date_oldest: `${date_oldest} days`}
});

export const slsOrdSummaryBoardResetNoPrfDelsTimestamp = (state) => ({
  ...state,
  slsOrdSummaryNoPrfDelsTimestamp: Date.now()
});

export const slsOrdSummaryBoardFetchNoPrfDelsLoading = (state, { boolean }) => ({
  ...state,
  slsOrdSummaryNoPrfDelsIsLoading: boolean
});
  
export const slsOrdSummaryBoardFetchNoPrfDelsSuccess = ( state, { total, date_oldest } ) => ({
  ...state,
  slsOrdSummaryNoPrfDels: {
    total: total,
    date_oldest: `${date_oldest} days`}
});

export const lspSlsOrdSummaryBoardResetSlsOrdsTimestamp = (state) => ({
  ...state,
  lspSlsOrdSummarySlsOrdsTimestamp: Date.now()
});

export const lspSlsOrdSummaryBoardFetchSlsOrdsLoading = (state, { boolean }) => ({
  ...state,
  lspSlsOrdSummarySlsOrdsIsLoading: boolean
});
  
export const lspSlsOrdSummaryBoardFetchSlsOrdsSuccess = ( state, { total, date_oldest } ) => ({
  ...state,
  lspSlsOrdSummarySlsOrds: {
    total: total,
    date_oldest: `${date_oldest} days`}
});
  
export const lspSlsOrdSummaryBoardResetOutbOrdsTimestamp = (state) => ({
  ...state,
  lspSlsOrdSummaryOutbOrdsTimestamp: Date.now()
});
  
export const lspSlsOrdSummaryBoardFetchOutbOrdsLoading = (state, { boolean }) => ({
  ...state,
  lspSlsOrdSummaryOutbOrdsIsLoading: boolean
});
   
export const lspSlsOrdSummaryBoardFetchOutbOrdsSuccess = ( state, { total, date_oldest } ) => ({
  ...state,
  lspSlsOrdSummaryOutbOrds: {
    total: total,
    date_oldest: `${date_oldest} days`}
});

export const lspSlsOrdSummaryBoardResetInvDocsTimestamp = (state) => ({
  ...state,
  lspSlsOrdSummaryInvDocsTimestamp: Date.now()
});

export const lspSlsOrdSummaryBoardFetchInvDocsLoading = (state, { boolean }) => ({
  ...state,
  lspSlsOrdSummaryInvDocsIsLoading: boolean
});
   
export const lspSlsOrdSummaryBoardFetchInvDocsSuccess = ( state, { total, date_oldest } ) => ({
  ...state,
  lspSlsOrdSummaryInvDocs: {
    total: total,
    date_oldest: `${date_oldest} days`}
});

export const lspSlsOrdSummaryBoardResetNoPrfDelsTimestamp = (state) => ({
  ...state,
  lspSlsOrdSummaryNoPrfDelsTimestamp: Date.now()
});

export const lspSlsOrdSummaryBoardFetchNoPrfDelsLoading = (state, { boolean }) => ({
  ...state,
  lspSlsOrdSummaryNoPrfDelsIsLoading: boolean
});
   
export const lspSlsOrdSummaryBoardFetchNoPrfDelsSuccess = ( state, { total, date_oldest } ) => ({
  ...state,
  lspSlsOrdSummaryNoPrfDels: {
    total: total,
    date_oldest: `${date_oldest} days`}
});

export const pickListSummaryBoardResetOutbOrdsTimestamp = (state) => ({
  ...state,
  pickListSummaryOutbOrdsTimestamp: Date.now()
});

export const pickListSummaryBoardFetchOutbOrdsLoading = (state, { boolean }) => ({
  ...state,
  pickListSummaryOutbOrdsIsLoading: boolean
});
  
export const pickListSummaryBoardFetchOutbOrdsSuccess = ( state, { total, date_oldest }) => ({
  ...state,
  pickListSummaryOutbOrds: {
    total: total,
    date_oldest: `${date_oldest} days`}
});

export const pickListSummaryBoardResetWipTimestamp = (state) => ({
  ...state,
  pickListSummaryWipTimestamp: Date.now()
});

export const pickListSummaryBoardFetchWipLoading = (state, { boolean }) => ({
  ...state,
  pickListSummaryWipIsLoading: boolean
});
   
export const pickListSummaryBoardFetchWipSuccess = ( state, { total, date_oldest } ) => ({
  ...state,
  pickListSummaryWip: {
    total: total,
    date_oldest: `${date_oldest} days`}
});

export const pickListSummaryBoardResetWhseJobsTimestamp = (state) => ({
  ...state,
  pickListSummaryWhseJobsTimestamp: Date.now()
});

export const pickListSummaryBoardFetchWhseJobsLoading = (state, { boolean }) => ({
  ...state,
  pickListSummaryWhseJobsIsLoading: boolean
});
   
export const pickListSummaryBoardFetchWhseJobsSuccess = ( state, { total, date_oldest } ) => ({
  ...state,
  pickListSummaryWhseJobs: {
    total: total,
    date_oldest: `${date_oldest} days`}
});

export const pickListSummaryBoardResetCompletedTimestamp = (state) => ({
  ...state,
  pickListSummaryCompletedTimestamp: Date.now()
});

export const pickListSummaryBoardFetchCompletedLoading = (state, { boolean }) => ({
  ...state,
  pickListSummaryCompletedIsLoading: boolean
});
   
export const pickListSummaryBoardFetchCompletedSuccess = ( state, { total, date_oldest } ) => ({
  ...state,
  pickListSummaryCompleted: {
    total: total,
    date_oldest: `${date_oldest} days`}
});

export const loadListSummaryBoardResetPendingTimestamp = (state) => ({
  ...state,
  loadListSummaryPendingTimestamp: Date.now()
});

export const loadListSummaryBoardFetchPendingLoading = (state, { boolean }) => ({
  ...state,
  loadListSummaryPendingIsLoading: boolean
});
  
export const loadListSummaryBoardFetchPendingSuccess = ( state, { total, date_oldest } ) => ({
  ...state,
  loadListSummaryPending: {
    total: total,
    date_oldest: `${date_oldest} days`}
});

export const loadListSummaryBoardResetWipTimestamp = (state) => ({
  ...state,
  loadListSummaryWipTimestamp: Date.now()
});

export const loadListSummaryBoardFetchWipLoading = (state, { boolean }) => ({
  ...state,
  loadListSummaryWipIsLoading: boolean
});
   
export const loadListSummaryBoardFetchWipSuccess = ( state, { total, date_oldest } ) => ({
  ...state,
  loadListSummaryWip: {
    total: total,
    date_oldest: `${date_oldest} days`}
});

export const loadListSummaryBoardResetWhseJobsTimestamp = (state) => ({
  ...state,
  loadListSummaryWhseJobsTimestamp: Date.now()
});

export const loadListSummaryBoardFetchWhseJobsLoading = (state, { boolean }) => ({
  ...state,
  loadListSummaryWhseJobsIsLoading: boolean
});
   
export const loadListSummaryBoardFetchWhseJobsSuccess = ( state, { total, date_oldest } ) => ({
  ...state,
  loadListSummaryWhseJobs: {
    total: total,
    date_oldest: `${date_oldest} days`}
});

export const loadListSummaryBoardResetCompletedTimestamp = (state) => ({
  ...state,
  loadListSummaryCompletedTimestamp: Date.now()
});

export const loadListSummaryBoardFetchCompletedLoading = (state, { boolean }) => ({
  ...state,
  loadListSummaryCompletedIsLoading: boolean
});
   
export const loadListSummaryBoardFetchCompletedSuccess = ( state, { total, date_oldest } ) => ({
  ...state,
  loadListSummaryCompleted: {
    total: total,
    date_oldest: `${date_oldest} days`}
});

/**
* @see https://github.com/infinitered/reduxsauce#createreducer
*/
export const reducer = createReducer(INITIAL_STATE, {
  [SummaryBoardTypes.SLS_ORD_SUMMARY_BOARD_RESET_SLS_ORDS_TIMESTAMP]: slsOrdSummaryBoardResetSlsOrdsTimestamp,
  [SummaryBoardTypes.SLS_ORD_SUMMARY_BOARD_FETCH_SLS_ORDS_LOADING]: slsOrdSummaryBoardFetchSlsOrdsLoading,
  [SummaryBoardTypes.SLS_ORD_SUMMARY_BOARD_FETCH_SLS_ORDS_SUCCESS]: slsOrdSummaryBoardFetchSlsOrdsSuccess,

  [SummaryBoardTypes.SLS_ORD_SUMMARY_BOARD_RESET_OUTB_ORDS_TIMESTAMP]: slsOrdSummaryBoardResetOutbOrdsTimestamp,
  [SummaryBoardTypes.SLS_ORD_SUMMARY_BOARD_FETCH_OUTB_ORDS_LOADING]: slsOrdSummaryBoardFetchOutbOrdsLoading,
  [SummaryBoardTypes.SLS_ORD_SUMMARY_BOARD_FETCH_OUTB_ORDS_SUCCESS]: slsOrdSummaryBoardFetchOutbOrdsSuccess,

  [SummaryBoardTypes.SLS_ORD_SUMMARY_BOARD_RESET_INV_DOCS_TIMESTAMP]: slsOrdSummaryBoardResetInvDocsTimestamp,
  [SummaryBoardTypes.SLS_ORD_SUMMARY_BOARD_FETCH_INV_DOCS_LOADING]: slsOrdSummaryBoardFetchInvDocsLoading,
  [SummaryBoardTypes.SLS_ORD_SUMMARY_BOARD_FETCH_INV_DOCS_SUCCESS]: slsOrdSummaryBoardFetchInvDocsSuccess,
  
  [SummaryBoardTypes.SLS_ORD_SUMMARY_BOARD_RESET_NO_PRF_DELS_TIMESTAMP]: slsOrdSummaryBoardResetNoPrfDelsTimestamp,
  [SummaryBoardTypes.SLS_ORD_SUMMARY_BOARD_FETCH_NO_PRF_DELS_LOADING]: slsOrdSummaryBoardFetchNoPrfDelsLoading,
  [SummaryBoardTypes.SLS_ORD_SUMMARY_BOARD_FETCH_NO_PRF_DELS_SUCCESS]: slsOrdSummaryBoardFetchNoPrfDelsSuccess,

  [SummaryBoardTypes.LSP_SLS_ORD_SUMMARY_BOARD_RESET_SLS_ORDS_TIMESTAMP]: lspSlsOrdSummaryBoardResetSlsOrdsTimestamp,
  [SummaryBoardTypes.LSP_SLS_ORD_SUMMARY_BOARD_FETCH_SLS_ORDS_LOADING]: lspSlsOrdSummaryBoardFetchSlsOrdsLoading,
  [SummaryBoardTypes.LSP_SLS_ORD_SUMMARY_BOARD_FETCH_SLS_ORDS_SUCCESS]: lspSlsOrdSummaryBoardFetchSlsOrdsSuccess,

  [SummaryBoardTypes.LSP_SLS_ORD_SUMMARY_BOARD_RESET_OUTB_ORDS_TIMESTAMP]: lspSlsOrdSummaryBoardResetOutbOrdsTimestamp,
  [SummaryBoardTypes.LSP_SLS_ORD_SUMMARY_BOARD_FETCH_OUTB_ORDS_LOADING]: lspSlsOrdSummaryBoardFetchOutbOrdsLoading,
  [SummaryBoardTypes.LSP_SLS_ORD_SUMMARY_BOARD_FETCH_OUTB_ORDS_SUCCESS]: lspSlsOrdSummaryBoardFetchOutbOrdsSuccess,
  
  [SummaryBoardTypes.LSP_SLS_ORD_SUMMARY_BOARD_RESET_INV_DOCS_TIMESTAMP]: lspSlsOrdSummaryBoardResetInvDocsTimestamp,
  [SummaryBoardTypes.LSP_SLS_ORD_SUMMARY_BOARD_FETCH_INV_DOCS_LOADING]: lspSlsOrdSummaryBoardFetchInvDocsLoading,
  [SummaryBoardTypes.LSP_SLS_ORD_SUMMARY_BOARD_FETCH_INV_DOCS_SUCCESS]: lspSlsOrdSummaryBoardFetchInvDocsSuccess,
  
  [SummaryBoardTypes.LSP_SLS_ORD_SUMMARY_BOARD_RESET_NO_PRF_DELS_TIMESTAMP]: lspSlsOrdSummaryBoardResetNoPrfDelsTimestamp,
  [SummaryBoardTypes.LSP_SLS_ORD_SUMMARY_BOARD_FETCH_NO_PRF_DELS_LOADING]: lspSlsOrdSummaryBoardFetchNoPrfDelsLoading,
  [SummaryBoardTypes.LSP_SLS_ORD_SUMMARY_BOARD_FETCH_NO_PRF_DELS_SUCCESS]: lspSlsOrdSummaryBoardFetchNoPrfDelsSuccess,

  [SummaryBoardTypes.PICK_LIST_SUMMARY_BOARD_RESET_OUTB_ORDS_TIMESTAMP]: pickListSummaryBoardResetOutbOrdsTimestamp,
  [SummaryBoardTypes.PICK_LIST_SUMMARY_BOARD_FETCH_OUTB_ORDS_LOADING]: pickListSummaryBoardFetchOutbOrdsLoading,
  [SummaryBoardTypes.PICK_LIST_SUMMARY_BOARD_FETCH_OUTB_ORDS_SUCCESS]: pickListSummaryBoardFetchOutbOrdsSuccess,

  [SummaryBoardTypes.PICK_LIST_SUMMARY_BOARD_RESET_WIP_TIMESTAMP]: pickListSummaryBoardResetWipTimestamp,
  [SummaryBoardTypes.PICK_LIST_SUMMARY_BOARD_FETCH_WIP_LOADING]: pickListSummaryBoardFetchWipLoading,
  [SummaryBoardTypes.PICK_LIST_SUMMARY_BOARD_FETCH_WIP_SUCCESS]: pickListSummaryBoardFetchWipSuccess,
  
  [SummaryBoardTypes.PICK_LIST_SUMMARY_BOARD_RESET_WHSE_JOBS_TIMESTAMP]: pickListSummaryBoardResetWhseJobsTimestamp,
  [SummaryBoardTypes.PICK_LIST_SUMMARY_BOARD_FETCH_WHSE_JOBS_LOADING]: pickListSummaryBoardFetchWhseJobsLoading,
  [SummaryBoardTypes.PICK_LIST_SUMMARY_BOARD_FETCH_WHSE_JOBS_SUCCESS]: pickListSummaryBoardFetchWhseJobsSuccess,
  
  [SummaryBoardTypes.PICK_LIST_SUMMARY_BOARD_RESET_COMPLETED_TIMESTAMP]: pickListSummaryBoardResetCompletedTimestamp,
  [SummaryBoardTypes.PICK_LIST_SUMMARY_BOARD_FETCH_COMPLETED_LOADING]: pickListSummaryBoardFetchCompletedLoading,
  [SummaryBoardTypes.PICK_LIST_SUMMARY_BOARD_FETCH_COMPLETED_SUCCESS]: pickListSummaryBoardFetchCompletedSuccess,

  [SummaryBoardTypes.LOAD_LIST_SUMMARY_BOARD_RESET_PENDING_TIMESTAMP]: loadListSummaryBoardResetPendingTimestamp,
  [SummaryBoardTypes.LOAD_LIST_SUMMARY_BOARD_FETCH_PENDING_LOADING]: loadListSummaryBoardFetchPendingLoading,
  [SummaryBoardTypes.LOAD_LIST_SUMMARY_BOARD_FETCH_PENDING_SUCCESS]: loadListSummaryBoardFetchPendingSuccess,
  
  [SummaryBoardTypes.LOAD_LIST_SUMMARY_BOARD_RESET_WIP_TIMESTAMP]: loadListSummaryBoardResetWipTimestamp,
  [SummaryBoardTypes.LOAD_LIST_SUMMARY_BOARD_FETCH_WIP_LOADING]: loadListSummaryBoardFetchWipLoading,
  [SummaryBoardTypes.LOAD_LIST_SUMMARY_BOARD_FETCH_WIP_SUCCESS]: loadListSummaryBoardFetchWipSuccess,

  [SummaryBoardTypes.LOAD_LIST_SUMMARY_BOARD_RESET_WHSE_JOBS_TIMESTAMP]: loadListSummaryBoardResetWhseJobsTimestamp,
  [SummaryBoardTypes.LOAD_LIST_SUMMARY_BOARD_FETCH_WHSE_JOBS_LOADING]: loadListSummaryBoardFetchWhseJobsLoading,
  [SummaryBoardTypes.LOAD_LIST_SUMMARY_BOARD_FETCH_WHSE_JOBS_SUCCESS]: loadListSummaryBoardFetchWhseJobsSuccess,

  [SummaryBoardTypes.LOAD_LIST_SUMMARY_BOARD_RESET_COMPLETED_TIMESTAMP]: loadListSummaryBoardResetCompletedTimestamp,
  [SummaryBoardTypes.LOAD_LIST_SUMMARY_BOARD_FETCH_COMPLETED_LOADING]: loadListSummaryBoardFetchCompletedLoading,
  [SummaryBoardTypes.LOAD_LIST_SUMMARY_BOARD_FETCH_COMPLETED_SUCCESS]: loadListSummaryBoardFetchCompletedSuccess
});
 