/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StkRcptDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const stkRcptDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const stkRcptDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  salesmanOptions: [],
  deliveryPointOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const stkRcptDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const stkRcptDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader,
  currencyOptions: [],
  frDivisionOptions: [],
  frLocationOptions: [],
  itemOptions: [],
  uomOptions: [],
  locationOptions: []
});

export const stkRcptDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const stkRcptDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const stkRcptDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const stkRcptDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  itemOptions: [],
  uomOptions: []
});

export const stkRcptDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const stkRcptDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

export const stkRcptDetailFetchFrDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  frDivisionIsLoading: boolean
});

export const stkRcptDetailFetchFrDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  frDivisionOptions: options
});

export const stkRcptDetailFetchFrLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  frLocationIsLoading: boolean
});

export const stkRcptDetailFetchFrLocationOptionSuccess = (state, { options }) => ({
  ...state,
  frLocationOptions: options
});

export const stkRcptDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const stkRcptDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const stkRcptDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const stkRcptDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const stkRcptDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const stkRcptDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const stkRcptDetailFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const stkRcptDetailFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const stkRcptDetailChangeFrDivision = state => {
  // reset the select2 cache

  return {
    ...state,
    frLocationOptions: []
  };
};

export const stkRcptDetailFetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const stkRcptDetailFetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StkRcptDetailTypes.STK_RCPT_DETAIL_RESET_TIMESTAMP]: stkRcptDetailResetTimestamp,
  [StkRcptDetailTypes.STK_RCPT_DETAIL_SET_HDR_ID]: stkRcptDetailSetHdrId,

  [StkRcptDetailTypes.STK_RCPT_DETAIL_SHOW_HEADER_SUCCESS]: stkRcptDetailShowHeaderSuccess,

  [StkRcptDetailTypes.STK_RCPT_DETAIL_UPDATE_DOCUMENT_SUCCESS]: stkRcptDetailUpdateDocumentSuccess,

  [StkRcptDetailTypes.STK_RCPT_DETAIL_SHOW_DOCUMENT_LOADING]: stkRcptDetailShowDocumentLoading,

  [StkRcptDetailTypes.STK_RCPT_DETAIL_SHOW_DETAILS_SUCCESS]: stkRcptDetailShowDetailsSuccess,

  [StkRcptDetailTypes.STK_RCPT_DETAIL_SET_DETAIL_VISIBLE]: stkRcptDetailSetDetailVisible,
  [StkRcptDetailTypes.STK_RCPT_DETAIL_SET_DOCUMENT_DETAIL]: stkRcptDetailSetDocumentDetail,

  [StkRcptDetailTypes.STK_RCPT_DETAIL_UPDATE_DETAILS]: stkRcptDetailUpdateDetails,
  [StkRcptDetailTypes.STK_RCPT_DETAIL_CREATE_DETAIL]: stkRcptDetailCreateDetail,

  [StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: stkRcptDetailFetchCurrencyOptionLoading,
  [StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: stkRcptDetailFetchCurrencyOptionSuccess,

  [StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_FR_DIVISION_OPTION_LOADING]: stkRcptDetailFetchFrDivisionOptionLoading,
  [StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_FR_DIVISION_OPTION_SUCCESS]: stkRcptDetailFetchFrDivisionOptionSuccess,

  [StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_FR_LOCATION_OPTION_LOADING]: stkRcptDetailFetchFrLocationOptionLoading,
  [StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_FR_LOCATION_OPTION_SUCCESS]: stkRcptDetailFetchFrLocationOptionSuccess,
  [StkRcptDetailTypes.STK_RCPT_DETAIL_CHANGE_FR_DIVISION]: stkRcptDetailChangeFrDivision,

  [StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_ITEM_OPTION_LOADING]: stkRcptDetailFetchItemOptionLoading,
  [StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: stkRcptDetailFetchItemOptionSuccess,

  [StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_UOM_OPTION_LOADING]: stkRcptDetailFetchUomOptionLoading,
  [StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_UOM_OPTION_SUCCESS]: stkRcptDetailFetchUomOptionSuccess,

  [StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_LOCATION_OPTION_LOADING]: stkRcptDetailFetchLocationOptionLoading,
  [StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_LOCATION_OPTION_SUCCESS]: stkRcptDetailFetchLocationOptionSuccess,

  [StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_PROJECT_OPTION_LOADING]: stkRcptDetailFetchProjectOptionLoading,
  [StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_PROJECT_OPTION_SUCCESS]: stkRcptDetailFetchProjectOptionSuccess
});
