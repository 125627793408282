import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  trfOrdDetailResetTimestamp: null,
  trfOrdDetailSetHdrId: ['hdrId', 'itemId'],

  trfOrdDetailInitHeader: ['docSubType'],
  trfOrdDetailShowHeader: ['hdrId'],
  trfOrdDetailShowHeaderSuccess: ['documentHeader'],

  trfOrdDetailUpdateHeader: ['formikBag', 'documentHeader'],
  trfOrdDetailCreateHeader: ['formikBag', 'documentHeader'],
  trfOrdDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  trfOrdDetailShowDocumentLoading: ['boolean'],
  trfOrdDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  trfOrdDetailShowDetails: ['hdrId'],
  trfOrdDetailShowDetailsSuccess: ['documentDetails'],

  trfOrdDetailSetDetailVisible: ['boolean'],
  trfOrdDetailSetDocumentDetail: ['documentDetail'],

  trfOrdDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  trfOrdDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  trfOrdDetailDeleteDetail: ['hdrId', 'documentDetail'],

  trfOrdDetailFetchCurrencyOptions: ['search'],
  trfOrdDetailFetchCurrencyOptionLoading: ['boolean'],
  trfOrdDetailFetchCurrencyOptionSuccess: ['options'],
  trfOrdDetailChangeCurrency: ['formikBag', 'currencyId'],

  trfOrdDetailFetchToDivisionOptions: ['docSubType', 'search'],
  trfOrdDetailFetchToDivisionOptionLoading: ['boolean'],
  trfOrdDetailFetchToDivisionOptionSuccess: ['options'],
  trfOrdDetailChangeToDivision: ['formikBag', 'toDivisionId'],

  trfOrdDetailFetchToLocationOptions: ['hdrId', 'docSubType', 'search'],
  trfOrdDetailFetchToLocationOptionLoading: ['boolean'],
  trfOrdDetailFetchToLocationOptionSuccess: ['options'],

  trfOrdDetailFetchHdrToLocationOptions: ['hdrId', 'docSubType', 'search'],
  trfOrdDetailFetchHdrToLocationOptionLoading: ['boolean'],
  trfOrdDetailFetchHdrToLocationOptionSuccess: ['options'],

  trfOrdDetailFetchLocationOptions: ['docSubType', 'search'],
  trfOrdDetailFetchLocationOptionLoading: ['boolean'],
  trfOrdDetailFetchLocationOptionSuccess: ['options'],

  trfOrdDetailFetchItemOptions: ['search'],
  trfOrdDetailFetchItemOptionLoading: ['boolean'],
  trfOrdDetailFetchItemOptionSuccess: ['options'],
  trfOrdDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  trfOrdDetailFetchItemBatchOptions: ['itemId', 'search'],
  trfOrdDetailFetchItemBatchOptionLoading: ['boolean'],
  trfOrdDetailFetchItemBatchOptionSuccess: ['options'],
  trfOrdDetailChangeItemBatch: ['formikBag', 'hdrId', 'itemBatchId'],

  trfOrdDetailFetchUomOptions: ['itemId', 'search'],
  trfOrdDetailFetchUomOptionLoading: ['boolean'],
  trfOrdDetailFetchUomOptionSuccess: ['options'],
  trfOrdDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  trfOrdDetailFetchProjectOptions: ['search'],
  trfOrdDetailFetchProjectOptionLoading: ['boolean'],
  trfOrdDetailFetchProjectOptionSuccess: ['options']
});

export const TrfOrdDetailTypes = Types;
export default Creators;
