import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  workerPutAwayAnalysisReportInitWorkerPutAwayAnalysis: null,
  workerPutAwayAnalysisReportInitWorkerPutAwayAnalysisSuccess: ['criteria'],
  workerPutAwayAnalysisReportWorkerPutAwayAnalysis: ['formikBag', 'criteria'],
  workerPutAwayAnalysisReportWorkerPutAwayAnalysisSuccess: ['criteria', 'reportData'],
  workerPutAwayAnalysisReportReportLoading: ['boolean'],

  workerPutAwayAnalysisReportFetchUserOptions: ['search'],
  workerPutAwayAnalysisReportFetchUserOptionLoading: ['boolean'],
  workerPutAwayAnalysisReportFetchUserOptionSuccess: ['options']
});

export const WorkerPutAwayAnalysisReportTypes = Types;
export default Creators;
