import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadList01FetchLoadList01Loading: ['boolean'],
  loadList01CreateLoadList01Loading: ['boolean'],

  loadList01ScanBarcode: ['value'],
  loadList01ScanBarcodeSuccess: ['outbOrdHdrs', 'inbOrdHdrs'],
  loadList01ScanBarcodeError: ['value'],

  loadList01FetchTransportOptions: ['search'],
  loadList01FetchTransportOptionLoading: ['boolean'],
  loadList01FetchTransportOptionSuccess: ['options'],
  loadList01ChangeTransport: ['formikBag', 'hdrId', 'itemId'],

  loadList01FetchDriver01Options: ['search'],
  loadList01FetchDriver01OptionLoading: ['boolean'],
  loadList01FetchDriver01OptionSuccess: ['options'],

  loadList01FetchDeliveryMan01Options: ['search'],
  loadList01FetchDeliveryMan01OptionLoading: ['boolean'],
  loadList01FetchDeliveryMan01OptionSuccess: ['options'],

  loadList01FetchDeliveryMan02Options: ['search'],
  loadList01FetchDeliveryMan02OptionLoading: ['boolean'],
  loadList01FetchDeliveryMan02OptionSuccess: ['options'],

  loadList01SearchDocument: ['search'],
  loadList01SearchDocumentLoading: ['boolean'],
  loadList01SearchDocumentSuccess: ['options'],

  loadList01CreateLoadList01: ['formikBag', 'formDetail', 'outbOrdHdrs', 'inbOrdHdrs'],
  loadList01CreateLoadList01Success: ['newDocuments'],

  loadList01UpOutbOrd: ['outbOrdHdr'],
  loadList01DownOutbOrd: ['outbOrdHdr'],
  loadList01UpdateOutbOrds: ['outbOrdHdrs'],

  loadList01UpInbOrd: ['inbOrdHdr'],
  loadList01DownInbOrd: ['inbOrdHdr'],
  loadList01UpdateInbOrds: ['inbOrdHdrs']
});

export const LoadList01Types = Types;
export default Creators;
