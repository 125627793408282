import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import DeliveryPointDetailActions, {
  DeliveryPointDetailTypes
} from '../Stores/DeliveryPointDetail/Actions';

const getAppStore = state => state.app;

export function* deliveryPointDetailInitModel({ debtorId }) {
  try {
    yield put(DeliveryPointDetailActions.deliveryPointDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `deliveryPoint/initModel/${debtorId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DeliveryPointDetailActions.deliveryPointDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DeliveryPointDetailActions.deliveryPointDetailShowModelLoading(false));
  }
}

export function* deliveryPointDetailShowModel({ resId }) {
  try {
    yield put(DeliveryPointDetailActions.deliveryPointDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `deliveryPoint/showModel/${resId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DeliveryPointDetailActions.deliveryPointDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DeliveryPointDetailActions.deliveryPointDetailShowModelLoading(false));
  }
}

export function* deliveryPointDetailUpdateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'deliveryPoint/updateModel',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const { model: retModel } = result.data;

      yield put(DeliveryPointDetailActions.deliveryPointDetailUpdateModelSuccess(retModel));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* deliveryPointDetailCreateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `deliveryPoint/createModel`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/deliveryPointDetail/update/${result.data}`));
      yield put(DeliveryPointDetailActions.deliveryPointDetailSetResId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* deliveryPointDetailFetchDebtorGroup01Options({ search }) {
  try {
    yield put(DeliveryPointDetailActions.deliveryPointDetailFetchDebtorGroup01OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `debtorGroup01/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(
        DeliveryPointDetailActions.deliveryPointDetailFetchDebtorGroup01OptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DeliveryPointDetailActions.deliveryPointDetailFetchDebtorGroup01OptionLoading(false));
  }
}

export function* deliveryPointDetailFetchDebtorGroup02Options({ search }) {
  try {
    yield put(DeliveryPointDetailActions.deliveryPointDetailFetchDebtorGroup02OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `debtorGroup02/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(
        DeliveryPointDetailActions.deliveryPointDetailFetchDebtorGroup02OptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DeliveryPointDetailActions.deliveryPointDetailFetchDebtorGroup02OptionLoading(false));
  }
}

export function* deliveryPointDetailFetchDebtorGroup03Options({ search }) {
  try {
    yield put(DeliveryPointDetailActions.deliveryPointDetailFetchDebtorGroup03OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `debtorGroup03/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(
        DeliveryPointDetailActions.deliveryPointDetailFetchDebtorGroup03OptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DeliveryPointDetailActions.deliveryPointDetailFetchDebtorGroup03OptionLoading(false));
  }
}

export function* deliveryPointDetailFetchAreaOptions({ search }) {
  try {
    yield put(DeliveryPointDetailActions.deliveryPointDetailFetchAreaOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `area/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(DeliveryPointDetailActions.deliveryPointDetailFetchAreaOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DeliveryPointDetailActions.deliveryPointDetailFetchAreaOptionLoading(false));
  }
}

export const saga = [
  takeLatest(
    DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_INIT_MODEL,
    deliveryPointDetailInitModel
  ),
  takeLatest(
    DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_SHOW_MODEL,
    deliveryPointDetailShowModel
  ),

  takeLatest(
    DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_UPDATE_MODEL,
    deliveryPointDetailUpdateModel
  ),
  takeLatest(
    DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_CREATE_MODEL,
    deliveryPointDetailCreateModel
  ),

  takeLatest(
    DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_FETCH_DEBTOR_GROUP01_OPTIONS,
    deliveryPointDetailFetchDebtorGroup01Options
  ),
  takeLatest(
    DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_FETCH_DEBTOR_GROUP02_OPTIONS,
    deliveryPointDetailFetchDebtorGroup02Options
  ),
  takeLatest(
    DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_FETCH_DEBTOR_GROUP03_OPTIONS,
    deliveryPointDetailFetchDebtorGroup03Options
  ),

  takeLatest(
    DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_FETCH_AREA_OPTIONS,
    deliveryPointDetailFetchAreaOptions
  )
];
