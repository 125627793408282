import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  deliveryPointProcessFetchDeliveryPointProcess: null,
  deliveryPointProcessFetchDeliveryPointProcessLoading: ['boolean'],
  deliveryPointProcessFetchDeliveryPointProcessSuccess: ['process']
});

export const DeliveryPointProcessTypes = Types;
export default Creators;
