import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography } from 'antd';
import {
  FormikSelect,
  FormikButton,
  FormikText,
  FormikTextNumber,
  FormikInputNumber
} from '../../Components/Formik';
import WhseJob17DetailActions from '../../Stores/WhseJob17Detail/Actions';

const { Text } = Typography;

class WhseJobType17Form extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchStorageBin = this.handleSearchStorageBin.bind(this);
    this.handleSearchQuantBal = this.handleSearchQuantBal.bind(this);
    this.handleSearchUom = this.handleSearchUom.bind(this);
    this.handleSearchToStorageBin = this.handleSearchToStorageBin.bind(this);
    this.handleSearchToHandlingUnit = this.handleSearchToHandlingUnit.bind(this);

    this.handleChangeStorageBin = this.handleChangeStorageBin.bind(this);
    this.handleChangeQuantBal = this.handleChangeQuantBal.bind(this);
    this.handleChangeUom = this.handleChangeUom.bind(this);
    this.handleChangeToStorageBin = this.handleChangeToStorageBin.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCompanySearch(form, value) {
    const { fetchCompanyOptions } = this.props;

    fetchCompanyOptions(value);
  }

  handleSearchStorageBin(form, value) {
    const { whseJobType, fetchFrStorageBinOptions } = this.props;

    fetchFrStorageBinOptions(whseJobType, value);
  }

  handleSearchQuantBal(form, value) {
    const { fetchQuantBalOptions } = this.props;
    const { values } = form;

    fetchQuantBalOptions(values.storage_bin_select2.value, value);
  }

  handleSearchUom(form, value) {
    const { fetchUomOptions } = this.props;
    const { values } = form;

    fetchUomOptions(values.item_id, value);
  }

  handleSearchToStorageBin(form, value) {
    const { whseJobType, fetchToStorageBinOptions } = this.props;

    fetchToStorageBinOptions(whseJobType, value);
  }

  handleSearchToHandlingUnit(form, value) {
    const { fetchToHandlingUnitOptions } = this.props;
    const { values } = form;

    fetchToHandlingUnitOptions(values.to_storage_bin_select2.value, value);
  }

  handleChangeStorageBin(form) {
    // params: form, value, option

    const { fetchQuantBalOptionSuccess, fetchUomOptionSuccess } = this.props;
    const { values: curValues, setFieldValue } = form;

    if ('storage_bin_select2' in curValues) {
      setFieldValue('quant_bal_select2', {
        value: 0,
        label: ''
      });
      setFieldValue('handling_unit_barcode', '');
      setFieldValue('item_code', '');
      setFieldValue('item_desc_01', '');
      setFieldValue('item_desc_02', '');

      fetchQuantBalOptionSuccess([]);
      fetchUomOptionSuccess([]);
    }
  }

  handleChangeQuantBal(form, value) {
    // params: form, value, option

    const { hdrId, changeQuantBal } = this.props;

    changeQuantBal(form, hdrId, value);
  }

  handleChangeUom(form, value) {
    // params: form, value, option
    const { hdrId, changeUom } = this.props;
    const { values } = form;

    changeUom(form, hdrId, values.item_select2.value, value);
  }

  handleChangeToStorageBin(form) {
    // params: form, value, option
    const { fetchToHandlingUnitOptionSuccess } = this.props;
    const { setFieldValue } = form;

    setFieldValue('to_handling_unit_select2', {
      value: 0,
      label: ''
    });
    fetchToHandlingUnitOptionSuccess([]);
  }

  render() {
    const {
      intl,
      hdrId,
      documentHeader,
      documentDetail,
      updateDetails,
      createDetail,
      documentIsLoading,
      frStorageBinIsLoading,
      frStorageBinOptions,
      quantBalIsLoading,
      quantBalOptions,
      uomIsLoading,
      uomOptions,
      toStorageBinIsLoading,
      toStorageBinOptions,
      toHandlingUnitIsLoading,
      toHandlingUnitOptions
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;
    return (
      <Formik
        enableReinitialize
        initialValues={documentDetail}
        onSubmit={(values, formikBag) => {
          // remove the submit_action field
          const processedValues = { ...values, whse_job_type: 17 };
          delete processedValues.submit_action;

          if (values.id > 0) {
            updateDetails(formikBag, hdrId, [processedValues]);
          } else {
            createDetail(formikBag, hdrId, processedValues);
          }
        }}
        validationSchema={Yup.object().shape({
          storage_bin_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'from_storage_bin_is_required' }))
          }),
          quant_bal_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'quant_bal_is_required' }))
          }),
          uom_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'uom_is_required' }))
          }),
          qty: Yup.number().moreThan(
            0,
            intl.formatMessage({ id: 'item_qty_need_to_be_more_than_0' })
          ),
          to_storage_bin_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'to_storage_bin_is_required' }))
          }),
          to_handling_unit_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'to_handling_unit_is_required' }))
          })
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'from_storage_bin' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="storage_bin_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchStorageBin}
                  notFoundContent={null}
                  loading={frStorageBinIsLoading}
                  options={frStorageBinOptions}
                  disabled={disabled}
                  onChange={this.handleChangeStorageBin}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'quant_bal' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="quant_bal_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchQuantBal}
                  notFoundContent={null}
                  loading={quantBalIsLoading}
                  options={quantBalOptions}
                  disabled={disabled}
                  onChange={this.handleChangeQuantBal}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'pallet_id' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="handling_unit_barcode" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item_code' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="item_code" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item_desc' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="desc_01" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text />
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="desc_02" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'batch_serial_no' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="batch_serial_no" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'expiry_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="expiry_date" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'receipt_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="receipt_date" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'qty' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="qty"
                  component={FormikInputNumber}
                  precision={values.qty_scale || 0}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text />
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={10}>
                <Field
                  name="uom_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchUom}
                  notFoundContent={null}
                  loading={uomIsLoading}
                  options={uomOptions}
                  disabled={disabled}
                  onChange={this.handleChangeUom}
                />
              </Col>
              <Col span={10}>
                <Field
                  name="uom_rate"
                  component={FormikTextNumber}
                  minimumfractiondigits={6}
                  maximumfractiondigits={6}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'to_storage_bin' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="to_storage_bin_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchToStorageBin}
                  notFoundContent={null}
                  loading={toStorageBinIsLoading}
                  options={toStorageBinOptions}
                  disabled={disabled}
                  onChange={this.handleChangeToStorageBin}
                />
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'to_pallet_id' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="to_handling_unit_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchToHandlingUnit}
                  notFoundContent={null}
                  loading={toHandlingUnitIsLoading}
                  options={toHandlingUnitOptions}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="end" gutter={[0, 16]}>
              <Col span={5}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  type="primary"
                  name="submit_action"
                  value="update"
                  component={FormikButton}
                  disabled={disabled}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: values.id > 0 ? 'update' : 'create'
                  })}
                  icon={<SaveOutlined />}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

WhseJobType17Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  updateDetails: PropTypes.func,
  createDetail: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentDetail: PropTypes.shape({}),
  documentIsLoading: PropTypes.bool,

  fetchCompanyOptions: PropTypes.func,

  fetchFrStorageBinOptions: PropTypes.func,
  frStorageBinIsLoading: PropTypes.bool,
  frStorageBinOptions: PropTypes.arrayOf(PropTypes.object),

  fetchQuantBalOptions: PropTypes.func,
  fetchQuantBalOptionSuccess: PropTypes.func,
  changeQuantBal: PropTypes.func,
  quantBalIsLoading: PropTypes.bool,
  quantBalOptions: PropTypes.arrayOf(PropTypes.object),

  fetchUomOptions: PropTypes.func,
  fetchUomOptionSuccess: PropTypes.func,
  changeUom: PropTypes.func,
  uomIsLoading: PropTypes.bool,
  uomOptions: PropTypes.arrayOf(PropTypes.object),

  fetchToStorageBinOptions: PropTypes.func,
  toStorageBinIsLoading: PropTypes.bool,
  toStorageBinOptions: PropTypes.arrayOf(PropTypes.object),

  fetchToHandlingUnitOptions: PropTypes.func,
  fetchToHandlingUnitOptionSuccess: PropTypes.func,
  toHandlingUnitIsLoading: PropTypes.bool,
  toHandlingUnitOptions: PropTypes.arrayOf(PropTypes.object),

  whseJobType: PropTypes.number
};

WhseJobType17Form.defaultProps = {
  intl: {},
  updateDetails() {},
  createDetail() {},
  hdrId: 0,
  documentHeader: { doc_status: 3 },
  documentDetail: {},
  documentIsLoading: false,

  fetchCompanyOptions() {},

  fetchFrStorageBinOptions() {},
  frStorageBinIsLoading: false,
  frStorageBinOptions: [],

  fetchQuantBalOptions() {},
  fetchQuantBalOptionSuccess() {},
  quantBalIsLoading: false,
  quantBalOptions: [],
  changeQuantBal() {},

  fetchUomOptions() {},
  fetchUomOptionSuccess() {},
  changeUom() {},
  uomIsLoading: false,
  uomOptions: [],

  fetchToStorageBinOptions() {},
  toStorageBinIsLoading: false,
  toStorageBinOptions: [],

  fetchToHandlingUnitOptions() {},
  fetchToHandlingUnitOptionSuccess() {},
  toHandlingUnitIsLoading: false,
  toHandlingUnitOptions: [],

  whseJobType: 0
};

const mapStateToProps = state => ({
  hdrId: state.whseJob17Detail.hdrId,
  documentHeader: state.whseJob17Detail.documentHeader,
  documentDetail: state.whseJob17Detail.documentDetail,
  documentIsLoading: state.whseJob17Detail.documentIsLoading,

  frStorageBinIsLoading: state.whseJob17Detail.frStorageBinIsLoading,
  frStorageBinOptions: state.whseJob17Detail.frStorageBinOptions,

  quantBalIsLoading: state.whseJob17Detail.quantBalIsLoading,
  quantBalOptions: state.whseJob17Detail.quantBalOptions,

  uomIsLoading: state.whseJob17Detail.uomIsLoading,
  uomOptions: state.whseJob17Detail.uomOptions,

  toStorageBinIsLoading: state.whseJob17Detail.toStorageBinIsLoading,
  toStorageBinOptions: state.whseJob17Detail.toStorageBinOptions,

  toHandlingUnitIsLoading: state.whseJob17Detail.toHandlingUnitIsLoading,
  toHandlingUnitOptions: state.whseJob17Detail.toHandlingUnitOptions,

  whseJobType: state.whseJob17Detail.whseJobType
});

const mapDispatchToProps = dispatch => ({
  updateDetails: (formikBag, hdrId, documentDetails) =>
    dispatch(
      WhseJob17DetailActions.whseJob17DetailUpdateDetails(formikBag, hdrId, documentDetails)
    ),
  createDetail: (formikBag, hdrId, documentDetail) =>
    dispatch(WhseJob17DetailActions.whseJob17DetailCreateDetail(formikBag, hdrId, documentDetail)),

  fetchFrStorageBinOptions: (whseJobType, search) =>
    dispatch(WhseJob17DetailActions.whseJob17DetailFetchFrStorageBinOptions(whseJobType, search)),

  fetchToStorageBinOptions: (whseJobType, search) =>
    dispatch(WhseJob17DetailActions.whseJob17DetailFetchToStorageBinOptions(whseJobType, search)),

  fetchToHandlingUnitOptions: (storageBinId, search) =>
    dispatch(
      WhseJob17DetailActions.whseJob17DetailFetchToHandlingUnitOptions(storageBinId, search)
    ),
  fetchToHandlingUnitOptionSuccess: options =>
    dispatch(WhseJob17DetailActions.whseJob17DetailFetchToHandlingUnitOptionSuccess(options)),

  fetchUomOptions: (itemId, search) =>
    dispatch(WhseJob17DetailActions.whseJob17DetailFetchUomOptions(itemId, search)),
  fetchUomOptionSuccess: options =>
    dispatch(WhseJob17DetailActions.whseJob17DetailFetchUomOptionSuccess(options)),
  changeUom: (formikBag, hdrId, itemId, uomId) =>
    dispatch(WhseJob17DetailActions.whseJob17DetailChangeUom(formikBag, hdrId, itemId, uomId)),

  fetchQuantBalOptions: (storageBinId, search) =>
    dispatch(WhseJob17DetailActions.whseJob17DetailFetchQuantBalOptions(storageBinId, search)),
  fetchQuantBalOptionSuccess: options =>
    dispatch(WhseJob17DetailActions.whseJob17DetailFetchQuantBalOptionSuccess(options)),
  changeQuantBal: (formikBag, hdrId, quantBalId) =>
    dispatch(WhseJob17DetailActions.whseJob17DetailChangeQuantBal(formikBag, hdrId, quantBalId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WhseJobType17Form));
