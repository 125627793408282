/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { BinTrf03Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const binTrf03ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
});

export const binTrf03FetchBinTrf03Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const binTrf03FetchBinTrf03Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const binTrf03AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const binTrf03RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const binTrf03SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const binTrf03CreateBinTrf03Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const binTrf03CreateBinTrf03Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [BinTrf03Types.BIN_TRF03_RESET_TIMESTAMP]: binTrf03ResetTimestamp,
  [BinTrf03Types.BIN_TRF03_FETCH_BIN_TRF03_LOADING]: binTrf03FetchBinTrf03Loading,
  [BinTrf03Types.BIN_TRF03_FETCH_BIN_TRF03_SUCCESS]: binTrf03FetchBinTrf03Success,
  [BinTrf03Types.BIN_TRF03_ADD_SELECTED_DOCUMENTS]: binTrf03AddSelectedDocuments,
  [BinTrf03Types.BIN_TRF03_REMOVE_SELECTED_DOCUMENTS]: binTrf03RemoveSelectedDocuments,
  [BinTrf03Types.BIN_TRF03_SET_WORKSPACE_VISIBLE]: binTrf03SetWorkspaceVisible,
  [BinTrf03Types.BIN_TRF03_CREATE_BIN_TRF03_LOADING]: binTrf03CreateBinTrf03Loading,
  [BinTrf03Types.BIN_TRF03_CREATE_BIN_TRF03_SUCCESS]: binTrf03CreateBinTrf03Success
});
