import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { EditOutlined, LaptopOutlined, ReloadOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button, Badge, Card } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import CycleCount02Actions from '../../Stores/CycleCount02/Actions';

import CycleCount02Dialog from './CycleCount02Dialog';
import UtilService from '../../Services/UtilService';

class CycleCount02Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.getExpandedColumns = this.getExpandedColumns.bind(this);
    this.showExpandedRow = this.showExpandedRow.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchCycleCount02,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchCycleCount02(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const { intl, sorts, filters, goToDocument } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_code'),
        // filter field
        key: 'doc_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'doc_code' }),
          'doc_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.doc_code
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_date'),
        // filter field
        key: 'doc_date',
        ...AntDesignTable.getColumnSearchDateProps(
          filters,
          intl.formatMessage({ id: 'doc_date' }),
          'doc_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'doc_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_status'),
        // filter field
        ...AntDesignTable.getColumnSearchDocStatusProps(filters, 'doc_status'),
        render: (text, record) => (
          <>
            <div>{record.str_doc_status}</div>
          </>
        )
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'desc' }),
        // sort field
        dataIndex: 'desc_01',
        // ...AntDesignTable.getColumnSortProps(sorts, 'desc_01'),
        // filter field
        key: 'desc_01',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'desc' }),
          'desc_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            <div>{record.desc_01}</div>
            {record.str_physical_count_statuses.map(value => (
              <div
                key={value}
                style={{
                  color: UtilService.processStrPhysicalCountStatusColor(value),
                  fontWeight: 'bold'
                }}
              >
                {intl.formatMessage({ id: value })}
              </div>
            ))}
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'storage_row' }),
        // sort field
        dataIndex: 'storage_row_qty',
        ...AntDesignTable.getColumnSortProps(sorts, 'storage_row_qty'),
        // filter field
        key: 'storage_row_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.storage_row_qty)}
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'storage_bay' }),
        // sort field
        dataIndex: 'storage_bay_qty',
        ...AntDesignTable.getColumnSortProps(sorts, 'storage_bay_qty'),
        // filter field
        key: 'storage_bay_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.storage_bay_qty)}
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'storage_bin' }),
        // sort field
        dataIndex: 'storage_bin_qty',
        ...AntDesignTable.getColumnSortProps(sorts, 'storage_bin_qty'),
        // filter field
        key: 'storage_bin_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.storage_bin_qty)}
          </>
        )
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => goToDocument(record.id)} />
          </>
        )
      }
    ];
  }

  getExpandedColumns() {
    const { intl } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'doc_code',
        // filter field
        key: 'doc_code',
        render: (text, record) => <>{record.doc_code}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'str_doc_status',
        // filter field
        key: 'str_doc_status',
        render: (text, record) => <>{record.str_doc_status}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'worker' }),
        // sort field
        dataIndex: 'worker_01_username',
        // filter field
        key: 'worker_01',
        render: (text, record) => (
          <>
            <b>{record.worker_01_username}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'desc_01' }),
        // sort field
        dataIndex: 'desc_01',
        // filter field
        key: 'desc_01',
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'desc_02' }),
        // sort field
        dataIndex: 'desc_02',
        // filter field
        key: 'desc_02',
        render: (text, record) => <>{record.desc_02}</>
      }
    ];
  }

  showExpandedRow(record) {
    const { fetchIsLoading } = this.props;

    return (
      <Table
        size="small"
        // rowSelection={rowSelection}
        rowKey="id"
        bordered={false}
        pagination={{
          pageSize: 20
        }}
        columns={this.getExpandedColumns()}
        dataSource={record.whse_job_hdrs}
        loading={fetchIsLoading}
        defaultExpandAllRows
      />
    );
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      intl,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments,
      expandedRows,
      setExpandedRows
    } = this.props;

    return (
      <Card
        title={intl.formatMessage({ id: 'cycle_counts' })}
        bordered={false}
        headStyle={{ textAlign: 'center' }}
        bodyStyle={{ padding: 0 }}
      >
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              // disabled: record.str_doc_status !== 'DRAFT'
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          expandedRowRender={this.showExpandedRow}
          expandedRowKeys={expandedRows}
          onExpandedRowsChange={rowKeys => {
            setExpandedRows(rowKeys);
          }}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
              </Row>
            </>
          )}
        />

        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px',
            zIndex: 9999
          }}
        >
          <Badge count={selectedDocuments.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon={<LaptopOutlined />}
              onClick={this.useShowWorkspace}
              loading={fetchIsLoading}
            />
          </Badge>
        </div>

        <CycleCount02Dialog />
      </Card>
    );
  }
}

CycleCount02Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchCycleCount02: PropTypes.func,

  expandedRows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  setExpandedRows: PropTypes.func
};

CycleCount02Table.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchCycleCount02() {},

  expandedRows: [],
  setExpandedRows() {}
};

const mapStateToProps = state => ({
  timestamp: state.cycleCount02.timestamp,
  workspaceIsVisible: state.cycleCount02.workspaceIsVisible,

  documents: state.cycleCount02.documents,
  selectedDocuments: state.cycleCount02.selectedDocuments,

  sorts: state.cycleCount02.sorts,
  filters: state.cycleCount02.filters,

  currentPage: state.cycleCount02.currentPage,
  pageSize: state.cycleCount02.pageSize,
  total: state.cycleCount02.total,
  fetchIsLoading: state.cycleCount02.fetchIsLoading,

  curSiteFlowId: state.app.curSiteFlowId,
  expandedRows: state.cycleCount02.expandedRows
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(CycleCount02Actions.cycleCount02ResetTimestamp(currentPage, sorts, filters, pageSize)),
  goToDocument: hdrId => dispatch(CycleCount02Actions.cycleCount02GoToDocument(hdrId)),
  setWorkspaceVisible: boolean =>
    dispatch(CycleCount02Actions.cycleCount02SetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(CycleCount02Actions.cycleCount02AddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(CycleCount02Actions.cycleCount02RemoveSelectedDocuments(selectedDocuments)),

  fetchCycleCount02: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      CycleCount02Actions.cycleCount02FetchCycleCount02(currentPage, sorts, filters, pageSize)
    ),

  setExpandedRows: expandedRows =>
    dispatch(CycleCount02Actions.cycleCount02SetExpandedRows(expandedRows))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CycleCount02Table));
