import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  rtnDelProcessFetchRtnDelProcess: null,
  rtnDelProcessFetchRtnDelProcessLoading: ['boolean'],
  rtnDelProcessFetchRtnDelProcessSuccess: ['process']
});

export const RtnDelProcessTypes = Types;
export default Creators;
