import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import WhseJob17DetailActions, { WhseJob17DetailTypes } from '../Stores/WhseJob17Detail/Actions';

const getAppStore = state => state.app;

const getWhseJob17DetailStore = state => state.whseJob17Detail;

export function* whseJob17DetailInitHeader() {
  try {
    yield put(WhseJob17DetailActions.whseJob17DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/initHeader/BIN_TRF/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(WhseJob17DetailActions.whseJob17DetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob17DetailActions.whseJob17DetailShowDocumentLoading(false));
  }
}

export function* whseJob17DetailShowHeader({ hdrId }) {
  try {
    yield put(WhseJob17DetailActions.whseJob17DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/showHeader/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(WhseJob17DetailActions.whseJob17DetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    // yield put(WhseJob17DetailActions.whseJob17DetailShowDocumentLoading(false));
  }
}

export function* whseJob17DetailTransitionToStatus({ formikBag, hdrId, docStatus }) {
  formikBag.setSubmitting(true);
  yield put(WhseJob17DetailActions.whseJob17DetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      hdrId,
      docStatus
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'whseJob/transitionToStatus',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const whseJob17Detail = yield select(getWhseJob17DetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = whseJob17Detail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        WhseJob17DetailActions.whseJob17DetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(WhseJob17DetailActions.whseJob17DetailShowDocumentLoading(false));
  }
}

export function* whseJob17DetailUpdateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'whseJob/updateHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const whseJob17Detail = yield select(getWhseJob17DetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = whseJob17Detail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        WhseJob17DetailActions.whseJob17DetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* whseJob17DetailShowDetails({ hdrId }) {
  try {
    yield put(WhseJob17DetailActions.whseJob17DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/showDetails/BIN_TRF/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(WhseJob17DetailActions.whseJob17DetailShowDetailsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob17DetailActions.whseJob17DetailShowDocumentLoading(false));
  }
}

export function* whseJob17DetailUpdateDetails({ formikBag, hdrId, documentDetails }) {
  formikBag.setSubmitting(true);
  yield put(WhseJob17DetailActions.whseJob17DetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetails
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `whseJob/updateDetails/BIN_TRF/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const whseJob17Detail = yield select(getWhseJob17DetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = whseJob17Detail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        WhseJob17DetailActions.whseJob17DetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(WhseJob17DetailActions.whseJob17DetailShowDocumentLoading(false));
  }
}

export function* whseJob17DetailFetchItemOptions({ search }) {
  try {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(WhseJob17DetailActions.whseJob17DetailFetchItemOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchItemOptionLoading(false));
  }
}

export function* whseJob17DetailChangeItem({ formikBag, hdrId, itemId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseJob/changeItem`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });

      // reset the select2 cache
      formikBag.setFieldValue('uom_select2', {
        value: 0,
        label: ''
      });
      formikBag.setFieldValue('uom_rate', 1);

      yield put(WhseJob17DetailActions.whseJob17DetailFetchUomOptionSuccess([]));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* whseJob17DetailFetchItemBatchOptions({ itemId, search }) {
  try {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchItemBatchOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemBatch/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.batch_serial_no} | ${d.expiry_date} | ${d.receipt_date}`
      }));

      yield put(WhseJob17DetailActions.whseJob17DetailFetchItemBatchOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchItemBatchOptionLoading(false));
  }
}

export function* whseJob17DetailChangeItemBatch({ formikBag, hdrId, itemBatchId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemBatchId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseJob/changeItemBatch`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* whseJob17DetailFetchUomOptions({ itemId, search }) {
  try {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchUomOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemUom/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.uom_id, label: `${d.uom_code}` }));

      yield put(WhseJob17DetailActions.whseJob17DetailFetchUomOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchUomOptionLoading(false));
  }
}

export function* whseJob17DetailChangeUom({ formikBag, hdrId, itemId, uomId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId,
      uomId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseJob/changeItemUom`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* whseJob17DetailFetchFrStorageBinOptions({ whseJobType, search }) {
  try {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchFrStorageBinOptionLoading(true));

    let binTypes = [];
    if (whseJobType === 16) {
      binTypes = [
        1, // UNLOADING_AREA
        4, // BULK_STORAGE
        5, // PALLET_STORAGE
        6 // BROKEN_PALLET_STORAGE
      ];
    } else if (whseJobType === 17) {
      binTypes = [
        4, // BULK_STORAGE
        5, // PALLET_STORAGE
        6 // BROKEN_PALLET_STORAGE
      ];
    } else if (whseJobType === 18) {
      binTypes = [
        4, // BULK_STORAGE
        5, // PALLET_STORAGE
        6 // BROKEN_PALLET_STORAGE
      ];
    } else if (whseJobType === 19) {
      binTypes = [
        7, // CASE_STORAGE
        8 // BROKEN_CASE_STORAGE
      ];
    } else if (whseJobType === 20) {
      binTypes = [
        4, // BULK_STORAGE
        5, // PALLET_STORAGE
        6 // BROKEN_PALLET_STORAGE
      ];
    } else if (whseJobType === 23) {
      binTypes = [
        7, // CASE_STORAGE
        8 // BROKEN_CASE_STORAGE
      ];
    }

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: [
        {
          field: 'bin_types',
          value: binTypes
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(WhseJob17DetailActions.whseJob17DetailFetchFrStorageBinOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchFrStorageBinOptionLoading(false));
  }
}

export function* whseJob17DetailFetchToStorageBinOptions({ whseJobType, search }) {
  try {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchToStorageBinOptionLoading(true));

    let binTypes = [];
    if (whseJobType === 16) {
      binTypes = [
        1, // UNLOADING_AREA
        4, // BULK_STORAGE
        5, // PALLET_STORAGE
        6 // BROKEN_PALLET_STORAGE
      ];
    } else if (whseJobType === 17) {
      binTypes = [
        4, // BULK_STORAGE
        5, // PALLET_STORAGE
        6 // BROKEN_PALLET_STORAGE
      ];
    } else if (whseJobType === 18) {
      binTypes = [
        7, // CASE_STORAGE
        8 // BROKEN_CASE_STORAGE
      ];
    } else if (whseJobType === 19) {
      binTypes = [
        7, // CASE_STORAGE
        8 // BROKEN_CASE_STORAGE
      ];
    } else if (whseJobType === 20) {
      binTypes = [
        7, // CASE_STORAGE
        8 // BROKEN_CASE_STORAGE];
      ];
    } else if (whseJobType === 23) {
      binTypes = [
        4, // BULK_STORAGE
        5, // PALLET_STORAGE
        6 // BROKEN_PALLET_STORAGE
      ];
    }

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: [
        {
          field: 'bin_types',
          value: binTypes
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(WhseJob17DetailActions.whseJob17DetailFetchToStorageBinOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchToStorageBinOptionLoading(false));
  }
}

export function* whseJob17DetailFetchHandlingUnitOptions({ search }) {
  try {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchHandlingUnitOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'site_flow_id',
          value: app.curSiteFlowId
        },
        {
          field: 'status',
          value: 4 // NEW
        },
        {
          field: 'handling_type',
          value: 1 // PALLET
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `handlingUnit/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.barcode}` }));

      yield put(WhseJob17DetailActions.whseJob17DetailFetchHandlingUnitOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchHandlingUnitOptionLoading(false));
  }
}

export function* whseJob17DetailCreateDetail({ formikBag, hdrId, documentDetail }) {
  formikBag.setSubmitting(true);
  yield put(WhseJob17DetailActions.whseJob17DetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetail
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseJob/createDetail/BIN_TRF/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const whseJob17Detail = yield select(getWhseJob17DetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = whseJob17Detail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        WhseJob17DetailActions.whseJob17DetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );

      yield put(
        WhseJob17DetailActions.whseJob17DetailSetDocumentDetail(whseJob17Detail.initDocumentDetail)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(WhseJob17DetailActions.whseJob17DetailShowDocumentLoading(false));
  }
}

export function* whseJob17DetailDeleteDetail({ hdrId, documentDetail }) {
  yield put(WhseJob17DetailActions.whseJob17DetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: [{ id: documentDetail.id }]
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `whseJob/deleteDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const whseJob17Detail = yield select(getWhseJob17DetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = whseJob17Detail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        WhseJob17DetailActions.whseJob17DetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob17DetailActions.whseJob17DetailShowDocumentLoading(false));
  }
}

export function* whseJob17DetailCreateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'whseJob/createHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/whseJob17Detail/update/${result.data}`));
      yield put(WhseJob17DetailActions.whseJob17DetailSetHdrId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* whseJob17DetailFetchStorageRowOptions({ search }) {
  try {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchStorageRowOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageRow/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code} ${d.desc_01}` }));

      yield put(WhseJob17DetailActions.whseJob17DetailFetchStorageRowOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchStorageRowOptionLoading(false));
  }
}

export function* whseJob17DetailFetchStorageBayOptions({ search }) {
  try {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchStorageBayOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBay/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code} ${d.desc_01}` }));

      yield put(WhseJob17DetailActions.whseJob17DetailFetchStorageBayOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchStorageBayOptionLoading(false));
  }
}

export function* whseJob17DetailFetchQuantBalOptions({ storageBinId, search }) {
  try {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchQuantBalOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'storage_bin_id',
          value: storageBinId
        },
        {
          field: 'balance_unit_qty',
          operator: '<>',
          value: 0
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `quantBal/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.handling_unit_barcode} | ${d.item_code} | ${d.case_qty} ${d.item_case_uom_code} | ${d.loose_qty} ${d.item_loose_uom_code}`
      }));

      yield put(WhseJob17DetailActions.whseJob17DetailFetchQuantBalOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchQuantBalOptionLoading(false));
  }
}

export function* whseJob17DetailChangeQuantBal({ formikBag, hdrId, quantBalId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      quantBalId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseJob/changeQuantBal`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });

      // reset the select2 cache
      formikBag.setFieldValue('uom_select2', {
        value: 0,
        label: ''
      });
      formikBag.setFieldValue('uom_rate', 1);

      yield put(WhseJob17DetailActions.whseJob17DetailFetchUomOptionSuccess([]));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* whseJob17DetailFetchWorkerOptions({ search }) {
  try {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchWorkerOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `user/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.username}` }));

      yield put(WhseJob17DetailActions.whseJob17DetailFetchWorkerOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchWorkerOptionLoading(false));
  }
}

export function* whseJob17DetailFetchToHandlingUnitOptions({ storageBinId, search }) {
  try {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchToHandlingUnitOptionLoading(true));

    const app = yield select(getAppStore);
    let filters = [
      {
        field: 'site_flow_id',
        value: app.curSiteFlowId
      },
      {
        field: 'handling_type',
        value: 1 // PALLET
      }
    ];
    if (storageBinId > 0) {
      filters = [
        {
          field: 'storage_bin_id',
          value: storageBinId
        }
      ];
    }
    const postData = {
      search,
      filters
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `handlingUnit/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.barcode}` }));

      yield put(WhseJob17DetailActions.whseJob17DetailFetchToHandlingUnitOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob17DetailActions.whseJob17DetailFetchToHandlingUnitOptionLoading(false));
  }
}

export const saga = [
  takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_INIT_HEADER, whseJob17DetailInitHeader),
  takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_SHOW_HEADER, whseJob17DetailShowHeader),

  takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_UPDATE_HEADER, whseJob17DetailUpdateHeader),
  takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_CREATE_HEADER, whseJob17DetailCreateHeader),

  takeLatest(
    WhseJob17DetailTypes.WHSE_JOB17_DETAIL_TRANSITION_TO_STATUS,
    whseJob17DetailTransitionToStatus
  ),

  takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_SHOW_DETAILS, whseJob17DetailShowDetails),

  takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_UPDATE_DETAILS, whseJob17DetailUpdateDetails),
  takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_DELETE_DETAIL, whseJob17DetailDeleteDetail),
  takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_CREATE_DETAIL, whseJob17DetailCreateDetail),

  takeLatest(
    WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_ITEM_OPTIONS,
    whseJob17DetailFetchItemOptions
  ),
  takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_CHANGE_ITEM, whseJob17DetailChangeItem),

  takeLatest(
    WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_ITEM_BATCH_OPTIONS,
    whseJob17DetailFetchItemBatchOptions
  ),
  takeLatest(
    WhseJob17DetailTypes.WHSE_JOB17_DETAIL_CHANGE_ITEM_BATCH,
    whseJob17DetailChangeItemBatch
  ),

  takeLatest(
    WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_UOM_OPTIONS,
    whseJob17DetailFetchUomOptions
  ),
  takeLatest(WhseJob17DetailTypes.WHSE_JOB17_DETAIL_CHANGE_UOM, whseJob17DetailChangeUom),

  takeLatest(
    WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_FR_STORAGE_BIN_OPTIONS,
    whseJob17DetailFetchFrStorageBinOptions
  ),

  takeLatest(
    WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_TO_STORAGE_BIN_OPTIONS,
    whseJob17DetailFetchToStorageBinOptions
  ),

  takeLatest(
    WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_TO_HANDLING_UNIT_OPTIONS,
    whseJob17DetailFetchToHandlingUnitOptions
  ),

  takeLatest(
    WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_HANDLING_UNIT_OPTIONS,
    whseJob17DetailFetchHandlingUnitOptions
  ),

  takeLatest(
    WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_STORAGE_ROW_OPTIONS,
    whseJob17DetailFetchStorageRowOptions
  ),
  takeLatest(
    WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_STORAGE_BAY_OPTIONS,
    whseJob17DetailFetchStorageBayOptions
  ),
  takeLatest(
    WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_QUANT_BAL_OPTIONS,
    whseJob17DetailFetchQuantBalOptions
  ),
  takeLatest(
    WhseJob17DetailTypes.WHSE_JOB17_DETAIL_CHANGE_QUANT_BAL,
    whseJob17DetailChangeQuantBal
  ),
  takeLatest(
    WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_WORKER_OPTIONS,
    whseJob17DetailFetchWorkerOptions
  )
];
