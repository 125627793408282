/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { ProdSheetDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const prodSheetDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const prodSheetDetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false,
  jobDetailIsVisible: false
});

export const prodSheetDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const prodSheetDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const prodSheetDetailUpdateDocumentSuccess = (
  state,
  { documentHeader, documentDetails }
) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const prodSheetDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const prodSheetDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const prodSheetDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  whseJobType: documentDetail.whse_job_type,
  companyOptions: [],
  frStorageBinOptions: [],
  quantBalOptions: [],
  itemBatchOptions: [],
  uomOptions: [],
  toStorageBinOptions: []
});

export const prodSheetDetailFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const prodSheetDetailFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const prodSheetDetailFetchUserOptionLoading = (state, { boolean }) => ({
  ...state,
  userIsLoading: boolean
});

export const prodSheetDetailFetchUserOptionSuccess = (state, { options }) => ({
  ...state,
  userOptions: options
});

export const prodSheetDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const prodSheetDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const prodSheetDetailFetchItemBatchOptionLoading = (state, { boolean }) => ({
  ...state,
  itemBatchIsLoading: boolean
});

export const prodSheetDetailFetchItemBatchOptionSuccess = (state, { options }) => ({
  ...state,
  itemBatchOptions: options
});

export const prodSheetDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const prodSheetDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

export const prodSheetDetailFetchItemCond01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemCond01IsLoading: boolean
});

export const prodSheetDetailFetchItemCond01OptionSuccess = (state, { options }) => ({
  ...state,
  itemCond01Options: options
});

export const prodSheetDetailFetchStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBinIsLoading: boolean
});

export const prodSheetDetailFetchStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  storageBinOptions: options
});

export const prodSheetDetailFetchHandlingUnitOptionLoading = (state, { boolean }) => ({
  ...state,
  handlingUnitIsLoading: boolean
});

export const prodSheetDetailFetchHandlingUnitOptionSuccess = (state, { options }) => ({
  ...state,
  handlingUnitOptions: options
});

// force refresh the formik form
export const prodSheetDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const prodSheetDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const prodSheetDetailFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const prodSheetDetailFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

export const prodSheetDetailFetchStorageBayOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBayIsLoading: boolean
});

export const prodSheetDetailFetchStorageBayOptionSuccess = (state, { options }) => ({
  ...state,
  storageBayOptions: options
});

export const prodSheetDetailFetchQuantBalOptionLoading = (state, { boolean }) => ({
  ...state,
  quantBalIsLoading: boolean
});

export const prodSheetDetailFetchQuantBalOptionSuccess = (state, { options }) => ({
  ...state,
  quantBalOptions: options
});

export const prodSheetDetailUploadLoading = (state, { boolean }) => ({
  ...state,
  uploadIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_RESET_TIMESTAMP]: prodSheetDetailResetTimestamp,
  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_SET_HDR_ID]: prodSheetDetailSetHdrId,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_SHOW_HEADER_SUCCESS]: prodSheetDetailShowHeaderSuccess,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_UPDATE_DOCUMENT_SUCCESS]: prodSheetDetailUpdateDocumentSuccess,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_SHOW_DOCUMENT_LOADING]: prodSheetDetailShowDocumentLoading,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_SHOW_DETAILS_SUCCESS]: prodSheetDetailShowDetailsSuccess,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_SET_DETAIL_VISIBLE]: prodSheetDetailSetDetailVisible,
  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_SET_DOCUMENT_DETAIL]: prodSheetDetailSetDocumentDetail,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_UPDATE_DETAILS]: prodSheetDetailUpdateDetails,
  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_CREATE_DETAIL]: prodSheetDetailCreateDetail,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_COMPANY_OPTION_LOADING]: prodSheetDetailFetchCompanyOptionLoading,
  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_COMPANY_OPTION_SUCCESS]: prodSheetDetailFetchCompanyOptionSuccess,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_USER_OPTION_LOADING]: prodSheetDetailFetchUserOptionLoading,
  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_USER_OPTION_SUCCESS]: prodSheetDetailFetchUserOptionSuccess,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_ITEM_OPTION_LOADING]: prodSheetDetailFetchItemOptionLoading,
  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: prodSheetDetailFetchItemOptionSuccess,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_ITEM_BATCH_OPTION_LOADING]: prodSheetDetailFetchItemBatchOptionLoading,
  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_ITEM_BATCH_OPTION_SUCCESS]: prodSheetDetailFetchItemBatchOptionSuccess,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_UOM_OPTION_LOADING]: prodSheetDetailFetchUomOptionLoading,
  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_UOM_OPTION_SUCCESS]: prodSheetDetailFetchUomOptionSuccess,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_ITEM_COND01_OPTION_LOADING]: prodSheetDetailFetchItemCond01OptionLoading,
  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_ITEM_COND01_OPTION_SUCCESS]: prodSheetDetailFetchItemCond01OptionSuccess,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_STORAGE_BIN_OPTION_LOADING]: prodSheetDetailFetchStorageBinOptionLoading,
  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_STORAGE_BIN_OPTION_SUCCESS]: prodSheetDetailFetchStorageBinOptionSuccess,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_HANDLING_UNIT_OPTION_LOADING]: prodSheetDetailFetchHandlingUnitOptionLoading,
  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_HANDLING_UNIT_OPTION_SUCCESS]: prodSheetDetailFetchHandlingUnitOptionSuccess,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_STORAGE_ROW_OPTION_LOADING]: prodSheetDetailFetchStorageRowOptionLoading,
  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_STORAGE_ROW_OPTION_SUCCESS]: prodSheetDetailFetchStorageRowOptionSuccess,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_STORAGE_BAY_OPTION_LOADING]: prodSheetDetailFetchStorageBayOptionLoading,
  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_STORAGE_BAY_OPTION_SUCCESS]: prodSheetDetailFetchStorageBayOptionSuccess,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_QUANT_BAL_OPTION_LOADING]: prodSheetDetailFetchQuantBalOptionLoading,
  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_QUANT_BAL_OPTION_SUCCESS]: prodSheetDetailFetchQuantBalOptionSuccess,

  [ProdSheetDetailTypes.PROD_SHEET_DETAIL_UPLOAD_LOADING]: prodSheetDetailUploadLoading
});
