import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  cycleCountIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  cycleCountIndexGoToDocument: ['hdrId'],
  cycleCountIndexGoToAudit: ['hdrId'],
  cycleCountIndexFetchCycleCountIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  cycleCountIndexFetchCycleCountIndexLoading: ['boolean'],
  cycleCountIndexFetchCycleCountIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  cycleCountIndexAddSelectedDocuments: ['selectedDocuments'],
  cycleCountIndexRemoveSelectedDocuments: ['selectedDocuments'],
  cycleCountIndexSetWorkspaceVisible: ['boolean'],
  cycleCountIndexPrintCycleCountIndex: ['hdrIds'],
  cycleCountIndexPrintCycleCountIndexLoading: ['boolean']
});

export const CycleCountIndexTypes = Types;
export default Creators;
