import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  divisionDetailResetModelTimestamp: null,
  divisionDetailSetResId: ['resId'],

  divisionDetailInitModel: null,
  divisionDetailShowModel: ['resId'],
  divisionDetailShowModelSuccess: ['model'],

  divisionDetailUpdateModel: ['formikBag', 'model'],
  divisionDetailCreateModel: ['formikBag', 'model'],
  divisionDetailUpdateModelSuccess: ['model'],

  divisionDetailShowModelLoading: ['boolean'],

  divisionDetailFetchSiteFlowOptions: ['search'],
  divisionDetailFetchSiteFlowOptionLoading: ['boolean'],
  divisionDetailFetchSiteFlowOptionSuccess: ['options'],

  divisionDetailFetchCompanyOptions: ['search'],
  divisionDetailFetchCompanyOptionLoading: ['boolean'],
  divisionDetailFetchCompanyOptionSuccess: ['options'],

  divisionDetailResetInvTxnFlowTimestamp: null,
  divisionDetailShowInvTxnFlows: ['resId'],
  divisionDetailShowInvTxnFlowsLoading: ['boolean'],
  divisionDetailShowInvTxnFlowsSuccess: ['txnFlows'],

  divisionDetailInvTxnFlowsUploadExcel: ['resId', 'file'],
  divisionDetailInvTxnFlowsUploadLoading: ['boolean'],
  divisionDetailInvTxnFlowsDownloadExcel: ['resId'],

  divisionDetailResetPrintDocSettingTimestamp: null,
  divisionDetailShowPrintDocSettings: ['resId'],
  divisionDetailShowPrintDocSettingsLoading: ['boolean'],
  divisionDetailShowPrintDocSettingsSuccess: ['settings'],

  divisionDetailPrintDocSettingsUploadExcel: ['resId', 'file'],
  divisionDetailPrintDocSettingsUploadLoading: ['boolean'],
  divisionDetailPrintDocSettingsDownloadExcel: ['resId'],

  divisionDetailResetUserDivisionTimestamp: null,
  divisionDetailShowUserDivisions: ['resId'],
  divisionDetailShowUserDivisionsLoading: ['boolean'],
  divisionDetailShowUserDivisionsSuccess: ['userDivisions'],

  divisionDetailUserDivisionsUploadExcel: ['resId', 'file'],
  divisionDetailUserDivisionsUploadLoading: ['boolean'],
  divisionDetailUserDivisionsDownloadExcel: ['resId'],

  divisionDetailFetchDocNoOptions: ['docType', 'search'],

  divisionDetailFetchArInvDocNoOptionLoading: ['boolean'],
  divisionDetailFetchArInvDocNoOptionSuccess: ['options'],
  divisionDetailFetchArCnDocNoOptionLoading: ['boolean'],
  divisionDetailFetchArCnDocNoOptionSuccess: ['options'],
  divisionDetailFetchArDnDocNoOptionLoading: ['boolean'],
  divisionDetailFetchArDnDocNoOptionSuccess: ['options'],
  divisionDetailFetchArPaymentDocNoOptionLoading: ['boolean'],
  divisionDetailFetchArPaymentDocNoOptionSuccess: ['options'],
  divisionDetailFetchArRefundDocNoOptionLoading: ['boolean'],
  divisionDetailFetchArRefundDocNoOptionSuccess: ['options'],
  divisionDetailFetchArDepositDocNoOptionLoading: ['boolean'],
  divisionDetailFetchArDepositDocNoOptionSuccess: ['options'],
  divisionDetailFetchArDepositForfeitDocNoOptionLoading: ['boolean'],
  divisionDetailFetchArDepositForfeitDocNoOptionSuccess: ['options'],
  divisionDetailFetchArDepositRefundDocNoOptionLoading: ['boolean'],
  divisionDetailFetchArDepositRefundDocNoOptionSuccess: ['options'],

  divisionDetailFetchApInvDocNoOptionLoading: ['boolean'],
  divisionDetailFetchApInvDocNoOptionSuccess: ['options'],
  divisionDetailFetchApDnDocNoOptionLoading: ['boolean'],
  divisionDetailFetchApDnDocNoOptionSuccess: ['options'],
  divisionDetailFetchApCnDocNoOptionLoading: ['boolean'],
  divisionDetailFetchApCnDocNoOptionSuccess: ['options'],
  divisionDetailFetchApPaymentDocNoOptionLoading: ['boolean'],
  divisionDetailFetchApPaymentDocNoOptionSuccess: ['options'],
  divisionDetailFetchApRefundDocNoOptionLoading: ['boolean'],
  divisionDetailFetchApRefundDocNoOptionSuccess: ['options'],
  divisionDetailFetchApDepositDocNoOptionLoading: ['boolean'],
  divisionDetailFetchApDepositDocNoOptionSuccess: ['options'],
  divisionDetailFetchApDepositForfeitDocNoOptionLoading: ['boolean'],
  divisionDetailFetchApDepositForfeitDocNoOptionSuccess: ['options'],
  divisionDetailFetchApDepositRefundDocNoOptionLoading: ['boolean'],
  divisionDetailFetchApDepositRefundDocNoOptionSuccess: ['options'],

  divisionDetailFetchPaymentVoucherDocNoOptionLoading: ['boolean'],
  divisionDetailFetchPaymentVoucherDocNoOptionSuccess: ['options'],
  divisionDetailFetchReceiptVoucherDocNoOptionLoading: ['boolean'],
  divisionDetailFetchReceiptVoucherDocNoOptionSuccess: ['options'],
  divisionDetailFetchJournalVoucherDocNoOptionLoading: ['boolean'],
  divisionDetailFetchJournalVoucherDocNoOptionSuccess: ['options'],

  divisionDetailFetchSlsOrdDocNoOptionLoading: ['boolean'],
  divisionDetailFetchSlsOrdDocNoOptionSuccess: ['options'],

  divisionDetailFetchSlsRtnDocNoOptionLoading: ['boolean'],
  divisionDetailFetchSlsRtnDocNoOptionSuccess: ['options'],

  divisionDetailFetchDelOrdDocNoOptionLoading: ['boolean'],
  divisionDetailFetchDelOrdDocNoOptionSuccess: ['options'],

  divisionDetailFetchSlsInvDocNoOptionLoading: ['boolean'],
  divisionDetailFetchSlsInvDocNoOptionSuccess: ['options'],

  divisionDetailFetchRtnRcptDocNoOptionLoading: ['boolean'],
  divisionDetailFetchRtnRcptDocNoOptionSuccess: ['options'],

  divisionDetailFetchAdvShipDocNoOptionLoading: ['boolean'],
  divisionDetailFetchAdvShipDocNoOptionSuccess: ['options'],

  divisionDetailFetchPurInvDocNoOptionLoading: ['boolean'],
  divisionDetailFetchPurInvDocNoOptionSuccess: ['options'],

  divisionDetailFetchPurRtnDocNoOptionLoading: ['boolean'],
  divisionDetailFetchPurRtnDocNoOptionSuccess: ['options'],

  divisionDetailFetchLspSlsOrdDocNoOptionLoading: ['boolean'],
  divisionDetailFetchLspSlsOrdDocNoOptionSuccess: ['options'],

  divisionDetailFetchLspSlsRtnDocNoOptionLoading: ['boolean'],
  divisionDetailFetchLspSlsRtnDocNoOptionSuccess: ['options'],

  divisionDetailFetchLspAdvShipDocNoOptionLoading: ['boolean'],
  divisionDetailFetchLspAdvShipDocNoOptionSuccess: ['options'],

  divisionDetailFetchLspDelOrdDocNoOptionLoading: ['boolean'],
  divisionDetailFetchLspDelOrdDocNoOptionSuccess: ['options'],

  divisionDetailFetchProdOrdDocNoOptionLoading: ['boolean'],
  divisionDetailFetchProdOrdDocNoOptionSuccess: ['options'],

  divisionDetailFetchProdIssDocNoOptionLoading: ['boolean'],
  divisionDetailFetchProdIssDocNoOptionSuccess: ['options'],

  divisionDetailFetchProdRcptDocNoOptionLoading: ['boolean'],
  divisionDetailFetchProdRcptDocNoOptionSuccess: ['options'],

  divisionDetailFetchProdSheetDocNoOptionLoading: ['boolean'],
  divisionDetailFetchProdSheetDocNoOptionSuccess: ['options'],

  divisionDetailFetchOutbOrdDocNoOptionLoading: ['boolean'],
  divisionDetailFetchOutbOrdDocNoOptionSuccess: ['options'],

  divisionDetailFetchInbOrdDocNoOptionLoading: ['boolean'],
  divisionDetailFetchInbOrdDocNoOptionSuccess: ['options'],

  divisionDetailFetchTrfOrdDocNoOptionLoading: ['boolean'],
  divisionDetailFetchTrfOrdDocNoOptionSuccess: ['options'],

  divisionDetailFetchStkIssDocNoOptionLoading: ['boolean'],
  divisionDetailFetchStkIssDocNoOptionSuccess: ['options'],

  divisionDetailFetchStkRcptDocNoOptionLoading: ['boolean'],
  divisionDetailFetchStkRcptDocNoOptionSuccess: ['options'],

  divisionDetailFetchStkTrfDocNoOptionLoading: ['boolean'],
  divisionDetailFetchStkTrfDocNoOptionSuccess: ['options'],

  divisionDetailFetchWorkOrdDocNoOptionLoading: ['boolean'],
  divisionDetailFetchWorkOrdDocNoOptionSuccess: ['options'],

  divisionDetailGoToDocTypeDocNo: ['docType']
});

export const DivisionDetailTypes = Types;
export default Creators;
