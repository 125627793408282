import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  trfToVanProcessFetchTrfToVanProcess: null,
  trfToVanProcessFetchTrfToVanProcessLoading: ['boolean'],
  trfToVanProcessFetchTrfToVanProcessSuccess: ['process']
});

export const TrfToVanProcessTypes = Types;
export default Creators;
