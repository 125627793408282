import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  dailyStockMovementReportInitDailyStockMovement: null,
  dailyStockMovementReportInitDailyStockMovementSuccess: ['criteria'],
  dailyStockMovementReportDailyStockMovement: ['formikBag', 'criteria'],
  dailyStockMovementReportDailyStockMovementSuccess: ['criteria', 'reportData'],
  dailyStockMovementReportReportLoading: ['boolean'],

  dailyStockMovementReportFetchItemOptions: ['search'],
  dailyStockMovementReportFetchItemOptionLoading: ['boolean'],
  dailyStockMovementReportFetchItemOptionSuccess: ['options'],

  dailyStockMovementReportFetchItemGroup01Options: ['search'],
  dailyStockMovementReportFetchItemGroup01OptionLoading: ['boolean'],
  dailyStockMovementReportFetchItemGroup01OptionSuccess: ['options'],

  dailyStockMovementReportFetchItemGroup02Options: ['search'],
  dailyStockMovementReportFetchItemGroup02OptionLoading: ['boolean'],
  dailyStockMovementReportFetchItemGroup02OptionSuccess: ['options'],

  dailyStockMovementReportFetchItemGroup03Options: ['search'],
  dailyStockMovementReportFetchItemGroup03OptionLoading: ['boolean'],
  dailyStockMovementReportFetchItemGroup03OptionSuccess: ['options'],

  dailyStockMovementReportFetchStorageBinOptions: ['search'],
  dailyStockMovementReportFetchStorageBinOptionLoading: ['boolean'],
  dailyStockMovementReportFetchStorageBinOptionSuccess: ['options'],

  dailyStockMovementReportFetchStorageRowOptions: ['search'],
  dailyStockMovementReportFetchStorageRowOptionLoading: ['boolean'],
  dailyStockMovementReportFetchStorageRowOptionSuccess: ['options'],

  dailyStockMovementReportFetchStorageBayOptions: ['search'],
  dailyStockMovementReportFetchStorageBayOptionLoading: ['boolean'],
  dailyStockMovementReportFetchStorageBayOptionSuccess: ['options'],

  dailyStockMovementReportFetchLocationOptions: ['search'],
  dailyStockMovementReportFetchLocationOptionLoading: ['boolean'],
  dailyStockMovementReportFetchLocationOptionSuccess: ['options']
});

export const DailyStockMovementReportTypes = Types;
export default Creators;
