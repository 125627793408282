import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  outOfStockAnalysisReportInitOutOfStockAnalysis: null,
  outOfStockAnalysisReportInitOutOfStockAnalysisSuccess: ['criteria'],
  outOfStockAnalysisReportOutOfStockAnalysis: ['formikBag', 'criteria'],
  outOfStockAnalysisReportOutOfStockAnalysisSuccess: ['criteria', 'reportData'],
  outOfStockAnalysisReportReportLoading: ['boolean'],

  outOfStockAnalysisReportFetchItemOptions: ['search'],
  outOfStockAnalysisReportFetchItemOptionLoading: ['boolean'],
  outOfStockAnalysisReportFetchItemOptionSuccess: ['options'],

  outOfStockAnalysisReportFetchItemGroup01Options: ['search'],
  outOfStockAnalysisReportFetchItemGroup01OptionLoading: ['boolean'],
  outOfStockAnalysisReportFetchItemGroup01OptionSuccess: ['options'],

  outOfStockAnalysisReportFetchItemGroup02Options: ['search'],
  outOfStockAnalysisReportFetchItemGroup02OptionLoading: ['boolean'],
  outOfStockAnalysisReportFetchItemGroup02OptionSuccess: ['options'],

  outOfStockAnalysisReportFetchItemGroup03Options: ['search'],
  outOfStockAnalysisReportFetchItemGroup03OptionLoading: ['boolean'],
  outOfStockAnalysisReportFetchItemGroup03OptionSuccess: ['options'],

  outOfStockAnalysisReportFetchDivisionOptions: ['search'],
  outOfStockAnalysisReportFetchDivisionOptionLoading: ['boolean'],
  outOfStockAnalysisReportFetchDivisionOptionSuccess: ['options'],

  outOfStockAnalysisReportFetchDeliveryPointOptions: ['search'],
  outOfStockAnalysisReportFetchDeliveryPointOptionLoading: ['boolean'],
  outOfStockAnalysisReportFetchDeliveryPointOptionSuccess: ['options']
});

export const OutOfStockAnalysisReportTypes = Types;
export default Creators;
