import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspSlsOrdDetailBoardResetWipLspSlsOrdTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  lspSlsOrdDetailBoardFetchWipLspSlsOrd: ['currentPage', 'sorts', 'filters', 'pageSize'],
  lspSlsOrdDetailBoardFetchWipLspSlsOrdLoading: ['boolean'],
  lspSlsOrdDetailBoardFetchWipLspSlsOrdSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  lspSlsOrdDetailBoardResetWipOutbOrdTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  lspSlsOrdDetailBoardFetchWipOutbOrd: ['currentPage', 'sorts', 'filters', 'pageSize'],
  lspSlsOrdDetailBoardFetchWipOutbOrdLoading: ['boolean'],
  lspSlsOrdDetailBoardFetchWipOutbOrdSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  lspSlsOrdDetailBoardResetWipInvDocTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  lspSlsOrdDetailBoardFetchWipInvDoc: ['currentPage', 'sorts', 'filters', 'pageSize'],
  lspSlsOrdDetailBoardFetchWipInvDocLoading: ['boolean'],
  lspSlsOrdDetailBoardFetchWipInvDocSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  lspSlsOrdDetailBoardResetCompleteLoadListTimestamp: [
    'currentPage',
    'sorts',
    'filters',
    'pageSize'
  ],
  lspSlsOrdDetailBoardFetchCompleteLoadList: ['currentPage', 'sorts', 'filters', 'pageSize'],
  lspSlsOrdDetailBoardFetchCompleteLoadListLoading: ['boolean'],
  lspSlsOrdDetailBoardFetchCompleteLoadListSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  lspSlsOrdDetailBoardSetWipLspSlsOrdDialogVisible: ['boolean'],
  lspSlsOrdDetailBoardShowWipLspSlsOrdSummary: ['docId'],
  lspSlsOrdDetailBoardShowWipLspSlsOrdSummaryLoading: ['boolean'],
  lspSlsOrdDetailBoardShowWipLspSlsOrdSummarySuccess: ['document'],

  lspSlsOrdDetailBoardSetWipOutbOrdDialogVisible: ['boolean'],
  lspSlsOrdDetailBoardShowWipOutbOrdSummary: ['docId'],
  lspSlsOrdDetailBoardShowWipOutbOrdSummaryLoading: ['boolean'],
  lspSlsOrdDetailBoardShowWipOutbOrdSummarySuccess: ['document'],

  lspSlsOrdDetailBoardSetWipInvDocDialogVisible: ['boolean'],
  lspSlsOrdDetailBoardShowWipInvDocSummary: ['docId'],
  lspSlsOrdDetailBoardShowWipInvDocSummaryLoading: ['boolean'],
  lspSlsOrdDetailBoardShowWipInvDocSummarySuccess: ['document'],

  lspSlsOrdDetailBoardSetCompleteLoadListDialogVisible: ['boolean'],
  lspSlsOrdDetailBoardShowCompleteLoadListSummary: ['docId'],
  lspSlsOrdDetailBoardShowCompleteLoadListSummaryLoading: ['boolean'],
  lspSlsOrdDetailBoardShowCompleteLoadListSummarySuccess: ['document']
});

export const LspSlsOrdDetailBoardTypes = Types;
export default Creators;
