import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { EditOutlined, LaptopOutlined, ReloadOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button, Badge } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import DebtorList01Actions from '../../Stores/DebtorList01/Actions';

class DebtorList01Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchDebtorList01,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchDebtorList01(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const { intl, sorts, filters, goToResource } = this.props;

    return [
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        ...AntDesignTable.getColumnSortProps(sorts, 'code'),
        // filter field
        key: 'code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'code' }),
          'code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.code}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'ref_code' }),
        // sort field
        dataIndex: 'ref_code_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'ref_code_01'),
        // filter field
        key: 'ref_code_01',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'ref_code' }),
          'ref_code_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.ref_code_01}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'name' }),
        // sort field
        dataIndex: 'company_name_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'company_name_01'),
        // filter field
        key: 'company_name_01',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'name' }),
          'company_name_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.company_name_01}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'area' }),
        // sort field
        dataIndex: 'area_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'area_code'),
        // filter field
        key: 'area_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'area' }),
          'area_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.area_code}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'unit_no' }),
        // sort field
        dataIndex: 'unit_no',
        ...AntDesignTable.getColumnSortProps(sorts, 'unit_no'),
        // filter field
        key: 'unit_no',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'unit_no' }),
          'unit_no',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.unit_no}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'building' }),
        // sort field
        dataIndex: 'building_name',
        ...AntDesignTable.getColumnSortProps(sorts, 'building_name'),
        // filter field
        key: 'building_name',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'building' }),
          'building_name',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.building_name}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'street_name' }),
        // sort field
        dataIndex: 'street_name',
        ...AntDesignTable.getColumnSortProps(sorts, 'street_name'),
        // filter field
        key: 'street_name',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'street_name' }),
          'street_name',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.street_name}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'district_01' }),
        // sort field
        dataIndex: 'district_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'district_01'),
        // filter field
        key: 'district_01',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'district_01' }),
          'district_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.district_01}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'district_02' }),
        // sort field
        dataIndex: 'district_02',
        ...AntDesignTable.getColumnSortProps(sorts, 'district_02'),
        // filter field
        key: 'district_02',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'district_02' }),
          'district_02',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.district_02}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'postcode' }),
        // sort field
        dataIndex: 'postcode',
        ...AntDesignTable.getColumnSortProps(sorts, 'postcode'),
        // filter field
        key: 'postcode',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'postcode' }),
          'postcode',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.postcode}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'state_name' }),
        // sort field
        dataIndex: 'state_name',
        ...AntDesignTable.getColumnSortProps(sorts, 'state_name'),
        // filter field
        key: 'state_name',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'state_name' }),
          'state_name',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.state_name}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'country_name' }),
        // sort field
        dataIndex: 'country_name',
        ...AntDesignTable.getColumnSortProps(sorts, 'country_name'),
        // filter field
        key: 'country_name',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'country_name' }),
          'country_name',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.country_name}</>
      },
      {
        width: 50,
        fixed: 'right',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => goToResource(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      intl,
      curSiteFlowId,
      resetTimestamp,
      newResource,
      currentPage,
      pageSize,
      total,
      resources,
      fetchIsLoading,
      selectedResources,
      addSelectedResources,
      removeSelectedResources
    } = this.props;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={resources}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedResources.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedResources([record]);
              } else {
                removeSelectedResources([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedResources(changeRows);
              } else {
                removeSelectedResources(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedResources(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              disabled: true
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
                <Col span={5}>
                  <Button
                    type="primary"
                    disabled={curSiteFlowId === 0}
                    loading={fetchIsLoading}
                    onClick={() => newResource()}
                    // icon={<PlusOutlined />}
                  >
                    {intl.formatMessage({ id: 'new' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />

        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px'
          }}
        >
          <Badge count={selectedResources.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon={<LaptopOutlined />}
              onClick={this.useShowWorkspace}
              loading={fetchIsLoading}
            />
          </Badge>
        </div>
      </>
    );
  }
}

DebtorList01Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToResource: PropTypes.func,
  newResource: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedResources: PropTypes.func,
  removeSelectedResources: PropTypes.func,
  resources: PropTypes.arrayOf(PropTypes.object),
  selectedResources: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchDebtorList01: PropTypes.func
};

DebtorList01Table.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToResource() {},
  newResource() {},

  setWorkspaceVisible() {},

  addSelectedResources() {},
  removeSelectedResources() {},
  resources: [],
  selectedResources: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchDebtorList01() {}
};

const mapStateToProps = state => ({
  timestamp: state.debtorList01.timestamp,
  workspaceIsVisible: state.debtorList01.workspaceIsVisible,

  resources: state.debtorList01.resources,
  selectedResources: state.debtorList01.selectedResources,

  sorts: state.debtorList01.sorts,
  filters: state.debtorList01.filters,

  currentPage: state.debtorList01.currentPage,
  pageSize: state.debtorList01.pageSize,
  total: state.debtorList01.total,
  fetchIsLoading: state.debtorList01.fetchIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(DebtorList01Actions.debtorList01ResetTimestamp(currentPage, sorts, filters, pageSize)),
  goToResource: resId => dispatch(DebtorList01Actions.debtorList01GoToResource(resId)),
  newResource: () => dispatch(DebtorList01Actions.debtorList01NewResource()),

  setWorkspaceVisible: boolean =>
    dispatch(DebtorList01Actions.debtorList01SetWorkspaceVisible(boolean)),

  addSelectedResources: selectedResources =>
    dispatch(DebtorList01Actions.debtorList01AddSelectedResources(selectedResources)),
  removeSelectedResources: selectedResources =>
    dispatch(DebtorList01Actions.debtorList01RemoveSelectedResources(selectedResources)),

  fetchDebtorList01: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      DebtorList01Actions.debtorList01FetchDebtorList01(currentPage, sorts, filters, pageSize)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DebtorList01Table));
