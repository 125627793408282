import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  trfFromVanProcessFetchTrfFromVanProcess: null,
  trfFromVanProcessFetchTrfFromVanProcessLoading: ['boolean'],
  trfFromVanProcessFetchTrfFromVanProcessSuccess: ['process']
});

export const TrfFromVanProcessTypes = Types;
export default Creators;
