import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  cycleCount03ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  cycleCount03GoToDocument: ['hdrId'],
  cycleCount03NewDocument: null,
  cycleCount03FetchCycleCount03: ['currentPage', 'sorts', 'filters', 'pageSize'],
  cycleCount03FetchCycleCount03Loading: ['boolean'],
  cycleCount03FetchCycleCount03Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  cycleCount03AddSelectedDocuments: ['selectedDocuments'],
  cycleCount03RemoveSelectedDocuments: ['selectedDocuments'],
  cycleCount03SetWorkspaceVisible: ['boolean'],
  cycleCount03PrintCycleCount03: ['hdrIds'],
  cycleCount03PrintCycleCount03Loading: ['boolean']
});

export const CycleCount03Types = Types;
export default Creators;
