/**
 * The initial values for the redux state.
 */
export default {
  wipInbOrdTimestamp: 0,
  wipInbOrds: [],
  wipInbOrdIsLoading: false,
  wipInbOrdSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  wipInbOrdFilters: {},
  wipInbOrdPageSize: '20',
  wipInbOrdCurrentPage: 1,
  wipInbOrdLastPage: 10,
  wipInbOrdTotal: 0,

  wipGdsRcptTimestamp: 0,
  wipGdsRcpts: [],
  wipGdsRcptIsLoading: false,
  wipGdsRcptSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  wipGdsRcptFilters: {},
  wipGdsRcptPageSize: '20',
  wipGdsRcptCurrentPage: 1,
  wipGdsRcptLastPage: 10,
  wipGdsRcptTotal: 0,

  wipWhseJobTimestamp: 0,
  wipWhseJobs: [],
  wipWhseJobIsLoading: false,
  wipWhseJobSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  wipWhseJobFilters: {},
  wipWhseJobPageSize: '20',
  wipWhseJobCurrentPage: 1,
  wipWhseJobLastPage: 10,
  wipWhseJobTotal: 0,

  completeGdsRcptTimestamp: 0,
  completeGdsRcpts: [],
  completeGdsRcptIsLoading: false,
  completeGdsRcptSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  completeGdsRcptFilters: {},
  completeGdsRcptPageSize: '20',
  completeGdsRcptCurrentPage: 1,
  completeGdsRcptLastPage: 10,
  completeGdsRcptTotal: 0,

  wipWhseJobDialogIsVisible: false,
  wipWhseJobDocId: 0,
  wipWhseJobDocument: { doc_code: '', doc_status: 3 },
  wipWhseJobDocumentIsLoading: false,

  wipGdsRcptDialogIsVisible: false,
  wipGdsRcptDocId: 0,
  wipGdsRcptDocument: { doc_code: '', doc_status: 3 },
  wipGdsRcptDocumentIsLoading: false,

  wipInbOrdDialogIsVisible: false,
  wipInbOrdDocId: 0,
  wipInbOrdDocument: { doc_code: '', doc_status: 3 },
  wipInbOrdDocumentIsLoading: false,

  completeGdsRcptDialogIsVisible: false,
  completeGdsRcptDocId: 0,
  completeGdsRcptDocument: { doc_code: '', doc_status: 3 },
  completeGdsRcptDocumentIsLoading: false
};
