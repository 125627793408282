import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import InvDoc0101Actions, { InvDoc0101Types } from '../Stores/InvDoc0101/Actions';

const getAppStore = state => state.app;

export function* invDoc0101FetchInvDoc0101({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(InvDoc0101Actions.invDoc0101FetchInvDoc0101Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `invDoc/indexProcess/INV_DOC_01_01/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        InvDoc0101Actions.invDoc0101FetchInvDoc0101Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(InvDoc0101Actions.invDoc0101FetchInvDoc0101Loading(false));
  }
}

export function* invDoc0101PrintInvDoc0101({ hdrIds }) {
  try {
    yield put(InvDoc0101Actions.invDoc0101PrintInvDoc0101Loading(true));

    const app = yield select(getAppStore);

    const getData = {
      hdrIds
    };

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `invDoc/printProcess/INV_DOC_01_01`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      const selectedDocuments = hdrIds.map(d => ({ id: d }));

      FileSaver.saveAs(result.data, 'InvDoc.pdf');
      yield put(InvDoc0101Actions.invDoc0101RemoveSelectedDocuments(selectedDocuments));
      yield put(InvDoc0101Actions.invDoc0101ResetTimestamp());
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(InvDoc0101Actions.invDoc0101PrintInvDoc0101Loading(false));
  }
}

export const saga = [
  takeLatest(InvDoc0101Types.INV_DOC0101_FETCH_INV_DOC0101, invDoc0101FetchInvDoc0101),
  takeLatest(InvDoc0101Types.INV_DOC0101_PRINT_INV_DOC0101, invDoc0101PrintInvDoc0101)
];
