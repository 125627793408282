import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  invDoc03ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  invDoc03GoToOrderDoc: ['hdrId', 'itemId'],
  invDoc03FetchInvDoc03: ['currentPage', 'sorts', 'filters', 'pageSize'],
  invDoc03FetchInvDoc03Loading: ['boolean'],
  invDoc03FetchInvDoc03Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  invDoc03AddSelectedDocuments: ['selectedDocuments'],
  invDoc03RemoveSelectedDocuments: ['selectedDocuments'],
  invDoc03SetWorkspaceVisible: ['boolean'],
  invDoc03CreateInvDoc03: ['hdrIds'],
  invDoc03CreateInvDoc03Loading: ['boolean'],
  invDoc03CreateInvDoc03Success: ['newDocuments'],

  invDoc03SetExpandedRows: ['expandedRows']
});

export const InvDoc03Types = Types;
export default Creators;
