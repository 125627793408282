import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  principalProcessFetchPrincipalProcess: null,
  principalProcessFetchPrincipalProcessLoading: ['boolean'],
  principalProcessFetchPrincipalProcessSuccess: ['process']
});

export const PrincipalProcessTypes = Types;
export default Creators;
