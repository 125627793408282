import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  workerLoadListAnalysisReportInitWorkerLoadListAnalysis: null,
  workerLoadListAnalysisReportInitWorkerLoadListAnalysisSuccess: ['criteria'],
  workerLoadListAnalysisReportWorkerLoadListAnalysis: ['formikBag', 'criteria'],
  workerLoadListAnalysisReportWorkerLoadListAnalysisSuccess: ['criteria', 'reportData'],
  workerLoadListAnalysisReportReportLoading: ['boolean'],

  workerLoadListAnalysisReportFetchUserOptions: ['search'],
  workerLoadListAnalysisReportFetchUserOptionLoading: ['boolean'],
  workerLoadListAnalysisReportFetchUserOptionSuccess: ['options']
});

export const WorkerLoadListAnalysisReportTypes = Types;
export default Creators;
