import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  prodRcpt01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prodRcpt01GoToDocument: ['hdrId'],
  prodRcpt01NewDocument: null,
  prodRcpt01FetchProdRcpt01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prodRcpt01FetchProdRcpt01Loading: ['boolean'],
  prodRcpt01FetchProdRcpt01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  prodRcpt01AddSelectedDocuments: ['selectedDocuments'],
  prodRcpt01RemoveSelectedDocuments: ['selectedDocuments'],
  prodRcpt01SetWorkspaceVisible: ['boolean'],
  prodRcpt01CreateProdRcpt01: ['hdrIds'],
  prodRcpt01CreateProdRcpt01Loading: ['boolean'],
  prodRcpt01CreateProdRcpt01Success: ['newDocuments']
});

export const ProdRcpt01Types = Types;
export default Creators;
