import { put, call, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
// import ApiService from '../Services/ApiService';
// import AppActions from '../Stores/App/Actions';
import CountAdjReasonProcessActions, {
  CountAdjReasonProcessTypes
} from '../Stores/CountAdjReasonProcess/Actions';

// const getAppStore = state => state.app;

// eslint-disable-next-line import/prefer-default-export
export function* countAdjReasonProcessFetchCountAdjReasonProcess() {
  try {
    yield put(
      CountAdjReasonProcessActions.countAdjReasonProcessFetchCountAdjReasonProcessLoading(true)
    );

    const result = [
      { proc_type: 'COUNT_ADJ_REASON_LIST_01', icon: 'fa fa-cubes' }
      // { proc_type: 'COUNT_ADJ_REASON_EXCEL_01', icon: 'fa fa-file-excel-o' },
      // { proc_type: 'COUNT_ADJ_REASON_SYNC_01', icon: 'fa fa-refresh' }
    ];
    yield put(
      CountAdjReasonProcessActions.countAdjReasonProcessFetchCountAdjReasonProcessSuccess(result)
    );

    /*
    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `site/indexItemFlow/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );
    
    if (result.isSuccess === true) {
      yield put(CountAdjReasonProcessActions.countAdjReasonProcessFetchCountAdjReasonProcessSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
    */
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      CountAdjReasonProcessActions.countAdjReasonProcessFetchCountAdjReasonProcessLoading(false)
    );
  }
}

export const saga = [
  takeLatest(
    CountAdjReasonProcessTypes.COUNT_ADJ_REASON_PROCESS_FETCH_COUNT_ADJ_REASON_PROCESS,
    countAdjReasonProcessFetchCountAdjReasonProcess
  )
];
