import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { SearchOutlined } from '@ant-design/icons';

import { Card, Button, Typography, Select, Row, Col, Breadcrumb } from 'antd';
import BarcodeReader from '../../Components/BarcodeReader';
import HeaderForm from './HeaderForm';
import OutbOrdTable from './OutbOrdTable';
import InbOrdTable from './InbOrdTable';
import LoadListDetailActions from '../../Stores/LoadListDetail/Actions';

const { Option } = Select;
const { Text } = Typography;

class LoadListDetailScreen extends React.PureComponent {
  constructor() {
    super();

    this.state = { searchValue: '' };

    this.handleScan = this.handleScan.bind(this);
    this.handleError = this.handleError.bind(this);

    this.handleSearchDocument = this.handleSearchDocument.bind(this);
    this.handleAddDocument = this.handleAddDocument.bind(this);
  }

  componentDidMount() {
    const { match, setHdrId } = this.props;

    if (match.params.action === 'create') {
      setHdrId(0);
    } else if (match.params.action === 'update') {
      setHdrId(parseInt(match.params.id, 10));
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleScan(data) {
    const { scanBarcode } = this.props;

    scanBarcode(data);
  }

  handleError(msg, err) {
    const { scanBarcodeError } = this.props;

    scanBarcodeError(err);
  }

  handleSearchDocument(value) {
    const { searchDocument } = this.props;

    searchDocument(value);
  }

  handleAddDocument() {
    const { hdrId, scanBarcode } = this.props;
    const { searchValue } = this.state;

    scanBarcode(hdrId, searchValue);
  }

  render() {
    const {
      intl,
      match,
      documentHeader,
      fetchIsLoading,
      documentOptions,
      documentIsLoading,
      appPath
    } = this.props;
    const { searchValue } = this.state;

    const processedOptions = documentOptions.map(d => (
      <Option value={d.value} key={d.value}>
        <b>{d.docCode}</b>
        {` ${d.docDate} `}
        <b>{d.refCode01}</b>
      </Option>
    ));

    return (
      <Card title={intl.formatMessage({ id: 'load_list' })}>
        <BarcodeReader onError={this.handleError} onScan={this.handleScan} />
        <Breadcrumb style={{ overflowWrap: 'break-word' }}>
          {documentHeader.doc_flows.map(docData =>
            docData.doc_id > 0 ? (
              <Breadcrumb.Item key={docData.doc_id}>
                {docData.is_current === true ? (
                  docData.doc_code
                ) : (
                  <Link replace to={`${appPath}/${docData.action}/update/${docData.doc_id}`}>
                    <span style={{ color: 'blue' }}>{docData.doc_code}</span>
                  </Link>
                )}
              </Breadcrumb.Item>
            ) : (
              ''
            )
          )}
        </Breadcrumb>
        <HeaderForm match={match} />
        <Card>
          <Row type="flex" justify="center" gutter={[8, 8]}>
            <Col span={3}>
              <Text>{intl.formatMessage({ id: 'doc_code' })}</Text>
            </Col>
            <Col span={1}>
              <Text>:</Text>
            </Col>
            <Col span={17}>
              <Select
                style={{ width: '100%' }}
                mode="default"
                showSearch
                value={searchValue}
                showArrow
                filterOption={false}
                /*
              onDropdownVisibleChange={open => {
                if (open && processedOptions.length === 0) {
                  this.handleSearchDocument('');
                }
              }}
              */
                onSearch={value => {
                  this.handleSearchDocument(value);
                }}
                onSelect={value => {
                  this.setState({
                    searchValue: value
                  });
                }}
                onDeselect={() => {
                  this.setState({
                    searchValue: ''
                  });
                }}
                notFoundContent={null}
                loading={documentIsLoading}
              >
                {processedOptions}
              </Select>
              <Text style={{ color: 'red' }}>
                {`*${intl.formatMessage({
                  id: 'please_enter_at_least_5_characters'
                })}`}
              </Text>
            </Col>
            <Col span={3}>
              <Button
                type="primary"
                disabled={searchValue.length === 0}
                loading={fetchIsLoading || documentIsLoading}
                onClick={this.handleAddDocument}
                icon={<SearchOutlined />}
              >
                {intl.formatMessage({ id: 'search' })}
              </Button>
            </Col>
          </Row>
        </Card>
        <OutbOrdTable match={match} />
        <InbOrdTable match={match} />
      </Card>
    );
  }
}

LoadListDetailScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,

  hdrId: PropTypes.number,

  scanBarcode: PropTypes.func,
  scanBarcodeError: PropTypes.func,

  setHdrId: PropTypes.func,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number,
    doc_flows: PropTypes.arrayOf(PropTypes.object)
  }),

  fetchIsLoading: PropTypes.bool,

  searchDocument: PropTypes.func,
  documentOptions: PropTypes.arrayOf(PropTypes.object),
  documentIsLoading: PropTypes.bool,

  appPath: PropTypes.string
};

LoadListDetailScreen.defaultProps = {
  intl: {},
  match: {},

  hdrId: 0,

  scanBarcode() {},
  scanBarcodeError() {},

  setHdrId() {},
  documentHeader: { doc_status: 3, doc_flows: [] },

  fetchIsLoading: false,

  searchDocument() {},
  documentOptions: [],
  documentIsLoading: false,

  appPath: ''
};

const mapStateToProps = state => ({
  hdrId: state.loadListDetail.hdrId,

  fetchIsLoading: state.loadListDetail.fetchIsLoading,
  documentHeader: state.loadListDetail.documentHeader,

  documentOptions: state.loadListDetail.documentOptions,
  documentIsLoading: state.loadListDetail.documentIsLoading,

  appPath: state.app.appPath
});

const mapDispatchToProps = dispatch => ({
  setHdrId: hdrId => dispatch(LoadListDetailActions.loadListDetailSetHdrId(hdrId)),
  setDetailVisible: boolean =>
    dispatch(LoadListDetailActions.loadListDetailSetDetailVisible(boolean)),

  setWhseJobType: whseJobType =>
    dispatch(LoadListDetailActions.loadListDetailSetWhseJobType(whseJobType)),

  scanBarcode: (hdrId, value) =>
    dispatch(LoadListDetailActions.loadListDetailScanBarcode(hdrId, value)),
  scanBarcodeError: value => dispatch(LoadListDetailActions.loadListDetailScanBarcodeError(value)),

  searchDocument: value => dispatch(LoadListDetailActions.loadListDetailSearchDocument(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoadListDetailScreen));
