/**
 * The initial values for the redux state.
 */
export default {
  syncSetting: {
    url: '',
    page_size: 1000,
    username: '',
    password: '',
    last_synced_at: '2000-01-01 00:00:00'
  },
  batchJobStatus: {
    proc_type: 0,
    user_id: 0,
    status_number: 0
  },

  timestamp: 0,
  documents: [],
  fetchIsLoading: false,
  sorts: {
    doc_code: 'descend'
  },
  filters: {},
  pageSize: '20',
  currentPage: 1,
  lastPage: 10,
  total: 0,
  selectedDocuments: [],
  workspaceIsVisible: false,
  exportIsLoading: false,

  expandedRows: []
};
