import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { ArrowLeftOutlined, UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography, Radio } from 'antd';
import treeChanges from 'tree-changes';
import {
  FormikInput,
  FormikSelect,
  FormikRadio,
  FormikButton,
  FormikInputNumber
} from '../../Components/Formik';
import TransportDetailActions from '../../Stores/TransportDetail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class ModelForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchLocation = this.handleSearchLocation.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { resId, initModel, showModel } = this.props;

    const { changed } = treeChanges(prevProps, this.props);
    if (changed('timestamp')) {
      if (resId <= 0) {
        initModel();
      } else {
        showModel(resId);
      }
    }
  }

  componentWillUnmount() {}

  handleSearchLocation(form, value) {
    const { fetchLocationOptions } = this.props;

    fetchLocationOptions(value);
  }

  render() {
    const {
      intl,
      match,
      model,
      createModel,
      updateModel,
      historyGoBack,
      modelIsLoading,
      locationIsLoading,
      locationOptions
    } = this.props;

    const transportTypeOptions = [
      <Radio.Button key={1} value={1}>
        {intl.formatMessage({ id: 'OWN_TEAM' })}
      </Radio.Button>,
      <Radio.Button key={2} value={2}>
        {intl.formatMessage({ id: 'THIRD_PARTY_INDIVIDUAL' })}
      </Radio.Button>,
      <Radio.Button key={3} value={3}>
        {intl.formatMessage({ id: 'THIRD_PARTY_COMPANY' })}
      </Radio.Button>
    ];

    const disabled = false;

    return (
      <Formik
        enableReinitialize
        initialValues={model}
        onSubmit={(values, formikBag) => {
          if (match.params.action === 'create') {
            createModel(formikBag, values);
          } else {
            updateModel(formikBag, values);
          }
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required(intl.formatMessage({ id: 'code_is_required' })),
          transport_type: Yup.number().min(1, intl.formatMessage({ id: 'type_is_required' })),
          desc_01: Yup.string().required(intl.formatMessage({ id: 'description_is_required' })),
          location_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'location_is_required' }))
          })
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'code' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="code" component={FormikInput} disabled={disabled || modelIsLoading} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'type' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="transport_type"
                  component={FormikRadio}
                  options={transportTypeOptions}
                  disabled={disabled || modelIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'description' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="desc_01"
                  component={FormikInput}
                  disabled={disabled || modelIsLoading}
                />
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3} />
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="desc_02"
                  component={FormikInput}
                  disabled={disabled || modelIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'min_load_length' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field name="min_load_length" component={FormikInputNumber} disabled={disabled} />
              </Col>
              <Col span={12} />
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'min_load_width' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field name="min_load_width" component={FormikInputNumber} disabled={disabled} />
              </Col>
              <Col span={12} />
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'min_load_height' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field name="min_load_height" component={FormikInputNumber} disabled={disabled} />
              </Col>
              <Col span={12} />
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'min_load_weight' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field name="min_load_weight" component={FormikInputNumber} disabled={disabled} />
              </Col>
              <Col span={12} />
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'max_load_length' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field name="max_load_length" component={FormikInputNumber} disabled={disabled} />
              </Col>
              <Col span={12} />
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'max_load_width' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field name="max_load_width" component={FormikInputNumber} disabled={disabled} />
              </Col>
              <Col span={12} />
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'max_load_height' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field name="max_load_height" component={FormikInputNumber} disabled={disabled} />
              </Col>
              <Col span={12} />
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'max_load_weight' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field name="max_load_weight" component={FormikInputNumber} disabled={disabled} />
              </Col>
              <Col span={12} />
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'location' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="location_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchLocation}
                  notFoundContent={null}
                  loading={locationIsLoading}
                  options={locationOptions}
                  disabled={disabled || modelIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="space-between" gutter={[0, 16]}>
              <Col span={8}>
                <Button
                  type="primary"
                  // disabled={!isValid}
                  loading={isSubmitting}
                  onClick={historyGoBack}
                  icon={<ArrowLeftOutlined />}
                >
                  {intl.formatMessage({
                    id: 'back'
                  })}
                </Button>
              </Col>
              <Col span={5}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting || modelIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  name="submit_action"
                  type="primary"
                  value="update"
                  component={FormikButton}
                  // disabled={values.status >= 50}
                  loading={isSubmitting || modelIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: match.params.action === 'create' ? 'create' : 'update'
                  })}
                  icon={<SaveOutlined />}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

ModelForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,

  initModel: PropTypes.func,
  showModel: PropTypes.func,
  createModel: PropTypes.func,
  updateModel: PropTypes.func,
  historyGoBack: PropTypes.func,
  resId: PropTypes.number,
  model: PropTypes.shape({
    status: PropTypes.number
  }),
  modelIsLoading: PropTypes.bool,

  fetchLocationOptions: PropTypes.func,
  locationIsLoading: PropTypes.bool,
  locationOptions: PropTypes.arrayOf(PropTypes.object)
};

ModelForm.defaultProps = {
  intl: {},
  match: {},

  initModel() {},
  showModel() {},
  createModel() {},
  updateModel() {},
  historyGoBack() {},
  resId: 0,
  model: { status: 2 },
  modelIsLoading: false,

  fetchLocationOptions() {},
  locationIsLoading: false,
  locationOptions: []
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.transportDetail.timestamp,
  resId: state.transportDetail.resId,
  model: state.transportDetail.model,
  modelIsLoading: state.transportDetail.modelIsLoading,

  locationIsLoading: state.transportDetail.locationIsLoading,
  locationOptions: state.transportDetail.locationOptions
});

const mapDispatchToProps = dispatch => ({
  initModel: () => dispatch(TransportDetailActions.transportDetailInitModel()),
  showModel: resId => dispatch(TransportDetailActions.transportDetailShowModel(resId)),

  updateModel: (formikBag, model) =>
    dispatch(TransportDetailActions.transportDetailUpdateModel(formikBag, model)),
  createModel: (formikBag, model) =>
    dispatch(TransportDetailActions.transportDetailCreateModel(formikBag, model)),

  fetchLocationOptions: search =>
    dispatch(TransportDetailActions.transportDetailFetchLocationOptions(search)),

  historyGoBack: () => dispatch(AppActions.appHistoryGoBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModelForm));
