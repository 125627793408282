import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';

import { Card, Button, Typography, Select, Row, Col, Breadcrumb, List, Divider } from 'antd';
import treeChanges from 'tree-changes';
import BarcodeReader from '../../Components/BarcodeReader';
import HeaderForm from './HeaderForm';
import OutbOrdTable from './OutbOrdTable';
import PrfDelDetailActions from '../../Stores/PrfDelDetail/Actions';

const { Option } = Select;
const { Text } = Typography;

class PrfDelDetailScreen extends React.PureComponent {
  constructor() {
    super();

    this.state = { searchValue: '' };

    this.handleScan = this.handleScan.bind(this);
    this.handleError = this.handleError.bind(this);

    this.handleSearchDocument = this.handleSearchDocument.bind(this);
    this.handleAddDocument = this.handleAddDocument.bind(this);
  }

  componentDidMount() {
    const { match, setHdrId } = this.props;

    if (match.params.action === 'create') {
      setHdrId(0);
    } else if (match.params.action === 'update') {
      setHdrId(parseInt(match.params.id, 10));
    }
  }

  componentDidUpdate(prevProps) {
    const { hdrId, showDocPhotos } = this.props;

    const { changed } = treeChanges(prevProps, this.props);
    if (changed('timestamp')) {
      showDocPhotos(hdrId);
    }
  }

  componentWillUnmount() {}

  handleScan(data) {
    const { scanBarcode } = this.props;

    scanBarcode(data);
  }

  handleError(msg, err) {
    const { scanBarcodeError } = this.props;

    scanBarcodeError(err);
  }

  handleSearchDocument(value) {
    const { searchDocument } = this.props;

    searchDocument(value);
  }

  handleAddDocument() {
    const { hdrId, scanBarcode } = this.props;
    const { searchValue } = this.state;

    scanBarcode(hdrId, searchValue);
  }

  render() {
    const {
      intl,
      match,
      hdrId,
      documentHeader,
      fetchIsLoading,
      documentOptions,
      documentIsLoading,
      appPath,
      deleteDocPhoto,
      docPhotos
    } = this.props;
    const { searchValue } = this.state;

    const processedOptions = documentOptions.map(d => (
      <Option value={d.value} key={d.value}>
        <b>{d.docCode}</b>
        {` ${d.docDate} `}
        <b>{d.refCode01}</b>
      </Option>
    ));

    return (
      <Card title={intl.formatMessage({ id: 'proof_of_delivery' })}>
        <BarcodeReader onError={this.handleError} onScan={this.handleScan} />
        <Breadcrumb style={{ overflowWrap: 'break-word' }}>
          {documentHeader.doc_flows.map(docData =>
            docData.doc_id > 0 ? (
              <Breadcrumb.Item key={docData.doc_id}>
                {docData.is_current === true ? (
                  docData.doc_code
                ) : (
                  <Link replace to={`${appPath}/${docData.action}/update/${docData.doc_id}`}>
                    <span style={{ color: 'blue' }}>{docData.doc_code}</span>
                  </Link>
                )}
              </Breadcrumb.Item>
            ) : (
              ''
            )
          )}
        </Breadcrumb>
        <HeaderForm match={match} />
        <Card>
          <Row type="flex" justify="center" gutter={[8, 8]}>
            <Col span={3}>
              <Text>{intl.formatMessage({ id: 'doc_code' })}</Text>
            </Col>
            <Col span={1}>
              <Text>:</Text>
            </Col>
            <Col span={17}>
              <Select
                style={{ width: '100%' }}
                mode="default"
                showSearch
                value={searchValue}
                showArrow
                filterOption={false}
                /*
              onDropdownVisibleChange={open => {
                if (open && processedOptions.length === 0) {
                  this.handleSearchDocument('');
                }
              }}
              */
                onSearch={value => {
                  this.handleSearchDocument(value);
                }}
                onSelect={value => {
                  this.setState({
                    searchValue: value
                  });
                }}
                onDeselect={() => {
                  this.setState({
                    searchValue: ''
                  });
                }}
                notFoundContent={null}
                loading={documentIsLoading}
              >
                {processedOptions}
              </Select>
              <Text style={{ color: 'red' }}>
                {`*${intl.formatMessage({
                  id: 'please_enter_at_least_5_characters'
                })}`}
              </Text>
            </Col>
            <Col span={3}>
              <Button
                type="primary"
                disabled={searchValue.length === 0}
                loading={fetchIsLoading || documentIsLoading}
                onClick={this.handleAddDocument}
                icon={<SearchOutlined />}
              >
                {intl.formatMessage({ id: 'search' })}
              </Button>
            </Col>
          </Row>
        </Card>
        <OutbOrdTable match={match} />
        {docPhotos && Array.isArray(docPhotos) && docPhotos.length > 0 && (
          <>
            <Divider />
            <List
              itemLayout="vertical"
              size="large"
              dataSource={docPhotos}
              renderItem={docPhoto => (
                <List.Item
                  key={docPhoto.id}
                  actions={[
                    <Button
                      type="primary"
                      icon={<DeleteOutlined />}
                      disabled={documentHeader.doc_status >= 100}
                      loading={documentIsLoading || fetchIsLoading}
                      onClick={() => deleteDocPhoto(hdrId, docPhoto.id)}
                    />
                  ]}
                  extra={<img alt={docPhoto.desc_01} src={docPhoto.medium_url} />}
                >
                  <List.Item.Meta description={`${docPhoto.desc_01} ${docPhoto.desc_02}`} />
                </List.Item>
              )}
            />
          </>
        )}
      </Card>
    );
  }
}

PrfDelDetailScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,

  hdrId: PropTypes.number,

  scanBarcode: PropTypes.func,
  scanBarcodeError: PropTypes.func,

  setHdrId: PropTypes.func,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number,
    doc_flows: PropTypes.arrayOf(PropTypes.object)
  }),

  fetchIsLoading: PropTypes.bool,

  searchDocument: PropTypes.func,
  documentOptions: PropTypes.arrayOf(PropTypes.object),
  documentIsLoading: PropTypes.bool,
  appPath: PropTypes.string,

  showDocPhotos: PropTypes.func,
  deleteDocPhoto: PropTypes.func,

  docPhotos: PropTypes.arrayOf(PropTypes.object)
};

PrfDelDetailScreen.defaultProps = {
  intl: {},
  match: {},

  hdrId: 0,

  scanBarcode() {},
  scanBarcodeError() {},

  setHdrId() {},
  documentHeader: { doc_status: 3, doc_flows: [] },

  fetchIsLoading: false,

  searchDocument() {},
  documentOptions: [],
  documentIsLoading: false,
  appPath: '',

  showDocPhotos() {},
  deleteDocPhoto() {},
  docPhotos: []
};

const mapStateToProps = state => ({
  timestamp: state.prfDelDetail.timestamp,
  hdrId: state.prfDelDetail.hdrId,

  fetchIsLoading: state.prfDelDetail.fetchIsLoading,
  documentHeader: state.prfDelDetail.documentHeader,

  documentOptions: state.prfDelDetail.documentOptions,
  documentIsLoading: state.prfDelDetail.documentIsLoading,
  docPhotos: state.prfDelDetail.docPhotos,

  appPath: state.app.appPath
});

const mapDispatchToProps = dispatch => ({
  setHdrId: hdrId => dispatch(PrfDelDetailActions.prfDelDetailSetHdrId(hdrId)),
  setDetailVisible: boolean => dispatch(PrfDelDetailActions.prfDelDetailSetDetailVisible(boolean)),

  setWhseJobType: whseJobType =>
    dispatch(PrfDelDetailActions.prfDelDetailSetWhseJobType(whseJobType)),

  scanBarcode: (hdrId, value) =>
    dispatch(PrfDelDetailActions.prfDelDetailScanBarcode(hdrId, value)),
  scanBarcodeError: value => dispatch(PrfDelDetailActions.prfDelDetailScanBarcodeError(value)),

  searchDocument: value => dispatch(PrfDelDetailActions.prfDelDetailSearchDocument(value)),
  showDocPhotos: prfDelHdrId =>
    dispatch(PrfDelDetailActions.prfDelDetailShowDocPhotos(prfDelHdrId)),
  deleteDocPhoto: (prfDelHdrId, docPhotoId) =>
    dispatch(PrfDelDetailActions.prfDelDetailDeleteDocPhoto(prfDelHdrId, docPhotoId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PrfDelDetailScreen));
