import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  advShipSync02ShowSyncSetting: null,
  advShipSync02ShowSyncSettingLoading: ['boolean'],
  advShipSync02ShowSyncSettingSuccess: ['syncSetting'],
  advShipSync02UpdateSyncSetting: ['formikBag', 'syncSetting'],
  advShipSync02ShowBatchJobStatus: null,
  advShipSync02ShowBatchJobStatusSuccess: ['batchJobStatus'],
  advShipSync02SyncNow: ['formikBag'],
  advShipSync02SyncLoading: ['boolean']
});

export const AdvShipSync02Types = Types;
export default Creators;
