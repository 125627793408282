import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspAdvShipDetailResetTimestamp: null,
  lspAdvShipDetailSetHdrId: ['hdrId', 'itemId'],

  lspAdvShipDetailInitHeader: null,
  lspAdvShipDetailShowHeader: ['hdrId'],
  lspAdvShipDetailShowHeaderSuccess: ['documentHeader'],

  lspAdvShipDetailUpdateHeader: ['formikBag', 'documentHeader'],
  lspAdvShipDetailCreateHeader: ['formikBag', 'documentHeader'],
  lspAdvShipDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  lspAdvShipDetailShowDocumentLoading: ['boolean'],
  lspAdvShipDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  lspAdvShipDetailShowDetails: ['hdrId'],
  lspAdvShipDetailShowDetailsSuccess: ['documentDetails'],

  lspAdvShipDetailSetDetailVisible: ['boolean'],
  lspAdvShipDetailSetDocumentDetail: ['documentDetail'],

  lspAdvShipDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  lspAdvShipDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  lspAdvShipDetailDeleteDetail: ['hdrId', 'documentDetail'],

  lspAdvShipDetailFetchDebtorOptions: ['search'],
  lspAdvShipDetailFetchDebtorOptionLoading: ['boolean'],
  lspAdvShipDetailFetchDebtorOptionSuccess: ['options'],
  lspAdvShipDetailChangeDebtor: ['formikBag', 'debtorId'],

  lspAdvShipDetailFetchPurchaserOptions: ['search'],
  lspAdvShipDetailFetchPurchaserOptionLoading: ['boolean'],
  lspAdvShipDetailFetchPurchaserOptionSuccess: ['options'],

  lspAdvShipDetailFetchBizPartnerOptions: ['debtorId', 'search'],
  lspAdvShipDetailFetchBizPartnerOptionLoading: ['boolean'],
  lspAdvShipDetailFetchBizPartnerOptionSuccess: ['options'],
  lspAdvShipDetailChangeBizPartner: ['formikBag', 'bizPartnerId'],

  lspAdvShipDetailFetchCreditTermOptions: ['search'],
  lspAdvShipDetailFetchCreditTermOptionLoading: ['boolean'],
  lspAdvShipDetailFetchCreditTermOptionSuccess: ['options'],

  lspAdvShipDetailFetchCurrencyOptions: ['search'],
  lspAdvShipDetailFetchCurrencyOptionLoading: ['boolean'],
  lspAdvShipDetailFetchCurrencyOptionSuccess: ['options'],
  lspAdvShipDetailChangeCurrency: ['formikBag', 'currencyId'],

  lspAdvShipDetailFetchLocationOptions: ['search'],
  lspAdvShipDetailFetchLocationOptionLoading: ['boolean'],
  lspAdvShipDetailFetchLocationOptionSuccess: ['options'],

  lspAdvShipDetailFetchItemOptions: ['search'],
  lspAdvShipDetailFetchItemOptionLoading: ['boolean'],
  lspAdvShipDetailFetchItemOptionSuccess: ['options'],
  lspAdvShipDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  lspAdvShipDetailFetchUomOptions: ['itemId', 'search'],
  lspAdvShipDetailFetchUomOptionLoading: ['boolean'],
  lspAdvShipDetailFetchUomOptionSuccess: ['options'],
  lspAdvShipDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  lspAdvShipDetailFetchProjectOptions: ['search'],
  lspAdvShipDetailFetchProjectOptionLoading: ['boolean'],
  lspAdvShipDetailFetchProjectOptionSuccess: ['options']
});

export const LspAdvShipDetailTypes = Types;
export default Creators;
