import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  workerPackListAnalysisReportInitWorkerPackListAnalysis: null,
  workerPackListAnalysisReportInitWorkerPackListAnalysisSuccess: ['criteria'],
  workerPackListAnalysisReportWorkerPackListAnalysis: ['formikBag', 'criteria'],
  workerPackListAnalysisReportWorkerPackListAnalysisSuccess: ['criteria', 'reportData'],
  workerPackListAnalysisReportReportLoading: ['boolean'],

  workerPackListAnalysisReportFetchCompanyOptions: ['search'],
  workerPackListAnalysisReportFetchCompanyOptionLoading: ['boolean'],
  workerPackListAnalysisReportFetchCompanyOptionSuccess: ['options'],

  workerPackListAnalysisReportFetchLocationOptions: ['search'],
  workerPackListAnalysisReportFetchLocationOptionLoading: ['boolean'],
  workerPackListAnalysisReportFetchLocationOptionSuccess: ['options']
});

export const WorkerPackListAnalysisReportTypes = Types;
export default Creators;
