import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import OutbOrd08Actions, { OutbOrd08Types } from '../Stores/OutbOrd08/Actions';

const getAppStore = state => state.app;

export function* outbOrd08FetchOutbOrd08({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(OutbOrd08Actions.outbOrd08FetchOutbOrd08Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexProcess/OUTB_ORD_08/${app.curDivisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        OutbOrd08Actions.outbOrd08FetchOutbOrd08Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(OutbOrd08Actions.outbOrd08FetchOutbOrd08Loading(false));
  }
}

export function* outbOrd08CreateOutbOrd08({ hdrIds }) {
  try {
    yield put(OutbOrd08Actions.outbOrd08CreateOutbOrd08Loading(true));

    const app = yield select(getAppStore);

    const postData = {
      hdrIds
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `outbOrd/createProcess/OUTB_ORD_08`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(OutbOrd08Actions.outbOrd08CreateOutbOrd08Success(result.data));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(OutbOrd08Actions.outbOrd08CreateOutbOrd08Loading(false));
  }
}

export function* outbOrd08GoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/purRtnDetail/update/${hdrId}`));
}

export function* outbOrd08NewDocument() {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/purRtnDetail/create/${app.curDivisionId}`));
}

export function* outbOrd08CheckStock({ hdrIds }) {
  try {
    yield put(OutbOrd08Actions.outbOrd08CreateOutbOrd08Loading(true));

    const app = yield select(getAppStore);

    const getData = {
      hdrIds
    };

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `purRtn/checkStock`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, 'CHECK_STOCK.XLSX');
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(OutbOrd08Actions.outbOrd08CreateOutbOrd08Loading(false));
  }
}

export const saga = [
  takeLatest(OutbOrd08Types.OUTB_ORD08_FETCH_OUTB_ORD08, outbOrd08FetchOutbOrd08),
  takeLatest(OutbOrd08Types.OUTB_ORD08_CREATE_OUTB_ORD08, outbOrd08CreateOutbOrd08),
  takeLatest(OutbOrd08Types.OUTB_ORD08_GO_TO_DOCUMENT, outbOrd08GoToDocument),
  takeLatest(OutbOrd08Types.OUTB_ORD08_NEW_DOCUMENT, outbOrd08NewDocument),
  takeLatest(OutbOrd08Types.OUTB_ORD08_CHECK_STOCK, outbOrd08CheckStock)
];
