import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob030102ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob030102GoToWhseJobDocument: ['hdrId'],
  whseJob030102FetchWhseJob030102: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob030102FetchWhseJob030102Loading: ['boolean'],
  whseJob030102FetchWhseJob030102Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  whseJob030102AddSelectedDocuments: ['selectedDocuments'],
  whseJob030102RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob030102SetWorkspaceVisible: ['boolean'],
  whseJob030102PrintWhseJob030102: ['hdrIds'],
  whseJob030102PrintWhseJob030102Loading: ['boolean'],

  whseJob030102SetExpandedRows: ['expandedRows'],
  whseJob030102DownloadExcel: null
});

export const WhseJob030102Types = Types;
export default Creators;
