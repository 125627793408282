/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { GdsIss01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const gdsIss01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  toStorageBinOptions: INITIAL_STATE.toStorageBinOptions,
  toStorageBinOption: INITIAL_STATE.toStorageBinOption
});

export const gdsIss01FetchGdsIss01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const gdsIss01FetchGdsIss01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const gdsIss01AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const gdsIss01RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const gdsIss01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const gdsIss01CreateGdsIss01Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const gdsIss01CreateGdsIss01Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GdsIss01Types.GDS_ISS01_RESET_TIMESTAMP]: gdsIss01ResetTimestamp,
  [GdsIss01Types.GDS_ISS01_FETCH_GDS_ISS01_LOADING]: gdsIss01FetchGdsIss01Loading,
  [GdsIss01Types.GDS_ISS01_FETCH_GDS_ISS01_SUCCESS]: gdsIss01FetchGdsIss01Success,
  [GdsIss01Types.GDS_ISS01_ADD_SELECTED_DOCUMENTS]: gdsIss01AddSelectedDocuments,
  [GdsIss01Types.GDS_ISS01_REMOVE_SELECTED_DOCUMENTS]: gdsIss01RemoveSelectedDocuments,
  [GdsIss01Types.GDS_ISS01_SET_WORKSPACE_VISIBLE]: gdsIss01SetWorkspaceVisible,
  [GdsIss01Types.GDS_ISS01_CREATE_GDS_ISS01_LOADING]: gdsIss01CreateGdsIss01Loading,
  [GdsIss01Types.GDS_ISS01_CREATE_GDS_ISS01_SUCCESS]: gdsIss01CreateGdsIss01Success
});
