import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import InbOrd06Actions, { InbOrd06Types } from '../Stores/InbOrd06/Actions';

const getAppStore = state => state.app;

export function* inbOrd06FetchInbOrd06({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(InbOrd06Actions.inbOrd06FetchInbOrd06Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `inbOrd/indexProcess/INB_ORD_06/${app.curDivisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        InbOrd06Actions.inbOrd06FetchInbOrd06Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(InbOrd06Actions.inbOrd06FetchInbOrd06Loading(false));
  }
}

export function* inbOrd06CreateInbOrd06({ hdrIds }) {
  try {
    yield put(InbOrd06Actions.inbOrd06CreateInbOrd06Loading(true));

    const app = yield select(getAppStore);

    const postData = {
      hdrIds
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `inbOrd/createProcess/INB_ORD_06`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(InbOrd06Actions.inbOrd06CreateInbOrd06Success(result.data));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(InbOrd06Actions.inbOrd06CreateInbOrd06Loading(false));
  }
}

export function* inbOrd06GoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/trfOrdDetail/update/${hdrId}`));
}

export function* inbOrd06GoToPrint({ document }) {
  try {
    yield put(InbOrd06Actions.inbOrd06FetchInbOrd06Loading(true));

    const app = yield select(getAppStore);

    const getData = {
      hdrIds: [document.id]
    };

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `trfOrd/print/${app.curDivisionId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, `TrfOrd_${document.doc_code}.pdf`);
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(InbOrd06Actions.inbOrd06FetchInbOrd06Loading(false));
  }
}

export function* inbOrd06NewDocument() {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/trfOrdDetail/create/${app.curDivisionId}/TRANSFER_FROM_STORE`));
}

export const saga = [
  takeLatest(InbOrd06Types.INB_ORD06_FETCH_INB_ORD06, inbOrd06FetchInbOrd06),
  takeLatest(InbOrd06Types.INB_ORD06_CREATE_INB_ORD06, inbOrd06CreateInbOrd06),
  takeLatest(InbOrd06Types.INB_ORD06_GO_TO_DOCUMENT, inbOrd06GoToDocument),
  takeLatest(InbOrd06Types.INB_ORD06_GO_TO_PRINT, inbOrd06GoToPrint),
  takeLatest(InbOrd06Types.INB_ORD06_NEW_DOCUMENT, inbOrd06NewDocument)
];
