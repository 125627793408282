import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  storageBinExcel01ShowBatchJobStatus: null,
  storageBinExcel01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  storageBinExcel01UploadExcel: ['file'],
  storageBinExcel01UploadLoading: ['boolean'],
  storageBinExcel01DownloadExcel: null
});

export const StorageBinExcel01Types = Types;
export default Creators;
