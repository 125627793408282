/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { DocTypeDocNoIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const docTypeDocNoIndexResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const docTypeDocNoIndexSetDocType = (state, { docType }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  docType,
  formIsVisible: false
});

export const docTypeDocNoIndexShowDocNoLoading = (state, { boolean }) => ({
  ...state,
  docNoIsLoading: boolean
});

export const docTypeDocNoIndexShowDocNosSuccess = (state, { docNos }) => ({
  ...state,
  docNos
});

export const docTypeDocNoIndexSetFormVisible = (state, { boolean }) => ({
  ...state,
  formIsVisible: boolean
});

export const docTypeDocNoIndexSetDocNo = (state, { docNo }) => ({
  ...state,
  docNo
});

export const docTypeDocNoIndexUpdateDocNoSuccess = (state, { docNo }) => ({
  ...state,
  docNo
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [DocTypeDocNoIndexTypes.DOC_TYPE_DOC_NO_INDEX_RESET_TIMESTAMP]: docTypeDocNoIndexResetTimestamp,
  [DocTypeDocNoIndexTypes.DOC_TYPE_DOC_NO_INDEX_SET_DOC_TYPE]: docTypeDocNoIndexSetDocType,

  [DocTypeDocNoIndexTypes.DOC_TYPE_DOC_NO_INDEX_SHOW_DOC_NO_LOADING]: docTypeDocNoIndexShowDocNoLoading,

  [DocTypeDocNoIndexTypes.DOC_TYPE_DOC_NO_INDEX_SHOW_DOC_NOS_SUCCESS]: docTypeDocNoIndexShowDocNosSuccess,

  [DocTypeDocNoIndexTypes.DOC_TYPE_DOC_NO_INDEX_SET_FORM_VISIBLE]: docTypeDocNoIndexSetFormVisible,
  [DocTypeDocNoIndexTypes.DOC_TYPE_DOC_NO_INDEX_SET_DOC_NO]: docTypeDocNoIndexSetDocNo,

  [DocTypeDocNoIndexTypes.DOC_TYPE_DOC_NO_INDEX_UPDATE_DOC_NO_SUCCESS]: docTypeDocNoIndexUpdateDocNoSuccess
});
