import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import './DialogStyle.css';

import { Modal, Row, Col, Typography, Skeleton } from 'antd';
import moment from 'moment';

import PickListDetailBoardActions from '../../Stores/PickListDetailBoard/Actions';

const { Text } = Typography;

class WipOutbOrdDialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.convertExpiryDate = this.convertExpiryDate.bind(this);
  }

  handleCancel() {
    const { setDialogVisible } = this.props;

    setDialogVisible(false);
  }

  convertExpiryDate(expiryDate) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    let diffDays = 0;
    if (expiryDate !== null) {
      const thisDate = new Date();
      const oneDay = 24 * 60 * 60 * 1000;
      diffDays = Math.round(Math.abs((expiryDate.getTime() - thisDate.getTime()) / oneDay));
    }

    let backgroundColor = 'white';
    if (diffDays <= 90) {
      backgroundColor = 'red';
    } else if (diffDays <= 180) {
      backgroundColor = 'orange';
    } else if (diffDays <= 270) {
      backgroundColor = 'yellow';
    }
    return backgroundColor;
  }

  render() {
    const { intl, dialogIsVisible, document, documentIsLoading } = this.props;

    return (
      <>
        <Modal
          visible={dialogIsVisible}
          title={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Text>{`${document.shipping_doc_code} ${document.doc_code}`}</Text>
          }
          // style={{top:20}}
          width="50%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          className="pick-list-dialog"
        >
          {documentIsLoading === true ? (
            <Skeleton active />
          ) : (
            <Row type="flex">
              <Col span={20} style={{ margin: 'auto' }}>
                <DialogRow
                  header={<Text>{intl.formatMessage({ id: 'created_at' })}</Text>}
                  details={<Text>{document.created_at}</Text>}
                />
                <DialogRow
                  header={<Text>{intl.formatMessage({ id: 'doc_date' })}</Text>}
                  details={<Text>{moment(document.doc_date).format('YYYY-MM-DD')}</Text>}
                />
                <DialogRow
                  header={<Text>{intl.formatMessage({ id: 'est_del_date' })}</Text>}
                  details={<Text>{moment(document.est_del_date).format('YYYY-MM-DD')}</Text>}
                />
                <DialogRow
                  header={<Text>{intl.formatMessage({ id: 'division_code' })}</Text>}
                  details={<Text>{document.division_code}</Text>}
                />
                <DialogRow
                  header={<Text>{intl.formatMessage({ id: 'delivery_point' })}</Text>}
                  details={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Text>
                      {document.delivery_point_code}
                      {document.delivery_point_company_name_01 && (
                        <>
                          <br />
                          {document.delivery_point_company_name_01}
                        </>
                      )}
                      {document.delivery_point_company_name_02 && (
                        <>
                          <br />
                          {document.delivery_point_company_name_02}
                        </>
                      )}
                    </Text>
                  }
                />
                <DialogRow
                  header={<Text>{intl.formatMessage({ id: 'address' })}</Text>}
                  details={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Text>
                      {document.delivery_point_unit_no}
                      {document.delivery_point_building_name && (
                        <>
                          <br />
                          {document.delivery_point_building_name}
                        </>
                      )}
                      {document.delivery_point_street_name && (
                        <>
                          <br />
                          {document.delivery_point_street_name}
                        </>
                      )}
                      {document.delivery_point_district_01 && (
                        <>
                          <br />
                          {document.delivery_point_district_01}
                        </>
                      )}
                      {document.delivery_point_district_02 && (
                        <>
                          <br />
                          {document.delivery_point_district_02}
                        </>
                      )}
                      {document.delivery_point_postcode && (
                        <>
                          <br />
                          {document.delivery_point_postcode}
                        </>
                      )}
                      {document.delivery_point_state_name && (
                        <>
                          <br />
                          {document.delivery_point_state_name}
                        </>
                      )}
                      {document.delivery_point_country_name && (
                        <>
                          <br />
                          {document.delivery_point_country_name}
                        </>
                      )}
                    </Text>
                  }
                />
                <DialogRow
                  header={<Text>{intl.formatMessage({ id: 'salesman' })}</Text>}
                  details={<Text>{document.salesman_username}</Text>}
                />
                <DialogRowLast
                  header={<Text>{intl.formatMessage({ id: 'net_amt' })}</Text>}
                  details={<Text>{parseFloat(document.net_amt)}</Text>}
                />
              </Col>
            </Row>
          )}
        </Modal>
      </>
    );
  }
}

function DialogRow(props) {
  // eslint-disable-next-line react/prop-types
  const { className, header, details } = props;
  return (
    <Row type="flex" gutter={[8, 8]} className={className || 'modalinfo-row'}>
      <Col span={5} className="modalinfo">
        {header}
      </Col>
      <Col span={19} className="modalinfo-details">
        {details}
      </Col>
    </Row>
  );
}

function DialogRowLast(props) {
  // eslint-disable-next-line react/prop-types
  const { header, details } = props;
  return (
    <Row type="flex" gutter={[8, 8]} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
      <Col span={5} className="modalinfo">
        {header}
      </Col>
      <Col span={19} className="modalinfo-details">
        {details}
      </Col>
    </Row>
  );
}

WipOutbOrdDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setDialogVisible: PropTypes.func,
  dialogIsVisible: PropTypes.bool,
  document: PropTypes.shape({
    doc_code: PropTypes.string,
    ref_code_01: PropTypes.string,
    shipping_doc_code: PropTypes.string,
    doc_status: PropTypes.number,
    created_at: PropTypes.string,
    doc_date: PropTypes.string,
    est_del_date: PropTypes.string,
    delivery_point_code: PropTypes.string,
    delivery_point_company_name_01: PropTypes.string,
    delivery_point_company_name_02: PropTypes.string,
    delivery_point_unit_no: PropTypes.string,
    delivery_point_building_name: PropTypes.string,
    delivery_point_street_name: PropTypes.string,
    delivery_point_district_01: PropTypes.string,
    delivery_point_district_02: PropTypes.string,
    delivery_point_postcode: PropTypes.string,
    delivery_point_state_name: PropTypes.string,
    delivery_point_country_name: PropTypes.string,
    net_amt: PropTypes.string,
    salesman_username: PropTypes.string,
    division_code: PropTypes.string
  }),
  documentIsLoading: PropTypes.bool
};

WipOutbOrdDialog.defaultProps = {
  intl: {},
  setDialogVisible() {},
  dialogIsVisible: false,
  document: {
    doc_code: '',
    doc_status: 3,
    doc_date: null
  },
  documentIsLoading: false
};

const mapStateToProps = state => ({
  timestamp: state.pickListDetailBoard.wipOutbOrdDialogTimestamp,
  dialogIsVisible: state.pickListDetailBoard.wipOutbOrdDialogIsVisible,
  docId: state.pickListDetailBoard.wipOutbOrdDocId,
  document: state.pickListDetailBoard.wipOutbOrdDocument,
  documentIsLoading: state.pickListDetailBoard.wipOutbOrdDocumentIsLoading
});

const mapDispatchToProps = dispatch => ({
  setDialogVisible: boolean =>
    dispatch(PickListDetailBoardActions.pickListDetailBoardSetWipOutbOrdDialogVisible(boolean))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WipOutbOrdDialog));
