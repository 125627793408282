import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { UndoOutlined, PlayCircleOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography, Radio } from 'antd';
import { FormikTransfer, FormikSelect, FormikButton, FormikRadio } from '../../Components/Formik';
import PickingAllocErrorReportActions from '../../Stores/PickingAllocErrorReport/Actions';

const { Text } = Typography;

class CriteriaForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchItem = this.handleSearchItem.bind(this);
    this.handleSearchItemGroup01 = this.handleSearchItemGroup01.bind(this);
    this.handleSearchItemGroup02 = this.handleSearchItemGroup02.bind(this);
    this.handleSearchItemGroup03 = this.handleSearchItemGroup03.bind(this);

    this.handleSearchStorageBin = this.handleSearchStorageBin.bind(this);
    this.handleSearchStorageRow = this.handleSearchStorageRow.bind(this);
    this.handleSearchStorageBay = this.handleSearchStorageBay.bind(this);
    this.handleSearchLocation = this.handleSearchLocation.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleSearchItem(form, value) {
    const { fetchItemOptions } = this.props;

    fetchItemOptions(value);
  }

  handleSearchItemGroup01(form, value) {
    const { fetchItemGroup01Options } = this.props;

    fetchItemGroup01Options(value);
  }

  handleSearchItemGroup02(form, value) {
    const { fetchItemGroup02Options } = this.props;

    fetchItemGroup02Options(value);
  }

  handleSearchItemGroup03(form, value) {
    const { fetchItemGroup03Options } = this.props;

    fetchItemGroup03Options(value);
  }

  handleSearchStorageBin(form, value) {
    const { fetchStorageBinOptions } = this.props;

    fetchStorageBinOptions(value);
  }

  handleSearchStorageRow(form, value) {
    const { fetchStorageRowOptions } = this.props;

    fetchStorageRowOptions(value);
  }

  handleSearchStorageBay(form, value) {
    const { fetchStorageBayOptions } = this.props;

    fetchStorageBayOptions(value);
  }

  handleSearchLocation(form, value) {
    const { fetchLocationOptions } = this.props;

    fetchLocationOptions(value);
  }

  render() {
    const {
      intl,
      criteria,
      reportIsLoading,
      pickingAllocError,
      itemOptions,
      itemIsLoading,
      itemGroup01Options,
      itemGroup01IsLoading,
      itemGroup02Options,
      itemGroup02IsLoading,
      itemGroup03Options,
      itemGroup03IsLoading,
      storageBinOptions,
      storageBinIsLoading,
      storageRowOptions,
      storageRowIsLoading,
      storageBayOptions,
      storageBayIsLoading,
      locationOptions,
      locationIsLoading
    } = this.props;

    const expiryDayOptions = [
      <Radio.Button key={0} value={0}>
        {intl.formatMessage({ id: 'expired' })}
      </Radio.Button>,
      <Radio.Button key={30} value={30}>
        {intl.formatMessage({ id: 'within_30_days' })}
      </Radio.Button>,
      <Radio.Button key={60} value={60}>
        {intl.formatMessage({ id: 'within_60_days' })}
      </Radio.Button>,
      <Radio.Button key={90} value={90}>
        {intl.formatMessage({ id: 'within_90_days' })}
      </Radio.Button>,
      <Radio.Button key={120} value={120}>
        {intl.formatMessage({ id: 'within_120_days' })}
      </Radio.Button>,
      <Radio.Button key={150} value={150}>
        {intl.formatMessage({ id: 'within_150_days' })}
      </Radio.Button>,
      <Radio.Button key={180} value={180}>
        {intl.formatMessage({ id: 'within_180_days' })}
      </Radio.Button>
    ];

    return (
      <Formik
        enableReinitialize
        initialValues={criteria}
        onSubmit={(values, formikBag) => {
          pickingAllocError(formikBag, values);
        }}
        validationSchema={Yup.object().shape({})}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItem}
                  notFoundContent={null}
                  loading={itemIsLoading}
                  options={itemOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'brand' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_group_01_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItemGroup01}
                  notFoundContent={null}
                  loading={itemGroup01IsLoading}
                  options={itemGroup01Options}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'category' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_group_02_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItemGroup02}
                  notFoundContent={null}
                  loading={itemGroup02IsLoading}
                  options={itemGroup02Options}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'manufacturer' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_group_03_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItemGroup03}
                  notFoundContent={null}
                  loading={itemGroup03IsLoading}
                  options={itemGroup03Options}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'storage_bin' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="storage_bin_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchStorageBin}
                  notFoundContent={null}
                  loading={storageBinIsLoading}
                  options={storageBinOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'storage_row' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="storage_row_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchStorageRow}
                  notFoundContent={null}
                  loading={storageRowIsLoading}
                  options={storageRowOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'storage_bay' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="storage_bay_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchStorageBay}
                  notFoundContent={null}
                  loading={storageBayIsLoading}
                  options={storageBayOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'location' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="location_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchLocation}
                  notFoundContent={null}
                  loading={locationIsLoading}
                  options={locationOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'expiry_days' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="expiry_days"
                  component={FormikRadio}
                  options={expiryDayOptions}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={24}>
                <Field
                  name="columns"
                  component={FormikTransfer}
                  intl={intl}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="space-between" gutter={[0, 16]}>
              <Col span={24}>
                <Button
                  disabled={!dirty}
                  loading={isSubmitting || reportIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                  type="primary"
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  name="submit_action"
                  value="run_report"
                  component={FormikButton}
                  loading={isSubmitting || reportIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'run_report'
                  })}
                  icon={<PlayCircleOutlined />}
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

CriteriaForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  // eslint-disable-next-line react/forbid-prop-types
  criteria: PropTypes.object,
  reportIsLoading: PropTypes.bool,
  pickingAllocError: PropTypes.func,
  fetchItemOptions: PropTypes.func,
  fetchItemGroup01Options: PropTypes.func,
  fetchItemGroup02Options: PropTypes.func,
  fetchItemGroup03Options: PropTypes.func,
  fetchStorageBinOptions: PropTypes.func,
  fetchStorageRowOptions: PropTypes.func,
  fetchStorageBayOptions: PropTypes.func,
  fetchLocationOptions: PropTypes.func,

  itemOptions: PropTypes.arrayOf(PropTypes.object),
  itemIsLoading: PropTypes.bool,

  itemGroup01Options: PropTypes.arrayOf(PropTypes.object),
  itemGroup01IsLoading: PropTypes.bool,

  itemGroup02Options: PropTypes.arrayOf(PropTypes.object),
  itemGroup02IsLoading: PropTypes.bool,

  itemGroup03Options: PropTypes.arrayOf(PropTypes.object),
  itemGroup03IsLoading: PropTypes.bool,

  storageBinOptions: PropTypes.arrayOf(PropTypes.object),
  storageBinIsLoading: PropTypes.bool,

  storageRowOptions: PropTypes.arrayOf(PropTypes.object),
  storageRowIsLoading: PropTypes.bool,

  storageBayOptions: PropTypes.arrayOf(PropTypes.object),
  storageBayIsLoading: PropTypes.bool,

  locationOptions: PropTypes.arrayOf(PropTypes.object),
  locationIsLoading: PropTypes.bool
};

CriteriaForm.defaultProps = {
  intl: {},

  criteria: {},
  reportIsLoading: false,
  pickingAllocError() {},
  fetchItemOptions() {},
  fetchItemGroup01Options() {},
  fetchItemGroup02Options() {},
  fetchItemGroup03Options() {},
  fetchStorageBinOptions() {},
  fetchStorageRowOptions() {},
  fetchStorageBayOptions() {},
  fetchLocationOptions() {},

  itemOptions: [],
  itemIsLoading: false,

  itemGroup01Options: [],
  itemGroup01IsLoading: false,

  itemGroup02Options: [],
  itemGroup02IsLoading: false,

  itemGroup03Options: [],
  itemGroup03IsLoading: false,

  storageBinOptions: [],
  storageBinIsLoading: false,

  storageRowOptions: [],
  storageRowIsLoading: false,

  storageBayOptions: [],
  storageBayIsLoading: false,

  locationOptions: [],
  locationIsLoading: false
};

const mapStateToProps = state => ({
  timestamp: state.pickingAllocErrorReport.timestamp,
  criteria: state.pickingAllocErrorReport.criteria,

  reportIsLoading: state.pickingAllocErrorReport.reportIsLoading,

  itemOptions: state.pickingAllocErrorReport.itemOptions,
  itemIsLoading: state.pickingAllocErrorReport.itemIsLoading,

  itemGroup01Options: state.pickingAllocErrorReport.itemGroup01Options,
  itemGroup01IsLoading: state.pickingAllocErrorReport.itemGroup01IsLoading,

  itemGroup02Options: state.pickingAllocErrorReport.itemGroup02Options,
  itemGroup02IsLoading: state.pickingAllocErrorReport.itemGroup02IsLoading,

  itemGroup03Options: state.pickingAllocErrorReport.itemGroup03Options,
  itemGroup03IsLoading: state.pickingAllocErrorReport.itemGroup03IsLoading,

  storageBinOptions: state.pickingAllocErrorReport.storageBinOptions,
  storageBinIsLoading: state.pickingAllocErrorReport.storageBinIsLoading,

  storageRowOptions: state.pickingAllocErrorReport.storageRowOptions,
  storageRowIsLoading: state.pickingAllocErrorReport.storageRowIsLoading,

  storageBayOptions: state.pickingAllocErrorReport.storageBayOptions,
  storageBayIsLoading: state.pickingAllocErrorReport.storageBayIsLoading,

  locationOptions: state.pickingAllocErrorReport.locationOptions,
  locationIsLoading: state.pickingAllocErrorReport.locationIsLoading
});

const mapDispatchToProps = dispatch => ({
  pickingAllocError: (formikBag, criteria) =>
    dispatch(
      PickingAllocErrorReportActions.pickingAllocErrorReportPickingAllocError(formikBag, criteria)
    ),

  fetchItemOptions: search =>
    dispatch(PickingAllocErrorReportActions.pickingAllocErrorReportFetchItemOptions(search)),
  fetchItemGroup01Options: search =>
    dispatch(PickingAllocErrorReportActions.pickingAllocErrorReportFetchItemGroup01Options(search)),
  fetchItemGroup02Options: search =>
    dispatch(PickingAllocErrorReportActions.pickingAllocErrorReportFetchItemGroup02Options(search)),
  fetchItemGroup03Options: search =>
    dispatch(PickingAllocErrorReportActions.pickingAllocErrorReportFetchItemGroup03Options(search)),

  fetchStorageBinOptions: search =>
    dispatch(PickingAllocErrorReportActions.pickingAllocErrorReportFetchStorageBinOptions(search)),
  fetchStorageRowOptions: search =>
    dispatch(PickingAllocErrorReportActions.pickingAllocErrorReportFetchStorageRowOptions(search)),
  fetchStorageBayOptions: search =>
    dispatch(PickingAllocErrorReportActions.pickingAllocErrorReportFetchStorageBayOptions(search)),
  fetchLocationOptions: search =>
    dispatch(PickingAllocErrorReportActions.pickingAllocErrorReportFetchLocationOptions(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CriteriaForm));
