/**
 * The initial values for the redux state.
 */
export default {
  syncSetting: {
    url: '',
    page_size: 1000,
    username: '',
    password: '',
    last_synced_at: '2000-01-01 00:00:00'
  },
  batchJobStatus: {
    proc_type: 0,
    user_id: 0,
    status_number: 0
  },
  fetchIsLoading: false,
  importIsLoading: false
};
