/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LspSlsRtnExcel01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const lspSlsRtnExcel01SetStrProcType = (state, { strProcType }) => ({
  ...state,
  strProcType
});

export const lspSlsRtnExcel01ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const lspSlsRtnExcel01UploadLoading = (state, { boolean }) => ({
  ...state,
  uploadIsLoading: boolean
});

export const lspSlsRtnExcel01FetchDebtorOptionLoading = (state, { boolean }) => ({
  ...state,
  debtorIsLoading: boolean
});

export const lspSlsRtnExcel01FetchDebtorOptionSuccess = (state, { options }) => ({
  ...state,
  debtorOptions: options
});

export const lspSlsRtnExcel01FetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const lspSlsRtnExcel01FetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LspSlsRtnExcel01Types.LSP_SLS_RTN_EXCEL01_SET_STR_PROC_TYPE]: lspSlsRtnExcel01SetStrProcType,
  [LspSlsRtnExcel01Types.LSP_SLS_RTN_EXCEL01_SHOW_BATCH_JOB_STATUS_SUCCESS]: lspSlsRtnExcel01ShowBatchJobStatusSuccess,
  [LspSlsRtnExcel01Types.LSP_SLS_RTN_EXCEL01_UPLOAD_LOADING]: lspSlsRtnExcel01UploadLoading,

  [LspSlsRtnExcel01Types.LSP_SLS_RTN_EXCEL01_FETCH_DEBTOR_OPTION_LOADING]: lspSlsRtnExcel01FetchDebtorOptionLoading,
  [LspSlsRtnExcel01Types.LSP_SLS_RTN_EXCEL01_FETCH_DEBTOR_OPTION_SUCCESS]: lspSlsRtnExcel01FetchDebtorOptionSuccess,

  [LspSlsRtnExcel01Types.LSP_SLS_RTN_EXCEL01_FETCH_PROJECT_OPTION_LOADING]: lspSlsRtnExcel01FetchProjectOptionLoading,
  [LspSlsRtnExcel01Types.LSP_SLS_RTN_EXCEL01_FETCH_PROJECT_OPTION_SUCCESS]: lspSlsRtnExcel01FetchProjectOptionSuccess
});
