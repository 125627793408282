import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  countAdjIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  countAdjIndexGoToPrint: ['document'],
  countAdjIndexGoToDocument: ['hdrId'],
  countAdjIndexNewDocument: null,
  countAdjIndexGoToAudit: ['hdrId'],
  countAdjIndexFetchCountAdjIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  countAdjIndexFetchCountAdjIndexLoading: ['boolean'],
  countAdjIndexFetchCountAdjIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  countAdjIndexAddSelectedDocuments: ['selectedDocuments'],
  countAdjIndexRemoveSelectedDocuments: ['selectedDocuments'],
  countAdjIndexSetWorkspaceVisible: ['boolean'],

  countAdjIndexWorkspaceLoading: ['boolean'],
  countAdjIndexResetCountAdjSync01: ['hdrIds'],
  countAdjIndexResetCountAdjSync01Success: null,
  countAdjIndexResetCountAdjFServ01: ['hdrIds'],
  countAdjIndexResetCountAdjFServ01Success: null
});

export const CountAdjIndexTypes = Types;
export default Creators;
