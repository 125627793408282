import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import AdvShipExcel01Actions, { AdvShipExcel01Types } from '../Stores/AdvShipExcel01/Actions';

const getAppStore = state => state.app;

const getAdvShipExcel01Store = state => state.advShipExcel01;

export function* advShipExcel01ShowBatchJobStatus() {
  try {
    const app = yield select(getAppStore);
    const advShipExcel01 = yield select(getAdvShipExcel01Store);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `batchJobStatus/showBatchJobStatus/${advShipExcel01.strProcType}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(AdvShipExcel01Actions.advShipExcel01ShowBatchJobStatusSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  }
}

export function* advShipExcel01UploadExcel({ formikBag, file }) {
  try {
    formikBag.setSubmitting(true);
    yield put(AdvShipExcel01Actions.advShipExcel01UploadLoading(true));

    const app = yield select(getAppStore);

    yield put(AdvShipExcel01Actions.advShipExcel01SetStrProcType('ADV_SHIP_EXCEL_01'));
    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file1', file);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `advShip/uploadProcess/ADV_SHIP_EXCEL_01/${app.curSiteFlowId}/${app.curDivisionId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(AdvShipExcel01Actions.advShipExcel01UploadLoading(false));
  }
}

export const saga = [
  takeLatest(
    AdvShipExcel01Types.ADV_SHIP_EXCEL01_SHOW_BATCH_JOB_STATUS,
    advShipExcel01ShowBatchJobStatus
  ),
  takeLatest(AdvShipExcel01Types.ADV_SHIP_EXCEL01_UPLOAD_EXCEL, advShipExcel01UploadExcel)
];
