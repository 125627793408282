import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  pickingAllocErrorReportInitPickingAllocError: null,
  pickingAllocErrorReportInitPickingAllocErrorSuccess: ['criteria'],
  pickingAllocErrorReportPickingAllocError: ['formikBag', 'criteria'],
  pickingAllocErrorReportPickingAllocErrorSuccess: ['criteria', 'reportData'],
  pickingAllocErrorReportReportLoading: ['boolean'],

  pickingAllocErrorReportFetchItemOptions: ['search'],
  pickingAllocErrorReportFetchItemOptionLoading: ['boolean'],
  pickingAllocErrorReportFetchItemOptionSuccess: ['options'],

  pickingAllocErrorReportFetchItemGroup01Options: ['search'],
  pickingAllocErrorReportFetchItemGroup01OptionLoading: ['boolean'],
  pickingAllocErrorReportFetchItemGroup01OptionSuccess: ['options'],

  pickingAllocErrorReportFetchItemGroup02Options: ['search'],
  pickingAllocErrorReportFetchItemGroup02OptionLoading: ['boolean'],
  pickingAllocErrorReportFetchItemGroup02OptionSuccess: ['options'],

  pickingAllocErrorReportFetchItemGroup03Options: ['search'],
  pickingAllocErrorReportFetchItemGroup03OptionLoading: ['boolean'],
  pickingAllocErrorReportFetchItemGroup03OptionSuccess: ['options'],

  pickingAllocErrorReportFetchStorageBinOptions: ['search'],
  pickingAllocErrorReportFetchStorageBinOptionLoading: ['boolean'],
  pickingAllocErrorReportFetchStorageBinOptionSuccess: ['options'],

  pickingAllocErrorReportFetchStorageRowOptions: ['search'],
  pickingAllocErrorReportFetchStorageRowOptionLoading: ['boolean'],
  pickingAllocErrorReportFetchStorageRowOptionSuccess: ['options'],

  pickingAllocErrorReportFetchStorageBayOptions: ['search'],
  pickingAllocErrorReportFetchStorageBayOptionLoading: ['boolean'],
  pickingAllocErrorReportFetchStorageBayOptionSuccess: ['options'],

  pickingAllocErrorReportFetchLocationOptions: ['search'],
  pickingAllocErrorReportFetchLocationOptionLoading: ['boolean'],
  pickingAllocErrorReportFetchLocationOptionSuccess: ['options']
});

export const PickingAllocErrorReportTypes = Types;
export default Creators;
