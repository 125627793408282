import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspSlsRtnIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  lspSlsRtnIndexFetchLspSlsRtnIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  lspSlsRtnIndexFetchLspSlsRtnIndexLoading: ['boolean'],
  lspSlsRtnIndexFetchLspSlsRtnIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  lspSlsRtnIndexAddSelectedDocuments: ['selectedDocuments'],
  lspSlsRtnIndexRemoveSelectedDocuments: ['selectedDocuments'],
  lspSlsRtnIndexSetWorkspaceVisible: ['boolean'],
  lspSlsRtnIndexGoToDocument: ['hdrId'],
  lspSlsRtnIndexGoToAudit: ['hdrId']
});

export const LspSlsRtnIndexTypes = Types;
export default Creators;
