import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  docTypeDocNoIndexResetTimestamp: null,
  docTypeDocNoIndexSetDocType: ['docType'],

  docTypeDocNoIndexShowDocNoLoading: ['boolean'],

  docTypeDocNoIndexShowDocNos: ['docType'],
  docTypeDocNoIndexShowDocNosSuccess: ['docNos'],

  docTypeDocNoIndexSetFormVisible: ['boolean'],
  docTypeDocNoIndexSetDocNo: ['docNo'],

  docTypeDocNoIndexUpdateDocNo: ['formikBag', 'docNo'],
  docTypeDocNoIndexCreateDocNo: ['formikBag', 'docType', 'docNo'],
  docTypeDocNoIndexDeleteDocNo: ['docNo'],

  docTypeDocNoIndexUpdateDocNoSuccess: ['docNo']
});

export const DocTypeDocNoIndexTypes = Types;
export default Creators;
