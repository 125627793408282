import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { DeleteOutlined } from '@ant-design/icons';

import { Row, Col, Modal, Table, Button, Popconfirm } from 'antd';

import CountAdj01Actions from '../../Stores/CountAdj01/Actions';

class CountAdj01Dialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnCreateDocument = this.useOnCreateDocument.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  getSelectedDocumentColumns() {
    const { intl, removeSelectedDocuments } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
            <br />
            {record.str_doc_status}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'desc_01' }),
        // sort field
        dataIndex: 'desc_01',
        sorter: (a, b) => `${a.desc_01}`.localeCompare(b.desc_01),
        // filter field
        key: 'desc_01',
        render: (text, record) => (
          <>
            <b>{record.desc_01}</b>
            <br />
            {record.desc_01}
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'storage_row' }),
        // sort field
        dataIndex: 'storage_row_qty',
        sorter: (a, b) => a.storage_row_qty - b.storage_row_qty,
        // filter field
        key: 'storage_row_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.storage_row_qty)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'storage_bay' }),
        // sort field
        dataIndex: 'storage_bay_qty',
        sorter: (a, b) => a.storage_bay_qty - b.storage_bay_qty,
        // filter field
        key: 'storage_bay_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.storage_bay_qty)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'storage_bin' }),
        // sort field
        dataIndex: 'storage_bin_qty',
        sorter: (a, b) => a.storage_bin_qty - b.storage_bin_qty,
        // filter field
        key: 'storage_bin_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.storage_bin_qty)}
            </div>
          </>
        )
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedDocuments([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  getNewDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'str_doc_status',
        sorter: (a, b) => `${a.str_doc_status}`.localeCompare(b.str_doc_status),
        // filter field
        key: 'str_doc_status',
        render: (text, record) => (
          <>
            <b>{record.str_doc_status}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'created_date' }),
        // sort field
        dataIndex: 'created_at',
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        // filter field
        key: 'created_at',
        render: (text, record) => <>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'updated_date' }),
        // sort field
        dataIndex: 'updated_at',
        sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
        // filter field
        key: 'updated_at',
        render: (text, record) => <>{moment(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}</>
      }
    ];
  }

  useOnCreateDocument() {
    const { selectedDocuments, createCountAdj01 } = this.props;

    const hdrIds = selectedDocuments.map(value => {
      return value.id;
    });

    createCountAdj01(hdrIds);
  }

  render() {
    const {
      intl,
      newDocuments,
      selectedDocuments,
      workspaceIsVisible,
      createIsLoading
    } = this.props;

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'create_count_adjustment' })}
          // style={{top:20}}
          width="80%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              key="create"
              disabled={selectedDocuments.length === 0}
              loading={createIsLoading}
              onClick={this.useOnCreateDocument}
            >
              {intl.formatMessage({ id: 'create' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocuments}
                loading={createIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_documents' })}</b>}
                summary={pageData => {
                  // summarise the table
                  const calcRow = pageData.reduce(
                    (ttlObj, object) => {
                      return {
                        storage_row_qty:
                          ('storage_row_qty' in ttlObj ? ttlObj.storage_row_qty : 0) +
                          parseFloat(object.storage_row_qty),
                        storage_bay_qty:
                          ('storage_bay_qty' in ttlObj ? ttlObj.storage_bay_qty : 0) +
                          parseFloat(object.storage_bay_qty),
                        storage_bin_qty:
                          ('storage_bin_qty' in ttlObj ? ttlObj.storage_bin_qty : 0) +
                          parseFloat(object.storage_bin_qty)
                      };
                    },
                    { storage_row_qty: 0, storage_bay_qty: 0, storage_bin_qty: 0 }
                  );

                  return (
                    <>
                      <tr className="ant-table-row">
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={3}>
                          {intl.formatMessage({ id: 'total' })}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.storage_row_qty)}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.storage_bay_qty)}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.storage_bin_qty)}
                        </td>
                        <td />
                      </tr>
                    </>
                  );
                }}
              />
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                size="small"
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getNewDocumentColumns()}
                dataSource={newDocuments}
                loading={createIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'new_count_adjustments' })}</b>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

CountAdj01Dialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedDocuments: PropTypes.func,
  newDocuments: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  createIsLoading: PropTypes.bool,

  createCountAdj01: PropTypes.func
};

CountAdj01Dialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedDocuments() {},
  newDocuments: [],
  selectedDocuments: [],

  createIsLoading: false,

  createCountAdj01() {}
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.countAdj01.workspaceIsVisible,

  selectedDocuments: state.countAdj01.selectedDocuments,
  newDocuments: state.countAdj01.newDocuments,

  createIsLoading: state.countAdj01.createIsLoading
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean =>
    dispatch(CountAdj01Actions.countAdj01SetWorkspaceVisible(boolean)),

  removeSelectedDocuments: selectedDocuments =>
    dispatch(CountAdj01Actions.countAdj01RemoveSelectedDocuments(selectedDocuments)),

  createCountAdj01: hdrIds => dispatch(CountAdj01Actions.countAdj01CreateCountAdj01(hdrIds))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CountAdj01Dialog));
