import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { ArrowLeftOutlined, UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography, Radio, Card } from 'antd';
import treeChanges from 'tree-changes';
import {
  FormikInput,
  FormikRadio,
  FormikButton,
  FormikSelect,
  FormikInputNumber
} from '../../Components/Formik';
import ItemDetailActions from '../../Stores/ItemDetail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class ModelForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchItemGroup01 = this.handleSearchItemGroup01.bind(this);
    this.handleSearchItemGroup02 = this.handleSearchItemGroup02.bind(this);
    this.handleSearchItemGroup03 = this.handleSearchItemGroup03.bind(this);
    this.handleSearchUom = this.handleSearchUom.bind(this);
  }

  componentDidMount() {
    const { resId, resetTimestamp } = this.props;

    if (resId > 0) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const { match, resId, resetTimestamp, initModel, showModel } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('resId')) {
      resetTimestamp();
    }
    if (changed('timestamp')) {
      if (resId <= 0) {
        initModel(match.params.id);
      } else {
        showModel(resId);
      }
    }
  }

  componentWillUnmount() {}

  handleSearchItemGroup01(form, value) {
    const { fetchItemGroup01Options } = this.props;

    fetchItemGroup01Options(value);
  }

  handleSearchItemGroup02(form, value) {
    const { fetchItemGroup02Options } = this.props;

    fetchItemGroup02Options(value);
  }

  handleSearchItemGroup03(form, value) {
    const { fetchItemGroup03Options } = this.props;

    fetchItemGroup03Options(value);
  }

  handleSearchUom(form, value) {
    const { fetchUomOptions } = this.props;

    fetchUomOptions(value);
  }

  render() {
    const {
      intl,
      match,
      model,
      createModel,
      updateModel,
      historyGoBack,
      modelIsLoading,
      itemGroup01IsLoading,
      itemGroup01Options,
      itemGroup02IsLoading,
      itemGroup02Options,
      itemGroup03IsLoading,
      itemGroup03Options,
      uomIsLoading,
      uomOptions
    } = this.props;

    const statusOptions = [
      <Radio.Button key={1} value={1}>
        {intl.formatMessage({ id: 'DELETED' })}
      </Radio.Button>,
      <Radio.Button key={2} value={2}>
        {intl.formatMessage({ id: 'INACTIVE' })}
      </Radio.Button>,
      <Radio.Button key={3} value={3}>
        {intl.formatMessage({ id: 'BLOCKED' })}
      </Radio.Button>,
      <Radio.Button key={4} value={4}>
        {intl.formatMessage({ id: 'CONFLICT' })}
      </Radio.Button>,
      <Radio.Button key={5} value={5}>
        {intl.formatMessage({ id: 'NEW' })}
      </Radio.Button>,
      <Radio.Button key={50} value={50}>
        {intl.formatMessage({ id: 'WIP' })}
      </Radio.Button>,
      <Radio.Button key={100} value={100}>
        {intl.formatMessage({ id: 'ACTIVE' })}
      </Radio.Button>
    ];

    const batchSerialControlOptions = [
      <Radio.Button key={0} value={0}>
        {intl.formatMessage({ id: 'EXPIRY_CONTROL_ONLY' })}
      </Radio.Button>,
      <Radio.Button key={1} value={1}>
        {intl.formatMessage({ id: 'BATCH_CONTROL_ONLY' })}
      </Radio.Button>,
      <Radio.Button key={2} value={2}>
        {intl.formatMessage({ id: 'BATCH_EXPIRY_CONTROL' })}
      </Radio.Button>,
      <Radio.Button key={3} value={3}>
        {intl.formatMessage({ id: 'SERIAL_CONTROL_ONLY' })}
      </Radio.Button>,
      <Radio.Button key={100} value={100}>
        {intl.formatMessage({ id: 'EXPIRY_CONTROL_ONLY_01' })}
      </Radio.Button>,
      <Radio.Button key={101} value={101}>
        {intl.formatMessage({ id: 'BATCH_CONTROL_ONLY_01' })}
      </Radio.Button>,
      <Radio.Button key={102} value={102}>
        {intl.formatMessage({ id: 'BATCH_EXPIRY_CONTROL_01' })}
      </Radio.Button>,
      <Radio.Button key={103} value={103}>
        {intl.formatMessage({ id: 'SERIAL_CONTROL_ONLY_01' })}
      </Radio.Button>
    ];

    const storageClassOptions = [
      <Radio.Button key={1} value={1}>
        {intl.formatMessage({ id: 'AMBIENT' })}
      </Radio.Button>,
      <Radio.Button key={2} value={2}>
        {intl.formatMessage({ id: 'CHILLED' })}
      </Radio.Button>,
      <Radio.Button key={3} value={3}>
        {intl.formatMessage({ id: 'FROZEN' })}
      </Radio.Button>
    ];

    const retrievalOptions = [
      <Radio.Button key={1} value={1}>
        {intl.formatMessage({ id: 'FIRST_EXPIRED_FIRST_OUT' })}
      </Radio.Button>,
      <Radio.Button key={2} value={2}>
        {intl.formatMessage({ id: 'LAST_EXPIRED_FIRST_OUT' })}
      </Radio.Button>,
      <Radio.Button key={3} value={3}>
        {intl.formatMessage({ id: 'FIRST_IN_FIRST_OUT' })}
      </Radio.Button>,
      <Radio.Button key={4} value={4}>
        {intl.formatMessage({ id: 'LAST_IN_FIRST_OUT' })}
      </Radio.Button>
    ];

    const disabled = false;

    return (
      <Formik
        enableReinitialize
        initialValues={model}
        onSubmit={(values, formikBag) => {
          if (match.params.action === 'create') {
            createModel(formikBag, values);
          } else {
            updateModel(formikBag, values);
          }
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required(intl.formatMessage({ id: 'code_is_required' })),
          desc_01: Yup.string().required(intl.formatMessage({ id: 'name_is_required' })),
          item_group_01_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'brand_is_required' }))
          }),
          item_group_02_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'category_is_required' }))
          }),
          item_group_03_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'manufacturer_is_required' }))
          }),
          unit_uom_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'unit_uom_is_required' }))
          }),
          case_uom_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'case_uom_is_required' }))
          }),
          case_uom_rate: Yup.number().moreThan(
            0,
            intl.formatMessage({ id: 'case_uom_rate_must_be_greater_than_0' })
          ),
          status: Yup.number().min(1, intl.formatMessage({ id: 'status_is_required' }))
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Card>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="code"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'status' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="status"
                    component={FormikRadio}
                    options={statusOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'ref_code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="ref_code_01"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'description' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="desc_01"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3} />
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="desc_02"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'batch_serial_control' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="batch_serial_control"
                    component={FormikRadio}
                    options={batchSerialControlOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'storage_class' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="storage_class"
                    component={FormikRadio}
                    options={storageClassOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'brand' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="item_group_01_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchItemGroup01}
                    notFoundContent={null}
                    loading={itemGroup01IsLoading}
                    options={itemGroup01Options}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'category' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="item_group_02_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchItemGroup02}
                    notFoundContent={null}
                    loading={itemGroup02IsLoading}
                    options={itemGroup02Options}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'manufacturer' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="item_group_03_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchItemGroup03}
                    notFoundContent={null}
                    loading={itemGroup03IsLoading}
                    options={itemGroup03Options}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'retrieval_method' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="retrieval_method"
                    component={FormikRadio}
                    options={retrievalOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'quantity_scale' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="qty_scale" component={FormikInputNumber} disabled={disabled} />
                </Col>
                <Col span={12} />
              </Row>
            </Card>
            <Card title={intl.formatMessage({ id: 'dimensions' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'case_ext_length' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="case_ext_length" component={FormikInputNumber} disabled={disabled} />
                </Col>
                <Col span={12} />
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'case_ext_width' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="case_ext_width" component={FormikInputNumber} disabled={disabled} />
                </Col>
                <Col span={12} />
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'case_ext_height' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="case_ext_height" component={FormikInputNumber} disabled={disabled} />
                </Col>
                <Col span={12} />
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'case_gross_weight' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="case_gross_weight"
                    component={FormikInputNumber}
                    precision={4}
                    disabled={disabled}
                  />
                </Col>
                <Col span={12} />
              </Row>
            </Card>
            <Card title={intl.formatMessage({ id: 'pallet_configuration' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'cases_per_layer' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="cases_per_pallet_length"
                    component={FormikInputNumber}
                    disabled={disabled}
                  />
                </Col>
                <Col span={12} />
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'no_of_layers' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="no_of_layers" component={FormikInputNumber} disabled={disabled} />
                </Col>
                <Col span={12} />
              </Row>
            </Card>
            <Card title={intl.formatMessage({ id: 'unit_of_measurement' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'unit_uom' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="unit_uom_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchUom}
                    notFoundContent={null}
                    loading={uomIsLoading}
                    options={uomOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={12} />
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'unit_barcode' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="unit_barcode"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'case_uom' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="case_uom_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchUom}
                    notFoundContent={null}
                    loading={uomIsLoading}
                    options={uomOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'rate' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="case_uom_rate" component={FormikInputNumber} precision={6} />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'case_barcode' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="case_barcode"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'other_uom_1' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="other_uom_1_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchUom}
                    notFoundContent={null}
                    loading={uomIsLoading}
                    options={uomOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'rate' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="other_uom_1_rate" component={FormikInputNumber} precision={6} />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'other_1_barcode' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="other_1_barcode"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'other_uom_2' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="other_uom_2_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchUom}
                    notFoundContent={null}
                    loading={uomIsLoading}
                    options={uomOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'rate' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="other_uom_2_rate" component={FormikInputNumber} precision={6} />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'other_2_barcode' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="other_2_barcode"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'other_uom_3' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="other_uom_3_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchUom}
                    notFoundContent={null}
                    loading={uomIsLoading}
                    options={uomOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'rate' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="other_uom_3_rate" component={FormikInputNumber} precision={6} />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'other_3_barcode' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="other_3_barcode"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'other_uom_4' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="other_uom_4_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchUom}
                    notFoundContent={null}
                    loading={uomIsLoading}
                    options={uomOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'rate' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="other_uom_4_rate" component={FormikInputNumber} precision={6} />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'other_4_barcode' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="other_4_barcode"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'other_uom_5' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="other_uom_5_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchUom}
                    notFoundContent={null}
                    loading={uomIsLoading}
                    options={uomOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'rate' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="other_uom_5_rate" component={FormikInputNumber} precision={6} />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'other_5_barcode' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="other_5_barcode"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
            </Card>
            <Row type="flex" justify="space-between" gutter={[0, 16]}>
              <Col span={8}>
                <Button
                  type="primary"
                  // disabled={!isValid}
                  loading={isSubmitting}
                  onClick={historyGoBack}
                  icon={<ArrowLeftOutlined />}
                >
                  {intl.formatMessage({
                    id: 'back'
                  })}
                </Button>
              </Col>
              <Col span={5}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting || modelIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  name="submit_action"
                  type="primary"
                  value="update"
                  component={FormikButton}
                  // disabled={values.status >= 50}
                  loading={isSubmitting || modelIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: match.params.action === 'create' ? 'create' : 'update'
                  })}
                  icon={<SaveOutlined />}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

ModelForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  resetTimestamp: PropTypes.func,

  initModel: PropTypes.func,
  showModel: PropTypes.func,
  createModel: PropTypes.func,
  updateModel: PropTypes.func,
  historyGoBack: PropTypes.func,
  resId: PropTypes.number,
  model: PropTypes.shape({
    status: PropTypes.number
  }),
  modelIsLoading: PropTypes.bool,

  fetchItemGroup01Options: PropTypes.func,
  itemGroup01IsLoading: PropTypes.bool,
  itemGroup01Options: PropTypes.arrayOf(PropTypes.object),

  fetchItemGroup02Options: PropTypes.func,
  itemGroup02IsLoading: PropTypes.bool,
  itemGroup02Options: PropTypes.arrayOf(PropTypes.object),

  fetchItemGroup03Options: PropTypes.func,
  itemGroup03IsLoading: PropTypes.bool,
  itemGroup03Options: PropTypes.arrayOf(PropTypes.object),

  fetchUomOptions: PropTypes.func,
  uomIsLoading: PropTypes.bool,
  uomOptions: PropTypes.arrayOf(PropTypes.object)
};

ModelForm.defaultProps = {
  intl: {},
  match: {},
  resetTimestamp() {},

  initModel() {},
  showModel() {},
  createModel() {},
  updateModel() {},
  historyGoBack() {},
  resId: 0,
  model: { status: 2 },
  modelIsLoading: false,

  fetchItemGroup01Options() {},
  itemGroup01IsLoading: false,
  itemGroup01Options: [],

  fetchItemGroup02Options() {},
  itemGroup02IsLoading: false,
  itemGroup02Options: [],

  fetchItemGroup03Options() {},
  itemGroup03IsLoading: false,
  itemGroup03Options: [],

  fetchUomOptions() {},
  uomIsLoading: false,
  uomOptions: []
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.itemDetail.modelTimestamp,
  resId: state.itemDetail.resId,
  model: state.itemDetail.model,
  modelIsLoading: state.itemDetail.modelIsLoading,

  itemGroup01IsLoading: state.itemDetail.itemGroup01IsLoading,
  itemGroup01Options: state.itemDetail.itemGroup01Options,

  itemGroup02IsLoading: state.itemDetail.itemGroup02IsLoading,
  itemGroup02Options: state.itemDetail.itemGroup02Options,

  itemGroup03IsLoading: state.itemDetail.itemGroup03IsLoading,
  itemGroup03Options: state.itemDetail.itemGroup03Options,

  uomIsLoading: state.itemDetail.uomIsLoading,
  uomOptions: state.itemDetail.uomOptions
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: () => dispatch(ItemDetailActions.itemDetailResetModelTimestamp()),
  initModel: itemId => dispatch(ItemDetailActions.itemDetailInitModel(itemId)),
  showModel: resId => dispatch(ItemDetailActions.itemDetailShowModel(resId)),

  updateModel: (formikBag, model) =>
    dispatch(ItemDetailActions.itemDetailUpdateModel(formikBag, model)),
  createModel: (formikBag, model) =>
    dispatch(ItemDetailActions.itemDetailCreateModel(formikBag, model)),

  fetchItemGroup01Options: search =>
    dispatch(ItemDetailActions.itemDetailFetchItemGroup01Options(search)),
  fetchItemGroup02Options: search =>
    dispatch(ItemDetailActions.itemDetailFetchItemGroup02Options(search)),
  fetchItemGroup03Options: search =>
    dispatch(ItemDetailActions.itemDetailFetchItemGroup03Options(search)),

  fetchUomOptions: search => dispatch(ItemDetailActions.itemDetailFetchUomOptions(search)),

  historyGoBack: () => dispatch(AppActions.appHistoryGoBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModelForm));
