import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  projectList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  projectList01NewResource: null,
  projectList01GoToResource: ['resId'],
  projectList01FetchProjectList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  projectList01FetchProjectList01Loading: ['boolean'],
  projectList01FetchProjectList01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ]
});

export const ProjectList01Types = Types;
export default Creators;
