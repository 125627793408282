import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  divisionProcessFetchDivisionProcess: null,
  divisionProcessFetchDivisionProcessLoading: ['boolean'],
  divisionProcessFetchDivisionProcessSuccess: ['process']
});

export const DivisionProcessTypes = Types;
export default Creators;
