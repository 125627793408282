import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stockBalanceReportInitStockBalance: null,
  stockBalanceReportInitStockBalanceSuccess: ['criteria'],
  stockBalanceReportStockBalance: ['formikBag', 'criteria'],
  stockBalanceReportStockBalanceSuccess: ['criteria', 'reportData'],
  stockBalanceReportReportLoading: ['boolean'],

  stockBalanceReportFetchCompanyOptions: ['search'],
  stockBalanceReportFetchCompanyOptionLoading: ['boolean'],
  stockBalanceReportFetchCompanyOptionSuccess: ['options'],

  stockBalanceReportFetchItemOptions: ['search'],
  stockBalanceReportFetchItemOptionLoading: ['boolean'],
  stockBalanceReportFetchItemOptionSuccess: ['options'],

  stockBalanceReportFetchItemGroup01Options: ['search'],
  stockBalanceReportFetchItemGroup01OptionLoading: ['boolean'],
  stockBalanceReportFetchItemGroup01OptionSuccess: ['options'],

  stockBalanceReportFetchItemGroup02Options: ['search'],
  stockBalanceReportFetchItemGroup02OptionLoading: ['boolean'],
  stockBalanceReportFetchItemGroup02OptionSuccess: ['options'],

  stockBalanceReportFetchItemGroup03Options: ['search'],
  stockBalanceReportFetchItemGroup03OptionLoading: ['boolean'],
  stockBalanceReportFetchItemGroup03OptionSuccess: ['options'],

  stockBalanceReportFetchStorageBinOptions: ['search'],
  stockBalanceReportFetchStorageBinOptionLoading: ['boolean'],
  stockBalanceReportFetchStorageBinOptionSuccess: ['options'],

  stockBalanceReportFetchStorageRowOptions: ['search'],
  stockBalanceReportFetchStorageRowOptionLoading: ['boolean'],
  stockBalanceReportFetchStorageRowOptionSuccess: ['options'],

  stockBalanceReportFetchStorageBayOptions: ['search'],
  stockBalanceReportFetchStorageBayOptionLoading: ['boolean'],
  stockBalanceReportFetchStorageBayOptionSuccess: ['options'],

  stockBalanceReportFetchLocationOptions: ['search'],
  stockBalanceReportFetchLocationOptionLoading: ['boolean'],
  stockBalanceReportFetchLocationOptionSuccess: ['options'],

  stockBalanceReportOpenBatchNoModal: ['isModalOpened', 'modalData']
});

export const StockBalanceReportTypes = Types;
export default Creators;
