/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StkIssSync05Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const stkIssSync05ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const stkIssSync05FetchStkIssSync05Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const stkIssSync05FetchStkIssSync05Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const stkIssSync05AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const stkIssSync05RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const stkIssSync05SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const stkIssSync05SyncLoading = (state, { boolean }) => ({
  ...state,
  syncIsLoading: boolean
});

export const stkIssSync05SyncNowSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StkIssSync05Types.STK_ISS_SYNC05_RESET_TIMESTAMP]: stkIssSync05ResetTimestamp,
  [StkIssSync05Types.STK_ISS_SYNC05_FETCH_STK_ISS_SYNC05_LOADING]: stkIssSync05FetchStkIssSync05Loading,
  [StkIssSync05Types.STK_ISS_SYNC05_FETCH_STK_ISS_SYNC05_SUCCESS]: stkIssSync05FetchStkIssSync05Success,
  [StkIssSync05Types.STK_ISS_SYNC05_ADD_SELECTED_DOCUMENTS]: stkIssSync05AddSelectedDocuments,
  [StkIssSync05Types.STK_ISS_SYNC05_REMOVE_SELECTED_DOCUMENTS]: stkIssSync05RemoveSelectedDocuments,
  [StkIssSync05Types.STK_ISS_SYNC05_SET_WORKSPACE_VISIBLE]: stkIssSync05SetWorkspaceVisible,
  [StkIssSync05Types.STK_ISS_SYNC05_SYNC_LOADING]: stkIssSync05SyncLoading,
  [StkIssSync05Types.STK_ISS_SYNC05_SYNC_NOW_SUCCESS]: stkIssSync05SyncNowSuccess
});
