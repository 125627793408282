import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import {
  AuditOutlined,
  BarcodeOutlined,
  EditOutlined,
  PrinterOutlined,
  ReloadOutlined,
  LaptopOutlined
} from '@ant-design/icons';

import { Row, Col, Table, Button, Card, Popconfirm, Tag, Badge, Popover } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import GdsRcptRtnIndexActions from '../../Stores/GdsRcptRtnIndex/Actions';
import GdsRcptRtnIndexDialog from './GdsRcptRtnIndexDialog';

class GdsRcptRtnIndexTable extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchGdsRcptRtnIndex,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchGdsRcptRtnIndex(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const {
      intl,
      sorts,
      filters,
      goToPrint,
      goToPrintHandlingUnit,
      goToDocument,
      goToAudit
    } = this.props;

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'division' }),
        // sort field
        dataIndex: 'division_code',
        // filter field
        key: 'division',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'division' }),
          'division',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.divisions.map(value => (
              <div key={`${record.id}:${value.code}`}>{value.code}</div>
            ))}
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_code'),
        // filter field
        key: 'doc_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'doc_code' }),
          'doc_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.doc_code
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_date'),
        // filter field
        key: 'doc_date',
        ...AntDesignTable.getColumnSearchDateProps(
          filters,
          intl.formatMessage({ id: 'doc_date' }),
          'doc_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'doc_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_status'),
        // filter field
        ...AntDesignTable.getColumnSearchDocStatusProps(filters, 'doc_status'),
        render: (text, record) => (
          <>
            <div>{record.str_doc_status}</div>
            {record.doc_flows.map(docData =>
              docData.doc_id === 0 ? (
                <Popover content={docData.doc_date} key={docData.doc_code}>
                  <Tag color="gold">{docData.doc_code}</Tag>
                </Popover>
              ) : (
                ''
              )
            )}
          </>
        )
      },

      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'salesman' }),
        // sort field
        dataIndex: 'salesman_username',
        // filter field
        key: 'salesman',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'salesman' }),
          'salesman',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.salesmans.map(value => (
              <div key={value.id}>
                <b>{value.username}</b>
              </div>
            ))}
          </>
        )
      },
      {
        width: 90,
        align: 'left',
        title: intl.formatMessage({ id: 'area' }),
        // sort field
        dataIndex: 'delivery_point_area_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'delivery_point_area_code'),
        // filter field
        key: 'delivery_point_area',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'area' }),
          'delivery_point_area',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.areas.map(value => (
              <div key={`${record.id}:${value.code}`}>
                <b>{value.code}</b>
                <br />
                {value.desc_01}
              </div>
            ))}
          </>
        )
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'delivery_point' }),
        // sort field
        dataIndex: 'delivery_point_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'delivery_point_code'),
        // filter field
        key: 'delivery_point',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'delivery_point' }),
          'delivery_point',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.delivery_points.map(value => (
              <div key={`${record.id}:${value.code}`}>
                <b>{value.code}</b>
                <br />
                {value.company_name_01}
              </div>
            ))}
          </>
        )
      },
      {
        width: 150,
        // fixed: 'right',
        align: 'left',
        title: intl.formatMessage({ id: 'documents' }),
        // sort field
        dataIndex: 'inb_ord_hdr_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'inb_ord_hdr_code'),
        // filter field
        key: 'inb_ord_hdr',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'documents' }),
          'inb_ord_hdr',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.inb_ord_hdrs.map(value => (
              <span key={`${record.id}:${value.doc_code}`}>
                <strong>{value.doc_code}</strong>
                <br />
                <strong>
                  {value.shipping_doc_code.length > 0 ? value.shipping_doc_code : <br />}
                </strong>
                {value.ref_code_01 ? (
                  <>
                    <br />
                    {value.ref_code_01}
                  </>
                ) : (
                  ''
                )}
                {value.ref_code_02 ? (
                  <>
                    <br />
                    {value.ref_code_02}
                  </>
                ) : (
                  ''
                )}
              </span>
            ))}
          </>
        )
      },

      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'desc_01' }),
        // sort field
        dataIndex: 'desc_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_01'),
        // filter field
        key: 'desc_01',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'desc_01' }),
          'desc_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.desc_01}</>
      },

      {
        width: 80,
        fixed: 'right',
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_print' })}
              onConfirm={() => goToPrint(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
              disabled={record.doc_status !== 100}
            >
              <Button
                type="dashed"
                icon={<PrinterOutlined />}
                disabled={record.doc_status !== 100}
              />
            </Popconfirm>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_print_pallet_label' })}
              onConfirm={() => goToPrintHandlingUnit(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
              disabled={record.doc_status !== 100}
            >
              <Button
                type="dashed"
                icon={<BarcodeOutlined />}
                disabled={record.doc_status !== 100}
              />
            </Popconfirm>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => goToDocument(record.id)} />
            <Button type="dashed" icon={<AuditOutlined />} onClick={() => goToAudit(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      intl,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments
    } = this.props;

    return (
      <Card title={intl.formatMessage({ id: 'goods_receipts_return' })}>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              // disabled: record.str_doc_status !== 'COMPLETE'
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />

        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px',
            zIndex: 9999
          }}
        >
          <Badge count={selectedDocuments.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon={<LaptopOutlined />}
              onClick={this.useShowWorkspace}
              loading={fetchIsLoading}
            />
          </Badge>
        </div>

        <GdsRcptRtnIndexDialog />
      </Card>
    );
  }
}

GdsRcptRtnIndexTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToPrint: PropTypes.func,
  goToPrintHandlingUnit: PropTypes.func,
  goToDocument: PropTypes.func,
  goToAudit: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchGdsRcptRtnIndex: PropTypes.func
};

GdsRcptRtnIndexTable.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToPrint() {},
  goToPrintHandlingUnit() {},
  goToDocument() {},
  goToAudit() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchGdsRcptRtnIndex() {}
};

const mapStateToProps = state => ({
  timestamp: state.gdsRcptRtnIndex.timestamp,
  workspaceIsVisible: state.gdsRcptRtnIndex.workspaceIsVisible,

  documents: state.gdsRcptRtnIndex.documents,
  selectedDocuments: state.gdsRcptRtnIndex.selectedDocuments,

  sorts: state.gdsRcptRtnIndex.sorts,
  filters: state.gdsRcptRtnIndex.filters,

  currentPage: state.gdsRcptRtnIndex.currentPage,
  pageSize: state.gdsRcptRtnIndex.pageSize,
  total: state.gdsRcptRtnIndex.total,
  fetchIsLoading: state.gdsRcptRtnIndex.fetchIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      GdsRcptRtnIndexActions.gdsRcptRtnIndexResetTimestamp(currentPage, sorts, filters, pageSize)
    ),
  goToPrint: document => dispatch(GdsRcptRtnIndexActions.gdsRcptRtnIndexGoToPrint(document)),
  goToPrintHandlingUnit: document =>
    dispatch(GdsRcptRtnIndexActions.gdsRcptRtnIndexGoToPrintHandlingUnit(document)),
  goToDocument: hdrId => dispatch(GdsRcptRtnIndexActions.gdsRcptRtnIndexGoToDocument(hdrId)),
  goToAudit: hdrId => dispatch(GdsRcptRtnIndexActions.gdsRcptRtnIndexGoToAudit(hdrId)),

  setWorkspaceVisible: boolean =>
    dispatch(GdsRcptRtnIndexActions.gdsRcptRtnIndexSetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(GdsRcptRtnIndexActions.gdsRcptRtnIndexAddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(GdsRcptRtnIndexActions.gdsRcptRtnIndexRemoveSelectedDocuments(selectedDocuments)),

  fetchGdsRcptRtnIndex: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      GdsRcptRtnIndexActions.gdsRcptRtnIndexFetchGdsRcptRtnIndex(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GdsRcptRtnIndexTable));
