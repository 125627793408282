import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  itemDetailResetModelTimestamp: null,
  itemDetailSetResId: ['resId'],

  itemDetailInitModel: ['itemType'],
  itemDetailShowModel: ['resId'],
  itemDetailShowModelSuccess: ['model'],

  itemDetailUpdateModel: ['formikBag', 'model'],
  itemDetailCreateModel: ['formikBag', 'model'],
  itemDetailUpdateModelSuccess: ['model'],

  itemDetailShowModelLoading: ['boolean'],

  itemDetailFetchItemGroup01Options: ['search'],
  itemDetailFetchItemGroup01OptionLoading: ['boolean'],
  itemDetailFetchItemGroup01OptionSuccess: ['options'],

  itemDetailFetchItemGroup02Options: ['search'],
  itemDetailFetchItemGroup02OptionLoading: ['boolean'],
  itemDetailFetchItemGroup02OptionSuccess: ['options'],

  itemDetailFetchItemGroup03Options: ['search'],
  itemDetailFetchItemGroup03OptionLoading: ['boolean'],
  itemDetailFetchItemGroup03OptionSuccess: ['options'],

  itemDetailFetchUomOptions: ['search'],
  itemDetailFetchUomOptionLoading: ['boolean'],
  itemDetailFetchUomOptionSuccess: ['options'],

  itemDetailResetItemBatchTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  itemDetailShowItemBatches: ['resId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  itemDetailShowItemBatchSuccess: ['itemBatches', 'currentPage', 'lastPage', 'total', 'pageSize'],
  itemDetailGoToItemBatch: ['itemBatchId'],
  itemDetailGoToAuditItemBatch: ['itemBatchId'],
  itemDetailNewItemBatch: ['itemId'],

  itemDetailShowItemDocNo: ['itemId'],
  itemDetailShowItemDocNoLoading: ['boolean'],
  itemDetailShowItemDocNoSuccess: ['itemDocNo'],

  itemDetailSetItemDocNo: ['itemDocNoDtl'],
  itemDetailSetFormVisible: ['boolean'],

  itemDetailDeleteItemDocNo: ['itemDocNoDtl', 'resId'],
  itemDetailCreateItemDocNo: ['formikBag', 'itemDocNoDtl', 'resId'],
  itemDetailUpdateItemDocNo: ['formikBag', 'itemDocNoDtl', 'resId']
});

export const ItemDetailTypes = Types;
export default Creators;
