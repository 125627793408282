/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LspSlsOrdFulfillmentChartTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const lspSlsOrdFulfillmentChartResetTimestamp = (state, { criteria }) => ({
  ...state,
  criteria: criteria || state.criteria,
  lspSlsOrdFulfillmentChartTimestamp: Date.now()
});

export const lspSlsOrdFulfillmentChartFetchIsLoading = (state, { boolean }) => ({
  ...state,
  lspSlsOrdFulfillmentDataIsLoading: boolean
});

export const lspSlsOrdFulfillmentChartFetchSuccess = (state, { data }) => {
  return {
    ...state,
    lspSlsOrdFulfillmentData: data
  };
};

export const lspSlsOrdFulfillmentChartSetVisibleAverageKeys = (state, { visibleKeys }) => {
  return {
    ...state,
    lspSlsOrdFulfillmentChartVisibleAverageKeys: visibleKeys
  };
};

export const lspSlsOrdFulfillmentChartChangeProcess = (state, { formikBag, process }) => {
  formikBag.setSubmitting(true);
  let processOptions = [];

  if (process === 'daily') {
    processOptions = [
      { label: '7 days', value: '7d' },
      { label: '14 days', value: '14d' },
      { label: '21 days', value: '21d' },
      { label: '28 days', value: '28d' }
    ];
    formikBag.setFieldValue('time_period', '7d');
  } else if (process === 'weekly') {
    processOptions = [
      { label: '1 month', value: '1m' },
      { label: '2 months', value: '2m' },
      { label: '3 months', value: '3m' }
    ];
    formikBag.setFieldValue('time_period', '1m');
  } else {
    processOptions = [
      { label: '3 months', value: '3m' },
      { label: '6 months', value: '6m' },
      { label: '12 months', value: '12m' }
    ];
    formikBag.setFieldValue('time_period', '3m');
  }

  formikBag.setSubmitting(false);

  return { ...state, processOptions };
};

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LspSlsOrdFulfillmentChartTypes.LSP_SLS_ORD_FULFILLMENT_CHART_RESET_TIMESTAMP]: lspSlsOrdFulfillmentChartResetTimestamp,
  [LspSlsOrdFulfillmentChartTypes.LSP_SLS_ORD_FULFILLMENT_CHART_FETCH_IS_LOADING]: lspSlsOrdFulfillmentChartFetchIsLoading,
  [LspSlsOrdFulfillmentChartTypes.LSP_SLS_ORD_FULFILLMENT_CHART_FETCH_SUCCESS]: lspSlsOrdFulfillmentChartFetchSuccess,
  [LspSlsOrdFulfillmentChartTypes.LSP_SLS_ORD_FULFILLMENT_CHART_CHANGE_PROCESS]: lspSlsOrdFulfillmentChartChangeProcess,
  [LspSlsOrdFulfillmentChartTypes.LSP_SLS_ORD_FULFILLMENT_CHART_SET_VISIBLE_AVERAGE_KEYS]: lspSlsOrdFulfillmentChartSetVisibleAverageKeys
});
