import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  workerGdsRcptAnalysisReportInitWorkerGdsRcptAnalysis: null,
  workerGdsRcptAnalysisReportInitWorkerGdsRcptAnalysisSuccess: ['criteria'],
  workerGdsRcptAnalysisReportWorkerGdsRcptAnalysis: ['formikBag', 'criteria'],
  workerGdsRcptAnalysisReportWorkerGdsRcptAnalysisSuccess: ['criteria', 'reportData'],
  workerGdsRcptAnalysisReportReportLoading: ['boolean'],

  workerGdsRcptAnalysisReportFetchUserOptions: ['search'],
  workerGdsRcptAnalysisReportFetchUserOptionLoading: ['boolean'],
  workerGdsRcptAnalysisReportFetchUserOptionSuccess: ['options']
});

export const WorkerGdsRcptAnalysisReportTypes = Types;
export default Creators;
