import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob1501IndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1501IndexGoToWhseJobDocument: ['hdrId'],
  whseJob1501IndexGoToAudit: ['hdrId'],
  whseJob1501IndexGoToPrint: ['document'],
  whseJob1501IndexFetchWhseJob1501Index: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1501IndexFetchWhseJob1501IndexLoading: ['boolean'],
  whseJob1501IndexFetchWhseJob1501IndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  whseJob1501IndexAddSelectedDocuments: ['selectedDocuments'],
  whseJob1501IndexRemoveSelectedDocuments: ['selectedDocuments'],
  whseJob1501IndexSetWorkspaceVisible: ['boolean']
});

export const WhseJob1501IndexTypes = Types;
export default Creators;
