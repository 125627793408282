/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PrfDelDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const prfDelDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const prfDelDetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false
});

export const prfDelDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const prfDelDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const prfDelDetailUpdateDocumentSuccess = (state, { documentHeader, outbOrdHdrs }) => ({
  ...state,
  documentHeader,
  outbOrdHdrs
});

export const prfDelDetailShowOutbOrdHdrsSuccess = (state, { outbOrdHdrs }) => ({
  ...state,
  outbOrdHdrs
});

export const prfDelDetailSearchDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const prfDelDetailSearchDocumentSuccess = (state, { options }) => ({
  ...state,
  documentOptions: options
});

export const prfDelDetailFetchPrfDelDetailLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const prfDelDetailShowDocPhotosSuccess = (state, { docPhotos }) => ({
  ...state,
  docPhotos
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PrfDelDetailTypes.PRF_DEL_DETAIL_RESET_TIMESTAMP]: prfDelDetailResetTimestamp,
  [PrfDelDetailTypes.PRF_DEL_DETAIL_SET_HDR_ID]: prfDelDetailSetHdrId,
  [PrfDelDetailTypes.PRF_DEL_DETAIL_SHOW_HEADER_SUCCESS]: prfDelDetailShowHeaderSuccess,
  [PrfDelDetailTypes.PRF_DEL_DETAIL_UPDATE_DOCUMENT_SUCCESS]: prfDelDetailUpdateDocumentSuccess,
  [PrfDelDetailTypes.PRF_DEL_DETAIL_SHOW_DOCUMENT_LOADING]: prfDelDetailShowDocumentLoading,
  [PrfDelDetailTypes.PRF_DEL_DETAIL_SHOW_OUTB_ORD_HDRS_SUCCESS]: prfDelDetailShowOutbOrdHdrsSuccess,
  [PrfDelDetailTypes.PRF_DEL_DETAIL_SEARCH_DOCUMENT_LOADING]: prfDelDetailSearchDocumentLoading,
  [PrfDelDetailTypes.PRF_DEL_DETAIL_SEARCH_DOCUMENT_SUCCESS]: prfDelDetailSearchDocumentSuccess,
  [PrfDelDetailTypes.PRF_DEL_DETAIL_FETCH_PRF_DEL_DETAIL_LOADING]: prfDelDetailFetchPrfDelDetailLoading,
  [PrfDelDetailTypes.PRF_DEL_DETAIL_SHOW_DOC_PHOTOS_SUCCESS]: prfDelDetailShowDocPhotosSuccess
});
