import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stkTrfDetailResetTimestamp: null,
  stkTrfDetailSetHdrId: ['hdrId', 'itemId'],

  stkTrfDetailInitHeader: ['docSubType'],
  stkTrfDetailShowHeader: ['hdrId'],
  stkTrfDetailShowHeaderSuccess: ['documentHeader'],

  stkTrfDetailUpdateHeader: ['formikBag', 'documentHeader'],
  stkTrfDetailCreateHeader: ['formikBag', 'documentHeader'],
  stkTrfDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  stkTrfDetailShowDocumentLoading: ['boolean'],
  stkTrfDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  stkTrfDetailShowDetails: ['hdrId'],
  stkTrfDetailShowDetailsSuccess: ['documentDetails'],

  stkTrfDetailSetDetailVisible: ['boolean'],
  stkTrfDetailSetDocumentDetail: ['documentDetail'],

  stkTrfDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  stkTrfDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  stkTrfDetailDeleteDetail: ['hdrId', 'documentDetail'],

  stkTrfDetailFetchCurrencyOptions: ['search'],
  stkTrfDetailFetchCurrencyOptionLoading: ['boolean'],
  stkTrfDetailFetchCurrencyOptionSuccess: ['options'],
  stkTrfDetailChangeCurrency: ['formikBag', 'currencyId'],

  stkTrfDetailFetchToDivisionOptions: ['docSubType', 'search'],
  stkTrfDetailFetchToDivisionOptionLoading: ['boolean'],
  stkTrfDetailFetchToDivisionOptionSuccess: ['options'],
  stkTrfDetailChangeToDivision: ['formikBag', 'toDivisionId'],

  stkTrfDetailFetchToLocationOptions: ['hdrId', 'docSubType', 'search'],
  stkTrfDetailFetchToLocationOptionLoading: ['boolean'],
  stkTrfDetailFetchToLocationOptionSuccess: ['options'],

  stkTrfDetailFetchLocationOptions: ['docSubType', 'search'],
  stkTrfDetailFetchLocationOptionLoading: ['boolean'],
  stkTrfDetailFetchLocationOptionSuccess: ['options'],

  stkTrfDetailFetchHdrToLocationOptions: ['docSubType', 'search'],
  stkTrfDetailFetchHdrToLocationOptionLoading: ['boolean'],
  stkTrfDetailFetchHdrToLocationOptionSuccess: ['options'],

  stkTrfDetailFetchItemOptions: ['search'],
  stkTrfDetailFetchItemOptionLoading: ['boolean'],
  stkTrfDetailFetchItemOptionSuccess: ['options'],
  stkTrfDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  stkTrfDetailFetchUomOptions: ['itemId', 'search'],
  stkTrfDetailFetchUomOptionLoading: ['boolean'],
  stkTrfDetailFetchUomOptionSuccess: ['options'],
  stkTrfDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  stkTrfDetailFetchProjectOptions: ['search'],
  stkTrfDetailFetchProjectOptionLoading: ['boolean'],
  stkTrfDetailFetchProjectOptionSuccess: ['options']
});

export const StkTrfDetailTypes = Types;
export default Creators;
