/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { OutbOrd02Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const outbOrd02ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
});

export const outbOrd02FetchOutbOrd02Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const outbOrd02FetchOutbOrd02Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const outbOrd02AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const outbOrd02RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const outbOrd02SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const outbOrd02CreateOutbOrd02Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const outbOrd02CreateOutbOrd02Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

export const outbOrd02SetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [OutbOrd02Types.OUTB_ORD02_RESET_TIMESTAMP]: outbOrd02ResetTimestamp,
  [OutbOrd02Types.OUTB_ORD02_FETCH_OUTB_ORD02_LOADING]: outbOrd02FetchOutbOrd02Loading,
  [OutbOrd02Types.OUTB_ORD02_FETCH_OUTB_ORD02_SUCCESS]: outbOrd02FetchOutbOrd02Success,
  [OutbOrd02Types.OUTB_ORD02_ADD_SELECTED_DOCUMENTS]: outbOrd02AddSelectedDocuments,
  [OutbOrd02Types.OUTB_ORD02_REMOVE_SELECTED_DOCUMENTS]: outbOrd02RemoveSelectedDocuments,
  [OutbOrd02Types.OUTB_ORD02_SET_WORKSPACE_VISIBLE]: outbOrd02SetWorkspaceVisible,
  [OutbOrd02Types.OUTB_ORD02_CREATE_OUTB_ORD02_LOADING]: outbOrd02CreateOutbOrd02Loading,
  [OutbOrd02Types.OUTB_ORD02_CREATE_OUTB_ORD02_SUCCESS]: outbOrd02CreateOutbOrd02Success,
  [OutbOrd02Types.OUTB_ORD02_SET_EXPANDED_ROWS]: outbOrd02SetExpandedRows
});
