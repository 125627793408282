/**
 * The initial values for the redux state.
 */

export default {
  workerPerformanceChartTimestamp: 0,

  workerPerformanceChartWidth: 800,

  workerPerformanceData: [{}],
  summaryData: {},
  avgData: {},
  workerPerformanceQueryStartDate: '',
  workerPerformanceQueryEndDate: '',
  workerPerformanceDataIsLoading: false,

  criteria: {
    whse_job_proc: 'WHSE_JOB_14_01',
    time_period_type: 'weekly',
    query_date: new Date()
  }
};
