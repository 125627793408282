import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import StockAgingReportActions, { StockAgingReportTypes } from '../Stores/StockAgingReport/Actions';

const getAppStore = state => state.app;

export function* stockAgingReportInitStockAging() {
  try {
    yield put(StockAgingReportActions.stockAgingReportReportLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseReport/initStockAging/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(StockAgingReportActions.stockAgingReportInitStockAgingSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StockAgingReportActions.stockAgingReportReportLoading(false));
  }
}

export function* stockAgingReportStockAging({ formikBag, criteria }) {
  try {
    formikBag.setSubmitting(true);
    yield put(StockAgingReportActions.stockAgingReportReportLoading(true));

    const objCriteria = {};
    if ('date' in criteria) {
      objCriteria.date = criteria.date;
    }
    if ('uom_option' in criteria) {
      objCriteria.uom_option = criteria.uom_option;
    }
    if ('company_ids_select2' in criteria) {
      const values = criteria.company_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.company_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_ids_select2' in criteria) {
      const values = criteria.item_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_01_ids_select2' in criteria) {
      const values = criteria.item_group_01_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_01_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_02_ids_select2' in criteria) {
      const values = criteria.item_group_02_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_02_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_03_ids_select2' in criteria) {
      const values = criteria.item_group_03_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_03_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('location_ids_select2' in criteria) {
      const values = criteria.location_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.location_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    let columns = [];
    if (criteria.columns.target.length > 0) {
      columns = criteria.columns.target.map(entry => {
        return entry.column;
      });
    }

    const app = yield select(getAppStore);
    const postData = {
      sorts: [],
      criteria: objCriteria,
      columns
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseReport/stockAging/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(
        StockAgingReportActions.stockAgingReportStockAgingSuccess(criteria, result.data.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(StockAgingReportActions.stockAgingReportReportLoading(false));
  }
}

export function* stockAgingReportFetchItemOptions({ search }) {
  try {
    yield put(StockAgingReportActions.stockAgingReportFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StockAgingReportActions.stockAgingReportFetchItemOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StockAgingReportActions.stockAgingReportFetchItemOptionLoading(false));
  }
}

export function* stockAgingReportFetchItemGroup01Options({ search }) {
  try {
    yield put(StockAgingReportActions.stockAgingReportFetchItemGroup01OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StockAgingReportActions.stockAgingReportFetchItemGroup01OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StockAgingReportActions.stockAgingReportFetchItemGroup01OptionLoading(false));
  }
}

export function* stockAgingReportFetchItemGroup02Options({ search }) {
  try {
    yield put(StockAgingReportActions.stockAgingReportFetchItemGroup02OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup02/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StockAgingReportActions.stockAgingReportFetchItemGroup02OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StockAgingReportActions.stockAgingReportFetchItemGroup02OptionLoading(false));
  }
}

export function* stockAgingReportFetchItemGroup03Options({ search }) {
  try {
    yield put(StockAgingReportActions.stockAgingReportFetchItemGroup03OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup03/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StockAgingReportActions.stockAgingReportFetchItemGroup03OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StockAgingReportActions.stockAgingReportFetchItemGroup03OptionLoading(false));
  }
}

export function* stockAgingReportFetchLocationOptions({ search }) {
  try {
    yield put(StockAgingReportActions.stockAgingReportFetchLocationOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `location/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StockAgingReportActions.stockAgingReportFetchLocationOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StockAgingReportActions.stockAgingReportFetchLocationOptionLoading(false));
  }
}

export function* stockAgingReportFetchCompanyOptions({ search }) {
  try {
    yield put(StockAgingReportActions.stockAgingReportFetchCompanyOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `company/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} - ${d.name_01}`
      }));

      yield put(StockAgingReportActions.stockAgingReportFetchCompanyOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StockAgingReportActions.stockAgingReportFetchCompanyOptionLoading(false));
  }
}

export const saga = [
  takeLatest(
    StockAgingReportTypes.STOCK_AGING_REPORT_INIT_STOCK_AGING,
    stockAgingReportInitStockAging
  ),
  takeLatest(StockAgingReportTypes.STOCK_AGING_REPORT_STOCK_AGING, stockAgingReportStockAging),
  takeLatest(
    StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_COMPANY_OPTIONS,
    stockAgingReportFetchCompanyOptions
  ),
  takeLatest(
    StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_ITEM_OPTIONS,
    stockAgingReportFetchItemOptions
  ),
  takeLatest(
    StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_ITEM_GROUP01_OPTIONS,
    stockAgingReportFetchItemGroup01Options
  ),
  takeLatest(
    StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_ITEM_GROUP02_OPTIONS,
    stockAgingReportFetchItemGroup02Options
  ),
  takeLatest(
    StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_ITEM_GROUP03_OPTIONS,
    stockAgingReportFetchItemGroup03Options
  ),
  takeLatest(
    StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_LOCATION_OPTIONS,
    stockAgingReportFetchLocationOptions
  )
];
