import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspSlsRtnExcel01SetStrProcType: ['strProcType'],
  lspSlsRtnExcel01ShowBatchJobStatus: null,
  lspSlsRtnExcel01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  lspSlsRtnExcel01UploadExcel: ['formikBag', 'projectId', 'debtorId', 'slsOrdFile'],
  lspSlsRtnExcel01UploadLoading: ['boolean'],

  lspSlsRtnExcel01FetchDebtorOptions: ['search'],
  lspSlsRtnExcel01FetchDebtorOptionLoading: ['boolean'],
  lspSlsRtnExcel01FetchDebtorOptionSuccess: ['options'],

  lspSlsRtnExcel01FetchProjectOptions: ['search'],
  lspSlsRtnExcel01FetchProjectOptionLoading: ['boolean'],
  lspSlsRtnExcel01FetchProjectOptionSuccess: ['options']
});

export const LspSlsRtnExcel01Types = Types;
export default Creators;
