import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadListSync01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  loadListSync01GoToDocument: ['hdrId'],
  loadListSync01FetchLoadListSync01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  loadListSync01FetchLoadListSync01Loading: ['boolean'],
  loadListSync01FetchLoadListSync01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  loadListSync01AddSelectedDocuments: ['selectedDocuments'],
  loadListSync01RemoveSelectedDocuments: ['selectedDocuments'],
  loadListSync01SetWorkspaceVisible: ['boolean'],

  loadListSync01SyncNow: ['hdrIds'],
  loadListSync01SyncNowSuccess: null,
  loadListSync01SyncLoading: ['boolean']
});

export const LoadListSync01Types = Types;
export default Creators;
