/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LspSlsOrdExcel01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const lspSlsOrdExcel01SetStrProcType = (state, { strProcType }) => ({
  ...state,
  strProcType
});

export const lspSlsOrdExcel01ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const lspSlsOrdExcel01UploadLoading = (state, { boolean }) => ({
  ...state,
  uploadIsLoading: boolean
});

export const lspSlsOrdExcel01FetchDebtorOptionLoading = (state, { boolean }) => ({
  ...state,
  debtorIsLoading: boolean
});

export const lspSlsOrdExcel01FetchDebtorOptionSuccess = (state, { options }) => ({
  ...state,
  debtorOptions: options
});

export const lspSlsOrdExcel01FetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const lspSlsOrdExcel01FetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LspSlsOrdExcel01Types.LSP_SLS_ORD_EXCEL01_SET_STR_PROC_TYPE]: lspSlsOrdExcel01SetStrProcType,
  [LspSlsOrdExcel01Types.LSP_SLS_ORD_EXCEL01_SHOW_BATCH_JOB_STATUS_SUCCESS]: lspSlsOrdExcel01ShowBatchJobStatusSuccess,
  [LspSlsOrdExcel01Types.LSP_SLS_ORD_EXCEL01_UPLOAD_LOADING]: lspSlsOrdExcel01UploadLoading,

  [LspSlsOrdExcel01Types.LSP_SLS_ORD_EXCEL01_FETCH_DEBTOR_OPTION_LOADING]: lspSlsOrdExcel01FetchDebtorOptionLoading,
  [LspSlsOrdExcel01Types.LSP_SLS_ORD_EXCEL01_FETCH_DEBTOR_OPTION_SUCCESS]: lspSlsOrdExcel01FetchDebtorOptionSuccess,

  [LspSlsOrdExcel01Types.LSP_SLS_ORD_EXCEL01_FETCH_PROJECT_OPTION_LOADING]: lspSlsOrdExcel01FetchProjectOptionLoading,
  [LspSlsOrdExcel01Types.LSP_SLS_ORD_EXCEL01_FETCH_PROJECT_OPTION_SUCCESS]: lspSlsOrdExcel01FetchProjectOptionSuccess
});
