import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsOrdDetailResetTimestamp: null,
  slsOrdDetailSetHdrId: ['hdrId', 'itemId'],

  slsOrdDetailInitHeader: null,
  slsOrdDetailShowHeader: ['hdrId'],
  slsOrdDetailShowHeaderSuccess: ['documentHeader'],

  slsOrdDetailUpdateHeader: ['formikBag', 'documentHeader'],
  slsOrdDetailCreateHeader: ['formikBag', 'documentHeader'],
  slsOrdDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  slsOrdDetailShowDocumentLoading: ['boolean'],
  slsOrdDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  slsOrdDetailShowDetails: ['hdrId'],
  slsOrdDetailShowDetailsSuccess: ['documentDetails'],

  slsOrdDetailSetDetailVisible: ['boolean'],
  slsOrdDetailSetDocumentDetail: ['documentDetail'],

  slsOrdDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  slsOrdDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  slsOrdDetailDeleteDetail: ['hdrId', 'documentDetail'],

  slsOrdDetailFetchSalesmanOptions: ['search'],
  slsOrdDetailFetchSalesmanOptionLoading: ['boolean'],
  slsOrdDetailFetchSalesmanOptionSuccess: ['options'],

  slsOrdDetailFetchDeliveryPointOptions: ['search'],
  slsOrdDetailFetchDeliveryPointOptionLoading: ['boolean'],
  slsOrdDetailFetchDeliveryPointOptionSuccess: ['options'],
  slsOrdDetailChangeDeliveryPoint: ['formikBag', 'deliveryPointId'],

  slsOrdDetailFetchCreditTermOptions: ['search'],
  slsOrdDetailFetchCreditTermOptionLoading: ['boolean'],
  slsOrdDetailFetchCreditTermOptionSuccess: ['options'],

  slsOrdDetailFetchCurrencyOptions: ['search'],
  slsOrdDetailFetchCurrencyOptionLoading: ['boolean'],
  slsOrdDetailFetchCurrencyOptionSuccess: ['options'],
  slsOrdDetailChangeCurrency: ['formikBag', 'currencyId'],

  slsOrdDetailFetchLocationOptions: ['search'],
  slsOrdDetailFetchLocationOptionLoading: ['boolean'],
  slsOrdDetailFetchLocationOptionSuccess: ['options'],

  slsOrdDetailFetchItemOptions: ['search'],
  slsOrdDetailFetchItemOptionLoading: ['boolean'],
  slsOrdDetailFetchItemOptionSuccess: ['options'],
  slsOrdDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  slsOrdDetailFetchItemBatchOptions: ['itemId', 'search'],
  slsOrdDetailFetchItemBatchOptionLoading: ['boolean'],
  slsOrdDetailFetchItemBatchOptionSuccess: ['options'],
  slsOrdDetailChangeItemBatch: ['formikBag', 'hdrId', 'itemBatchId'],

  slsOrdDetailFetchUomOptions: ['itemId', 'search'],
  slsOrdDetailFetchUomOptionLoading: ['boolean'],
  slsOrdDetailFetchUomOptionSuccess: ['options'],
  slsOrdDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  slsOrdDetailFetchProjectOptions: ['search'],
  slsOrdDetailFetchProjectOptionLoading: ['boolean'],
  slsOrdDetailFetchProjectOptionSuccess: ['options']
});

export const SlsOrdDetailTypes = Types;
export default Creators;
