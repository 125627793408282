/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CountAdjDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const countAdjDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const countAdjDetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false,
  jobDetailIsVisible: false
});

export const countAdjDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const countAdjDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const countAdjDetailUpdateDocumentSuccess = (
  state,
  { documentHeader, documentDetails }
) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const countAdjDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const countAdjDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const countAdjDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  whseJobType: documentDetail.whse_job_type,
  companyOptions: [],
  frStorageBinOptions: [],
  quantBalOptions: [],
  itemBatchOptions: [],
  uomOptions: [],
  toStorageBinOptions: []
});

export const countAdjDetailFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const countAdjDetailFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const countAdjDetailFetchCountAdjReasonOptionLoading = (state, { boolean }) => ({
  ...state,
  countAdjReasonIsLoading: boolean
});

export const countAdjDetailFetchCountAdjReasonOptionSuccess = (state, { options }) => ({
  ...state,
  countAdjReasonOptions: options
});

export const countAdjDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const countAdjDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const countAdjDetailFetchItemBatchOptionLoading = (state, { boolean }) => ({
  ...state,
  itemBatchIsLoading: boolean
});

export const countAdjDetailFetchItemBatchOptionSuccess = (state, { options }) => ({
  ...state,
  itemBatchOptions: options
});

export const countAdjDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const countAdjDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

export const countAdjDetailFetchStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBinIsLoading: boolean
});

export const countAdjDetailFetchStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  storageBinOptions: options
});

export const countAdjDetailFetchHandlingUnitOptionLoading = (state, { boolean }) => ({
  ...state,
  handlingUnitIsLoading: boolean
});

export const countAdjDetailFetchHandlingUnitOptionSuccess = (state, { options }) => ({
  ...state,
  handlingUnitOptions: options
});

// force refresh the formik form
export const countAdjDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const countAdjDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const countAdjDetailFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const countAdjDetailFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

export const countAdjDetailFetchStorageBayOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBayIsLoading: boolean
});

export const countAdjDetailFetchStorageBayOptionSuccess = (state, { options }) => ({
  ...state,
  storageBayOptions: options
});

export const countAdjDetailFetchQuantBalOptionLoading = (state, { boolean }) => ({
  ...state,
  quantBalIsLoading: boolean
});

export const countAdjDetailFetchQuantBalOptionSuccess = (state, { options }) => ({
  ...state,
  quantBalOptions: options
});

export const countAdjDetailUploadLoading = (state, { boolean }) => ({
  ...state,
  uploadIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_RESET_TIMESTAMP]: countAdjDetailResetTimestamp,
  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_SET_HDR_ID]: countAdjDetailSetHdrId,

  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_SHOW_HEADER_SUCCESS]: countAdjDetailShowHeaderSuccess,

  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_UPDATE_DOCUMENT_SUCCESS]: countAdjDetailUpdateDocumentSuccess,

  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_SHOW_DOCUMENT_LOADING]: countAdjDetailShowDocumentLoading,

  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_SHOW_DETAILS_SUCCESS]: countAdjDetailShowDetailsSuccess,

  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_SET_DETAIL_VISIBLE]: countAdjDetailSetDetailVisible,
  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_SET_DOCUMENT_DETAIL]: countAdjDetailSetDocumentDetail,

  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_UPDATE_DETAILS]: countAdjDetailUpdateDetails,
  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_CREATE_DETAIL]: countAdjDetailCreateDetail,

  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_COMPANY_OPTION_LOADING]: countAdjDetailFetchCompanyOptionLoading,
  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_COMPANY_OPTION_SUCCESS]: countAdjDetailFetchCompanyOptionSuccess,

  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_COUNT_ADJ_REASON_OPTION_LOADING]: countAdjDetailFetchCountAdjReasonOptionLoading,
  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_COUNT_ADJ_REASON_OPTION_SUCCESS]: countAdjDetailFetchCountAdjReasonOptionSuccess,

  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_ITEM_OPTION_LOADING]: countAdjDetailFetchItemOptionLoading,
  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: countAdjDetailFetchItemOptionSuccess,

  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_ITEM_BATCH_OPTION_LOADING]: countAdjDetailFetchItemBatchOptionLoading,
  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_ITEM_BATCH_OPTION_SUCCESS]: countAdjDetailFetchItemBatchOptionSuccess,

  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_UOM_OPTION_LOADING]: countAdjDetailFetchUomOptionLoading,
  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_UOM_OPTION_SUCCESS]: countAdjDetailFetchUomOptionSuccess,

  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_STORAGE_BIN_OPTION_LOADING]: countAdjDetailFetchStorageBinOptionLoading,
  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_STORAGE_BIN_OPTION_SUCCESS]: countAdjDetailFetchStorageBinOptionSuccess,

  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_HANDLING_UNIT_OPTION_LOADING]: countAdjDetailFetchHandlingUnitOptionLoading,
  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_HANDLING_UNIT_OPTION_SUCCESS]: countAdjDetailFetchHandlingUnitOptionSuccess,

  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_STORAGE_ROW_OPTION_LOADING]: countAdjDetailFetchStorageRowOptionLoading,
  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_STORAGE_ROW_OPTION_SUCCESS]: countAdjDetailFetchStorageRowOptionSuccess,

  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_STORAGE_BAY_OPTION_LOADING]: countAdjDetailFetchStorageBayOptionLoading,
  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_STORAGE_BAY_OPTION_SUCCESS]: countAdjDetailFetchStorageBayOptionSuccess,

  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_QUANT_BAL_OPTION_LOADING]: countAdjDetailFetchQuantBalOptionLoading,
  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_QUANT_BAL_OPTION_SUCCESS]: countAdjDetailFetchQuantBalOptionSuccess,

  [CountAdjDetailTypes.COUNT_ADJ_DETAIL_UPLOAD_LOADING]: countAdjDetailUploadLoading
});
