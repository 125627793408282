import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsOrdSync01ShowSyncSetting: null,
  slsOrdSync01ShowSyncSettingLoading: ['boolean'],
  slsOrdSync01ShowSyncSettingSuccess: ['syncSetting'],
  slsOrdSync01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  slsOrdSync01ShowBatchJobStatus: null,
  slsOrdSync01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  slsOrdSync01SyncNow: ['formikBag'],
  slsOrdSync01SyncLoading: ['boolean']
});

export const SlsOrdSync01Types = Types;
export default Creators;
