/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SystemSettingTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const systemSettingResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const systemSettingFetchSettingSuccess = (state, { data }) => ({
  ...state,
  data
});

export const systemSettingSetLoading = (state, { isLoading }) => ({
  ...state,
  isLoading
});

export const systemSettingFetchCreditTermSuccess = (state, { creditTermOptions }) => ({
  ...state,
  creditTermOptions
});

export const systemSettingFetchCreditTermLoading = (state, { creditTermLoading }) => ({
  ...state,
  creditTermLoading
});
/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SystemSettingTypes.SYSTEM_SETTING_RESET_TIMESTAMP]: systemSettingResetTimestamp,
  [SystemSettingTypes.SYSTEM_SETTING_FETCH_SETTING_SUCCESS]: systemSettingFetchSettingSuccess,
  [SystemSettingTypes.SYSTEM_SETTING_SET_LOADING]: systemSettingSetLoading,
  [SystemSettingTypes.SYSTEM_SETTING_FETCH_CREDIT_TERM_SUCCESS]: systemSettingFetchCreditTermSuccess,
  [SystemSettingTypes.SYSTEM_SETTING_FETCH_CREDIT_TERM_LOADING]: systemSettingFetchCreditTermLoading
});
