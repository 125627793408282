/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StkRcpt03Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const stkRcpt03ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  toStorageBinOptions: INITIAL_STATE.toStorageBinOptions,
  toStorageBinOption: INITIAL_STATE.toStorageBinOption
});

export const stkRcpt03FetchStkRcpt03Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const stkRcpt03FetchStkRcpt03Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const stkRcpt03AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const stkRcpt03RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const stkRcpt03SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const stkRcpt03CreateStkRcpt03Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const stkRcpt03CreateStkRcpt03Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StkRcpt03Types.STK_RCPT03_RESET_TIMESTAMP]: stkRcpt03ResetTimestamp,
  [StkRcpt03Types.STK_RCPT03_FETCH_STK_RCPT03_LOADING]: stkRcpt03FetchStkRcpt03Loading,
  [StkRcpt03Types.STK_RCPT03_FETCH_STK_RCPT03_SUCCESS]: stkRcpt03FetchStkRcpt03Success,
  [StkRcpt03Types.STK_RCPT03_ADD_SELECTED_DOCUMENTS]: stkRcpt03AddSelectedDocuments,
  [StkRcpt03Types.STK_RCPT03_REMOVE_SELECTED_DOCUMENTS]: stkRcpt03RemoveSelectedDocuments,
  [StkRcpt03Types.STK_RCPT03_SET_WORKSPACE_VISIBLE]: stkRcpt03SetWorkspaceVisible,
  [StkRcpt03Types.STK_RCPT03_CREATE_STK_RCPT03_LOADING]: stkRcpt03CreateStkRcpt03Loading,
  [StkRcpt03Types.STK_RCPT03_CREATE_STK_RCPT03_SUCCESS]: stkRcpt03CreateStkRcpt03Success
});
