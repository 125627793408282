import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  prodOrdFServ02ShowSyncSetting: null,
  prodOrdFServ02ShowSyncSettingLoading: ['boolean'],
  prodOrdFServ02ShowSyncSettingSuccess: ['syncSetting'],
  prodOrdFServ02UpdateSyncSetting: ['formikBag', 'syncSetting'],
  prodOrdFServ02ShowBatchJobStatus: null,
  prodOrdFServ02ShowBatchJobStatusSuccess: ['batchJobStatus'],

  prodOrdFServ02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prodOrdFServ02GoToDocument: ['hdrId'],
  prodOrdFServ02FetchProdOrdFServ02: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prodOrdFServ02FetchProdOrdFServ02Loading: ['boolean'],
  prodOrdFServ02FetchProdOrdFServ02Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  prodOrdFServ02AddSelectedDocuments: ['selectedDocuments'],
  prodOrdFServ02RemoveSelectedDocuments: ['selectedDocuments'],
  prodOrdFServ02SetWorkspaceVisible: ['boolean'],

  prodOrdFServ02Export: ['hdrIds'],
  prodOrdFServ02ExportSuccess: null,
  prodOrdFServ02ExportLoading: ['boolean']
});

export const ProdOrdFServ02Types = Types;
export default Creators;
