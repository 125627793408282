import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob1502ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1502GoToDocument: ['hdrId'],
  whseJob1502FetchWhseJob1502: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1502FetchWhseJob1502Loading: ['boolean'],
  whseJob1502FetchWhseJob1502Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  whseJob1502AddSelectedDocuments: ['selectedDocuments'],
  whseJob1502RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob1502SetWorkspaceVisible: ['boolean'],
  whseJob1502CreateWhseJob1502: ['hdrIds'],
  whseJob1502CreateWhseJob1502Loading: ['boolean'],
  whseJob1502CreateWhseJob1502Success: ['newDocuments']
});

export const WhseJob1502Types = Types;
export default Creators;
