import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import FailDel01Actions, { FailDel01Types } from '../Stores/FailDel01/Actions';

const getAppStore = state => state.app;

const getFailDel01Store = state => state.failDel01;

export function* failDel01ScanBarcode({ value }) {
  try {
    yield put(FailDel01Actions.failDel01FetchFailDel01Loading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      value
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `failDel/addDetailsByBarcode`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const failDel01 = yield select(getFailDel01Store);

      const { outbOrdHdrs: oldOutbOrdHdrs } = failDel01;
      const { outb_ord_hdrs: retOutbOrdHdrs } = result.data;

      // remove outbOrdHdrs if existed
      const filterOutbOrdHdrs = oldOutbOrdHdrs.filter(oldOutbOrdHdr => {
        const foundHdrId = retOutbOrdHdrs.reduce((lastId, data, index) => {
          if (oldOutbOrdHdr.id === data.id) {
            retOutbOrdHdrs.splice(index, 1);
            return data.id;
          }
          return lastId;
        }, -1);

        return foundHdrId === -1;
      });

      // append the remaining retOutbOrdHdrs
      const newOutbOrdHdrs = [];
      filterOutbOrdHdrs.forEach(data => {
        newOutbOrdHdrs.push(data);
      });
      retOutbOrdHdrs.forEach(data => {
        newOutbOrdHdrs.push(data);
      });

      yield put(FailDel01Actions.failDel01ScanBarcodeSuccess(newOutbOrdHdrs));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(FailDel01Actions.failDel01FetchFailDel01Loading(false));
  }
}

export function* failDel01SearchDocument({ search }) {
  if (search.length <= 2) {
    return;
  }
  try {
    yield put(FailDel01Actions.failDel01SearchDocumentLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `failDel/searchDocument`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: `${d.res_type}${d.id}`,
        label: `${d.doc_code} ${d.doc_date} ${d.ref_code_01}`,
        docCode: d.doc_code,
        docDate: d.doc_date,
        refCode01: d.ref_code_01
      }));

      yield put(FailDel01Actions.failDel01SearchDocumentSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(FailDel01Actions.failDel01SearchDocumentLoading(false));
  }
}

export function* failDel01UpOutbOrd({ outbOrdHdr }) {
  yield put(FailDel01Actions.failDel01FetchFailDel01Loading(true));

  const failDel01 = yield select(getFailDel01Store);
  const { outbOrdHdrs } = failDel01;

  const oldIndex = outbOrdHdrs.indexOf(outbOrdHdr);
  const newIndex = oldIndex - 1;

  if (newIndex >= 0) {
    outbOrdHdrs.splice(newIndex, 0, outbOrdHdrs.splice(oldIndex, 1)[0]);
    yield put(FailDel01Actions.failDel01UpdateOutbOrds(outbOrdHdrs));
  }

  yield put(FailDel01Actions.failDel01FetchFailDel01Loading(false));
}

export function* failDel01DownOutbOrd({ outbOrdHdr }) {
  yield put(FailDel01Actions.failDel01FetchFailDel01Loading(true));

  const failDel01 = yield select(getFailDel01Store);
  const { outbOrdHdrs } = failDel01;

  const oldIndex = outbOrdHdrs.indexOf(outbOrdHdr);
  const newIndex = oldIndex + 1;

  if (newIndex < outbOrdHdrs.length) {
    outbOrdHdrs.splice(newIndex, 0, outbOrdHdrs.splice(oldIndex, 1)[0]);
    yield put(FailDel01Actions.failDel01UpdateOutbOrds(outbOrdHdrs));
  }

  yield put(FailDel01Actions.failDel01FetchFailDel01Loading(false));
}

export function* failDel01CreateFailDel01({ formikBag, formDetail, outbOrdHdrs: oldOutbOrdHdrs }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    let lineNo = 1;
    const outbOrdHdrs = oldOutbOrdHdrs.map(d => {
      lineNo += 1;
      return {
        line_no: lineNo,
        id: d.id
      };
    });

    const postData = {
      data: formDetail,
      outbOrdHdrs
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'failDel/createProcess/FAIL_DEL_01',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(FailDel01Actions.failDel01CreateFailDel01Success(result.data));
      formikBag.resetForm();

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* failDel01FetchToStorageBinOptions({ search }) {
  try {
    yield put(FailDel01Actions.failDel01FetchToStorageBinOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: [
        {
          field: 'bin_types',
          value: [
            2 // LOADING_AREA
          ]
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(FailDel01Actions.failDel01FetchToStorageBinOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(FailDel01Actions.failDel01FetchToStorageBinOptionLoading(false));
  }
}

export const saga = [
  takeLatest(
    FailDel01Types.FAIL_DEL01_FETCH_TO_STORAGE_BIN_OPTIONS,
    failDel01FetchToStorageBinOptions
  ),
  takeLatest(FailDel01Types.FAIL_DEL01_SCAN_BARCODE, failDel01ScanBarcode),
  takeLatest(FailDel01Types.FAIL_DEL01_SEARCH_DOCUMENT, failDel01SearchDocument),
  takeLatest(FailDel01Types.FAIL_DEL01_UP_OUTB_ORD, failDel01UpOutbOrd),
  takeLatest(FailDel01Types.FAIL_DEL01_DOWN_OUTB_ORD, failDel01DownOutbOrd),
  takeLatest(FailDel01Types.FAIL_DEL01_CREATE_FAIL_DEL01, failDel01CreateFailDel01)
];
