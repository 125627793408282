import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import LoadListIndexActions, { LoadListIndexTypes } from '../Stores/LoadListIndex/Actions';

const getAppStore = state => state.app;

export function* loadListIndexFetchLoadListIndex({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(LoadListIndexActions.loadListIndexFetchLoadListIndexLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `loadList/index/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        LoadListIndexActions.loadListIndexFetchLoadListIndexSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListIndexActions.loadListIndexFetchLoadListIndexLoading(false));
  }
}

export function* loadListIndexGoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/loadListDetail/update/${hdrId}`));
}

export function* loadListIndexGoToAudit({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/auditResource/LOAD_LIST/${hdrId}/LoadListHdr`));
}

export function* loadListIndexGoToPrint({ document }) {
  try {
    yield put(LoadListIndexActions.loadListIndexFetchLoadListIndexLoading(true));

    const app = yield select(getAppStore);

    const getData = {
      hdrIds: [document.id]
    };

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `loadList/print/${app.curSiteFlowId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, `LoadList_${document.doc_code}.pdf`);
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListIndexActions.loadListIndexFetchLoadListIndexLoading(false));
  }
}

export function* loadListIndexResetLoadListSync01({ hdrIds }) {
  try {
    yield put(LoadListIndexActions.loadListIndexWorkspaceLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      hdrIds
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `loadList/syncProcess/RESET_LOAD_LIST_SYNC_01`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(LoadListIndexActions.loadListIndexResetLoadListSync01Success());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListIndexActions.loadListIndexWorkspaceLoading(false));
  }
}

export const saga = [
  takeLatest(LoadListIndexTypes.LOAD_LIST_INDEX_GO_TO_DOCUMENT, loadListIndexGoToDocument),
  takeLatest(LoadListIndexTypes.LOAD_LIST_INDEX_GO_TO_AUDIT, loadListIndexGoToAudit),
  takeLatest(
    LoadListIndexTypes.LOAD_LIST_INDEX_FETCH_LOAD_LIST_INDEX,
    loadListIndexFetchLoadListIndex
  ),
  takeLatest(LoadListIndexTypes.LOAD_LIST_INDEX_GO_TO_PRINT, loadListIndexGoToPrint),
  takeLatest(
    LoadListIndexTypes.LOAD_LIST_INDEX_RESET_LOAD_LIST_SYNC01,
    loadListIndexResetLoadListSync01
  )
];
