import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  workerPickListAnalysisReportInitWorkerPickListAnalysis: null,
  workerPickListAnalysisReportInitWorkerPickListAnalysisSuccess: ['criteria'],
  workerPickListAnalysisReportWorkerPickListAnalysis: ['formikBag', 'criteria'],
  workerPickListAnalysisReportWorkerPickListAnalysisSuccess: ['criteria', 'reportData'],
  workerPickListAnalysisReportReportLoading: ['boolean'],

  workerPickListAnalysisReportFetchUserOptions: ['search'],
  workerPickListAnalysisReportFetchUserOptionLoading: ['boolean'],
  workerPickListAnalysisReportFetchUserOptionSuccess: ['options']
});

export const WorkerPickListAnalysisReportTypes = Types;
export default Creators;
