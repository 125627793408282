import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  prodOrdExcel01SetStrProcType: ['strProcType'],
  prodOrdExcel01ShowBatchJobStatus: null,
  prodOrdExcel01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  prodOrdExcel01UploadExcel: ['formikBag', 'hdrFile', 'dtlFile'],
  prodOrdExcel01UploadLoading: ['boolean']
});

export const ProdOrdExcel01Types = Types;
export default Creators;
