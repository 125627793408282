import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stkRcpt02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkRcpt02GoToDocument: ['hdrId'],
  stkRcpt02FetchStkRcpt02: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkRcpt02FetchStkRcpt02Loading: ['boolean'],
  stkRcpt02FetchStkRcpt02Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  stkRcpt02AddSelectedDocuments: ['selectedDocuments'],
  stkRcpt02RemoveSelectedDocuments: ['selectedDocuments'],
  stkRcpt02SetWorkspaceVisible: ['boolean'],
  stkRcpt02CreateStkRcpt02: ['hdrIds', 'toStorageBinId'],
  stkRcpt02CreateStkRcpt02Loading: ['boolean'],
  stkRcpt02CreateStkRcpt02Success: ['newDocuments']
});

export const StkRcpt02Types = Types;
export default Creators;
