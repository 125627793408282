import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { PlayCircleOutlined } from '@ant-design/icons';

import { Row, Col, Typography } from 'antd';
import { FormikDatePicker, FormikSelect, FormikButton } from '../../Components/Formik';
import SlsOrdFulfillmentChartActions from '../../Stores/SlsOrdFulfillmentChart/Actions';

const { Text } = Typography;

class CriteriaForm extends React.PureComponent {
  constructor() {
    super();

    this.handleChangeProcess = this.handleChangeProcess.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleChangeProcess(form, value) {
    const { changeProcess } = this.props;

    changeProcess(form, value);
  }

  render() {
    const { intl, criteria, chartIsLoading, resetTimestamp, processOptions } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={criteria}
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);
          try {
            const tmpCriteria = {};
            tmpCriteria.end_date = values.end_date;
            tmpCriteria.process = values.process;
            if (values.time_period.value) {
              tmpCriteria.time_period = values.time_period.value;
            } else {
              tmpCriteria.time_period = values.time_period;
            }

            if (values.process.value) {
              tmpCriteria.process = values.process.value;
            } else {
              tmpCriteria.process = values.process;
            }

            resetTimestamp(tmpCriteria);
          } finally {
            formikBag.setSubmitting(false);
          }
        }}
        validationSchema={Yup.object().shape({
          end_date: Yup.date().min(
            new Date(1969, 12, 1),
            intl.formatMessage({ id: 'end_date_is_required' })
          )
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          // dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit
          // handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'end_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="end_date"
                  component={FormikDatePicker}
                  disabled={isSubmitting || chartIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'range' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={10}>
                <Field
                  name="process"
                  component={FormikSelect}
                  showArrow
                  notFoundContent={null}
                  options={[
                    { label: 'Daily', value: 'daily' },
                    { label: 'Weekly', value: 'weekly' },
                    { label: 'Monthly', value: 'monthly' }
                  ]}
                  disabled={isSubmitting || chartIsLoading}
                  onChange={this.handleChangeProcess}
                />
              </Col>
              <Col span={10}>
                <Field
                  name="time_period"
                  component={FormikSelect}
                  showArrow
                  notFoundContent={null}
                  options={processOptions}
                  disabled={isSubmitting || chartIsLoading}
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-between" gutter={[0, 16]} style={{ height: '10px' }} />
            <Row
              type="flex"
              justify="space-between"
              gutter={[0, 16]}
              style={{ marginBottom: '10px' }}
            >
              <Col span={24}>
                <Field
                  component={FormikButton}
                  loading={isSubmitting || chartIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'run_report'
                  })}
                  icon={<PlayCircleOutlined />}
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

CriteriaForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  resetTimestamp: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  criteria: PropTypes.object,
  chartIsLoading: PropTypes.bool,

  changeProcess: PropTypes.func,
  processOptions: PropTypes.arrayOf(PropTypes.object)
};

CriteriaForm.defaultProps = {
  intl: {},

  resetTimestamp() {},
  criteria: {},
  chartIsLoading: false,

  changeProcess() {},
  processOptions: []
};

const mapStateToProps = state => ({
  criteria: state.slsOrdFulfillmentChart.criteria,
  chartIsLoading: state.slsOrdFulfillmentChart.slsOrdFulfillmentDataIsLoading,

  processOptions: state.slsOrdFulfillmentChart.processOptions
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: criteria =>
    dispatch(SlsOrdFulfillmentChartActions.slsOrdFulfillmentChartResetTimestamp(criteria)),

  changeProcess: (formikBag, process) =>
    dispatch(SlsOrdFulfillmentChartActions.slsOrdFulfillmentChartChangeProcess(formikBag, process))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CriteriaForm));
