/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CountAdjFServ01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const countAdjFServ01ShowSyncSettingLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const countAdjFServ01ShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const countAdjFServ01ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const countAdjFServ01ExportLoading = (state, { boolean }) => ({
  ...state,
  exportIsLoading: boolean
});

export const countAdjFServ01ResetTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const countAdjFServ01FetchCountAdjFServ01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const countAdjFServ01FetchCountAdjFServ01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const countAdjFServ01AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const countAdjFServ01RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const countAdjFServ01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const countAdjFServ01ExportSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CountAdjFServ01Types.COUNT_ADJ_F_SERV01_SHOW_SYNC_SETTING_LOADING]: countAdjFServ01ShowSyncSettingLoading,
  [CountAdjFServ01Types.COUNT_ADJ_F_SERV01_SHOW_SYNC_SETTING_SUCCESS]: countAdjFServ01ShowSyncSettingSuccess,
  [CountAdjFServ01Types.COUNT_ADJ_F_SERV01_SHOW_BATCH_JOB_STATUS_SUCCESS]: countAdjFServ01ShowBatchJobStatusSuccess,

  [CountAdjFServ01Types.COUNT_ADJ_F_SERV01_RESET_TIMESTAMP]: countAdjFServ01ResetTimestamp,
  [CountAdjFServ01Types.COUNT_ADJ_F_SERV01_FETCH_COUNT_ADJ_F_SERV01_LOADING]: countAdjFServ01FetchCountAdjFServ01Loading,
  [CountAdjFServ01Types.COUNT_ADJ_F_SERV01_FETCH_COUNT_ADJ_F_SERV01_SUCCESS]: countAdjFServ01FetchCountAdjFServ01Success,
  [CountAdjFServ01Types.COUNT_ADJ_F_SERV01_ADD_SELECTED_DOCUMENTS]: countAdjFServ01AddSelectedDocuments,
  [CountAdjFServ01Types.COUNT_ADJ_F_SERV01_REMOVE_SELECTED_DOCUMENTS]: countAdjFServ01RemoveSelectedDocuments,
  [CountAdjFServ01Types.COUNT_ADJ_F_SERV01_SET_WORKSPACE_VISIBLE]: countAdjFServ01SetWorkspaceVisible,
  [CountAdjFServ01Types.COUNT_ADJ_F_SERV01_EXPORT_LOADING]: countAdjFServ01ExportLoading,
  [CountAdjFServ01Types.COUNT_ADJ_F_SERV01_EXPORT_SUCCESS]: countAdjFServ01ExportSuccess
});
