import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcpt0101ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcpt0101GoToDocument: ['hdrId'],
  gdsRcpt0101FetchGdsRcpt0101: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcpt0101FetchGdsRcpt0101Loading: ['boolean'],
  gdsRcpt0101FetchGdsRcpt0101Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  gdsRcpt0101AddSelectedDocuments: ['selectedDocuments'],
  gdsRcpt0101RemoveSelectedDocuments: ['selectedDocuments'],
  gdsRcpt0101SetWorkspaceVisible: ['boolean'],
  gdsRcpt0101PrintGdsRcpt0101: ['hdrIds'],
  gdsRcpt0101PrintGdsRcpt0101Loading: ['boolean'],
  gdsRcpt0101PrintHandlingUnit: ['hdrIds']
});

export const GdsRcpt0101Types = Types;
export default Creators;
