/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { InvDoc0101Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const invDoc0101ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const invDoc0101FetchInvDoc0101Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const invDoc0101FetchInvDoc0101Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const invDoc0101AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ].slice(0, 5)
  };
};

export const invDoc0101RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const invDoc0101SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const invDoc0101PrintInvDoc0101Loading = (state, { boolean }) => ({
  ...state,
  printIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [InvDoc0101Types.INV_DOC0101_RESET_TIMESTAMP]: invDoc0101ResetTimestamp,
  [InvDoc0101Types.INV_DOC0101_FETCH_INV_DOC0101_LOADING]: invDoc0101FetchInvDoc0101Loading,
  [InvDoc0101Types.INV_DOC0101_FETCH_INV_DOC0101_SUCCESS]: invDoc0101FetchInvDoc0101Success,
  [InvDoc0101Types.INV_DOC0101_ADD_SELECTED_DOCUMENTS]: invDoc0101AddSelectedDocuments,
  [InvDoc0101Types.INV_DOC0101_REMOVE_SELECTED_DOCUMENTS]: invDoc0101RemoveSelectedDocuments,
  [InvDoc0101Types.INV_DOC0101_SET_WORKSPACE_VISIBLE]: invDoc0101SetWorkspaceVisible,
  [InvDoc0101Types.INV_DOC0101_PRINT_INV_DOC0101_LOADING]: invDoc0101PrintInvDoc0101Loading
});
