import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import { Link } from 'react-router-dom';

import { Col, Row, Statistic, Spin } from 'antd';
import { Card } from 'react-bootstrap';

import SummaryBoardActions from '../../Stores/SummaryBoard/Actions';
import './SummaryBoardStyle.css';

class LspSlsOrdSummaryCard extends React.PureComponent {
  componentDidMount() {
    const {
      resetTimestampSlsOrds,
      resetTimestampOutbOrds,
      resetTimestampDOs,
      resetTimestampNoPrfDels
    } = this.props;

    const { curSiteFlowId } = this.props;

    if (curSiteFlowId > 0) {
      resetTimestampSlsOrds();
      resetTimestampOutbOrds();
      resetTimestampDOs();
      resetTimestampNoPrfDels();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      fetchSummarySlsOrds,
      fetchSummaryOutbOrds,
      fetchSummaryDOs,
      fetchSummaryNoPrfDels
    } = this.props;

    const {
      resetTimestampSlsOrds,
      resetTimestampOutbOrds,
      resetTimestampDOs,
      resetTimestampNoPrfDels
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestampSlsOrds();
      resetTimestampOutbOrds();
      resetTimestampDOs();
      resetTimestampNoPrfDels();
    }

    if (changed('summarySlsOrdsTimestamp')) {
      if (curSiteFlowId > 0) {
        fetchSummarySlsOrds();
      }
    }

    if (changed('summaryOutbOrdsTimestamp')) {
      if (curSiteFlowId > 0) {
        fetchSummaryOutbOrds();
      }
    }

    if (changed('summaryDOsTimestamp')) {
      if (curSiteFlowId > 0) {
        fetchSummaryDOs();
      }
    }

    if (changed('summaryNoPrfDelsTimestamp')) {
      if (curSiteFlowId > 0) {
        fetchSummaryNoPrfDels();
      }
    }
  }

  render() {
    const { summarySlsOrds, summaryOutbOrds, summaryDOs, summaryNoPrfDels } = this.props;

    const { intl } = this.props;

    const {
      summarySlsOrdsIsLoading,
      summaryOutbOrdsIsLoading,
      summaryDOsIsLoading,
      summaryNoPrfDelsIsLoading
    } = this.props;

    return (
      <Link to="/lspSlsOrdDetailBoard" style={{ textDecoration: 'none' }}>
        <Card className="summary-card" text="dark">
          <Card.Header className="lsp-sls-ord-header">
            {intl.formatMessage({ id: 'logistic_services_sales_order_summary' })}
          </Card.Header>
          <Row>
            <Col span={12} style={summarySlsOrdsIsLoading ? { paddingTop: '10%' } : {}}>
              <Card.Body>
                {summarySlsOrdsIsLoading ? (
                  <Spin />
                ) : (
                  <div>
                    <Statistic
                      title={intl.formatMessage({ id: 'sales_orders' })}
                      value={summarySlsOrds.total}
                    />
                    <div style={{ color: 'red' }}>{summarySlsOrds.date_oldest}</div>
                  </div>
                )}
              </Card.Body>
            </Col>
            <Col span={12} style={summaryOutbOrdsIsLoading ? { paddingTop: '10%' } : {}}>
              <Card.Body>
                {summaryOutbOrdsIsLoading ? (
                  <Spin />
                ) : (
                  <div>
                    <Statistic
                      title={intl.formatMessage({ id: 'outbound_orders' })}
                      value={summaryOutbOrds.total}
                    />
                    <div style={{ color: 'red' }}>{summaryOutbOrds.date_oldest}</div>
                  </div>
                )}
              </Card.Body>
            </Col>
            <Col span={12} style={summaryDOsIsLoading ? { paddingBottom: '10%' } : {}}>
              <Card.Body>
                {summaryDOsIsLoading ? (
                  <Spin />
                ) : (
                  <div>
                    <Statistic
                      title={intl.formatMessage({ id: 'delivery_orders' })}
                      value={summaryDOs.total}
                    />
                    <div style={{ color: 'red' }}>{summaryDOs.date_oldest}</div>
                  </div>
                )}
              </Card.Body>
            </Col>
            <Col span={12} style={summaryNoPrfDelsIsLoading ? { paddingBottom: '10%' } : {}}>
              <Card.Body>
                {summaryNoPrfDelsIsLoading ? (
                  <Spin />
                ) : (
                  <div>
                    <Statistic
                      title={intl.formatMessage({ id: 'deliveries' })}
                      value={summaryNoPrfDels.total}
                    />
                    <div style={{ color: 'red' }}>{summaryNoPrfDels.date_oldest}</div>
                  </div>
                )}
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </Link>
    );
  }
}

LspSlsOrdSummaryCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  // eslint-disable-next-line react/forbid-prop-types
  summarySlsOrds: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  summaryOutbOrds: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  summaryDOs: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  summaryNoPrfDels: PropTypes.object,

  summarySlsOrdsIsLoading: PropTypes.bool,
  summaryOutbOrdsIsLoading: PropTypes.bool,
  summaryDOsIsLoading: PropTypes.bool,
  summaryNoPrfDelsIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchSummarySlsOrds: PropTypes.func,
  fetchSummaryOutbOrds: PropTypes.func,
  fetchSummaryDOs: PropTypes.func,
  fetchSummaryNoPrfDels: PropTypes.func,

  resetTimestampSlsOrds: PropTypes.func,
  resetTimestampOutbOrds: PropTypes.func,
  resetTimestampDOs: PropTypes.func,
  resetTimestampNoPrfDels: PropTypes.func
};

LspSlsOrdSummaryCard.defaultProps = {
  intl: {},

  summarySlsOrds: { total: 0, date_oldest: '0 days' },
  summaryOutbOrds: { total: 0, date_oldest: '0 days' },
  summaryDOs: { total: 0, date_oldest: '0 days' },
  summaryNoPrfDels: { total: 0, date_oldest: '0 days' },

  summarySlsOrdsIsLoading: false,
  summaryOutbOrdsIsLoading: false,
  summaryDOsIsLoading: false,
  summaryNoPrfDelsIsLoading: false,

  curSiteFlowId: 0,
  fetchSummarySlsOrds() {},
  fetchSummaryOutbOrds() {},
  fetchSummaryDOs() {},
  fetchSummaryNoPrfDels() {},

  resetTimestampSlsOrds() {},
  resetTimestampOutbOrds() {},
  resetTimestampDOs() {},
  resetTimestampNoPrfDels() {}
};

const mapStateToProps = state => ({
  summarySlsOrdsTimestamp: state.summaryBoard.lspSlsOrdSummarySlsOrdsTimestamp,
  summarySlsOrds: state.summaryBoard.lspSlsOrdSummarySlsOrds,

  summaryOutbOrdsTimestamp: state.summaryBoard.lspSlsOrdSummaryOutbOrdsTimestamp,
  summaryOutbOrds: state.summaryBoard.lspSlsOrdSummaryOutbOrds,

  summaryDOsTimestamp: state.summaryBoard.lspSlsOrdSummaryInvDocsTimestamp,
  summaryDOs: state.summaryBoard.lspSlsOrdSummaryInvDocs,

  summaryNoPrfDelsTimestamp: state.summaryBoard.lspSlsOrdSummaryNoPrfDelsTimestamp,
  summaryNoPrfDels: state.summaryBoard.lspSlsOrdSummaryNoPrfDels,

  summarySlsOrdsIsLoading: state.summaryBoard.lspSlsOrdSummarySlsOrdsIsLoading,
  summaryOutbOrdsIsLoading: state.summaryBoard.lspSlsOrdSummaryOutbOrdsIsLoading,
  summaryDOsIsLoading: state.summaryBoard.lspSlsOrdSummaryInvDocsIsLoading,
  summaryNoPrfDelsIsLoading: state.summaryBoard.lspSlsOrdSummaryNoPrfDelsIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestampSlsOrds: () =>
    dispatch(SummaryBoardActions.lspSlsOrdSummaryBoardResetSlsOrdsTimestamp()),

  fetchSummarySlsOrds: () => dispatch(SummaryBoardActions.lspSlsOrdSummaryBoardFetchSlsOrds()),

  resetTimestampOutbOrds: () =>
    dispatch(SummaryBoardActions.lspSlsOrdSummaryBoardResetOutbOrdsTimestamp()),

  fetchSummaryOutbOrds: () => dispatch(SummaryBoardActions.lspSlsOrdSummaryBoardFetchOutbOrds()),

  resetTimestampDOs: () =>
    dispatch(SummaryBoardActions.lspSlsOrdSummaryBoardResetInvDocsTimestamp()),

  fetchSummaryDOs: () => dispatch(SummaryBoardActions.lspSlsOrdSummaryBoardFetchInvDocs()),

  resetTimestampNoPrfDels: () =>
    dispatch(SummaryBoardActions.lspSlsOrdSummaryBoardResetNoPrfDelsTimestamp()),

  fetchSummaryNoPrfDels: () => dispatch(SummaryBoardActions.lspSlsOrdSummaryBoardFetchNoPrfDels())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LspSlsOrdSummaryCard));
