/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { GdsRcptDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const gdsRcptDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const gdsRcptDetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false
});

export const gdsRcptDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const gdsRcptDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const gdsRcptDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const gdsRcptDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const gdsRcptDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const gdsRcptDetailSetWhseJobType = (state, { whseJobType }) => ({
  ...state,
  whseJobType
});

export const gdsRcptDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  whseJobType: documentDetail.whse_job_type,
  companyOptions: [],
  frStorageBinOptions: [],
  quantBalOptions: [],
  itemBatchOptions: [],
  uomOptions: [],
  toStorageBinOptions: []
});

export const gdsRcptDetailFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const gdsRcptDetailFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const gdsRcptDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const gdsRcptDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const gdsRcptDetailFetchItemBatchOptionLoading = (state, { boolean }) => ({
  ...state,
  itemBatchIsLoading: boolean
});

export const gdsRcptDetailFetchItemBatchOptionSuccess = (state, { options }) => ({
  ...state,
  itemBatchOptions: options
});

export const gdsRcptDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const gdsRcptDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

export const gdsRcptDetailFetchItemCond01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemCond01IsLoading: boolean
});

export const gdsRcptDetailFetchItemCond01OptionSuccess = (state, { options }) => ({
  ...state,
  itemCond01Options: options
});

export const gdsRcptDetailFetchToStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  toStorageBinIsLoading: boolean
});

export const gdsRcptDetailFetchToStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  toStorageBinOptions: options
});

export const gdsRcptDetailFetchToHandlingUnitOptionLoading = (state, { boolean }) => ({
  ...state,
  toHandlingUnitIsLoading: boolean
});

export const gdsRcptDetailFetchToHandlingUnitOptionSuccess = (state, { options }) => ({
  ...state,
  toHandlingUnitOptions: options
});

// force refresh the formik form
export const gdsRcptDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const gdsRcptDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const gdsRcptDetailSetDetailSummaryVisible = (state, { boolean }) => ({
  ...state,
  detailSummaryIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_RESET_TIMESTAMP]: gdsRcptDetailResetTimestamp,
  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_SET_HDR_ID]: gdsRcptDetailSetHdrId,

  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_SHOW_HEADER_SUCCESS]: gdsRcptDetailShowHeaderSuccess,

  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_UPDATE_DOCUMENT_SUCCESS]: gdsRcptDetailUpdateDocumentSuccess,

  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_SHOW_DOCUMENT_LOADING]: gdsRcptDetailShowDocumentLoading,

  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_SHOW_DETAILS_SUCCESS]: gdsRcptDetailShowDetailsSuccess,

  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_SET_DETAIL_VISIBLE]: gdsRcptDetailSetDetailVisible,
  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_SET_DOCUMENT_DETAIL]: gdsRcptDetailSetDocumentDetail,

  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_UPDATE_DETAILS]: gdsRcptDetailUpdateDetails,
  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_CREATE_DETAIL]: gdsRcptDetailCreateDetail,

  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_SET_WHSE_JOB_TYPE]: gdsRcptDetailSetWhseJobType,

  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_COMPANY_OPTION_LOADING]: gdsRcptDetailFetchCompanyOptionLoading,
  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_COMPANY_OPTION_SUCCESS]: gdsRcptDetailFetchCompanyOptionSuccess,

  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_ITEM_OPTION_LOADING]: gdsRcptDetailFetchItemOptionLoading,
  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: gdsRcptDetailFetchItemOptionSuccess,

  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_ITEM_BATCH_OPTION_LOADING]: gdsRcptDetailFetchItemBatchOptionLoading,
  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_ITEM_BATCH_OPTION_SUCCESS]: gdsRcptDetailFetchItemBatchOptionSuccess,

  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_UOM_OPTION_LOADING]: gdsRcptDetailFetchUomOptionLoading,
  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_UOM_OPTION_SUCCESS]: gdsRcptDetailFetchUomOptionSuccess,

  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_ITEM_COND01_OPTION_LOADING]: gdsRcptDetailFetchItemCond01OptionLoading,
  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_ITEM_COND01_OPTION_SUCCESS]: gdsRcptDetailFetchItemCond01OptionSuccess,

  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_LOADING]: gdsRcptDetailFetchToStorageBinOptionLoading,
  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_SUCCESS]: gdsRcptDetailFetchToStorageBinOptionSuccess,

  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_TO_HANDLING_UNIT_OPTION_LOADING]: gdsRcptDetailFetchToHandlingUnitOptionLoading,
  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_TO_HANDLING_UNIT_OPTION_SUCCESS]: gdsRcptDetailFetchToHandlingUnitOptionSuccess,

  [GdsRcptDetailTypes.GDS_RCPT_DETAIL_SET_DETAIL_SUMMARY_VISIBLE]: gdsRcptDetailSetDetailSummaryVisible
});
