/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhseJob1401IndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const whseJob1401IndexResetTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  fetchIsLoading: false
});

export const whseJob1401IndexFetchWhseJob1401IndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const whseJob1401IndexFetchWhseJob1401IndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const whseJob1401IndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ].slice(0, 5)
  };
};

export const whseJob1401IndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const whseJob1401IndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhseJob1401IndexTypes.WHSE_JOB1401_INDEX_RESET_TIMESTAMP]: whseJob1401IndexResetTimestamp,
  [WhseJob1401IndexTypes.WHSE_JOB1401_INDEX_FETCH_WHSE_JOB1401_INDEX_LOADING]: whseJob1401IndexFetchWhseJob1401IndexLoading,
  [WhseJob1401IndexTypes.WHSE_JOB1401_INDEX_FETCH_WHSE_JOB1401_INDEX_SUCCESS]: whseJob1401IndexFetchWhseJob1401IndexSuccess,
  [WhseJob1401IndexTypes.WHSE_JOB1401_INDEX_ADD_SELECTED_DOCUMENTS]: whseJob1401IndexAddSelectedDocuments,
  [WhseJob1401IndexTypes.WHSE_JOB1401_INDEX_REMOVE_SELECTED_DOCUMENTS]: whseJob1401IndexRemoveSelectedDocuments,
  [WhseJob1401IndexTypes.WHSE_JOB1401_INDEX_SET_WORKSPACE_VISIBLE]: whseJob1401IndexSetWorkspaceVisible
});
