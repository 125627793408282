/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { ProdSheet01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const prodSheet01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
});

export const prodSheet01FetchProdSheet01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const prodSheet01FetchProdSheet01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const prodSheet01AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const prodSheet01RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const prodSheet01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const prodSheet01CreateProdSheet01Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const prodSheet01CreateProdSheet01Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ProdSheet01Types.PROD_SHEET01_RESET_TIMESTAMP]: prodSheet01ResetTimestamp,
  [ProdSheet01Types.PROD_SHEET01_FETCH_PROD_SHEET01_LOADING]: prodSheet01FetchProdSheet01Loading,
  [ProdSheet01Types.PROD_SHEET01_FETCH_PROD_SHEET01_SUCCESS]: prodSheet01FetchProdSheet01Success,
  [ProdSheet01Types.PROD_SHEET01_ADD_SELECTED_DOCUMENTS]: prodSheet01AddSelectedDocuments,
  [ProdSheet01Types.PROD_SHEET01_REMOVE_SELECTED_DOCUMENTS]: prodSheet01RemoveSelectedDocuments,
  [ProdSheet01Types.PROD_SHEET01_SET_WORKSPACE_VISIBLE]: prodSheet01SetWorkspaceVisible,
  [ProdSheet01Types.PROD_SHEET01_CREATE_PROD_SHEET01_LOADING]: prodSheet01CreateProdSheet01Loading,
  [ProdSheet01Types.PROD_SHEET01_CREATE_PROD_SHEET01_SUCCESS]: prodSheet01CreateProdSheet01Success
});
