/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  hdrId: 0,
  itemId: 0,
  documentIsLoading: false,
  documentDetails: [],
  detailIsVisible: false,

  documentHeader: {
    doc_status: 3,
    str_doc_status: 'DRAFT',
    doc_code: '',
    ref_code_01: '',
    ref_code_02: '',
    doc_date: '1970-01-01',
    est_del_date: '1970-01-01',
    desc_01: '',
    desc_02: '',
    division_id: 0,
    division_code: '',
    site_flow_id: 0,
    company_id: 0,
    company_code: '',
    debtor_select2: {
      value: 0,
      label: ''
    },
    purchaser_select2: {
      value: 0,
      label: ''
    },
    doc_no_id: 0,
    currency_select2: {
      value: 0,
      label: ''
    },
    currency_id: 0,
    currency_rate: 1,
    credit_term_select2: {
      value: 0,
      label: ''
    },
    credit_term_id: 0,
    biz_partner_select2: {
      value: 0,
      label: ''
    },
    biz_partner_unit_no: '',
    biz_partner_building_name: '',
    biz_partner_street_name: '',
    biz_partner_district_01: '',
    biz_partner_district_02: '',
    biz_partner_postcode: '',
    biz_partner_state_name: '',
    biz_partner_country_name: '',
    hdr_disc_val_01: 0,
    hdr_disc_perc_01: 0,
    hdr_disc_val_02: 0,
    hdr_disc_perc_02: 0,
    hdr_disc_val_03: 0,
    hdr_disc_perc_03: 0,
    hdr_disc_val_04: 0,
    hdr_disc_perc_04: 0,
    hdr_disc_val_05: 0,
    hdr_disc_perc_05: 0,
    disc_amt: 0,
    tax_amt: 0,
    round_adj_amt: 0,
    net_amt: 0,
    doc_flows: []
  },
  documentDetail: {
    item_id: 0,
    desc_01: '',
    desc_02: '',
    uom_id: 0,
    uom_rate: 1,
    sale_price: 0,
    price_disc: 0,
    qty: 0,
    dtl_disc_val_01: 0,
    dtl_disc_perc_01: 0,
    dtl_disc_val_02: 0,
    dtl_disc_perc_02: 0,
    dtl_disc_val_03: 0,
    dtl_disc_perc_03: 0,
    dtl_disc_val_04: 0,
    dtl_disc_perc_04: 0,
    dtl_disc_val_05: 0,
    dtl_disc_perc_05: 0,
    dtl_tax_incl_01: 0,
    dtl_tax_val_01: 0,
    dtl_tax_perc_01: 0,
    item_select2: {
      value: 0,
      label: ''
    },
    uom_select2: {
      value: 0,
      label: ''
    },

    location_id: 0,
    location_select2: {
      value: 0,
      label: ''
    }
  },
  initDocumentDetail: {
    item_id: 0,
    desc_01: '',
    desc_02: '',
    uom_id: 0,
    uom_rate: 1,
    sale_price: 0,
    price_disc: 0,
    qty: 0,
    dtl_disc_val_01: 0,
    dtl_disc_perc_01: 0,
    dtl_disc_val_02: 0,
    dtl_disc_perc_02: 0,
    dtl_disc_val_03: 0,
    dtl_disc_perc_03: 0,
    dtl_disc_val_04: 0,
    dtl_disc_perc_04: 0,
    dtl_disc_val_05: 0,
    dtl_disc_perc_05: 0,
    dtl_tax_incl_01: 0,
    dtl_tax_val_01: 0,
    dtl_tax_perc_01: 0,
    item_select2: {
      value: 0,
      label: ''
    },
    uom_select2: {
      value: 0,
      label: ''
    },

    location_id: 0,
    location_select2: {
      value: 0,
      label: ''
    }
  },

  debtorOptions: [],
  debtorIsLoading: false,

  purchaserOptions: [],
  purchaserIsLoading: false,

  deliveryPointOptions: [],
  deliveryPointIsLoading: false,

  creditTermOptions: [],
  creditTermIsLoading: false,

  currencyOptions: [],
  currencyIsLoading: false,

  itemOptions: [],
  itemIsLoading: false,

  uomOptions: [],
  uomIsLoading: false,

  locationOptions: [],
  locationIsLoading: false,

  projectOptions: [],
  projectIsLoading: false
};
