/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { LspSlsOrdIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const lspSlsOrdIndexResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const lspSlsOrdIndexFetchLspSlsOrdIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const lspSlsOrdIndexFetchLspSlsOrdIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const lspSlsOrdIndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const lspSlsOrdIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const lspSlsOrdIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LspSlsOrdIndexTypes.LSP_SLS_ORD_INDEX_RESET_TIMESTAMP]: lspSlsOrdIndexResetTimestamp,
  [LspSlsOrdIndexTypes.LSP_SLS_ORD_INDEX_FETCH_LSP_SLS_ORD_INDEX_LOADING]: lspSlsOrdIndexFetchLspSlsOrdIndexLoading,
  [LspSlsOrdIndexTypes.LSP_SLS_ORD_INDEX_FETCH_LSP_SLS_ORD_INDEX_SUCCESS]: lspSlsOrdIndexFetchLspSlsOrdIndexSuccess,
  [LspSlsOrdIndexTypes.LSP_SLS_ORD_INDEX_ADD_SELECTED_DOCUMENTS]: lspSlsOrdIndexAddSelectedDocuments,
  [LspSlsOrdIndexTypes.LSP_SLS_ORD_INDEX_REMOVE_SELECTED_DOCUMENTS]: lspSlsOrdIndexRemoveSelectedDocuments,
  [LspSlsOrdIndexTypes.LSP_SLS_ORD_INDEX_SET_WORKSPACE_VISIBLE]: lspSlsOrdIndexSetWorkspaceVisible
});
