import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { ArrowLeftOutlined, UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography, Radio, Card } from 'antd';
import treeChanges from 'tree-changes';
import {
  FormikInput,
  FormikRadio,
  FormikButton,
  FormikSelect,
  FormikInputNumber
} from '../../Components/Formik';
import StorageBinDetailActions from '../../Stores/StorageBinDetail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class ModelForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchStorageRow = this.handleSearchStorageRow.bind(this);
    this.handleSearchStorageBay = this.handleSearchStorageBay.bind(this);
    this.handleSearchStorageType = this.handleSearchStorageType.bind(this);
    this.handleSearchLocation = this.handleSearchLocation.bind(this);
  }

  componentDidMount() {
    const { resId, resetTimestamp } = this.props;

    if (resId > 0) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const { match, resId, resetTimestamp, initModel, showModel } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('resId')) {
      resetTimestamp();
    }
    if (changed('timestamp')) {
      if (resId <= 0) {
        initModel(match.params.id);
      } else {
        showModel(resId);
      }
    }
  }

  componentWillUnmount() {}

  handleSearchStorageRow(form, value) {
    const { fetchStorageRowOptions } = this.props;

    fetchStorageRowOptions(value);
  }

  handleSearchStorageBay(form, value) {
    const { fetchStorageBayOptions } = this.props;

    fetchStorageBayOptions(value);
  }

  handleSearchStorageType(form, value) {
    const { fetchStorageTypeOptions } = this.props;

    fetchStorageTypeOptions(value);
  }

  handleSearchLocation(form, value) {
    const { fetchLocationOptions } = this.props;

    fetchLocationOptions(value);
  }

  render() {
    const {
      intl,
      match,
      model,
      createModel,
      updateModel,
      historyGoBack,
      modelIsLoading,
      storageRowIsLoading,
      storageRowOptions,
      storageBayIsLoading,
      storageBayOptions,
      storageTypeIsLoading,
      storageTypeOptions,
      locationIsLoading,
      locationOptions
    } = this.props;

    const statusOptions = [
      <Radio.Button key={1} value={1}>
        {intl.formatMessage({ id: 'DELETED' })}
      </Radio.Button>,
      <Radio.Button key={2} value={2}>
        {intl.formatMessage({ id: 'INACTIVE' })}
      </Radio.Button>,
      <Radio.Button key={3} value={3}>
        {intl.formatMessage({ id: 'BLOCKED' })}
      </Radio.Button>,
      <Radio.Button key={4} value={4}>
        {intl.formatMessage({ id: 'CONFLICT' })}
      </Radio.Button>,
      <Radio.Button key={5} value={5}>
        {intl.formatMessage({ id: 'NEW' })}
      </Radio.Button>,
      <Radio.Button key={50} value={50}>
        {intl.formatMessage({ id: 'WIP' })}
      </Radio.Button>,
      <Radio.Button key={100} value={100}>
        {intl.formatMessage({ id: 'ACTIVE' })}
      </Radio.Button>
    ];

    const disabled = false;

    return (
      <Formik
        enableReinitialize
        initialValues={model}
        onSubmit={(values, formikBag) => {
          if (match.params.action === 'create') {
            createModel(formikBag, values);
          } else {
            updateModel(formikBag, values);
          }
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required(intl.formatMessage({ id: 'code_is_required' })),
          desc_01: Yup.string().required(intl.formatMessage({ id: 'description_is_required' })),
          level: Yup.number().moreThan(
            0,
            intl.formatMessage({ id: 'level_must_be_greater_than_0' })
          ),
          storage_row_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'storage_row_is_required' }))
          }),
          storage_bay_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'storage_bay_is_required' }))
          }),
          location_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'location_is_required' }))
          }),
          storage_type_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'storage_type_is_required' }))
          }),
          status: Yup.number().min(1, intl.formatMessage({ id: 'status_is_required' }))
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Card title={intl.formatMessage({ id: 'general' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="code"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'status' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="bin_status"
                    component={FormikRadio}
                    options={statusOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'description' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="desc_01"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3} />
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="desc_02"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'level' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="level"
                    component={FormikInputNumber}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'row' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="storage_row_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchStorageRow}
                    notFoundContent={null}
                    loading={storageRowIsLoading}
                    options={storageRowOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'bay' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="storage_bay_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchStorageBay}
                    notFoundContent={null}
                    loading={storageBayIsLoading}
                    options={storageBayOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'location' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="location_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchLocation}
                    notFoundContent={null}
                    loading={locationIsLoading}
                    options={locationOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'storage_type' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="storage_type_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchStorageType}
                    notFoundContent={null}
                    loading={storageTypeIsLoading}
                    options={storageTypeOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
            </Card>
            <Row type="flex" justify="space-between" gutter={[0, 16]}>
              <Col span={8}>
                <Button
                  type="primary"
                  // disabled={!isValid}
                  loading={isSubmitting}
                  onClick={historyGoBack}
                  icon={<ArrowLeftOutlined />}
                >
                  {intl.formatMessage({
                    id: 'back'
                  })}
                </Button>
              </Col>
              <Col span={5}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting || modelIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  name="submit_action"
                  type="primary"
                  value="update"
                  component={FormikButton}
                  // disabled={values.status >= 50}
                  loading={isSubmitting || modelIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: match.params.action === 'create' ? 'create' : 'update'
                  })}
                  icon={<SaveOutlined />}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

ModelForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  resetTimestamp: PropTypes.func,

  initModel: PropTypes.func,
  showModel: PropTypes.func,
  createModel: PropTypes.func,
  updateModel: PropTypes.func,
  historyGoBack: PropTypes.func,
  resId: PropTypes.number,
  model: PropTypes.shape({
    status: PropTypes.number
  }),
  modelIsLoading: PropTypes.bool,

  fetchStorageRowOptions: PropTypes.func,
  storageRowIsLoading: PropTypes.bool,
  storageRowOptions: PropTypes.arrayOf(PropTypes.object),

  fetchStorageBayOptions: PropTypes.func,
  storageBayIsLoading: PropTypes.bool,
  storageBayOptions: PropTypes.arrayOf(PropTypes.object),

  fetchLocationOptions: PropTypes.func,
  locationIsLoading: PropTypes.bool,
  locationOptions: PropTypes.arrayOf(PropTypes.object),

  fetchStorageTypeOptions: PropTypes.func,
  storageTypeIsLoading: PropTypes.bool,
  storageTypeOptions: PropTypes.arrayOf(PropTypes.object)
};

ModelForm.defaultProps = {
  intl: {},
  match: {},
  resetTimestamp() {},

  initModel() {},
  showModel() {},
  createModel() {},
  updateModel() {},
  historyGoBack() {},
  resId: 0,
  model: { status: 2 },
  modelIsLoading: false,

  fetchStorageRowOptions() {},
  storageRowIsLoading: false,
  storageRowOptions: [],

  fetchStorageBayOptions() {},
  storageBayIsLoading: false,
  storageBayOptions: [],

  fetchStorageTypeOptions() {},
  storageTypeIsLoading: false,
  storageTypeOptions: [],

  fetchLocationOptions() {},
  locationIsLoading: false,
  locationOptions: []
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.storageBinDetail.modelTimestamp,
  resId: state.storageBinDetail.resId,
  model: state.storageBinDetail.model,
  modelIsLoading: state.storageBinDetail.modelIsLoading,

  storageRowIsLoading: state.storageBinDetail.storageRowIsLoading,
  storageRowOptions: state.storageBinDetail.storageRowOptions,

  storageBayIsLoading: state.storageBinDetail.storageBayIsLoading,
  storageBayOptions: state.storageBinDetail.storageBayOptions,

  storageTypeIsLoading: state.storageBinDetail.storageTypeIsLoading,
  storageTypeOptions: state.storageBinDetail.storageTypeOptions,

  locationIsLoading: state.storageBinDetail.locationIsLoading,
  locationOptions: state.storageBinDetail.locationOptions
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: () => dispatch(StorageBinDetailActions.storageBinDetailResetModelTimestamp()),
  initModel: storageBinId =>
    dispatch(StorageBinDetailActions.storageBinDetailInitModel(storageBinId)),
  showModel: resId => dispatch(StorageBinDetailActions.storageBinDetailShowModel(resId)),

  updateModel: (formikBag, model) =>
    dispatch(StorageBinDetailActions.storageBinDetailUpdateModel(formikBag, model)),
  createModel: (formikBag, model) =>
    dispatch(StorageBinDetailActions.storageBinDetailCreateModel(formikBag, model)),

  fetchStorageRowOptions: search =>
    dispatch(StorageBinDetailActions.storageBinDetailFetchStorageRowOptions(search)),
  fetchStorageBayOptions: search =>
    dispatch(StorageBinDetailActions.storageBinDetailFetchStorageBayOptions(search)),
  fetchStorageTypeOptions: search =>
    dispatch(StorageBinDetailActions.storageBinDetailFetchStorageTypeOptions(search)),
  fetchLocationOptions: search =>
    dispatch(StorageBinDetailActions.storageBinDetailFetchLocationOptions(search)),

  historyGoBack: () => dispatch(AppActions.appHistoryGoBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModelForm));
