/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { AdvShipFServ02Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const advShipFServ02ShowSyncSettingLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const advShipFServ02ShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const advShipFServ02ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const advShipFServ02ExportLoading = (state, { boolean }) => ({
  ...state,
  exportIsLoading: boolean
});

export const advShipFServ02ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const advShipFServ02FetchAdvShipFServ02Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const advShipFServ02FetchAdvShipFServ02Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const advShipFServ02AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const advShipFServ02RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const advShipFServ02SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const advShipFServ02ExportSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [AdvShipFServ02Types.ADV_SHIP_F_SERV02_SHOW_SYNC_SETTING_LOADING]: advShipFServ02ShowSyncSettingLoading,
  [AdvShipFServ02Types.ADV_SHIP_F_SERV02_SHOW_SYNC_SETTING_SUCCESS]: advShipFServ02ShowSyncSettingSuccess,
  [AdvShipFServ02Types.ADV_SHIP_F_SERV02_SHOW_BATCH_JOB_STATUS_SUCCESS]: advShipFServ02ShowBatchJobStatusSuccess,

  [AdvShipFServ02Types.ADV_SHIP_F_SERV02_RESET_TIMESTAMP]: advShipFServ02ResetTimestamp,
  [AdvShipFServ02Types.ADV_SHIP_F_SERV02_FETCH_ADV_SHIP_F_SERV02_LOADING]: advShipFServ02FetchAdvShipFServ02Loading,
  [AdvShipFServ02Types.ADV_SHIP_F_SERV02_FETCH_ADV_SHIP_F_SERV02_SUCCESS]: advShipFServ02FetchAdvShipFServ02Success,
  [AdvShipFServ02Types.ADV_SHIP_F_SERV02_ADD_SELECTED_DOCUMENTS]: advShipFServ02AddSelectedDocuments,
  [AdvShipFServ02Types.ADV_SHIP_F_SERV02_REMOVE_SELECTED_DOCUMENTS]: advShipFServ02RemoveSelectedDocuments,
  [AdvShipFServ02Types.ADV_SHIP_F_SERV02_SET_WORKSPACE_VISIBLE]: advShipFServ02SetWorkspaceVisible,
  [AdvShipFServ02Types.ADV_SHIP_F_SERV02_EXPORT_LOADING]: advShipFServ02ExportLoading,
  [AdvShipFServ02Types.ADV_SHIP_F_SERV02_EXPORT_SUCCESS]: advShipFServ02ExportSuccess
});
