import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  cycleCount02DetailResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  cycleCount02DetailSetHdrId: ['hdrId'],

  cycleCount02DetailShowDetails: ['hdrId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  cycleCount02DetailShowDocumentLoading: ['boolean'],
  cycleCount02DetailShowDetailsSuccess: [
    'documentDetails',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  cycleCount02DetailAutoConfirm: ['hdrId'],
  cycleCount02DetailAutoGroupConfirm: ['hdrId'],

  cycleCount02DetailUpdateRecount: ['dtlId', 'action'],
  cycleCount02DetailCreateCycleCount02: ['groupCount', 'hdrId'],
  cycleCount02DetailCreateCycleCount02Success: ['newDocuments'],

  cycleCount02DetailSetWorkspaceVisible: ['boolean'],

  cycleCount02DetailSetExpandedRows: ['expandedRows'],

  cycleCount02DetailDownload: ['hdrId'],

  cycleCount02DetailExpandAllRows: null,
  cycleCount02DetailCollapseAllRows: null
});

export const CycleCount02DetailTypes = Types;
export default Creators;
