import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  storageRowDetailResetModelTimestamp: null,
  storageRowDetailSetResId: ['resId'],

  storageRowDetailInitModel: ['storageRowType'],
  storageRowDetailShowModel: ['resId'],
  storageRowDetailShowModelSuccess: ['model'],

  storageRowDetailUpdateModel: ['formikBag', 'model'],
  storageRowDetailCreateModel: ['formikBag', 'model'],
  storageRowDetailUpdateModelSuccess: ['model'],

  storageRowDetailShowModelLoading: ['boolean']
});

export const StorageRowDetailTypes = Types;
export default Creators;
