import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { UndoOutlined, PlayCircleOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography } from 'antd';
import {
  FormikTransfer,
  FormikDatePicker,
  FormikSelect,
  FormikButton
} from '../../Components/Formik';
import WorkerGdsRcptAnalysisReportActions from '../../Stores/WorkerGdsRcptAnalysisReport/Actions';

const { Text } = Typography;

class CriteriaForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchUser = this.handleSearchUser.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleSearchUser(form, value) {
    const { fetchUserOptions } = this.props;

    fetchUserOptions(value);
  }

  render() {
    const {
      intl,
      criteria,
      reportIsLoading,
      workerGdsRcptAnalysis,
      userOptions,
      userIsLoading
    } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={criteria}
        onSubmit={(values, formikBag) => {
          workerGdsRcptAnalysis(formikBag, values);
        }}
        validationSchema={Yup.object().shape({
          start_date: Yup.date().min(
            new Date(1969, 12, 1),
            intl.formatMessage({ id: 'start_date_is_required' })
          ),
          end_date: Yup.date().min(
            new Date(1969, 12, 1),
            intl.formatMessage({ id: 'end_date_is_required' })
          )
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'whse_job_start_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="start_date"
                  component={FormikDatePicker}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'whse_job_end_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="end_date"
                  component={FormikDatePicker}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'user' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="user_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchUser}
                  notFoundContent={null}
                  loading={userIsLoading}
                  options={userOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={24}>
                <Field
                  name="columns"
                  component={FormikTransfer}
                  intl={intl}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="space-between" gutter={[0, 16]}>
              <Col span={24}>
                <Button
                  disabled={!dirty}
                  loading={isSubmitting || reportIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                  type="primary"
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  name="submit_action"
                  value="run_report"
                  component={FormikButton}
                  loading={isSubmitting || reportIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'run_report'
                  })}
                  icon={<PlayCircleOutlined />}
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

CriteriaForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  // eslint-disable-next-line react/forbid-prop-types
  criteria: PropTypes.object,
  reportIsLoading: PropTypes.bool,
  workerGdsRcptAnalysis: PropTypes.func,
  fetchUserOptions: PropTypes.func,

  userOptions: PropTypes.arrayOf(PropTypes.object),
  userIsLoading: PropTypes.bool
};

CriteriaForm.defaultProps = {
  intl: {},

  criteria: {},
  reportIsLoading: false,
  workerGdsRcptAnalysis() {},
  fetchUserOptions() {},

  userOptions: [],
  userIsLoading: false
};

const mapStateToProps = state => ({
  timestamp: state.workerGdsRcptAnalysisReport.timestamp,
  criteria: state.workerGdsRcptAnalysisReport.criteria,

  reportIsLoading: state.workerGdsRcptAnalysisReport.reportIsLoading,

  userOptions: state.workerGdsRcptAnalysisReport.userOptions,
  userIsLoading: state.workerGdsRcptAnalysisReport.userIsLoading
});

const mapDispatchToProps = dispatch => ({
  workerGdsRcptAnalysis: (formikBag, criteria) =>
    dispatch(
      WorkerGdsRcptAnalysisReportActions.workerGdsRcptAnalysisReportWorkerGdsRcptAnalysis(
        formikBag,
        criteria
      )
    ),

  fetchUserOptions: search =>
    dispatch(WorkerGdsRcptAnalysisReportActions.workerGdsRcptAnalysisReportFetchUserOptions(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CriteriaForm));
