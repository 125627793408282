import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob17IndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob17IndexGoToWhseJobDocument: ['hdrId'],
  whseJob17IndexGoToAudit: ['hdrId'],
  whseJob17IndexFetchWhseJob17Index: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob17IndexFetchWhseJob17IndexLoading: ['boolean'],
  whseJob17IndexFetchWhseJob17IndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  whseJob17IndexAddSelectedDocuments: ['selectedDocuments'],
  whseJob17IndexRemoveSelectedDocuments: ['selectedDocuments'],
  whseJob17IndexSetWorkspaceVisible: ['boolean']
});

export const WhseJob17IndexTypes = Types;
export default Creators;
