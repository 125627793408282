import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { DeleteOutlined } from '@ant-design/icons';

import { Row, Col, Modal, Table, Button, Popconfirm } from 'antd';

import StkRcptSync01Actions from '../../Stores/StkRcptSync01/Actions';

class StkRcptSync01Dialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnSyncNow = this.useOnSyncNow.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  getSelectedDocumentColumns() {
    const { intl, removeSelectedDocuments } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
            <br />
            {record.str_doc_status}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'desc_01' }),
        // sort field
        dataIndex: 'desc_01',
        sorter: (a, b) => `${a.desc_01}`.localeCompare(b.desc_01),
        // filter field
        key: 'desc_01',
        render: (text, record) => (
          <>
            <b>{record.desc_01}</b>
            <br />
            {record.desc_01}
          </>
        )
      },
      {
        // width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'net_amt' }),
        // sort field
        dataIndex: 'net_amt',
        // filter field
        key: 'net_amt',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.net_amt)}
          </>
        )
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedDocuments([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnSyncNow() {
    const { selectedDocuments, syncNow } = this.props;

    const hdrIds = selectedDocuments.map(value => {
      return value.id;
    });

    syncNow(hdrIds);
  }

  render() {
    const { intl, selectedDocuments, workspaceIsVisible, syncIsLoading } = this.props;

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'export_stock_receipt' })}
          // style={{top:20}}
          width="80%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              key="create"
              disabled={selectedDocuments.length === 0}
              loading={syncIsLoading}
              onClick={this.useOnSyncNow}
            >
              {intl.formatMessage({ id: 'export' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocuments}
                loading={syncIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_documents' })}</b>}
                summary={pageData => {
                  // summarise the table
                  const calcRow = pageData.reduce(
                    (ttlObj, object) => {
                      return {
                        net_amt:
                          ('net_amt' in ttlObj ? ttlObj.net_amt : 0) + parseFloat(object.net_amt)
                      };
                    },
                    { net_amt: 0 }
                  );

                  return (
                    <>
                      <tr className="ant-table-row">
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={3}>
                          {intl.formatMessage({ id: 'total' })}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.net_amt)}
                        </td>
                        <td />
                      </tr>
                    </>
                  );
                }}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

StkRcptSync01Dialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedDocuments: PropTypes.func,
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  syncIsLoading: PropTypes.bool,

  syncNow: PropTypes.func
};

StkRcptSync01Dialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedDocuments() {},
  selectedDocuments: [],

  syncIsLoading: false,

  syncNow() {}
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.stkRcptSync01.workspaceIsVisible,

  selectedDocuments: state.stkRcptSync01.selectedDocuments,

  syncIsLoading: state.stkRcptSync01.syncIsLoading
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean =>
    dispatch(StkRcptSync01Actions.stkRcptSync01SetWorkspaceVisible(boolean)),

  removeSelectedDocuments: selectedDocuments =>
    dispatch(StkRcptSync01Actions.stkRcptSync01RemoveSelectedDocuments(selectedDocuments)),

  syncNow: hdrIds => dispatch(StkRcptSync01Actions.stkRcptSync01SyncNow(hdrIds))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(StkRcptSync01Dialog));
