import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';

import { Card } from 'antd';
import ModelForm from './ModelForm';
import StorageBayDetailActions from '../../Stores/StorageBayDetail/Actions';

class StorageBayDetailScreen extends React.PureComponent {
  componentDidMount() {
    const { match, setReslId } = this.props;

    if (match.params.action === 'create') {
      setReslId(0);
    } else if (match.params.action === 'update') {
      setReslId(parseInt(match.params.id, 10));
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const { intl, match, model } = this.props;

    return (
      <Card title={`${intl.formatMessage({ id: 'storage_bay' })} ${model.code}`}>
        <ModelForm match={match} />
      </Card>
    );
  }
}

StorageBayDetailScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,

  setReslId: PropTypes.func,
  model: PropTypes.shape({
    code: PropTypes.string,
    status: PropTypes.number
  })
};

StorageBayDetailScreen.defaultProps = {
  intl: {},
  match: {},

  setReslId() {},
  model: { code: '', status: 2 }
};

const mapStateToProps = state => ({
  model: state.storageBayDetail.model
});

const mapDispatchToProps = dispatch => ({
  setReslId: resId => dispatch(StorageBayDetailActions.storageBayDetailSetResId(resId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(StorageBayDetailScreen));
