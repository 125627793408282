import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import { Table, Popconfirm, Button } from 'antd';

import LspAdvShipDetailActions from '../../Stores/LspAdvShipDetail/Actions';

class DetailTable extends React.PureComponent {
  constructor() {
    super();

    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.useOnSelectItem = this.useOnSelectItem.bind(this);
    this.useCreateDetail = this.useCreateDetail.bind(this);
    this.useOnDeleteItem = this.useOnDeleteItem.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { hdrId, showDetails, showDetailsSuccess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('timestamp')) {
      if (hdrId === 0) {
        showDetailsSuccess([]);
      } else {
        showDetails(hdrId);
      }
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        width: 50,
        fixed: 'left',
        align: 'right',
        title: '',
        // sort field
        dataIndex: 'line_no',
        // filter field
        key: 'line_no',
        render: (text, record) => <>{record.line_no}</>
      },
      {
        width: 80,
        align: 'left',
        title: intl.formatMessage({ id: 'location' }),
        // sort field
        dataIndex: 'location_code',
        // filter field
        key: 'location_code',
        render: (text, record) => <>{record.location_code}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'item_code',
        // filter field
        key: 'item_code',
        render: (text, record) => <>{record.item_code}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        // filter field
        key: 'desc_01',
        render: (text, record) => (
          <>
            {record.desc_01}
            {record.desc_02 ? (
              <>
                <br />
                {record.desc_02}
              </>
            ) : (
              ''
            )}
            {record.desc_03 ? (
              <>
                <br />
                {record.desc_03}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'qty' }),
        // sort field
        dataIndex: 'qty',
        // filter field
        key: 'qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.qty)}
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'uom' }),
        // sort field
        dataIndex: 'uom_code',
        // filter field
        key: 'uom_code',
        render: (text, record) => <>{record.uom_select2.label}</>
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'price' }),
        // sort field
        dataIndex: 'sale_price',
        // filter field
        key: 'sale_price',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_PRICE_SCALE,
              maximumFractionDigits: process.env.REACT_APP_PRICE_SCALE
            }).format(record.sale_price)}
            {record.price_disc > 0 ? (
              <>
                <br />
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.price_disc)}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'disc' }),
        // sort field
        dataIndex: 'dtl_disc_perc_01',
        // filter field
        key: 'dtl_disc_perc_01',
        render: (text, record) => (
          <>
            {record.dtl_disc_val_01 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_val_01)}
                &nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_perc_01 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_perc_01)}
                %&nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_val_02 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_val_02)}
                &nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_perc_02 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_perc_02)}
                %&nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_val_03 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_val_03)}
                &nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_perc_03 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_perc_03)}
                %&nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_val_04 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_val_04)}
                &nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_perc_04 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_perc_04)}
                %&nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_val_05 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_val_05)}
                &nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_perc_05 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_perc_05)}
                %&nbsp;
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'taxable_amt' }),
        // sort field
        dataIndex: 'dtl_taxable_amt_01',
        // filter field
        key: 'dtl_taxable_amt_01',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.dtl_taxable_amt_01)}
          </>
        )
      },
      {
        width: 150,
        align: 'right',
        title: intl.formatMessage({ id: 'tax' }),
        // sort field
        dataIndex: 'dtl_tax_perc_01',
        // filter field
        key: 'dtl_tax_perc_01',
        render: (text, record) => (
          <>
            {record.dtl_tax_val_01 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_tax_val_01)}
                &nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_tax_perc_01 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_tax_perc_01)}
                %&nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_tax_val_02 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_tax_val_02)}
                &nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_tax_perc_02 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_tax_perc_02)}
                %&nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_tax_val_03 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_tax_val_03)}
                &nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_tax_perc_03 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_tax_perc_03)}
                %&nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_tax_val_04 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_tax_val_04)}
                &nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_tax_perc_04 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_tax_perc_04)}
                %&nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_tax_val_05 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_tax_val_05)}
                &nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_tax_perc_05 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_tax_perc_05)}
                %&nbsp;
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'tax_amt' }),
        // sort field
        dataIndex: 'dtl_tax_amt_01',
        // filter field
        key: 'dtl_tax_amt_01',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.dtl_tax_amt_01)}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'net_amt' }),
        // sort field
        dataIndex: 'net_amt',
        // filter field
        key: 'net_amt',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.net_amt)}
          </>
        )
      },
      {
        width: 50,
        fixed: 'right',
        key: 'action',
        render: (text, record) => (
          <>
            <Button
              type="dashed"
              icon={<EditOutlined />}
              onClick={() => this.useOnSelectItem(record)}
            />
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_line' })}
              onConfirm={() => this.useOnDeleteItem(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnSelectItem(record) {
    const { setDocumentDetail, setDetailVisible } = this.props;

    setDocumentDetail(record);

    setDetailVisible(true);
  }

  useCreateDetail() {
    const { initDocumentDetail, setDocumentDetail, setDetailVisible } = this.props;

    setDocumentDetail(initDocumentDetail);

    setDetailVisible(true);
  }

  useOnDeleteItem(record) {
    const { hdrId, deleteDetail } = this.props;

    deleteDetail(hdrId, record);
  }

  render() {
    const { intl, hdrId, itemId, documentDetails, documentIsLoading } = this.props;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{ pageSize: 20 }}
          columns={this.getDocumentColumns()}
          dataSource={documentDetails}
          loading={documentIsLoading}
          bordered
          rowClassName={rowData => {
            if ('is_modified' in rowData && rowData.is_modified === true) {
              return 'success-row';
            }
            if (rowData.item_id === itemId) {
              return 'error-row';
            }
            return '';
          }}
          title={() => (
            <Button
              name="add_detail"
              type="primary"
              icon={<PlusOutlined />}
              disabled={hdrId === 0}
              loading={documentIsLoading}
              onClick={this.useCreateDetail}
            >
              {intl.formatMessage({ id: 'add' })}
            </Button>
          )}
          scroll={{ x: 950 }}
        />
      </>
    );
  }
}

DetailTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  showDetails: PropTypes.func,
  showDetailsSuccess: PropTypes.func,
  setDocumentDetail: PropTypes.func,
  setDetailVisible: PropTypes.func,
  deleteDetail: PropTypes.func,
  hdrId: PropTypes.number,
  documentIsLoading: PropTypes.bool,
  documentDetails: PropTypes.arrayOf(PropTypes.object),
  initDocumentDetail: PropTypes.shape({}),
  itemId: PropTypes.number
};

DetailTable.defaultProps = {
  intl: {},
  showDetails() {},
  showDetailsSuccess() {},
  setDocumentDetail() {},
  setDetailVisible() {},
  deleteDetail() {},
  hdrId: 0,
  documentDetails: [],
  documentIsLoading: false,
  initDocumentDetail: {},
  itemId: 0
};

const mapStateToProps = state => ({
  timestamp: state.lspAdvShipDetail.timestamp,
  hdrId: state.lspAdvShipDetail.hdrId,
  documentDetails: state.lspAdvShipDetail.documentDetails,
  initDocumentDetail: state.lspAdvShipDetail.initDocumentDetail,
  documentIsLoading: state.lspAdvShipDetail.documentIsLoading,
  itemId: state.lspAdvShipDetail.itemId
});

const mapDispatchToProps = dispatch => ({
  showDetails: hdrId => dispatch(LspAdvShipDetailActions.lspAdvShipDetailShowDetails(hdrId)),
  showDetailsSuccess: details =>
    dispatch(LspAdvShipDetailActions.lspAdvShipDetailShowDetailsSuccess(details)),
  setDetailVisible: boolean =>
    dispatch(LspAdvShipDetailActions.lspAdvShipDetailSetDetailVisible(boolean)),
  setDocumentDetail: documentDetail =>
    dispatch(LspAdvShipDetailActions.lspAdvShipDetailSetDocumentDetail(documentDetail)),
  deleteDetail: (hdrId, documentDetail) =>
    dispatch(LspAdvShipDetailActions.lspAdvShipDetailDeleteDetail(hdrId, documentDetail))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DetailTable));
