import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { ArrowLeftOutlined, EditOutlined, ReloadOutlined, DeleteOutlined } from '@ant-design/icons';

import { Table, Button, Modal, Row, Col, Popconfirm } from 'antd';

import ItemDetailActions from '../../Stores/ItemDetail/Actions';
import AppActions from '../../Stores/App/Actions';
import ItemDocNoForm from './ItemDocNoForm';

class ItemDocNoTable extends React.PureComponent {
  constructor() {
    super();

    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.useOnSelectItem = this.useOnSelectItem.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.useCreateDocNo = this.useCreateDocNo.bind(this);
    this.useOnDeleteItem = this.useOnDeleteItem.bind(this);
  }

  componentDidMount() {
    const { match, showItemDetail } = this.props;

    if (match.params.id > 0) {
      showItemDetail(match.params.id);
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setFormVisible, isLoading } = this.props;

    if (isLoading === false) {
      setFormVisible(false);
    }
  }

  getDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'item_code' }),
        // sort field
        dataIndex: 'item_code',
        // filter field
        key: 'item_code',

        render: (text, record) => record.item.code
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'description',
        // filter field
        key: 'description',

        render: (text, record) => record.item.desc_01
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'item_doc_prefix' }),
        // sort field
        dataIndex: 'item_doc_prefix',
        // filter field
        key: 'item_doc_prefix',

        render: (text, record) => record.doc_prefix
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'running_number' }),
        // sort field
        dataIndex: 'running_number',
        // filter field
        key: 'running_number',

        render: (text, record) => record.running_no
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'running_number_length' }),
        // sort field
        dataIndex: 'running_number_length',
        // filter field
        key: 'running_number_length',

        render: (text, record) => record.running_no_length
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_suffix' }),
        // sort field
        dataIndex: 'doc_suffix',
        // filter field
        key: 'doc_suffix',

        render: (text, record) => record.doc_suffix
      },
      {
        fixed: 'right',
        width: 50,
        key: 'action',
        render: (text, record) => (
          <>
            <Button
              type="dashed"
              icon={<EditOutlined />}
              onClick={() => this.useOnSelectItem(record)}
            />
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_line' })}
              onConfirm={() => this.useOnDeleteItem(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useCreateDocNo() {
    const { initDocNo, setFormVisible, setDocNo } = this.props;

    setDocNo(initDocNo);

    setFormVisible(true);
  }

  useOnSelectItem(record) {
    const { setDocNo, setFormVisible } = this.props;

    setDocNo(record);

    setFormVisible(true);
  }

  useOnDeleteItem(record) {
    const { deleteDocNo, resId } = this.props;

    deleteDocNo(record, resId);
  }

  render() {
    const {
      intl,
      historyGoBack,
      data,
      isLoading,
      match,
      formIsVisible,
      resetTimestamp
    } = this.props;

    return (
      <>
        <Table
          // title={() => intl.formatMessage({ id: 'data' })}
          // rowSelection={rowSelection}
          rowKey="id"
          columns={this.getDocumentColumns()}
          dataSource={data}
          loading={isLoading}
          bordered
          onChange={this.useOnTableChange}
          scroll={{ x: 900 }}
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={3}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={isLoading}
                    onClick={historyGoBack}
                    icon={<ArrowLeftOutlined />}
                  >
                    {intl.formatMessage({
                      id: 'back'
                    })}
                  </Button>
                </Col>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={isLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
                <Col span={2}>
                  <Button
                    type="primary"
                    loading={isLoading}
                    onClick={this.useCreateDocNo}
                    disabled={data.length > 0}
                  >
                    {intl.formatMessage({ id: 'new' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        />
        <Modal
          visible={formIsVisible}
          title={intl.formatMessage({ id: 'item_doc_no_dtl' })}
          width="80%"
          centered
          forceRender
          footer={null}
          onCancel={this.handleCancel}
        >
          <ItemDocNoForm match={match} />
        </Modal>
      </>
    );
  }
}

ItemDocNoTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  showItemDetail: PropTypes.func,
  historyGoBack: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),

  isLoading: PropTypes.bool,
  formIsVisible: PropTypes.bool,
  deleteDocNo: PropTypes.func,

  match: PropTypes.object,

  initDocNo: PropTypes.shape({}),
  setDocNo: PropTypes.func,
  setFormVisible: PropTypes.func,
  resId: PropTypes.number
};

ItemDocNoTable.defaultProps = {
  intl: {},
  match: {},
  resetTimestamp() {},
  showItemDetail() {},
  historyGoBack() {},
  data: [],

  isLoading: false,
  formIsVisible: false,
  deleteDocNo() {},

  initDocNo: {},
  setDocNo() {},
  setFormVisible() {},
  resId: 0
};

const mapStateToProps = state => ({
  timestamp: state.itemDetail.itemBatchTimestamp,
  data: state.itemDetail.itemDocNo,
  formIsVisible: state.itemDetail.formIsVisible,
  isLoading: state.itemDetail.itemDetailShowItemDocNoLoading,
  initDocNo: state.itemDetail.initItemDocNo,
  resId: state.itemDetail.resId
});

const mapDispatchToProps = dispatch => ({
  showItemDetail: itemId => dispatch(ItemDetailActions.itemDetailShowItemDocNo(itemId)),

  historyGoBack: () => dispatch(AppActions.appHistoryGoBack()),
  resetTimestamp: () => dispatch(ItemDetailActions.itemDetailResetModelTimestamp()),
  setDocNo: itemDocNoDtl => dispatch(ItemDetailActions.itemDetailSetItemDocNo(itemDocNoDtl)),
  setFormVisible: boolean => dispatch(ItemDetailActions.itemDetailSetFormVisible(boolean)),
  deleteDocNo: (itemDocNoDtl, resId) =>
    dispatch(ItemDetailActions.itemDetailDeleteItemDocNo(itemDocNoDtl, resId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ItemDocNoTable));
