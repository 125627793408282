/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { StkRcptIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const stkRcptIndexResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const stkRcptIndexFetchStkRcptIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const stkRcptIndexFetchStkRcptIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const stkRcptIndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const stkRcptIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const stkRcptIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StkRcptIndexTypes.STK_RCPT_INDEX_RESET_TIMESTAMP]: stkRcptIndexResetTimestamp,
  [StkRcptIndexTypes.STK_RCPT_INDEX_FETCH_STK_RCPT_INDEX_LOADING]: stkRcptIndexFetchStkRcptIndexLoading,
  [StkRcptIndexTypes.STK_RCPT_INDEX_FETCH_STK_RCPT_INDEX_SUCCESS]: stkRcptIndexFetchStkRcptIndexSuccess,
  [StkRcptIndexTypes.STK_RCPT_INDEX_ADD_SELECTED_DOCUMENTS]: stkRcptIndexAddSelectedDocuments,
  [StkRcptIndexTypes.STK_RCPT_INDEX_REMOVE_SELECTED_DOCUMENTS]: stkRcptIndexRemoveSelectedDocuments,
  [StkRcptIndexTypes.STK_RCPT_INDEX_SET_WORKSPACE_VISIBLE]: stkRcptIndexSetWorkspaceVisible
});
