import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  principalDetailResetModelTimestamp: null,
  principalDetailSetResId: ['resId'],

  principalDetailInitModel: null,
  principalDetailShowModel: ['resId'],
  principalDetailShowModelSuccess: ['model'],

  principalDetailUpdateModel: ['formikBag', 'model'],
  principalDetailCreateModel: ['formikBag', 'model'],
  principalDetailUpdateModelSuccess: ['model'],

  principalDetailShowModelLoading: ['boolean'],

  principalDetailFetchAreaOptions: ['search'],
  principalDetailFetchAreaOptionLoading: ['boolean'],
  principalDetailFetchAreaOptionSuccess: ['options'],

  principalDetailResetDeliveryPointTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  principalDetailShowDeliveryPoints: ['resId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  principalDetailShowDeliveryPointSuccess: [
    'deliveryPoints',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  principalDetailGoToDeliveryPoint: ['deliveryPointId'],
  principalDetailNewDeliveryPoint: ['debtorId'],

  principalDetailResetBizPartnerTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  principalDetailShowBizPartners: ['resId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  principalDetailShowBizPartnerSuccess: [
    'bizPartners',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  principalDetailGoToBizPartner: ['bizPartnerId'],
  principalDetailNewBizPartner: ['debtorId']
});

export const PrincipalDetailTypes = Types;
export default Creators;
