import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Card, Button, Row, Col, Typography } from 'antd';
import { FormikDatePicker, FormikButton, FormikSelect, FormikInput } from '../../Components/Formik';
import LoadList01Actions from '../../Stores/LoadList01/Actions';

const { Text } = Typography;

class LoadList01Form extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchTransport = this.handleSearchTransport.bind(this);
    this.handleSearchDriver01 = this.handleSearchDriver01.bind(this);
    this.handleSearchDeliveryMan01 = this.handleSearchDeliveryMan01.bind(this);
    this.handleSearchDeliveryMan02 = this.handleSearchDeliveryMan02.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleSearchTransport(form, value) {
    const { fetchTransportOptions } = this.props;

    fetchTransportOptions(value);
  }

  handleSearchDriver01(form, value) {
    const { fetchDriver01Options } = this.props;

    fetchDriver01Options(value);
  }

  handleSearchDeliveryMan01(form, value) {
    const { fetchDeliveryMan01Options } = this.props;

    fetchDeliveryMan01Options(value);
  }

  handleSearchDeliveryMan02(form, value) {
    const { fetchDeliveryMan02Options } = this.props;

    fetchDeliveryMan02Options(value);
  }

  render() {
    const {
      intl,
      outbOrdHdrs,
      inbOrdHdrs,
      transportIsLoading,
      transportOptions,
      driver01IsLoading,
      driver01Options,
      deliveryMan01IsLoading,
      deliveryMan01Options,
      deliveryMan02IsLoading,
      deliveryMan02Options,
      createLoadList01
    } = this.props;

    return (
      <Card>
        <Formik
          enableReinitialize
          initialValues={{
            doc_date: moment(new Date()).format('YYYY-MM-DD'),
            transport_select2: { value: 0, label: '' },
            driver_01_select2: { value: 0, label: '' },
            delivery_man_01_select2: { value: 0, label: '' },
            delivery_man_02_select2: { value: 0, label: '' },
            desc_01: '',
            desc_02: ''
          }}
          onSubmit={(values, formikBag) => {
            const processedValues = {
              doc_date: values.doc_date,
              transport_id: values.transport_select2.value,
              driver_01_id: values.driver_01_select2.value,
              delivery_man_01_id: values.delivery_man_01_select2.value,
              delivery_man_02_id: values.delivery_man_02_select2.value,
              desc_01: values.desc_01,
              desc_02: values.desc_02
            };
            createLoadList01(formikBag, processedValues, outbOrdHdrs, inbOrdHdrs);
          }}
          validationSchema={Yup.object().shape({
            doc_date: Yup.date().min(
              new Date(1969, 12, 1),
              intl.formatMessage({ id: 'doc_date_is_required' })
            ),
            transport_select2: Yup.object().shape({
              value: Yup.number().min(1, intl.formatMessage({ id: 'transport_is_required' }))
            }),
            driver_01_select2: Yup.object().shape({
              value: Yup.number().min(1, intl.formatMessage({ id: 'driver_is_required' }))
            }),
            delivery_man_01_select2: Yup.object().shape({
              value: Yup.number().min(1, intl.formatMessage({ id: 'delivery_man_is_required' }))
            })
          })}
        >
          {({
            // values,
            // handleChange,
            // errors,
            // setFieldTouched,
            dirty,
            // touched,
            isSubmitting,
            // isValid,
            handleSubmit,
            handleReset
          }) => (
            <Form>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'doc_date' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="doc_date" component={FormikDatePicker} />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'transport' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="transport_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchTransport}
                    notFoundContent={null}
                    loading={transportIsLoading}
                    options={transportOptions}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'driver' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="driver_01_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchDriver01}
                    notFoundContent={null}
                    loading={driver01IsLoading}
                    options={driver01Options}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'delivery_man_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="delivery_man_01_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchDeliveryMan01}
                    notFoundContent={null}
                    loading={deliveryMan01IsLoading}
                    options={deliveryMan01Options}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'delivery_man_02' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="delivery_man_02_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchDeliveryMan02}
                    notFoundContent={null}
                    loading={deliveryMan02IsLoading}
                    options={deliveryMan02Options}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'desc_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="desc_01" component={FormikInput} />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'desc_02' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="desc_02" component={FormikInput} />
                </Col>
              </Row>

              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={6}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting}
                    onClick={handleReset}
                    icon={<UndoOutlined />}
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    name="submit_action"
                    type="primary"
                    value="create"
                    component={FormikButton}
                    disabled={outbOrdHdrs.length === 0}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'create'
                    })}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card>
    );
  }
}

LoadList01Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  outbOrdHdrs: PropTypes.arrayOf(PropTypes.object),
  inbOrdHdrs: PropTypes.arrayOf(PropTypes.object),

  fetchTransportOptions: PropTypes.func,
  transportOptions: PropTypes.arrayOf(PropTypes.object),
  transportIsLoading: PropTypes.bool,

  fetchDriver01Options: PropTypes.func,
  driver01Options: PropTypes.arrayOf(PropTypes.object),
  driver01IsLoading: PropTypes.bool,

  fetchDeliveryMan01Options: PropTypes.func,
  deliveryMan01Options: PropTypes.arrayOf(PropTypes.object),
  deliveryMan01IsLoading: PropTypes.bool,

  fetchDeliveryMan02Options: PropTypes.func,
  deliveryMan02Options: PropTypes.arrayOf(PropTypes.object),
  deliveryMan02IsLoading: PropTypes.bool,

  createLoadList01: PropTypes.func
};

LoadList01Form.defaultProps = {
  intl: {},

  outbOrdHdrs: [],
  inbOrdHdrs: [],

  fetchTransportOptions() {},
  transportOptions: [],
  transportIsLoading: false,

  fetchDriver01Options() {},
  driver01Options: [],
  driver01IsLoading: false,

  fetchDeliveryMan01Options() {},
  deliveryMan01Options: [],
  deliveryMan01IsLoading: false,

  fetchDeliveryMan02Options() {},
  deliveryMan02Options: [],
  deliveryMan02IsLoading: false,

  createLoadList01() {}
};

const mapStateToProps = state => ({
  outbOrdHdrs: state.loadList01.outbOrdHdrs,
  inbOrdHdrs: state.loadList01.inbOrdHdrs,

  transportOptions: state.loadList01.transportOptions,
  transportIsLoading: state.loadList01.transportIsLoading,
  driver01Options: state.loadList01.driver01Options,
  driver01IsLoading: state.loadList01.driver01IsLoading,
  deliveryMan01Options: state.loadList01.deliveryMan01Options,
  deliveryMan01IsLoading: state.loadList01.deliveryMan01IsLoading,
  deliveryMan02Options: state.loadList01.deliveryMan02Options,
  deliveryMan02IsLoading: state.loadList01.deliveryMan02IsLoading
});

const mapDispatchToProps = dispatch => ({
  fetchTransportOptions: search =>
    dispatch(LoadList01Actions.loadList01FetchTransportOptions(search)),
  fetchDriver01Options: search =>
    dispatch(LoadList01Actions.loadList01FetchDriver01Options(search)),
  fetchDeliveryMan01Options: search =>
    dispatch(LoadList01Actions.loadList01FetchDeliveryMan01Options(search)),
  fetchDeliveryMan02Options: search =>
    dispatch(LoadList01Actions.loadList01FetchDeliveryMan02Options(search)),
  createLoadList01: (formikBag, formDetail, outbOrdHdrs, inbOrdHdrs) =>
    dispatch(
      LoadList01Actions.loadList01CreateLoadList01(formikBag, formDetail, outbOrdHdrs, inbOrdHdrs)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoadList01Form));
