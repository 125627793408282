/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { AdvShipSync02Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const advShipSync02ShowSyncSettingLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const advShipSync02ShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const advShipSync02ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const advShipSync02SyncLoading = (state, { boolean }) => ({
  ...state,
  syncIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [AdvShipSync02Types.ADV_SHIP_SYNC02_SHOW_SYNC_SETTING_LOADING]: advShipSync02ShowSyncSettingLoading,
  [AdvShipSync02Types.ADV_SHIP_SYNC02_SHOW_SYNC_SETTING_SUCCESS]: advShipSync02ShowSyncSettingSuccess,
  [AdvShipSync02Types.ADV_SHIP_SYNC02_SHOW_BATCH_JOB_STATUS_SUCCESS]: advShipSync02ShowBatchJobStatusSuccess,
  [AdvShipSync02Types.ADV_SHIP_SYNC02_SYNC_LOADING]: advShipSync02SyncLoading
});
