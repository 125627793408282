import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import GdsRcptIndexActions, { GdsRcptIndexTypes } from '../Stores/GdsRcptIndex/Actions';

const getAppStore = state => state.app;

export function* gdsRcptIndexFetchGdsRcptIndex({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(GdsRcptIndexActions.gdsRcptIndexFetchGdsRcptIndexLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `gdsRcpt/indexGdsRcpt/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        GdsRcptIndexActions.gdsRcptIndexFetchGdsRcptIndexSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptIndexActions.gdsRcptIndexFetchGdsRcptIndexLoading(false));
  }
}

export function* gdsRcptIndexGoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/gdsRcptDetail/update/${hdrId}`));
}

export function* gdsRcptIndexGoToAudit({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/auditResource/GDS_RCPT/${hdrId}/GdsRcptHdr`));
}

export function* gdsRcptIndexGoToPrint({ document }) {
  try {
    yield put(GdsRcptIndexActions.gdsRcptIndexFetchGdsRcptIndexLoading(true));

    const app = yield select(getAppStore);

    const getData = {
      hdrIds: [document.id]
    };

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `gdsRcpt/print/${app.curSiteFlowId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, `GdsRcpt_${document.doc_code}.pdf`);
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptIndexActions.gdsRcptIndexFetchGdsRcptIndexLoading(false));
  }
}

export function* gdsRcptIndexGoToPrintHandlingUnit({ document }) {
  try {
    yield put(GdsRcptIndexActions.gdsRcptIndexFetchGdsRcptIndexLoading(true));

    const app = yield select(getAppStore);

    const getData = {
      ids: [document.id]
    };

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `handlingUnit/printProcess/HANDLING_UNIT_LIST_04/${app.curSiteFlowId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, `GdsRcpt_${document.doc_code}.pdf`);
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptIndexActions.gdsRcptIndexFetchGdsRcptIndexLoading(false));
  }
}

export function* gdsRcptIndexResetGdsRcptFServ01({ hdrIds }) {
  try {
    yield put(GdsRcptIndexActions.gdsRcptIndexWorkspaceLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      hdrIds
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `gdsRcpt/fServProcess/RESET_GDS_RCPT_F_SERV_01/${app.curSiteFlowId}/${app.curDivisionId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(GdsRcptIndexActions.gdsRcptIndexResetGdsRcptFServ01Success());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptIndexActions.gdsRcptIndexWorkspaceLoading(false));
  }
}

export function* gdsRcptIndexFetchExpandedDetails({ hdrId }) {
  try {
    yield put(GdsRcptIndexActions.gdsRcptIndexFetchExpandedLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `gdsRcpt/showPutAwayDetails/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(GdsRcptIndexActions.gdsRcptIndexFetchExpandedSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptIndexActions.gdsRcptIndexFetchExpandedLoading(false));
  }
}

export const saga = [
  takeLatest(GdsRcptIndexTypes.GDS_RCPT_INDEX_GO_TO_DOCUMENT, gdsRcptIndexGoToDocument),
  takeLatest(GdsRcptIndexTypes.GDS_RCPT_INDEX_GO_TO_AUDIT, gdsRcptIndexGoToAudit),
  takeLatest(GdsRcptIndexTypes.GDS_RCPT_INDEX_FETCH_GDS_RCPT_INDEX, gdsRcptIndexFetchGdsRcptIndex),
  takeLatest(GdsRcptIndexTypes.GDS_RCPT_INDEX_GO_TO_PRINT, gdsRcptIndexGoToPrint),
  takeLatest(
    GdsRcptIndexTypes.GDS_RCPT_INDEX_GO_TO_PRINT_HANDLING_UNIT,
    gdsRcptIndexGoToPrintHandlingUnit
  ),
  takeLatest(
    GdsRcptIndexTypes.GDS_RCPT_INDEX_RESET_GDS_RCPT_F_SERV01,
    gdsRcptIndexResetGdsRcptFServ01
  ),
  takeLatest(
    GdsRcptIndexTypes.GDS_RCPT_INDEX_FETCH_EXPANDED_DETAILS,
    gdsRcptIndexFetchExpandedDetails
  )
];
