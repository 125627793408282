import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import CountAdjAnalysisReportActions, {
  CountAdjAnalysisReportTypes
} from '../Stores/CountAdjAnalysisReport/Actions';

const getAppStore = state => state.app;

export function* countAdjAnalysisReportInitCountAdjAnalysis() {
  try {
    yield put(CountAdjAnalysisReportActions.countAdjAnalysisReportReportLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseReport/initCountAdjAnalysis/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        CountAdjAnalysisReportActions.countAdjAnalysisReportInitCountAdjAnalysisSuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjAnalysisReportActions.countAdjAnalysisReportReportLoading(false));
  }
}

export function* countAdjAnalysisReportCountAdjAnalysis({ formikBag, criteria }) {
  try {
    formikBag.setSubmitting(true);
    yield put(CountAdjAnalysisReportActions.countAdjAnalysisReportReportLoading(true));

    const objCriteria = {};
    if ('start_date' in criteria) {
      objCriteria.start_date = criteria.start_date;
    }
    if ('end_date' in criteria) {
      objCriteria.end_date = criteria.end_date;
    }
    if ('company_ids_select2' in criteria) {
      const values = criteria.company_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.company_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_ids_select2' in criteria) {
      const values = criteria.item_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_01_ids_select2' in criteria) {
      const values = criteria.item_group_01_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_01_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_02_ids_select2' in criteria) {
      const values = criteria.item_group_02_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_02_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_03_ids_select2' in criteria) {
      const values = criteria.item_group_03_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_03_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_bin_ids_select2' in criteria) {
      const values = criteria.storage_bin_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_bin_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_row_ids_select2' in criteria) {
      const values = criteria.storage_row_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_row_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_bay_ids_select2' in criteria) {
      const values = criteria.storage_bay_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_bay_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('location_ids_select2' in criteria) {
      const values = criteria.location_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.location_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    let columns = [];
    if (criteria.columns.target.length > 0) {
      columns = criteria.columns.target.map(entry => {
        return entry.column;
      });
    }

    const app = yield select(getAppStore);
    const postData = {
      sorts: [],
      criteria: objCriteria,
      columns
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseReport/countAdjAnalysis/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(
        CountAdjAnalysisReportActions.countAdjAnalysisReportCountAdjAnalysisSuccess(
          criteria,
          result.data.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(CountAdjAnalysisReportActions.countAdjAnalysisReportReportLoading(false));
  }
}

export function* countAdjAnalysisReportFetchItemOptions({ search }) {
  try {
    yield put(CountAdjAnalysisReportActions.countAdjAnalysisReportFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        CountAdjAnalysisReportActions.countAdjAnalysisReportFetchItemOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjAnalysisReportActions.countAdjAnalysisReportFetchItemOptionLoading(false));
  }
}

export function* countAdjAnalysisReportFetchItemGroup01Options({ search }) {
  try {
    yield put(
      CountAdjAnalysisReportActions.countAdjAnalysisReportFetchItemGroup01OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        CountAdjAnalysisReportActions.countAdjAnalysisReportFetchItemGroup01OptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      CountAdjAnalysisReportActions.countAdjAnalysisReportFetchItemGroup01OptionLoading(false)
    );
  }
}

export function* countAdjAnalysisReportFetchItemGroup02Options({ search }) {
  try {
    yield put(
      CountAdjAnalysisReportActions.countAdjAnalysisReportFetchItemGroup02OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup02/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        CountAdjAnalysisReportActions.countAdjAnalysisReportFetchItemGroup02OptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      CountAdjAnalysisReportActions.countAdjAnalysisReportFetchItemGroup02OptionLoading(false)
    );
  }
}

export function* countAdjAnalysisReportFetchItemGroup03Options({ search }) {
  try {
    yield put(
      CountAdjAnalysisReportActions.countAdjAnalysisReportFetchItemGroup03OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup03/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        CountAdjAnalysisReportActions.countAdjAnalysisReportFetchItemGroup03OptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      CountAdjAnalysisReportActions.countAdjAnalysisReportFetchItemGroup03OptionLoading(false)
    );
  }
}

export function* countAdjAnalysisReportFetchStorageBinOptions({ search }) {
  try {
    yield put(
      CountAdjAnalysisReportActions.countAdjAnalysisReportFetchStorageBinOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        CountAdjAnalysisReportActions.countAdjAnalysisReportFetchStorageBinOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      CountAdjAnalysisReportActions.countAdjAnalysisReportFetchStorageBinOptionLoading(false)
    );
  }
}

export function* countAdjAnalysisReportFetchStorageRowOptions({ search }) {
  try {
    yield put(
      CountAdjAnalysisReportActions.countAdjAnalysisReportFetchStorageRowOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageRow/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        CountAdjAnalysisReportActions.countAdjAnalysisReportFetchStorageRowOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      CountAdjAnalysisReportActions.countAdjAnalysisReportFetchStorageRowOptionLoading(false)
    );
  }
}

export function* countAdjAnalysisReportFetchStorageBayOptions({ search }) {
  try {
    yield put(
      CountAdjAnalysisReportActions.countAdjAnalysisReportFetchStorageBayOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBay/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        CountAdjAnalysisReportActions.countAdjAnalysisReportFetchStorageBayOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      CountAdjAnalysisReportActions.countAdjAnalysisReportFetchStorageBayOptionLoading(false)
    );
  }
}

export function* countAdjAnalysisReportFetchLocationOptions({ search }) {
  try {
    yield put(CountAdjAnalysisReportActions.countAdjAnalysisReportFetchLocationOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `location/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        CountAdjAnalysisReportActions.countAdjAnalysisReportFetchLocationOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      CountAdjAnalysisReportActions.countAdjAnalysisReportFetchLocationOptionLoading(false)
    );
  }
}

export function* countAdjAnalysisReportFetchCompanyOptions({ search }) {
  try {
    yield put(CountAdjAnalysisReportActions.countAdjAnalysisReportFetchCompanyOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `company/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} - ${d.name_01}`
      }));

      yield put(
        CountAdjAnalysisReportActions.countAdjAnalysisReportFetchCompanyOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjAnalysisReportActions.countAdjAnalysisReportFetchCompanyOptionLoading(false));
  }
}

export const saga = [
  takeLatest(
    CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_INIT_COUNT_ADJ_ANALYSIS,
    countAdjAnalysisReportInitCountAdjAnalysis
  ),
  takeLatest(
    CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_COUNT_ADJ_ANALYSIS,
    countAdjAnalysisReportCountAdjAnalysis
  ),
  takeLatest(
    CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_ITEM_OPTIONS,
    countAdjAnalysisReportFetchItemOptions
  ),
  takeLatest(
    CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_ITEM_GROUP01_OPTIONS,
    countAdjAnalysisReportFetchItemGroup01Options
  ),
  takeLatest(
    CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_ITEM_GROUP02_OPTIONS,
    countAdjAnalysisReportFetchItemGroup02Options
  ),
  takeLatest(
    CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_ITEM_GROUP03_OPTIONS,
    countAdjAnalysisReportFetchItemGroup03Options
  ),
  takeLatest(
    CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_STORAGE_BIN_OPTIONS,
    countAdjAnalysisReportFetchStorageBinOptions
  ),
  takeLatest(
    CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_STORAGE_ROW_OPTIONS,
    countAdjAnalysisReportFetchStorageRowOptions
  ),
  takeLatest(
    CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_STORAGE_BAY_OPTIONS,
    countAdjAnalysisReportFetchStorageBayOptions
  ),
  takeLatest(
    CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_LOCATION_OPTIONS,
    countAdjAnalysisReportFetchLocationOptions
  ),
  takeLatest(
    CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_COMPANY_OPTIONS,
    countAdjAnalysisReportFetchCompanyOptions
  )
];
