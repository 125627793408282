import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  putAway01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAway01GoToPrint: ['document'],
  putAway01GoToDocument: ['hdrId'],
  putAway01FetchPutAway01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAway01FetchPutAway01Loading: ['boolean'],
  putAway01FetchPutAway01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  putAway01AddSelectedDocuments: ['selectedDocuments'],
  putAway01RemoveSelectedDocuments: ['selectedDocuments'],
  putAway01SetWorkspaceVisible: ['boolean'],
  putAway01CreatePutAway01: ['hdrIds'],
  putAway01CreatePutAway01Loading: ['boolean'],
  putAway01CreatePutAway01Success: ['newDocuments'],

  putAway01SetExpandedRows: ['expandedRows']
});

export const PutAway01Types = Types;
export default Creators;
