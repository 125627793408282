/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,

  isLoading: false,
  data: {
    id: 0,
    code: '',
    description: '',
    setting: []
  },

  creditTermLoading: false,
  creditTermOptions: []
};
