import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob16DetailResetTimestamp: null,
  whseJob16DetailSetHdrId: ['hdrId'],

  whseJob16DetailInitHeader: null,
  whseJob16DetailShowHeader: ['hdrId'],
  whseJob16DetailShowHeaderSuccess: ['documentHeader'],

  whseJob16DetailUpdateHeader: ['formikBag', 'documentHeader'],
  whseJob16DetailCreateHeader: ['formikBag', 'documentHeader'],
  whseJob16DetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  whseJob16DetailShowDocumentLoading: ['boolean'],
  whseJob16DetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  whseJob16DetailShowDetails: ['hdrId'],
  whseJob16DetailShowDetailsSuccess: ['documentDetails'],

  whseJob16DetailSetDetailVisible: ['boolean'],
  whseJob16DetailSetDocumentDetail: ['documentDetail'],

  whseJob16DetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  whseJob16DetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  whseJob16DetailDeleteDetail: ['hdrId', 'documentDetail'],

  whseJob16DetailSetWhseJobType: ['whseJobType'],

  whseJob16DetailFetchCompanyOptions: ['search'],
  whseJob16DetailFetchCompanyOptionLoading: ['boolean'],
  whseJob16DetailFetchCompanyOptionSuccess: ['options'],

  whseJob16DetailFetchItemOptions: ['search'],
  whseJob16DetailFetchItemOptionLoading: ['boolean'],
  whseJob16DetailFetchItemOptionSuccess: ['options'],
  whseJob16DetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  whseJob16DetailFetchItemBatchOptions: ['itemId', 'search'],
  whseJob16DetailFetchItemBatchOptionLoading: ['boolean'],
  whseJob16DetailFetchItemBatchOptionSuccess: ['options'],
  whseJob16DetailChangeItemBatch: ['formikBag', 'hdrId', 'itemBatchId'],

  whseJob16DetailFetchUomOptions: ['itemId', 'search'],
  whseJob16DetailFetchUomOptionLoading: ['boolean'],
  whseJob16DetailFetchUomOptionSuccess: ['options'],
  whseJob16DetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  whseJob16DetailFetchStorageBinOptions: ['search'],
  whseJob16DetailFetchStorageBinOptionLoading: ['boolean'],
  whseJob16DetailFetchStorageBinOptionSuccess: ['options'],

  whseJob16DetailFetchHandlingUnitOptions: ['search'],
  whseJob16DetailFetchHandlingUnitOptionLoading: ['boolean'],
  whseJob16DetailFetchHandlingUnitOptionSuccess: ['options'],

  whseJob16DetailFetchStorageRowOptions: ['search'],
  whseJob16DetailFetchStorageRowOptionLoading: ['boolean'],
  whseJob16DetailFetchStorageRowOptionSuccess: ['options'],

  whseJob16DetailFetchStorageBayOptions: ['search'],
  whseJob16DetailFetchStorageBayOptionLoading: ['boolean'],
  whseJob16DetailFetchStorageBayOptionSuccess: ['options'],

  whseJob16DetailFetchWorkerOptions: ['search'],
  whseJob16DetailFetchWorkerOptionLoading: ['boolean'],
  whseJob16DetailFetchWorkerOptionSuccess: ['options']
});

export const WhseJob16DetailTypes = Types;
export default Creators;
