import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import GdsRcptRtnDetailBoardActions, {
  GdsRcptRtnDetailBoardTypes
} from '../Stores/GdsRcptRtnDetailBoard/Actions';

const getAppStore = state => state.app;

export function* gdsRcptRtnDetailBoardFetchWipInbOrd({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardFetchWipInbOrdLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `inbOrd/indexRtnToDoGdsRcpt/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardFetchWipInbOrdSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardFetchWipInbOrdLoading(false));
  }
}

export function* gdsRcptRtnDetailBoardFetchWipGdsRcpt({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardFetchWipGdsRcptLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `gdsRcpt/indexRtnToDoWhseJob/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardFetchWipGdsRcptSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardFetchWipGdsRcptLoading(false));
  }
}

export function* gdsRcptRtnDetailBoardFetchWipWhseJob({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardFetchWipWhseJobLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/indexRtnToDoGdsRcpt/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardFetchWipWhseJobSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardFetchWipWhseJobLoading(false));
  }
}

export function* gdsRcptRtnDetailBoardFetchCompleteGdsRcpt({
  currentPage,
  sorts,
  filters,
  pageSize
}) {
  try {
    yield put(GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardFetchCompleteGdsRcptLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `gdsRcpt/indexRtnToDoPutAway/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardFetchCompleteGdsRcptSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardFetchCompleteGdsRcptLoading(false));
  }
}

export function* gdsRcptRtnDetailBoardShowWipInbOrdSummary({ docId }) {
  try {
    yield put(GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardShowWipInbOrdSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `inbOrd/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardShowWipInbOrdSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardShowWipInbOrdSummaryLoading(false));
  }
}

export function* gdsRcptRtnDetailBoardShowWipGdsRcptSummary({ docId }) {
  try {
    yield put(GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardShowWipGdsRcptSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `gdsRcpt/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardShowWipGdsRcptSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardShowWipGdsRcptSummaryLoading(false)
    );
  }
}

export function* gdsRcptRtnDetailBoardShowWipWhseJobSummary({ docId }) {
  try {
    yield put(GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardShowWipWhseJobSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/showGdsRcptSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardShowWipWhseJobSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardShowWipWhseJobSummaryLoading(false)
    );
  }
}

export function* gdsRcptRtnDetailBoardShowCompleteGdsRcptSummary({ docId }) {
  try {
    yield put(
      GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardShowCompleteGdsRcptSummaryLoading(true)
    );

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `gdsRcpt/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardShowCompleteGdsRcptSummarySuccess(
          result.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      GdsRcptRtnDetailBoardActions.gdsRcptRtnDetailBoardShowCompleteGdsRcptSummaryLoading(false)
    );
  }
}

export const saga = [
  takeLatest(
    GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_FETCH_WIP_INB_ORD,
    gdsRcptRtnDetailBoardFetchWipInbOrd
  ),

  takeLatest(
    GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_FETCH_WIP_GDS_RCPT,
    gdsRcptRtnDetailBoardFetchWipGdsRcpt
  ),

  takeLatest(
    GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_FETCH_WIP_WHSE_JOB,
    gdsRcptRtnDetailBoardFetchWipWhseJob
  ),

  takeLatest(
    GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_FETCH_COMPLETE_GDS_RCPT,
    gdsRcptRtnDetailBoardFetchCompleteGdsRcpt
  ),

  takeLatest(
    GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SHOW_WIP_INB_ORD_SUMMARY,
    gdsRcptRtnDetailBoardShowWipInbOrdSummary
  ),

  takeLatest(
    GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SHOW_WIP_GDS_RCPT_SUMMARY,
    gdsRcptRtnDetailBoardShowWipGdsRcptSummary
  ),

  takeLatest(
    GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY,
    gdsRcptRtnDetailBoardShowWipWhseJobSummary
  ),

  takeLatest(
    GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SHOW_COMPLETE_GDS_RCPT_SUMMARY,
    gdsRcptRtnDetailBoardShowCompleteGdsRcptSummary
  )
];
