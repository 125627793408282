import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspAdvShipExcel01SetStrProcType: ['strProcType'],
  lspAdvShipExcel01ShowBatchJobStatus: null,
  lspAdvShipExcel01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  lspAdvShipExcel01UploadExcel: ['formikBag', 'projectId', 'debtorId', 'slsOrdFile'],
  lspAdvShipExcel01UploadLoading: ['boolean'],

  lspAdvShipExcel01FetchDebtorOptions: ['search'],
  lspAdvShipExcel01FetchDebtorOptionLoading: ['boolean'],
  lspAdvShipExcel01FetchDebtorOptionSuccess: ['options'],

  lspAdvShipExcel01FetchProjectOptions: ['search'],
  lspAdvShipExcel01FetchProjectOptionLoading: ['boolean'],
  lspAdvShipExcel01FetchProjectOptionSuccess: ['options']
});

export const LspAdvShipExcel01Types = Types;
export default Creators;
