import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import GdsRcptDetailBoardActions, {
  GdsRcptDetailBoardTypes
} from '../Stores/GdsRcptDetailBoard/Actions';

const getAppStore = state => state.app;

export function* gdsRcptDetailBoardFetchWipInbOrd({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(GdsRcptDetailBoardActions.gdsRcptDetailBoardFetchWipInbOrdLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `inbOrd/indexToDoGdsRcpt/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        GdsRcptDetailBoardActions.gdsRcptDetailBoardFetchWipInbOrdSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailBoardActions.gdsRcptDetailBoardFetchWipInbOrdLoading(false));
  }
}

export function* gdsRcptDetailBoardFetchWipGdsRcpt({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(GdsRcptDetailBoardActions.gdsRcptDetailBoardFetchWipGdsRcptLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `gdsRcpt/indexToDoWhseJob/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        GdsRcptDetailBoardActions.gdsRcptDetailBoardFetchWipGdsRcptSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailBoardActions.gdsRcptDetailBoardFetchWipGdsRcptLoading(false));
  }
}

export function* gdsRcptDetailBoardFetchWipWhseJob({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(GdsRcptDetailBoardActions.gdsRcptDetailBoardFetchWipWhseJobLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/indexToDoGdsRcpt/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        GdsRcptDetailBoardActions.gdsRcptDetailBoardFetchWipWhseJobSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailBoardActions.gdsRcptDetailBoardFetchWipWhseJobLoading(false));
  }
}

export function* gdsRcptDetailBoardFetchCompleteGdsRcpt({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(GdsRcptDetailBoardActions.gdsRcptDetailBoardFetchCompleteGdsRcptLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `gdsRcpt/indexToDoPutAway/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        GdsRcptDetailBoardActions.gdsRcptDetailBoardFetchCompleteGdsRcptSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailBoardActions.gdsRcptDetailBoardFetchCompleteGdsRcptLoading(false));
  }
}

export function* gdsRcptDetailBoardShowWipInbOrdSummary({ docId }) {
  try {
    yield put(GdsRcptDetailBoardActions.gdsRcptDetailBoardShowWipInbOrdSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `inbOrd/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        GdsRcptDetailBoardActions.gdsRcptDetailBoardShowWipInbOrdSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailBoardActions.gdsRcptDetailBoardShowWipInbOrdSummaryLoading(false));
  }
}

export function* gdsRcptDetailBoardShowWipGdsRcptSummary({ docId }) {
  try {
    yield put(GdsRcptDetailBoardActions.gdsRcptDetailBoardShowWipGdsRcptSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `gdsRcpt/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        GdsRcptDetailBoardActions.gdsRcptDetailBoardShowWipGdsRcptSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailBoardActions.gdsRcptDetailBoardShowWipGdsRcptSummaryLoading(false));
  }
}

export function* gdsRcptDetailBoardShowWipWhseJobSummary({ docId }) {
  try {
    yield put(GdsRcptDetailBoardActions.gdsRcptDetailBoardShowWipWhseJobSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/showGdsRcptSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        GdsRcptDetailBoardActions.gdsRcptDetailBoardShowWipWhseJobSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailBoardActions.gdsRcptDetailBoardShowWipWhseJobSummaryLoading(false));
  }
}

export function* gdsRcptDetailBoardShowCompleteGdsRcptSummary({ docId }) {
  try {
    yield put(GdsRcptDetailBoardActions.gdsRcptDetailBoardShowCompleteGdsRcptSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `gdsRcpt/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        GdsRcptDetailBoardActions.gdsRcptDetailBoardShowCompleteGdsRcptSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailBoardActions.gdsRcptDetailBoardShowCompleteGdsRcptSummaryLoading(false));
  }
}

export const saga = [
  takeLatest(
    GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_FETCH_WIP_INB_ORD,
    gdsRcptDetailBoardFetchWipInbOrd
  ),

  takeLatest(
    GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_FETCH_WIP_GDS_RCPT,
    gdsRcptDetailBoardFetchWipGdsRcpt
  ),

  takeLatest(
    GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_FETCH_WIP_WHSE_JOB,
    gdsRcptDetailBoardFetchWipWhseJob
  ),

  takeLatest(
    GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_FETCH_COMPLETE_GDS_RCPT,
    gdsRcptDetailBoardFetchCompleteGdsRcpt
  ),

  takeLatest(
    GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SHOW_WIP_INB_ORD_SUMMARY,
    gdsRcptDetailBoardShowWipInbOrdSummary
  ),

  takeLatest(
    GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SHOW_WIP_GDS_RCPT_SUMMARY,
    gdsRcptDetailBoardShowWipGdsRcptSummary
  ),

  takeLatest(
    GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY,
    gdsRcptDetailBoardShowWipWhseJobSummary
  ),

  takeLatest(
    GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SHOW_COMPLETE_GDS_RCPT_SUMMARY,
    gdsRcptDetailBoardShowCompleteGdsRcptSummary
  )
];
