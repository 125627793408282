import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import OutbOrdAnalysisReportActions, {
  OutbOrdAnalysisReportTypes
} from '../Stores/OutbOrdAnalysisReport/Actions';

const getAppStore = state => state.app;

export function* outbOrdAnalysisReportInitOutbOrdAnalysis() {
  try {
    yield put(OutbOrdAnalysisReportActions.outbOrdAnalysisReportReportLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `salesReport/initOutbOrdAnalysis/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        OutbOrdAnalysisReportActions.outbOrdAnalysisReportInitOutbOrdAnalysisSuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(OutbOrdAnalysisReportActions.outbOrdAnalysisReportReportLoading(false));
  }
}

export function* outbOrdAnalysisReportOutbOrdAnalysis({ formikBag, criteria }) {
  try {
    formikBag.setSubmitting(true);
    yield put(OutbOrdAnalysisReportActions.outbOrdAnalysisReportReportLoading(true));

    const objCriteria = {};
    if ('start_date' in criteria) {
      objCriteria.start_date = criteria.start_date;
    }
    if ('end_date' in criteria) {
      objCriteria.end_date = criteria.end_date;
    }
    if ('is_show_zero_balance' in criteria) {
      objCriteria.is_show_zero_balance = criteria.is_show_zero_balance;
    }
    if ('expiry_days' in criteria) {
      objCriteria.expiry_days = criteria.expiry_days;
    }
    if ('item_ids_select2' in criteria) {
      const values = criteria.item_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_01_ids_select2' in criteria) {
      const values = criteria.item_group_01_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_01_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_02_ids_select2' in criteria) {
      const values = criteria.item_group_02_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_02_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_03_ids_select2' in criteria) {
      const values = criteria.item_group_03_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_03_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_bin_ids_select2' in criteria) {
      const values = criteria.storage_bin_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_bin_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_row_ids_select2' in criteria) {
      const values = criteria.storage_row_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_row_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_bay_ids_select2' in criteria) {
      const values = criteria.storage_bay_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_bay_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('location_ids_select2' in criteria) {
      const values = criteria.location_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.location_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    let columns = [];
    if (criteria.columns.target.length > 0) {
      columns = criteria.columns.target.map(entry => {
        return entry.column;
      });
    }

    const app = yield select(getAppStore);
    const postData = {
      sorts: [],
      criteria: objCriteria,
      columns
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `salesReport/outbOrdAnalysis/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(
        OutbOrdAnalysisReportActions.outbOrdAnalysisReportOutbOrdAnalysisSuccess(
          criteria,
          result.data.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(OutbOrdAnalysisReportActions.outbOrdAnalysisReportReportLoading(false));
  }
}

export function* outbOrdAnalysisReportFetchItemOptions({ search }) {
  try {
    yield put(OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchItemOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchItemOptionLoading(false));
  }
}

export function* outbOrdAnalysisReportFetchItemGroup01Options({ search }) {
  try {
    yield put(
      OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchItemGroup01OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchItemGroup01OptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchItemGroup01OptionLoading(false)
    );
  }
}

export function* outbOrdAnalysisReportFetchItemGroup02Options({ search }) {
  try {
    yield put(
      OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchItemGroup02OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup02/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchItemGroup02OptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchItemGroup02OptionLoading(false)
    );
  }
}

export function* outbOrdAnalysisReportFetchItemGroup03Options({ search }) {
  try {
    yield put(
      OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchItemGroup03OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup03/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchItemGroup03OptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchItemGroup03OptionLoading(false)
    );
  }
}

export function* outbOrdAnalysisReportFetchDivisionOptions({ search }) {
  try {
    yield put(OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchDivisionOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `division/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.name_01}`
      }));

      yield put(
        OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchDivisionOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchDivisionOptionLoading(false));
  }
}

export function* outbOrdAnalysisReportFetchDeliveryPointOptions({ search }) {
  try {
    yield put(
      OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchDeliveryPointOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `deliveryPoint/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchDeliveryPointOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutbOrdAnalysisReportActions.outbOrdAnalysisReportFetchDeliveryPointOptionLoading(false)
    );
  }
}

export const saga = [
  takeLatest(
    OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_INIT_OUTB_ORD_ANALYSIS,
    outbOrdAnalysisReportInitOutbOrdAnalysis
  ),
  takeLatest(
    OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_OUTB_ORD_ANALYSIS,
    outbOrdAnalysisReportOutbOrdAnalysis
  ),
  takeLatest(
    OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_OPTIONS,
    outbOrdAnalysisReportFetchItemOptions
  ),
  takeLatest(
    OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_GROUP01_OPTIONS,
    outbOrdAnalysisReportFetchItemGroup01Options
  ),
  takeLatest(
    OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_GROUP02_OPTIONS,
    outbOrdAnalysisReportFetchItemGroup02Options
  ),
  takeLatest(
    OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_GROUP03_OPTIONS,
    outbOrdAnalysisReportFetchItemGroup03Options
  ),

  takeLatest(
    OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_DIVISION_OPTIONS,
    outbOrdAnalysisReportFetchDivisionOptions
  ),
  takeLatest(
    OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_DELIVERY_POINT_OPTIONS,
    outbOrdAnalysisReportFetchDeliveryPointOptions
  )
];
