import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stkIssSync05ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkIssSync05GoToDocument: ['hdrId'],
  stkIssSync05FetchStkIssSync05: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkIssSync05FetchStkIssSync05Loading: ['boolean'],
  stkIssSync05FetchStkIssSync05Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  stkIssSync05AddSelectedDocuments: ['selectedDocuments'],
  stkIssSync05RemoveSelectedDocuments: ['selectedDocuments'],
  stkIssSync05SetWorkspaceVisible: ['boolean'],

  stkIssSync05SyncNow: ['hdrIds'],
  stkIssSync05SyncNowSuccess: null,
  stkIssSync05SyncLoading: ['boolean']
});

export const StkIssSync05Types = Types;
export default Creators;
