import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { Card, Typography, Menu, Row, Col } from 'antd';

import DefaultMinPalletForm from './DefaultMinPalletForm';
import DefaultMobileBatchNoForm from './DefaultMobileBatchNoForm';

const { Text } = Typography;

class GdsRcptSettingScreen extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      selectedKey: 'GDS_RCPT_DEFAULT_MIN_PALLET_QTY' // Default selected key
    };
  }

  handleMenuClick = e => {
    this.setState({ selectedKey: e.key });
  };

  renderComponent() {
    const { selectedKey } = this.state;
    switch (selectedKey) {
      case 'GDS_RCPT_DEFAULT_MIN_PALLET_QTY':
        return <DefaultMinPalletForm />;
      case 'GDS_RCPT_DEFAULT_MOBILE_BATCH_NO':
        return <DefaultMobileBatchNoForm />;
      default:
        return null;
    }
  }

  render() {
    const { intl } = this.props;
    return (
      <Card
        title={`${intl.formatMessage({ id: 'gds_rcpt' })} ${intl.formatMessage({
          id: 'setting'
        })}`}
      >
        <Row>
          <Col span={18}>{this.renderComponent()}</Col>
          <Col span={6}>
            <Menu
              onClick={this.handleMenuClick}
              selectedKeys={[this.state.selectedKey]}
              style={{ width: '100%' }}
            >
              <Menu.Item key="GDS_RCPT_DEFAULT_MIN_PALLET_QTY">
                <Text>{`${intl.formatMessage({
                  id: 'GDS_RCPT_DEFAULT_MIN_PALLET_QTY'
                })}`}</Text>
              </Menu.Item>
              <Menu.Item key="GDS_RCPT_DEFAULT_MOBILE_BATCH_NO">
                <Text>{`${intl.formatMessage({
                  id: 'GDS_RCPT_DEFAULT_MOBILE_BATCH_NO'
                })}`}</Text>
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
      </Card>
    );
  }
}

GdsRcptSettingScreen.propTypes = {
  intl: PropTypes.object
};

GdsRcptSettingScreen.defaultProps = {
  intl: {}
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GdsRcptSettingScreen));
