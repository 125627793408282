import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stkTrfSync01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkTrfSync01GoToDocument: ['hdrId'],
  stkTrfSync01FetchStkTrfSync01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkTrfSync01FetchStkTrfSync01Loading: ['boolean'],
  stkTrfSync01FetchStkTrfSync01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  stkTrfSync01AddSelectedDocuments: ['selectedDocuments'],
  stkTrfSync01RemoveSelectedDocuments: ['selectedDocuments'],
  stkTrfSync01SetWorkspaceVisible: ['boolean'],

  stkTrfSync01SyncNow: ['hdrIds'],
  stkTrfSync01SyncNowSuccess: null,
  stkTrfSync01SyncLoading: ['boolean']
});

export const StkTrfSync01Types = Types;
export default Creators;
