/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StockReorderPlanningV2ReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const stockReorderPlanningV2ReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const stockReorderPlanningV2ReportInitStockReorderPlanningV2Success = (
  state,
  { criteria }
) => ({
  ...state,
  criteria
});

export const stockReorderPlanningV2ReportStockReorderPlanningV2Success = (
  state,
  { criteria, reportData }
) => ({
  ...state,
  criteria,
  reportData
});

export const stockReorderPlanningV2ReportFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const stockReorderPlanningV2ReportFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const stockReorderPlanningV2ReportFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const stockReorderPlanningV2ReportFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const stockReorderPlanningV2ReportFetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const stockReorderPlanningV2ReportFetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const stockReorderPlanningV2ReportFetchItemGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup02IsLoading: boolean
});

export const stockReorderPlanningV2ReportFetchItemGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup02Options: options
});

export const stockReorderPlanningV2ReportFetchItemGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup03IsLoading: boolean
});

export const stockReorderPlanningV2ReportFetchItemGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup03Options: options
});

export const stockReorderPlanningV2ReportFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const stockReorderPlanningV2ReportFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_REPORT_LOADING]: stockReorderPlanningV2ReportReportLoading,
  [StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_INIT_STOCK_REORDER_PLANNING_V2_SUCCESS]: stockReorderPlanningV2ReportInitStockReorderPlanningV2Success,
  [StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_STOCK_REORDER_PLANNING_V2_SUCCESS]: stockReorderPlanningV2ReportStockReorderPlanningV2Success,

  [StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_COMPANY_OPTION_LOADING]: stockReorderPlanningV2ReportFetchCompanyOptionLoading,
  [StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_COMPANY_OPTION_SUCCESS]: stockReorderPlanningV2ReportFetchCompanyOptionSuccess,

  [StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_ITEM_OPTION_LOADING]: stockReorderPlanningV2ReportFetchItemOptionLoading,
  [StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_ITEM_OPTION_SUCCESS]: stockReorderPlanningV2ReportFetchItemOptionSuccess,

  [StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_ITEM_GROUP01_OPTION_LOADING]: stockReorderPlanningV2ReportFetchItemGroup01OptionLoading,
  [StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: stockReorderPlanningV2ReportFetchItemGroup01OptionSuccess,

  [StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_ITEM_GROUP02_OPTION_LOADING]: stockReorderPlanningV2ReportFetchItemGroup02OptionLoading,
  [StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_ITEM_GROUP02_OPTION_SUCCESS]: stockReorderPlanningV2ReportFetchItemGroup02OptionSuccess,

  [StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_ITEM_GROUP03_OPTION_LOADING]: stockReorderPlanningV2ReportFetchItemGroup03OptionLoading,
  [StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_ITEM_GROUP03_OPTION_SUCCESS]: stockReorderPlanningV2ReportFetchItemGroup03OptionSuccess,

  [StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_LOCATION_OPTION_LOADING]: stockReorderPlanningV2ReportFetchLocationOptionLoading,
  [StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_LOCATION_OPTION_SUCCESS]: stockReorderPlanningV2ReportFetchLocationOptionSuccess
});
