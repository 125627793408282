import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob1401IndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1401IndexGoToWhseJobDocument: ['hdrId'],
  whseJob1401IndexGoToAudit: ['hdrId'],
  whseJob1401IndexGoToPrint: ['document'],
  whseJob1401IndexFetchWhseJob1401Index: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1401IndexFetchWhseJob1401IndexLoading: ['boolean'],
  whseJob1401IndexFetchWhseJob1401IndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  whseJob1401IndexAddSelectedDocuments: ['selectedDocuments'],
  whseJob1401IndexRemoveSelectedDocuments: ['selectedDocuments'],
  whseJob1401IndexSetWorkspaceVisible: ['boolean']
});

export const WhseJob1401IndexTypes = Types;
export default Creators;
