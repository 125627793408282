/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { DivisionDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const divisionDetailResetModelTimestamp = state => ({
  ...state,
  modelTimestamp: Date.now()
});

export const divisionDetailSetResId = (state, { resId }) => ({
  ...state,
  modelTimestamp: Date.now(),
  resId
});

export const divisionDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const divisionDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const divisionDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const divisionDetailFetchSiteFlowOptionLoading = (state, { boolean }) => ({
  ...state,
  siteFlowIsLoading: boolean
});

export const divisionDetailFetchSiteFlowOptionSuccess = (state, { options }) => ({
  ...state,
  siteFlowOptions: options
});

export const divisionDetailFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const divisionDetailFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const divisionDetailResetInvTxnFlowTimestamp = state => ({
  ...state,
  invTxnFlowTimestamp: Date.now()
});

export const divisionDetailShowInvTxnFlowsLoading = (state, { boolean }) => ({
  ...state,
  invTxnFlowsIsLoading: boolean
});

export const divisionDetailShowInvTxnFlowsSuccess = (state, { txnFlows }) => ({
  ...state,
  invTxnFlows: txnFlows
});

export const divisionDetailInvTxnFlowsUploadLoading = (state, { boolean }) => ({
  ...state,
  invTxnFlowsUploadIsLoading: boolean
});

export const divisionDetailResetPrintDocSettingTimestamp = state => ({
  ...state,
  printDocSettingTimestamp: Date.now()
});

export const divisionDetailShowPrintDocSettingsLoading = (state, { boolean }) => ({
  ...state,
  printDocSettingsIsLoading: boolean
});

export const divisionDetailShowPrintDocSettingsSuccess = (state, { settings }) => ({
  ...state,
  printDocSettings: settings
});

export const divisionDetailPrintDocSettingsUploadLoading = (state, { boolean }) => ({
  ...state,
  printDocSettingsUploadIsLoading: boolean
});

export const divisionDetailResetUserDivisionTimestamp = state => ({
  ...state,
  userDivisionTimestamp: Date.now()
});

export const divisionDetailShowUserDivisionsLoading = (state, { boolean }) => ({
  ...state,
  userDivisionsIsLoading: boolean
});

export const divisionDetailShowUserDivisionsSuccess = (state, { userDivisions }) => ({
  ...state,
  userDivisions
});

export const divisionDetailUserDivisionsUploadLoading = (state, { boolean }) => ({
  ...state,
  userDivisionsUploadIsLoading: boolean
});

export const divisionDetailFetchArInvDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  arInvDocNoIsLoading: boolean
});

export const divisionDetailFetchArInvDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  arInvDocNoOptions: options
});

export const divisionDetailFetchArCnDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  arCnDocNoIsLoading: boolean
});

export const divisionDetailFetchArCnDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  arCnDocNoOptions: options
});

export const divisionDetailFetchArDnDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  arDnDocNoIsLoading: boolean
});

export const divisionDetailFetchArDnDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  arDnDocNoOptions: options
});

export const divisionDetailFetchArPaymentDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  arPaymentDocNoIsLoading: boolean
});

export const divisionDetailFetchArPaymentDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  arPaymentDocNoOptions: options
});

export const divisionDetailFetchArDepositDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  arDepositDocNoIsLoading: boolean
});

export const divisionDetailFetchArDepositDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  arDepositDocNoOptions: options
});

export const divisionDetailFetchArDepositForfeitDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  arDepositForfeitDocNoIsLoading: boolean
});

export const divisionDetailFetchArDepositForfeitDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  arDepositForfeitDocNoOptions: options
});

export const divisionDetailFetchArDepositRefundDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  arDepositRefundDocNoIsLoading: boolean
});

export const divisionDetailFetchArDepositRefundDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  arDepositRefundDocNoOptions: options
});

export const divisionDetailFetchArRefundDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  arRefundDocNoIsLoading: boolean
});

export const divisionDetailFetchArRefundDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  arRefundDocNoOptions: options
});

export const divisionDetailFetchApInvDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  apInvDocNoIsLoading: boolean
});

export const divisionDetailFetchApInvDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  apInvDocNoOptions: options
});

export const divisionDetailFetchApDnDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  apDnDocNoIsLoading: boolean
});

export const divisionDetailFetchApDnDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  apDnDocNoOptions: options
});

export const divisionDetailFetchApCnDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  apCnDocNoIsLoading: boolean
});

export const divisionDetailFetchApCnDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  apCnDocNoOptions: options
});

export const divisionDetailFetchApPaymentDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  apPaymentDocNoIsLoading: boolean
});

export const divisionDetailFetchApPaymentDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  apPaymentDocNoOptions: options
});

export const divisionDetailFetchApDepositDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  apDepositDocNoIsLoading: boolean
});

export const divisionDetailFetchApDepositDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  apDepositDocNoOptions: options
});

export const divisionDetailFetchApDepositForfeitDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  apDepositForfeitDocNoIsLoading: boolean
});

export const divisionDetailFetchApDepositForfeitDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  apDepositForfeitDocNoOptions: options
});

export const divisionDetailFetchApDepositRefundDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  apDepositRefundDocNoIsLoading: boolean
});

export const divisionDetailFetchApDepositRefundDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  apDepositRefundDocNoOptions: options
});

export const divisionDetailFetchApRefundDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  apRefundDocNoIsLoading: boolean
});

export const divisionDetailFetchApRefundDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  apRefundDocNoOptions: options
});

export const divisionDetailFetchPaymentVoucherDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  paymentVoucherDocNoIsLoading: boolean
});

export const divisionDetailFetchPaymentVoucherDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  paymentVoucherDocNoOptions: options
});

export const divisionDetailFetchReceiptVoucherDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  receiptVoucherDocNoIsLoading: boolean
});

export const divisionDetailFetchReceiptVoucherDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  receiptVoucherDocNoOptions: options
});

export const divisionDetailFetchJournalVoucherDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  journalVoucherDocNoIsLoading: boolean
});

export const divisionDetailFetchJournalVoucherDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  journalVoucherDocNoOptions: options
});

export const divisionDetailFetchSlsOrdDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  slsOrdDocNoIsLoading: boolean
});

export const divisionDetailFetchSlsOrdDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  slsOrdDocNoOptions: options
});

export const divisionDetailFetchSlsRtnDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  slsRtnDocNoIsLoading: boolean
});

export const divisionDetailFetchSlsRtnDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  slsRtnDocNoOptions: options
});

export const divisionDetailFetchDelOrdDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  delOrdDocNoIsLoading: boolean
});

export const divisionDetailFetchDelOrdDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  delOrdDocNoOptions: options
});

export const divisionDetailFetchSlsInvDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  slsInvDocNoIsLoading: boolean
});

export const divisionDetailFetchSlsInvDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  slsInvDocNoOptions: options
});

export const divisionDetailFetchRtnRcptDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  rtnRcptDocNoIsLoading: boolean
});

export const divisionDetailFetchRtnRcptDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  rtnRcptDocNoOptions: options
});

export const divisionDetailFetchAdvShipDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  advShipDocNoIsLoading: boolean
});

export const divisionDetailFetchAdvShipDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  advShipDocNoOptions: options
});

export const divisionDetailFetchPurInvDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  purInvDocNoIsLoading: boolean
});

export const divisionDetailFetchPurInvDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  purInvDocNoOptions: options
});

export const divisionDetailFetchPurRtnDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  purRtnDocNoIsLoading: boolean
});

export const divisionDetailFetchPurRtnDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  purRtnDocNoOptions: options
});

export const divisionDetailFetchLspSlsOrdDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  lspSlsOrdDocNoIsLoading: boolean
});

export const divisionDetailFetchLspSlsOrdDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  lspSlsOrdDocNoOptions: options
});

export const divisionDetailFetchLspSlsRtnDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  lspSlsRtnDocNoIsLoading: boolean
});

export const divisionDetailFetchLspSlsRtnDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  lspSlsRtnDocNoOptions: options
});

export const divisionDetailFetchLspAdvShipDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  lspAdvShipDocNoIsLoading: boolean
});

export const divisionDetailFetchLspAdvShipDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  lspAdvShipDocNoOptions: options
});

export const divisionDetailFetchLspDelOrdDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  lspDelOrdDocNoIsLoading: boolean
});

export const divisionDetailFetchLspDelOrdDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  lspDelOrdDocNoOptions: options
});

export const divisionDetailFetchProdOrdDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  prodOrdDocNoIsLoading: boolean
});

export const divisionDetailFetchProdOrdDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  prodOrdDocNoOptions: options
});

export const divisionDetailFetchProdIssDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  prodIssDocNoIsLoading: boolean
});

export const divisionDetailFetchProdIssDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  prodIssDocNoOptions: options
});

export const divisionDetailFetchProdRcptDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  prodRcptDocNoIsLoading: boolean
});

export const divisionDetailFetchProdRcptDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  prodRcptDocNoOptions: options
});

export const divisionDetailFetchProdSheetDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  prodSheetDocNoIsLoading: boolean
});

export const divisionDetailFetchProdSheetDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  prodSheetDocNoOptions: options
});

export const divisionDetailFetchOutbOrdDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  outbOrdDocNoIsLoading: boolean
});

export const divisionDetailFetchOutbOrdDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  outbOrdDocNoOptions: options
});

export const divisionDetailFetchInbOrdDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  inbOrdDocNoIsLoading: boolean
});

export const divisionDetailFetchInbOrdDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  inbOrdDocNoOptions: options
});

export const divisionDetailFetchTrfOrdDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  trfOrdDocNoIsLoading: boolean
});

export const divisionDetailFetchTrfOrdDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  trfOrdDocNoOptions: options
});

export const divisionDetailFetchStkIssDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  stkIssDocNoIsLoading: boolean
});

export const divisionDetailFetchStkIssDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  stkIssDocNoOptions: options
});

export const divisionDetailFetchStkRcptDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  stkRcptDocNoIsLoading: boolean
});

export const divisionDetailFetchStkRcptDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  stkRcptDocNoOptions: options
});

export const divisionDetailFetchStkTrfDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  stkTrfDocNoIsLoading: boolean
});

export const divisionDetailFetchStkTrfDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  stkTrfDocNoOptions: options
});

export const divisionDetailFetchWorkOrdDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  workOrdDocNoIsLoading: boolean
});

export const divisionDetailFetchWorkOrdDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  workOrdDocNoOptions: options
});
/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [DivisionDetailTypes.DIVISION_DETAIL_RESET_MODEL_TIMESTAMP]: divisionDetailResetModelTimestamp,
  [DivisionDetailTypes.DIVISION_DETAIL_SET_RES_ID]: divisionDetailSetResId,
  [DivisionDetailTypes.DIVISION_DETAIL_SHOW_MODEL_LOADING]: divisionDetailShowModelLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_SHOW_MODEL_SUCCESS]: divisionDetailShowModelSuccess,
  [DivisionDetailTypes.DIVISION_DETAIL_UPDATE_MODEL_SUCCESS]: divisionDetailUpdateModelSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_SITE_FLOW_OPTION_LOADING]: divisionDetailFetchSiteFlowOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_SITE_FLOW_OPTION_SUCCESS]: divisionDetailFetchSiteFlowOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_COMPANY_OPTION_LOADING]: divisionDetailFetchCompanyOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_COMPANY_OPTION_SUCCESS]: divisionDetailFetchCompanyOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_RESET_INV_TXN_FLOW_TIMESTAMP]: divisionDetailResetInvTxnFlowTimestamp,
  [DivisionDetailTypes.DIVISION_DETAIL_SHOW_INV_TXN_FLOWS_LOADING]: divisionDetailShowInvTxnFlowsLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_SHOW_INV_TXN_FLOWS_SUCCESS]: divisionDetailShowInvTxnFlowsSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_INV_TXN_FLOWS_UPLOAD_LOADING]: divisionDetailInvTxnFlowsUploadLoading,

  [DivisionDetailTypes.DIVISION_DETAIL_RESET_PRINT_DOC_SETTING_TIMESTAMP]: divisionDetailResetPrintDocSettingTimestamp,
  [DivisionDetailTypes.DIVISION_DETAIL_SHOW_PRINT_DOC_SETTINGS_LOADING]: divisionDetailShowPrintDocSettingsLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_SHOW_PRINT_DOC_SETTINGS_SUCCESS]: divisionDetailShowPrintDocSettingsSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_PRINT_DOC_SETTINGS_UPLOAD_LOADING]: divisionDetailPrintDocSettingsUploadLoading,

  [DivisionDetailTypes.DIVISION_DETAIL_RESET_USER_DIVISION_TIMESTAMP]: divisionDetailResetUserDivisionTimestamp,
  [DivisionDetailTypes.DIVISION_DETAIL_SHOW_USER_DIVISIONS_LOADING]: divisionDetailShowUserDivisionsLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_SHOW_USER_DIVISIONS_SUCCESS]: divisionDetailShowUserDivisionsSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_USER_DIVISIONS_UPLOAD_LOADING]: divisionDetailUserDivisionsUploadLoading,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AR_INV_DOC_NO_OPTION_LOADING]: divisionDetailFetchArInvDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AR_INV_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchArInvDocNoOptionSuccess,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AR_CN_DOC_NO_OPTION_LOADING]: divisionDetailFetchArCnDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AR_CN_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchArCnDocNoOptionSuccess,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AR_DN_DOC_NO_OPTION_LOADING]: divisionDetailFetchArDnDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AR_DN_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchArDnDocNoOptionSuccess,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AR_PAYMENT_DOC_NO_OPTION_LOADING]: divisionDetailFetchArPaymentDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AR_PAYMENT_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchArPaymentDocNoOptionSuccess,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AR_REFUND_DOC_NO_OPTION_LOADING]: divisionDetailFetchArRefundDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AR_REFUND_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchArRefundDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AR_DEPOSIT_DOC_NO_OPTION_LOADING]: divisionDetailFetchArDepositDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AR_DEPOSIT_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchArDepositDocNoOptionSuccess,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AR_DEPOSIT_FORFEIT_DOC_NO_OPTION_LOADING]: divisionDetailFetchArDepositForfeitDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AR_DEPOSIT_FORFEIT_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchArDepositForfeitDocNoOptionSuccess,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AR_DEPOSIT_REFUND_DOC_NO_OPTION_LOADING]: divisionDetailFetchArDepositRefundDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AR_DEPOSIT_REFUND_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchArDepositRefundDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AP_INV_DOC_NO_OPTION_LOADING]: divisionDetailFetchApInvDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AP_INV_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchApInvDocNoOptionSuccess,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AP_DN_DOC_NO_OPTION_LOADING]: divisionDetailFetchApDnDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AP_DN_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchApDnDocNoOptionSuccess,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AP_CN_DOC_NO_OPTION_LOADING]: divisionDetailFetchApCnDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AP_CN_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchApCnDocNoOptionSuccess,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AP_PAYMENT_DOC_NO_OPTION_LOADING]: divisionDetailFetchApPaymentDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AP_PAYMENT_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchApPaymentDocNoOptionSuccess,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AP_REFUND_DOC_NO_OPTION_LOADING]: divisionDetailFetchApRefundDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AP_REFUND_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchApRefundDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AP_DEPOSIT_DOC_NO_OPTION_LOADING]: divisionDetailFetchApDepositDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AP_DEPOSIT_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchApDepositDocNoOptionSuccess,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AP_DEPOSIT_FORFEIT_DOC_NO_OPTION_LOADING]: divisionDetailFetchApDepositForfeitDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AP_DEPOSIT_FORFEIT_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchApDepositForfeitDocNoOptionSuccess,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AP_DEPOSIT_REFUND_DOC_NO_OPTION_LOADING]: divisionDetailFetchApDepositRefundDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_AP_DEPOSIT_REFUND_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchApDepositRefundDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_PAYMENT_VOUCHER_DOC_NO_OPTION_LOADING]: divisionDetailFetchPaymentVoucherDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_PAYMENT_VOUCHER_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchPaymentVoucherDocNoOptionSuccess,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_RECEIPT_VOUCHER_DOC_NO_OPTION_LOADING]: divisionDetailFetchReceiptVoucherDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_RECEIPT_VOUCHER_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchReceiptVoucherDocNoOptionSuccess,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_JOURNAL_VOUCHER_DOC_NO_OPTION_LOADING]: divisionDetailFetchJournalVoucherDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_JOURNAL_VOUCHER_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchJournalVoucherDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_SLS_ORD_DOC_NO_OPTION_LOADING]: divisionDetailFetchSlsOrdDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_SLS_ORD_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchSlsOrdDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_SLS_RTN_DOC_NO_OPTION_LOADING]: divisionDetailFetchSlsRtnDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_SLS_RTN_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchSlsRtnDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_DEL_ORD_DOC_NO_OPTION_LOADING]: divisionDetailFetchDelOrdDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_DEL_ORD_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchDelOrdDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_SLS_INV_DOC_NO_OPTION_LOADING]: divisionDetailFetchSlsInvDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_SLS_INV_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchSlsInvDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_RTN_RCPT_DOC_NO_OPTION_LOADING]: divisionDetailFetchRtnRcptDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_RTN_RCPT_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchRtnRcptDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_ADV_SHIP_DOC_NO_OPTION_LOADING]: divisionDetailFetchAdvShipDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_ADV_SHIP_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchAdvShipDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_PUR_INV_DOC_NO_OPTION_LOADING]: divisionDetailFetchPurInvDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_PUR_INV_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchPurInvDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_PUR_RTN_DOC_NO_OPTION_LOADING]: divisionDetailFetchPurRtnDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_PUR_RTN_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchPurRtnDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_LSP_SLS_ORD_DOC_NO_OPTION_LOADING]: divisionDetailFetchLspSlsOrdDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_LSP_SLS_ORD_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchLspSlsOrdDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_LSP_SLS_RTN_DOC_NO_OPTION_LOADING]: divisionDetailFetchLspSlsRtnDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_LSP_SLS_RTN_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchLspSlsRtnDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_LSP_ADV_SHIP_DOC_NO_OPTION_LOADING]: divisionDetailFetchLspAdvShipDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_LSP_ADV_SHIP_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchLspAdvShipDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_LSP_DEL_ORD_DOC_NO_OPTION_LOADING]: divisionDetailFetchLspDelOrdDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_LSP_DEL_ORD_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchLspDelOrdDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_PROD_ORD_DOC_NO_OPTION_LOADING]: divisionDetailFetchProdOrdDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_PROD_ORD_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchProdOrdDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_PROD_ISS_DOC_NO_OPTION_LOADING]: divisionDetailFetchProdIssDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_PROD_ISS_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchProdIssDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_PROD_RCPT_DOC_NO_OPTION_LOADING]: divisionDetailFetchProdRcptDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_PROD_RCPT_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchProdRcptDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_PROD_SHEET_DOC_NO_OPTION_LOADING]: divisionDetailFetchProdSheetDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_PROD_SHEET_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchProdSheetDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_OUTB_ORD_DOC_NO_OPTION_LOADING]: divisionDetailFetchOutbOrdDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_OUTB_ORD_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchOutbOrdDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_INB_ORD_DOC_NO_OPTION_LOADING]: divisionDetailFetchInbOrdDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_INB_ORD_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchInbOrdDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_TRF_ORD_DOC_NO_OPTION_LOADING]: divisionDetailFetchTrfOrdDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_TRF_ORD_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchTrfOrdDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_STK_ISS_DOC_NO_OPTION_LOADING]: divisionDetailFetchStkIssDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_STK_ISS_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchStkIssDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_STK_RCPT_DOC_NO_OPTION_LOADING]: divisionDetailFetchStkRcptDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_STK_RCPT_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchStkRcptDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_STK_TRF_DOC_NO_OPTION_LOADING]: divisionDetailFetchStkTrfDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_STK_TRF_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchStkTrfDocNoOptionSuccess,

  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_WORK_ORD_DOC_NO_OPTION_LOADING]: divisionDetailFetchWorkOrdDocNoOptionLoading,
  [DivisionDetailTypes.DIVISION_DETAIL_FETCH_WORK_ORD_DOC_NO_OPTION_SUCCESS]: divisionDetailFetchWorkOrdDocNoOptionSuccess
});
