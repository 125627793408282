import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stockAgingReportInitStockAging: null,
  stockAgingReportInitStockAgingSuccess: ['criteria'],
  stockAgingReportStockAging: ['formikBag', 'criteria'],
  stockAgingReportStockAgingSuccess: ['criteria', 'reportData'],
  stockAgingReportReportLoading: ['boolean'],

  stockAgingReportFetchCompanyOptions: ['search'],
  stockAgingReportFetchCompanyOptionLoading: ['boolean'],
  stockAgingReportFetchCompanyOptionSuccess: ['options'],

  stockAgingReportFetchItemOptions: ['search'],
  stockAgingReportFetchItemOptionLoading: ['boolean'],
  stockAgingReportFetchItemOptionSuccess: ['options'],

  stockAgingReportFetchItemGroup01Options: ['search'],
  stockAgingReportFetchItemGroup01OptionLoading: ['boolean'],
  stockAgingReportFetchItemGroup01OptionSuccess: ['options'],

  stockAgingReportFetchItemGroup02Options: ['search'],
  stockAgingReportFetchItemGroup02OptionLoading: ['boolean'],
  stockAgingReportFetchItemGroup02OptionSuccess: ['options'],

  stockAgingReportFetchItemGroup03Options: ['search'],
  stockAgingReportFetchItemGroup03OptionLoading: ['boolean'],
  stockAgingReportFetchItemGroup03OptionSuccess: ['options'],

  stockAgingReportFetchLocationOptions: ['search'],
  stockAgingReportFetchLocationOptionLoading: ['boolean'],
  stockAgingReportFetchLocationOptionSuccess: ['options']
});

export const StockAgingReportTypes = Types;
export default Creators;
