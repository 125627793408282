import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcptRtnIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptRtnIndexGoToPrint: ['document'],
  gdsRcptRtnIndexGoToPrintHandlingUnit: ['document'],
  gdsRcptRtnIndexGoToDocument: ['hdrId'],
  gdsRcptRtnIndexGoToAudit: ['hdrId'],
  gdsRcptRtnIndexFetchGdsRcptRtnIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptRtnIndexFetchGdsRcptRtnIndexLoading: ['boolean'],
  gdsRcptRtnIndexFetchGdsRcptRtnIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  gdsRcptRtnIndexAddSelectedDocuments: ['selectedDocuments'],
  gdsRcptRtnIndexRemoveSelectedDocuments: ['selectedDocuments'],
  gdsRcptRtnIndexSetWorkspaceVisible: ['boolean'],

  gdsRcptRtnIndexWorkspaceLoading: ['boolean'],
  gdsRcptRtnIndexResetGdsRcptFServ02: ['hdrIds'],
  gdsRcptRtnIndexResetGdsRcptFServ02Success: null
});

export const GdsRcptRtnIndexTypes = Types;
export default Creators;
