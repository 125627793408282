/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhseJob17DetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const whseJob17DetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const whseJob17DetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false,
  jobDetailIsVisible: false
});

export const whseJob17DetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const whseJob17DetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const whseJob17DetailUpdateDocumentSuccess = (
  state,
  { documentHeader, documentDetails }
) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const whseJob17DetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const whseJob17DetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const whseJob17DetailSetWhseJobType = (state, { whseJobType }) => ({
  ...state,
  whseJobType
});

export const whseJob17DetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  whseJobType: documentDetail.whse_job_type,
  frStorageBinOptions: [],
  quantBalOptions: [],
  itemBatchOptions: [],
  uomOptions: [],
  toStorageBinOptions: []
});

export const whseJob17DetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const whseJob17DetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const whseJob17DetailFetchItemBatchOptionLoading = (state, { boolean }) => ({
  ...state,
  itemBatchIsLoading: boolean
});

export const whseJob17DetailFetchItemBatchOptionSuccess = (state, { options }) => ({
  ...state,
  itemBatchOptions: options
});

export const whseJob17DetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const whseJob17DetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

export const whseJob17DetailFetchFrStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  frStorageBinIsLoading: boolean
});

export const whseJob17DetailFetchFrStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  frStorageBinOptions: options
});

export const whseJob17DetailFetchToStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  toStorageBinIsLoading: boolean
});

export const whseJob17DetailFetchToStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  toStorageBinOptions: options
});

export const whseJob17DetailFetchToHandlingUnitOptionLoading = (state, { boolean }) => ({
  ...state,
  toHandlingUnitIsLoading: boolean
});

export const whseJob17DetailFetchToHandlingUnitOptionSuccess = (state, { options }) => ({
  ...state,
  toHandlingUnitOptions: options
});

export const whseJob17DetailFetchHandlingUnitOptionLoading = (state, { boolean }) => ({
  ...state,
  handlingUnitIsLoading: boolean
});

export const whseJob17DetailFetchHandlingUnitOptionSuccess = (state, { options }) => ({
  ...state,
  handlingUnitOptions: options
});

// force refresh the formik form
export const whseJob17DetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const whseJob17DetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const whseJob17DetailFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const whseJob17DetailFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

export const whseJob17DetailFetchStorageBayOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBayIsLoading: boolean
});

export const whseJob17DetailFetchStorageBayOptionSuccess = (state, { options }) => ({
  ...state,
  storageBayOptions: options
});

export const whseJob17DetailFetchQuantBalOptionLoading = (state, { boolean }) => ({
  ...state,
  quantBalIsLoading: boolean
});

export const whseJob17DetailFetchQuantBalOptionSuccess = (state, { options }) => ({
  ...state,
  quantBalOptions: options
});

export const whseJob17DetailFetchWorkerOptionLoading = (state, { boolean }) => ({
  ...state,
  workerIsLoading: boolean
});

export const whseJob17DetailFetchWorkerOptionSuccess = (state, { options }) => ({
  ...state,
  workerOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_RESET_TIMESTAMP]: whseJob17DetailResetTimestamp,
  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_SET_HDR_ID]: whseJob17DetailSetHdrId,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_SHOW_HEADER_SUCCESS]: whseJob17DetailShowHeaderSuccess,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_UPDATE_DOCUMENT_SUCCESS]: whseJob17DetailUpdateDocumentSuccess,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_SHOW_DOCUMENT_LOADING]: whseJob17DetailShowDocumentLoading,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_SHOW_DETAILS_SUCCESS]: whseJob17DetailShowDetailsSuccess,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_SET_DETAIL_VISIBLE]: whseJob17DetailSetDetailVisible,
  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_SET_DOCUMENT_DETAIL]: whseJob17DetailSetDocumentDetail,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_UPDATE_DETAILS]: whseJob17DetailUpdateDetails,
  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_CREATE_DETAIL]: whseJob17DetailCreateDetail,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_SET_WHSE_JOB_TYPE]: whseJob17DetailSetWhseJobType,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_ITEM_OPTION_LOADING]: whseJob17DetailFetchItemOptionLoading,
  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: whseJob17DetailFetchItemOptionSuccess,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_ITEM_BATCH_OPTION_LOADING]: whseJob17DetailFetchItemBatchOptionLoading,
  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_ITEM_BATCH_OPTION_SUCCESS]: whseJob17DetailFetchItemBatchOptionSuccess,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_UOM_OPTION_LOADING]: whseJob17DetailFetchUomOptionLoading,
  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_UOM_OPTION_SUCCESS]: whseJob17DetailFetchUomOptionSuccess,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_FR_STORAGE_BIN_OPTION_LOADING]: whseJob17DetailFetchFrStorageBinOptionLoading,
  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_FR_STORAGE_BIN_OPTION_SUCCESS]: whseJob17DetailFetchFrStorageBinOptionSuccess,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_LOADING]: whseJob17DetailFetchToStorageBinOptionLoading,
  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_SUCCESS]: whseJob17DetailFetchToStorageBinOptionSuccess,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_TO_HANDLING_UNIT_OPTION_LOADING]: whseJob17DetailFetchToHandlingUnitOptionLoading,
  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_TO_HANDLING_UNIT_OPTION_SUCCESS]: whseJob17DetailFetchToHandlingUnitOptionSuccess,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_HANDLING_UNIT_OPTION_LOADING]: whseJob17DetailFetchHandlingUnitOptionLoading,
  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_HANDLING_UNIT_OPTION_SUCCESS]: whseJob17DetailFetchHandlingUnitOptionSuccess,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_STORAGE_ROW_OPTION_LOADING]: whseJob17DetailFetchStorageRowOptionLoading,
  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_STORAGE_ROW_OPTION_SUCCESS]: whseJob17DetailFetchStorageRowOptionSuccess,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_STORAGE_BAY_OPTION_LOADING]: whseJob17DetailFetchStorageBayOptionLoading,
  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_STORAGE_BAY_OPTION_SUCCESS]: whseJob17DetailFetchStorageBayOptionSuccess,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_QUANT_BAL_OPTION_LOADING]: whseJob17DetailFetchQuantBalOptionLoading,
  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_QUANT_BAL_OPTION_SUCCESS]: whseJob17DetailFetchQuantBalOptionSuccess,

  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_WORKER_OPTION_LOADING]: whseJob17DetailFetchWorkerOptionLoading,
  [WhseJob17DetailTypes.WHSE_JOB17_DETAIL_FETCH_WORKER_OPTION_SUCCESS]: whseJob17DetailFetchWorkerOptionSuccess
});
