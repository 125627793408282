import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  packListIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  packListIndexGoToPrint: ['document'],
  packListIndexGoToDocument: ['hdrId'],
  packListIndexGoToAudit: ['hdrId'],
  packListIndexFetchPackListIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  packListIndexFetchPackListIndexLoading: ['boolean'],
  packListIndexFetchPackListIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  packListIndexAddSelectedDocuments: ['selectedDocuments'],
  packListIndexRemoveSelectedDocuments: ['selectedDocuments'],
  packListIndexSetWorkspaceVisible: ['boolean'],

  packListIndexExcelOutbOrdDtls: ['document']
});

export const PackListIndexTypes = Types;
export default Creators;
