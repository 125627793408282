import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stkTrfIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkTrfIndexFetchStkTrfIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkTrfIndexFetchStkTrfIndexLoading: ['boolean'],
  stkTrfIndexFetchStkTrfIndexSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  stkTrfIndexAddSelectedDocuments: ['selectedDocuments'],
  stkTrfIndexRemoveSelectedDocuments: ['selectedDocuments'],
  stkTrfIndexSetWorkspaceVisible: ['boolean'],
  stkTrfIndexGoToDocument: ['hdrId'],
  stkTrfIndexGoToAudit: ['hdrId']
});

export const StkTrfIndexTypes = Types;
export default Creators;
