import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Card, Button, Row, Col, Typography } from 'antd';
import { FormikDatePicker, FormikButton, FormikInput, FormikSelect } from '../../Components/Formik';
import FailDel01Actions from '../../Stores/FailDel01/Actions';

const { Text } = Typography;

class FailDel01Form extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchToStorageBin = this.handleSearchToStorageBin.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleSearchToStorageBin(form, value) {
    const { fetchToStorageBinOptions } = this.props;

    fetchToStorageBinOptions(value);
  }

  render() {
    const {
      intl,
      outbOrdHdrs,
      createFailDel01,
      toStorageBinIsLoading,
      toStorageBinOptions
    } = this.props;

    return (
      <Card>
        <Formik
          enableReinitialize
          initialValues={{
            doc_date: moment(new Date()).format('YYYY-MM-DD'),
            desc_01: '',
            desc_02: ''
          }}
          onSubmit={(values, formikBag) => {
            const processedValues = {
              doc_date: values.doc_date,
              to_storage_bin_id: values.to_storage_bin_select2.value,
              desc_01: values.desc_01,
              desc_02: values.desc_02
            };
            createFailDel01(formikBag, processedValues, outbOrdHdrs);
          }}
          validationSchema={Yup.object().shape({
            doc_date: Yup.date().min(
              new Date(1969, 12, 1),
              intl.formatMessage({ id: 'doc_date_is_required' })
            ),
            to_storage_bin_select2: Yup.object().shape({
              value: Yup.number().min(1, intl.formatMessage({ id: 'loading_bay_is_required' }))
            })
          })}
        >
          {({
            // values,
            // handleChange,
            // errors,
            // setFieldTouched,
            dirty,
            // touched,
            isSubmitting,
            // isValid,
            handleSubmit,
            handleReset
          }) => (
            <Form>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'doc_date' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="doc_date" component={FormikDatePicker} />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'transport' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="to_storage_bin_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchToStorageBin}
                    notFoundContent={null}
                    loading={toStorageBinIsLoading}
                    options={toStorageBinOptions}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'desc_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="desc_01" component={FormikInput} />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'desc_02' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="desc_02" component={FormikInput} />
                </Col>
              </Row>

              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={6}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting}
                    onClick={handleReset}
                    icon={<UndoOutlined />}
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    name="submit_action"
                    type="primary"
                    value="create"
                    component={FormikButton}
                    disabled={outbOrdHdrs.length === 0}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'create'
                    })}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card>
    );
  }
}

FailDel01Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  outbOrdHdrs: PropTypes.arrayOf(PropTypes.object),

  createFailDel01: PropTypes.func,

  fetchToStorageBinOptions: PropTypes.func,
  toStorageBinOptions: PropTypes.arrayOf(PropTypes.object),
  toStorageBinIsLoading: PropTypes.bool
};

FailDel01Form.defaultProps = {
  intl: {},

  outbOrdHdrs: [],

  createFailDel01() {},

  fetchToStorageBinOptions() {},
  toStorageBinOptions: [],
  toStorageBinIsLoading: false
};

const mapStateToProps = state => ({
  outbOrdHdrs: state.failDel01.outbOrdHdrs,

  toStorageBinOptions: state.failDel01.toStorageBinOptions,
  toStorageBinIsLoading: state.failDel01.transportIsLoading
});

const mapDispatchToProps = dispatch => ({
  createFailDel01: (formikBag, formDetail, outbOrdHdrs, inbOrdHdrs) =>
    dispatch(
      FailDel01Actions.failDel01CreateFailDel01(formikBag, formDetail, outbOrdHdrs, inbOrdHdrs)
    ),

  fetchToStorageBinOptions: search =>
    dispatch(FailDel01Actions.failDel01FetchToStorageBinOptions(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FailDel01Form));
