/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  hdrId: 0,
  documentIsLoading: false,
  documentDetails: [],
  detailIsVisible: false,
  jobDetailIsVisible: false,
  documentHeader: {
    doc_status: 3,
    str_doc_status: 'DRAFT',
    doc_code: '',
    ref_code_01: '',
    ref_code_02: '',
    doc_date: '1970-01-01',
    desc_01: '',
    desc_02: '',
    doc_no_id: 0,
    doc_flows: []
  },
  documentDetail: {
    id: 0,
    whse_job_type: 1,
    item_id: 0,
    desc_01: '',
    desc_02: '',
    batch_serial_no: '',
    expiry_date: new Date(),
    receipt_date: new Date(),
    uom_id: 0,
    uom_rate: 1,
    qty: 0,
    storage_bin_select2: {
      value: 0,
      label: ''
    },
    handling_unit_select2: {
      value: 0,
      label: ''
    },
    item_select2: {
      value: 0,
      label: ''
    },
    item_batch_select2: {
      value: 0,
      label: ''
    },
    uom_select2: {
      value: 0,
      label: ''
    },
    to_storage_bin_select2: {
      value: 0,
      label: ''
    },
    to_handling_unit_select2: {
      value: 0,
      label: ''
    },
    handling_unit_barcode: '',
    item_code: '',
    item_desc_01: '',
    item_desc_02: ''
  },
  initDocumentDetail: {
    id: 0,
    whse_job_type: 16,
    item_id: 0,
    desc_01: '',
    desc_02: '',
    batch_serial_no: '',
    expiry_date: new Date(),
    receipt_date: new Date(),
    uom_id: 0,
    uom_rate: 1,
    qty: 0,
    storage_bin_select2: {
      value: 0,
      label: ''
    },
    handling_unit_select2: {
      value: 0,
      label: ''
    },
    item_select2: {
      value: 0,
      label: ''
    },
    item_batch_select2: {
      value: 0,
      label: ''
    },
    uom_select2: {
      value: 0,
      label: ''
    },
    to_storage_bin_select2: {
      value: 0,
      label: ''
    },
    to_handling_unit_select2: {
      value: 0,
      label: ''
    },
    handling_unit_barcode: '',
    item_code: '',
    item_desc_01: '',
    item_desc_02: ''
  },

  whseJobType: 0,

  frStorageBinOptions: [],
  frStorageBinIsLoading: false,

  toStorageBinOptions: [],
  toStorageBinIsLoading: false,

  toHandlingUnitOptions: [],
  toHandlingUnitIsLoading: false,

  handlingUnitOptions: [],
  handlingUnitIsLoading: false,

  itemOptions: [],
  itemIsLoading: false,

  itemBatchOptions: [],
  itemBatchIsLoading: false,

  uomOptions: [],
  uomIsLoading: false,

  storageRowOptions: [],
  storageRowIsLoading: false,

  storageBayOptions: [],
  storageBayIsLoading: false,

  quantBalOptions: [],
  quantBalIsLoading: false,

  uploadIsLoading: false
};
