import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Typography, Row, Col, Card, Modal, Select, Breadcrumb } from 'antd';
import HeaderForm from './HeaderForm';
import DetailTable from './DetailTable';
import WhseJobType11Form from './WhseJobType11Form';
import WhseJobType13Form from './WhseJobType13Form';
import PutAwayDetailActions from '../../Stores/PutAwayDetail/Actions';

const { Option } = Select;
const { Text } = Typography;

class PutAwayDetailScreen extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    const { match, setHdrId } = this.props;

    if (match.params.action === 'create') {
      setHdrId(0);
    } else if (match.params.action === 'update') {
      setHdrId(parseInt(match.params.id, 10));
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setDetailVisible, documentIsLoading } = this.props;

    if (documentIsLoading === false) {
      setDetailVisible(false);
    }
  }

  handleSelectChange(value) {
    const { setWhseJobType } = this.props;

    setWhseJobType(value);
  }

  render() {
    const { intl, match, detailIsVisible, whseJobType, documentHeader, appPath } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;

    return (
      <Card title={intl.formatMessage({ id: 'put_away' })}>
        <Breadcrumb style={{ overflowWrap: 'break-word' }}>
          {documentHeader.doc_flows.map(docData =>
            docData.doc_id > 0 ? (
              <Breadcrumb.Item key={docData.doc_id}>
                {docData.is_current === true ? (
                  docData.doc_code
                ) : (
                  <Link replace to={`${appPath}/${docData.action}/update/${docData.doc_id}`}>
                    <span style={{ color: 'blue' }}>{docData.doc_code}</span>
                  </Link>
                )}
              </Breadcrumb.Item>
            ) : (
              ''
            )
          )}
        </Breadcrumb>
        <HeaderForm match={match} />
        <DetailTable />
        <Modal
          visible={detailIsVisible}
          title={intl.formatMessage({ id: 'put_away_detail' })}
          // style={{top:20}}
          width="80%"
          centered
          forceRender
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={3}>
              <Text>{intl.formatMessage({ id: 'type' })}</Text>
            </Col>
            <Col span={1}>
              <Text>:</Text>
            </Col>
            <Col span={20}>
              <Select
                value={whseJobType}
                onChange={this.handleSelectChange}
                style={{ width: 350 }}
                disabled={disabled}
              >
                <Option value={0}>{intl.formatMessage({ id: 'please_select_type' })}</Option>
                <Option value={11}>{intl.formatMessage({ id: 'put_away_pallet' })}</Option>
                <Option value={13}>{intl.formatMessage({ id: 'put_away_loose' })}</Option>
              </Select>
            </Col>
          </Row>
          {whseJobType === 11 && <WhseJobType11Form match={match} />}
          {whseJobType === 13 && <WhseJobType13Form match={match} />}
        </Modal>
      </Card>
    );
  }
}

PutAwayDetailScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  setHdrId: PropTypes.func,
  setDetailVisible: PropTypes.func,
  setWhseJobType: PropTypes.func,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number,
    doc_flows: PropTypes.arrayOf(PropTypes.object)
  }),
  documentIsLoading: PropTypes.bool,
  detailIsVisible: PropTypes.bool,

  whseJobType: PropTypes.number,
  appPath: PropTypes.string
};

PutAwayDetailScreen.defaultProps = {
  intl: {},
  match: {},
  setHdrId() {},
  setDetailVisible() {},
  setWhseJobType() {},
  documentHeader: { doc_status: 3, doc_flows: [] },
  documentIsLoading: false,
  detailIsVisible: false,

  whseJobType: 0,
  appPath: ''
};

const mapStateToProps = state => ({
  documentHeader: state.putAwayDetail.documentHeader,
  documentIsLoading: state.putAwayDetail.documentIsLoading,
  detailIsVisible: state.putAwayDetail.detailIsVisible,

  whseJobType: state.putAwayDetail.whseJobType,
  appPath: state.app.appPath
});

const mapDispatchToProps = dispatch => ({
  setHdrId: hdrId => dispatch(PutAwayDetailActions.putAwayDetailSetHdrId(hdrId)),
  setDetailVisible: boolean =>
    dispatch(PutAwayDetailActions.putAwayDetailSetDetailVisible(boolean)),

  setWhseJobType: whseJobType =>
    dispatch(PutAwayDetailActions.putAwayDetailSetWhseJobType(whseJobType))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PutAwayDetailScreen));
