import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  pickListReservedStockReportInitPickListReservedStock: null,
  pickListReservedStockReportInitPickListReservedStockSuccess: ['criteria'],
  pickListReservedStockReportPickListReservedStock: ['formikBag', 'criteria'],
  pickListReservedStockReportPickListReservedStockSuccess: ['criteria', 'reportData'],
  pickListReservedStockReportReportLoading: ['boolean'],

  pickListReservedStockReportFetchItemOptions: ['search'],
  pickListReservedStockReportFetchItemOptionLoading: ['boolean'],
  pickListReservedStockReportFetchItemOptionSuccess: ['options'],

  pickListReservedStockReportFetchItemGroup01Options: ['search'],
  pickListReservedStockReportFetchItemGroup01OptionLoading: ['boolean'],
  pickListReservedStockReportFetchItemGroup01OptionSuccess: ['options'],

  pickListReservedStockReportFetchItemGroup02Options: ['search'],
  pickListReservedStockReportFetchItemGroup02OptionLoading: ['boolean'],
  pickListReservedStockReportFetchItemGroup02OptionSuccess: ['options'],

  pickListReservedStockReportFetchItemGroup03Options: ['search'],
  pickListReservedStockReportFetchItemGroup03OptionLoading: ['boolean'],
  pickListReservedStockReportFetchItemGroup03OptionSuccess: ['options'],

  pickListReservedStockReportFetchStorageBinOptions: ['search'],
  pickListReservedStockReportFetchStorageBinOptionLoading: ['boolean'],
  pickListReservedStockReportFetchStorageBinOptionSuccess: ['options'],

  pickListReservedStockReportFetchStorageRowOptions: ['search'],
  pickListReservedStockReportFetchStorageRowOptionLoading: ['boolean'],
  pickListReservedStockReportFetchStorageRowOptionSuccess: ['options'],

  pickListReservedStockReportFetchStorageBayOptions: ['search'],
  pickListReservedStockReportFetchStorageBayOptionLoading: ['boolean'],
  pickListReservedStockReportFetchStorageBayOptionSuccess: ['options'],

  pickListReservedStockReportFetchLocationOptions: ['search'],
  pickListReservedStockReportFetchLocationOptionLoading: ['boolean'],
  pickListReservedStockReportFetchLocationOptionSuccess: ['options']
});

export const PickListReservedStockReportTypes = Types;
export default Creators;
