import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import WorkerPackListAnalysisReportActions, {
  WorkerPackListAnalysisReportTypes
} from '../Stores/WorkerPackListAnalysisReport/Actions';

const getAppStore = state => state.app;

export function* workerPackListAnalysisReportInitWorkerPackListAnalysis() {
  try {
    yield put(WorkerPackListAnalysisReportActions.workerPackListAnalysisReportReportLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `workerReport/initWorkerPackListAnalysis/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        WorkerPackListAnalysisReportActions.workerPackListAnalysisReportInitWorkerPackListAnalysisSuccess(
          result.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WorkerPackListAnalysisReportActions.workerPackListAnalysisReportReportLoading(false));
  }
}

export function* workerPackListAnalysisReportWorkerPackListAnalysis({ formikBag, criteria }) {
  try {
    formikBag.setSubmitting(true);
    yield put(WorkerPackListAnalysisReportActions.workerPackListAnalysisReportReportLoading(true));

    const objCriteria = {};
    if ('start_date' in criteria) {
      objCriteria.start_date = criteria.start_date;
    }
    if ('end_date' in criteria) {
      objCriteria.end_date = criteria.end_date;
    }
    if ('company_ids_select2' in criteria) {
      const values = criteria.company_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.company_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('location_ids_select2' in criteria) {
      const values = criteria.location_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.location_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    let columns = [];
    if (criteria.columns.target.length > 0) {
      columns = criteria.columns.target.map(entry => {
        return entry.column;
      });
    }

    const app = yield select(getAppStore);
    const postData = {
      sorts: [],
      criteria: objCriteria,
      columns
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `workerReport/workerPackListAnalysis/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(
        WorkerPackListAnalysisReportActions.workerPackListAnalysisReportWorkerPackListAnalysisSuccess(
          criteria,
          result.data.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(WorkerPackListAnalysisReportActions.workerPackListAnalysisReportReportLoading(false));
  }
}

export function* workerPackListAnalysisReportFetchLocationOptions({ search }) {
  try {
    yield put(
      WorkerPackListAnalysisReportActions.workerPackListAnalysisReportFetchLocationOptionLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `location/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        WorkerPackListAnalysisReportActions.workerPackListAnalysisReportFetchLocationOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      WorkerPackListAnalysisReportActions.workerPackListAnalysisReportFetchLocationOptionLoading(
        false
      )
    );
  }
}

export function* workerPackListAnalysisReportFetchCompanyOptions({ search }) {
  try {
    yield put(
      WorkerPackListAnalysisReportActions.workerPackListAnalysisReportFetchCompanyOptionLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `company/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} - ${d.name_01}`
      }));

      yield put(
        WorkerPackListAnalysisReportActions.workerPackListAnalysisReportFetchCompanyOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      WorkerPackListAnalysisReportActions.workerPackListAnalysisReportFetchCompanyOptionLoading(
        false
      )
    );
  }
}

export const saga = [
  takeLatest(
    WorkerPackListAnalysisReportTypes.WORKER_PACK_LIST_ANALYSIS_REPORT_INIT_WORKER_PACK_LIST_ANALYSIS,
    workerPackListAnalysisReportInitWorkerPackListAnalysis
  ),
  takeLatest(
    WorkerPackListAnalysisReportTypes.WORKER_PACK_LIST_ANALYSIS_REPORT_WORKER_PACK_LIST_ANALYSIS,
    workerPackListAnalysisReportWorkerPackListAnalysis
  ),
  takeLatest(
    WorkerPackListAnalysisReportTypes.WORKER_PACK_LIST_ANALYSIS_REPORT_FETCH_LOCATION_OPTIONS,
    workerPackListAnalysisReportFetchLocationOptions
  ),
  takeLatest(
    WorkerPackListAnalysisReportTypes.WORKER_PACK_LIST_ANALYSIS_REPORT_FETCH_COMPANY_OPTIONS,
    workerPackListAnalysisReportFetchCompanyOptions
  )
];
