/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LspAdvShipExcel01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const lspAdvShipExcel01SetStrProcType = (state, { strProcType }) => ({
  ...state,
  strProcType
});

export const lspAdvShipExcel01ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const lspAdvShipExcel01UploadLoading = (state, { boolean }) => ({
  ...state,
  uploadIsLoading: boolean
});

export const lspAdvShipExcel01FetchDebtorOptionLoading = (state, { boolean }) => ({
  ...state,
  debtorIsLoading: boolean
});

export const lspAdvShipExcel01FetchDebtorOptionSuccess = (state, { options }) => ({
  ...state,
  debtorOptions: options
});

export const lspAdvShipExcel01FetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const lspAdvShipExcel01FetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LspAdvShipExcel01Types.LSP_ADV_SHIP_EXCEL01_SET_STR_PROC_TYPE]: lspAdvShipExcel01SetStrProcType,
  [LspAdvShipExcel01Types.LSP_ADV_SHIP_EXCEL01_SHOW_BATCH_JOB_STATUS_SUCCESS]: lspAdvShipExcel01ShowBatchJobStatusSuccess,
  [LspAdvShipExcel01Types.LSP_ADV_SHIP_EXCEL01_UPLOAD_LOADING]: lspAdvShipExcel01UploadLoading,

  [LspAdvShipExcel01Types.LSP_ADV_SHIP_EXCEL01_FETCH_DEBTOR_OPTION_LOADING]: lspAdvShipExcel01FetchDebtorOptionLoading,
  [LspAdvShipExcel01Types.LSP_ADV_SHIP_EXCEL01_FETCH_DEBTOR_OPTION_SUCCESS]: lspAdvShipExcel01FetchDebtorOptionSuccess,

  [LspAdvShipExcel01Types.LSP_ADV_SHIP_EXCEL01_FETCH_PROJECT_OPTION_LOADING]: lspAdvShipExcel01FetchProjectOptionLoading,
  [LspAdvShipExcel01Types.LSP_ADV_SHIP_EXCEL01_FETCH_PROJECT_OPTION_SUCCESS]: lspAdvShipExcel01FetchProjectOptionSuccess
});
