/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PackListDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const packListDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const packListDetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false
});

export const packListDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const packListDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const packListDetailUpdateDocumentSuccess = (
  state,
  { documentHeader, documentDetails }
) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const packListDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const packListDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const packListDetailSetWhseJobType = (state, { whseJobType }) => ({
  ...state,
  whseJobType
});

export const packListDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  whseJobType: documentDetail.whse_job_type,
  companyOptions: [],
  frStorageBinOptions: [],
  quantBalOptions: [],
  uomOptions: [],
  toStorageBinOptions: []
});

export const packListDetailFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const packListDetailFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const packListDetailFetchFrStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  frStorageBinIsLoading: boolean
});

export const packListDetailFetchFrStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  frStorageBinOptions: options
});

export const packListDetailFetchQuantBalOptionLoading = (state, { boolean }) => ({
  ...state,
  quantBalIsLoading: boolean
});

export const packListDetailFetchQuantBalOptionSuccess = (state, { options }) => ({
  ...state,
  quantBalOptions: options
});

export const packListDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const packListDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

export const packListDetailFetchToStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  toStorageBinIsLoading: boolean
});

export const packListDetailFetchToStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  toStorageBinOptions: options
});

// force refresh the formik form
export const packListDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const packListDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PackListDetailTypes.PACK_LIST_DETAIL_RESET_TIMESTAMP]: packListDetailResetTimestamp,
  [PackListDetailTypes.PACK_LIST_DETAIL_SET_HDR_ID]: packListDetailSetHdrId,

  [PackListDetailTypes.PACK_LIST_DETAIL_SHOW_HEADER_SUCCESS]: packListDetailShowHeaderSuccess,

  [PackListDetailTypes.PACK_LIST_DETAIL_UPDATE_DOCUMENT_SUCCESS]: packListDetailUpdateDocumentSuccess,

  [PackListDetailTypes.PACK_LIST_DETAIL_SHOW_DOCUMENT_LOADING]: packListDetailShowDocumentLoading,

  [PackListDetailTypes.PACK_LIST_DETAIL_SHOW_DETAILS_SUCCESS]: packListDetailShowDetailsSuccess,

  [PackListDetailTypes.PACK_LIST_DETAIL_SET_DETAIL_VISIBLE]: packListDetailSetDetailVisible,
  [PackListDetailTypes.PACK_LIST_DETAIL_SET_DOCUMENT_DETAIL]: packListDetailSetDocumentDetail,

  [PackListDetailTypes.PACK_LIST_DETAIL_UPDATE_DETAILS]: packListDetailUpdateDetails,
  [PackListDetailTypes.PACK_LIST_DETAIL_CREATE_DETAIL]: packListDetailCreateDetail,

  [PackListDetailTypes.PACK_LIST_DETAIL_SET_WHSE_JOB_TYPE]: packListDetailSetWhseJobType,

  [PackListDetailTypes.PACK_LIST_DETAIL_FETCH_COMPANY_OPTION_LOADING]: packListDetailFetchCompanyOptionLoading,
  [PackListDetailTypes.PACK_LIST_DETAIL_FETCH_COMPANY_OPTION_SUCCESS]: packListDetailFetchCompanyOptionSuccess,

  [PackListDetailTypes.PACK_LIST_DETAIL_FETCH_FR_STORAGE_BIN_OPTION_LOADING]: packListDetailFetchFrStorageBinOptionLoading,
  [PackListDetailTypes.PACK_LIST_DETAIL_FETCH_FR_STORAGE_BIN_OPTION_SUCCESS]: packListDetailFetchFrStorageBinOptionSuccess,

  [PackListDetailTypes.PACK_LIST_DETAIL_FETCH_QUANT_BAL_OPTION_LOADING]: packListDetailFetchQuantBalOptionLoading,
  [PackListDetailTypes.PACK_LIST_DETAIL_FETCH_QUANT_BAL_OPTION_SUCCESS]: packListDetailFetchQuantBalOptionSuccess,

  [PackListDetailTypes.PACK_LIST_DETAIL_FETCH_UOM_OPTION_LOADING]: packListDetailFetchUomOptionLoading,
  [PackListDetailTypes.PACK_LIST_DETAIL_FETCH_UOM_OPTION_SUCCESS]: packListDetailFetchUomOptionSuccess,

  [PackListDetailTypes.PACK_LIST_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_LOADING]: packListDetailFetchToStorageBinOptionLoading,
  [PackListDetailTypes.PACK_LIST_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_SUCCESS]: packListDetailFetchToStorageBinOptionSuccess
});
