import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  prodIssIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prodIssIndexFetchProdIssIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prodIssIndexFetchProdIssIndexLoading: ['boolean'],
  prodIssIndexFetchProdIssIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  prodIssIndexAddSelectedDocuments: ['selectedDocuments'],
  prodIssIndexRemoveSelectedDocuments: ['selectedDocuments'],
  prodIssIndexSetWorkspaceVisible: ['boolean'],
  prodIssIndexGoToDocument: ['hdrId'],
  prodIssIndexGoToAudit: ['hdrId'],

  prodIssIndexWorkspaceLoading: ['boolean'],
  prodIssIndexResetProdIssFServ01: ['hdrIds'],
  prodIssIndexResetProdIssFServ01Success: null
});

export const ProdIssIndexTypes = Types;
export default Creators;
