import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import BinTrfDetailActions, { BinTrfDetailTypes } from '../Stores/BinTrfDetail/Actions';

const getAppStore = state => state.app;

const getBinTrfDetailStore = state => state.binTrfDetail;

export function* binTrfDetailInitHeader() {
  try {
    yield put(BinTrfDetailActions.binTrfDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `binTrf/initHeader/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(BinTrfDetailActions.binTrfDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrfDetailActions.binTrfDetailShowDocumentLoading(false));
  }
}

export function* binTrfDetailShowHeader({ hdrId }) {
  try {
    yield put(BinTrfDetailActions.binTrfDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `binTrf/showHeader/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(BinTrfDetailActions.binTrfDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    // yield put(BinTrfDetailActions.binTrfDetailShowDocumentLoading(false));
  }
}

export function* binTrfDetailTransitionToStatus({ formikBag, hdrId, docStatus }) {
  formikBag.setSubmitting(true);
  yield put(BinTrfDetailActions.binTrfDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      hdrId,
      docStatus
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'binTrf/transitionToStatus',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const binTrfDetail = yield select(getBinTrfDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = binTrfDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        BinTrfDetailActions.binTrfDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(BinTrfDetailActions.binTrfDetailShowDocumentLoading(false));
  }
}

export function* binTrfDetailUpdateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'binTrf/updateHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const binTrfDetail = yield select(getBinTrfDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = binTrfDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        BinTrfDetailActions.binTrfDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* binTrfDetailShowDetails({ hdrId }) {
  try {
    yield put(BinTrfDetailActions.binTrfDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `binTrf/showDetails/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(BinTrfDetailActions.binTrfDetailShowDetailsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrfDetailActions.binTrfDetailShowDocumentLoading(false));
  }
}

export function* binTrfDetailUpdateDetails({ formikBag, hdrId, documentDetails }) {
  formikBag.setSubmitting(true);
  yield put(BinTrfDetailActions.binTrfDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetails
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `binTrf/updateDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const binTrfDetail = yield select(getBinTrfDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = binTrfDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        BinTrfDetailActions.binTrfDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(BinTrfDetailActions.binTrfDetailShowDocumentLoading(false));
  }
}

export function* binTrfDetailFetchItemOptions({ search }) {
  try {
    yield put(BinTrfDetailActions.binTrfDetailFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(BinTrfDetailActions.binTrfDetailFetchItemOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrfDetailActions.binTrfDetailFetchItemOptionLoading(false));
  }
}

export function* binTrfDetailChangeItem({ formikBag, hdrId, itemId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `binTrf/changeItem`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });

      // reset the select2 cache
      formikBag.setFieldValue('uom_select2', {
        value: 0,
        label: ''
      });
      formikBag.setFieldValue('uom_rate', 1);

      yield put(BinTrfDetailActions.binTrfDetailFetchUomOptionSuccess([]));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* binTrfDetailFetchItemBatchOptions({ itemId, search }) {
  try {
    yield put(BinTrfDetailActions.binTrfDetailFetchItemBatchOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemBatch/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.batch_serial_no} | ${d.expiry_date} | ${d.receipt_date}`
      }));

      yield put(BinTrfDetailActions.binTrfDetailFetchItemBatchOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrfDetailActions.binTrfDetailFetchItemBatchOptionLoading(false));
  }
}

export function* binTrfDetailChangeItemBatch({ formikBag, hdrId, itemBatchId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemBatchId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `binTrf/changeItemBatch`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* binTrfDetailFetchUomOptions({ itemId, search }) {
  try {
    yield put(BinTrfDetailActions.binTrfDetailFetchUomOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemUom/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.uom_id, label: `${d.uom_code}` }));

      yield put(BinTrfDetailActions.binTrfDetailFetchUomOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrfDetailActions.binTrfDetailFetchUomOptionLoading(false));
  }
}

export function* binTrfDetailChangeUom({ formikBag, hdrId, itemId, uomId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId,
      uomId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `binTrf/changeItemUom`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* binTrfDetailFetchFrStorageBinOptions({ whseJobType, search }) {
  try {
    yield put(BinTrfDetailActions.binTrfDetailFetchFrStorageBinOptionLoading(true));

    let binTypes = [];
    if (whseJobType === 16) {
      binTypes = [
        1, // UNLOADING_AREA
        4, // BULK_STORAGE
        5, // PALLET_STORAGE
        6 // BROKEN_PALLET_STORAGE
      ];
    } else if (whseJobType === 17) {
      binTypes = [
        4, // BULK_STORAGE
        5, // PALLET_STORAGE
        6 // BROKEN_PALLET_STORAGE
      ];
    } else if (whseJobType === 18) {
      binTypes = [
        4, // BULK_STORAGE
        5, // PALLET_STORAGE
        6 // BROKEN_PALLET_STORAGE
      ];
    } else if (whseJobType === 19) {
      binTypes = [
        7, // CASE_STORAGE
        8 // BROKEN_CASE_STORAGE
      ];
    } else if (whseJobType === 20) {
      binTypes = [
        4, // BULK_STORAGE
        5, // PALLET_STORAGE
        6 // BROKEN_PALLET_STORAGE
      ];
    } else if (whseJobType === 23) {
      binTypes = [
        7, // CASE_STORAGE
        8 // BROKEN_CASE_STORAGE
      ];
    }

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: [
        {
          field: 'bin_types',
          value: binTypes
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(BinTrfDetailActions.binTrfDetailFetchFrStorageBinOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrfDetailActions.binTrfDetailFetchFrStorageBinOptionLoading(false));
  }
}

export function* binTrfDetailFetchToStorageBinOptions({ whseJobType, search }) {
  try {
    yield put(BinTrfDetailActions.binTrfDetailFetchToStorageBinOptionLoading(true));

    let binTypes = [];
    if (whseJobType === 16) {
      binTypes = [
        1, // UNLOADING_AREA
        4, // BULK_STORAGE
        5, // PALLET_STORAGE
        6 // BROKEN_PALLET_STORAGE
      ];
    } else if (whseJobType === 17) {
      binTypes = [
        4, // BULK_STORAGE
        5, // PALLET_STORAGE
        6 // BROKEN_PALLET_STORAGE
      ];
    } else if (whseJobType === 18) {
      binTypes = [
        7, // CASE_STORAGE
        8 // BROKEN_CASE_STORAGE
      ];
    } else if (whseJobType === 19) {
      binTypes = [
        7, // CASE_STORAGE
        8 // BROKEN_CASE_STORAGE
      ];
    } else if (whseJobType === 20) {
      binTypes = [
        7, // CASE_STORAGE
        8 // BROKEN_CASE_STORAGE];
      ];
    } else if (whseJobType === 23) {
      binTypes = [
        4, // BULK_STORAGE
        5, // PALLET_STORAGE
        6 // BROKEN_PALLET_STORAGE
      ];
    }

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: [
        {
          field: 'bin_types',
          value: binTypes
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(BinTrfDetailActions.binTrfDetailFetchToStorageBinOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrfDetailActions.binTrfDetailFetchToStorageBinOptionLoading(false));
  }
}

export function* binTrfDetailFetchHandlingUnitOptions({ search }) {
  try {
    yield put(BinTrfDetailActions.binTrfDetailFetchHandlingUnitOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'site_flow_id',
          value: app.curSiteFlowId
        },
        {
          field: 'status',
          value: 4 // NEW
        },
        {
          field: 'handling_type',
          value: 1 // PALLET
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `handlingUnit/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.barcode}` }));

      yield put(BinTrfDetailActions.binTrfDetailFetchHandlingUnitOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrfDetailActions.binTrfDetailFetchHandlingUnitOptionLoading(false));
  }
}

export function* binTrfDetailCreateDetail({ formikBag, hdrId, documentDetail }) {
  formikBag.setSubmitting(true);
  yield put(BinTrfDetailActions.binTrfDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetail
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `binTrf/createDetail/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const binTrfDetail = yield select(getBinTrfDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = binTrfDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        BinTrfDetailActions.binTrfDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );

      yield put(BinTrfDetailActions.binTrfDetailSetDocumentDetail(binTrfDetail.initDocumentDetail));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(BinTrfDetailActions.binTrfDetailShowDocumentLoading(false));
  }
}

export function* binTrfDetailDeleteDetail({ hdrId, documentDetail }) {
  yield put(BinTrfDetailActions.binTrfDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: [{ id: documentDetail.id }]
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `binTrf/deleteDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const binTrfDetail = yield select(getBinTrfDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = binTrfDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        BinTrfDetailActions.binTrfDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrfDetailActions.binTrfDetailShowDocumentLoading(false));
  }
}

export function* binTrfDetailCreateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'binTrf/createHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/binTrfDetail/update/${result.data}`));
      yield put(BinTrfDetailActions.binTrfDetailSetHdrId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* binTrfDetailFetchStorageRowOptions({ search }) {
  try {
    yield put(BinTrfDetailActions.binTrfDetailFetchStorageRowOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageRow/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code} ${d.desc_01}` }));

      yield put(BinTrfDetailActions.binTrfDetailFetchStorageRowOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrfDetailActions.binTrfDetailFetchStorageRowOptionLoading(false));
  }
}

export function* binTrfDetailFetchStorageBayOptions({ search }) {
  try {
    yield put(BinTrfDetailActions.binTrfDetailFetchStorageBayOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBay/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code} ${d.desc_01}` }));

      yield put(BinTrfDetailActions.binTrfDetailFetchStorageBayOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrfDetailActions.binTrfDetailFetchStorageBayOptionLoading(false));
  }
}

export function* binTrfDetailFetchQuantBalOptions({ storageBinId, search }) {
  try {
    yield put(BinTrfDetailActions.binTrfDetailFetchQuantBalOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'storage_bin_id',
          value: storageBinId
        },
        {
          field: 'balance_unit_qty',
          operator: '>',
          value: 0
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `quantBal/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.item_code} | ${d.handling_unit_barcode} EXP: ${d.expiry_date} BAL: ${d.case_qty} ${d.item_case_uom_code} ${d.loose_qty} ${d.item_loose_uom_code} RSVD: (${d.reserved_case_qty} ${d.item_case_uom_code} ${d.reserved_loose_qty} ${d.item_loose_uom_code})`
      }));

      yield put(BinTrfDetailActions.binTrfDetailFetchQuantBalOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrfDetailActions.binTrfDetailFetchQuantBalOptionLoading(false));
  }
}

export function* binTrfDetailChangeQuantBal({ formikBag, hdrId, quantBalId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      quantBalId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `binTrf/changeQuantBal`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });

      // reset the select2 cache
      formikBag.setFieldValue('uom_select2', {
        value: 0,
        label: ''
      });
      formikBag.setFieldValue('uom_rate', 1);

      yield put(BinTrfDetailActions.binTrfDetailFetchUomOptionSuccess([]));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* binTrfDetailFetchToHandlingUnitOptions({ storageBinId, search }) {
  try {
    yield put(BinTrfDetailActions.binTrfDetailFetchToHandlingUnitOptionLoading(true));

    const app = yield select(getAppStore);

    let filters = [
      {
        field: 'site_flow_id',
        value: app.curSiteFlowId
      },
      {
        field: 'handling_type',
        value: 1 // PALLET
      }
    ];
    if (storageBinId > 0) {
      filters = [
        {
          field: 'storage_bin_id',
          value: storageBinId
        }
      ];
    }
    const postData = {
      search,
      filters
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `handlingUnit/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.barcode}` }));

      yield put(BinTrfDetailActions.binTrfDetailFetchToHandlingUnitOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrfDetailActions.binTrfDetailFetchToHandlingUnitOptionLoading(false));
  }
}

export function* binTrfDetailUploadExcel({ hdrId, file }) {
  try {
    yield put(BinTrfDetailActions.binTrfDetailUploadLoading(true));

    const app = yield select(getAppStore);

    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file', file);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `binTrf/uploadDetails/${hdrId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield put(BinTrfDetailActions.binTrfDetailResetTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrfDetailActions.binTrfDetailUploadLoading(false));
  }
}

export function* binTrfDetailDownloadExcel({ hdrId }) {
  try {
    yield put(BinTrfDetailActions.binTrfDetailUploadLoading(true));

    const app = yield select(getAppStore);

    const getData = {};

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `binTrf/downloadDetails/${hdrId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      const binTrfDetail = yield select(getBinTrfDetailStore);

      const { documentHeader } = binTrfDetail;

      FileSaver.saveAs(result.data, `BIN_TRF_${documentHeader.doc_code}.XLSX`);
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(BinTrfDetailActions.binTrfDetailUploadLoading(false));
  }
}

export const saga = [
  takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_INIT_HEADER, binTrfDetailInitHeader),
  takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_SHOW_HEADER, binTrfDetailShowHeader),

  takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_UPDATE_HEADER, binTrfDetailUpdateHeader),
  takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_CREATE_HEADER, binTrfDetailCreateHeader),

  takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_TRANSITION_TO_STATUS, binTrfDetailTransitionToStatus),

  takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_SHOW_DETAILS, binTrfDetailShowDetails),

  takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_UPDATE_DETAILS, binTrfDetailUpdateDetails),
  takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_DELETE_DETAIL, binTrfDetailDeleteDetail),
  takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_CREATE_DETAIL, binTrfDetailCreateDetail),

  takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_ITEM_OPTIONS, binTrfDetailFetchItemOptions),
  takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_CHANGE_ITEM, binTrfDetailChangeItem),

  takeLatest(
    BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_ITEM_BATCH_OPTIONS,
    binTrfDetailFetchItemBatchOptions
  ),
  takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_CHANGE_ITEM_BATCH, binTrfDetailChangeItemBatch),

  takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_UOM_OPTIONS, binTrfDetailFetchUomOptions),
  takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_CHANGE_UOM, binTrfDetailChangeUom),

  takeLatest(
    BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_FR_STORAGE_BIN_OPTIONS,
    binTrfDetailFetchFrStorageBinOptions
  ),

  takeLatest(
    BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_TO_STORAGE_BIN_OPTIONS,
    binTrfDetailFetchToStorageBinOptions
  ),
  takeLatest(
    BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_TO_HANDLING_UNIT_OPTIONS,
    binTrfDetailFetchToHandlingUnitOptions
  ),

  takeLatest(
    BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_HANDLING_UNIT_OPTIONS,
    binTrfDetailFetchHandlingUnitOptions
  ),

  takeLatest(
    BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_STORAGE_ROW_OPTIONS,
    binTrfDetailFetchStorageRowOptions
  ),
  takeLatest(
    BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_STORAGE_BAY_OPTIONS,
    binTrfDetailFetchStorageBayOptions
  ),
  takeLatest(
    BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_QUANT_BAL_OPTIONS,
    binTrfDetailFetchQuantBalOptions
  ),
  takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_CHANGE_QUANT_BAL, binTrfDetailChangeQuantBal),
  takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_UPLOAD_EXCEL, binTrfDetailUploadExcel),
  takeLatest(BinTrfDetailTypes.BIN_TRF_DETAIL_DOWNLOAD_EXCEL, binTrfDetailDownloadExcel)
];
