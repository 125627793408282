import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsRtnIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  slsRtnIndexFetchSlsRtnIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  slsRtnIndexFetchSlsRtnIndexLoading: ['boolean'],
  slsRtnIndexFetchSlsRtnIndexSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  slsRtnIndexAddSelectedDocuments: ['selectedDocuments'],
  slsRtnIndexRemoveSelectedDocuments: ['selectedDocuments'],
  slsRtnIndexSetWorkspaceVisible: ['boolean'],
  slsRtnIndexGoToDocument: ['hdrId'],
  slsRtnIndexGoToAudit: ['hdrId'],

  slsRtnIndexWorkspaceLoading: ['boolean'],
  slsRtnIndexResetSlsRtnFServ02: ['hdrIds'],
  slsRtnIndexResetSlsRtnFServ02Success: null
});

export const SlsRtnIndexTypes = Types;
export default Creators;
