import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stkIssIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkIssIndexFetchStkIssIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkIssIndexFetchStkIssIndexLoading: ['boolean'],
  stkIssIndexFetchStkIssIndexSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  stkIssIndexAddSelectedDocuments: ['selectedDocuments'],
  stkIssIndexRemoveSelectedDocuments: ['selectedDocuments'],
  stkIssIndexSetWorkspaceVisible: ['boolean'],
  stkIssIndexGoToPrint: ['document'],
  stkIssIndexGoToDocument: ['hdrId'],
  stkIssIndexGoToAudit: ['hdrId'],

  stkIssIndexWorkspaceLoading: ['boolean'],
  stkIssIndexResetStkIssFServ01: ['hdrIds'],
  stkIssIndexResetStkIssFServ01Success: null
});

export const StkIssIndexTypes = Types;
export default Creators;
