import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  areaDetailResetTimestamp: null,
  areaDetailSetResId: ['resId'],

  areaDetailInitModel: null,
  areaDetailShowModel: ['resId'],
  areaDetailShowModelSuccess: ['model'],

  areaDetailUpdateModel: ['formikBag', 'model'],
  areaDetailCreateModel: ['formikBag', 'model'],
  areaDetailUpdateModelSuccess: ['model'],

  areaDetailShowModelLoading: ['boolean']
});

export const AreaDetailTypes = Types;
export default Creators;
