/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { GdsRcptIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const gdsRcptIndexResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  toStorageBinOptions: INITIAL_STATE.toStorageBinOptions,
  toStorageBinOption: INITIAL_STATE.toStorageBinOption
});

export const gdsRcptIndexFetchGdsRcptIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const gdsRcptIndexFetchGdsRcptIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const gdsRcptIndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const gdsRcptIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const gdsRcptIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const gdsRcptIndexWorkspaceLoading = (state, { boolean }) => ({
  ...state,
  workspaceIsLoading: boolean
});

export const gdsRcptIndexResetGdsRcptFServ01Success = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const gdsRcptIndexSetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

export const gdsRcptIndexFetchExpandedLoading = (state, { boolean }) => ({
  ...state,
  expandedIsLoading: boolean
});

export const gdsRcptIndexFetchExpandedSuccess = (state, { details }) => ({
  ...state,
  expandedDetails: details
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GdsRcptIndexTypes.GDS_RCPT_INDEX_RESET_TIMESTAMP]: gdsRcptIndexResetTimestamp,
  [GdsRcptIndexTypes.GDS_RCPT_INDEX_FETCH_GDS_RCPT_INDEX_LOADING]: gdsRcptIndexFetchGdsRcptIndexLoading,
  [GdsRcptIndexTypes.GDS_RCPT_INDEX_FETCH_GDS_RCPT_INDEX_SUCCESS]: gdsRcptIndexFetchGdsRcptIndexSuccess,
  [GdsRcptIndexTypes.GDS_RCPT_INDEX_ADD_SELECTED_DOCUMENTS]: gdsRcptIndexAddSelectedDocuments,
  [GdsRcptIndexTypes.GDS_RCPT_INDEX_REMOVE_SELECTED_DOCUMENTS]: gdsRcptIndexRemoveSelectedDocuments,
  [GdsRcptIndexTypes.GDS_RCPT_INDEX_SET_WORKSPACE_VISIBLE]: gdsRcptIndexSetWorkspaceVisible,
  [GdsRcptIndexTypes.GDS_RCPT_INDEX_WORKSPACE_LOADING]: gdsRcptIndexWorkspaceLoading,
  [GdsRcptIndexTypes.GDS_RCPT_INDEX_RESET_GDS_RCPT_F_SERV01_SUCCESS]: gdsRcptIndexResetGdsRcptFServ01Success,

  [GdsRcptIndexTypes.GDS_RCPT_INDEX_SET_EXPANDED_ROWS]: gdsRcptIndexSetExpandedRows,
  [GdsRcptIndexTypes.GDS_RCPT_INDEX_FETCH_EXPANDED_LOADING]: gdsRcptIndexFetchExpandedLoading,
  [GdsRcptIndexTypes.GDS_RCPT_INDEX_FETCH_EXPANDED_SUCCESS]: gdsRcptIndexFetchExpandedSuccess
});
