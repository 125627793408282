/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SlsOrdDetailBoardTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const slsOrdDetailBoardResetWipSlsOrdTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipSlsOrdCurrentPage: currentPage || state.wipSlsOrdCurrentPage,
  wipSlsOrdSorts: sorts || state.wipSlsOrdSorts,
  wipSlsOrdFilters: filters || state.wipSlsOrdFilters,
  wipSlsOrdPageSize: pageSize || state.wipSlsOrdPageSize,
  wipSlsOrdTimestamp: Date.now()
});

export const slsOrdDetailBoardFetchWipSlsOrdLoading = (state, { boolean }) => ({
  ...state,
  wipSlsOrdIsLoading: boolean
});

export const slsOrdDetailBoardFetchWipSlsOrdSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipSlsOrds: documents,
  wipSlsOrdCurrentPage: currentPage,
  wipSlsOrdLastPage: lastPage,
  wipSlsOrdTotal: total,
  wipSlsOrdPageSize: pageSize
});

export const slsOrdDetailBoardResetWipOutbOrdTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipOutbOrdCurrentPage: currentPage || state.wipOutbOrdCurrentPage,
  wipOutbOrdSorts: sorts || state.wipOutbOrdSorts,
  wipOutbOrdFilters: filters || state.wipOutbOrdFilters,
  wipOutbOrdPageSize: pageSize || state.wipOutbOrdPageSize,
  wipOutbOrdTimestamp: Date.now()
});

export const slsOrdDetailBoardFetchWipOutbOrdLoading = (state, { boolean }) => ({
  ...state,
  wipOutbOrdIsLoading: boolean
});

export const slsOrdDetailBoardFetchWipOutbOrdSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipOutbOrds: documents,
  wipOutbOrdCurrentPage: currentPage,
  wipOutbOrdLastPage: lastPage,
  wipOutbOrdTotal: total,
  wipOutbOrdPageSize: pageSize
});

export const slsOrdDetailBoardResetWipInvDocTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipInvDocCurrentPage: currentPage || state.wipInvDocCurrentPage,
  wipInvDocSorts: sorts || state.wipInvDocSorts,
  wipInvDocFilters: filters || state.wipInvDocFilters,
  wipInvDocPageSize: pageSize || state.wipInvDocPageSize,
  wipInvDocTimestamp: Date.now()
});

export const slsOrdDetailBoardFetchWipInvDocLoading = (state, { boolean }) => ({
  ...state,
  wipInvDocIsLoading: boolean
});

export const slsOrdDetailBoardFetchWipInvDocSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipInvDocs: documents,
  wipInvDocCurrentPage: currentPage,
  wipInvDocLastPage: lastPage,
  wipInvDocTotal: total,
  wipInvDocPageSize: pageSize
});

export const slsOrdDetailBoardResetCompleteLoadListTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  completeLoadListCurrentPage: currentPage || state.completeLoadListCurrentPage,
  completeLoadListSorts: sorts || state.completeLoadListSorts,
  completeLoadListFilters: filters || state.completeLoadListFilters,
  completeLoadListPageSize: pageSize || state.completeLoadListPageSize,
  completeLoadListTimestamp: Date.now()
});

export const slsOrdDetailBoardFetchCompleteLoadListLoading = (state, { boolean }) => ({
  ...state,
  completeLoadListIsLoading: boolean
});

export const slsOrdDetailBoardFetchCompleteLoadListSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  completeLoadLists: documents,
  completeLoadListCurrentPage: currentPage,
  completeLoadListLastPage: lastPage,
  completeLoadListTotal: total,
  completeLoadListPageSize: pageSize
});

export const slsOrdDetailBoardShowWipSlsOrdSummary = (state, { docId }) => ({
  ...state,
  wipSlsOrdDocId: docId,
  wipSlsOrdDialogIsVisible: true
});

export const slsOrdDetailBoardSetWipSlsOrdDialogVisible = (state, { boolean }) => ({
  ...state,
  wipSlsOrdDialogIsVisible: boolean
});

export const slsOrdDetailBoardShowWipSlsOrdSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipSlsOrdDocumentIsLoading: boolean
});

export const slsOrdDetailBoardShowWipSlsOrdSummarySuccess = (state, { document }) => ({
  ...state,
  wipSlsOrdDocument: document
});

export const slsOrdDetailBoardShowWipOutbOrdSummary = (state, { docId }) => ({
  ...state,
  wipOutbOrdDocId: docId,
  wipOutbOrdDialogIsVisible: true
});

export const slsOrdDetailBoardSetWipOutbOrdDialogVisible = (state, { boolean }) => ({
  ...state,
  wipOutbOrdDialogIsVisible: boolean
});

export const slsOrdDetailBoardShowWipOutbOrdSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipOutbOrdDocumentIsLoading: boolean
});

export const slsOrdDetailBoardShowWipOutbOrdSummarySuccess = (state, { document }) => ({
  ...state,
  wipOutbOrdDocument: document
});

export const slsOrdDetailBoardShowWipInvDocSummary = (state, { docId }) => ({
  ...state,
  wipInvDocDocId: docId,
  wipInvDocDialogIsVisible: true
});

export const slsOrdDetailBoardSetWipInvDocDialogVisible = (state, { boolean }) => ({
  ...state,
  wipInvDocDialogIsVisible: boolean
});

export const slsOrdDetailBoardShowWipInvDocSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipInvDocDocumentIsLoading: boolean
});

export const slsOrdDetailBoardShowWipInvDocSummarySuccess = (state, { document }) => ({
  ...state,
  wipInvDocDocument: document
});

export const slsOrdDetailBoardShowCompleteLoadListSummary = (state, { docId }) => ({
  ...state,
  completeLoadListDocId: docId,
  completeLoadListDialogIsVisible: true
});

export const slsOrdDetailBoardSetCompleteLoadListDialogVisible = (state, { boolean }) => ({
  ...state,
  completeLoadListDialogIsVisible: boolean
});

export const slsOrdDetailBoardShowCompleteLoadListSummaryLoading = (state, { boolean }) => ({
  ...state,
  completeLoadListDocumentIsLoading: boolean
});

export const slsOrdDetailBoardShowCompleteLoadListSummarySuccess = (state, { document }) => ({
  ...state,
  completeLoadListDocument: document
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_RESET_WIP_SLS_ORD_TIMESTAMP]: slsOrdDetailBoardResetWipSlsOrdTimestamp,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_FETCH_WIP_SLS_ORD_LOADING]: slsOrdDetailBoardFetchWipSlsOrdLoading,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_FETCH_WIP_SLS_ORD_SUCCESS]: slsOrdDetailBoardFetchWipSlsOrdSuccess,

  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_RESET_WIP_OUTB_ORD_TIMESTAMP]: slsOrdDetailBoardResetWipOutbOrdTimestamp,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_FETCH_WIP_OUTB_ORD_LOADING]: slsOrdDetailBoardFetchWipOutbOrdLoading,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_FETCH_WIP_OUTB_ORD_SUCCESS]: slsOrdDetailBoardFetchWipOutbOrdSuccess,

  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_RESET_WIP_INV_DOC_TIMESTAMP]: slsOrdDetailBoardResetWipInvDocTimestamp,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_FETCH_WIP_INV_DOC_LOADING]: slsOrdDetailBoardFetchWipInvDocLoading,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_FETCH_WIP_INV_DOC_SUCCESS]: slsOrdDetailBoardFetchWipInvDocSuccess,

  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_RESET_COMPLETE_LOAD_LIST_TIMESTAMP]: slsOrdDetailBoardResetCompleteLoadListTimestamp,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_FETCH_COMPLETE_LOAD_LIST_LOADING]: slsOrdDetailBoardFetchCompleteLoadListLoading,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_FETCH_COMPLETE_LOAD_LIST_SUCCESS]: slsOrdDetailBoardFetchCompleteLoadListSuccess,

  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SHOW_WIP_SLS_ORD_SUMMARY]: slsOrdDetailBoardShowWipSlsOrdSummary,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SET_WIP_SLS_ORD_DIALOG_VISIBLE]: slsOrdDetailBoardSetWipSlsOrdDialogVisible,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SHOW_WIP_SLS_ORD_SUMMARY_LOADING]: slsOrdDetailBoardShowWipSlsOrdSummaryLoading,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SHOW_WIP_SLS_ORD_SUMMARY_SUCCESS]: slsOrdDetailBoardShowWipSlsOrdSummarySuccess,

  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SHOW_WIP_OUTB_ORD_SUMMARY]: slsOrdDetailBoardShowWipOutbOrdSummary,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SET_WIP_OUTB_ORD_DIALOG_VISIBLE]: slsOrdDetailBoardSetWipOutbOrdDialogVisible,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SHOW_WIP_OUTB_ORD_SUMMARY_LOADING]: slsOrdDetailBoardShowWipOutbOrdSummaryLoading,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SHOW_WIP_OUTB_ORD_SUMMARY_SUCCESS]: slsOrdDetailBoardShowWipOutbOrdSummarySuccess,

  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SHOW_WIP_INV_DOC_SUMMARY]: slsOrdDetailBoardShowWipInvDocSummary,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SET_WIP_INV_DOC_DIALOG_VISIBLE]: slsOrdDetailBoardSetWipInvDocDialogVisible,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SHOW_WIP_INV_DOC_SUMMARY_LOADING]: slsOrdDetailBoardShowWipInvDocSummaryLoading,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SHOW_WIP_INV_DOC_SUMMARY_SUCCESS]: slsOrdDetailBoardShowWipInvDocSummarySuccess,

  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SHOW_COMPLETE_LOAD_LIST_SUMMARY]: slsOrdDetailBoardShowCompleteLoadListSummary,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SET_COMPLETE_LOAD_LIST_DIALOG_VISIBLE]: slsOrdDetailBoardSetCompleteLoadListDialogVisible,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SHOW_COMPLETE_LOAD_LIST_SUMMARY_LOADING]: slsOrdDetailBoardShowCompleteLoadListSummaryLoading,
  [SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SHOW_COMPLETE_LOAD_LIST_SUMMARY_SUCCESS]: slsOrdDetailBoardShowCompleteLoadListSummarySuccess
});
