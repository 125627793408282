import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import TrfToVanProcessActions from '../../Stores/TrfToVanProcess/Actions';

import OutbOrd07Table from '../OutbOrd07/OutbOrd07Table';
import StkRcpt03Table from '../StkRcpt03/StkRcpt03Table';
import StkTrfSync01Table from '../StkTrfSync01/StkTrfSync01Table';

const { TabPane } = Tabs;

class TrfToVanProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curDivisionId, fetchTrfToVanProcess } = this.props;
    if (curDivisionId > 0) {
      fetchTrfToVanProcess();
    }
  }

  componentDidUpdate(prevProps) {
    const { curDivisionId, fetchTrfToVanProcess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curDivisionId')) {
      if (curDivisionId > 0) {
        fetchTrfToVanProcess();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath, processIsLoading } = this.props;

    return (
      <Card>
        <Spin spinning={processIsLoading}>
          <Tabs type="card" activeKey={match.params.proc_type}>
            {process.map(value => (
              <TabPane
                tab={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                  >
                    <Link
                      style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                      to={`${appPath}/trfToVanProcess/index/${value.proc_type}`}
                    >
                      <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                    </Link>
                  </Tooltip>
                }
                key={value.proc_type}
              />
            ))}
          </Tabs>
        </Spin>

        {match.params.proc_type === 'OUTB_ORD_07' && <OutbOrd07Table match={match} />}
        {match.params.proc_type === 'STK_RCPT_03' && <StkRcpt03Table match={match} />}
        {match.params.proc_type === 'STK_TRF_SYNC_01' && <StkTrfSync01Table match={match} />}
      </Card>
    );
  }
}

TrfToVanProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),
  processIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchTrfToVanProcess: PropTypes.func
};

TrfToVanProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],
  processIsLoading: false,

  curDivisionId: 0,
  fetchTrfToVanProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.trfToVanProcess.process,
  processIsLoading: state.trfToVanProcess.processIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  fetchTrfToVanProcess: () => dispatch(TrfToVanProcessActions.trfToVanProcessFetchTrfToVanProcess())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TrfToVanProcessScreen));
