import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcpt02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcpt02GoToDocument: ['hdrId'],
  gdsRcpt02FetchGdsRcpt02: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcpt02FetchGdsRcpt02Loading: ['boolean'],
  gdsRcpt02FetchGdsRcpt02Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  gdsRcpt02AddSelectedDocuments: ['selectedDocuments'],
  gdsRcpt02RemoveSelectedDocuments: ['selectedDocuments'],
  gdsRcpt02SetWorkspaceVisible: ['boolean'],
  gdsRcpt02PrintGdsRcpt02: ['hdrIds'],
  gdsRcpt02CreateGdsRcpt02: ['hdrIds', 'toStorageBinId', 'minPalletQtyPerc'],
  gdsRcpt02CreateGdsRcpt02Loading: ['boolean'],
  gdsRcpt02CreateGdsRcpt02Success: ['newDocuments'],

  gdsRcpt02FetchToStorageBinOptions: ['search'],
  gdsRcpt02FetchToStorageBinOptionLoading: ['boolean'],
  gdsRcpt02FetchToStorageBinOptionSuccess: ['options'],
  gdsRcpt02SetToStorageBinOption: ['option'],
  gdsRcpt02SetMinPalletQtyPerc: ['value']
});

export const GdsRcpt02Types = Types;
export default Creators;
