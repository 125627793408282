import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  binTrfDetailResetTimestamp: null,
  binTrfDetailSetHdrId: ['hdrId'],

  binTrfDetailInitHeader: null,
  binTrfDetailShowHeader: ['hdrId'],
  binTrfDetailShowHeaderSuccess: ['documentHeader'],

  binTrfDetailUpdateHeader: ['formikBag', 'documentHeader'],
  binTrfDetailCreateHeader: ['formikBag', 'documentHeader'],
  binTrfDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  binTrfDetailShowDocumentLoading: ['boolean'],
  binTrfDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  binTrfDetailShowDetails: ['hdrId'],
  binTrfDetailShowDetailsSuccess: ['documentDetails'],

  binTrfDetailSetDetailVisible: ['boolean'],
  binTrfDetailSetJobDetailVisible: ['boolean'],
  binTrfDetailSetDocumentDetail: ['documentDetail'],

  binTrfDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  binTrfDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  binTrfDetailDeleteDetail: ['hdrId', 'documentDetail'],

  binTrfDetailSetWhseJobType: ['whseJobType'],

  binTrfDetailFetchItemOptions: ['search'],
  binTrfDetailFetchItemOptionLoading: ['boolean'],
  binTrfDetailFetchItemOptionSuccess: ['options'],
  binTrfDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  binTrfDetailFetchItemBatchOptions: ['itemId', 'search'],
  binTrfDetailFetchItemBatchOptionLoading: ['boolean'],
  binTrfDetailFetchItemBatchOptionSuccess: ['options'],
  binTrfDetailChangeItemBatch: ['formikBag', 'hdrId', 'itemBatchId'],

  binTrfDetailFetchUomOptions: ['itemId', 'search'],
  binTrfDetailFetchUomOptionLoading: ['boolean'],
  binTrfDetailFetchUomOptionSuccess: ['options'],
  binTrfDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  binTrfDetailFetchFrStorageBinOptions: ['whseJobType', 'search'],
  binTrfDetailFetchFrStorageBinOptionLoading: ['boolean'],
  binTrfDetailFetchFrStorageBinOptionSuccess: ['options'],

  binTrfDetailFetchToStorageBinOptions: ['whseJobType', 'search'],
  binTrfDetailFetchToStorageBinOptionLoading: ['boolean'],
  binTrfDetailFetchToStorageBinOptionSuccess: ['options'],

  binTrfDetailFetchToHandlingUnitOptions: ['storageBinId', 'search'],
  binTrfDetailFetchToHandlingUnitOptionLoading: ['boolean'],
  binTrfDetailFetchToHandlingUnitOptionSuccess: ['options'],

  binTrfDetailFetchHandlingUnitOptions: ['search'],
  binTrfDetailFetchHandlingUnitOptionLoading: ['boolean'],
  binTrfDetailFetchHandlingUnitOptionSuccess: ['options'],

  binTrfDetailFetchStorageRowOptions: ['search'],
  binTrfDetailFetchStorageRowOptionLoading: ['boolean'],
  binTrfDetailFetchStorageRowOptionSuccess: ['options'],

  binTrfDetailFetchStorageBayOptions: ['search'],
  binTrfDetailFetchStorageBayOptionLoading: ['boolean'],
  binTrfDetailFetchStorageBayOptionSuccess: ['options'],

  binTrfDetailFetchQuantBalOptions: ['storageBinId', 'search'],
  binTrfDetailFetchQuantBalOptionLoading: ['boolean'],
  binTrfDetailFetchQuantBalOptionSuccess: ['options'],
  binTrfDetailChangeQuantBal: ['formikBag', 'hdrId', 'quantBalId'],

  binTrfDetailUploadExcel: ['hdrId', 'file'],
  binTrfDetailUploadLoading: ['boolean'],
  binTrfDetailDownloadExcel: ['hdrId']
});

export const BinTrfDetailTypes = Types;
export default Creators;
