import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { Table, Button } from 'antd';

import WhseJob06DetailActions from '../../Stores/WhseJob06Detail/Actions';

class InbOrdTable extends React.PureComponent {
  constructor() {
    super();

    this.state = { pageSize: 20 };

    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { hdrId, showInbOrdHdrs } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('timestamp')) {
      showInbOrdHdrs(hdrId);
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl, hdrId, upInbOrd, downInbOrd } = this.props;

    return [
      {
        width: 50,
        // fixed: 'left',
        key: 'action',
        render: (text, record) => (
          <>
            <Button
              type="dashed"
              icon={<UpOutlined />}
              size="small"
              onClick={() => upInbOrd(hdrId, record)}
            />
            <Button
              type="dashed"
              icon={<DownOutlined />}
              size="small"
              onClick={() => downInbOrd(hdrId, record)}
            />
          </>
        )
      },
      {
        // fixed: 'left',
        width: 50,
        align: 'right',
        title: '#',
        // sort field
        dataIndex: 'line_no',
        // filter field
        key: 'line_no',
        render: (text, record) => <span style={{ wordBreak: 'normal' }}>{record.line_no}</span>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'shipping_doc_code' }),
        // sort field
        dataIndex: 'doc_code_01',
        // filter field
        key: 'doc_code_01',
        render: (text, record) => record.doc_code_01
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'ref_code_01' }),
        // sort field
        dataIndex: 'ref_code_01',
        // filter field
        key: 'ref_code_01',
        render: (text, record) => record.ref_code_01
      },
      {
        width: 105,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date_01',
        // filter field
        key: 'doc_date_01',
        render: (text, record) => <>{moment(record.doc_date_01).format('YYYY-MM-DD')}</>
      },
      {
        width: 100,
        // fixed: 'right',
        align: 'right',
        title: intl.formatMessage({ id: 'net_amt' }),
        // sort field
        dataIndex: 'net_amt',
        // filter field
        key: 'net_amt',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.net_amt)}
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'area' }),
        // sort field
        dataIndex: 'delivery_point_area_code',
        // filter field
        key: 'delivery_point_area',
        render: (text, record) => (
          <>
            <b>{record.delivery_point_area_code}</b>
            <br />
            {record.delivery_point_area_desc_01}
          </>
        )
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'delivery_point' }),
        // sort field
        dataIndex: 'delivery_point_code',
        // filter field
        key: 'delivery_point',
        render: (text, record) => (
          <>
            <b>{record.delivery_point_code}</b>
            <br />
            {record.delivery_point_company_name_01}
          </>
        )
      }
    ];
  }

  render() {
    const { intl, inbOrdHdrs, documentIsLoading, fetchIsLoading } = this.props;
    const { pageSize: statePageSize } = this.state;

    return (
      <Table
        title={() => intl.formatMessage({ id: 'inbound_orders' })}
        // rowSelection={rowSelection}
        rowKey="id"
        pagination={{
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          showSizeChanger: true,
          pageSizeOptions: ['20', '50'],
          onShowSizeChange: (current, pageSize) => {
            this.setState({
              pageSize
            });
          },
          pageSize: statePageSize
        }}
        columns={this.getDocumentColumns()}
        dataSource={inbOrdHdrs}
        loading={documentIsLoading || fetchIsLoading}
        bordered
        rowClassName={rowData => {
          if ('is_modified' in rowData && rowData.is_modified === true) {
            return 'success-row';
          }
          return '';
        }}
        scroll={{ x: 950 }}
        summary={pageData => {
          // summarise the table
          const calcRow = pageData.reduce(
            (ttlObj, object) => {
              return {
                net_amt: ('net_amt' in ttlObj ? ttlObj.net_amt : 0) + parseFloat(object.net_amt),
                case_decimal_qty:
                  ('case_decimal_qty' in ttlObj ? ttlObj.case_decimal_qty : 0) +
                  parseFloat(object.case_decimal_qty),
                gross_weight:
                  ('gross_weight' in ttlObj ? ttlObj.gross_weight : 0) +
                  parseFloat(object.gross_weight),
                cubic_meter:
                  ('cubic_meter' in ttlObj ? ttlObj.cubic_meter : 0) +
                  parseFloat(object.cubic_meter)
              };
            },
            { net_amt: 0, case_decimal_qty: 0, gross_weight: 0, cubic_meter: 0 }
          );

          return (
            <>
              <tr className="ant-table-row">
                <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={6}>
                  {intl.formatMessage({ id: 'total' })}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.net_amt)}
                </td>
              </tr>
            </>
          );
        }}
      />
    );
  }
}

InbOrdTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  showInbOrdHdrs: PropTypes.func,
  inbOrdHdrs: PropTypes.arrayOf(PropTypes.object),
  documentIsLoading: PropTypes.bool,
  fetchIsLoading: PropTypes.bool,

  hdrId: PropTypes.number,
  upInbOrd: PropTypes.func,
  downInbOrd: PropTypes.func
};

InbOrdTable.defaultProps = {
  intl: {},

  showInbOrdHdrs() {},
  inbOrdHdrs: [],

  hdrId: 0,
  documentIsLoading: false,
  fetchIsLoading: false,

  upInbOrd() {},
  downInbOrd() {}
};

const mapStateToProps = state => ({
  timestamp: state.whseJob06Detail.timestamp,
  hdrId: state.whseJob06Detail.hdrId,
  inbOrdHdrs: state.whseJob06Detail.inbOrdHdrs,

  documentIsLoading: state.whseJob06Detail.documentIsLoading,
  fetchIsLoading: state.whseJob06Detail.fetchIsLoading
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      WhseJob06DetailActions.inbOrdIndexResetTimestamp(currentPage, sorts, filters, pageSize)
    ),
  showInbOrdHdrs: hdrId => dispatch(WhseJob06DetailActions.whseJob06DetailShowInbOrdHdrs(hdrId)),

  upInbOrd: (hdrId, inbOrdHdr) =>
    dispatch(WhseJob06DetailActions.whseJob06DetailUpInbOrd(hdrId, inbOrdHdr)),
  downInbOrd: (hdrId, inbOrdHdr) =>
    dispatch(WhseJob06DetailActions.whseJob06DetailDownInbOrd(hdrId, inbOrdHdr))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(InbOrdTable));
