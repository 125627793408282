/**
 * The initial values for the redux state.
 */
export default {
  resId: 0,

  modelTimestamp: 0,
  model: {
    status: 2,
    str_status: 'INACTIVE',
    code: '',
    ref_code_01: '',
    ref_code_02: '',
    company_name_01: '',
    company_name_02: ''
  },

  bizPartnerTimestamp: 0,
  bizPartners: [],
  bizPartnerSorts: {
    code: 'ascend'
  },
  bizPartnerFilters: {},
  bizPartnerPageSize: '20',
  bizPartnerCurrentPage: 1,
  bizPartnerLastPage: 10,
  bizPartnerTotal: 100,

  modelIsLoading: false
};
