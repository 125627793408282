import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { UndoOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography, Progress } from 'antd';
import { FormikSelect, FormikButton } from '../../Components/Formik';
import BinTrf01Actions from '../../Stores/BinTrf01/Actions';

const { Text } = Typography;

class CriteriaForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchCompany = this.handleSearchCompany.bind(this);
    this.handleSearchItem = this.handleSearchItem.bind(this);
    this.handleSearchItemGroup01 = this.handleSearchItemGroup01.bind(this);
    this.handleSearchItemGroup02 = this.handleSearchItemGroup02.bind(this);
    this.handleSearchItemGroup03 = this.handleSearchItemGroup03.bind(this);
    this.handleQuery = this.handleQuery.bind(this);
    this.handleAnalyse = this.handleAnalyse.bind(this);

    this.handleDownload = this.handleDownload.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {
    const { batchJobIsLoading, showBatchJobStatus } = this.props;

    if (batchJobIsLoading > 0) {
      setTimeout(() => {
        showBatchJobStatus();
      }, 10000);
    }
  }

  componentWillUnmount() {}

  handleSearchCompany(form, value) {
    const { fetchCompanyOptions } = this.props;

    fetchCompanyOptions(value);
  }

  handleSearchItem(form, value) {
    const { fetchItemOptions } = this.props;

    fetchItemOptions(value);
  }

  handleSearchItemGroup01(form, value) {
    const { fetchItemGroup01Options } = this.props;

    fetchItemGroup01Options(value);
  }

  handleSearchItemGroup02(form, value) {
    const { fetchItemGroup02Options } = this.props;

    fetchItemGroup02Options(value);
  }

  handleSearchItemGroup03(form, value) {
    const { fetchItemGroup03Options } = this.props;

    fetchItemGroup03Options(value);
  }

  handleQuery(values, formikBag) {
    const { resetTimestamp, currentPage, sorts, pageSize } = this.props;

    resetTimestamp(currentPage, sorts, values, pageSize);
    formikBag.setSubmitting(false);
  }

  handleAnalyse(values, formikBag) {
    const { analyseBinTrf01 } = this.props;

    analyseBinTrf01(values);
    formikBag.setSubmitting(false);
  }

  handleDownload(values, formikBag) {
    const { downloadBinTrf01 } = this.props;

    downloadBinTrf01(values);
    formikBag.setSubmitting(false);
  }

  render() {
    const {
      intl,
      criteria,
      fetchIsLoading,
      companyOptions,
      companyIsLoading,
      itemOptions,
      itemIsLoading,
      itemGroup01Options,
      itemGroup01IsLoading,
      itemGroup02Options,
      itemGroup02IsLoading,
      itemGroup03Options,
      itemGroup03IsLoading,
      batchJobStatus
    } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={criteria}
        onSubmit={(values, formikBag) => {
          if (values.submit_action === 'query') {
            this.handleQuery(values, formikBag);
          } else if (values.submit_action === 'analyse') {
            this.handleAnalyse(values, formikBag);
          } else if (values.submit_action === 'download') {
            this.handleDownload(values, formikBag);
          }
        }}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'company' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="company_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchCompany}
                  notFoundContent={null}
                  loading={companyIsLoading}
                  options={companyOptions}
                  disabled={isSubmitting || fetchIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItem}
                  notFoundContent={null}
                  loading={itemIsLoading}
                  options={itemOptions}
                  disabled={isSubmitting || fetchIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'brand' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_group_01_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItemGroup01}
                  notFoundContent={null}
                  loading={itemGroup01IsLoading}
                  options={itemGroup01Options}
                  disabled={isSubmitting || fetchIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'category' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_group_02_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItemGroup02}
                  notFoundContent={null}
                  loading={itemGroup02IsLoading}
                  options={itemGroup02Options}
                  disabled={isSubmitting || fetchIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'manufacturer' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_group_03_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItemGroup03}
                  notFoundContent={null}
                  loading={itemGroup03IsLoading}
                  options={itemGroup03Options}
                  disabled={isSubmitting || fetchIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="space-between" gutter={[0, 16]}>
              <Col span={24}>
                <Button
                  disabled={!dirty}
                  loading={isSubmitting || fetchIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                  type="primary"
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  name="submit_action"
                  value="query"
                  component={FormikButton}
                  loading={isSubmitting || fetchIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'query'
                  })}
                  type="primary"
                />
                <Field
                  name="submit_action"
                  value="analyse"
                  component={FormikButton}
                  loading={isSubmitting || fetchIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'analyse'
                  })}
                  type="primary"
                />
                <Field
                  name="submit_action"
                  value="download"
                  component={FormikButton}
                  loading={isSubmitting || fetchIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'download'
                  })}
                  type="primary"
                />
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={[0, 8]}>
              <Col span={24}>
                <Progress percent={parseInt(batchJobStatus.status_number, 10)} />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

CriteriaForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  // eslint-disable-next-line react/forbid-prop-types
  criteria: PropTypes.object,

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  fetchIsLoading: PropTypes.bool,
  resetTimestamp: PropTypes.func,
  analyseBinTrf01: PropTypes.func,
  downloadBinTrf01: PropTypes.func,
  fetchCompanyOptions: PropTypes.func,
  fetchItemOptions: PropTypes.func,
  fetchItemGroup01Options: PropTypes.func,
  fetchItemGroup02Options: PropTypes.func,
  fetchItemGroup03Options: PropTypes.func,

  companyOptions: PropTypes.arrayOf(PropTypes.object),
  companyIsLoading: PropTypes.bool,

  itemOptions: PropTypes.arrayOf(PropTypes.object),
  itemIsLoading: PropTypes.bool,

  itemGroup01Options: PropTypes.arrayOf(PropTypes.object),
  itemGroup01IsLoading: PropTypes.bool,

  itemGroup02Options: PropTypes.arrayOf(PropTypes.object),
  itemGroup02IsLoading: PropTypes.bool,

  itemGroup03Options: PropTypes.arrayOf(PropTypes.object),
  itemGroup03IsLoading: PropTypes.bool,

  batchJobIsLoading: PropTypes.bool,
  batchJobStatus: PropTypes.shape({
    status_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  showBatchJobStatus: PropTypes.func
};

CriteriaForm.defaultProps = {
  intl: {},

  sorts: {},
  criteria: {},
  currentPage: 1,
  pageSize: '20',

  fetchIsLoading: false,
  resetTimestamp() {},
  analyseBinTrf01() {},
  downloadBinTrf01() {},
  fetchCompanyOptions() {},
  fetchItemOptions() {},
  fetchItemGroup01Options() {},
  fetchItemGroup02Options() {},
  fetchItemGroup03Options() {},

  companyOptions: [],
  companyIsLoading: false,

  itemOptions: [],
  itemIsLoading: false,

  itemGroup01Options: [],
  itemGroup01IsLoading: false,

  itemGroup02Options: [],
  itemGroup02IsLoading: false,

  itemGroup03Options: [],
  itemGroup03IsLoading: false,

  batchJobIsLoading: false,
  batchJobStatus: {
    status_number: '0'
  },
  showBatchJobStatus() {}
};

const mapStateToProps = state => ({
  timestamp: state.binTrf01.timestamp,

  sorts: state.binTrf01.sorts,
  criteria: state.binTrf01.criteria,

  currentPage: state.binTrf01.currentPage,
  pageSize: state.binTrf01.pageSize,

  fetchIsLoading: state.binTrf01.fetchIsLoading,

  companyOptions: state.binTrf01.companyOptions,
  companyIsLoading: state.binTrf01.companyIsLoading,

  itemOptions: state.binTrf01.itemOptions,
  itemIsLoading: state.binTrf01.itemIsLoading,

  itemGroup01Options: state.binTrf01.itemGroup01Options,
  itemGroup01IsLoading: state.binTrf01.itemGroup01IsLoading,

  itemGroup02Options: state.binTrf01.itemGroup02Options,
  itemGroup02IsLoading: state.binTrf01.itemGroup02IsLoading,

  itemGroup03Options: state.binTrf01.itemGroup03Options,
  itemGroup03IsLoading: state.binTrf01.itemGroup03IsLoading,

  batchJobIsLoading: state.binTrf01.batchJobIsLoading,
  batchJobStatus: state.binTrf01.batchJobStatus
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, criteria, pageSize) =>
    dispatch(BinTrf01Actions.binTrf01ResetTimestamp(currentPage, sorts, criteria, pageSize)),
  analyseBinTrf01: criteria => dispatch(BinTrf01Actions.binTrf01AnalyseBinTrf01(criteria)),
  downloadBinTrf01: criteria => dispatch(BinTrf01Actions.binTrf01DownloadBinTrf01(criteria)),

  fetchCompanyOptions: search => dispatch(BinTrf01Actions.binTrf01FetchCompanyOptions(search)),
  fetchItemOptions: search => dispatch(BinTrf01Actions.binTrf01FetchItemOptions(search)),
  fetchItemGroup01Options: search =>
    dispatch(BinTrf01Actions.binTrf01FetchItemGroup01Options(search)),
  fetchItemGroup02Options: search =>
    dispatch(BinTrf01Actions.binTrf01FetchItemGroup02Options(search)),
  fetchItemGroup03Options: search =>
    dispatch(BinTrf01Actions.binTrf01FetchItemGroup03Options(search)),

  showBatchJobStatus: batchJobCode =>
    dispatch(BinTrf01Actions.binTrf01ShowBatchJobStatus(batchJobCode))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CriteriaForm));
