import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import GdsRcptDetailActions, { GdsRcptDetailTypes } from '../Stores/GdsRcptDetail/Actions';

const getAppStore = state => state.app;

const getGdsRcptDetailStore = state => state.gdsRcptDetail;

export function* gdsRcptDetailInitHeader() {
  try {
    yield put(GdsRcptDetailActions.gdsRcptDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `gdsRcpt/initHeader/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(GdsRcptDetailActions.gdsRcptDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailActions.gdsRcptDetailShowDocumentLoading(false));
  }
}

export function* gdsRcptDetailShowHeader({ hdrId }) {
  try {
    yield put(GdsRcptDetailActions.gdsRcptDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `gdsRcpt/showHeader/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(GdsRcptDetailActions.gdsRcptDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailActions.gdsRcptDetailShowDocumentLoading(false));
  }
}

export function* gdsRcptDetailTransitionToStatus({ formikBag, hdrId, docStatus }) {
  formikBag.setSubmitting(true);
  yield put(GdsRcptDetailActions.gdsRcptDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      hdrId,
      docStatus
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'gdsRcpt/transitionToStatus',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const gdsRcptDetail = yield select(getGdsRcptDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = gdsRcptDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        GdsRcptDetailActions.gdsRcptDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(GdsRcptDetailActions.gdsRcptDetailShowDocumentLoading(false));
  }
}

export function* gdsRcptDetailUpdateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'gdsRcpt/updateHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const gdsRcptDetail = yield select(getGdsRcptDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = gdsRcptDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        GdsRcptDetailActions.gdsRcptDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* gdsRcptDetailShowDetails({ hdrId }) {
  try {
    yield put(GdsRcptDetailActions.gdsRcptDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `gdsRcpt/showDetails/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(GdsRcptDetailActions.gdsRcptDetailShowDetailsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailActions.gdsRcptDetailShowDocumentLoading(false));
  }
}

export function* gdsRcptDetailUpdateDetails({ formikBag, hdrId, documentDetails }) {
  formikBag.setSubmitting(true);
  yield put(GdsRcptDetailActions.gdsRcptDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetails
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `gdsRcpt/updateDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const gdsRcptDetail = yield select(getGdsRcptDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = gdsRcptDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        GdsRcptDetailActions.gdsRcptDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(GdsRcptDetailActions.gdsRcptDetailShowDocumentLoading(false));
  }
}

export function* gdsRcptDetailFetchCompanyOptions({ search }) {
  try {
    yield put(GdsRcptDetailActions.gdsRcptDetailFetchCompanyOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `company/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(GdsRcptDetailActions.gdsRcptDetailFetchCompanyOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailActions.gdsRcptDetailFetchCompanyOptionLoading(false));
  }
}

export function* gdsRcptDetailFetchItemOptions({ search }) {
  try {
    yield put(GdsRcptDetailActions.gdsRcptDetailFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(GdsRcptDetailActions.gdsRcptDetailFetchItemOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailActions.gdsRcptDetailFetchItemOptionLoading(false));
  }
}

export function* gdsRcptDetailChangeItem({ formikBag, hdrId, itemId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `gdsRcpt/changeItem`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });

      // reset the select2 cache
      formikBag.setFieldValue('uom_select2', {
        value: 0,
        label: ''
      });
      formikBag.setFieldValue('uom_rate', 1);

      yield put(GdsRcptDetailActions.gdsRcptDetailFetchUomOptionSuccess([]));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* gdsRcptDetailFetchItemBatchOptions({ itemId, search }) {
  try {
    yield put(GdsRcptDetailActions.gdsRcptDetailFetchItemBatchOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemBatch/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.batch_serial_no} | ${d.expiry_date} | ${d.receipt_date}`
      }));

      yield put(GdsRcptDetailActions.gdsRcptDetailFetchItemBatchOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailActions.gdsRcptDetailFetchItemBatchOptionLoading(false));
  }
}

export function* gdsRcptDetailChangeItemBatch({ formikBag, hdrId, itemBatchId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemBatchId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `gdsRcpt/changeItemBatch`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* gdsRcptDetailFetchUomOptions({ itemId, search }) {
  try {
    yield put(GdsRcptDetailActions.gdsRcptDetailFetchUomOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemUom/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.uom_id, label: `${d.uom_code}` }));

      yield put(GdsRcptDetailActions.gdsRcptDetailFetchUomOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailActions.gdsRcptDetailFetchUomOptionLoading(false));
  }
}

export function* gdsRcptDetailChangeUom({ formikBag, hdrId, itemId, uomId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId,
      uomId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `gdsRcpt/changeItemUom`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* gdsRcptDetailFetchItemCond01Options({ search }) {
  try {
    yield put(GdsRcptDetailActions.gdsRcptDetailFetchItemCond01OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemCond01/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(GdsRcptDetailActions.gdsRcptDetailFetchItemCond01OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailActions.gdsRcptDetailFetchItemCond01OptionLoading(false));
  }
}

export function* gdsRcptDetailFetchToStorageBinOptions({ search }) {
  try {
    yield put(GdsRcptDetailActions.gdsRcptDetailFetchToStorageBinOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: [
        {
          field: 'bin_types',
          value: [
            1, // UNLOADING_AREA
            3, // UNLOADING_LOADING_AREA
            4, // BULK_STORAGE
            5, // PALLET_STORAGE
            6, // BROKEN_PALLET_STORAGE
            7, // CASE_STORAGE
            8, // BROKEN_CASE_STORAGE
            11, // BAD_PALLET_STORAGE
            12 // BAD_BROKEN_CASE_STORAGE
          ]
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const gdsRcptDetail = yield select(getGdsRcptDetailStore);

      const { documentDetails } = gdsRcptDetail;

      const docHandlingUnitCountHash = {};
      documentDetails.forEach(detail => {
        let docHandlingUnitHash = {};
        if (detail.to_storage_bin_id in docHandlingUnitCountHash) {
          docHandlingUnitHash = docHandlingUnitCountHash[detail.to_storage_bin_id];
        }
        if (detail.to_handling_unit_id > 0) {
          docHandlingUnitHash[detail.to_handling_unit_id] = detail.to_handling_unit_id;
        }

        docHandlingUnitCountHash[detail.to_storage_bin_id] = docHandlingUnitHash;
      });

      const options = result.data.data.map(d => {
        let docHandlingUnitCount = 0;
        if (d.id in docHandlingUnitCountHash) {
          const docHandlingUnitHash = docHandlingUnitCountHash[d.id];
          docHandlingUnitCount = Object.keys(docHandlingUnitHash).length;
        }

        return {
          value: d.id,
          label: `${d.code} PALLET: ${d.handling_unit_count} DOC: ${docHandlingUnitCount}`
        };
      });

      yield put(GdsRcptDetailActions.gdsRcptDetailFetchToStorageBinOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailActions.gdsRcptDetailFetchToStorageBinOptionLoading(false));
  }
}

export function* gdsRcptDetailFetchToHandlingUnitOptions({ search }) {
  try {
    yield put(GdsRcptDetailActions.gdsRcptDetailFetchToHandlingUnitOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'site_flow_id',
          value: app.curSiteFlowId
        },
        {
          field: 'status',
          value: 4 // NEW
        },
        {
          field: 'handling_type',
          value: 1 // PALLET
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `handlingUnit/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.barcode}` }));

      yield put(GdsRcptDetailActions.gdsRcptDetailFetchToHandlingUnitOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailActions.gdsRcptDetailFetchToHandlingUnitOptionLoading(false));
  }
}

export function* gdsRcptDetailCreateDetail({ formikBag, hdrId, documentDetail }) {
  formikBag.setSubmitting(true);
  yield put(GdsRcptDetailActions.gdsRcptDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetail
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `gdsRcpt/createDetail/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const gdsRcptDetail = yield select(getGdsRcptDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = gdsRcptDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        GdsRcptDetailActions.gdsRcptDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );

      yield put(
        GdsRcptDetailActions.gdsRcptDetailSetDocumentDetail(gdsRcptDetail.initDocumentDetail)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(GdsRcptDetailActions.gdsRcptDetailShowDocumentLoading(false));
  }
}

export function* gdsRcptDetailDeleteDetail({ hdrId, documentDetail }) {
  yield put(GdsRcptDetailActions.gdsRcptDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: [{ id: documentDetail.id }]
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `gdsRcpt/deleteDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const gdsRcptDetail = yield select(getGdsRcptDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = gdsRcptDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        GdsRcptDetailActions.gdsRcptDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailActions.gdsRcptDetailShowDocumentLoading(false));
  }
}

export function* gdsRcptDetailCreateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'gdsRcpt/createHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/gdsRcptDetail/update/${result.data}`));
      yield put(GdsRcptDetailActions.gdsRcptDetailSetHdrId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export const saga = [
  takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_INIT_HEADER, gdsRcptDetailInitHeader),
  takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_SHOW_HEADER, gdsRcptDetailShowHeader),

  takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_UPDATE_HEADER, gdsRcptDetailUpdateHeader),
  takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_CREATE_HEADER, gdsRcptDetailCreateHeader),

  takeLatest(
    GdsRcptDetailTypes.GDS_RCPT_DETAIL_TRANSITION_TO_STATUS,
    gdsRcptDetailTransitionToStatus
  ),

  takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_SHOW_DETAILS, gdsRcptDetailShowDetails),

  takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_UPDATE_DETAILS, gdsRcptDetailUpdateDetails),
  takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_DELETE_DETAIL, gdsRcptDetailDeleteDetail),
  takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_CREATE_DETAIL, gdsRcptDetailCreateDetail),

  takeLatest(
    GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_COMPANY_OPTIONS,
    gdsRcptDetailFetchCompanyOptions
  ),

  takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_ITEM_OPTIONS, gdsRcptDetailFetchItemOptions),
  takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_CHANGE_ITEM, gdsRcptDetailChangeItem),

  takeLatest(
    GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_ITEM_BATCH_OPTIONS,
    gdsRcptDetailFetchItemBatchOptions
  ),
  takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_CHANGE_ITEM_BATCH, gdsRcptDetailChangeItemBatch),

  takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_UOM_OPTIONS, gdsRcptDetailFetchUomOptions),
  takeLatest(GdsRcptDetailTypes.GDS_RCPT_DETAIL_CHANGE_UOM, gdsRcptDetailChangeUom),

  takeLatest(
    GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_ITEM_COND01_OPTIONS,
    gdsRcptDetailFetchItemCond01Options
  ),

  takeLatest(
    GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_TO_STORAGE_BIN_OPTIONS,
    gdsRcptDetailFetchToStorageBinOptions
  ),

  takeLatest(
    GdsRcptDetailTypes.GDS_RCPT_DETAIL_FETCH_TO_HANDLING_UNIT_OPTIONS,
    gdsRcptDetailFetchToHandlingUnitOptions
  )
];
