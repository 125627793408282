import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace, push } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import SiteDetailActions, { SiteDetailTypes } from '../Stores/SiteDetail/Actions';

const getAppStore = state => state.app;

export function* siteDetailInitModel() {
  try {
    yield put(SiteDetailActions.siteDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `site/initModel`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(SiteDetailActions.siteDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SiteDetailActions.siteDetailShowModelLoading(false));
  }
}

export function* siteDetailShowModel({ resId }) {
  try {
    yield put(SiteDetailActions.siteDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `site/showModel/${resId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(SiteDetailActions.siteDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SiteDetailActions.siteDetailShowModelLoading(false));
  }
}

export function* siteDetailUpdateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'site/updateModel',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const { model: retModel } = result.data;

      yield put(SiteDetailActions.siteDetailUpdateModelSuccess(retModel));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* siteDetailCreateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `site/createModel`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/siteDetail/update/${result.data}`));
      yield put(SiteDetailActions.siteDetailSetResId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* siteDetailFetchDocNoOptions({ docType, search }) {
  try {
    if (docType === 'PickListHdr') {
      yield put(SiteDetailActions.siteDetailFetchPickListDocNoOptionLoading(true));
    } else if (docType === 'PackListHdr') {
      yield put(SiteDetailActions.siteDetailFetchPackListDocNoOptionLoading(true));
    } else if (docType === 'GdsRcptHdr') {
      yield put(SiteDetailActions.siteDetailFetchGdsRcptDocNoOptionLoading(true));
    } else if (docType === 'PutAwayHdr') {
      yield put(SiteDetailActions.siteDetailFetchPutAwayDocNoOptionLoading(true));
    } else if (docType === 'GdsIssHdr') {
      yield put(SiteDetailActions.siteDetailFetchGdsIssDocNoOptionLoading(true));
    } else if (docType === 'BinTrfHdr') {
      yield put(SiteDetailActions.siteDetailFetchBinTrfDocNoOptionLoading(true));
    } else if (docType === 'CycleCountHdr') {
      yield put(SiteDetailActions.siteDetailFetchCycleCountDocNoOptionLoading(true));
    } else if (docType === 'CountAdjHdr') {
      yield put(SiteDetailActions.siteDetailFetchCountAdjDocNoOptionLoading(true));
    } else if (docType === 'WhseJobHdr') {
      yield put(SiteDetailActions.siteDetailFetchWhseJobDocNoOptionLoading(true));
    } else if (docType === 'LoadListHdr') {
      yield put(SiteDetailActions.siteDetailFetchLoadListDocNoOptionLoading(true));
    } else if (docType === 'PrfDelHdr') {
      yield put(SiteDetailActions.siteDetailFetchPrfDelDocNoOptionLoading(true));
    } else if (docType === 'FailDelHdr') {
      yield put(SiteDetailActions.siteDetailFetchFailDelDocNoOptionLoading(true));
    }

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'doc_type',
          value: docType
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `docNo/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => {
        const siteCodes = d.sites.reduce((lastStr, data) => {
          let newStr = '';
          if (lastStr.length > 0) {
            newStr = `${lastStr}/${data.code}`;
          } else {
            newStr = data.code;
          }
          return newStr;
        }, '');

        return { value: d.id, label: `${d.doc_code} ${siteCodes}` };
      });

      if (docType === 'PickListHdr') {
        yield put(SiteDetailActions.siteDetailFetchPickListDocNoOptionSuccess(options));
      } else if (docType === 'PackListHdr') {
        yield put(SiteDetailActions.siteDetailFetchPackListDocNoOptionSuccess(options));
      } else if (docType === 'GdsRcptHdr') {
        yield put(SiteDetailActions.siteDetailFetchGdsRcptDocNoOptionSuccess(options));
      } else if (docType === 'PutAwayHdr') {
        yield put(SiteDetailActions.siteDetailFetchPutAwayDocNoOptionSuccess(options));
      } else if (docType === 'GdsIssHdr') {
        yield put(SiteDetailActions.siteDetailFetchGdsIssDocNoOptionSuccess(options));
      } else if (docType === 'BinTrfHdr') {
        yield put(SiteDetailActions.siteDetailFetchBinTrfDocNoOptionSuccess(options));
      } else if (docType === 'CycleCountHdr') {
        yield put(SiteDetailActions.siteDetailFetchCycleCountDocNoOptionSuccess(options));
      } else if (docType === 'CountAdjHdr') {
        yield put(SiteDetailActions.siteDetailFetchCountAdjDocNoOptionSuccess(options));
      } else if (docType === 'WhseJobHdr') {
        yield put(SiteDetailActions.siteDetailFetchWhseJobDocNoOptionSuccess(options));
      } else if (docType === 'LoadListHdr') {
        yield put(SiteDetailActions.siteDetailFetchLoadListDocNoOptionSuccess(options));
      } else if (docType === 'PrfDelHdr') {
        yield put(SiteDetailActions.siteDetailFetchPrfDelDocNoOptionSuccess(options));
      } else if (docType === 'FailDelHdr') {
        yield put(SiteDetailActions.siteDetailFetchFailDelDocNoOptionSuccess(options));
      }
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    if (docType === 'PickListHdr') {
      yield put(SiteDetailActions.siteDetailFetchPickListDocNoOptionLoading(false));
    } else if (docType === 'PackListHdr') {
      yield put(SiteDetailActions.siteDetailFetchPackListDocNoOptionLoading(false));
    } else if (docType === 'GdsRcptHdr') {
      yield put(SiteDetailActions.siteDetailFetchGdsRcptDocNoOptionLoading(false));
    } else if (docType === 'PutAwayHdr') {
      yield put(SiteDetailActions.siteDetailFetchPutAwayDocNoOptionLoading(false));
    } else if (docType === 'GdsIssHdr') {
      yield put(SiteDetailActions.siteDetailFetchGdsIssDocNoOptionLoading(false));
    } else if (docType === 'BinTrfHdr') {
      yield put(SiteDetailActions.siteDetailFetchBinTrfDocNoOptionLoading(false));
    } else if (docType === 'CycleCountHdr') {
      yield put(SiteDetailActions.siteDetailFetchCycleCountDocNoOptionLoading(false));
    } else if (docType === 'CountAdjHdr') {
      yield put(SiteDetailActions.siteDetailFetchCountAdjDocNoOptionLoading(false));
    } else if (docType === 'WhseJobHdr') {
      yield put(SiteDetailActions.siteDetailFetchWhseJobDocNoOptionLoading(false));
    } else if (docType === 'LoadListHdr') {
      yield put(SiteDetailActions.siteDetailFetchLoadListDocNoOptionLoading(false));
    } else if (docType === 'PrfDelHdr') {
      yield put(SiteDetailActions.siteDetailFetchPrfDelDocNoOptionLoading(false));
    } else if (docType === 'FailDelHdr') {
      yield put(SiteDetailActions.siteDetailFetchFailDelDocNoOptionLoading(false));
    }
  }
}

export function* siteDetailGoToDocTypeDocNo({ docType }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/docTypeDocNoIndex/${docType}`));
}

export const saga = [
  takeLatest(SiteDetailTypes.SITE_DETAIL_INIT_MODEL, siteDetailInitModel),
  takeLatest(SiteDetailTypes.SITE_DETAIL_SHOW_MODEL, siteDetailShowModel),

  takeLatest(SiteDetailTypes.SITE_DETAIL_UPDATE_MODEL, siteDetailUpdateModel),
  takeLatest(SiteDetailTypes.SITE_DETAIL_CREATE_MODEL, siteDetailCreateModel),

  takeLatest(SiteDetailTypes.SITE_DETAIL_FETCH_DOC_NO_OPTIONS, siteDetailFetchDocNoOptions),

  takeLatest(SiteDetailTypes.SITE_DETAIL_GO_TO_DOC_TYPE_DOC_NO, siteDetailGoToDocTypeDocNo)
];
