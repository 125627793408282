import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcptFServ02ShowSyncSetting: null,
  gdsRcptFServ02ShowSyncSettingLoading: ['boolean'],
  gdsRcptFServ02ShowSyncSettingSuccess: ['syncSetting'],
  gdsRcptFServ02UpdateSyncSetting: ['formikBag', 'syncSetting'],
  gdsRcptFServ02ShowBatchJobStatus: null,
  gdsRcptFServ02ShowBatchJobStatusSuccess: ['batchJobStatus'],

  gdsRcptFServ02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptFServ02GoToDocument: ['hdrId'],
  gdsRcptFServ02FetchGdsRcptFServ02: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptFServ02FetchGdsRcptFServ02Loading: ['boolean'],
  gdsRcptFServ02FetchGdsRcptFServ02Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  gdsRcptFServ02AddSelectedDocuments: ['selectedDocuments'],
  gdsRcptFServ02RemoveSelectedDocuments: ['selectedDocuments'],
  gdsRcptFServ02SetWorkspaceVisible: ['boolean'],

  gdsRcptFServ02Export: ['hdrIds'],
  gdsRcptFServ02ExportSuccess: null,
  gdsRcptFServ02ExportLoading: ['boolean'],

  gdsRcptFServ02SetExpandedRows: ['expandedRows']
});

export const GdsRcptFServ02Types = Types;
export default Creators;
