/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { AdvShipExcel01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const advShipExcel01SetStrProcType = (state, { strProcType }) => ({
  ...state,
  strProcType
});

export const advShipExcel01ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const advShipExcel01UploadLoading = (state, { boolean }) => ({
  ...state,
  uploadIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [AdvShipExcel01Types.ADV_SHIP_EXCEL01_SET_STR_PROC_TYPE]: advShipExcel01SetStrProcType,
  [AdvShipExcel01Types.ADV_SHIP_EXCEL01_SHOW_BATCH_JOB_STATUS_SUCCESS]: advShipExcel01ShowBatchJobStatusSuccess,
  [AdvShipExcel01Types.ADV_SHIP_EXCEL01_UPLOAD_LOADING]: advShipExcel01UploadLoading
});
