import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcptFServ03ShowSyncSetting: null,
  gdsRcptFServ03ShowSyncSettingLoading: ['boolean'],
  gdsRcptFServ03ShowSyncSettingSuccess: ['syncSetting'],
  gdsRcptFServ03UpdateSyncSetting: ['formikBag', 'syncSetting'],
  gdsRcptFServ03ShowBatchJobStatus: null,
  gdsRcptFServ03ShowBatchJobStatusSuccess: ['batchJobStatus'],

  gdsRcptFServ03ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptFServ03GoToDocument: ['hdrId'],
  gdsRcptFServ03FetchGdsRcptFServ03: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptFServ03FetchGdsRcptFServ03Loading: ['boolean'],
  gdsRcptFServ03FetchGdsRcptFServ03Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  gdsRcptFServ03AddSelectedDocuments: ['selectedDocuments'],
  gdsRcptFServ03RemoveSelectedDocuments: ['selectedDocuments'],
  gdsRcptFServ03SetWorkspaceVisible: ['boolean'],

  gdsRcptFServ03Export: ['hdrIds'],
  gdsRcptFServ03ExportSuccess: null,
  gdsRcptFServ03ExportLoading: ['boolean'],

  gdsRcptFServ03SetExpandedRows: ['expandedRows']
});

export const GdsRcptFServ03Types = Types;
export default Creators;
