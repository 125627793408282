/**
 * The initial values for the redux state.
 */
export default {
  resId: 0,

  modelTimestamp: 0,
  model: {
    id: 0,
    code: '',
    name_01: '',
    name_02: ''
  },

  modelIsLoading: false
};
