import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { SyncOutlined, SaveOutlined } from '@ant-design/icons';
import treeChanges from 'tree-changes';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Card, Row, Col, Progress, Typography } from 'antd';
import { FormikPassword, FormikInput, FormikText, FormikButton } from '../../Components/Formik';

import SlsOrdSync01Actions from '../../Stores/SlsOrdSync01/Actions';

const { Text } = Typography;

class SlsOrdSync01Form extends React.PureComponent {
  componentDidMount() {
    const { curDivisionId, showSyncSetting } = this.props;

    if (curDivisionId > 0) {
      showSyncSetting();
    }
  }

  componentDidUpdate(prevProps) {
    const { curDivisionId, syncIsLoading, showSyncSetting, showBatchJobStatus } = this.props;
    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curDivisionId')) {
      if (curDivisionId > 0) {
        showSyncSetting();
      }
    }

    if (syncIsLoading) {
      setTimeout(() => {
        showBatchJobStatus();
      }, 10000);
    }
  }

  componentWillUnmount() {}

  render() {
    const {
      intl,
      fetchIsLoading,
      syncIsLoading,
      syncSetting,
      batchJobStatus,
      updateSyncSetting,
      syncNow
    } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={syncSetting}
        onSubmit={(values, formikBag) => {
          if (values.submit_action === 'update') {
            updateSyncSetting(formikBag, values);
          } else if (values.submit_action === 'sync_now') {
            syncNow(formikBag);
          }
        }}
        validationSchema={Yup.object().shape({
          url: Yup.string().required(intl.formatMessage({ id: 'url_is_required' })),
          page_size: Yup.number().moreThan(
            0,
            intl.formatMessage({ id: 'page_size_must_be_greater_than_0' })
          ),
          username: Yup.string().required(intl.formatMessage({ id: 'username_is_required' })),
          password: Yup.string().required(intl.formatMessage({ id: 'password_is_required' }))
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          // dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit
          // handleReset
        }) => (
          <Form>
            <Card>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'url' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="url" component={FormikInput} loading={fetchIsLoading} />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'page_size' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="page_size" component={FormikInput} loading={fetchIsLoading} />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'username' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="username" component={FormikInput} loading={fetchIsLoading} />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'password' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="password" component={FormikPassword} loading={fetchIsLoading} />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'last_synced_at' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="last_synced_at" component={FormikText} loading={fetchIsLoading} />
                </Col>
              </Row>

              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={3}>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="update"
                    component={FormikButton}
                    // disabled={values.doc_status >= 50}
                    loading={isSubmitting || fetchIsLoading || syncIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({ id: 'update' })}
                    icon={<SaveOutlined />}
                  />
                </Col>
                <Col span={3}>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="sync_now"
                    component={FormikButton}
                    // disabled={values.doc_status >= 50}
                    loading={isSubmitting || fetchIsLoading || syncIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({ id: 'sync_now' })}
                    icon={<SyncOutlined />}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[0, 8]}>
                <Col span={24}>
                  <Progress percent={parseInt(batchJobStatus.status_number, 10)} />
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      </Formik>
    );
  }
}

SlsOrdSync01Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  syncSetting: PropTypes.shape({}),
  batchJobStatus: PropTypes.shape({
    status_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  fetchIsLoading: PropTypes.bool,
  syncIsLoading: PropTypes.bool,

  showSyncSetting: PropTypes.func,
  updateSyncSetting: PropTypes.func,
  showBatchJobStatus: PropTypes.func,
  syncNow: PropTypes.func,

  curDivisionId: PropTypes.number
};

SlsOrdSync01Form.defaultProps = {
  intl: {},

  syncSetting: {},
  batchJobStatus: {
    status_number: '0'
  },
  fetchIsLoading: false,
  syncIsLoading: false,

  showSyncSetting() {},
  updateSyncSetting() {},
  showBatchJobStatus() {},
  syncNow() {},

  curDivisionId: 0
};

const mapStateToProps = state => ({
  syncSetting: state.slsOrdSync01.syncSetting,
  batchJobStatus: state.slsOrdSync01.batchJobStatus,
  fetchIsLoading: state.slsOrdSync01.fetchIsLoading,
  syncIsLoading: state.slsOrdSync01.syncIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  showSyncSetting: () => dispatch(SlsOrdSync01Actions.slsOrdSync01ShowSyncSetting()),
  updateSyncSetting: (formikBag, syncSetting) =>
    dispatch(SlsOrdSync01Actions.slsOrdSync01UpdateSyncSetting(formikBag, syncSetting)),
  showBatchJobStatus: () => dispatch(SlsOrdSync01Actions.slsOrdSync01ShowBatchJobStatus()),
  syncNow: formikBag => dispatch(SlsOrdSync01Actions.slsOrdSync01SyncNow(formikBag))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SlsOrdSync01Form));
