/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  resId: 0,

  model: {
    code: '',
    desc_01: '',
    desc_02: ''
  },

  modelIsLoading: false
};
