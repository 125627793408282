import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import LspSlsRtnExcel01Actions, { LspSlsRtnExcel01Types } from '../Stores/LspSlsRtnExcel01/Actions';

const getAppStore = state => state.app;

const getLspSlsRtnExcel01Store = state => state.lspSlsRtnExcel01;

export function* lspSlsRtnExcel01ShowBatchJobStatus() {
  try {
    const app = yield select(getAppStore);
    const lspSlsRtnExcel01 = yield select(getLspSlsRtnExcel01Store);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `batchJobStatus/showBatchJobStatus/${lspSlsRtnExcel01.strProcType}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(LspSlsRtnExcel01Actions.lspSlsRtnExcel01ShowBatchJobStatusSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  }
}

export function* lspSlsRtnExcel01UploadExcel({ formikBag, projectId, debtorId, slsOrdFile }) {
  try {
    formikBag.setSubmitting(true);
    yield put(LspSlsRtnExcel01Actions.lspSlsRtnExcel01UploadLoading(true));

    const app = yield select(getAppStore);

    yield put(LspSlsRtnExcel01Actions.lspSlsRtnExcel01SetStrProcType('LSP_SLS_RTN_EXCEL_01'));
    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file', slsOrdFile);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `lspSlsRtn/uploadProcess/LSP_SLS_RTN_EXCEL_01/${app.curSiteFlowId}/${app.curDivisionId}/${projectId}/${debtorId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(LspSlsRtnExcel01Actions.lspSlsRtnExcel01UploadLoading(false));
  }
}

export function* lspSlsRtnExcel01FetchDebtorOptions({ search }) {
  try {
    yield put(LspSlsRtnExcel01Actions.lspSlsRtnExcel01FetchDebtorOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      debtorType: 1, // 0 = normal, 1 = principal
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `debtor/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.company_name_01} ${d.company_name_02}`
      }));

      yield put(LspSlsRtnExcel01Actions.lspSlsRtnExcel01FetchDebtorOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LspSlsRtnExcel01Actions.lspSlsRtnExcel01FetchDebtorOptionLoading(false));
  }
}

export function* lspSlsRtnExcel01FetchProjectOptions({ search }) {
  try {
    yield put(LspSlsRtnExcel01Actions.lspSlsRtnExcel01FetchProjectOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `project/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(LspSlsRtnExcel01Actions.lspSlsRtnExcel01FetchProjectOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LspSlsRtnExcel01Actions.lspSlsRtnExcel01FetchProjectOptionLoading(false));
  }
}

export const saga = [
  takeLatest(
    LspSlsRtnExcel01Types.LSP_SLS_RTN_EXCEL01_SHOW_BATCH_JOB_STATUS,
    lspSlsRtnExcel01ShowBatchJobStatus
  ),
  takeLatest(LspSlsRtnExcel01Types.LSP_SLS_RTN_EXCEL01_UPLOAD_EXCEL, lspSlsRtnExcel01UploadExcel),

  takeLatest(
    LspSlsRtnExcel01Types.LSP_SLS_RTN_EXCEL01_FETCH_DEBTOR_OPTIONS,
    lspSlsRtnExcel01FetchDebtorOptions
  ),

  takeLatest(
    LspSlsRtnExcel01Types.LSP_SLS_RTN_EXCEL01_FETCH_PROJECT_OPTIONS,
    lspSlsRtnExcel01FetchProjectOptions
  )
];
