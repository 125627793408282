/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  documents: [],
  fetchIsLoading: false,
  sorts: {
    est_del_date: 'ascend',
    division_code: 'ascend',
    delivery_point_area_code: 'ascend'
  },
  filters: {},
  pageSize: '20',
  currentPage: 1,
  lastPage: 10,
  total: 0,
  selectedDocuments: [],
  workspaceIsVisible: false,
  createIsLoading: false,
  newDocuments: []
};
