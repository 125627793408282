import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob1702ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1702GoToDocument: ['hdrId'],
  whseJob1702NewDocument: null,
  whseJob1702FetchWhseJob1702: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1702FetchWhseJob1702Loading: ['boolean'],
  whseJob1702FetchWhseJob1702Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  whseJob1702AddSelectedDocuments: ['selectedDocuments'],
  whseJob1702RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob1702SetWorkspaceVisible: ['boolean'],
  whseJob1702CreateWhseJob1702: ['hdrIds'],
  whseJob1702CreateWhseJob1702Loading: ['boolean'],
  whseJob1702CreateWhseJob1702Success: ['newDocuments']
});

export const WhseJob1702Types = Types;
export default Creators;
