import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import {
  AuditOutlined,
  EditOutlined,
  PrinterOutlined,
  DownloadOutlined,
  ReloadOutlined
} from '@ant-design/icons';

import { Row, Col, Card, Table, Button, Popconfirm } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import PickListIndexActions from '../../Stores/PickListIndex/Actions';

class PickListIndexScreen extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchPickListIndex,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchPickListIndex(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const {
      intl,
      sorts,
      filters,
      goToPrint,
      excelOutbOrdDtls,
      goToDocument,
      goToAudit
    } = this.props;

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_code'),
        // filter field
        key: 'doc_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'doc_code' }),
          'doc_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.doc_code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_date'),
        // filter field
        key: 'doc_date',
        ...AntDesignTable.getColumnSearchDateProps(
          filters,
          intl.formatMessage({ id: 'doc_date' }),
          'doc_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'doc_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_status'),
        // filter field
        ...AntDesignTable.getColumnSearchDocStatusProps(filters, 'doc_status'),
        render: (text, record) => <>{record.str_doc_status}</>
      },
      {
        width: 90,
        align: 'left',
        title: intl.formatMessage({ id: 'to_storage_bin' }),
        // sort field
        dataIndex: 'to_storage_bin_code',
        // filter field
        key: 'to_storage_bin',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'to_storage_bin' }),
          'to_storage_bin',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <b>{record.to_storage_bin_code}</b>
      },
      {
        width: 90,
        align: 'left',
        title: intl.formatMessage({ id: 'area' }),
        // sort field
        dataIndex: 'delivery_point_area_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'delivery_point_area_code'),
        // filter field
        key: 'delivery_point_area',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'area' }),
          'delivery_point_area',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.areas.map(value => (
              <div key={`${record.id}:${value.code}`}>
                <b>{value.code}</b>
                <br />
                {value.desc_01}
              </div>
            ))}
          </>
        )
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'salesman' }),
        // sort field
        dataIndex: 'salesman_username',
        // filter field
        key: 'salesman',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'salesman' }),
          'salesman',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.salesmans.map(value => (
              <div key={`${record.id}:${value.username}`}>
                <b>{value.username}</b>
              </div>
            ))}
          </>
        )
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'delivery_point' }),
        // sort field
        dataIndex: 'delivery_point_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'delivery_point_code'),
        // filter field
        key: 'delivery_point',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'delivery_point' }),
          'delivery_point',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.delivery_points.map(value => (
              <div key={`${record.id}:${value.code}`}>
                <b>{value.code}</b>
                <br />
                {value.company_name_01}
              </div>
            ))}
          </>
        )
      },
      {
        width: 150,
        // fixed: 'right',
        align: 'left',
        title: intl.formatMessage({ id: 'documents' }),
        // sort field
        dataIndex: 'outb_ord_hdr_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'outb_ord_hdr_code'),
        // filter field
        key: 'outb_ord_hdr',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'documents' }),
          'outb_ord_hdr',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.outb_ord_hdrs.map(value => (
              <div key={`${record.id}:${value.id}`} style={{ width: '100%', textAlign: 'right' }}>
                <div>
                  <b>{value.shipping_doc_code}</b>
                </div>
                <div>
                  <b>{value.ref_code_01}</b>
                </div>
                <div>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(value.net_amt)}
                </div>
              </div>
            ))}
          </>
        )
      },

      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'desc_01' }),
        // sort field
        dataIndex: 'desc_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_01'),
        // filter field
        key: 'desc_01',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'desc_01' }),
          'desc_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'desc_02' }),
        // sort field
        dataIndex: 'desc_02',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_02'),
        // filter field
        key: 'desc_02',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'desc_02' }),
          'desc_02',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.desc_02}</>
      },

      {
        width: 50,
        fixed: 'right',
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_print' })}
              onConfirm={() => goToPrint(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
              disabled={record.doc_status !== 100}
            >
              <Button
                type="dashed"
                icon={<PrinterOutlined />}
                disabled={record.doc_status !== 100}
              />
            </Popconfirm>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_download' })}
              onConfirm={() => excelOutbOrdDtls(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
              disabled={record.doc_status !== 100}
            >
              <Button
                type="dashed"
                icon={<DownloadOutlined />}
                disabled={record.doc_status !== 100}
              />
            </Popconfirm>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => goToDocument(record.id)} />
            <Button type="dashed" icon={<AuditOutlined />} onClick={() => goToAudit(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      intl,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments
    } = this.props;

    return (
      <Card
        title={intl.formatMessage({ id: 'pick_lists' })}
        bordered={false}
        headStyle={{ textAlign: 'center' }}
        bodyStyle={{ padding: 0 }}
      >
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              // disabled: record.str_doc_status !== 'WIP'
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />
      </Card>
    );
  }
}

PickListIndexScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,
  goToPrint: PropTypes.func,
  excelOutbOrdDtls: PropTypes.func,
  goToAudit: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchPickListIndex: PropTypes.func
};

PickListIndexScreen.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},
  goToPrint() {},
  excelOutbOrdDtls() {},
  goToAudit() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchPickListIndex() {}
};

const mapStateToProps = state => ({
  timestamp: state.pickListIndex.timestamp,

  documents: state.pickListIndex.documents,
  selectedDocuments: state.pickListIndex.selectedDocuments,

  sorts: state.pickListIndex.sorts,
  filters: state.pickListIndex.filters,

  currentPage: state.pickListIndex.currentPage,
  pageSize: state.pickListIndex.pageSize,
  total: state.pickListIndex.total,
  fetchIsLoading: state.pickListIndex.fetchIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PickListIndexActions.pickListIndexResetTimestamp(currentPage, sorts, filters, pageSize)
    ),
  goToDocument: hdrId => dispatch(PickListIndexActions.pickListIndexGoToDocument(hdrId)),
  goToPrint: document => dispatch(PickListIndexActions.pickListIndexGoToPrint(document)),
  excelOutbOrdDtls: document =>
    dispatch(PickListIndexActions.pickListIndexExcelOutbOrdDtls(document)),
  goToAudit: hdrId => dispatch(PickListIndexActions.pickListIndexGoToAudit(hdrId)),

  setWorkspaceVisible: boolean =>
    dispatch(PickListIndexActions.pickListIndexSetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(PickListIndexActions.pickListIndexAddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(PickListIndexActions.pickListIndexRemoveSelectedDocuments(selectedDocuments)),

  fetchPickListIndex: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PickListIndexActions.pickListIndexFetchPickListIndex(currentPage, sorts, filters, pageSize)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PickListIndexScreen));
