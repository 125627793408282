import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob1402IndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1402IndexGoToWhseJobDocument: ['hdrId'],
  whseJob1402IndexGoToAudit: ['hdrId'],
  whseJob1402IndexFetchWhseJob1402Index: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1402IndexFetchWhseJob1402IndexLoading: ['boolean'],
  whseJob1402IndexFetchWhseJob1402IndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  whseJob1402IndexAddSelectedDocuments: ['selectedDocuments'],
  whseJob1402IndexRemoveSelectedDocuments: ['selectedDocuments'],
  whseJob1402IndexSetWorkspaceVisible: ['boolean']
});

export const WhseJob1402IndexTypes = Types;
export default Creators;
