import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  uomListResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  uomListGoToResource: ['resId'],
  uomListNewResource: null,
  uomListGoToAudit: ['hdrId'],
  uomListFetchList: ['currentPage', 'sorts', 'filters', 'pageSize'],
  uomListFetchListLoading: ['boolean'],
  uomListFetchListSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  uomListAddSelectedDocuments: ['selectedDocuments'],
  uomListRemoveSelectedDocuments: ['selectedDocuments'],
  uomListSetWorkspaceVisible: ['boolean']
});

export const UomListTypes = Types;
export default Creators;
