import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  prfDel01FetchPrfDel01Loading: ['boolean'],
  prfDel01CreatePrfDel01Loading: ['boolean'],

  prfDel01ScanBarcode: ['value'],
  prfDel01ScanBarcodeSuccess: ['outbOrdHdrs'],
  prfDel01ScanBarcodeError: ['value'],

  prfDel01SearchDocument: ['search'],
  prfDel01SearchDocumentLoading: ['boolean'],
  prfDel01SearchDocumentSuccess: ['options'],

  prfDel01CreatePrfDel01: ['formikBag', 'formDetail', 'outbOrdHdrs'],
  prfDel01CreatePrfDel01Success: ['newDocuments'],

  prfDel01UpOutbOrd: ['outbOrdHdr'],
  prfDel01DownOutbOrd: ['outbOrdHdr'],
  prfDel01UpdateOutbOrds: ['outbOrdHdrs']
});

export const PrfDel01Types = Types;
export default Creators;
