import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { DeleteOutlined } from '@ant-design/icons';

import { Table, Popconfirm, Button, Row, Col, Typography, Select } from 'antd';

import UserDetailActions from '../../Stores/UserDetail/Actions';

const { Text } = Typography;
const { Option } = Select;

class SiteFlowTable extends React.PureComponent {
  constructor() {
    super();

    this.state = { pageSize: 20 };

    this.getDocumentColumns = this.getDocumentColumns.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.useOnAddSiteFlow = this.useOnAddSiteFlow.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { resId, showUserSiteFlows } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('timestamp')) {
      showUserSiteFlows(resId);
    }
  }

  componentWillUnmount() {}

  handleSearch(value) {
    const { resId, fetchSiteFlowOptions } = this.props;
    fetchSiteFlowOptions(resId, value);
  }

  handleChange(value, option) {
    const { setSiteFlowOption } = this.props;
    const curOption = { value: parseInt(option.key, 10), label: option.props.children };
    setSiteFlowOption(curOption);
  }

  getDocumentColumns() {
    const { intl, resId, removeUserSiteFlow } = this.props;

    return [
      {
        // fixed: 'left',
        width: 50,
        align: 'right',
        title: '#',
        key: 'line_no',
        render: (text, record, index) => <span style={{ wordBreak: 'normal' }}>{index + 1}</span>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        // filter field
        key: 'code',
        render: (text, record) => record.site_flow_code
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'ref_code_01' }),
        // sort field
        dataIndex: 'ref_code_01',
        // filter field
        key: 'ref_code_01',
        render: (text, record) => record.site_flow_ref_code_01
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'desc_01' }),
        // sort field
        dataIndex: 'desc_01',
        // filter field
        key: 'desc_01',
        render: (text, record) => record.site_flow_desc_01
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'desc_02' }),
        // sort field
        dataIndex: 'desc_02',
        // filter field
        key: 'desc_02',
        render: (text, record) => record.site_flow_desc_02
      },
      {
        width: 50,
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_site_flow' })}
              onConfirm={() => {
                removeUserSiteFlow(resId, record.id);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnAddSiteFlow() {
    const { resId, siteFlowOption, addUserSiteFlow } = this.props;

    if (siteFlowOption.value > 0) {
      addUserSiteFlow(resId, siteFlowOption.value);
    }
  }

  render() {
    const {
      intl,
      userSiteFlows,
      userSiteFlowIsLoading,
      siteFlowOption,
      siteFlowOptions,
      siteFlowOptionIsLoading
    } = this.props;
    const { pageSize: statePageSize } = this.state;

    const options = siteFlowOptions.map(d => <Option key={d.value}>{`${d.label}`}</Option>);

    return (
      <>
        <Row type="flex" justify="start" gutter={[0, 8]}>
          <Col span={3}>
            <Text>{intl.formatMessage({ id: 'new_site_flow' })}</Text>
          </Col>
          <Col span={8}>
            <Select
              showSearch
              value={siteFlowOption.label}
              placeholder={intl.formatMessage({ id: 'key_in_code_or_description' })}
              style={{ width: 300 }}
              defaultActiveFirstOption={false}
              showArrow
              filterOption={false}
              onSearch={this.handleSearch}
              onChange={this.handleChange}
              notFoundContent={null}
              loading={siteFlowOptionIsLoading}
              onDropdownVisibleChange={open => {
                if (open && options.length === 0) {
                  this.handleSearch('');
                }
              }}
            >
              {options}
            </Select>
          </Col>
          <Col span={3}>
            <Button
              type="primary"
              disabled={siteFlowOption.value === 0}
              loading={userSiteFlowIsLoading}
              onClick={this.useOnAddSiteFlow}
            >
              {intl.formatMessage({ id: 'add' })}
            </Button>
          </Col>
        </Row>
        <Row type="flex" justify="start" gutter={[0, 8]}>
          <Col span={24}>
            <Table
              // title={() => intl.formatMessage({ id: 'site_flows' })}
              // rowSelection={rowSelection}
              rowKey="id"
              pagination={{
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50'],
                onShowSizeChange: (current, pageSize) => {
                  this.setState({
                    pageSize
                  });
                },
                pageSize: statePageSize
              }}
              columns={this.getDocumentColumns()}
              dataSource={userSiteFlows}
              loading={userSiteFlowIsLoading}
              bordered
              scroll={{ x: 900 }}
            />
          </Col>
        </Row>
      </>
    );
  }
}

SiteFlowTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  showUserSiteFlows: PropTypes.func,
  userSiteFlows: PropTypes.arrayOf(PropTypes.object),
  userSiteFlowIsLoading: PropTypes.bool,
  removeUserSiteFlow: PropTypes.func,

  resId: PropTypes.number,

  siteFlowOption: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string
  }),
  siteFlowOptions: PropTypes.arrayOf(PropTypes.object),
  siteFlowOptionIsLoading: PropTypes.bool,
  fetchSiteFlowOptions: PropTypes.func,
  setSiteFlowOption: PropTypes.func,

  addUserSiteFlow: PropTypes.func
};

SiteFlowTable.defaultProps = {
  intl: {},

  showUserSiteFlows() {},
  userSiteFlows: [],
  userSiteFlowIsLoading: false,
  removeUserSiteFlow() {},

  resId: 0,

  siteFlowOption: { value: 0, label: '' },
  siteFlowOptions: [],
  siteFlowOptionIsLoading: false,
  fetchSiteFlowOptions() {},
  setSiteFlowOption() {},

  addUserSiteFlow() {}
};

const mapStateToProps = state => ({
  timestamp: state.userDetail.timestamp,
  resId: state.userDetail.resId,
  userSiteFlows: state.userDetail.userSiteFlows,
  userSiteFlowIsLoading: state.userDetail.userSiteFlowIsLoading,

  siteFlowOption: state.userDetail.siteFlowOption,
  siteFlowOptions: state.userDetail.siteFlowOptions,
  siteFlowOptionIsLoading: state.userDetail.siteFlowOptionIsLoading
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: () => dispatch(UserDetailActions.userDetailResetTimestamp()),
  showUserSiteFlows: resId => dispatch(UserDetailActions.userDetailShowUserSiteFlows(resId)),

  fetchSiteFlowOptions: (resId, search) =>
    dispatch(UserDetailActions.userDetailFetchSiteFlowOptions(resId, search)),
  setSiteFlowOption: option => dispatch(UserDetailActions.userDetailSetSiteFlowOption(option)),

  addUserSiteFlow: (resId, siteFlowId) =>
    dispatch(UserDetailActions.userDetailAddUserSiteFlow(resId, siteFlowId)),
  removeUserSiteFlow: (resId, userSiteFlowId) =>
    dispatch(UserDetailActions.userDetailRemoveUserSiteFlow(resId, userSiteFlowId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SiteFlowTable));
