/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StorageRowList01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const storageRowList01ResetTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  toStorageRowOptions: INITIAL_STATE.toStorageRowOptions,
  toStorageRowOption: INITIAL_STATE.toStorageRowOption
});

export const storageRowList01FetchStorageRowList01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const storageRowList01FetchStorageRowList01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const storageRowList01AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const storageRowList01RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const storageRowList01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StorageRowList01Types.STORAGE_ROW_LIST01_RESET_TIMESTAMP]: storageRowList01ResetTimestamp,
  [StorageRowList01Types.STORAGE_ROW_LIST01_FETCH_STORAGE_ROW_LIST01_LOADING]: storageRowList01FetchStorageRowList01Loading,
  [StorageRowList01Types.STORAGE_ROW_LIST01_FETCH_STORAGE_ROW_LIST01_SUCCESS]: storageRowList01FetchStorageRowList01Success,
  [StorageRowList01Types.STORAGE_ROW_LIST01_ADD_SELECTED_DOCUMENTS]: storageRowList01AddSelectedDocuments,
  [StorageRowList01Types.STORAGE_ROW_LIST01_REMOVE_SELECTED_DOCUMENTS]: storageRowList01RemoveSelectedDocuments,
  [StorageRowList01Types.STORAGE_ROW_LIST01_SET_WORKSPACE_VISIBLE]: storageRowList01SetWorkspaceVisible
});
