/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StkIssDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const stkIssDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const stkIssDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  currencyOptions: []
});

export const stkIssDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const stkIssDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader,
  currencyOptions: [],
  toDivisionOptions: [],
  toLocationOptions: [],
  itemOptions: [],
  uomOptions: [],
  locationOptions: [],
  hdrToLocationOptions: []
});

export const stkIssDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const stkIssDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const stkIssDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const stkIssDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  itemOptions: [],
  uomOptions: []
});

export const stkIssDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const stkIssDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

export const stkIssDetailFetchToDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  toDivisionIsLoading: boolean
});

export const stkIssDetailFetchToDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  toDivisionOptions: options
});

export const stkIssDetailFetchToLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  toLocationIsLoading: boolean
});

export const stkIssDetailFetchToLocationOptionSuccess = (state, { options }) => ({
  ...state,
  toLocationOptions: options
});

export const stkIssDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const stkIssDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const stkIssDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const stkIssDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const stkIssDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const stkIssDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const stkIssDetailFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const stkIssDetailFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const stkIssDetailChangeToDivision = state => {
  // reset the select2 cache

  return {
    ...state,
    toLocationOptions: []
  };
};

export const stkIssDetailFetchHdrToLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  hdrToLocationIsLoading: boolean
});

export const stkIssDetailFetchHdrToLocationOptionSuccess = (state, { options }) => ({
  ...state,
  hdrToLocationOptions: options
});

export const stkIssDetailFetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const stkIssDetailFetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StkIssDetailTypes.STK_ISS_DETAIL_RESET_TIMESTAMP]: stkIssDetailResetTimestamp,
  [StkIssDetailTypes.STK_ISS_DETAIL_SET_HDR_ID]: stkIssDetailSetHdrId,

  [StkIssDetailTypes.STK_ISS_DETAIL_SHOW_HEADER_SUCCESS]: stkIssDetailShowHeaderSuccess,

  [StkIssDetailTypes.STK_ISS_DETAIL_UPDATE_DOCUMENT_SUCCESS]: stkIssDetailUpdateDocumentSuccess,

  [StkIssDetailTypes.STK_ISS_DETAIL_SHOW_DOCUMENT_LOADING]: stkIssDetailShowDocumentLoading,

  [StkIssDetailTypes.STK_ISS_DETAIL_SHOW_DETAILS_SUCCESS]: stkIssDetailShowDetailsSuccess,

  [StkIssDetailTypes.STK_ISS_DETAIL_SET_DETAIL_VISIBLE]: stkIssDetailSetDetailVisible,
  [StkIssDetailTypes.STK_ISS_DETAIL_SET_DOCUMENT_DETAIL]: stkIssDetailSetDocumentDetail,

  [StkIssDetailTypes.STK_ISS_DETAIL_UPDATE_DETAILS]: stkIssDetailUpdateDetails,
  [StkIssDetailTypes.STK_ISS_DETAIL_CREATE_DETAIL]: stkIssDetailCreateDetail,

  [StkIssDetailTypes.STK_ISS_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: stkIssDetailFetchCurrencyOptionLoading,
  [StkIssDetailTypes.STK_ISS_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: stkIssDetailFetchCurrencyOptionSuccess,

  [StkIssDetailTypes.STK_ISS_DETAIL_FETCH_TO_DIVISION_OPTION_LOADING]: stkIssDetailFetchToDivisionOptionLoading,
  [StkIssDetailTypes.STK_ISS_DETAIL_FETCH_TO_DIVISION_OPTION_SUCCESS]: stkIssDetailFetchToDivisionOptionSuccess,

  [StkIssDetailTypes.STK_ISS_DETAIL_FETCH_TO_LOCATION_OPTION_LOADING]: stkIssDetailFetchToLocationOptionLoading,
  [StkIssDetailTypes.STK_ISS_DETAIL_FETCH_TO_LOCATION_OPTION_SUCCESS]: stkIssDetailFetchToLocationOptionSuccess,
  [StkIssDetailTypes.STK_ISS_DETAIL_CHANGE_TO_DIVISION]: stkIssDetailChangeToDivision,

  [StkIssDetailTypes.STK_ISS_DETAIL_FETCH_ITEM_OPTION_LOADING]: stkIssDetailFetchItemOptionLoading,
  [StkIssDetailTypes.STK_ISS_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: stkIssDetailFetchItemOptionSuccess,

  [StkIssDetailTypes.STK_ISS_DETAIL_FETCH_UOM_OPTION_LOADING]: stkIssDetailFetchUomOptionLoading,
  [StkIssDetailTypes.STK_ISS_DETAIL_FETCH_UOM_OPTION_SUCCESS]: stkIssDetailFetchUomOptionSuccess,

  [StkIssDetailTypes.STK_ISS_DETAIL_FETCH_LOCATION_OPTION_LOADING]: stkIssDetailFetchLocationOptionLoading,
  [StkIssDetailTypes.STK_ISS_DETAIL_FETCH_LOCATION_OPTION_SUCCESS]: stkIssDetailFetchLocationOptionSuccess,

  [StkIssDetailTypes.STK_ISS_DETAIL_FETCH_HDR_TO_LOCATION_OPTION_LOADING]: stkIssDetailFetchHdrToLocationOptionLoading,
  [StkIssDetailTypes.STK_ISS_DETAIL_FETCH_HDR_TO_LOCATION_OPTION_SUCCESS]: stkIssDetailFetchHdrToLocationOptionSuccess,

  [StkIssDetailTypes.STK_ISS_DETAIL_FETCH_PROJECT_OPTION_LOADING]: stkIssDetailFetchProjectOptionLoading,
  [StkIssDetailTypes.STK_ISS_DETAIL_FETCH_PROJECT_OPTION_SUCCESS]: stkIssDetailFetchProjectOptionSuccess
});
