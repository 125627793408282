import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import WhseJob06IndexActions, { WhseJob06IndexTypes } from '../Stores/WhseJob06Index/Actions';

const getAppStore = state => state.app;

export function* whseJob06IndexFetchWhseJob06Index({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(WhseJob06IndexActions.whseJob06IndexFetchWhseJob06IndexLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/indexWhseJob06/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        WhseJob06IndexActions.whseJob06IndexFetchWhseJob06IndexSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob06IndexActions.whseJob06IndexFetchWhseJob06IndexLoading(false));
  }
}

export function* whseJob06IndexGoToWhseJobDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/whseJob06Detail/update/${hdrId}`));
}

export function* whseJob06IndexGoToAudit({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/auditResource/WHSE_JOB/${hdrId}/WhseJobHdr`));
}

export const saga = [
  takeLatest(
    WhseJob06IndexTypes.WHSE_JOB06_INDEX_FETCH_WHSE_JOB06_INDEX,
    whseJob06IndexFetchWhseJob06Index
  ),
  takeLatest(
    WhseJob06IndexTypes.WHSE_JOB06_INDEX_GO_TO_WHSE_JOB_DOCUMENT,
    whseJob06IndexGoToWhseJobDocument
  ),
  takeLatest(WhseJob06IndexTypes.WHSE_JOB06_INDEX_GO_TO_AUDIT, whseJob06IndexGoToAudit)
];
