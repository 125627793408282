import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  inbOrd05ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrd05GoToPrint: ['document'],
  inbOrd05GoToDocument: ['hdrId'],
  inbOrd05FetchInbOrd05: ['currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrd05FetchInbOrd05Loading: ['boolean'],
  inbOrd05FetchInbOrd05Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  inbOrd05AddSelectedDocuments: ['selectedDocuments'],
  inbOrd05RemoveSelectedDocuments: ['selectedDocuments'],
  inbOrd05SetWorkspaceVisible: ['boolean'],
  inbOrd05CreateInbOrd05: ['hdrIds'],
  inbOrd05CreateInbOrd05Loading: ['boolean'],
  inbOrd05CreateInbOrd05Success: ['newDocuments']
});

export const InbOrd05Types = Types;
export default Creators;
