import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadListDetailBoardResetWipPickListTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  loadListDetailBoardFetchWipPickList: ['currentPage', 'sorts', 'filters', 'pageSize'],
  loadListDetailBoardFetchWipPickListLoading: ['boolean'],
  loadListDetailBoardFetchWipPickListSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  loadListDetailBoardResetWipLoadListTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  loadListDetailBoardFetchWipLoadList: ['currentPage', 'sorts', 'filters', 'pageSize'],
  loadListDetailBoardFetchWipLoadListLoading: ['boolean'],
  loadListDetailBoardFetchWipLoadListSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  loadListDetailBoardResetWipWhseJobTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  loadListDetailBoardFetchWipWhseJob: ['currentPage', 'sorts', 'filters', 'pageSize'],
  loadListDetailBoardFetchWipWhseJobLoading: ['boolean'],
  loadListDetailBoardFetchWipWhseJobSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  loadListDetailBoardResetCompleteLoadListTimestamp: [
    'currentPage',
    'sorts',
    'filters',
    'pageSize'
  ],
  loadListDetailBoardFetchCompleteLoadList: ['currentPage', 'sorts', 'filters', 'pageSize'],
  loadListDetailBoardFetchCompleteLoadListLoading: ['boolean'],
  loadListDetailBoardFetchCompleteLoadListSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  loadListDetailBoardSetWipPickListDialogVisible: ['boolean'],
  loadListDetailBoardShowWipPickListSummary: ['docId'],
  loadListDetailBoardShowWipPickListSummaryLoading: ['boolean'],
  loadListDetailBoardShowWipPickListSummarySuccess: ['document'],

  loadListDetailBoardSetWipLoadListDialogVisible: ['boolean'],
  loadListDetailBoardShowWipLoadListSummary: ['docId'],
  loadListDetailBoardShowWipLoadListSummaryLoading: ['boolean'],
  loadListDetailBoardShowWipLoadListSummarySuccess: ['document'],

  loadListDetailBoardSetWipWhseJobDialogVisible: ['boolean'],
  loadListDetailBoardShowWipWhseJobSummary: ['docId'],
  loadListDetailBoardShowWipWhseJobSummaryLoading: ['boolean'],
  loadListDetailBoardShowWipWhseJobSummarySuccess: ['document'],

  loadListDetailBoardSetCompleteLoadListDialogVisible: ['boolean'],
  loadListDetailBoardShowCompleteLoadListSummary: ['docId'],
  loadListDetailBoardShowCompleteLoadListSummaryLoading: ['boolean'],
  loadListDetailBoardShowCompleteLoadListSummarySuccess: ['document']
});

export const LoadListDetailBoardTypes = Types;
export default Creators;
