/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { InbOrd07Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const inbOrd07ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
});

export const inbOrd07FetchInbOrd07Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const inbOrd07FetchInbOrd07Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const inbOrd07AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const inbOrd07RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const inbOrd07SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const inbOrd07CreateInbOrd07Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const inbOrd07CreateInbOrd07Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [InbOrd07Types.INB_ORD07_RESET_TIMESTAMP]: inbOrd07ResetTimestamp,
  [InbOrd07Types.INB_ORD07_FETCH_INB_ORD07_LOADING]: inbOrd07FetchInbOrd07Loading,
  [InbOrd07Types.INB_ORD07_FETCH_INB_ORD07_SUCCESS]: inbOrd07FetchInbOrd07Success,
  [InbOrd07Types.INB_ORD07_ADD_SELECTED_DOCUMENTS]: inbOrd07AddSelectedDocuments,
  [InbOrd07Types.INB_ORD07_REMOVE_SELECTED_DOCUMENTS]: inbOrd07RemoveSelectedDocuments,
  [InbOrd07Types.INB_ORD07_SET_WORKSPACE_VISIBLE]: inbOrd07SetWorkspaceVisible,
  [InbOrd07Types.INB_ORD07_CREATE_INB_ORD07_LOADING]: inbOrd07CreateInbOrd07Loading,
  [InbOrd07Types.INB_ORD07_CREATE_INB_ORD07_SUCCESS]: inbOrd07CreateInbOrd07Success
});
