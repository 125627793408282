import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadList0101ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  loadList0101GoToDocument: ['hdrId'],
  loadList0101FetchLoadList0101: ['currentPage', 'sorts', 'filters', 'pageSize'],
  loadList0101FetchLoadList0101Loading: ['boolean'],
  loadList0101FetchLoadList0101Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  loadList0101AddSelectedDocuments: ['selectedDocuments'],
  loadList0101RemoveSelectedDocuments: ['selectedDocuments'],
  loadList0101SetWorkspaceVisible: ['boolean'],
  loadList0101PrintLoadList0101: ['hdrIds'],
  loadList0101PrintLoadList0101Loading: ['boolean']
});

export const LoadList0101Types = Types;
export default Creators;
