/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { TrfOrdDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const trfOrdDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const trfOrdDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  salesmanOptions: [],
  deliveryPointOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const trfOrdDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const trfOrdDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader,
  currencyOptions: [],
  toDivisionOptions: [],
  toLocationOptions: [],
  itemOptions: [],
  uomOptions: [],
  locationOptions: [],
  hdrToLocationOptions: []
});

export const trfOrdDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const trfOrdDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const trfOrdDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const trfOrdDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  itemOptions: [],
  itemBatchOptions: [],
  uomOptions: []
});

export const trfOrdDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const trfOrdDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

export const trfOrdDetailFetchToDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  toDivisionIsLoading: boolean
});

export const trfOrdDetailFetchToDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  toDivisionOptions: options
});

export const trfOrdDetailFetchToLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  toLocationIsLoading: boolean
});

export const trfOrdDetailFetchToLocationOptionSuccess = (state, { options }) => ({
  ...state,
  toLocationOptions: options
});

export const trfOrdDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const trfOrdDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const trfOrdDetailFetchItemBatchOptionLoading = (state, { boolean }) => ({
  ...state,
  itemBatchIsLoading: boolean
});

export const trfOrdDetailFetchItemBatchOptionSuccess = (state, { options }) => ({
  ...state,
  itemBatchOptions: options
});

export const trfOrdDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const trfOrdDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const trfOrdDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const trfOrdDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const trfOrdDetailFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const trfOrdDetailFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const trfOrdDetailChangeToDivision = (state, { toDivisionId }) => {
  // reset the select2 cache

  return {
    ...state,
    to_division_id: toDivisionId,
    toLocationOptions: []
  };
};

export const trfOrdDetailFetchHdrToLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  hdrToLocationIsLoading: boolean
});

export const trfOrdDetailFetchHdrToLocationOptionSuccess = (state, { options }) => ({
  ...state,
  hdrToLocationOptions: options
});

export const trfOrdDetailFetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const trfOrdDetailFetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [TrfOrdDetailTypes.TRF_ORD_DETAIL_RESET_TIMESTAMP]: trfOrdDetailResetTimestamp,
  [TrfOrdDetailTypes.TRF_ORD_DETAIL_SET_HDR_ID]: trfOrdDetailSetHdrId,

  [TrfOrdDetailTypes.TRF_ORD_DETAIL_SHOW_HEADER_SUCCESS]: trfOrdDetailShowHeaderSuccess,

  [TrfOrdDetailTypes.TRF_ORD_DETAIL_UPDATE_DOCUMENT_SUCCESS]: trfOrdDetailUpdateDocumentSuccess,

  [TrfOrdDetailTypes.TRF_ORD_DETAIL_SHOW_DOCUMENT_LOADING]: trfOrdDetailShowDocumentLoading,

  [TrfOrdDetailTypes.TRF_ORD_DETAIL_SHOW_DETAILS_SUCCESS]: trfOrdDetailShowDetailsSuccess,

  [TrfOrdDetailTypes.TRF_ORD_DETAIL_SET_DETAIL_VISIBLE]: trfOrdDetailSetDetailVisible,
  [TrfOrdDetailTypes.TRF_ORD_DETAIL_SET_DOCUMENT_DETAIL]: trfOrdDetailSetDocumentDetail,

  [TrfOrdDetailTypes.TRF_ORD_DETAIL_UPDATE_DETAILS]: trfOrdDetailUpdateDetails,
  [TrfOrdDetailTypes.TRF_ORD_DETAIL_CREATE_DETAIL]: trfOrdDetailCreateDetail,

  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: trfOrdDetailFetchCurrencyOptionLoading,
  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: trfOrdDetailFetchCurrencyOptionSuccess,

  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_TO_DIVISION_OPTION_LOADING]: trfOrdDetailFetchToDivisionOptionLoading,
  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_TO_DIVISION_OPTION_SUCCESS]: trfOrdDetailFetchToDivisionOptionSuccess,

  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_TO_LOCATION_OPTION_LOADING]: trfOrdDetailFetchToLocationOptionLoading,
  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_TO_LOCATION_OPTION_SUCCESS]: trfOrdDetailFetchToLocationOptionSuccess,
  [TrfOrdDetailTypes.TRF_ORD_DETAIL_CHANGE_TO_DIVISION]: trfOrdDetailChangeToDivision,

  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_ITEM_OPTION_LOADING]: trfOrdDetailFetchItemOptionLoading,
  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: trfOrdDetailFetchItemOptionSuccess,

  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_ITEM_BATCH_OPTION_LOADING]: trfOrdDetailFetchItemBatchOptionLoading,
  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_ITEM_BATCH_OPTION_SUCCESS]: trfOrdDetailFetchItemBatchOptionSuccess,

  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_UOM_OPTION_LOADING]: trfOrdDetailFetchUomOptionLoading,
  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_UOM_OPTION_SUCCESS]: trfOrdDetailFetchUomOptionSuccess,

  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_LOCATION_OPTION_LOADING]: trfOrdDetailFetchLocationOptionLoading,
  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_LOCATION_OPTION_SUCCESS]: trfOrdDetailFetchLocationOptionSuccess,

  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_HDR_TO_LOCATION_OPTION_LOADING]: trfOrdDetailFetchHdrToLocationOptionLoading,
  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_HDR_TO_LOCATION_OPTION_SUCCESS]: trfOrdDetailFetchHdrToLocationOptionSuccess,

  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_PROJECT_OPTION_LOADING]: trfOrdDetailFetchProjectOptionLoading,
  [TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_PROJECT_OPTION_SUCCESS]: trfOrdDetailFetchProjectOptionSuccess
});
