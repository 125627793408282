import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import WorkerPutAwayAnalysisReportActions, {
  WorkerPutAwayAnalysisReportTypes
} from '../Stores/WorkerPutAwayAnalysisReport/Actions';

const getAppStore = state => state.app;

export function* workerPutAwayAnalysisReportInitWorkerPutAwayAnalysis() {
  try {
    yield put(WorkerPutAwayAnalysisReportActions.workerPutAwayAnalysisReportReportLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `workerReport/initWorkerPutAwayAnalysis/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        WorkerPutAwayAnalysisReportActions.workerPutAwayAnalysisReportInitWorkerPutAwayAnalysisSuccess(
          result.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WorkerPutAwayAnalysisReportActions.workerPutAwayAnalysisReportReportLoading(false));
  }
}

export function* workerPutAwayAnalysisReportWorkerPutAwayAnalysis({ formikBag, criteria }) {
  try {
    formikBag.setSubmitting(true);
    yield put(WorkerPutAwayAnalysisReportActions.workerPutAwayAnalysisReportReportLoading(true));

    const objCriteria = {};
    if ('start_date' in criteria) {
      objCriteria.start_date = criteria.start_date;
    }
    if ('end_date' in criteria) {
      objCriteria.end_date = criteria.end_date;
    }
    if ('user_ids_select2' in criteria) {
      const values = criteria.user_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.user_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    let columns = [];
    if (criteria.columns.target.length > 0) {
      columns = criteria.columns.target.map(entry => {
        return entry.column;
      });
    }

    const app = yield select(getAppStore);
    const postData = {
      sorts: [],
      criteria: objCriteria,
      columns
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `workerReport/workerPutAwayAnalysis/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(
        WorkerPutAwayAnalysisReportActions.workerPutAwayAnalysisReportWorkerPutAwayAnalysisSuccess(
          criteria,
          result.data.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(WorkerPutAwayAnalysisReportActions.workerPutAwayAnalysisReportReportLoading(false));
  }
}

export function* workerPutAwayAnalysisReportFetchUserOptions({ search }) {
  try {
    yield put(
      WorkerPutAwayAnalysisReportActions.workerPutAwayAnalysisReportFetchUserOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `user/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.username} ${d.first_name} ${d.last_name}`
      }));

      yield put(
        WorkerPutAwayAnalysisReportActions.workerPutAwayAnalysisReportFetchUserOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      WorkerPutAwayAnalysisReportActions.workerPutAwayAnalysisReportFetchUserOptionLoading(false)
    );
  }
}

export const saga = [
  takeLatest(
    WorkerPutAwayAnalysisReportTypes.WORKER_PUT_AWAY_ANALYSIS_REPORT_INIT_WORKER_PUT_AWAY_ANALYSIS,
    workerPutAwayAnalysisReportInitWorkerPutAwayAnalysis
  ),
  takeLatest(
    WorkerPutAwayAnalysisReportTypes.WORKER_PUT_AWAY_ANALYSIS_REPORT_WORKER_PUT_AWAY_ANALYSIS,
    workerPutAwayAnalysisReportWorkerPutAwayAnalysis
  ),
  takeLatest(
    WorkerPutAwayAnalysisReportTypes.WORKER_PUT_AWAY_ANALYSIS_REPORT_FETCH_USER_OPTIONS,
    workerPutAwayAnalysisReportFetchUserOptions
  )
];
