import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';

import { Row, Card, Col, Select } from 'antd';

import SlsOrdSummaryCard from './SlsOrdSummaryCard';
import LspSlsOrdSummaryCard from './LspSlsOrdSummaryCard';
import PickListSummaryCard from './PickListSummaryCard';
import LoadListSummaryCard from './LoadListSummaryCard';

import SummaryBoardActions from '../../Stores/SummaryBoard/Actions';

const { Option } = Select;

class SummaryBoardScreen extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      intervalId: 0,
      // milisecond
      refreshInterval: 300000
    };

    this.handleInterval = this.handleInterval.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    const { refreshInterval } = this.state;

    if (refreshInterval > 0) {
      const intervalId = setInterval(this.handleInterval, refreshInterval);
      this.setState({
        intervalId
      });
    }
  }

  componentWillUnmount() {
    const { intervalId } = this.state;

    clearInterval(intervalId);
  }

  handleInterval() {
    const {
      slsOrdSummaryResetTimestampSlsOrds,
      slsOrdSummaryResetTimestampOutbOrds,
      slsOrdSummaryResetTimestampInvDocs,
      slsOrdSummaryResetTimestampNoPrfDels,

      lspSlsOrdSummaryResetTimestampSlsOrds,
      lspSlsOrdSummaryResetTimestampOutbOrds,
      lspSlsOrdSummaryResetTimestampDOs,
      lspSlsOrdSummaryResetTimestampNoPrfDels,

      pickListSummaryResetTimestampOutbOrds,
      pickListSummaryResetTimestampWip,
      pickListSummaryResetTimestampWhseJobs,
      pickListSummaryResetTimestampCompleted,

      loadListSummaryResetTimestampPending,
      loadListSummaryResetTimestampWip,
      loadListSummaryResetTimestampWhseJobs,
      loadListSummaryResetTimestampCompleted
    } = this.props;

    slsOrdSummaryResetTimestampSlsOrds();
    slsOrdSummaryResetTimestampOutbOrds();
    slsOrdSummaryResetTimestampInvDocs();
    slsOrdSummaryResetTimestampNoPrfDels();

    lspSlsOrdSummaryResetTimestampSlsOrds();
    lspSlsOrdSummaryResetTimestampOutbOrds();
    lspSlsOrdSummaryResetTimestampDOs();
    lspSlsOrdSummaryResetTimestampNoPrfDels();

    pickListSummaryResetTimestampOutbOrds();
    pickListSummaryResetTimestampWip();
    pickListSummaryResetTimestampWhseJobs();
    pickListSummaryResetTimestampCompleted();

    loadListSummaryResetTimestampPending();
    loadListSummaryResetTimestampWip();
    loadListSummaryResetTimestampWhseJobs();
    loadListSummaryResetTimestampCompleted();
  }

  handleSelectChange(value) {
    let { intervalId } = this.state;

    this.setState({
      refreshInterval: value
    });

    clearInterval(intervalId);

    if (value > 0) {
      intervalId = setInterval(this.handleInterval, value);

      this.setState({
        intervalId
      });
    }
  }

  render() {
    const { refreshInterval } = this.state;

    const { intl } = this.props;

    return (
      <Card
        title={intl.formatMessage({ id: 'outbound_summary_board' })}
        extra={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Select value={refreshInterval} onChange={this.handleSelectChange} style={{ width: 75 }}>
            <Option value={0}>off</Option>
            <Option value={30000}>30s</Option>
            <Option value={60000}>1min</Option>
            <Option value={120000}>2min</Option>
            <Option value={300000}>5min</Option>
          </Select>
        }
      >
        <Row justify="space-around" style={{ margin: 'auto' }}>
          <Col span={6}>
            <SlsOrdSummaryCard />
          </Col>
          <Col span={6}>
            <LspSlsOrdSummaryCard />
          </Col>
        </Row>
        <Row justify="space-around" style={{ margin: 'auto' }}>
          <Col span={6}>
            <PickListSummaryCard />
          </Col>
          <Col span={6}>
            <LoadListSummaryCard />
          </Col>
        </Row>
      </Card>
    );
  }
}

SummaryBoardScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types

  slsOrdSummaryResetTimestampSlsOrds: PropTypes.func,
  slsOrdSummaryResetTimestampOutbOrds: PropTypes.func,
  slsOrdSummaryResetTimestampInvDocs: PropTypes.func,
  slsOrdSummaryResetTimestampNoPrfDels: PropTypes.func,

  lspSlsOrdSummaryResetTimestampSlsOrds: PropTypes.func,
  lspSlsOrdSummaryResetTimestampOutbOrds: PropTypes.func,
  lspSlsOrdSummaryResetTimestampDOs: PropTypes.func,
  lspSlsOrdSummaryResetTimestampNoPrfDels: PropTypes.func,

  pickListSummaryResetTimestampOutbOrds: PropTypes.func,
  pickListSummaryResetTimestampWip: PropTypes.func,
  pickListSummaryResetTimestampWhseJobs: PropTypes.func,
  pickListSummaryResetTimestampCompleted: PropTypes.func,

  loadListSummaryResetTimestampPending: PropTypes.func,
  loadListSummaryResetTimestampWip: PropTypes.func,
  loadListSummaryResetTimestampWhseJobs: PropTypes.func,
  loadListSummaryResetTimestampCompleted: PropTypes.func
};

SummaryBoardScreen.defaultProps = {
  intl: {},

  slsOrdSummaryResetTimestampSlsOrds() {},
  slsOrdSummaryResetTimestampOutbOrds() {},
  slsOrdSummaryResetTimestampInvDocs() {},
  slsOrdSummaryResetTimestampNoPrfDels() {},

  lspSlsOrdSummaryResetTimestampSlsOrds() {},
  lspSlsOrdSummaryResetTimestampOutbOrds() {},
  lspSlsOrdSummaryResetTimestampDOs() {},
  lspSlsOrdSummaryResetTimestampNoPrfDels() {},

  pickListSummaryResetTimestampOutbOrds() {},
  pickListSummaryResetTimestampWip() {},
  pickListSummaryResetTimestampWhseJobs() {},
  pickListSummaryResetTimestampCompleted() {},

  loadListSummaryResetTimestampPending() {},
  loadListSummaryResetTimestampWip() {},
  loadListSummaryResetTimestampWhseJobs() {},
  loadListSummaryResetTimestampCompleted() {}
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  slsOrdSummaryResetTimestampSlsOrds: () =>
    dispatch(SummaryBoardActions.slsOrdSummaryBoardResetSlsOrdsTimestamp()),

  slsOrdSummaryResetTimestampOutbOrds: () =>
    dispatch(SummaryBoardActions.slsOrdSummaryBoardResetOutbOrdsTimestamp()),

  slsOrdSummaryResetTimestampInvDocs: () =>
    dispatch(SummaryBoardActions.slsOrdSummaryBoardResetInvDocsTimestamp()),

  slsOrdSummaryResetTimestampNoPrfDels: () =>
    dispatch(SummaryBoardActions.slsOrdSummaryBoardResetNoPrfDelsTimestamp()),

  lspSlsOrdSummaryResetTimestampSlsOrds: () =>
    dispatch(SummaryBoardActions.lspSlsOrdSummaryBoardResetSlsOrdsTimestamp()),

  lspSlsOrdSummaryResetTimestampOutbOrds: () =>
    dispatch(SummaryBoardActions.lspSlsOrdSummaryBoardResetOutbOrdsTimestamp()),

  lspSlsOrdSummaryResetTimestampDOs: () =>
    dispatch(SummaryBoardActions.lspSlsOrdSummaryBoardResetInvDocsTimestamp()),

  lspSlsOrdSummaryResetTimestampNoPrfDels: () =>
    dispatch(SummaryBoardActions.lspSlsOrdSummaryBoardResetNoPrfDelsTimestamp()),

  pickListSummaryResetTimestampOutbOrds: () =>
    dispatch(SummaryBoardActions.pickListSummaryBoardResetOutbOrdsTimestamp()),

  pickListSummaryResetTimestampWip: () =>
    dispatch(SummaryBoardActions.pickListSummaryBoardResetWipTimestamp()),

  pickListSummaryResetTimestampWhseJobs: () =>
    dispatch(SummaryBoardActions.pickListSummaryBoardResetWhseJobsTimestamp()),

  pickListSummaryResetTimestampCompleted: () =>
    dispatch(SummaryBoardActions.pickListSummaryBoardResetCompletedTimestamp()),

  loadListSummaryResetTimestampPending: () =>
    dispatch(SummaryBoardActions.loadListSummaryBoardResetPendingTimestamp()),

  loadListSummaryResetTimestampWip: () =>
    dispatch(SummaryBoardActions.loadListSummaryBoardResetWipTimestamp()),

  loadListSummaryResetTimestampWhseJobs: () =>
    dispatch(SummaryBoardActions.loadListSummaryBoardResetWhseJobsTimestamp()),

  loadListSummaryResetTimestampCompleted: () =>
    dispatch(SummaryBoardActions.loadListSummaryBoardResetCompletedTimestamp())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SummaryBoardScreen));
