/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StorageTypeDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const storageTypeDetailResetModelTimestamp = state => ({
  ...state,
  modelTimestamp: Date.now()
});

export const storageTypeDetailSetResId = (state, { resId }) => ({
  ...state,
  modelTimestamp: Date.now(),
  resId
});

export const storageTypeDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const storageTypeDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const storageTypeDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const storageTypeDetailFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const storageTypeDetailFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StorageTypeDetailTypes.STORAGE_TYPE_DETAIL_RESET_MODEL_TIMESTAMP]: storageTypeDetailResetModelTimestamp,
  [StorageTypeDetailTypes.STORAGE_TYPE_DETAIL_SET_RES_ID]: storageTypeDetailSetResId,
  [StorageTypeDetailTypes.STORAGE_TYPE_DETAIL_SHOW_MODEL_LOADING]: storageTypeDetailShowModelLoading,
  [StorageTypeDetailTypes.STORAGE_TYPE_DETAIL_SHOW_MODEL_SUCCESS]: storageTypeDetailShowModelSuccess,
  [StorageTypeDetailTypes.STORAGE_TYPE_DETAIL_UPDATE_MODEL_SUCCESS]: storageTypeDetailUpdateModelSuccess,

  [StorageTypeDetailTypes.STORAGE_TYPE_DETAIL_FETCH_STORAGE_ROW_OPTION_LOADING]: storageTypeDetailFetchStorageRowOptionLoading,
  [StorageTypeDetailTypes.STORAGE_TYPE_DETAIL_FETCH_STORAGE_ROW_OPTION_SUCCESS]: storageTypeDetailFetchStorageRowOptionSuccess
});
