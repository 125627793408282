import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { DeleteOutlined } from '@ant-design/icons';

import { Row, Col, Modal, Table, Button, Popconfirm } from 'antd';

import WhseJob140201Actions from '../../Stores/WhseJob140201/Actions';

class WhseJob140201Dialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnPrintDocument = this.useOnPrintDocument.bind(this);
    this.useOnPrintHandlingUnit = this.useOnPrintHandlingUnit.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setWorkspaceVisible, printIsLoading } = this.props;

    if (printIsLoading === false) {
      setWorkspaceVisible(false);
    }
  }

  getSelectedDocumentColumns() {
    const { intl, removeSelectedDocuments } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
            <br />
            {record.str_doc_status}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'desc_01' }),
        // sort field
        dataIndex: 'desc_01',
        sorter: (a, b) => `${a.desc_01}`.localeCompare(b.desc_01),
        // filter field
        key: 'desc_01',
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'pallet_qty' }),
        // sort field
        dataIndex: 'pallet_qty',
        sorter: (a, b) => a.pallet_qty - b.pallet_qty,
        // filter field
        key: 'pallet_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.pallet_qty)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'case_decimal_qty' }),
        // sort field
        dataIndex: 'case_decimal_qty',
        sorter: (a, b) => a.case_decimal_qty - b.case_decimal_qty,
        // filter field
        key: 'case_decimal_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.case_decimal_qty)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'gross_weight' }),
        // sort field
        dataIndex: 'gross_weight',
        sorter: (a, b) => a.gross_weight - b.gross_weight,
        // filter field
        key: 'gross_weight',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.gross_weight)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'cubic_meter' }),
        // sort field
        dataIndex: 'cubic_meter',
        sorter: (a, b) => a.cubic_meter - b.cubic_meter,
        // filter field
        key: 'cubic_meter',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.cubic_meter)}
            </div>
          </>
        )
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedDocuments([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnPrintDocument() {
    const { selectedDocuments, printWhseJob140201 } = this.props;

    const hdrIds = selectedDocuments.map(value => {
      return value.id;
    });

    printWhseJob140201(hdrIds);
  }

  useOnPrintHandlingUnit() {
    const { selectedDocuments, printHandlingUnit } = this.props;

    const hdrIds = selectedDocuments.map(value => {
      return value.id;
    });

    printHandlingUnit(hdrIds);
  }

  render() {
    const { intl, selectedDocuments, workspaceIsVisible, printIsLoading } = this.props;

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'print_mobile_gds_rcpt_return' })}
          // style={{top:20}}
          width="80%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              type="primary"
              key="pallet_label"
              disabled={selectedDocuments.length === 0}
              loading={printIsLoading}
              onClick={this.useOnPrintHandlingUnit}
            >
              {intl.formatMessage({ id: 'pallet_label' })}
            </Button>,
            <Button
              type="primary"
              key="print"
              disabled={selectedDocuments.length === 0}
              loading={printIsLoading}
              onClick={this.useOnPrintDocument}
            >
              {intl.formatMessage({ id: 'print' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocuments}
                loading={printIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_documents' })}</b>}
                summary={pageData => {
                  // summarise the table
                  const calcRow = pageData.reduce(
                    (ttlObj, object) => {
                      return {
                        pallet_qty:
                          ('pallet_qty' in ttlObj ? ttlObj.pallet_qty : 0) +
                          parseFloat(object.pallet_qty),
                        case_decimal_qty:
                          ('case_decimal_qty' in ttlObj ? ttlObj.case_decimal_qty : 0) +
                          parseFloat(object.case_decimal_qty),
                        gross_weight:
                          ('gross_weight' in ttlObj ? ttlObj.gross_weight : 0) +
                          parseFloat(object.gross_weight),
                        cubic_meter:
                          ('cubic_meter' in ttlObj ? ttlObj.cubic_meter : 0) +
                          parseFloat(object.cubic_meter)
                      };
                    },
                    { pallet_qty: 0, case_decimal_qty: 0, gross_weight: 0, cubic_meter: 0 }
                  );

                  return (
                    <>
                      <tr className="ant-table-row">
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={3}>
                          {intl.formatMessage({ id: 'total' })}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.pallet_qty)}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.case_decimal_qty)}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.gross_weight)}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.cubic_meter)}
                        </td>
                        <td />
                      </tr>
                    </>
                  );
                }}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

WhseJob140201Dialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedDocuments: PropTypes.func,
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  printIsLoading: PropTypes.bool,

  printWhseJob140201: PropTypes.func,
  printHandlingUnit: PropTypes.func
};

WhseJob140201Dialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedDocuments() {},
  selectedDocuments: [],

  printIsLoading: false,

  printWhseJob140201() {},
  printHandlingUnit() {}
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.whseJob140201.workspaceIsVisible,

  selectedDocuments: state.whseJob140201.selectedDocuments,
  newDocuments: state.whseJob140201.newDocuments,

  printIsLoading: state.whseJob140201.printIsLoading
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean =>
    dispatch(WhseJob140201Actions.whseJob140201SetWorkspaceVisible(boolean)),

  removeSelectedDocuments: selectedDocuments =>
    dispatch(WhseJob140201Actions.whseJob140201RemoveSelectedDocuments(selectedDocuments)),

  printWhseJob140201: hdrIds =>
    dispatch(WhseJob140201Actions.whseJob140201PrintWhseJob140201(hdrIds)),

  printHandlingUnit: hdrIds => dispatch(WhseJob140201Actions.whseJob140201PrintHandlingUnit(hdrIds))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WhseJob140201Dialog));
