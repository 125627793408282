import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { EditOutlined, ReloadOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import ProjectList01Actions from '../../Stores/ProjectList01/Actions';

class ProjectList01Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { resetTimestamp } = this.props;

    resetTimestamp();
  }

  componentDidUpdate(prevProps) {
    const { fetchProjectList01, currentPage, sorts, filters, pageSize } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('timestamp')) {
      fetchProjectList01(currentPage, sorts, filters, pageSize);
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const { intl, sorts, filters, goToResource } = this.props;

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        // filter field
        key: 'code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'code' }),
          'code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.code}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_01'),
        // filter field
        key: 'desc_01',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'description' }),
          'desc_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        width: 150,
        align: 'left',
        title: '',
        // sort field
        dataIndex: 'desc_02',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_02'),
        // filter field
        key: 'desc_02',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'description' }),
          'desc_02',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.desc_02}</>
      },
      {
        width: 50,
        fixed: 'right',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => goToResource(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  render() {
    const {
      intl,
      resetTimestamp,
      newResource,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading
    } = this.props;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
                <Col span={2}>
                  <Button type="primary" loading={fetchIsLoading} onClick={newResource}>
                    {intl.formatMessage({ id: 'new' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />
      </>
    );
  }
}

ProjectList01Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToResource: PropTypes.func,
  newResource: PropTypes.func,

  documents: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  fetchProjectList01: PropTypes.func
};

ProjectList01Table.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToResource() {},
  newResource() {},

  documents: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  fetchProjectList01() {}
};

const mapStateToProps = state => ({
  timestamp: state.projectList01.timestamp,
  workspaceIsVisible: state.projectList01.workspaceIsVisible,

  documents: state.projectList01.documents,

  sorts: state.projectList01.sorts,
  filters: state.projectList01.filters,

  currentPage: state.projectList01.currentPage,
  pageSize: state.projectList01.pageSize,
  total: state.projectList01.total,
  fetchIsLoading: state.projectList01.fetchIsLoading
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      ProjectList01Actions.projectList01ResetTimestamp(currentPage, sorts, filters, pageSize)
    ),
  goToResource: resId => dispatch(ProjectList01Actions.projectList01GoToResource(resId)),
  newResource: () => dispatch(ProjectList01Actions.projectList01NewResource()),

  fetchProjectList01: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      ProjectList01Actions.projectList01FetchProjectList01(currentPage, sorts, filters, pageSize)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProjectList01Table));
