/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SiteFlowList01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const siteFlowList01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  toSiteOptions: INITIAL_STATE.toSiteOptions,
  toSiteOption: INITIAL_STATE.toSiteOption
});

export const siteFlowList01FetchSiteFlowList01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const siteFlowList01FetchSiteFlowList01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SiteFlowList01Types.SITE_FLOW_LIST01_RESET_TIMESTAMP]: siteFlowList01ResetTimestamp,
  [SiteFlowList01Types.SITE_FLOW_LIST01_FETCH_SITE_FLOW_LIST01_LOADING]: siteFlowList01FetchSiteFlowList01Loading,
  [SiteFlowList01Types.SITE_FLOW_LIST01_FETCH_SITE_FLOW_LIST01_SUCCESS]: siteFlowList01FetchSiteFlowList01Success
});
