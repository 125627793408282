/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { DailyStockMovementReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const dailyStockMovementReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const dailyStockMovementReportInitDailyStockMovementSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const dailyStockMovementReportDailyStockMovementSuccess = (
  state,
  { criteria, reportData }
) => ({
  ...state,
  criteria,
  reportData
});

export const dailyStockMovementReportFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const dailyStockMovementReportFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const dailyStockMovementReportFetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const dailyStockMovementReportFetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const dailyStockMovementReportFetchItemGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup02IsLoading: boolean
});

export const dailyStockMovementReportFetchItemGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup02Options: options
});

export const dailyStockMovementReportFetchItemGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup03IsLoading: boolean
});

export const dailyStockMovementReportFetchItemGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup03Options: options
});

export const dailyStockMovementReportFetchStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBinIsLoading: boolean
});

export const dailyStockMovementReportFetchStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  storageBinOptions: options
});

export const dailyStockMovementReportFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const dailyStockMovementReportFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

export const dailyStockMovementReportFetchStorageBayOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBayIsLoading: boolean
});

export const dailyStockMovementReportFetchStorageBayOptionSuccess = (state, { options }) => ({
  ...state,
  storageBayOptions: options
});

export const dailyStockMovementReportFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const dailyStockMovementReportFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_REPORT_LOADING]: dailyStockMovementReportReportLoading,
  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_INIT_DAILY_STOCK_MOVEMENT_SUCCESS]: dailyStockMovementReportInitDailyStockMovementSuccess,
  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_DAILY_STOCK_MOVEMENT_SUCCESS]: dailyStockMovementReportDailyStockMovementSuccess,

  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_ITEM_OPTION_LOADING]: dailyStockMovementReportFetchItemOptionLoading,
  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_ITEM_OPTION_SUCCESS]: dailyStockMovementReportFetchItemOptionSuccess,

  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_ITEM_GROUP01_OPTION_LOADING]: dailyStockMovementReportFetchItemGroup01OptionLoading,
  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: dailyStockMovementReportFetchItemGroup01OptionSuccess,

  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_ITEM_GROUP02_OPTION_LOADING]: dailyStockMovementReportFetchItemGroup02OptionLoading,
  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_ITEM_GROUP02_OPTION_SUCCESS]: dailyStockMovementReportFetchItemGroup02OptionSuccess,

  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_ITEM_GROUP03_OPTION_LOADING]: dailyStockMovementReportFetchItemGroup03OptionLoading,
  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_ITEM_GROUP03_OPTION_SUCCESS]: dailyStockMovementReportFetchItemGroup03OptionSuccess,

  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_STORAGE_BIN_OPTION_LOADING]: dailyStockMovementReportFetchStorageBinOptionLoading,
  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_STORAGE_BIN_OPTION_SUCCESS]: dailyStockMovementReportFetchStorageBinOptionSuccess,

  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_STORAGE_ROW_OPTION_LOADING]: dailyStockMovementReportFetchStorageRowOptionLoading,
  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_STORAGE_ROW_OPTION_SUCCESS]: dailyStockMovementReportFetchStorageRowOptionSuccess,

  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_STORAGE_BAY_OPTION_LOADING]: dailyStockMovementReportFetchStorageBayOptionLoading,
  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_STORAGE_BAY_OPTION_SUCCESS]: dailyStockMovementReportFetchStorageBayOptionSuccess,

  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_LOCATION_OPTION_LOADING]: dailyStockMovementReportFetchLocationOptionLoading,
  [DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_LOCATION_OPTION_SUCCESS]: dailyStockMovementReportFetchLocationOptionSuccess
});
