import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { SaveOutlined } from '@ant-design/icons';

import { Card, Row, Col, Typography, Space } from 'antd';
import { FormikButton, FormikSelect } from '../../Components/Formik';
import SystemSettingActions from '../../Stores/SystemSetting/Actions';

const { Text } = Typography;

class ShippingDocSettingScreen extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchCreditTerm = this.handleSearchCreditTerm.bind(this);
  }

  componentDidMount() {
    const { systemSettingFetchSetting, systemSettingFetchCreditTerm } = this.props;
    systemSettingFetchSetting('SHIPPING_DOC_SETTING_EXCLUDE');
    systemSettingFetchCreditTerm('');
  }

  componentDidUpdate(prevProps) {
    const { systemSettingFetchSetting } = this.props;

    const { changed } = treeChanges(prevProps, this.props);
    if (changed('timestamp')) {
      systemSettingFetchSetting('SHIPPING_DOC_SETTING_EXCLUDE');
    }
  }

  componentWillUnmount() {}

  handleSearchCreditTerm(form, value) {
    const { systemSettingFetchCreditTerm } = this.props;
    systemSettingFetchCreditTerm(value);
  }

  render() {
    const {
      intl,
      data,
      systemSettingUpdateSetting,
      creditTermOptions,
      creditTermLoading
    } = this.props;
    return (
      <Card
        title={`${intl.formatMessage({ id: 'shipping_document' })} ${intl.formatMessage({
          id: 'setting'
        })}`}
      >
        <Formik
          enableReinitialize
          initialValues={{
            id: data?.id ?? 0,
            code: data?.code ?? 'SHIPPING_DOC_SETTING_EXCLUDE',
            setting: data?.setting ?? []
          }}
          onSubmit={(values, formikBag) => {
            // remove the submit_action field
            const processedValues = { ...values };
            delete processedValues.submit_action;

            systemSettingUpdateSetting(formikBag, processedValues);
          }}
        >
          {({ values, isSubmitting, handleSubmit }) => (
            <Form>
              <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <div>
                  <Row type="flex" justify="center" gutter={[8, 8]}>
                    <Col span={4}>
                      <Text>{`${intl.formatMessage({ id: 'exclude' })} ${intl.formatMessage({
                        id: 'print'
                      })} ${intl.formatMessage({ id: 'of' })} ${intl.formatMessage({
                        id: 'credit_term_code'
                      })}`}</Text>
                    </Col>
                    <Col span={1}>
                      <Text>:</Text>
                    </Col>
                    <Col span={18}>
                      <Field
                        name="setting"
                        component={FormikSelect}
                        showArrow
                        filterOption={false}
                        onSearch={this.handleSearchCreditTerm}
                        notFoundContent={null}
                        loading={creditTermLoading}
                        options={creditTermOptions}
                        disabled={isSubmitting}
                        selectMode="multiple"
                      />
                    </Col>
                  </Row>
                </div>

                <Row type="flex" justify="end" gutter={[0, 16]}>
                  <Col>
                    <Field
                      type="primary"
                      name="submit_action"
                      value="update"
                      component={FormikButton}
                      onClick={handleSubmit}
                      label={intl.formatMessage({
                        id: 'update'
                      })}
                      icon={<SaveOutlined />}
                    />
                  </Col>
                </Row>
              </Space>
            </Form>
          )}
        </Formik>
      </Card>
    );
  }
}

ShippingDocSettingScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  systemSettingUpdateSetting: PropTypes.func,
  systemSettingFetchSetting: PropTypes.func,
  systemSettingFetchCreditTerm: PropTypes.func,

  data: PropTypes.object,
  creditTermOptions: PropTypes.arrayOf(PropTypes.object),

  isLoading: PropTypes.bool,
  creditTermLoading: PropTypes.bool
};

ShippingDocSettingScreen.defaultProps = {
  intl: {},
  systemSettingUpdateSetting() {},
  systemSettingFetchSetting() {},
  systemSettingFetchCreditTerm() {},
  data: {},
  creditTermOptions: [],
  isLoading: false,
  creditTermLoading: false
};

const mapStateToProps = state => ({
  data: state.systemSetting.data,
  creditTermOptions: state.systemSetting.creditTermOptions,

  isLoading: state.systemSetting.appSettingIsLoading,
  creditTermLoading: state.systemSetting.creditTermLoading
});

const mapDispatchToProps = dispatch => ({
  systemSettingUpdateSetting: (formikBag, newDetail) =>
    dispatch(SystemSettingActions.systemSettingUpdateSetting(formikBag, newDetail)),
  systemSettingFetchSetting: code => dispatch(SystemSettingActions.systemSettingFetchSetting(code)),
  systemSettingFetchCreditTerm: search =>
    dispatch(SystemSettingActions.systemSettingFetchCreditTerm(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShippingDocSettingScreen));
