import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  pickListDetailBoardResetWipOutbOrdTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  pickListDetailBoardFetchWipOutbOrd: ['currentPage', 'sorts', 'filters', 'pageSize'],
  pickListDetailBoardFetchWipOutbOrdLoading: ['boolean'],
  pickListDetailBoardFetchWipOutbOrdSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  pickListDetailBoardResetWipPickListTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  pickListDetailBoardFetchWipPickList: ['currentPage', 'sorts', 'filters', 'pageSize'],
  pickListDetailBoardFetchWipPickListLoading: ['boolean'],
  pickListDetailBoardFetchWipPickListSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  pickListDetailBoardResetWipWhseJobTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  pickListDetailBoardFetchWipWhseJob: ['currentPage', 'sorts', 'filters', 'pageSize'],
  pickListDetailBoardFetchWipWhseJobLoading: ['boolean'],
  pickListDetailBoardFetchWipWhseJobSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  pickListDetailBoardResetCompletePickListTimestamp: [
    'currentPage',
    'sorts',
    'filters',
    'pageSize'
  ],
  pickListDetailBoardFetchCompletePickList: ['currentPage', 'sorts', 'filters', 'pageSize'],
  pickListDetailBoardFetchCompletePickListLoading: ['boolean'],
  pickListDetailBoardFetchCompletePickListSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  pickListDetailBoardSetWipWhseJobDialogVisible: ['boolean'],
  pickListDetailBoardShowWipWhseJobSummary: ['docId'],
  pickListDetailBoardShowWipWhseJobSummaryLoading: ['boolean'],
  pickListDetailBoardShowWipWhseJobSummarySuccess: ['document'],

  pickListDetailBoardSetWipPickListDialogVisible: ['boolean'],
  pickListDetailBoardShowWipPickListSummary: ['docId'],
  pickListDetailBoardShowWipPickListSummaryLoading: ['boolean'],
  pickListDetailBoardShowWipPickListSummarySuccess: ['document'],

  pickListDetailBoardSetWipOutbOrdDialogVisible: ['boolean'],
  pickListDetailBoardShowWipOutbOrdSummary: ['docId'],
  pickListDetailBoardShowWipOutbOrdSummaryLoading: ['boolean'],
  pickListDetailBoardShowWipOutbOrdSummarySuccess: ['document'],

  pickListDetailBoardSetCompletePickListDialogVisible: ['boolean'],
  pickListDetailBoardShowCompletePickListSummary: ['docId'],
  pickListDetailBoardShowCompletePickListSummaryLoading: ['boolean'],
  pickListDetailBoardShowCompletePickListSummarySuccess: ['document'],

  pickListDetailBoardFetchLoadingBinOptions: ['search'],
  pickListDetailBoardFetchLoadingBinOptionsSuccess: ['options'],
  pickListDetailBoardChangeLoadingBin: ['loadingBinId']
});

export const PickListDetailBoardTypes = Types;
export default Creators;
