import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Card, Row, Col, Upload, Button, Progress, notification, Radio, Typography } from 'antd';

import ItemManufactureExcelActions from '../../Stores/ItemManufactureExcel/Actions';

const { Text } = Typography;

class ItemManufactureExcelTable extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      itemType: 'NULL',
      fileList: []
    };

    this.useOnUpload = this.useOnUpload.bind(this);
    this.useOnDownload = this.useOnDownload.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {
    const { uploadIsLoading, showBatchJobStatus } = this.props;

    if (uploadIsLoading) {
      setTimeout(() => {
        showBatchJobStatus();
      }, 10000);
    }
  }

  componentWillUnmount() {}

  useOnUpload() {
    const { uploadExcel, match } = this.props;
    const { fileList } = this.state;

    if (fileList.length > 0) {
      uploadExcel(fileList[0], match.params.proc_type);
    }
  }

  useOnDownload() {
    const { downloadExcel } = this.props;
    const { itemType } = this.state;

    downloadExcel(itemType);
  }

  render() {
    const { intl, uploadIsLoading, batchJobStatus } = this.props;
    const { fileList, itemType } = this.state;

    const groupOptions = [
      <Radio.Button key="NULL" value="NULL">
        {intl.formatMessage({ id: 'all_item' })}
      </Radio.Button>,
      <Radio.Button key="FINISHED_ITEM" value="FINISHED_ITEM">
        {intl.formatMessage({ id: 'finished_item' })}
      </Radio.Button>,
      <Radio.Button key="SEMI_FINISHED_ITEM" value="SEMI_FINISHED_ITEM">
        {intl.formatMessage({ id: 'semi_finished_item' })}
      </Radio.Button>,
      <Radio.Button key="RAW_MATERIAL" value="RAW_MATERIAL">
        {intl.formatMessage({ id: 'raw_material' })}
      </Radio.Button>
    ];

    return (
      <Card>
        <Row type="flex" justify="center" gutter={[0, 8]}>
          <Col span={3}>
            <Text>{intl.formatMessage({ id: 'item_type' })}</Text>
          </Col>
          <Col span={1}>
            <Text>:</Text>
          </Col>
          <Col span={20}>
            <Radio.Group
              onChange={e => {
                this.setState({
                  itemType: e.target.value
                });
              }}
              name="itemType"
              value={itemType}
            >
              {groupOptions}
            </Radio.Group>
          </Col>
        </Row>
        <Row type="flex" justify="start" gutter={[0, 8]}>
          <Col span={12}>
            <Upload
              disabled={uploadIsLoading}
              onRemove={() => {
                this.setState({
                  fileList: []
                });
              }}
              beforeUpload={file => {
                // const validFileName = 'ITEM_EXCEL_01';
                const validFileName = 'ITEM';
                if (file && file.name.toLowerCase().includes(validFileName.toLowerCase())) {
                  this.setState({
                    fileList: [file]
                  });
                } else {
                  notification.error({
                    message: `${intl.formatMessage({
                      id: 'invalid_file_name_must_contain'
                    })} ${validFileName}`,
                    duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
                  });
                }

                return false;
              }}
              fileList={fileList}
            >
              <Button type="primary" loading={uploadIsLoading}>
                {intl.formatMessage({ id: 'browse' })}
              </Button>
            </Upload>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              onClick={this.useOnUpload}
              disabled={fileList.length === 0}
              loading={uploadIsLoading}
            >
              {intl.formatMessage({ id: 'upload' })}
            </Button>
            <Button type="primary" onClick={this.useOnDownload} loading={uploadIsLoading}>
              {intl.formatMessage({ id: 'download' })}
            </Button>
          </Col>
        </Row>
        <Row type="flex" justify="center" gutter={[0, 8]}>
          <Col span={24}>
            <Progress percent={parseInt(batchJobStatus.status_number, 10)} />
          </Col>
        </Row>
      </Card>
    );
  }
}

ItemManufactureExcelTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  match: PropTypes.object,

  batchJobStatus: PropTypes.shape({
    status_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  uploadIsLoading: PropTypes.bool,

  showBatchJobStatus: PropTypes.func,
  uploadExcel: PropTypes.func,
  downloadExcel: PropTypes.func
};

ItemManufactureExcelTable.defaultProps = {
  intl: {},
  match: {},

  batchJobStatus: {
    status_number: '0'
  },
  uploadIsLoading: false,

  showBatchJobStatus() {},
  uploadExcel() {},
  downloadExcel() {}
};

const mapStateToProps = state => ({
  batchJobStatus: state.itemManufactureExcel.batchJobStatus,
  uploadIsLoading: state.itemManufactureExcel.uploadIsLoading
});

const mapDispatchToProps = dispatch => ({
  showBatchJobStatus: () =>
    dispatch(ItemManufactureExcelActions.itemManufactureExcelShowBatchJobStatus()),
  uploadExcel: (file, procType) =>
    dispatch(ItemManufactureExcelActions.itemManufactureExcelUploadExcel(file, procType)),
  downloadExcel: itemType =>
    dispatch(ItemManufactureExcelActions.itemManufactureExcelDownloadExcel(itemType))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ItemManufactureExcelTable));
