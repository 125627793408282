import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { ReloadOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button } from 'antd';

import treeChanges from 'tree-changes';

import AntDesignTable from '../../Components/AntDesignTable';
import WarehouseMapActions from '../../Stores/WarehouseMap/Actions';

class WarehouseMapTable extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useOnSelectStorageBin = this.useOnSelectStorageBin.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);

    this.convertExpiryDate = this.convertExpiryDate.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchStorageBinList02,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchStorageBinList02(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  convertExpiryDate(expiryDate) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    let diffDays = 0;
    if (expiryDate !== null) {
      const thisDate = new Date();
      const oneDay = 24 * 60 * 60 * 1000;
      diffDays = Math.round(Math.abs((expiryDate.getTime() - thisDate.getTime()) / oneDay));
    }

    let backgroundColor = 'white';
    if (diffDays <= 90) {
      backgroundColor = '#ff4500'; // orange red
    } else if (diffDays <= 180) {
      backgroundColor = '#ffae42'; // yellow orange
    } else if (diffDays <= 270) {
      backgroundColor = '#FFFFE0'; // light yellow
    }
    return backgroundColor;
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useOnSelectStorageBin(storageBin, quantBals) {
    const { setWorkspaceVisible, selectStorageBin } = this.props;

    selectStorageBin(storageBin, quantBals);

    setWorkspaceVisible(true);
  }

  render() {
    const {
      intl,
      filters,
      currentPage,
      pageSize,
      total,
      rowLevels,
      fetchIsLoading,
      savePageSize,
      resetTimestamp
    } = this.props;

    let bayAbbreviationLabels = [];
    if (rowLevels.length > 0) {
      bayAbbreviationLabels = rowLevels[0].bay_abbreviation_labels;
    }
    let binAbbreviationLabels = [];
    if (rowLevels.length > 0) {
      binAbbreviationLabels = rowLevels[0].bin_abbreviation_labels;
    }

    let lastEndIndex = 0;
    const binColumns = bayAbbreviationLabels.map((bayColumn, a) => {
      const startIndex = lastEndIndex;
      lastEndIndex = startIndex + bayColumn.col_span;

      if (a === 0) {
        return {
          fixed: 'left',
          width: 35,
          align: 'left',
          title: <div style={{ fontWeight: 'bold' }}>L</div>,
          // sort field
          dataIndex: bayColumn.col_index,
          // filter field
          key: a.toString(),
          render: (text, record) => {
            return <div style={{ fontWeight: 'bold' }}>{record.level}</div>;
          }
        };
      }

      return {
        fixed: false,
        title: <div style={{ fontWeight: 'bold' }}>{bayColumn.label}</div>,
        width: 60 * bayColumn.col_span,
        children: binAbbreviationLabels.slice(startIndex, lastEndIndex).map(binColumn => {
          return {
            fixed: false,
            title: binColumn.label,
            width: 70,
            align: 'left',
            // title: intl.formatMessage({ id: entry.column }),
            // sort field
            dataIndex: binColumn.col_index,
            // filter field
            key: binColumn.col_index,
            render: (text, record) => {
              const field = binColumn.col_index;

              // bin does not exist
              if (record.bins_model[field] === null) {
                return <div style={{ width: 55, height: 55, backgroundColor: 'black' }} />;
              }
              if (record.bins_model[field].bin_status === 1) {
                // DELETED
                return (
                  <div style={{ width: 55, height: 55, color: 'white', backgroundColor: 'black' }}>
                    <div style={{ fontSize: 'xx-small', textAlign: 'center' }}>
                      {record.bins_model[field].code}
                    </div>
                  </div>
                );
              }

              let border = '';
              if (record.bins_model[field].bin_status === 50) {
                border = '2px solid blue';
              } else if (record.bins_model[field].bin_status === 2) {
                // INACTIVE
                border = '2px solid grey';
              }

              // bin does exist
              if (record.bins_quant_bals[field].length > 0) {
                // bin is not empty
                // check the quantBal oldest expiry date, so can print color for notification
                let palletQty = 0;
                let oldestExpiryDate = null;

                record.bins_quant_bals[field].forEach(quantBal => {
                  palletQty += parseFloat(quantBal.pallet_qty);

                  const expiryDate = new Date(quantBal.expiry_date);
                  if (oldestExpiryDate === null) {
                    oldestExpiryDate = expiryDate;
                  }
                  if (expiryDate < oldestExpiryDate) {
                    oldestExpiryDate = expiryDate;
                  }
                });

                const backgroundColor = this.convertExpiryDate(oldestExpiryDate);
                return (
                  <div
                    style={{
                      width: 55,
                      height: 55,
                      overflow: 'hidden',
                      backgroundColor,
                      border
                    }}
                  >
                    <div style={{ fontSize: 'xx-small', textAlign: 'center' }}>
                      {record.bins_model[field].code}
                    </div>
                    <Button
                      type="link"
                      style={{
                        paddingLeft: 3,
                        paddingRight: 3,
                        paddingBottom: 3,
                        paddingTop: 0
                      }}
                      onClick={() => {
                        this.useOnSelectStorageBin(
                          record.bins_model[field],
                          record.bins_quant_bals[field]
                        );
                      }}
                    >
                      <div style={{}}>
                        {new Intl.NumberFormat([], {
                          style: 'decimal',
                          minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                          maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                        }).format(palletQty)}
                      </div>
                    </Button>
                  </div>
                );
              }

              // bin is empty
              return (
                <div
                  style={{
                    width: 55,
                    height: 55,
                    backgroundColor: '#3CB371', // mediumseagreen
                    border
                  }}
                >
                  <div
                    style={{
                      fontSize: 'xx-small',
                      textAlign: 'center',
                      color: 'white'
                    }}
                  >
                    {record.bins_model[field].code}
                  </div>
                </div>
              );
            }
          };
        })
      };
    });

    const dynamicColumns = [
      // first column is row code
      {
        fixed: 'left',
        width: 50,
        align: 'left',
        // title: intl.formatMessage({ id: entry.column }),
        // sort field
        dataIndex: 'storage_row_code',
        // filter field
        key: 'storage_row_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'row_code' }),
          'storage_row_code',
          this.handleSearch,
          this.handleReset
        ),
        render: text => {
          return <div style={{ fontWeight: 'bold' }}>{text}</div>;
        }
      },
      ...binColumns
    ];

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey={record => {
            return record.storage_row_code + record.level;
          }}
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            showSizeChanger: true,
            pageSizeOptions: ['4', '5', '6', '7', '8', '10', '12', '14', '15', '16', '18', '21'],
            onShowSizeChange: (current, curPageSize) => {
              savePageSize(curPageSize.toString());
              resetTimestamp();
            },
            total,
            showTotal: (tmpTotal, range) => `${range[0]}-${range[1]} of ${tmpTotal} items`
          }}
          columns={dynamicColumns}
          dataSource={rowLevels}
          loading={fetchIsLoading}
          bordered
          scroll={{ x: 1000, y: 500 }}
          rowClassName={rowData => {
            if (rowData.storage_row_id % 4 === 1) {
              return 'success-row';
            }
            if (rowData.storage_row_id % 4 === 2) {
              return 'warning-row';
            }
            if (rowData.storage_row_id % 4 === 3) {
              return 'error-row';
            }
            return '';
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
              </Row>
            </>
          )}
        />
      </>
    );
  }
}

WarehouseMapTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  resetTimestamp: PropTypes.func,
  setWorkspaceVisible: PropTypes.func,
  selectStorageBin: PropTypes.func,

  rowLevels: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  savePageSize: PropTypes.func,

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  fetchStorageBinList02: PropTypes.func,

  curSiteFlowId: PropTypes.number
};

WarehouseMapTable.defaultProps = {
  intl: {},

  resetTimestamp() {},
  setWorkspaceVisible() {},
  selectStorageBin() {},

  rowLevels: [],

  sorts: {},
  filters: {},
  savePageSize() {},

  currentPage: 1,
  pageSize: '15',
  total: 0,
  fetchIsLoading: false,

  fetchStorageBinList02() {},

  curSiteFlowId: 0
};

const mapStateToProps = state => ({
  timestamp: state.warehouseMap.timestamp,

  sorts: state.warehouseMap.sorts,
  filters: state.warehouseMap.filters,

  currentPage: state.warehouseMap.currentPage,
  pageSize: state.warehouseMap.pageSize,
  total: state.warehouseMap.total,
  fetchIsLoading: state.warehouseMap.fetchIsLoading,

  rowLevels: state.warehouseMap.rowLevels,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(WarehouseMapActions.warehouseMapResetTimestamp(currentPage, sorts, filters, pageSize)),
  setWorkspaceVisible: boolean =>
    dispatch(WarehouseMapActions.warehouseMapSetWorkspaceVisible(boolean)),

  savePageSize: pageSize => dispatch(WarehouseMapActions.warehouseMapSavePageSize(pageSize)),

  fetchStorageBinList02: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      WarehouseMapActions.warehouseMapFetchStorageBinList02(currentPage, sorts, filters, pageSize)
    ),

  selectStorageBin: (storageBin, quantBals) =>
    dispatch(WarehouseMapActions.warehouseMapSelectStorageBin(storageBin, quantBals))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WarehouseMapTable));
