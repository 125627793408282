import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import SiteTrfProcessActions from '../../Stores/SiteTrfProcess/Actions';

import OutbOrd05Table from '../OutbOrd05/OutbOrd05Table';
import InbOrd05Table from '../InbOrd05/InbOrd05Table';
import StkIssSync01Table from '../StkIssSync01/StkIssSync01Table';
import StkRcptSync01Table from '../StkRcptSync01/StkRcptSync01Table';
import StkIssFServ01Table from '../StkIssFServ01/StkIssFServ01Table';
import GdsRcptFServ02Table from '../GdsRcptFServ02/GdsRcptFServ02Table';

const { TabPane } = Tabs;

class SiteTrfProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curDivisionId, fetchSiteTrfProcess } = this.props;
    if (curDivisionId > 0) {
      fetchSiteTrfProcess();
    }
  }

  componentDidUpdate(prevProps) {
    const { curDivisionId, fetchSiteTrfProcess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curDivisionId')) {
      if (curDivisionId > 0) {
        fetchSiteTrfProcess();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath, processIsLoading } = this.props;

    return (
      <Card>
        <Spin spinning={processIsLoading}>
          <Tabs type="card" activeKey={match.params.proc_type}>
            {process.map(value => (
              <TabPane
                tab={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                  >
                    <Link
                      style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                      to={`${appPath}/siteTrfProcess/index/${value.proc_type}`}
                    >
                      <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                    </Link>
                  </Tooltip>
                }
                key={value.proc_type}
              />
            ))}
          </Tabs>
        </Spin>

        {match.params.proc_type === 'OUTB_ORD_05' && <OutbOrd05Table match={match} />}
        {match.params.proc_type === 'INB_ORD_05' && <InbOrd05Table match={match} />}
        {match.params.proc_type === 'STK_ISS_SYNC_01' && <StkIssSync01Table match={match} />}
        {match.params.proc_type === 'STK_RCPT_SYNC_01' && <StkRcptSync01Table match={match} />}
        {match.params.proc_type === 'STK_ISS_F_SERV_01' && <StkIssFServ01Table match={match} />}
        {match.params.proc_type === 'GDS_RCPT_F_SERV_02' && <GdsRcptFServ02Table match={match} />}
      </Card>
    );
  }
}

SiteTrfProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),
  processIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchSiteTrfProcess: PropTypes.func
};

SiteTrfProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],
  processIsLoading: false,

  curDivisionId: 0,
  fetchSiteTrfProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.siteTrfProcess.process,
  processIsLoading: state.siteTrfProcess.processIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  fetchSiteTrfProcess: () => dispatch(SiteTrfProcessActions.siteTrfProcessFetchSiteTrfProcess())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SiteTrfProcessScreen));
