/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SlsOrdTrackingReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const slsOrdTrackingReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const slsOrdTrackingReportInitSlsOrdTrackingSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const slsOrdTrackingReportSlsOrdTrackingSuccess = (state, { criteria, reportData }) => ({
  ...state,
  criteria,
  reportData
});

export const slsOrdTrackingReportFetchDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  divisionIsLoading: boolean
});

export const slsOrdTrackingReportFetchDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  divisionOptions: options
});

export const slsOrdTrackingReportFetchDeliveryPointOptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryPointIsLoading: boolean
});

export const slsOrdTrackingReportFetchDeliveryPointOptionSuccess = (state, { options }) => ({
  ...state,
  deliveryPointOptions: options
});

export const slsOrdTrackingReportFetchDebtorOptionLoading = (state, { boolean }) => ({
  ...state,
  debtorIsLoading: boolean
});

export const slsOrdTrackingReportFetchDebtorOptionSuccess = (state, { options }) => ({
  ...state,
  debtorOptions: options
});

export const slsOrdTrackingReportFetchTransportOptionLoading = (state, { boolean }) => ({
  ...state,
  transportIsLoading: boolean
});

export const slsOrdTrackingReportFetchTransportOptionSuccess = (state, { options }) => ({
  ...state,
  transportOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SlsOrdTrackingReportTypes.SLS_ORD_TRACKING_REPORT_REPORT_LOADING]: slsOrdTrackingReportReportLoading,
  [SlsOrdTrackingReportTypes.SLS_ORD_TRACKING_REPORT_INIT_SLS_ORD_TRACKING_SUCCESS]: slsOrdTrackingReportInitSlsOrdTrackingSuccess,
  [SlsOrdTrackingReportTypes.SLS_ORD_TRACKING_REPORT_SLS_ORD_TRACKING_SUCCESS]: slsOrdTrackingReportSlsOrdTrackingSuccess,

  [SlsOrdTrackingReportTypes.SLS_ORD_TRACKING_REPORT_FETCH_DIVISION_OPTION_LOADING]: slsOrdTrackingReportFetchDivisionOptionLoading,
  [SlsOrdTrackingReportTypes.SLS_ORD_TRACKING_REPORT_FETCH_DIVISION_OPTION_SUCCESS]: slsOrdTrackingReportFetchDivisionOptionSuccess,

  [SlsOrdTrackingReportTypes.SLS_ORD_TRACKING_REPORT_FETCH_DELIVERY_POINT_OPTION_LOADING]: slsOrdTrackingReportFetchDeliveryPointOptionLoading,
  [SlsOrdTrackingReportTypes.SLS_ORD_TRACKING_REPORT_FETCH_DELIVERY_POINT_OPTION_SUCCESS]: slsOrdTrackingReportFetchDeliveryPointOptionSuccess,

  [SlsOrdTrackingReportTypes.SLS_ORD_TRACKING_REPORT_FETCH_DEBTOR_OPTION_LOADING]: slsOrdTrackingReportFetchDebtorOptionLoading,
  [SlsOrdTrackingReportTypes.SLS_ORD_TRACKING_REPORT_FETCH_DEBTOR_OPTION_SUCCESS]: slsOrdTrackingReportFetchDebtorOptionSuccess,

  [SlsOrdTrackingReportTypes.SLS_ORD_TRACKING_REPORT_FETCH_TRANSPORT_OPTION_LOADING]: slsOrdTrackingReportFetchTransportOptionLoading,
  [SlsOrdTrackingReportTypes.SLS_ORD_TRACKING_REPORT_FETCH_TRANSPORT_OPTION_SUCCESS]: slsOrdTrackingReportFetchTransportOptionSuccess
});
