import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  projectDetailResetTimestamp: null,
  projectDetailSetResId: ['resId'],

  projectDetailInitModel: null,
  projectDetailShowModel: ['resId'],
  projectDetailShowModelSuccess: ['model'],

  projectDetailUpdateModel: ['formikBag', 'model'],
  projectDetailCreateModel: ['formikBag', 'model'],
  projectDetailUpdateModelSuccess: ['model'],

  projectDetailShowModelLoading: ['boolean']
});

export const ProjectDetailTypes = Types;
export default Creators;
