import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { CloudDownloadOutlined } from '@ant-design/icons';

import { Table, Row, Col, Button, Space, Typography, Card, Modal } from 'antd';

import FileSaver from 'file-saver';
import XLSX from 'xlsx';

import StockBalanceReportActions from '../../Stores/StockBalanceReport/Actions';

const { Text, Link } = Typography;
class ReportTable extends React.PureComponent {
  constructor() {
    super();
    this.useOnDownloadCsv = this.useOnDownloadCsv.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  useOnDownloadCsv() {
    const { intl, reportData, criteria } = this.props;

    const mappedDetails = reportData.map(dataEntry => {
      return criteria.columns.target.reduce((lastObj, columnEntry) => {
        const newObj = { ...lastObj };
        newObj[intl.formatMessage({ id: columnEntry.column })] = dataEntry[columnEntry.column];
        return newObj;
      }, {});
    });

    const ws = XLSX.utils.json_to_sheet(mappedDetails);

    const fileType = 'application/octet-stream';

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
    // eslint-disable-next-line no-undef
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `StockBalance.csv`);
  }

  render() {
    const {
      intl,
      criteria,
      reportData,
      reportIsLoading,
      openBatchNoModal,
      isModalOpened,
      modalData
    } = this.props;

    const dynamicColumns = criteria.columns.target.map(entry => {
      if (
        entry.column === 'unit_qty' ||
        entry.column === 'case_qty' ||
        entry.column === 'pallet_qty' ||
        entry.column === 'case_uom_rate' ||
        entry.column === 'loose_qty' ||
        entry.column === 'loose_uom_rate' ||
        entry.column === 'picking_unit_qty' ||
        entry.column === 'balance_case_cubic_meter' ||
        entry.column === 'balance_loose_cubic_meter' ||
        entry.column === 'balance_case_gross_weight' ||
        entry.column === 'balance_loose_gross_weight' ||
        entry.column === 'documents'
      ) {
        return {
          width: 100,
          align: 'right',
          title: intl.formatMessage({ id: entry.column }),
          // sort field
          dataIndex: entry.column,
          sorter: {
            compare: (a, b) => a[entry.column] - b[entry.column],
            multiple: 1
          },
          // filter field
          key: entry.column,
          render: text =>
            text === 0 ? (
              ''
            ) : text.length === 0 ? (
              ''
            ) : Array.isArray(text) ? (
              <Space size="small" direction="vertical">
                {text.map(doc => (
                  <Space size="small" direction="horizontal">
                    <Button
                      type="primary"
                      onClick={() => {
                        openBatchNoModal(true, doc);
                      }}
                    >
                      {doc.doc_code}
                    </Button>
                    <Button width={100} type="secondary">
                      {doc.layer_farm}
                    </Button>
                  </Space>
                ))}
              </Space>
            ) : (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(text)}
              </>
            )
        };
      }

      return {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: entry.column }),
        // sort field
        dataIndex: entry.column,
        sorter: {
          compare: (a, b) => `${a[entry.column]}`.localeCompare(b[entry.column]),
          multiple: 1
        },
        // filter field
        key: entry.column,
        render: text => <>{text}</>
      };
    });

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            pageSize: 20,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
          columns={dynamicColumns}
          dataSource={reportData}
          loading={reportIsLoading}
          bordered
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={reportIsLoading}
                    onClick={this.useOnDownloadCsv}
                    icon={<CloudDownloadOutlined />}
                  >
                    {intl.formatMessage({ id: 'excel' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />

        {modalData ? (
          <Modal
            key={modalData.doc_hdr_id}
            title={
              <Space direction="horizontal" size="small">
                <Text>Document</Text>
                <Text type="success">{modalData.doc_code}</Text>
              </Space>
            }
            open={isModalOpened}
            onCancel={() => {
              openBatchNoModal(false, {});
            }}
            footer={
              <div style={{ marginTop: 20, textAlign: 'right' }}>
                <Button type="primary">
                  <a href={modalData.cps_web} target="_blank">
                    View
                  </a>
                </Button>
              </div>
            }
            bodyStyle={{
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '400px', // Set a max height for scrolling
              overflowY: 'auto' // Enable vertical scrolling
            }}
          >
            <Space direction="vertical" size="small">
              <Card size="small" title="Item">
                <Text>{modalData.item_code}</Text>
              </Card>
              <Card size="small" title="Batch Serial No">
                <Text>{modalData.batch_serial_no}</Text>
              </Card>
              <Card size="small" title="Layer No">
                <Text>{modalData.layer_farm}</Text>
              </Card>
              <Card size="small" title="Storage Bin">
                <Text>{modalData.storage_bin}</Text>
              </Card>
              <Card size="small" title="Balance">
                <Space direction="vertical" size="small">
                  <Text>{modalData.stk_bal_case}</Text>
                  <Text>{modalData.stk_bal_case_01}</Text>
                  <Text>{modalData.stk_bal_loose}</Text>
                </Space>
              </Card>
            </Space>
          </Modal>
        ) : (
          ''
        )}
      </>
    );
  }
}

ReportTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  criteria: PropTypes.shape({
    columns: PropTypes.shape({
      source: PropTypes.arrayOf(PropTypes.object),
      target: PropTypes.arrayOf(PropTypes.object)
    })
  }),
  reportData: PropTypes.arrayOf(PropTypes.object),
  reportIsLoading: PropTypes.bool,

  isModalOpened: PropTypes.bool,
  modalData: PropTypes.object
};

ReportTable.defaultProps = {
  intl: {},

  criteria: {
    columns: {
      source: [],
      target: []
    }
  },
  reportData: [],
  reportIsLoading: false,

  isModalOpened: false,
  modalData: {}
};

const mapStateToProps = state => ({
  criteria: state.stockBalanceReport.criteria,

  reportData: state.stockBalanceReport.reportData,
  reportIsLoading: state.stockBalanceReport.reportIsLoading,
  isModalOpened: state.stockBalanceReport.isModalOpened,
  modalData: state.stockBalanceReport.modalData
});

const mapDispatchToProps = dispatch => ({
  openBatchNoModal: (isModalOpened, modalData) =>
    dispatch(StockBalanceReportActions.stockBalanceReportOpenBatchNoModal(isModalOpened, modalData))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReportTable));
