import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import { Row, Col, Card, Select, Typography } from 'antd';
import WipPickListTable from './WipPickListTable';
import WipLoadListTable from './WipLoadListTable';
import WipWhseJobTable from './WipWhseJobTable';
import CompleteLoadListTable from './CompleteLoadListTable';
import WipWhseJobDialog from './WipWhseJobDialog';
import WipLoadListDialog from './WipLoadListDialog';
import WipPickListDialog from './WipPickListDialog';
import CompleteLoadListDialog from './CompleteLoadListDialog';

import LoadListDetailBoardActions from '../../Stores/LoadListDetailBoard/Actions';

const { Option } = Select;
const { Text } = Typography;

class LoadListDetailBoardScreen extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      intervalId: 0,
      // milisecond
      refreshInterval: 300000
    };

    this.handleInterval = this.handleInterval.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    const { refreshInterval } = this.state;

    if (refreshInterval > 0) {
      const intervalId = setInterval(this.handleInterval, refreshInterval);
      this.setState({
        intervalId
      });
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    const { intervalId } = this.state;

    clearInterval(intervalId);
  }

  handleInterval() {
    const {
      resetWipPickListTimestamp,
      resetWipLoadListTimestamp,
      resetWipWhseJobTimestamp,
      resetCompleteLoadListTimestamp
    } = this.props;

    resetWipPickListTimestamp();
    resetWipLoadListTimestamp();
    resetWipWhseJobTimestamp();
    resetCompleteLoadListTimestamp();
  }

  handleSelectChange(value) {
    let { intervalId } = this.state;

    this.setState({
      refreshInterval: value
    });

    clearInterval(intervalId);

    if (value > 0) {
      intervalId = setInterval(this.handleInterval, value);

      this.setState({
        intervalId
      });
    }
  }

  render() {
    const { intl, match, wipPickListTimestamp } = this.props;
    const { refreshInterval } = this.state;

    return (
      <Card
        title={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <>
            <Text>{intl.formatMessage({ id: 'load_list_kanban_board' })}</Text>
            <br />
            <small>
              {`${intl.formatMessage({ id: 'last_updated' })} ${moment(wipPickListTimestamp).format(
                'YYYY-MM-DD HH:mm:ss'
              )}`}
            </small>
          </>
        }
        extra={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Select value={refreshInterval} onChange={this.handleSelectChange} style={{ width: 75 }}>
            <Option value={0}>{intl.formatMessage({ id: 'off' })}</Option>
            <Option value={30000}>30s</Option>
            <Option value={60000}>1min</Option>
            <Option value={120000}>2min</Option>
            <Option value={300000}>5min</Option>
          </Select>
        }
      >
        <Row type="flex" justify="center" gutter={[16, 8]}>
          <Col span={6}>
            <WipPickListTable match={match} />
            <WipPickListDialog match={match} />
          </Col>
          <Col span={6}>
            <WipLoadListTable match={match} />
            <WipLoadListDialog match={match} />
          </Col>
          <Col span={6}>
            <WipWhseJobTable match={match} />
            <WipWhseJobDialog match={match} />
          </Col>
          <Col span={6}>
            <CompleteLoadListTable match={match} />
            <CompleteLoadListDialog match={match} />
          </Col>
        </Row>
      </Card>
    );
  }
}

LoadListDetailBoardScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  wipPickListTimestamp: PropTypes.number,

  resetWipPickListTimestamp: PropTypes.func,
  resetWipLoadListTimestamp: PropTypes.func,
  resetWipWhseJobTimestamp: PropTypes.func,
  resetCompleteLoadListTimestamp: PropTypes.func
};

LoadListDetailBoardScreen.defaultProps = {
  intl: {},
  match: {},
  wipPickListTimestamp: 0,

  resetWipPickListTimestamp() {},
  resetWipLoadListTimestamp() {},
  resetWipWhseJobTimestamp() {},
  resetCompleteLoadListTimestamp() {}
};

const mapStateToProps = state => ({
  wipPickListTimestamp: state.loadListDetailBoard.wipPickListTimestamp
});

const mapDispatchToProps = dispatch => ({
  resetWipPickListTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      LoadListDetailBoardActions.loadListDetailBoardResetWipPickListTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  resetWipLoadListTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      LoadListDetailBoardActions.loadListDetailBoardResetWipLoadListTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  resetWipWhseJobTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      LoadListDetailBoardActions.loadListDetailBoardResetWipWhseJobTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  resetCompleteLoadListTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      LoadListDetailBoardActions.loadListDetailBoardResetCompleteLoadListTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoadListDetailBoardScreen));
