/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { ItemManufactureExcelTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const itemManufactureExcelShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const itemManufactureExcelUploadLoading = (state, { boolean }) => ({
  ...state,
  uploadIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ItemManufactureExcelTypes.ITEM_MANUFACTURE_EXCEL_SHOW_BATCH_JOB_STATUS_SUCCESS]: itemManufactureExcelShowBatchJobStatusSuccess,
  [ItemManufactureExcelTypes.ITEM_MANUFACTURE_EXCEL_UPLOAD_LOADING]: itemManufactureExcelUploadLoading
});
