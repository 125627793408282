import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  itemExcel01ShowBatchJobStatus: null,
  itemExcel01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  itemExcel01UploadExcel: ['file'],
  itemExcel01UploadLoading: ['boolean'],
  itemExcel01DownloadExcel: ['group']
});

export const ItemExcel01Types = Types;
export default Creators;
