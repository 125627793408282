/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { GdsRcptRtnIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const gdsRcptRtnIndexResetTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  toStorageBinOptions: INITIAL_STATE.toStorageBinOptions,
  toStorageBinOption: INITIAL_STATE.toStorageBinOption
});

export const gdsRcptRtnIndexFetchGdsRcptRtnIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const gdsRcptRtnIndexFetchGdsRcptRtnIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const gdsRcptRtnIndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const gdsRcptRtnIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const gdsRcptRtnIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const gdsRcptRtnIndexWorkspaceLoading = (state, { boolean }) => ({
  ...state,
  workspaceIsLoading: boolean
});

export const gdsRcptRtnIndexResetGdsRcptFServ02Success = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GdsRcptRtnIndexTypes.GDS_RCPT_RTN_INDEX_RESET_TIMESTAMP]: gdsRcptRtnIndexResetTimestamp,
  [GdsRcptRtnIndexTypes.GDS_RCPT_RTN_INDEX_FETCH_GDS_RCPT_RTN_INDEX_LOADING]: gdsRcptRtnIndexFetchGdsRcptRtnIndexLoading,
  [GdsRcptRtnIndexTypes.GDS_RCPT_RTN_INDEX_FETCH_GDS_RCPT_RTN_INDEX_SUCCESS]: gdsRcptRtnIndexFetchGdsRcptRtnIndexSuccess,
  [GdsRcptRtnIndexTypes.GDS_RCPT_RTN_INDEX_ADD_SELECTED_DOCUMENTS]: gdsRcptRtnIndexAddSelectedDocuments,
  [GdsRcptRtnIndexTypes.GDS_RCPT_RTN_INDEX_REMOVE_SELECTED_DOCUMENTS]: gdsRcptRtnIndexRemoveSelectedDocuments,
  [GdsRcptRtnIndexTypes.GDS_RCPT_RTN_INDEX_SET_WORKSPACE_VISIBLE]: gdsRcptRtnIndexSetWorkspaceVisible,
  [GdsRcptRtnIndexTypes.GDS_RCPT_RTN_INDEX_WORKSPACE_LOADING]: gdsRcptRtnIndexWorkspaceLoading,
  [GdsRcptRtnIndexTypes.GDS_RCPT_RTN_INDEX_RESET_GDS_RCPT_F_SERV02_SUCCESS]: gdsRcptRtnIndexResetGdsRcptFServ02Success
});
