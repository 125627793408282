import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  itemList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  itemList01GoToResource: ['resId'],
  itemList01NewResource: null,
  itemList01GoToAudit: ['hdrId'],
  itemList01FetchItemList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  itemList01FetchItemList01Loading: ['boolean'],
  itemList01FetchItemList01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  itemList01AddSelectedDocuments: ['selectedDocuments'],
  itemList01RemoveSelectedDocuments: ['selectedDocuments'],
  itemList01SetWorkspaceVisible: ['boolean']
});

export const ItemList01Types = Types;
export default Creators;
