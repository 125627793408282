import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  areaList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  areaList01GoToResource: ['resId'],
  areaList01NewResource: null,
  areaList01FetchAreaList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  areaList01FetchAreaList01Loading: ['boolean'],
  areaList01FetchAreaList01Success: ['resources', 'currentPage', 'lastPage', 'total', 'pageSize'],
  areaList01AddSelectedResources: ['selectedResources'],
  areaList01RemoveSelectedResources: ['selectedResources'],
  areaList01SetWorkspaceVisible: ['boolean']
});

export const AreaList01Types = Types;
export default Creators;
