/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  documents: [],
  fetchIsLoading: false,
  sorts: {
    code: 'ascend'
  },
  filters: {},
  pageSize: '20',
  currentPage: 1,
  lastPage: 10,
  total: 0,
  selectedResources: [],
  workspaceIsVisible: false,
  createIsLoading: false,
  newResources: [],

  barcodeDialogBarcode: '',
  barcodeDialogCode: '',
  barcodeDialogDesc01: '',
  barcodeDialogDesc02: '',
  barcodeDialogIsVisible: false,

  printIsLoading: false
};
