import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import InvDoc02Actions, { InvDoc02Types } from '../Stores/InvDoc02/Actions';

const getAppStore = state => state.app;

export function* invDoc02FetchInvDoc02({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(InvDoc02Actions.invDoc02FetchInvDoc02Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `invDoc/indexProcess/INV_DOC_02/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        InvDoc02Actions.invDoc02FetchInvDoc02Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(InvDoc02Actions.invDoc02FetchInvDoc02Loading(false));
  }
}

export function* invDoc02CreateInvDoc02({ hdrIds }) {
  try {
    yield put(InvDoc02Actions.invDoc02CreateInvDoc02Loading(true));

    const app = yield select(getAppStore);

    const postData = {
      hdrIds
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `invDoc/createProcess/INV_DOC_02`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(InvDoc02Actions.invDoc02CreateInvDoc02Success(result.data));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(InvDoc02Actions.invDoc02CreateInvDoc02Loading(false));
  }
}

export function* invDoc02GoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/gdsRcptDetail/update/${hdrId}`));
}

export const saga = [
  takeLatest(InvDoc02Types.INV_DOC02_GO_TO_DOCUMENT, invDoc02GoToDocument),
  takeLatest(InvDoc02Types.INV_DOC02_FETCH_INV_DOC02, invDoc02FetchInvDoc02),
  takeLatest(InvDoc02Types.INV_DOC02_CREATE_INV_DOC02, invDoc02CreateInvDoc02)
];
