import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspSlsOrdTrackingReportInitLspSlsOrdTracking: null,
  lspSlsOrdTrackingReportInitLspSlsOrdTrackingSuccess: ['criteria'],
  lspSlsOrdTrackingReportLspSlsOrdTracking: ['formikBag', 'criteria'],
  lspSlsOrdTrackingReportLspSlsOrdTrackingSuccess: ['criteria', 'reportData'],
  lspSlsOrdTrackingReportReportLoading: ['boolean'],

  lspSlsOrdTrackingReportFetchDivisionOptions: ['search'],
  lspSlsOrdTrackingReportFetchDivisionOptionLoading: ['boolean'],
  lspSlsOrdTrackingReportFetchDivisionOptionSuccess: ['options'],

  lspSlsOrdTrackingReportFetchDeliveryPointOptions: ['search'],
  lspSlsOrdTrackingReportFetchDeliveryPointOptionLoading: ['boolean'],
  lspSlsOrdTrackingReportFetchDeliveryPointOptionSuccess: ['options'],

  lspSlsOrdTrackingReportFetchDebtorOptions: ['search'],
  lspSlsOrdTrackingReportFetchDebtorOptionLoading: ['boolean'],
  lspSlsOrdTrackingReportFetchDebtorOptionSuccess: ['options'],

  lspSlsOrdTrackingReportFetchTransportOptions: ['search'],
  lspSlsOrdTrackingReportFetchTransportOptionLoading: ['boolean'],
  lspSlsOrdTrackingReportFetchTransportOptionSuccess: ['options']
});

export const LspSlsOrdTrackingReportTypes = Types;
export default Creators;
