import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsOrdSummaryBoardResetSlsOrdsTimestamp: [],
  slsOrdSummaryBoardFetchSlsOrds: null,
  slsOrdSummaryBoardFetchSlsOrdsLoading: ['boolean'],
  slsOrdSummaryBoardFetchSlsOrdsSuccess: ['total', 'date_oldest'],

  slsOrdSummaryBoardResetOutbOrdsTimestamp: [],
  slsOrdSummaryBoardFetchOutbOrds: null,
  slsOrdSummaryBoardFetchOutbOrdsLoading: ['boolean'],
  slsOrdSummaryBoardFetchOutbOrdsSuccess: ['total', 'date_oldest'],

  slsOrdSummaryBoardResetInvDocsTimestamp: [],
  slsOrdSummaryBoardFetchInvDocs: null,
  slsOrdSummaryBoardFetchInvDocsLoading: ['boolean'],
  slsOrdSummaryBoardFetchInvDocsSuccess: ['total', 'date_oldest'],

  slsOrdSummaryBoardResetNoPrfDelsTimestamp: [],
  slsOrdSummaryBoardFetchNoPrfDels: null,
  slsOrdSummaryBoardFetchNoPrfDelsLoading: ['boolean'],
  slsOrdSummaryBoardFetchNoPrfDelsSuccess: ['total', 'date_oldest'],

  lspSlsOrdSummaryBoardResetSlsOrdsTimestamp: [],
  lspSlsOrdSummaryBoardFetchSlsOrds: null,
  lspSlsOrdSummaryBoardFetchSlsOrdsLoading: ['boolean'],
  lspSlsOrdSummaryBoardFetchSlsOrdsSuccess: ['total', 'date_oldest'],

  lspSlsOrdSummaryBoardResetOutbOrdsTimestamp: [],
  lspSlsOrdSummaryBoardFetchOutbOrds: null,
  lspSlsOrdSummaryBoardFetchOutbOrdsLoading: ['boolean'],
  lspSlsOrdSummaryBoardFetchOutbOrdsSuccess: ['total', 'date_oldest'],

  lspSlsOrdSummaryBoardResetInvDocsTimestamp: [],
  lspSlsOrdSummaryBoardFetchInvDocs: null,
  lspSlsOrdSummaryBoardFetchInvDocsLoading: ['boolean'],
  lspSlsOrdSummaryBoardFetchInvDocsSuccess: ['total', 'date_oldest'],

  lspSlsOrdSummaryBoardResetNoPrfDelsTimestamp: [],
  lspSlsOrdSummaryBoardFetchNoPrfDels: null,
  lspSlsOrdSummaryBoardFetchNoPrfDelsLoading: ['boolean'],
  lspSlsOrdSummaryBoardFetchNoPrfDelsSuccess: ['total', 'date_oldest'],

  pickListSummaryBoardResetOutbOrdsTimestamp: [],
  pickListSummaryBoardFetchOutbOrds: null,
  pickListSummaryBoardFetchOutbOrdsLoading: ['boolean'],
  pickListSummaryBoardFetchOutbOrdsSuccess: ['total', 'date_oldest'],

  pickListSummaryBoardResetWipTimestamp: [],
  pickListSummaryBoardFetchWip: null,
  pickListSummaryBoardFetchWipLoading: ['boolean'],
  pickListSummaryBoardFetchWipSuccess: ['total', 'date_oldest'],

  pickListSummaryBoardResetWhseJobsTimestamp: [],
  pickListSummaryBoardFetchWhseJobs: null,
  pickListSummaryBoardFetchWhseJobsLoading: ['boolean'],
  pickListSummaryBoardFetchWhseJobsSuccess: ['total', 'date_oldest'],

  pickListSummaryBoardResetCompletedTimestamp: [],
  pickListSummaryBoardFetchCompleted: null,
  pickListSummaryBoardFetchCompletedLoading: ['boolean'],
  pickListSummaryBoardFetchCompletedSuccess: ['total', 'date_oldest'],

  loadListSummaryBoardResetPendingTimestamp: [],
  loadListSummaryBoardFetchPending: null,
  loadListSummaryBoardFetchPendingLoading: ['boolean'],
  loadListSummaryBoardFetchPendingSuccess: ['total', 'date_oldest'],

  loadListSummaryBoardResetWipTimestamp: [],
  loadListSummaryBoardFetchWip: null,
  loadListSummaryBoardFetchWipLoading: ['boolean'],
  loadListSummaryBoardFetchWipSuccess: ['total', 'date_oldest'],

  loadListSummaryBoardResetWhseJobsTimestamp: [],
  loadListSummaryBoardFetchWhseJobs: null,
  loadListSummaryBoardFetchWhseJobsLoading: ['boolean'],
  loadListSummaryBoardFetchWhseJobsSuccess: ['total', 'date_oldest'],

  loadListSummaryBoardResetCompletedTimestamp: [],
  loadListSummaryBoardFetchCompleted: null,
  loadListSummaryBoardFetchCompletedLoading: ['boolean'],
  loadListSummaryBoardFetchCompletedSuccess: ['total', 'date_oldest']
});

export const SummaryBoardTypes = Types;
export default Creators;
