import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';

import { Card, Tabs } from 'antd';
import ModelForm from './ModelForm';
import CountAdjReasonDetailActions from '../../Stores/CountAdjReasonDetail/Actions';

const { TabPane } = Tabs;

class CountAdjReasonDetailScreen extends React.PureComponent {
  componentDidMount() {
    const { match, setReslId } = this.props;

    if (match.params.action === 'create') {
      setReslId(0);
    } else if (match.params.action === 'update') {
      setReslId(parseInt(match.params.id, 10));
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const { intl, match, model, modelIsLoading } = this.props;

    return (
      <Card
        title={`${intl.formatMessage({ id: 'count_adj_reason' })} ${model.code}`}
        loading={modelIsLoading}
      >
        <Tabs type="card" defaultActiveKey="general">
          <TabPane tab={intl.formatMessage({ id: 'general' })} key="general">
            <ModelForm match={match} />
          </TabPane>
        </Tabs>
      </Card>
    );
  }
}

CountAdjReasonDetailScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,

  setReslId: PropTypes.func,
  model: PropTypes.shape({
    code: PropTypes.string,
    status: PropTypes.number
  }),
  modelIsLoading: PropTypes.bool
};

CountAdjReasonDetailScreen.defaultProps = {
  intl: {},
  match: {},

  setReslId() {},
  model: { code: '', status: 2 },
  modelIsLoading: false
};

const mapStateToProps = state => ({
  model: state.countAdjReasonDetail.model,
  modelIsLoading: state.countAdjReasonDetail.modelIsLoading
});

const mapDispatchToProps = dispatch => ({
  setReslId: resId => dispatch(CountAdjReasonDetailActions.countAdjReasonDetailSetResId(resId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CountAdjReasonDetailScreen));
