import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import { Row, Col, Card, Select, Typography } from 'antd';
import WipGdsRcptTable from './WipGdsRcptTable';
import WipPutAwayTable from './WipPutAwayTable';
import WipWhseJobTable from './WipWhseJobTable';
import CompletePutAwayTable from './CompletePutAwayTable';
import WipGdsRcptDialog from './WipGdsRcptDialog';
import WipPutAwayDialog from './WipPutAwayDialog';
import WipWhseJobDialog from './WipWhseJobDialog';
import CompletePutAwayDialog from './CompletePutAwayDialog';

import PutAwayDetailBoardActions from '../../Stores/PutAwayDetailBoard/Actions';

const { Option } = Select;
const { Text } = Typography;

class PutAwayDetailBoardScreen extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      intervalId: 0,
      // milisecond
      refreshInterval: 300000
    };

    this.handleInterval = this.handleInterval.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    const { refreshInterval } = this.state;

    if (refreshInterval > 0) {
      const intervalId = setInterval(this.handleInterval, refreshInterval);
      this.setState({
        intervalId
      });
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    const { intervalId } = this.state;

    clearInterval(intervalId);
  }

  handleInterval() {
    const {
      resetWipPutAwayTimestamp,
      resetGdsRcptTimestamp,
      resetWipWhseJobTimestamp,
      resetCompletePutAwayTimestamp
    } = this.props;

    resetGdsRcptTimestamp();
    resetWipPutAwayTimestamp();
    resetWipWhseJobTimestamp();
    resetCompletePutAwayTimestamp();
  }

  handleSelectChange(value) {
    let { intervalId } = this.state;

    this.setState({
      refreshInterval: value
    });

    clearInterval(intervalId);

    if (value > 0) {
      intervalId = setInterval(this.handleInterval, value);

      this.setState({
        intervalId
      });
    }
  }

  render() {
    const { intl, match, wipGdsRcptTimestamp } = this.props;
    const { refreshInterval } = this.state;

    return (
      <Card
        title={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <>
            <Text>{intl.formatMessage({ id: 'put_away_details_kanban_board' })}</Text>
            <br />
            <small>
              {`${intl.formatMessage({ id: 'last_updated' })} ${moment(wipGdsRcptTimestamp).format(
                'YYYY-MM-DD HH:mm:ss'
              )}`}
            </small>
          </>
        }
        extra={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Select value={refreshInterval} onChange={this.handleSelectChange} style={{ width: 75 }}>
            <Option value={0}>{intl.formatMessage({ id: 'off' })}</Option>
            <Option value={30000}>30s</Option>
            <Option value={60000}>1min</Option>
            <Option value={120000}>2min</Option>
            <Option value={300000}>5min</Option>
          </Select>
        }
      >
        <Row type="flex" justify="center" gutter={[16, 8]}>
          <Col span={6}>
            <WipGdsRcptTable match={match} />
            <WipGdsRcptDialog match={match} />
          </Col>
          <Col span={6}>
            <WipPutAwayTable match={match} />
            <WipPutAwayDialog match={match} />
          </Col>
          <Col span={6}>
            <WipWhseJobTable match={match} />
            <WipWhseJobDialog match={match} />
          </Col>
          <Col span={6}>
            <CompletePutAwayTable match={match} />
            <CompletePutAwayDialog match={match} />
          </Col>
        </Row>
      </Card>
    );
  }
}

PutAwayDetailBoardScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  wipGdsRcptTimestamp: PropTypes.number,

  resetGdsRcptTimestamp: PropTypes.func,
  resetWipPutAwayTimestamp: PropTypes.func,
  resetWipWhseJobTimestamp: PropTypes.func,
  resetCompletePutAwayTimestamp: PropTypes.func
};

PutAwayDetailBoardScreen.defaultProps = {
  intl: {},
  match: {},
  wipGdsRcptTimestamp: 0,

  resetGdsRcptTimestamp() {},
  resetWipPutAwayTimestamp() {},
  resetWipWhseJobTimestamp() {},
  resetCompletePutAwayTimestamp() {}
};

const mapStateToProps = state => ({
  wipGdsRcptTimestamp: state.putAwayDetailBoard.wipGdsRcptTimestamp
});

const mapDispatchToProps = dispatch => ({
  resetGdsRcptTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PutAwayDetailBoardActions.putAwayDetailBoardResetWipGdsRcptTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  resetWipPutAwayTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PutAwayDetailBoardActions.putAwayDetailBoardResetWipPutAwayTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  resetWipWhseJobTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PutAwayDetailBoardActions.putAwayDetailBoardResetWipWhseJobTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  resetCompletePutAwayTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PutAwayDetailBoardActions.putAwayDetailBoardResetCompletePutAwayTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PutAwayDetailBoardScreen));
