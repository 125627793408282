import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  deliveryPointDetailResetModelTimestamp: null,
  deliveryPointDetailSetResId: ['resId'],

  deliveryPointDetailInitModel: ['debtorId'],
  deliveryPointDetailShowModel: ['resId'],
  deliveryPointDetailShowModelSuccess: ['model'],

  deliveryPointDetailUpdateModel: ['formikBag', 'model'],
  deliveryPointDetailCreateModel: ['formikBag', 'model'],
  deliveryPointDetailUpdateModelSuccess: ['model'],

  deliveryPointDetailShowModelLoading: ['boolean'],

  deliveryPointDetailFetchDebtorGroup01Options: ['search'],
  deliveryPointDetailFetchDebtorGroup01OptionLoading: ['boolean'],
  deliveryPointDetailFetchDebtorGroup01OptionSuccess: ['options'],

  deliveryPointDetailFetchDebtorGroup02Options: ['search'],
  deliveryPointDetailFetchDebtorGroup02OptionLoading: ['boolean'],
  deliveryPointDetailFetchDebtorGroup02OptionSuccess: ['options'],

  deliveryPointDetailFetchDebtorGroup03Options: ['search'],
  deliveryPointDetailFetchDebtorGroup03OptionLoading: ['boolean'],
  deliveryPointDetailFetchDebtorGroup03OptionSuccess: ['options'],

  deliveryPointDetailFetchAreaOptions: ['search'],
  deliveryPointDetailFetchAreaOptionLoading: ['boolean'],
  deliveryPointDetailFetchAreaOptionSuccess: ['options']
});

export const DeliveryPointDetailTypes = Types;
export default Creators;
