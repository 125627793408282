import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  manufactureItemListResetTimestamp: ['procType', 'currentPage', 'sorts', 'filters', 'pageSize'],
  manufactureItemListGoToResource: ['resId'],
  manufactureItemListNewResource: ['itemType'],
  manufactureItemListGoToAudit: ['hdrId'],
  manufactureItemListFetchList: ['procType', 'currentPage', 'sorts', 'filters', 'pageSize'],
  manufactureItemListFetchListLoading: ['boolean'],
  manufactureItemListFetchListSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  manufactureItemListAddSelectedDocuments: ['selectedDocuments'],
  manufactureItemListRemoveSelectedDocuments: ['selectedDocuments'],
  manufactureItemListSetWorkspaceVisible: ['boolean']
});

export const ManufactureItemListTypes = Types;
export default Creators;
