import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { UndoOutlined, UploadOutlined } from '@ant-design/icons';

import { Card, Row, Col, Upload, Button, Progress, notification, Typography } from 'antd';
import { FormikButton, FormikSelect } from '../../Components/Formik';

import LspSlsOrdExcel01Actions from '../../Stores/LspSlsOrdExcel01/Actions';

const { Text } = Typography;

class LspSlsOrdExcel01Form extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      slsOrdFile: []
    };

    this.handleDebtorSearch = this.handleDebtorSearch.bind(this);
    this.handleProjectSearch = this.handleProjectSearch.bind(this);
    this.useOnUpload = this.useOnUpload.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {
    const { uploadIsLoading, showBatchJobStatus } = this.props;

    if (uploadIsLoading) {
      setTimeout(() => {
        showBatchJobStatus();
      }, 10000);
    }
  }

  componentWillUnmount() {}

  handleDebtorSearch(form, value) {
    const { fetchDebtorOptions } = this.props;

    fetchDebtorOptions(value);
  }

  handleProjectSearch(form, value) {
    const { fetchProjectOptions } = this.props;

    fetchProjectOptions(value);
  }

  useOnUpload(values, formikBag) {
    const { uploadExcel } = this.props;
    const { slsOrdFile } = this.state;

    let debtorId = 0;
    if (values.debtor_select2.value > 0) {
      debtorId = values.debtor_select2.value;
    }

    let projectId = 0;
    if (values.project_select2.value > 0) {
      projectId = values.project_select2.value;
    }
    if (slsOrdFile.length > 0 && debtorId > 0 && projectId > 0) {
      uploadExcel(formikBag, projectId, debtorId, slsOrdFile[0]);
    }
  }

  render() {
    const {
      intl,
      uploadIsLoading,
      batchJobStatus,
      debtorIsLoading,
      debtorOptions,
      projectIsLoading,
      projectOptions
    } = this.props;
    const { slsOrdFile } = this.state;

    return (
      <Card>
        <Formik
          enableReinitialize
          initialValues={{
            debtor_select2: {
              value: 0,
              label: ''
            },
            project_select2: {
              value: 0,
              label: ''
            }
          }}
          onSubmit={this.useOnUpload}
          validationSchema={Yup.object().shape({
            debtor_select2: Yup.object().shape({
              value: Yup.number().min(1, intl.formatMessage({ id: 'principal_is_required' }))
            }),
            project_select2: Yup.object().shape({
              value: Yup.number().min(1, intl.formatMessage({ id: 'project_is_required' }))
            })
          })}
        >
          {({
            // values,
            // handleChange,
            // errors,
            // setFieldTouched,
            dirty,
            // touched,
            isSubmitting,
            // isValid,
            handleSubmit,
            handleReset
          }) => (
            <Form>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'principal' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="debtor_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleDebtorSearch}
                    notFoundContent={null}
                    loading={debtorIsLoading}
                    options={debtorOptions}
                  />
                </Col>
                <Col span={12} />
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'project' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="project_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleProjectSearch}
                    notFoundContent={null}
                    loading={projectIsLoading}
                    options={projectOptions}
                  />
                </Col>
                <Col span={12} />
              </Row>

              <Row type="flex" justify="start" gutter={[0, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'sales_file' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={12}>
                  <Upload
                    disabled={uploadIsLoading}
                    onRemove={() => {
                      this.setState({
                        slsOrdFile: []
                      });
                    }}
                    beforeUpload={file => {
                      const validFileName1 = 'SLS_ORD';
                      const validFileName2 = 'SLSORD';
                      const validFileName3 = 'SLS_INV';
                      const validFileName4 = 'SLSINV';
                      if (
                        file &&
                        (file.name.toUpperCase().includes(validFileName1) ||
                          file.name.toUpperCase().includes(validFileName2) ||
                          file.name.toUpperCase().includes(validFileName3) ||
                          file.name.toUpperCase().includes(validFileName4))
                      ) {
                        this.setState({
                          slsOrdFile: [file]
                        });
                      } else {
                        notification.error({
                          message: `${intl.formatMessage({
                            id: 'invalid_file_name_must_contain'
                          })} ${validFileName1}/${validFileName3}`,
                          duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
                        });
                      }

                      return false;
                    }}
                    fileList={slsOrdFile}
                  >
                    <Button type="primary" loading={uploadIsLoading}>
                      {intl.formatMessage({ id: 'browse' })}
                    </Button>
                  </Upload>
                </Col>
              </Row>

              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={6}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting}
                    onClick={handleReset}
                    icon={<UndoOutlined />}
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    type="primary"
                    name="upload_action"
                    value="update"
                    component={FormikButton}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'upload'
                    })}
                    icon={<UploadOutlined />}
                    disabled={slsOrdFile.length === 0}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[0, 8]}>
                <Col span={24}>
                  <Progress percent={parseInt(batchJobStatus.status_number, 10)} />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card>
    );
  }
}

LspSlsOrdExcel01Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  batchJobStatus: PropTypes.shape({
    status_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  uploadIsLoading: PropTypes.bool,

  showBatchJobStatus: PropTypes.func,
  uploadExcel: PropTypes.func,

  fetchDebtorOptions: PropTypes.func,
  debtorIsLoading: PropTypes.bool,
  debtorOptions: PropTypes.arrayOf(PropTypes.object),

  fetchProjectOptions: PropTypes.func,
  projectIsLoading: PropTypes.bool,
  projectOptions: PropTypes.arrayOf(PropTypes.object)
};

LspSlsOrdExcel01Form.defaultProps = {
  intl: {},

  batchJobStatus: {
    status_number: '0'
  },
  uploadIsLoading: false,

  showBatchJobStatus() {},
  uploadExcel() {},

  fetchDebtorOptions() {},
  debtorIsLoading: false,
  debtorOptions: [],

  fetchProjectOptions() {},
  projectIsLoading: false,
  projectOptions: []
};

const mapStateToProps = state => ({
  batchJobStatus: state.lspSlsOrdExcel01.batchJobStatus,
  uploadIsLoading: state.lspSlsOrdExcel01.uploadIsLoading,

  debtorIsLoading: state.lspSlsOrdExcel01.debtorIsLoading,
  debtorOptions: state.lspSlsOrdExcel01.debtorOptions,

  projectIsLoading: state.lspSlsOrdExcel01.projectIsLoading,
  projectOptions: state.lspSlsOrdExcel01.projectOptions
});

const mapDispatchToProps = dispatch => ({
  showBatchJobStatus: () => dispatch(LspSlsOrdExcel01Actions.lspSlsOrdExcel01ShowBatchJobStatus()),
  uploadExcel: (formikBag, projectId, debtorId, slsOrdFile) =>
    dispatch(
      LspSlsOrdExcel01Actions.lspSlsOrdExcel01UploadExcel(
        formikBag,
        projectId,
        debtorId,
        slsOrdFile
      )
    ),

  fetchDebtorOptions: search =>
    dispatch(LspSlsOrdExcel01Actions.lspSlsOrdExcel01FetchDebtorOptions(search)),

  fetchProjectOptions: search =>
    dispatch(LspSlsOrdExcel01Actions.lspSlsOrdExcel01FetchProjectOptions(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LspSlsOrdExcel01Form));
