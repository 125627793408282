import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  outbOrdIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  outbOrdIndexFetchOutbOrdIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  outbOrdIndexFetchOutbOrdIndexLoading: ['boolean'],
  outbOrdIndexFetchOutbOrdIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  outbOrdIndexAddSelectedDocuments: ['selectedDocuments'],
  outbOrdIndexRemoveSelectedDocuments: ['selectedDocuments'],
  outbOrdIndexSetWorkspaceVisible: ['boolean'],
  outbOrdIndexGoToDocument: ['hdrId'],
  outbOrdIndexGoToAudit: ['hdrId']
});

export const OutbOrdIndexTypes = Types;
export default Creators;
