import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import { Formik, Form, Field } from 'formik';
import treeChanges from 'tree-changes';

import { Modal, Table, Button } from 'antd';
import { UndoOutlined, SaveOutlined } from '@ant-design/icons';
import { FormikInputNumber } from '../../Components/Formik';

import InbOrd01Actions from '../../Stores/InbOrd01/Actions';

class PartialDelDialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);

    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { selectedDocument, showDetails, showDetailsSuccess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('timestamp')) {
      if (selectedDocument.id === 0) {
        showDetailsSuccess([]);
      } else {
        showDetails(selectedDocument.id);
      }
    }
  }

  componentWillUnmount() {}

  handleCancel() {
    const { createIsLoading, setDetailVisible } = this.props;

    if (createIsLoading === false) {
      setDetailVisible(false);
    }
  }

  getDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        width: 30,
        fixed: 'left',
        align: 'right',
        title: '',
        // sort field
        dataIndex: 'line_no',
        // filter field
        key: 'line_no',
        render: (text, record) => <>{record.line_no}</>
      },
      {
        width: 200,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        // filter field
        key: 'desc_01',
        render: (text, record) => (
          <>
            <b>{record.item_code}</b>
            <br />
            {record.desc_01}
            {record.desc_02 ? (
              <>
                <br />
                {record.desc_02}
              </>
            ) : (
              ''
            )}
            {record.desc_03 ? (
              <>
                <br />
                {record.desc_03}
              </>
            ) : (
              ''
            )}
            <br />
            <b>{record.location_code}</b>
          </>
        )
      },
      {
        width: 70,
        align: 'right',
        title: intl.formatMessage({ id: 'price' }),
        // sort field
        dataIndex: 'sale_price',
        // filter field
        key: 'sale_price',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_PRICE_SCALE,
              maximumFractionDigits: process.env.REACT_APP_PRICE_SCALE
            }).format(record.sale_price)}
            {record.price_disc > 0 ? (
              <>
                <br />
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.price_disc)}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'disc' }),
        // sort field
        dataIndex: 'dtl_disc_perc_01',
        // filter field
        key: 'dtl_disc_perc_01',
        render: (text, record) => (
          <>
            {record.dtl_disc_val_01 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_val_01)}
                &nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_perc_01 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_perc_01)}
                %&nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_val_02 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_val_02)}
                &nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_perc_02 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_perc_02)}
                %&nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_val_03 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_val_03)}
                &nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_perc_03 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_perc_03)}
                %&nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_val_04 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_val_04)}
                &nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_perc_04 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_perc_04)}
                %&nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_val_05 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_val_05)}
                &nbsp;
              </>
            ) : (
              ''
            )}

            {record.dtl_disc_perc_05 > 0 ? (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.dtl_disc_perc_05)}
                %&nbsp;
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 90,
        align: 'right',
        title: intl.formatMessage({ id: 'net_amt' }),
        // sort field
        dataIndex: 'net_amt',
        // filter field
        key: 'net_amt',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.net_amt)}
          </>
        )
      },
      {
        width: 80,
        align: 'right',
        title: intl.formatMessage({ id: 'original_qty' }),
        // sort field
        dataIndex: 'qty',
        // filter field
        key: 'qty',
        render: (text, record) => {
          return (
            <>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.qty)}
            </>
          );
        }
      },
      {
        width: 70,
        align: 'left',
        title: intl.formatMessage({ id: 'uom' }),
        // sort field
        dataIndex: 'uom_code',
        // filter field
        key: 'uom_code',
        render: (text, record) => <>{record.uom_select2.label}</>
      },
      {
        width: 80,
        align: 'right',
        title: intl.formatMessage({ id: 'partial_qty' }),
        // sort field
        dataIndex: 'partial_qty',
        // filter field
        key: 'partial_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.partial_qty)}
          </>
        )
      },
      {
        width: 80,
        align: 'right',
        title: intl.formatMessage({ id: 'qty' }),
        // sort field
        dataIndex: 'current_qty',
        // filter field
        key: 'current_qty',
        render: (text, record, index) => {
          return (
            <>
              <Field
                name={`details[${index}].current_qty`}
                component={FormikInputNumber}
                precision={record.qty_scale || 0}
                max={record.qty}
                min={0.0000000001}
              />
            </>
          );
        }
      }
    ];
  }

  render() {
    const {
      intl,
      timestamp,
      selectedDocument,
      documentDetails,
      detailIsVisible,
      createIsLoading,
      createInbOrd01Partial
    } = this.props;

    return (
      <>
        <Formik
          enableReinitialize
          initialValues={{ timestamp, details: documentDetails }}
          onSubmit={(values, formikBag) => {
            createInbOrd01Partial(formikBag, selectedDocument.id, values.details);
          }}
          /* validationSchema={Yup.object().shape({
            details: Yup.array().of(
              Yup.object().shape({
                qty: Yup.number().moreThan(-1, intl.formatMessage({ id: 'qty_must_be_positive' }))
              })
            )
          })} */
        >
          {({
            values,
            // handleChange,
            // errors,
            // setFieldTouched,
            dirty,
            // touched,
            isSubmitting,
            // isValid,
            handleSubmit,
            handleReset
          }) => (
            <Form>
              <Modal
                visible={detailIsVisible}
                title={selectedDocument.doc_code}
                // style={{top:20}}
                width="80%"
                centered
                // onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting || createIsLoading}
                    onClick={handleReset}
                    icon={<UndoOutlined />}
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>,
                  <Button
                    type="primary"
                    loading={isSubmitting || createIsLoading}
                    onClick={handleSubmit}
                    icon={<SaveOutlined />}
                  >
                    {intl.formatMessage({ id: 'create' })}
                  </Button>
                ]}
              >
                <Table
                  size="small"
                  // rowSelection={rowSelection}
                  rowKey="id"
                  pagination={{ pageSize: Number.MAX_SAFE_INTEGER }}
                  columns={this.getDocumentColumns()}
                  dataSource={values.details}
                  loading={createIsLoading}
                  bordered
                  scroll={{ x: 950 }}
                />
              </Modal>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

PartialDelDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  timestamp: PropTypes.number,
  selectedDocument: PropTypes.shape({
    id: PropTypes.number,
    doc_code: PropTypes.string
  }),
  showDetails: PropTypes.func,
  showDetailsSuccess: PropTypes.func,
  setDetailVisible: PropTypes.func,
  detailIsVisible: PropTypes.bool,
  createIsLoading: PropTypes.bool,
  documentDetails: PropTypes.arrayOf(PropTypes.object),
  createInbOrd01Partial: PropTypes.func
};

PartialDelDialog.defaultProps = {
  intl: {},
  timestamp: 0,
  selectedDocument: { id: 0, doc_code: '' },
  showDetails() {},
  showDetailsSuccess() {},
  setDetailVisible() {},
  detailIsVisible: false,
  createIsLoading: false,
  documentDetails: [],
  createInbOrd01Partial() {}
};

const mapStateToProps = state => ({
  timestamp: state.inbOrd01.detailTimestamp,
  selectedDocument: state.inbOrd01.selectedDocument,
  detailIsVisible: state.inbOrd01.detailIsVisible,
  createIsLoading: state.inbOrd01.createIsLoading,
  documentDetails: state.inbOrd01.documentDetails
});

const mapDispatchToProps = dispatch => ({
  showDetails: hdrId => dispatch(InbOrd01Actions.inbOrd01ShowDetails(hdrId)),
  showDetailsSuccess: details => dispatch(InbOrd01Actions.inbOrd01ShowDetailsSuccess(details)),
  setDetailVisible: boolean => dispatch(InbOrd01Actions.inbOrd01SetDetailVisible(boolean)),

  createInbOrd01Partial: (formikBag, hdrId, documentDetails) =>
    dispatch(InbOrd01Actions.inbOrd01CreateInbOrd01Partial(formikBag, hdrId, documentDetails))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PartialDelDialog));
