import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Card, Breadcrumb } from 'antd';
import HeaderForm from './HeaderForm';
import OutbOrdTable from './OutbOrdTable';
import InbOrdTable from './InbOrdTable';
import WhseJob06DetailActions from '../../Stores/WhseJob06Detail/Actions';

class WhseJob06DetailScreen extends React.PureComponent {
  componentDidMount() {
    const { match, setHdrId } = this.props;

    if (match.params.action === 'create') {
      setHdrId(0);
    } else if (match.params.action === 'update') {
      setHdrId(parseInt(match.params.id, 10));
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const { intl, match, documentHeader, appPath } = this.props;

    return (
      <Card title={intl.formatMessage({ id: 'load_list' })}>
        <Breadcrumb style={{ overflowWrap: 'break-word' }}>
          {documentHeader.doc_flows.map(docData =>
            docData.doc_id > 0 ? (
              <Breadcrumb.Item key={docData.doc_id}>
                {docData.is_current === true ? (
                  docData.doc_code
                ) : (
                  <Link replace to={`${appPath}/${docData.action}/update/${docData.doc_id}`}>
                    <span style={{ color: 'blue' }}>{docData.doc_code}</span>
                  </Link>
                )}
              </Breadcrumb.Item>
            ) : (
              ''
            )
          )}
        </Breadcrumb>
        <HeaderForm match={match} />
        <OutbOrdTable match={match} />
        <InbOrdTable match={match} />
      </Card>
    );
  }
}

WhseJob06DetailScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,

  setHdrId: PropTypes.func,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number,
    doc_flows: PropTypes.arrayOf(PropTypes.object)
  }),

  appPath: PropTypes.string
};

WhseJob06DetailScreen.defaultProps = {
  intl: {},
  match: {},

  setHdrId() {},
  documentHeader: { doc_status: 3, doc_flows: [] },

  appPath: ''
};

const mapStateToProps = state => ({
  documentHeader: state.whseJob06Detail.documentHeader,

  appPath: state.app.appPath
});

const mapDispatchToProps = dispatch => ({
  setHdrId: hdrId => dispatch(WhseJob06DetailActions.whseJob06DetailSetHdrId(hdrId)),
  setDetailVisible: boolean =>
    dispatch(WhseJob06DetailActions.whseJob06DetailSetDetailVisible(boolean)),

  setWhseJobType: whseJobType =>
    dispatch(WhseJob06DetailActions.whseJob06DetailSetWhseJobType(whseJobType))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WhseJob06DetailScreen));
