/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { DelOrdIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const delOrdIndexResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const delOrdIndexFetchDelOrdIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const delOrdIndexFetchDelOrdIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const delOrdIndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const delOrdIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const delOrdIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const delOrdIndexWorkspaceLoading = (state, { boolean }) => ({
  ...state,
  workspaceIsLoading: boolean
});

export const delOrdIndexResetDelOrdFServ01Success = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [DelOrdIndexTypes.DEL_ORD_INDEX_RESET_TIMESTAMP]: delOrdIndexResetTimestamp,
  [DelOrdIndexTypes.DEL_ORD_INDEX_FETCH_DEL_ORD_INDEX_LOADING]: delOrdIndexFetchDelOrdIndexLoading,
  [DelOrdIndexTypes.DEL_ORD_INDEX_FETCH_DEL_ORD_INDEX_SUCCESS]: delOrdIndexFetchDelOrdIndexSuccess,
  [DelOrdIndexTypes.DEL_ORD_INDEX_ADD_SELECTED_DOCUMENTS]: delOrdIndexAddSelectedDocuments,
  [DelOrdIndexTypes.DEL_ORD_INDEX_REMOVE_SELECTED_DOCUMENTS]: delOrdIndexRemoveSelectedDocuments,
  [DelOrdIndexTypes.DEL_ORD_INDEX_SET_WORKSPACE_VISIBLE]: delOrdIndexSetWorkspaceVisible,
  [DelOrdIndexTypes.DEL_ORD_INDEX_WORKSPACE_LOADING]: delOrdIndexWorkspaceLoading,
  [DelOrdIndexTypes.DEL_ORD_INDEX_RESET_DEL_ORD_F_SERV01_SUCCESS]: delOrdIndexResetDelOrdFServ01Success
});
