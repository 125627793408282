/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CreditorProcessTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const creditorProcessFetchCreditorProcessLoading = (state, { boolean }) => ({
  ...state,
  processIsLoading: boolean
});

export const creditorProcessFetchCreditorProcessSuccess = (state, { process }) => ({
  ...state,
  process
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CreditorProcessTypes.CREDITOR_PROCESS_FETCH_CREDITOR_PROCESS_LOADING]: creditorProcessFetchCreditorProcessLoading,
  [CreditorProcessTypes.CREDITOR_PROCESS_FETCH_CREDITOR_PROCESS_SUCCESS]: creditorProcessFetchCreditorProcessSuccess
});
