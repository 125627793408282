import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  prodRcptDetailResetTimestamp: null,
  prodRcptDetailSetHdrId: ['hdrId', 'itemId'],

  prodRcptDetailInitHeader: null,
  prodRcptDetailShowHeader: ['hdrId'],
  prodRcptDetailShowHeaderSuccess: ['documentHeader'],

  prodRcptDetailUpdateHeader: ['formikBag', 'documentHeader'],
  prodRcptDetailCreateHeader: ['formikBag', 'documentHeader'],
  prodRcptDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  prodRcptDetailShowDocumentLoading: ['boolean'],
  prodRcptDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  prodRcptDetailShowDetails: ['hdrId'],
  prodRcptDetailShowDetailsSuccess: ['documentDetails'],

  prodRcptDetailSetDetailVisible: ['boolean'],
  prodRcptDetailSetDocumentDetail: ['documentDetail'],

  prodRcptDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  prodRcptDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  prodRcptDetailDeleteDetail: ['hdrId', 'documentDetail'],

  prodRcptDetailFetchCurrencyOptions: ['search'],
  prodRcptDetailFetchCurrencyOptionLoading: ['boolean'],
  prodRcptDetailFetchCurrencyOptionSuccess: ['options'],
  prodRcptDetailChangeCurrency: ['formikBag', 'currencyId'],

  prodRcptDetailFetchDtlLocationOptions: ['search'],
  prodRcptDetailFetchDtlLocationOptionLoading: ['boolean'],
  prodRcptDetailFetchDtlLocationOptionSuccess: ['options'],

  prodRcptDetailFetchDtlItemOptions: ['search'],
  prodRcptDetailFetchDtlItemOptionLoading: ['boolean'],
  prodRcptDetailFetchDtlItemOptionSuccess: ['options'],
  prodRcptDetailChangeDtlItem: ['formikBag', 'hdrId', 'itemId'],

  prodRcptDetailFetchDtlUomOptions: ['itemId', 'search'],
  prodRcptDetailFetchDtlUomOptionLoading: ['boolean'],
  prodRcptDetailFetchDtlUomOptionSuccess: ['options'],
  prodRcptDetailChangeDtlUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  prodRcptDetailFetchProjectOptions: ['search'],
  prodRcptDetailFetchProjectOptionLoading: ['boolean'],
  prodRcptDetailFetchProjectOptionSuccess: ['options']
});

export const ProdRcptDetailTypes = Types;
export default Creators;
