import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import { Row, Col, Card, Select, Typography } from 'antd';
import WipOutbOrdTable from './WipOutbOrdTable';
import WipPickListTable from './WipPickListTable';
import WipWhseJobTable from './WipWhseJobTable';
import CompletePickListTable from './CompletePickListTable';
import WipWhseJobDialog from './WipWhseJobDialog';
import WipPickListDialog from './WipPickListDialog';
import WipOutbOrdDialog from './WipOutbOrdDialog';
import CompletePickListDialog from './CompletePickListDialog';

import PickListDetailBoardActions from '../../Stores/PickListDetailBoard/Actions';

const { Option } = Select;
const { Text } = Typography;

class PickListDetailBoardScreen extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      intervalId: 0,
      // milisecond
      refreshInterval: 300000
    };

    this.handleInterval = this.handleInterval.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    const { refreshInterval } = this.state;

    if (refreshInterval > 0) {
      const intervalId = setInterval(this.handleInterval, refreshInterval);
      this.setState({
        intervalId
      });
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    const { intervalId } = this.state;

    clearInterval(intervalId);
  }

  handleInterval() {
    const {
      resetWipOutbOrdTimestamp,
      resetPickListTimestamp,
      resetWipWhseJobTimestamp,
      resetCompletePickListTimestamp
    } = this.props;

    resetWipOutbOrdTimestamp();
    resetPickListTimestamp();
    resetWipWhseJobTimestamp();
    resetCompletePickListTimestamp();
  }

  handleSelectChange(value) {
    let { intervalId } = this.state;

    this.setState({
      refreshInterval: value
    });

    clearInterval(intervalId);

    if (value > 0) {
      intervalId = setInterval(this.handleInterval, value);

      this.setState({
        intervalId
      });
    }
  }

  render() {
    const {
      intl,
      match,
      wipOutbOrdTimestamp,
      loadingBinOptions,
      loadingBinId,
      wipWhseJobDocumentIsLoading,
      fetchLoadingBinOptions,
      changeLoadingBin
    } = this.props;
    const { refreshInterval } = this.state;

    let processedOptions = [];
    processedOptions = loadingBinOptions
      ? loadingBinOptions.map(d => <Option value={d.value} key={d.value}>{`${d.label}`}</Option>)
      : [];

    return (
      <Card
        title={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <>
            <Text>{intl.formatMessage({ id: 'pick_list_kanban_board' })}</Text>
            <br />
            <small>
              {`${intl.formatMessage({ id: 'last_updated' })} ${moment(wipOutbOrdTimestamp).format(
                'YYYY-MM-DD HH:mm:ss'
              )}`}
            </small>
          </>
        }
      >
        <Row type="flex" justify="center" gutter={[16, 8]}>
          <Col span={15} />
          <Col span={6}>
            <Select
              mode="default"
              showSearch
              value={loadingBinId}
              // onDropdownVisibleChange={(open) => {
              //   if (open && processedOptions.length === 0) {
              //     fetchLoadingBinOptions('');
              //   }
              // }}
              onSearch={value => {
                fetchLoadingBinOptions(value);
              }}
              onSelect={value => {
                changeLoadingBin(value);
              }}
              notFoundContent={null}
              loading={wipWhseJobDocumentIsLoading}
              showArrow
              filterOption={false}
              style={{ width: '100%' }}
            >
              {processedOptions}
            </Select>
          </Col>
          <Col span={3}>
            <Select
              value={refreshInterval}
              onChange={this.handleSelectChange}
              style={{ width: 75 }}
            >
              <Option value={0}>{intl.formatMessage({ id: 'off' })}</Option>
              <Option value={30000}>30s</Option>
              <Option value={60000}>1min</Option>
              <Option value={120000}>2min</Option>
              <Option value={300000}>5min</Option>
            </Select>
          </Col>
        </Row>
        <Row type="flex" justify="center" gutter={[16, 8]}>
          <Col span={6}>
            <WipOutbOrdTable match={match} />
            <WipOutbOrdDialog match={match} />
          </Col>
          <Col span={6}>
            <WipPickListTable match={match} />
            <WipPickListDialog match={match} />
          </Col>
          <Col span={6}>
            <WipWhseJobTable match={match} />
            <WipWhseJobDialog match={match} />
          </Col>
          <Col span={6}>
            <CompletePickListTable match={match} />
            <CompletePickListDialog match={match} />
          </Col>
        </Row>
      </Card>
    );
  }
}

PickListDetailBoardScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  wipOutbOrdTimestamp: PropTypes.number,

  resetWipOutbOrdTimestamp: PropTypes.func,
  resetPickListTimestamp: PropTypes.func,
  resetWipWhseJobTimestamp: PropTypes.func,
  resetCompletePickListTimestamp: PropTypes.func,

  loadingBinId: PropTypes.number,
  loadingBinOptions: PropTypes.arrayOf(PropTypes.object),
  wipWhseJobDocumentIsLoading: PropTypes.bool,

  fetchLoadingBinOptions: PropTypes.func,
  changeLoadingBin: PropTypes.func
};

PickListDetailBoardScreen.defaultProps = {
  intl: {},
  match: {},
  wipOutbOrdTimestamp: 0,

  resetWipOutbOrdTimestamp() {},
  resetPickListTimestamp() {},
  resetWipWhseJobTimestamp() {},
  resetCompletePickListTimestamp() {},

  loadingBinId: 0,
  loadingBinOptions: [],
  wipWhseJobDocumentIsLoading: false,
  fetchLoadingBinOptions() {},

  changeLoadingBin() {}
};

const mapStateToProps = state => ({
  wipOutbOrdTimestamp: state.pickListDetailBoard.wipOutbOrdTimestamp,

  loadingBinId: state.pickListDetailBoard.loadingBinId,
  loadingBinOptions: state.pickListDetailBoard.loadingBinOptions,
  wipWhseJobDocumentIsLoading: state.pickListDetailBoard.wipWhseJobDocumentIsLoading
});

const mapDispatchToProps = dispatch => ({
  resetWipOutbOrdTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PickListDetailBoardActions.pickListDetailBoardResetWipOutbOrdTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  resetPickListTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PickListDetailBoardActions.pickListDetailBoardResetWipPickListTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  resetWipWhseJobTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PickListDetailBoardActions.pickListDetailBoardResetWipWhseJobTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  resetCompletePickListTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PickListDetailBoardActions.pickListDetailBoardResetCompletePickListTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  fetchLoadingBinOptions: search =>
    dispatch(PickListDetailBoardActions.pickListDetailBoardFetchLoadingBinOptions(search)),
  changeLoadingBin: loadingBinId =>
    dispatch(PickListDetailBoardActions.pickListDetailBoardChangeLoadingBin(loadingBinId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PickListDetailBoardScreen));
