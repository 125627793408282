import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  itemSync0101ShowSyncSetting: null,
  itemSync0101ShowSyncSettingLoading: ['boolean'],
  itemSync0101ShowSyncSettingSuccess: ['syncSetting'],
  itemSync0101UpdateSyncSetting: ['formikBag', 'syncSetting'],
  itemSync0101ShowBatchJobStatus: null,
  itemSync0101ShowBatchJobStatusSuccess: ['batchJobStatus'],
  itemSync0101SyncNow: ['formikBag'],
  itemSync0101SyncLoading: ['boolean']
});

export const ItemSync0101Types = Types;
export default Creators;
