import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SearchOutlined, FilterOutlined } from '@ant-design/icons';

import { Input, Button, DatePicker, Checkbox } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

const getColumnSortProps = (sorts, sortField) => {
  return {
    sorter: true,
    sortOrder: sortField in sorts && sorts[sortField]
  };
};

const getColumnMultiSortProps = (sorts, sortField, multiple = 1, compare = (a, b) => a - b) => {
  return {
    sorter: {
      compare,
      multiple
    },
    sortOrder: sortField in sorts && sorts[sortField]
  };
};

const getColumnSearchInputProps = (
  filters,
  columnTitle,
  filterField,
  handleSearch,
  handleReset,
  onFilter = null
) => {
  let searchInput;
  return {
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              searchInput = node;
            }}
            placeholder={columnTitle}
            value={selectedKeys ? selectedKeys[0] : ''}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={e => {
              e.stopPropagation();
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <FormattedMessage id="search" />
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            <FormattedMessage id="reset" />
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: onFilter || (() => true),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    filteredValue:
      filters && filterField in filters && filters[filterField] ? [filters[filterField]] : null
  };
};

const getColumnSearchDateProps = (
  filters,
  columnTitle,
  filterField,
  handleSearch,
  handleReset,
  onFilter = null
) => {
  let tmpFilteredValue = null;
  if (filters && filterField in filters && filters[filterField]) {
    tmpFilteredValue = filters[filterField].split(',').map(e => moment(e));
  }

  let searchDate;
  return {
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            ref={node => {
              searchDate = node;
            }}
            size="small"
            value={selectedKeys || null}
            onChange={e => setSelectedKeys(e || [])}
            onPressEnter={e => {
              e.stopPropagation();
              // confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <FormattedMessage id="search" />
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            <FormattedMessage id="reset" />
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: onFilter || (() => true),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchDate.focus());
      }
    },
    filteredValue: tmpFilteredValue
  };
};

const getColumnSearchCheckBoxGroupProps = (
  filters,
  columnTitle,
  filterField,
  handleSearch,
  handleReset,
  options,
  onFilter = null
) => {
  // let searchInput;
  let processedFilterValue = [];
  if (
    filters &&
    filterField in filters &&
    filters[filterField] &&
    filters[filterField].length > 0
  ) {
    if (Array.isArray(filters[filterField])) {
      processedFilterValue = filters[filterField];
    } else if (typeof filters[filterField] === 'string') {
      processedFilterValue = filters[filterField].split(',');
    }
  }
  return {
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <CheckboxGroup
            // ref={node => {
            //   searchInput = node;
            // }}
            placeholder={columnTitle}
            value={selectedKeys || []}
            onChange={e => setSelectedKeys(e || [])}
            // onPressEnter={e => {
            //   e.stopPropagation();
            //   confirm();
            // }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            options={options}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <FormattedMessage id="search" />
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            <FormattedMessage id="reset" />
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (
      <FilterOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: onFilter || (() => true),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.focus());
      }
    },
    filteredValue: processedFilterValue
  };
};

const processFilters = filters => {
  const processedFilters = {};
  Object.entries(filters).forEach(entry => {
    const key = entry[0];
    const value = entry[1];
    if (value && value[0]) {
      const filterVal = value.reduce((lastObj, data) => {
        const strData = moment.isMoment(data) ? data.format('YYYY-MM-DD') : data;
        if (lastObj.length === 0) {
          return strData;
        }
        return `${lastObj},${strData}`;
      }, '');
      processedFilters[key] = filterVal;
    }
  });

  return processedFilters;
};

const processSorts = sorter => {
  const processedSorts = {};
  if (sorter && typeof sorter[Symbol.iterator] === 'function') {
    sorter.forEach(entry => {
      processedSorts[entry.field] = entry.order;
    });
  } else if (sorter.order) {
    processedSorts[sorter.field] = sorter.order;
  }

  return processedSorts;
};

const getColumnSearchDocStatusProps = (filters, filterField) => {
  let tmpFilteredValue = null;
  if (filters && filterField in filters && filters[filterField]) {
    if (filters[filterField].length > 0) {
      tmpFilteredValue = filters[filterField].split(',');
    } else {
      // convert to array
      tmpFilteredValue = [filters[filterField]];
    }
  }

  return {
    key: 'doc_status',
    filters: [
      {
        text: 'VOID',
        value: 2
      },
      {
        text: 'DRAFT',
        value: 3
      },
      {
        text: 'WIP',
        value: 50
      },
      {
        text: 'COMPLETE',
        value: 100
      }
    ],
    filteredValue: tmpFilteredValue
  };
};

const getColumnSearchPhysicalCountStatusProps = (filters, filterField) => {
  let tmpFilteredValue = null;
  if (filters && filterField in filters && filters[filterField]) {
    if (filters[filterField].length > 0) {
      tmpFilteredValue = filters[filterField].split(',');
    } else {
      // convert to array
      tmpFilteredValue = [filters[filterField]];
    }
  }

  return {
    key: 'physical_count_status',
    filters: [
      {
        text: 'RECOUNT',
        value: 2
      },
      {
        text: 'MARK_RECOUNT',
        value: 50
      },
      {
        text: 'MARK_CONFIRMED',
        value: 51
      },
      {
        text: 'MARK_DROPPED',
        value: 52
      },
      {
        text: 'COUNTING',
        value: 100
      }
    ],
    filteredValue: tmpFilteredValue
  };
};

export default {
  getColumnSortProps,
  getColumnMultiSortProps,
  getColumnSearchInputProps,
  getColumnSearchDateProps,
  getColumnSearchDocStatusProps,
  getColumnSearchCheckBoxGroupProps,
  getColumnSearchPhysicalCountStatusProps,
  processFilters,
  processSorts
};
