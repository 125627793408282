import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspDelOrdIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  lspDelOrdIndexFetchLspDelOrdIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  lspDelOrdIndexFetchLspDelOrdIndexLoading: ['boolean'],
  lspDelOrdIndexFetchLspDelOrdIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  lspDelOrdIndexAddSelectedDocuments: ['selectedDocuments'],
  lspDelOrdIndexRemoveSelectedDocuments: ['selectedDocuments'],
  lspDelOrdIndexSetWorkspaceVisible: ['boolean'],
  lspDelOrdIndexGoToDocument: ['hdrId'],
  lspDelOrdIndexGoToAudit: ['hdrId'],

  lspDelOrdIndexWorkspaceLoading: ['boolean']
});

export const LspDelOrdIndexTypes = Types;
export default Creators;
