/**
 * The initial values for the redux state.
 */
export default {
  resId: 0,

  modelTimestamp: 0,
  model: {
    code: '',
    bin_status: 100,
    str_status: 'ACTIVE',
    desc_01: '',
    desc_02: '',
    level: 1
  },

  modelIsLoading: false,

  storageRowOptions: [],
  storageRowIsLoading: false,
  storageBayOptions: [],
  storageBayIsLoading: false,
  storageTypeOptions: [],
  storageTypeIsLoading: false,
  locationOptions: [],
  locationIsLoading: false
};
