/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { ProdRcptDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const prodRcptDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const prodRcptDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  salesmanOptions: [],
  deliveryPointOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const prodRcptDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const prodRcptDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const prodRcptDetailUpdateDocumentSuccess = (
  state,
  { documentHeader, documentDetails }
) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const prodRcptDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const prodRcptDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const prodRcptDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  dtlItemOptions: [],
  dtlUomOptions: []
});

export const prodRcptDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const prodRcptDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

// force refresh the formik form
export const prodRcptDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const prodRcptDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const prodRcptDetailFetchDtlItemOptionLoading = (state, { boolean }) => ({
  ...state,
  dtlItemIsLoading: boolean
});

export const prodRcptDetailFetchDtlItemOptionSuccess = (state, { options }) => ({
  ...state,
  dtlItemOptions: options
});

export const prodRcptDetailFetchDtlUomOptionLoading = (state, { boolean }) => ({
  ...state,
  dtlUomIsLoading: boolean
});

export const prodRcptDetailFetchDtlUomOptionSuccess = (state, { options }) => ({
  ...state,
  dtlUomOptions: options
});

export const prodRcptDetailFetchDtlLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  dtlLocationIsLoading: boolean
});

export const prodRcptDetailFetchDtlLocationOptionSuccess = (state, { options }) => ({
  ...state,
  dtlLocationOptions: options
});

export const prodRcptDetailFetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const prodRcptDetailFetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_RESET_TIMESTAMP]: prodRcptDetailResetTimestamp,
  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_SET_HDR_ID]: prodRcptDetailSetHdrId,

  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_SHOW_HEADER_SUCCESS]: prodRcptDetailShowHeaderSuccess,

  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_UPDATE_DOCUMENT_SUCCESS]: prodRcptDetailUpdateDocumentSuccess,

  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_SHOW_DOCUMENT_LOADING]: prodRcptDetailShowDocumentLoading,

  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_SHOW_DETAILS_SUCCESS]: prodRcptDetailShowDetailsSuccess,

  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_SET_DETAIL_VISIBLE]: prodRcptDetailSetDetailVisible,
  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_SET_DOCUMENT_DETAIL]: prodRcptDetailSetDocumentDetail,

  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_UPDATE_DETAILS]: prodRcptDetailUpdateDetails,
  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_CREATE_DETAIL]: prodRcptDetailCreateDetail,

  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: prodRcptDetailFetchCurrencyOptionLoading,
  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: prodRcptDetailFetchCurrencyOptionSuccess,

  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_FETCH_DTL_ITEM_OPTION_LOADING]: prodRcptDetailFetchDtlItemOptionLoading,
  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_FETCH_DTL_ITEM_OPTION_SUCCESS]: prodRcptDetailFetchDtlItemOptionSuccess,

  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_FETCH_DTL_UOM_OPTION_LOADING]: prodRcptDetailFetchDtlUomOptionLoading,
  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_FETCH_DTL_UOM_OPTION_SUCCESS]: prodRcptDetailFetchDtlUomOptionSuccess,

  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_FETCH_DTL_LOCATION_OPTION_LOADING]: prodRcptDetailFetchDtlLocationOptionLoading,
  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_FETCH_DTL_LOCATION_OPTION_SUCCESS]: prodRcptDetailFetchDtlLocationOptionSuccess,

  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_FETCH_PROJECT_OPTION_LOADING]: prodRcptDetailFetchProjectOptionLoading,
  [ProdRcptDetailTypes.PROD_RCPT_DETAIL_FETCH_PROJECT_OPTION_SUCCESS]: prodRcptDetailFetchProjectOptionSuccess
});
