/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  currentPage: 0,
  criteria: {
    division_ids_select2: [],
    delivery_point_ids_select2: [],
    item_ids_select2: [],
    item_group_01_ids_select2: [],
    item_group_02_ids_select2: [],
    item_group_03_ids_select2: [],
    columns: {
      source: [],
      target: []
    }
  },
  sorts: {
    item_code: 'ascend'
  },
  filters: {},
  reportData: [],
  reportIsLoading: false,

  itemOptions: [],
  itemIsLoading: false,

  itemGroup01Options: [],
  itemGroup01IsLoading: false,

  itemGroup02Options: [],
  itemGroup02IsLoading: false,

  itemGroup03Options: [],
  itemGroup03IsLoading: false,

  divisionOptions: [],
  divisionIsLoading: false,

  deliveryPointOptions: [],
  deliveryPointIsLoading: false
};
