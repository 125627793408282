/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CountAdjSync02Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const countAdjSync02ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const countAdjSync02ExportLoading = (state, { boolean }) => ({
  ...state,
  exportIsLoading: boolean
});

export const countAdjSync02ExportSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const countAdjSync02FetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const countAdjSync02FetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const countAdjSync02FetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const countAdjSync02FetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const countAdjSync02FetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const countAdjSync02FetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const countAdjSync02FetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const countAdjSync02FetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CountAdjSync02Types.COUNT_ADJ_SYNC02_SHOW_BATCH_JOB_STATUS_SUCCESS]: countAdjSync02ShowBatchJobStatusSuccess,

  [CountAdjSync02Types.COUNT_ADJ_SYNC02_EXPORT_LOADING]: countAdjSync02ExportLoading,
  [CountAdjSync02Types.COUNT_ADJ_SYNC02_EXPORT_SUCCESS]: countAdjSync02ExportSuccess,

  [CountAdjSync02Types.COUNT_ADJ_SYNC02_FETCH_COMPANY_OPTION_LOADING]: countAdjSync02FetchCompanyOptionLoading,
  [CountAdjSync02Types.COUNT_ADJ_SYNC02_FETCH_COMPANY_OPTION_SUCCESS]: countAdjSync02FetchCompanyOptionSuccess,

  [CountAdjSync02Types.COUNT_ADJ_SYNC02_FETCH_LOCATION_OPTION_LOADING]: countAdjSync02FetchLocationOptionLoading,
  [CountAdjSync02Types.COUNT_ADJ_SYNC02_FETCH_LOCATION_OPTION_SUCCESS]: countAdjSync02FetchLocationOptionSuccess,

  [CountAdjSync02Types.COUNT_ADJ_SYNC02_FETCH_ITEM_GROUP01_OPTION_LOADING]: countAdjSync02FetchItemGroup01OptionLoading,
  [CountAdjSync02Types.COUNT_ADJ_SYNC02_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: countAdjSync02FetchItemGroup01OptionSuccess,

  [CountAdjSync02Types.COUNT_ADJ_SYNC02_FETCH_ITEM_OPTION_LOADING]: countAdjSync02FetchItemOptionLoading,
  [CountAdjSync02Types.COUNT_ADJ_SYNC02_FETCH_ITEM_OPTION_SUCCESS]: countAdjSync02FetchItemOptionSuccess
});
