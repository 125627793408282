/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PickingCriteriaProcessTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const pickingCriteriaProcessFetchPickingCriteriaProcessLoading = (state, { boolean }) => ({
  ...state,
  processIsLoading: boolean
});

export const pickingCriteriaProcessFetchPickingCriteriaProcessSuccess = (state, { process }) => ({
  ...state,
  process
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PickingCriteriaProcessTypes.PICKING_CRITERIA_PROCESS_FETCH_PICKING_CRITERIA_PROCESS_LOADING]: pickingCriteriaProcessFetchPickingCriteriaProcessLoading,
  [PickingCriteriaProcessTypes.PICKING_CRITERIA_PROCESS_FETCH_PICKING_CRITERIA_PROCESS_SUCCESS]: pickingCriteriaProcessFetchPickingCriteriaProcessSuccess
});
