import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { EditOutlined, LaptopOutlined, ReloadOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button, Badge, Upload, notification } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import StorageBayList01Actions from '../../Stores/StorageBayList01/Actions';

class StorageBayList01Table extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      fileList: []
    };

    this.useOnUpload = this.useOnUpload.bind(this);
    this.useOnDownload = this.useOnDownload.bind(this);

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchStorageBayList01,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchStorageBayList01(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const { intl, sorts, filters, goToDocument } = this.props;

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'row' }),
        // sort field
        dataIndex: 'storage_row_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'storage_row_code'),
        // filter field
        key: 'storage_row_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'row' }),
          'storage_row_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.storage_row_code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        ...AntDesignTable.getColumnSortProps(sorts, 'code'),
        // filter field
        key: 'code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'code' }),
          'code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.code}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_01'),
        // filter field
        key: 'desc_01',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'description' }),
          'desc_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'sequence' }),
        // sort field
        dataIndex: 'bay_sequence',
        ...AntDesignTable.getColumnSortProps(sorts, 'bay_sequence'),
        // filter field
        key: 'bay_sequence',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'sequence' }),
          'bay_sequence',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.bay_sequence}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'max_level' }),
        // sort field
        dataIndex: 'max_level',
        ...AntDesignTable.getColumnSortProps(sorts, 'max_level'),
        // filter field
        key: 'max_level',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'max_level' }),
          'max_level',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.max_level}</>
      },
      {
        width: 50,
        fixed: 'right',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => goToDocument(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  useOnUpload() {
    const { uploadExcel } = this.props;
    const { fileList } = this.state;

    if (fileList.length > 0) {
      uploadExcel(fileList[0]);
    }
  }

  useOnDownload() {
    const { downloadExcel } = this.props;

    downloadExcel();
  }

  render() {
    const {
      intl,
      curSiteFlowId,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments,
      uploadIsLoading
    } = this.props;
    const { fileList } = this.state;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              disabled: true
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
                <Col span={8}>
                  <Upload
                    disabled={false}
                    onRemove={() => {
                      this.setState({
                        fileList: []
                      });
                    }}
                    beforeUpload={file => {
                      const validFileName = 'STORAGE_BAY_LIST_01';
                      if (file && file.name.toLowerCase().includes(validFileName.toLowerCase())) {
                        this.setState({
                          fileList: [file]
                        });
                      } else {
                        notification.error({
                          message: `${intl.formatMessage({
                            id: 'invalid_file_name_must_contain'
                          })} ${validFileName}`,
                          duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
                        });
                      }

                      return false;
                    }}
                    fileList={fileList}
                  >
                    <Button type="primary" disabled={false} loading={uploadIsLoading}>
                      {intl.formatMessage({ id: 'browse' })}
                    </Button>
                  </Upload>
                </Col>
                <Col span={6}>
                  <Button
                    type="primary"
                    onClick={this.useOnUpload}
                    disabled={fileList.length === 0}
                    loading={uploadIsLoading}
                  >
                    {intl.formatMessage({ id: 'upload' })}
                  </Button>
                  <Button
                    type="primary"
                    disabled={uploadIsLoading || curSiteFlowId === 0}
                    onClick={this.useOnDownload}
                    loading={uploadIsLoading}
                  >
                    {intl.formatMessage({ id: 'download' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />

        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px',
            zIndex: 9999
          }}
        >
          <Badge count={selectedDocuments.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon={<LaptopOutlined />}
              onClick={this.useShowWorkspace}
              loading={fetchIsLoading}
            />
          </Badge>
        </div>
      </>
    );
  }
}

StorageBayList01Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchStorageBayList01: PropTypes.func,

  uploadIsLoading: PropTypes.bool,
  uploadExcel: PropTypes.func,
  downloadExcel: PropTypes.func
};

StorageBayList01Table.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchStorageBayList01() {},

  uploadIsLoading: false,
  uploadExcel() {},
  downloadExcel() {}
};

const mapStateToProps = state => ({
  timestamp: state.storageBayList01.timestamp,
  workspaceIsVisible: state.storageBayList01.workspaceIsVisible,

  documents: state.storageBayList01.documents,
  selectedDocuments: state.storageBayList01.selectedDocuments,

  sorts: state.storageBayList01.sorts,
  filters: state.storageBayList01.filters,

  currentPage: state.storageBayList01.currentPage,
  pageSize: state.storageBayList01.pageSize,
  total: state.storageBayList01.total,
  fetchIsLoading: state.storageBayList01.fetchIsLoading,

  uploadIsLoading: state.storageBayList01.uploadIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      StorageBayList01Actions.storageBayList01ResetTimestamp(currentPage, sorts, filters, pageSize)
    ),
  goToDocument: hdrId => dispatch(StorageBayList01Actions.storageBayList01GoToDocument(hdrId)),
  setWorkspaceVisible: boolean =>
    dispatch(StorageBayList01Actions.storageBayList01SetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(StorageBayList01Actions.storageBayList01AddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(StorageBayList01Actions.storageBayList01RemoveSelectedDocuments(selectedDocuments)),

  fetchStorageBayList01: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      StorageBayList01Actions.storageBayList01FetchStorageBayList01(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),

  uploadExcel: file => dispatch(StorageBayList01Actions.storageBayList01UploadExcel(file)),
  downloadExcel: () => dispatch(StorageBayList01Actions.storageBayList01DownloadExcel())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(StorageBayList01Table));
