import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  outbOrdDetailResetTimestamp: null,
  outbOrdDetailSetHdrId: ['hdrId', 'itemId'],

  outbOrdDetailInitHeader: null,
  outbOrdDetailShowHeader: ['hdrId'],
  outbOrdDetailShowHeaderSuccess: ['documentHeader'],

  outbOrdDetailUpdateHeader: ['formikBag', 'documentHeader'],
  outbOrdDetailCreateHeader: ['formikBag', 'documentHeader'],
  outbOrdDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  outbOrdDetailShowDocumentLoading: ['boolean'],
  outbOrdDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  outbOrdDetailShowDetails: ['hdrId'],
  outbOrdDetailShowDetailsSuccess: ['documentDetails'],

  outbOrdDetailSetDetailVisible: ['boolean'],
  outbOrdDetailSetDocumentDetail: ['documentDetail'],

  outbOrdDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  outbOrdDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  outbOrdDetailDeleteDetail: ['hdrId', 'documentDetail'],

  outbOrdDetailFetchSalesmanOptions: ['search'],
  outbOrdDetailFetchSalesmanOptionLoading: ['boolean'],
  outbOrdDetailFetchSalesmanOptionSuccess: ['options'],

  outbOrdDetailFetchDeliveryPointOptions: ['search'],
  outbOrdDetailFetchDeliveryPointOptionLoading: ['boolean'],
  outbOrdDetailFetchDeliveryPointOptionSuccess: ['options'],
  outbOrdDetailChangeDeliveryPoint: ['formikBag', 'deliveryPointId'],

  outbOrdDetailFetchCreditTermOptions: ['search'],
  outbOrdDetailFetchCreditTermOptionLoading: ['boolean'],
  outbOrdDetailFetchCreditTermOptionSuccess: ['options'],

  outbOrdDetailFetchCurrencyOptions: ['search'],
  outbOrdDetailFetchCurrencyOptionLoading: ['boolean'],
  outbOrdDetailFetchCurrencyOptionSuccess: ['options'],
  outbOrdDetailChangeCurrency: ['formikBag', 'currencyId'],

  outbOrdDetailFetchLocationOptions: ['search'],
  outbOrdDetailFetchLocationOptionLoading: ['boolean'],
  outbOrdDetailFetchLocationOptionSuccess: ['options'],

  outbOrdDetailFetchItemOptions: ['search'],
  outbOrdDetailFetchItemOptionLoading: ['boolean'],
  outbOrdDetailFetchItemOptionSuccess: ['options'],
  outbOrdDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  outbOrdDetailFetchUomOptions: ['itemId', 'search'],
  outbOrdDetailFetchUomOptionLoading: ['boolean'],
  outbOrdDetailFetchUomOptionSuccess: ['options'],
  outbOrdDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  outbOrdDetailFetchProjectOptions: ['search'],
  outbOrdDetailFetchProjectOptionLoading: ['boolean'],
  outbOrdDetailFetchProjectOptionSuccess: ['options'],

  outbOrdDetailSetExpandedRows: ['expandedRows']
});

export const OutbOrdDetailTypes = Types;
export default Creators;
