/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PickFaceStrategyList01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const pickFaceStrategyList01ResetTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const pickFaceStrategyList01FetchPickFaceStrategyList01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const pickFaceStrategyList01FetchPickFaceStrategyList01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const pickFaceStrategyList01AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const pickFaceStrategyList01RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const pickFaceStrategyList01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PickFaceStrategyList01Types.PICK_FACE_STRATEGY_LIST01_RESET_TIMESTAMP]: pickFaceStrategyList01ResetTimestamp,
  [PickFaceStrategyList01Types.PICK_FACE_STRATEGY_LIST01_FETCH_PICK_FACE_STRATEGY_LIST01_LOADING]: pickFaceStrategyList01FetchPickFaceStrategyList01Loading,
  [PickFaceStrategyList01Types.PICK_FACE_STRATEGY_LIST01_FETCH_PICK_FACE_STRATEGY_LIST01_SUCCESS]: pickFaceStrategyList01FetchPickFaceStrategyList01Success,
  [PickFaceStrategyList01Types.PICK_FACE_STRATEGY_LIST01_ADD_SELECTED_DOCUMENTS]: pickFaceStrategyList01AddSelectedDocuments,
  [PickFaceStrategyList01Types.PICK_FACE_STRATEGY_LIST01_REMOVE_SELECTED_DOCUMENTS]: pickFaceStrategyList01RemoveSelectedDocuments,
  [PickFaceStrategyList01Types.PICK_FACE_STRATEGY_LIST01_SET_WORKSPACE_VISIBLE]: pickFaceStrategyList01SetWorkspaceVisible
});
