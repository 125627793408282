import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  purRtnDetailResetTimestamp: null,
  purRtnDetailSetHdrId: ['hdrId'],

  purRtnDetailInitHeader: null,
  purRtnDetailShowHeader: ['hdrId'],
  purRtnDetailShowHeaderSuccess: ['documentHeader'],

  purRtnDetailUpdateHeader: ['formikBag', 'documentHeader'],
  purRtnDetailCreateHeader: ['formikBag', 'documentHeader'],
  purRtnDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  purRtnDetailShowDocumentLoading: ['boolean'],
  purRtnDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  purRtnDetailShowDetails: ['hdrId'],
  purRtnDetailShowDetailsSuccess: ['documentDetails'],

  purRtnDetailSetDetailVisible: ['boolean'],
  purRtnDetailSetDocumentDetail: ['documentDetail'],

  purRtnDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  purRtnDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  purRtnDetailDeleteDetail: ['hdrId', 'documentDetail'],

  purRtnDetailFetchPurchaserOptions: ['search'],
  purRtnDetailFetchPurchaserOptionLoading: ['boolean'],
  purRtnDetailFetchPurchaserOptionSuccess: ['options'],

  purRtnDetailFetchBizPartnerOptions: ['search'],
  purRtnDetailFetchBizPartnerOptionLoading: ['boolean'],
  purRtnDetailFetchBizPartnerOptionSuccess: ['options'],
  purRtnDetailChangeBizPartner: ['formikBag', 'bizPartnerId'],

  purRtnDetailFetchCreditTermOptions: ['search'],
  purRtnDetailFetchCreditTermOptionLoading: ['boolean'],
  purRtnDetailFetchCreditTermOptionSuccess: ['options'],

  purRtnDetailFetchCurrencyOptions: ['search'],
  purRtnDetailFetchCurrencyOptionLoading: ['boolean'],
  purRtnDetailFetchCurrencyOptionSuccess: ['options'],
  purRtnDetailChangeCurrency: ['formikBag', 'currencyId'],

  purRtnDetailFetchLocationOptions: ['search'],
  purRtnDetailFetchLocationOptionLoading: ['boolean'],
  purRtnDetailFetchLocationOptionSuccess: ['options'],

  purRtnDetailFetchItemOptions: ['search'],
  purRtnDetailFetchItemOptionLoading: ['boolean'],
  purRtnDetailFetchItemOptionSuccess: ['options'],
  purRtnDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  purRtnDetailFetchItemBatchOptions: ['itemId', 'search'],
  purRtnDetailFetchItemBatchOptionLoading: ['boolean'],
  purRtnDetailFetchItemBatchOptionSuccess: ['options'],
  purRtnDetailChangeItemBatch: ['formikBag', 'hdrId', 'itemBatchId'],

  purRtnDetailFetchUomOptions: ['itemId', 'search'],
  purRtnDetailFetchUomOptionLoading: ['boolean'],
  purRtnDetailFetchUomOptionSuccess: ['options'],
  purRtnDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  purRtnDetailFetchProjectOptions: ['search'],
  purRtnDetailFetchProjectOptionLoading: ['boolean'],
  purRtnDetailFetchProjectOptionSuccess: ['options']
});

export const PurRtnDetailTypes = Types;
export default Creators;
