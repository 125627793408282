import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { Card, Typography } from 'antd';
import CriteriaForm from './CriteriaForm';

import WhsePerformanceChartActions from '../../Stores/WhsePerformanceChart/Actions';
import WhsePerformanceChart from './WhsePerformanceChart';

const { Text } = Typography;

class WhsePerformanceChatScreen extends React.PureComponent {
  componentDidMount() {
    const { curSiteFlowId, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchWhsePerformanceChart, curSiteFlowId, resetTimestamp, criteria } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchWhsePerformanceChart(criteria);
      }
    }
  }

  render() {
    const { intl, timestamp } = this.props;

    return (
      <Card
        title={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <>
            <Text>{intl.formatMessage({ id: 'warehouse_performance' })}</Text>
            <br />
            <small>
              {`${intl.formatMessage({ id: 'last_updated' })} ${moment(timestamp).format(
                'YYYY-MM-DD HH:mm:ss'
              )}`}
            </small>
          </>
        }
      >
        <CriteriaForm />
        <WhsePerformanceChart />
      </Card>
    );
  }
}

WhsePerformanceChatScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  timestamp: PropTypes.number,

  // eslint-disable-next-line react/forbid-prop-types
  criteria: PropTypes.object,

  resetTimestamp: PropTypes.func,
  fetchWhsePerformanceChart: PropTypes.func,

  curSiteFlowId: PropTypes.number
};

WhsePerformanceChatScreen.defaultProps = {
  intl: {},
  timestamp: 0,

  criteria: {},

  resetTimestamp() {},
  fetchWhsePerformanceChart() {},

  curSiteFlowId: 0
};

const mapStateToProps = state => ({
  timestamp: state.whsePerformanceChart.whsePerformanceChartTimestamp,
  criteria: state.whsePerformanceChart.criteria,
  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: () => dispatch(WhsePerformanceChartActions.whsePerformanceChartResetTimestamp()),
  fetchWhsePerformanceChart: filters =>
    dispatch(WhsePerformanceChartActions.whsePerformanceChartFetch(filters))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WhsePerformanceChatScreen));
