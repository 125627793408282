import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { ArrowLeftOutlined, UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography } from 'antd';
import treeChanges from 'tree-changes';
import {
  FormikInput,
  FormikText,
  FormikDatePicker,
  FormikButton,
  FormikSelect
} from '../../Components/Formik';
import WhseJob06DetailActions from '../../Stores/WhseJob06Detail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class HeaderForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchTransport = this.handleSearchTransport.bind(this);
    this.handleSearchDriver01 = this.handleSearchDriver01.bind(this);
    this.handleSearchDeliveryMan01 = this.handleSearchDeliveryMan01.bind(this);
    this.handleSearchDeliveryMan02 = this.handleSearchDeliveryMan02.bind(this);

    this.handleWorkerSearch = this.handleWorkerSearch.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { hdrId, showHeader } = this.props;

    const { changed } = treeChanges(prevProps, this.props);
    if (changed('timestamp')) {
      if (hdrId > 0) {
        showHeader(hdrId);
      }
    }
  }

  componentWillUnmount() {}

  handleSearchTransport(form, value) {
    const { fetchTransportOptions } = this.props;

    fetchTransportOptions(value);
  }

  handleSearchDriver01(form, value) {
    const { fetchDriver01Options } = this.props;

    fetchDriver01Options(value);
  }

  handleSearchDeliveryMan01(form, value) {
    const { fetchDeliveryMan01Options } = this.props;

    fetchDeliveryMan01Options(value);
  }

  handleSearchDeliveryMan02(form, value) {
    const { fetchDeliveryMan02Options } = this.props;

    fetchDeliveryMan02Options(value);
  }

  handleWorkerSearch(form, value) {
    const { fetchWorkerOptions } = this.props;

    fetchWorkerOptions(value);
  }

  render() {
    const {
      intl,
      hdrId,
      match,
      documentHeader,
      transitionToStatus,
      createHeader,
      updateHeader,
      historyGoBack,
      documentIsLoading,
      transportIsLoading,
      transportOptions,
      driver01IsLoading,
      driver01Options,
      deliveryMan01IsLoading,
      deliveryMan01Options,
      deliveryMan02IsLoading,
      deliveryMan02Options,
      workerIsLoading,
      workerOptions
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;

    return (
      <Formik
        enableReinitialize
        initialValues={documentHeader}
        onSubmit={(values, formikBag) => {
          if (values.submit_action === 'update') {
            if (match.params.action === 'create') {
              createHeader(formikBag, values);
            } else {
              updateHeader(formikBag, values);
            }
          } else if (values.submit_action === 'void') {
            transitionToStatus(formikBag, hdrId, 'VOID');
          } else if (values.submit_action === 'draft') {
            transitionToStatus(formikBag, hdrId, 'DRAFT');
          } else if (values.submit_action === 'wip') {
            transitionToStatus(formikBag, hdrId, 'WIP');
          } else if (values.submit_action === 'complete') {
            transitionToStatus(formikBag, hdrId, 'COMPLETE');
          } else if (values.submit_action === 'release') {
            transitionToStatus(formikBag, hdrId, 'RELEASE');
          }
        }}
        validationSchema={Yup.object().shape({
          doc_date: Yup.date().min(
            new Date(1969, 12, 1),
            intl.formatMessage({ id: 'doc_date_is_required' })
          ),
          transport_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'transport_is_required' }))
          }),
          driver_01_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'driver_is_required' }))
          }),
          delivery_man_01_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'delivery_man_is_required' }))
          }),
          worker_01_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'worker_01_is_required' }))
          })
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'doc_code' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                {match.params.action === 'create' ? (
                  <Field
                    name="doc_no_id"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    notFoundContent={null}
                    options={values.doc_no_id_options}
                    disabled={disabled || documentIsLoading}
                  />
                ) : (
                  <Field name="doc_code" component={FormikText} />
                )}
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'doc_status' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="submit_action"
                  value="release"
                  component={FormikButton}
                  style={values.doc_status === 3 ? { backgroundColor: 'white' } : {}}
                  disabled={values.doc_status !== 50 || match.params.action === 'create'}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'release'
                  })}
                />
                <Field
                  name="submit_action"
                  value="void"
                  component={FormikButton}
                  style={values.doc_status === 2 ? { backgroundColor: 'red', color: 'white' } : {}}
                  disabled={
                    values.doc_status === 2 ||
                    match.params.action === 'create' ||
                    values.doc_status > 3
                  }
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'void'
                  })}
                />
                <Field
                  name="submit_action"
                  value="draft"
                  component={FormikButton}
                  style={values.doc_status === 3 ? { backgroundColor: 'red', color: 'white' } : {}}
                  disabled={values.doc_status === 3 || match.params.action === 'create'}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'draft'
                  })}
                />
                <Field
                  name="submit_action"
                  value="wip"
                  component={FormikButton}
                  style={values.doc_status === 50 ? { backgroundColor: 'red', color: 'white' } : {}}
                  disabled={values.doc_status === 50 || match.params.action === 'create'}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'WIP'
                  })}
                />
                <Field
                  name="submit_action"
                  value="complete"
                  component={FormikButton}
                  style={
                    values.doc_status === 100 ? { backgroundColor: 'green', color: 'white' } : {}
                  }
                  disabled={values.doc_status === 100 || match.params.action === 'create'}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'complete'
                  })}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'doc_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="doc_date"
                  component={FormikDatePicker}
                  disabled={disabled || documentIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'ref_code' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={10}>
                <Field
                  name="ref_code_01"
                  component={FormikInput}
                  disabled={disabled || documentIsLoading}
                />
              </Col>
              <Col span={10}>
                <Field
                  name="ref_code_02"
                  component={FormikInput}
                  disabled={disabled || documentIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'worker_01' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="worker_01_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleWorkerSearch}
                  notFoundContent={null}
                  loading={workerIsLoading}
                  options={workerOptions}
                  disabled={disabled || documentIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'transport' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="transport_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchTransport}
                  notFoundContent={null}
                  loading={transportIsLoading}
                  options={transportOptions}
                  disabled={disabled || documentIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'driver' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="driver_01_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchDriver01}
                  notFoundContent={null}
                  loading={driver01IsLoading}
                  options={driver01Options}
                  disabled={disabled || documentIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'delivery_man_01' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="delivery_man_01_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchDeliveryMan01}
                  notFoundContent={null}
                  loading={deliveryMan01IsLoading}
                  options={deliveryMan01Options}
                  disabled={disabled || documentIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'delivery_man_02' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="delivery_man_02_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchDeliveryMan02}
                  notFoundContent={null}
                  loading={deliveryMan02IsLoading}
                  options={deliveryMan02Options}
                  disabled={disabled || documentIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'desc_01' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="desc_01"
                  component={FormikInput}
                  disabled={disabled || documentIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'desc_02' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="desc_02"
                  component={FormikInput}
                  disabled={disabled || documentIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="space-between" gutter={[0, 16]}>
              <Col span={8}>
                <Button
                  type="primary"
                  // disabled={!isValid}
                  loading={isSubmitting}
                  onClick={historyGoBack}
                  icon={<ArrowLeftOutlined />}
                >
                  {intl.formatMessage({
                    id: 'back'
                  })}
                </Button>
              </Col>
              <Col span={5}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  name="submit_action"
                  type="primary"
                  value="update"
                  component={FormikButton}
                  disabled={values.doc_status >= 50}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: match.params.action === 'create' ? 'create' : 'update'
                  })}
                  icon={<SaveOutlined />}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

HeaderForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,

  showHeader: PropTypes.func,
  transitionToStatus: PropTypes.func,
  createHeader: PropTypes.func,
  updateHeader: PropTypes.func,
  historyGoBack: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentIsLoading: PropTypes.bool,

  fetchTransportOptions: PropTypes.func,
  transportOptions: PropTypes.arrayOf(PropTypes.object),
  transportIsLoading: PropTypes.bool,

  fetchDriver01Options: PropTypes.func,
  driver01Options: PropTypes.arrayOf(PropTypes.object),
  driver01IsLoading: PropTypes.bool,

  fetchDeliveryMan01Options: PropTypes.func,
  deliveryMan01Options: PropTypes.arrayOf(PropTypes.object),
  deliveryMan01IsLoading: PropTypes.bool,

  fetchDeliveryMan02Options: PropTypes.func,
  deliveryMan02Options: PropTypes.arrayOf(PropTypes.object),
  deliveryMan02IsLoading: PropTypes.bool,

  fetchWorkerOptions: PropTypes.func,
  workerIsLoading: PropTypes.bool,
  workerOptions: PropTypes.arrayOf(PropTypes.object)
};

HeaderForm.defaultProps = {
  intl: {},
  match: {},

  showHeader() {},
  transitionToStatus() {},
  createHeader() {},
  updateHeader() {},
  historyGoBack() {},
  hdrId: 0,
  documentHeader: { doc_status: 3 },
  documentIsLoading: false,

  fetchTransportOptions() {},
  transportOptions: [],
  transportIsLoading: false,

  fetchDriver01Options() {},
  driver01Options: [],
  driver01IsLoading: false,

  fetchDeliveryMan01Options() {},
  deliveryMan01Options: [],
  deliveryMan01IsLoading: false,

  fetchDeliveryMan02Options() {},
  deliveryMan02Options: [],
  deliveryMan02IsLoading: false,

  fetchWorkerOptions() {},
  workerIsLoading: false,
  workerOptions: []
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.whseJob06Detail.timestamp,
  hdrId: state.whseJob06Detail.hdrId,
  documentHeader: state.whseJob06Detail.documentHeader,
  documentIsLoading: state.whseJob06Detail.documentIsLoading,

  transportOptions: state.whseJob06Detail.transportOptions,
  transportIsLoading: state.whseJob06Detail.transportIsLoading,
  driver01Options: state.whseJob06Detail.driver01Options,
  driver01IsLoading: state.whseJob06Detail.driver01IsLoading,
  deliveryMan01Options: state.whseJob06Detail.deliveryMan01Options,
  deliveryMan01IsLoading: state.whseJob06Detail.deliveryMan01IsLoading,
  deliveryMan02Options: state.whseJob06Detail.deliveryMan02Options,
  deliveryMan02IsLoading: state.whseJob06Detail.deliveryMan02IsLoading,

  workerIsLoading: state.whseJob06Detail.workerIsLoading,
  workerOptions: state.whseJob06Detail.workerOptions
});

const mapDispatchToProps = dispatch => ({
  showHeader: hdrId => dispatch(WhseJob06DetailActions.whseJob06DetailShowHeader(hdrId)),
  transitionToStatus: (formikBag, hdrId, docStatus) =>
    dispatch(WhseJob06DetailActions.whseJob06DetailTransitionToStatus(formikBag, hdrId, docStatus)),
  updateHeader: (formikBag, documentHeader) =>
    dispatch(WhseJob06DetailActions.whseJob06DetailUpdateHeader(formikBag, documentHeader)),
  createHeader: (formikBag, documentHeader) =>
    dispatch(WhseJob06DetailActions.whseJob06DetailCreateHeader(formikBag, documentHeader)),

  fetchTransportOptions: search =>
    dispatch(WhseJob06DetailActions.whseJob06DetailFetchTransportOptions(search)),
  fetchDriver01Options: search =>
    dispatch(WhseJob06DetailActions.whseJob06DetailFetchDriver01Options(search)),
  fetchDeliveryMan01Options: search =>
    dispatch(WhseJob06DetailActions.whseJob06DetailFetchDeliveryMan01Options(search)),
  fetchDeliveryMan02Options: search =>
    dispatch(WhseJob06DetailActions.whseJob06DetailFetchDeliveryMan02Options(search)),

  fetchWorkerOptions: search =>
    dispatch(WhseJob06DetailActions.whseJob06DetailFetchWorkerOptions(search)),

  historyGoBack: () => dispatch(AppActions.appHistoryGoBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HeaderForm));
