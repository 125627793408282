import { put, select, call, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';

import WhsePerformanceChartActions, {
  WhsePerformanceChartTypes
} from '../Stores/WhsePerformanceChart/Actions';

const getAppStore = state => state.app;

export function* whsePerformanceChartFetch({ criteria }) {
  try {
    yield put(WhsePerformanceChartActions.whsePerformanceChartFetchIsLoading(true));

    const processedFilters = [];
    if ('end_date' in criteria) {
      const value = criteria.end_date;
      if (value !== null && value.length > 0) {
        processedFilters.push(`end_date:${value}`);
      }
    }

    if ('time_period' in criteria) {
      const value = criteria.time_period;
      if (value !== null && value.length > 0) {
        processedFilters.push(`time_period:${value}`);
      }
    }

    if ('process' in criteria) {
      const value = criteria.process;
      if (value !== null && value.length > 0) {
        processedFilters.push(`process:${value}`);
      }
    }

    const app = yield select(getAppStore);
    const postData = {
      criteria: processedFilters
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseReport/whseJobAnalysisProcess/${app.curSiteFlowId}`,
      app.token,
      postData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(WhsePerformanceChartActions.whsePerformanceChartFetchSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhsePerformanceChartActions.whsePerformanceChartFetchIsLoading(false));
  }
}

export const saga = [
  takeLatest(WhsePerformanceChartTypes.WHSE_PERFORMANCE_CHART_FETCH, whsePerformanceChartFetch)
];
