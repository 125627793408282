import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Card, Modal, Breadcrumb } from 'antd';
import HeaderForm from './HeaderForm';
import DetailTable from './DetailTable';
import DetailForm from './DetailForm';
import TrfOrdDetailActions from '../../Stores/TrfOrdDetail/Actions';

class TrfOrdDetailScreen extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    const { match, setHdrId } = this.props;

    if (match.params.action === 'create') {
      setHdrId(0);
    } else if (match.params.action === 'update') {
      const hdrId = parseInt(match.params.id, 10);
      let itemId = 0;
      if (match.params.item_id) {
        itemId = parseInt(match.params.item_id, 10);
      }
      setHdrId(hdrId, itemId);
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setDetailVisible, documentIsLoading } = this.props;

    if (documentIsLoading === false) {
      setDetailVisible(false);
    }
  }

  render() {
    const { intl, match, detailIsVisible, documentHeader, appPath } = this.props;

    return (
      <Card title={intl.formatMessage({ id: 'transfer_order' })}>
        <Breadcrumb style={{ overflowWrap: 'break-word' }}>
          {documentHeader.doc_flows.map(docData =>
            docData.doc_id > 0 ? (
              <Breadcrumb.Item key={docData.doc_id}>
                {docData.is_current === true ? (
                  docData.doc_code
                ) : (
                  <Link replace to={`${appPath}/${docData.action}/update/${docData.doc_id}`}>
                    <span style={{ color: 'blue' }}>{docData.doc_code}</span>
                  </Link>
                )}
              </Breadcrumb.Item>
            ) : (
              ''
            )
          )}
        </Breadcrumb>
        <HeaderForm match={match} />
        <DetailTable />
        <Modal
          visible={detailIsVisible}
          title={intl.formatMessage({ id: 'transfer_order_detail' })}
          // style={{top:20}}
          width="80%"
          centered
          forceRender
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <DetailForm match={match} />
        </Modal>
      </Card>
    );
  }
}

TrfOrdDetailScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  setHdrId: PropTypes.func,
  setDetailVisible: PropTypes.func,
  documentIsLoading: PropTypes.bool,
  detailIsVisible: PropTypes.bool,
  documentHeader: PropTypes.shape({
    doc_flows: PropTypes.arrayOf(PropTypes.object)
  }),
  appPath: PropTypes.string
};

TrfOrdDetailScreen.defaultProps = {
  intl: {},
  match: {},
  setHdrId() {},
  setDetailVisible() {},
  documentIsLoading: false,
  detailIsVisible: false,
  documentHeader: { doc_flows: [] },
  appPath: ''
};

const mapStateToProps = state => ({
  documentIsLoading: state.trfOrdDetail.documentIsLoading,
  detailIsVisible: state.trfOrdDetail.detailIsVisible,
  documentHeader: state.trfOrdDetail.documentHeader,
  appPath: state.app.appPath
});

const mapDispatchToProps = dispatch => ({
  setHdrId: (hdrId, itemId) => dispatch(TrfOrdDetailActions.trfOrdDetailSetHdrId(hdrId, itemId)),
  setDetailVisible: boolean => dispatch(TrfOrdDetailActions.trfOrdDetailSetDetailVisible(boolean))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TrfOrdDetailScreen));
