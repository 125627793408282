/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LspSlsRtnDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const lspSlsRtnDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const lspSlsRtnDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  salesmanOptions: [],
  deliveryPointOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const lspSlsRtnDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const lspSlsRtnDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const lspSlsRtnDetailUpdateDocumentSuccess = (
  state,
  { documentHeader, documentDetails }
) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const lspSlsRtnDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const lspSlsRtnDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const lspSlsRtnDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  itemOptions: [],
  uomOptions: []
});

export const lspSlsRtnDetailFetchDebtorOptionLoading = (state, { boolean }) => ({
  ...state,
  debtorIsLoading: boolean
});

export const lspSlsRtnDetailFetchDebtorOptionSuccess = (state, { options }) => ({
  ...state,
  debtorOptions: options
});

export const lspSlsRtnDetailFetchSalesmanOptionLoading = (state, { boolean }) => ({
  ...state,
  salesmanIsLoading: boolean
});

export const lspSlsRtnDetailFetchSalesmanOptionSuccess = (state, { options }) => ({
  ...state,
  salesmanOptions: options
});

export const lspSlsRtnDetailFetchDeliveryPointOptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryPointIsLoading: boolean
});

export const lspSlsRtnDetailFetchDeliveryPointOptionSuccess = (state, { options }) => ({
  ...state,
  deliveryPointOptions: options
});

export const lspSlsRtnDetailFetchCreditTermOptionLoading = (state, { boolean }) => ({
  ...state,
  creditTermIsLoading: boolean
});

export const lspSlsRtnDetailFetchCreditTermOptionSuccess = (state, { options }) => ({
  ...state,
  creditTermOptions: options
});

export const lspSlsRtnDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const lspSlsRtnDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

export const lspSlsRtnDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const lspSlsRtnDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const lspSlsRtnDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const lspSlsRtnDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const lspSlsRtnDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const lspSlsRtnDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const lspSlsRtnDetailFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const lspSlsRtnDetailFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const lspSlsRtnDetailFetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const lspSlsRtnDetailFetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_RESET_TIMESTAMP]: lspSlsRtnDetailResetTimestamp,
  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_SET_HDR_ID]: lspSlsRtnDetailSetHdrId,

  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_SHOW_HEADER_SUCCESS]: lspSlsRtnDetailShowHeaderSuccess,

  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_UPDATE_DOCUMENT_SUCCESS]: lspSlsRtnDetailUpdateDocumentSuccess,

  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_SHOW_DOCUMENT_LOADING]: lspSlsRtnDetailShowDocumentLoading,

  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_SHOW_DETAILS_SUCCESS]: lspSlsRtnDetailShowDetailsSuccess,

  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_SET_DETAIL_VISIBLE]: lspSlsRtnDetailSetDetailVisible,
  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_SET_DOCUMENT_DETAIL]: lspSlsRtnDetailSetDocumentDetail,

  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_UPDATE_DETAILS]: lspSlsRtnDetailUpdateDetails,
  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_CREATE_DETAIL]: lspSlsRtnDetailCreateDetail,

  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_DEBTOR_OPTION_LOADING]: lspSlsRtnDetailFetchDebtorOptionLoading,
  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_DEBTOR_OPTION_SUCCESS]: lspSlsRtnDetailFetchDebtorOptionSuccess,

  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_SALESMAN_OPTION_LOADING]: lspSlsRtnDetailFetchSalesmanOptionLoading,
  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_SALESMAN_OPTION_SUCCESS]: lspSlsRtnDetailFetchSalesmanOptionSuccess,

  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_DELIVERY_POINT_OPTION_LOADING]: lspSlsRtnDetailFetchDeliveryPointOptionLoading,
  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_DELIVERY_POINT_OPTION_SUCCESS]: lspSlsRtnDetailFetchDeliveryPointOptionSuccess,

  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_CREDIT_TERM_OPTION_LOADING]: lspSlsRtnDetailFetchCreditTermOptionLoading,
  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_CREDIT_TERM_OPTION_SUCCESS]: lspSlsRtnDetailFetchCreditTermOptionSuccess,

  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: lspSlsRtnDetailFetchCurrencyOptionLoading,
  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: lspSlsRtnDetailFetchCurrencyOptionSuccess,

  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_ITEM_OPTION_LOADING]: lspSlsRtnDetailFetchItemOptionLoading,
  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: lspSlsRtnDetailFetchItemOptionSuccess,

  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_UOM_OPTION_LOADING]: lspSlsRtnDetailFetchUomOptionLoading,
  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_UOM_OPTION_SUCCESS]: lspSlsRtnDetailFetchUomOptionSuccess,

  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_LOCATION_OPTION_LOADING]: lspSlsRtnDetailFetchLocationOptionLoading,
  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_LOCATION_OPTION_SUCCESS]: lspSlsRtnDetailFetchLocationOptionSuccess,

  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_PROJECT_OPTION_LOADING]: lspSlsRtnDetailFetchProjectOptionLoading,
  [LspSlsRtnDetailTypes.LSP_SLS_RTN_DETAIL_FETCH_PROJECT_OPTION_SUCCESS]: lspSlsRtnDetailFetchProjectOptionSuccess
});
