/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { GdsRcpt02Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const gdsRcpt02ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const gdsRcpt02FetchGdsRcpt02Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const gdsRcpt02FetchGdsRcpt02Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const gdsRcpt02AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const gdsRcpt02RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const gdsRcpt02SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const gdsRcpt02CreateGdsRcpt02Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const gdsRcpt02CreateGdsRcpt02Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

export const gdsRcpt02FetchToStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  toStorageBinIsLoading: boolean
});

export const gdsRcpt02FetchToStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  toStorageBinOptions: options
});

export const gdsRcpt02SetToStorageBinOption = (state, { option }) => ({
  ...state,
  toStorageBinOption: option
});

export const gdsRcpt02SetMinPalletQtyPerc = (state, { value }) => ({
  ...state,
  minPalletQtyPerc: value
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GdsRcpt02Types.GDS_RCPT02_RESET_TIMESTAMP]: gdsRcpt02ResetTimestamp,
  [GdsRcpt02Types.GDS_RCPT02_FETCH_GDS_RCPT02_LOADING]: gdsRcpt02FetchGdsRcpt02Loading,
  [GdsRcpt02Types.GDS_RCPT02_FETCH_GDS_RCPT02_SUCCESS]: gdsRcpt02FetchGdsRcpt02Success,
  [GdsRcpt02Types.GDS_RCPT02_ADD_SELECTED_DOCUMENTS]: gdsRcpt02AddSelectedDocuments,
  [GdsRcpt02Types.GDS_RCPT02_REMOVE_SELECTED_DOCUMENTS]: gdsRcpt02RemoveSelectedDocuments,
  [GdsRcpt02Types.GDS_RCPT02_SET_WORKSPACE_VISIBLE]: gdsRcpt02SetWorkspaceVisible,
  [GdsRcpt02Types.GDS_RCPT02_CREATE_GDS_RCPT02_LOADING]: gdsRcpt02CreateGdsRcpt02Loading,
  [GdsRcpt02Types.GDS_RCPT02_CREATE_GDS_RCPT02_SUCCESS]: gdsRcpt02CreateGdsRcpt02Success,
  [GdsRcpt02Types.GDS_RCPT02_FETCH_TO_STORAGE_BIN_OPTION_LOADING]: gdsRcpt02FetchToStorageBinOptionLoading,
  [GdsRcpt02Types.GDS_RCPT02_FETCH_TO_STORAGE_BIN_OPTION_SUCCESS]: gdsRcpt02FetchToStorageBinOptionSuccess,
  [GdsRcpt02Types.GDS_RCPT02_SET_TO_STORAGE_BIN_OPTION]: gdsRcpt02SetToStorageBinOption,
  [GdsRcpt02Types.GDS_RCPT02_SET_MIN_PALLET_QTY_PERC]: gdsRcpt02SetMinPalletQtyPerc
});
