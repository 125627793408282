import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import { Card, Tabs, Tooltip } from 'antd';

import ProjectList01Table from '../ProjectList01/ProjectList01Table';

const { TabPane } = Tabs;

class GeneralManagementScreen extends React.PureComponent {
  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const { intl, match, appPath } = this.props;

    return (
      <Card>
        <Tabs type="card" activeKey={match.params.proc_type}>
          <TabPane
            tab={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Tooltip placement="bottom" title={intl.formatMessage({ id: `projects` })}>
                <Link
                  style={match.params.proc_type !== 'PROJECT_LIST_01' ? { color: 'black' } : {}}
                  to={`${appPath}/generalManagement/index/PROJECT_LIST_01`}
                >
                  <FormattedMessage id="project_list_01_label" />
                </Link>
              </Tooltip>
            }
            key="PROJECT_LIST_01"
          />
        </Tabs>

        {match.params.proc_type === 'PROJECT_LIST_01' && <ProjectList01Table match={match} />}
      </Card>
    );
  }
}

GeneralManagementScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string
};

GeneralManagementScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: ''
};

const mapStateToProps = state => ({
  appPath: state.app.appPath
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GeneralManagementScreen));
