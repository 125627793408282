import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  pickFaceStrategyExcel01ShowBatchJobStatus: null,
  pickFaceStrategyExcel01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  pickFaceStrategyExcel01UploadExcel: ['file'],
  pickFaceStrategyExcel01UploadLoading: ['boolean'],
  pickFaceStrategyExcel01DownloadExcel: null
});

export const PickFaceStrategyExcel01Types = Types;
export default Creators;
