/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { ProdOrdIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const prodOrdIndexResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
});

export const prodOrdIndexFetchProdOrdIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const prodOrdIndexFetchProdOrdIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const prodOrdIndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const prodOrdIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const prodOrdIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const prodOrdIndexWorkspaceLoading = (state, { boolean }) => ({
  ...state,
  workspaceIsLoading: boolean
});

export const prodOrdIndexResetProdOrdFServ02Success = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ProdOrdIndexTypes.PROD_ORD_INDEX_RESET_TIMESTAMP]: prodOrdIndexResetTimestamp,
  [ProdOrdIndexTypes.PROD_ORD_INDEX_FETCH_PROD_ORD_INDEX_LOADING]: prodOrdIndexFetchProdOrdIndexLoading,
  [ProdOrdIndexTypes.PROD_ORD_INDEX_FETCH_PROD_ORD_INDEX_SUCCESS]: prodOrdIndexFetchProdOrdIndexSuccess,
  [ProdOrdIndexTypes.PROD_ORD_INDEX_ADD_SELECTED_DOCUMENTS]: prodOrdIndexAddSelectedDocuments,
  [ProdOrdIndexTypes.PROD_ORD_INDEX_REMOVE_SELECTED_DOCUMENTS]: prodOrdIndexRemoveSelectedDocuments,
  [ProdOrdIndexTypes.PROD_ORD_INDEX_SET_WORKSPACE_VISIBLE]: prodOrdIndexSetWorkspaceVisible,
  [ProdOrdIndexTypes.PROD_ORD_INDEX_WORKSPACE_LOADING]: prodOrdIndexWorkspaceLoading,
  [ProdOrdIndexTypes.PROD_ORD_INDEX_RESET_PROD_ORD_F_SERV02_SUCCESS]: prodOrdIndexResetProdOrdFServ02Success
});
