/**
 * The initial values for the redux state.
 */
export default {
  resId: 0,

  modelTimestamp: 0,
  model: {
    id: 0,
    code: '',
    ref_code_01: '',
    desc_01: '',
    desc_02: ''
  },

  modelIsLoading: false,

  siteOptions: [],
  siteIsLoading: false,

  whseTxnFlowTimestamp: 0,
  whseTxnFlows: [],
  whseTxnFlowsIsLoading: false,

  whseTxnFlowsUploadIsLoading: false,

  printDocSettingTimestamp: 0,
  printDocSettings: [],
  printDocSettingsIsLoading: false,

  printDocSettingsUploadIsLoading: false
};
