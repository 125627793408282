import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  advShipSync01ShowSyncSetting: null,
  advShipSync01ShowSyncSettingLoading: ['boolean'],
  advShipSync01ShowSyncSettingSuccess: ['syncSetting'],
  advShipSync01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  advShipSync01ShowBatchJobStatus: null,
  advShipSync01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  advShipSync01SyncNow: ['formikBag'],
  advShipSync01SyncLoading: ['boolean']
});

export const AdvShipSync01Types = Types;
export default Creators;
