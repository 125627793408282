import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import RtnRcptSync01Actions, { RtnRcptSync01Types } from '../Stores/RtnRcptSync01/Actions';

const getAppStore = state => state.app;

export function* rtnRcptSync01ShowSyncSetting() {
  try {
    yield put(RtnRcptSync01Actions.rtnRcptSync01ShowSyncSettingLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `syncSetting/showDivisionSetting/SYNC_EFICHAIN_01/${app.curDivisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(RtnRcptSync01Actions.rtnRcptSync01ShowSyncSettingSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(RtnRcptSync01Actions.rtnRcptSync01ShowSyncSettingLoading(false));
  }
}

export function* rtnRcptSync01UpdateSyncSetting({ formikBag, syncSetting }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: syncSetting
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `syncSetting/updateDivisionSetting/SYNC_EFICHAIN_01/${app.curDivisionId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* rtnRcptSync01ShowBatchJobStatus() {
  try {
    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `batchJobStatus/showBatchJobStatus/RTN_RCPT_SYNC_01`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(RtnRcptSync01Actions.rtnRcptSync01ShowBatchJobStatusSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  }
}

export function* rtnRcptSync01SyncNow({ hdrIds }) {
  try {
    yield put(RtnRcptSync01Actions.rtnRcptSync01SyncLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      divisionId: app.curDivisionId,
      hdrIds
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `rtnRcpt/syncProcess/RTN_RCPT_SYNC_01`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(RtnRcptSync01Actions.rtnRcptSync01SyncNowSuccess());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(RtnRcptSync01Actions.rtnRcptSync01SyncLoading(false));
  }
}

export function* rtnRcptSync01GoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/rtnRcptDetail/update/${hdrId}`));
}

export function* rtnRcptSync01FetchRtnRcptSync01({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(RtnRcptSync01Actions.rtnRcptSync01FetchRtnRcptSync01Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `rtnRcpt/indexProcess/RTN_RCPT_SYNC_01/${app.curDivisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        RtnRcptSync01Actions.rtnRcptSync01FetchRtnRcptSync01Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(RtnRcptSync01Actions.rtnRcptSync01FetchRtnRcptSync01Loading(false));
  }
}

export const saga = [
  takeLatest(
    RtnRcptSync01Types.RTN_RCPT_SYNC01_SHOW_BATCH_JOB_STATUS,
    rtnRcptSync01ShowBatchJobStatus
  ),
  takeLatest(RtnRcptSync01Types.RTN_RCPT_SYNC01_SHOW_SYNC_SETTING, rtnRcptSync01ShowSyncSetting),
  // takeLatest(RtnRcptSync01Types.RTN_RCPT_SYNC01_SYNC_NOW, rtnRcptSync01SyncNow),
  takeLatest(
    RtnRcptSync01Types.RTN_RCPT_SYNC01_UPDATE_SYNC_SETTING,
    rtnRcptSync01UpdateSyncSetting
  ),

  takeLatest(RtnRcptSync01Types.RTN_RCPT_SYNC01_GO_TO_DOCUMENT, rtnRcptSync01GoToDocument),
  takeLatest(
    RtnRcptSync01Types.RTN_RCPT_SYNC01_FETCH_RTN_RCPT_SYNC01,
    rtnRcptSync01FetchRtnRcptSync01
  ),
  takeLatest(RtnRcptSync01Types.RTN_RCPT_SYNC01_SYNC_NOW, rtnRcptSync01SyncNow)
];
