import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  appUpdateApiUrl: ['apiUrl'],
  appUpdateAppPath: ['appPath'],
  appAuthenticate: ['formikBag', 'username', 'password'],
  appAuthenticateSuccess: ['user', 'successMessage'],
  appChangePassword: ['formikBag', 'currentPassword', 'newPassword'],
  appChangePasswordSuccess: ['successMessage'],
  appTokenExpired: ['errorMessage'],
  appPasswordExpired: ['errorMessage'],
  appChangeLocale: ['locale'],
  appHistoryGoBack: [],
  appGoToHome: [],
  appLogout: null,
  appLogoutSuccess: ['successMessage'],

  appFetchSiteFlowOptions: null,
  appFetchSiteFlowOptionLoading: ['boolean'],
  appFetchSiteFlowOptionSuccess: ['options'],
  appChangeSiteFlow: ['siteFlowId'],

  appFetchDivisionOptions: ['siteFlowId'],
  appFetchDivisionOptionLoading: ['boolean'],
  appFetchDivisionOptionSuccess: ['options'],
  appChangeDivision: ['divisionId']
});

export const AppTypes = Types;
export default Creators;
