import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspSupplierReturnProcessFetchLspSupplierReturnProcess: null,
  lspSupplierReturnProcessFetchLspSupplierReturnProcessLoading: ['boolean'],
  lspSupplierReturnProcessFetchLspSupplierReturnProcessSuccess: ['process']
});

export const LspSupplierReturnProcessTypes = Types;
export default Creators;
