import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { DeleteOutlined } from '@ant-design/icons';

import { Typography, Row, Col, Modal, Table, Button, Popconfirm, Select, InputNumber } from 'antd';

import GdsRcpt01Actions from '../../Stores/GdsRcpt01/Actions';
import SystemSettingActions from '../../Stores/SystemSetting/Actions';

const { Option } = Select;
const { Title, Text } = Typography;

class GdsRcpt01Dialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnCreateDocument = this.useOnCreateDocument.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);

    this.handleSearchToStorageBin = this.handleSearchToStorageBin.bind(this);
    this.handleChangeToStorageBin = this.handleChangeToStorageBin.bind(this);

    this.handleChangeMinPalletQtyPerc = this.handleChangeMinPalletQtyPerc.bind(this);
  }

  componentDidMount() {
    const { fetchMinPalletQty } = this.props;

    fetchMinPalletQty('GDS_RCPT_DEFAULT_MIN_PALLET_QTY');
  }

  componentDidUpdate(prevProps) {
    const { setMinPalletQtyPerc, minPalletQtySetting, fetchMinPalletQty } = this.props;

    const { changed } = treeChanges(prevProps, this.props);
    if (changed('workspaceIsVisible')) {
      fetchMinPalletQty('GDS_RCPT_DEFAULT_MIN_PALLET_QTY');
      setMinPalletQtyPerc(minPalletQtySetting.setting);
    }
  }

  componentWillUnmount() {}

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  handleSearchToStorageBin(value) {
    const { fetchToStorageBinOptions } = this.props;
    fetchToStorageBinOptions(value);
  }

  handleChangeToStorageBin(value, option) {
    const { setToStorageBinOption } = this.props;
    const curOption = { value: parseInt(option.key, 10), label: option.props.children };
    setToStorageBinOption(curOption);
  }

  handleChangeMinPalletQtyPerc(value) {
    const { setMinPalletQtyPerc } = this.props;
    setMinPalletQtyPerc(value);
  }

  getSelectedDocumentColumns() {
    const { intl, removeSelectedDocuments } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'purchaser' }),
        // sort field
        dataIndex: 'purchaser_username',
        sorter: (a, b) => `${a.purchaser_username}`.localeCompare(b.purchaser_username),
        // filter field
        key: 'salesman',
        render: (text, record) => (
          <>
            <b>{record.purchaser_username}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'business_partner' }),
        // sort field
        dataIndex: 'biz_partner_company_name_01',
        sorter: (a, b) =>
          `${a.biz_partner_company_name_01}`.localeCompare(b.biz_partner_company_name_01),
        // filter field
        key: 'biz_partner',
        render: (text, record) => (
          <>
            <b>{record.biz_partner_code}</b>
            <br />
            {record.biz_partner_company_name_01}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
            <br />
            {record.str_doc_status}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'net_amt' }),
        // sort field
        dataIndex: 'net_amt',
        sorter: (a, b) => a.net_amt - b.net_amt,
        // filter field
        key: 'net_amt',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.net_amt)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'case_decimal_qty' }),
        // sort field
        dataIndex: 'case_decimal_qty',
        sorter: (a, b) => a.case_decimal_qty - b.case_decimal_qty,
        // filter field
        key: 'case_decimal_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.case_decimal_qty)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'gross_weight' }),
        // sort field
        dataIndex: 'gross_weight',
        sorter: (a, b) => a.gross_weight - b.gross_weight,
        // filter field
        key: 'gross_weight',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.gross_weight)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'cubic_meter' }),
        // sort field
        dataIndex: 'cubic_meter',
        sorter: (a, b) => a.cubic_meter - b.cubic_meter,
        // filter field
        key: 'cubic_meter',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.cubic_meter)}
            </div>
          </>
        )
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedDocuments([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  getNewDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'str_doc_status',
        sorter: (a, b) => `${a.str_doc_status}`.localeCompare(b.str_doc_status),
        // filter field
        key: 'str_doc_status',
        render: (text, record) => (
          <>
            <b>{record.str_doc_status}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'created_date' }),
        // sort field
        dataIndex: 'created_at',
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        // filter field
        key: 'created_at',
        render: (text, record) => <>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'updated_date' }),
        // sort field
        dataIndex: 'updated_at',
        sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
        // filter field
        key: 'updated_at',
        render: (text, record) => <>{moment(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}</>
      }
    ];
  }

  useOnCreateDocument() {
    const { selectedDocuments, toStorageBinOption, createGdsRcpt01, minPalletQtyPerc } = this.props;

    const hdrIds = selectedDocuments.map(value => {
      return value.id;
    });

    if (toStorageBinOption.value > 0) {
      createGdsRcpt01(hdrIds, toStorageBinOption.value, minPalletQtyPerc);
    }
  }

  render() {
    const {
      intl,
      newDocuments,
      selectedDocuments,
      workspaceIsVisible,
      toStorageBinOption,
      toStorageBinOptions,
      createIsLoading,
      toStorageBinIsLoading,
      minPalletQtyPerc
    } = this.props;

    const options = toStorageBinOptions.map(d => <Option key={d.value}>{`${d.label}`}</Option>);

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'create_gds_rcpt' })}
          // style={{top:20}}
          width="80%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              key="create"
              type="primary"
              disabled={selectedDocuments.length === 0 || toStorageBinOption.value === 0}
              loading={createIsLoading}
              onClick={this.useOnCreateDocument}
            >
              {intl.formatMessage({ id: 'create' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={4}>
              <Title level={4}>{intl.formatMessage({ id: 'to_storage_bin' })}</Title>
            </Col>
            <Col span={1}>
              <Title level={4}>:</Title>
            </Col>
            <Col span={18}>
              <Select
                showSearch
                value={toStorageBinOption.label}
                placeholder={intl.formatMessage({ id: 'key_in_code_or_description' })}
                style={{ width: 350 }}
                defaultActiveFirstOption={false}
                showArrow
                filterOption={false}
                onSearch={this.handleSearchToStorageBin}
                onChange={this.handleChangeToStorageBin}
                notFoundContent={null}
                loading={toStorageBinIsLoading}
                onDropdownVisibleChange={open => {
                  if (open && options.length === 0) {
                    this.handleSearchToStorageBin('');
                  }
                }}
              >
                {options}
              </Select>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={4}>
              <Title level={4}>{intl.formatMessage({ id: 'min_pallet_qty' })}</Title>
            </Col>
            <Col span={1}>
              <Title level={4}>:</Title>
            </Col>
            <Col span={18}>
              <InputNumber
                value={minPalletQtyPerc}
                onChange={this.handleChangeMinPalletQtyPerc}
                max={100}
                min={0}
              />
              <Text>% </Text>
              <Text italic>
                (100% will not create any pallet ID, 0% will always create pallet ID)
              </Text>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocuments}
                loading={createIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_documents' })}</b>}
                summary={pageData => {
                  // summarise the table
                  const calcRow = pageData.reduce(
                    (ttlObj, object) => {
                      return {
                        net_amt:
                          ('net_amt' in ttlObj ? ttlObj.net_amt : 0) + parseFloat(object.net_amt),
                        case_decimal_qty:
                          ('case_decimal_qty' in ttlObj ? ttlObj.case_decimal_qty : 0) +
                          parseFloat(object.case_decimal_qty),
                        gross_weight:
                          ('gross_weight' in ttlObj ? ttlObj.gross_weight : 0) +
                          parseFloat(object.gross_weight),
                        cubic_meter:
                          ('cubic_meter' in ttlObj ? ttlObj.cubic_meter : 0) +
                          parseFloat(object.cubic_meter)
                      };
                    },
                    { net_amt: 0, case_decimal_qty: 0, gross_weight: 0, cubic_meter: 0 }
                  );

                  return (
                    <>
                      <tr className="ant-table-row">
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={4}>
                          {intl.formatMessage({ id: 'total' })}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.net_amt)}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.case_decimal_qty)}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.gross_weight)}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.cubic_meter)}
                        </td>
                        <td />
                      </tr>
                    </>
                  );
                }}
              />
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                size="small"
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getNewDocumentColumns()}
                dataSource={newDocuments}
                loading={createIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'new_gds_rcpts' })}</b>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

GdsRcpt01Dialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedDocuments: PropTypes.func,
  newDocuments: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  createIsLoading: PropTypes.bool,

  createGdsRcpt01: PropTypes.func,

  setToStorageBinOption: PropTypes.func,
  fetchToStorageBinOptions: PropTypes.func,
  toStorageBinOption: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string
  }),
  toStorageBinOptions: PropTypes.arrayOf(PropTypes.object),
  toStorageBinIsLoading: PropTypes.bool,

  setMinPalletQtyPerc: PropTypes.func,
  minPalletQtyPerc: PropTypes.number,
  minPalletQtySetting: PropTypes.object
};

GdsRcpt01Dialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedDocuments() {},
  newDocuments: [],
  selectedDocuments: [],

  createIsLoading: false,

  createGdsRcpt01() {},

  fetchToStorageBinOptions() {},
  setToStorageBinOption() {},
  toStorageBinOption: { value: 0, label: '' },
  toStorageBinOptions: [],
  toStorageBinIsLoading: false,

  setMinPalletQtyPerc() {},
  minPalletQtyPerc: 10,
  minPalletQtySetting: {}
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.gdsRcpt01.workspaceIsVisible,

  selectedDocuments: state.gdsRcpt01.selectedDocuments,
  newDocuments: state.gdsRcpt01.newDocuments,

  createIsLoading: state.gdsRcpt01.createIsLoading,

  toStorageBinIsLoading: state.gdsRcpt01.toStorageBinIsLoading,
  toStorageBinOptions: state.gdsRcpt01.toStorageBinOptions,
  toStorageBinOption: state.gdsRcpt01.toStorageBinOption,
  minPalletQtyPerc: state.gdsRcpt01.minPalletQtyPerc,

  minPalletQtySetting: state.systemSetting.data
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean => dispatch(GdsRcpt01Actions.gdsRcpt01SetWorkspaceVisible(boolean)),

  removeSelectedDocuments: selectedDocuments =>
    dispatch(GdsRcpt01Actions.gdsRcpt01RemoveSelectedDocuments(selectedDocuments)),

  createGdsRcpt01: (hdrIds, toStorageBinId, minPalletQtyPerc) =>
    dispatch(GdsRcpt01Actions.gdsRcpt01CreateGdsRcpt01(hdrIds, toStorageBinId, minPalletQtyPerc)),

  fetchToStorageBinOptions: search =>
    dispatch(GdsRcpt01Actions.gdsRcpt01FetchToStorageBinOptions(search)),
  setToStorageBinOption: option =>
    dispatch(GdsRcpt01Actions.gdsRcpt01SetToStorageBinOption(option)),
  setMinPalletQtyPerc: option => dispatch(GdsRcpt01Actions.gdsRcpt01SetMinPalletQtyPerc(option)),
  fetchMinPalletQty: code => dispatch(SystemSettingActions.systemSettingFetchSetting(code))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GdsRcpt01Dialog));
