import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspAdvShipIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  lspAdvShipIndexGoToDocument: ['hdrId'],
  lspAdvShipIndexGoToAudit: ['hdrId'],
  lspAdvShipIndexFetchLspAdvShipIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  lspAdvShipIndexFetchLspAdvShipIndexLoading: ['boolean'],
  lspAdvShipIndexFetchLspAdvShipIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  lspAdvShipIndexAddSelectedDocuments: ['selectedDocuments'],
  lspAdvShipIndexRemoveSelectedDocuments: ['selectedDocuments'],
  lspAdvShipIndexSetWorkspaceVisible: ['boolean'],
  lspAdvShipIndexCreateLspAdvShipIndex: ['hdrIds'],
  lspAdvShipIndexCreateLspAdvShipIndexLoading: ['boolean'],
  lspAdvShipIndexCreateLspAdvShipIndexSuccess: ['newDocuments']
});

export const LspAdvShipIndexTypes = Types;
export default Creators;
