import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspSlsOrdIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  lspSlsOrdIndexFetchLspSlsOrdIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  lspSlsOrdIndexFetchLspSlsOrdIndexLoading: ['boolean'],
  lspSlsOrdIndexFetchLspSlsOrdIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  lspSlsOrdIndexAddSelectedDocuments: ['selectedDocuments'],
  lspSlsOrdIndexRemoveSelectedDocuments: ['selectedDocuments'],
  lspSlsOrdIndexSetWorkspaceVisible: ['boolean'],
  lspSlsOrdIndexGoToDocument: ['hdrId'],
  lspSlsOrdIndexGoToAudit: ['hdrId']
});

export const LspSlsOrdIndexTypes = Types;
export default Creators;
