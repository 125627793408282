/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { GdsRcptFServ02Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const gdsRcptFServ02ShowSyncSettingLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const gdsRcptFServ02ShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const gdsRcptFServ02ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const gdsRcptFServ02ExportLoading = (state, { boolean }) => ({
  ...state,
  exportIsLoading: boolean
});

export const gdsRcptFServ02ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const gdsRcptFServ02FetchGdsRcptFServ02Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const gdsRcptFServ02FetchGdsRcptFServ02Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const gdsRcptFServ02AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const gdsRcptFServ02RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const gdsRcptFServ02SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const gdsRcptFServ02ExportSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const gdsRcptFServ02SetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GdsRcptFServ02Types.GDS_RCPT_F_SERV02_SHOW_SYNC_SETTING_LOADING]: gdsRcptFServ02ShowSyncSettingLoading,
  [GdsRcptFServ02Types.GDS_RCPT_F_SERV02_SHOW_SYNC_SETTING_SUCCESS]: gdsRcptFServ02ShowSyncSettingSuccess,
  [GdsRcptFServ02Types.GDS_RCPT_F_SERV02_SHOW_BATCH_JOB_STATUS_SUCCESS]: gdsRcptFServ02ShowBatchJobStatusSuccess,

  [GdsRcptFServ02Types.GDS_RCPT_F_SERV02_RESET_TIMESTAMP]: gdsRcptFServ02ResetTimestamp,
  [GdsRcptFServ02Types.GDS_RCPT_F_SERV02_FETCH_GDS_RCPT_F_SERV02_LOADING]: gdsRcptFServ02FetchGdsRcptFServ02Loading,
  [GdsRcptFServ02Types.GDS_RCPT_F_SERV02_FETCH_GDS_RCPT_F_SERV02_SUCCESS]: gdsRcptFServ02FetchGdsRcptFServ02Success,
  [GdsRcptFServ02Types.GDS_RCPT_F_SERV02_ADD_SELECTED_DOCUMENTS]: gdsRcptFServ02AddSelectedDocuments,
  [GdsRcptFServ02Types.GDS_RCPT_F_SERV02_REMOVE_SELECTED_DOCUMENTS]: gdsRcptFServ02RemoveSelectedDocuments,
  [GdsRcptFServ02Types.GDS_RCPT_F_SERV02_SET_WORKSPACE_VISIBLE]: gdsRcptFServ02SetWorkspaceVisible,
  [GdsRcptFServ02Types.GDS_RCPT_F_SERV02_EXPORT_LOADING]: gdsRcptFServ02ExportLoading,
  [GdsRcptFServ02Types.GDS_RCPT_F_SERV02_EXPORT_SUCCESS]: gdsRcptFServ02ExportSuccess,

  [GdsRcptFServ02Types.GDS_RCPT_F_SERV02_SET_EXPANDED_ROWS]: gdsRcptFServ02SetExpandedRows
});
