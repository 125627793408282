import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  uomDetailResetModelTimestamp: null,
  uomDetailSetResId: ['resId'],

  uomDetailInitModel: [],
  uomDetailShowModel: ['resId'],
  uomDetailShowModelSuccess: ['model'],

  uomDetailUpdateModel: ['formikBag', 'model'],
  uomDetailCreateModel: ['formikBag', 'model'],
  uomDetailUpdateModelSuccess: ['model'],

  uomDetailShowModelLoading: ['boolean']
});

export const UomDetailTypes = Types;
export default Creators;
