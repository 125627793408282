/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhseJob060201Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const whseJob060201ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  fetchIsLoading: false,
  printIsLoading: false
});

export const whseJob060201FetchWhseJob060201Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const whseJob060201FetchWhseJob060201Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const whseJob060201AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ].slice(0, 5)
  };
};

export const whseJob060201RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const whseJob060201SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const whseJob060201PrintWhseJob060201Loading = (state, { boolean }) => ({
  ...state,
  printIsLoading: boolean
});

export const whseJob060201SetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhseJob060201Types.WHSE_JOB060201_RESET_TIMESTAMP]: whseJob060201ResetTimestamp,
  [WhseJob060201Types.WHSE_JOB060201_FETCH_WHSE_JOB060201_LOADING]: whseJob060201FetchWhseJob060201Loading,
  [WhseJob060201Types.WHSE_JOB060201_FETCH_WHSE_JOB060201_SUCCESS]: whseJob060201FetchWhseJob060201Success,
  [WhseJob060201Types.WHSE_JOB060201_ADD_SELECTED_DOCUMENTS]: whseJob060201AddSelectedDocuments,
  [WhseJob060201Types.WHSE_JOB060201_REMOVE_SELECTED_DOCUMENTS]: whseJob060201RemoveSelectedDocuments,
  [WhseJob060201Types.WHSE_JOB060201_SET_WORKSPACE_VISIBLE]: whseJob060201SetWorkspaceVisible,
  [WhseJob060201Types.WHSE_JOB060201_PRINT_WHSE_JOB060201_LOADING]: whseJob060201PrintWhseJob060201Loading,

  [WhseJob060201Types.WHSE_JOB060201_SET_EXPANDED_ROWS]: whseJob060201SetExpandedRows
});
