/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CountAdjReasonDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const countAdjReasonDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const countAdjReasonDetailSetResId = (state, { resId }) => ({
  ...state,
  timestamp: Date.now(),
  modelIsLoading: false,
  resId
});

export const countAdjReasonDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const countAdjReasonDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const countAdjReasonDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CountAdjReasonDetailTypes.COUNT_ADJ_REASON_DETAIL_RESET_TIMESTAMP]: countAdjReasonDetailResetTimestamp,
  [CountAdjReasonDetailTypes.COUNT_ADJ_REASON_DETAIL_SET_RES_ID]: countAdjReasonDetailSetResId,
  [CountAdjReasonDetailTypes.COUNT_ADJ_REASON_DETAIL_SHOW_MODEL_LOADING]: countAdjReasonDetailShowModelLoading,
  [CountAdjReasonDetailTypes.COUNT_ADJ_REASON_DETAIL_SHOW_MODEL_SUCCESS]: countAdjReasonDetailShowModelSuccess,
  [CountAdjReasonDetailTypes.COUNT_ADJ_REASON_DETAIL_UPDATE_MODEL_SUCCESS]: countAdjReasonDetailUpdateModelSuccess
});
