/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { FailDel01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const failDel01FetchFailDel01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const failDel01CreateFailDel01Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const failDel01SearchDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const failDel01SearchDocumentSuccess = (state, { options }) => ({
  ...state,
  documentOptions: options
});

export const failDel01ScanBarcodeSuccess = (state, { outbOrdHdrs }) => ({
  ...state,
  outbOrdHdrs
});

export const failDel01UpdateOutbOrds = (state, { outbOrdHdrs }) => ({
  ...state,
  outbOrdTimestamp: Date.now(),
  outbOrdHdrs
});

export const failDel01CreateFailDel01Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  outbOrdHdrs: [],
  newDocuments
});

export const failDel01FetchToStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  toStorageBinIsLoading: boolean
});

export const failDel01FetchToStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  toStorageBinOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [FailDel01Types.FAIL_DEL01_FETCH_FAIL_DEL01_LOADING]: failDel01FetchFailDel01Loading,
  [FailDel01Types.FAIL_DEL01_FETCH_TO_STORAGE_BIN_OPTION_LOADING]: failDel01FetchToStorageBinOptionLoading,
  [FailDel01Types.FAIL_DEL01_FETCH_TO_STORAGE_BIN_OPTION_SUCCESS]: failDel01FetchToStorageBinOptionSuccess,
  [FailDel01Types.FAIL_DEL01_CREATE_FAIL_DEL01_LOADING]: failDel01CreateFailDel01Loading,
  [FailDel01Types.FAIL_DEL01_CREATE_FAIL_DEL01_SUCCESS]: failDel01CreateFailDel01Success,
  [FailDel01Types.FAIL_DEL01_SEARCH_DOCUMENT_LOADING]: failDel01SearchDocumentLoading,
  [FailDel01Types.FAIL_DEL01_SEARCH_DOCUMENT_SUCCESS]: failDel01SearchDocumentSuccess,
  [FailDel01Types.FAIL_DEL01_SCAN_BARCODE_SUCCESS]: failDel01ScanBarcodeSuccess,
  [FailDel01Types.FAIL_DEL01_UPDATE_OUTB_ORDS]: failDel01UpdateOutbOrds
});
