import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  binTrf01ResetTimestamp: ['currentPage', 'sorts', 'criteria', 'pageSize'],
  binTrf01GoToResource: ['resId'],
  binTrf01GoToAudit: ['hdrId'],
  binTrf01FetchBinTrf01: ['currentPage', 'sorts', 'criteria', 'pageSize'],
  binTrf01AnalyseBinTrf01: ['criteria'],
  binTrf01DownloadBinTrf01: ['criteria'],
  binTrf01FetchBinTrf01Loading: ['boolean'],
  binTrf01FetchBinTrf01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  binTrf01AddSelectedDocuments: ['selectedDocuments'],
  binTrf01RemoveSelectedDocuments: ['selectedDocuments'],
  binTrf01SetWorkspaceVisible: ['boolean'],

  binTrf01FetchCompanyOptions: ['search'],
  binTrf01FetchCompanyOptionLoading: ['boolean'],
  binTrf01FetchCompanyOptionSuccess: ['options'],

  binTrf01FetchItemOptions: ['search'],
  binTrf01FetchItemOptionLoading: ['boolean'],
  binTrf01FetchItemOptionSuccess: ['options'],

  binTrf01FetchItemGroup01Options: ['search'],
  binTrf01FetchItemGroup01OptionLoading: ['boolean'],
  binTrf01FetchItemGroup01OptionSuccess: ['options'],

  binTrf01FetchItemGroup02Options: ['search'],
  binTrf01FetchItemGroup02OptionLoading: ['boolean'],
  binTrf01FetchItemGroup02OptionSuccess: ['options'],

  binTrf01FetchItemGroup03Options: ['search'],
  binTrf01FetchItemGroup03OptionLoading: ['boolean'],
  binTrf01FetchItemGroup03OptionSuccess: ['options'],

  binTrf01ShowBatchJobStatus: null,
  binTrf01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  binTrf01BatchJobLoading: ['batchJobCode']
});

export const BinTrf01Types = Types;
export default Creators;
