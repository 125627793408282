import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { DeleteOutlined } from '@ant-design/icons';

import { Row, Col, Modal, Table, Button, Popconfirm } from 'antd';

import GdsIssFServ01Actions from '../../Stores/GdsIssFServ01/Actions';

class GdsIssFServ01Dialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnSyncNow = this.useOnSyncNow.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  getSelectedDocumentColumns() {
    const { intl, removeSelectedDocuments } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
            <br />
            {record.str_doc_status}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'desc_01' }),
        // sort field
        dataIndex: 'desc_01',
        sorter: (a, b) => `${a.desc_01}`.localeCompare(b.desc_01),
        // filter field
        key: 'desc_01',
        render: (text, record) => (
          <>
            <b>{record.desc_01}</b>
            <br />
            {record.desc_01}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'pallet_qty' }),
        // sort field
        dataIndex: 'pallet_qty',
        // filter field
        key: 'pallet_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.pallet_qty)}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'case_decimal_qty' }),
        // sort field
        dataIndex: 'case_decimal_qty',
        // filter field
        key: 'case_decimal_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.case_decimal_qty)}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'gross_weight' }),
        // sort field
        dataIndex: 'gross_weight',
        // filter field
        key: 'gross_weight',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.gross_weight)}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'cubic_meter' }),
        // sort field
        dataIndex: 'cubic_meter',
        // filter field
        key: 'cubic_meter',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.cubic_meter)}
          </>
        )
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedDocuments([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnSyncNow() {
    const { selectedDocuments, fServ01Export } = this.props;

    const hdrIds = selectedDocuments.map(value => {
      return value.id;
    });

    fServ01Export(hdrIds);
  }

  render() {
    const { intl, selectedDocuments, workspaceIsVisible, exportIsLoading } = this.props;

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'export_purchase_transaction' })}
          // style={{top:20}}
          width="80%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              key="export"
              disabled={selectedDocuments.length === 0}
              loading={exportIsLoading}
              onClick={this.useOnSyncNow}
            >
              {intl.formatMessage({ id: 'export' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocuments}
                loading={exportIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_documents' })}</b>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

GdsIssFServ01Dialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedDocuments: PropTypes.func,
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  exportIsLoading: PropTypes.bool,

  fServ01Export: PropTypes.func
};

GdsIssFServ01Dialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedDocuments() {},
  selectedDocuments: [],

  exportIsLoading: false,

  fServ01Export() {}
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.gdsIssFServ01.workspaceIsVisible,

  selectedDocuments: state.gdsIssFServ01.selectedDocuments,

  exportIsLoading: state.gdsIssFServ01.exportIsLoading
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean =>
    dispatch(GdsIssFServ01Actions.gdsIssFServ01SetWorkspaceVisible(boolean)),

  removeSelectedDocuments: selectedDocuments =>
    dispatch(GdsIssFServ01Actions.gdsIssFServ01RemoveSelectedDocuments(selectedDocuments)),

  fServ01Export: hdrIds => dispatch(GdsIssFServ01Actions.gdsIssFServ01Export(hdrIds))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GdsIssFServ01Dialog));
