/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StkIssSync04Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const stkIssSync04ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const stkIssSync04FetchStkIssSync04Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const stkIssSync04FetchStkIssSync04Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const stkIssSync04AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const stkIssSync04RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const stkIssSync04SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const stkIssSync04SyncLoading = (state, { boolean }) => ({
  ...state,
  syncIsLoading: boolean
});

export const stkIssSync04SyncNowSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StkIssSync04Types.STK_ISS_SYNC04_RESET_TIMESTAMP]: stkIssSync04ResetTimestamp,
  [StkIssSync04Types.STK_ISS_SYNC04_FETCH_STK_ISS_SYNC04_LOADING]: stkIssSync04FetchStkIssSync04Loading,
  [StkIssSync04Types.STK_ISS_SYNC04_FETCH_STK_ISS_SYNC04_SUCCESS]: stkIssSync04FetchStkIssSync04Success,
  [StkIssSync04Types.STK_ISS_SYNC04_ADD_SELECTED_DOCUMENTS]: stkIssSync04AddSelectedDocuments,
  [StkIssSync04Types.STK_ISS_SYNC04_REMOVE_SELECTED_DOCUMENTS]: stkIssSync04RemoveSelectedDocuments,
  [StkIssSync04Types.STK_ISS_SYNC04_SET_WORKSPACE_VISIBLE]: stkIssSync04SetWorkspaceVisible,
  [StkIssSync04Types.STK_ISS_SYNC04_SYNC_LOADING]: stkIssSync04SyncLoading,
  [StkIssSync04Types.STK_ISS_SYNC04_SYNC_NOW_SUCCESS]: stkIssSync04SyncNowSuccess
});
