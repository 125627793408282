/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CountAdjAnalysisReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const countAdjAnalysisReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const countAdjAnalysisReportInitCountAdjAnalysisSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const countAdjAnalysisReportCountAdjAnalysisSuccess = (state, { criteria, reportData }) => ({
  ...state,
  criteria,
  reportData
});

export const countAdjAnalysisReportFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const countAdjAnalysisReportFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const countAdjAnalysisReportFetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const countAdjAnalysisReportFetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const countAdjAnalysisReportFetchItemGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup02IsLoading: boolean
});

export const countAdjAnalysisReportFetchItemGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup02Options: options
});

export const countAdjAnalysisReportFetchItemGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup03IsLoading: boolean
});

export const countAdjAnalysisReportFetchItemGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup03Options: options
});

export const countAdjAnalysisReportFetchStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBinIsLoading: boolean
});

export const countAdjAnalysisReportFetchStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  storageBinOptions: options
});

export const countAdjAnalysisReportFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const countAdjAnalysisReportFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

export const countAdjAnalysisReportFetchStorageBayOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBayIsLoading: boolean
});

export const countAdjAnalysisReportFetchStorageBayOptionSuccess = (state, { options }) => ({
  ...state,
  storageBayOptions: options
});

export const countAdjAnalysisReportFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const countAdjAnalysisReportFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const countAdjAnalysisReportFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const countAdjAnalysisReportFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_REPORT_LOADING]: countAdjAnalysisReportReportLoading,
  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_INIT_COUNT_ADJ_ANALYSIS_SUCCESS]: countAdjAnalysisReportInitCountAdjAnalysisSuccess,
  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_COUNT_ADJ_ANALYSIS_SUCCESS]: countAdjAnalysisReportCountAdjAnalysisSuccess,

  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_COMPANY_OPTION_LOADING]: countAdjAnalysisReportFetchCompanyOptionLoading,
  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_COMPANY_OPTION_SUCCESS]: countAdjAnalysisReportFetchCompanyOptionSuccess,

  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_ITEM_OPTION_LOADING]: countAdjAnalysisReportFetchItemOptionLoading,
  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_ITEM_OPTION_SUCCESS]: countAdjAnalysisReportFetchItemOptionSuccess,

  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_ITEM_GROUP01_OPTION_LOADING]: countAdjAnalysisReportFetchItemGroup01OptionLoading,
  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: countAdjAnalysisReportFetchItemGroup01OptionSuccess,

  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_ITEM_GROUP02_OPTION_LOADING]: countAdjAnalysisReportFetchItemGroup02OptionLoading,
  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_ITEM_GROUP02_OPTION_SUCCESS]: countAdjAnalysisReportFetchItemGroup02OptionSuccess,

  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_ITEM_GROUP03_OPTION_LOADING]: countAdjAnalysisReportFetchItemGroup03OptionLoading,
  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_ITEM_GROUP03_OPTION_SUCCESS]: countAdjAnalysisReportFetchItemGroup03OptionSuccess,

  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_STORAGE_BIN_OPTION_LOADING]: countAdjAnalysisReportFetchStorageBinOptionLoading,
  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_STORAGE_BIN_OPTION_SUCCESS]: countAdjAnalysisReportFetchStorageBinOptionSuccess,

  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_STORAGE_ROW_OPTION_LOADING]: countAdjAnalysisReportFetchStorageRowOptionLoading,
  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_STORAGE_ROW_OPTION_SUCCESS]: countAdjAnalysisReportFetchStorageRowOptionSuccess,

  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_STORAGE_BAY_OPTION_LOADING]: countAdjAnalysisReportFetchStorageBayOptionLoading,
  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_STORAGE_BAY_OPTION_SUCCESS]: countAdjAnalysisReportFetchStorageBayOptionSuccess,

  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_LOCATION_OPTION_LOADING]: countAdjAnalysisReportFetchLocationOptionLoading,
  [CountAdjAnalysisReportTypes.COUNT_ADJ_ANALYSIS_REPORT_FETCH_LOCATION_OPTION_SUCCESS]: countAdjAnalysisReportFetchLocationOptionSuccess
});
