/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { ItemSync0101Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const itemSync0101ShowSyncSettingLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const itemSync0101ShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const itemSync0101ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const itemSync0101SyncLoading = (state, { boolean }) => ({
  ...state,
  syncIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ItemSync0101Types.ITEM_SYNC0101_SHOW_SYNC_SETTING_LOADING]: itemSync0101ShowSyncSettingLoading,
  [ItemSync0101Types.ITEM_SYNC0101_SHOW_SYNC_SETTING_SUCCESS]: itemSync0101ShowSyncSettingSuccess,
  [ItemSync0101Types.ITEM_SYNC0101_SHOW_BATCH_JOB_STATUS_SUCCESS]: itemSync0101ShowBatchJobStatusSuccess,
  [ItemSync0101Types.ITEM_SYNC0101_SYNC_LOADING]: itemSync0101SyncLoading
});
