import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  itemBatchExcel01ShowBatchJobStatus: null,
  itemBatchExcel01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  itemBatchExcel01UploadExcel: ['file'],
  itemBatchExcel01UploadLoading: ['boolean'],
  itemBatchExcel01DownloadExcel: null
});

export const ItemBatchExcel01Types = Types;
export default Creators;
