import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import CycleCountIndexActions, { CycleCountIndexTypes } from '../Stores/CycleCountIndex/Actions';

const getAppStore = state => state.app;

export function* cycleCountIndexFetchCycleCountIndex({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(CycleCountIndexActions.cycleCountIndexFetchCycleCountIndexLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `cycleCount/index/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        CycleCountIndexActions.cycleCountIndexFetchCycleCountIndexSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CycleCountIndexActions.cycleCountIndexFetchCycleCountIndexLoading(false));
  }
}

export function* cycleCountIndexGoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/cycleCountDetail/update/${hdrId}`));
}

export function* cycleCountIndexGoToAudit({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/auditResource/CYCLE_COUNT/${hdrId}/CycleCountHdr`));
}

export const saga = [
  takeLatest(CycleCountIndexTypes.CYCLE_COUNT_INDEX_GO_TO_DOCUMENT, cycleCountIndexGoToDocument),
  takeLatest(CycleCountIndexTypes.CYCLE_COUNT_INDEX_GO_TO_AUDIT, cycleCountIndexGoToAudit),
  takeLatest(
    CycleCountIndexTypes.CYCLE_COUNT_INDEX_FETCH_CYCLE_COUNT_INDEX,
    cycleCountIndexFetchCycleCountIndex
  )
];
