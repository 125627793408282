import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stkIssSync02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkIssSync02GoToDocument: ['hdrId'],
  stkIssSync02FetchStkIssSync02: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkIssSync02FetchStkIssSync02Loading: ['boolean'],
  stkIssSync02FetchStkIssSync02Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  stkIssSync02AddSelectedDocuments: ['selectedDocuments'],
  stkIssSync02RemoveSelectedDocuments: ['selectedDocuments'],
  stkIssSync02SetWorkspaceVisible: ['boolean'],

  stkIssSync02SyncNow: ['hdrIds'],
  stkIssSync02SyncNowSuccess: null,
  stkIssSync02SyncLoading: ['boolean']
});

export const StkIssSync02Types = Types;
export default Creators;
