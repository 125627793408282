import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  layerFarmExcelShowBatchJobStatus: null,
  layerFarmExcelShowBatchJobStatusSuccess: ['batchJobStatus'],
  layerFarmExcelUploadExcel: ['file', 'procType'],
  layerFarmExcelUploadLoading: ['boolean'],
  layerFarmExcelDownloadExcel: []
});

export const LayerFarmExcelTypes = Types;
export default Creators;
