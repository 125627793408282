import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { DeleteOutlined } from '@ant-design/icons';

import { Row, Col, Modal, Table, Button, Popconfirm } from 'antd';

import InvDoc03Actions from '../../Stores/InvDoc03/Actions';

class InvDoc03Dialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnCreateDocument = this.useOnCreateDocument.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  getSelectedDocumentColumns() {
    const { intl, removeSelectedDocuments } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
            <br />
            {record.str_doc_status}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'receipt_qty' }),
        // sort field
        dataIndex: 'rcpt_unit_qty',
        sorter: (a, b) => a.case_qty - b.case_qty,
        // filter field
        key: 'rcpt_unit_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.rcpt_unit_qty)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'order_qty' }),
        // sort field
        dataIndex: 'ord_unit_qty',
        sorter: (a, b) => a.ord_unit_qty - b.ord_unit_qty,
        // filter field
        key: 'ord_unit_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.ord_unit_qty)}
            </div>
          </>
        )
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedDocuments([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  getNewDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'created_date' }),
        // sort field
        dataIndex: 'created_at',
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        // filter field
        key: 'created_at',
        render: (text, record) => <>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'updated_date' }),
        // sort field
        dataIndex: 'updated_at',
        sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
        // filter field
        key: 'updated_at',
        render: (text, record) => <>{moment(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}</>
      }
    ];
  }

  useOnCreateDocument() {
    const { selectedDocuments, createInvDoc03 } = this.props;

    const hdrIds = selectedDocuments.map(value => {
      return value.id;
    });

    createInvDoc03(hdrIds);
  }

  render() {
    const {
      intl,
      newDocuments,
      selectedDocuments,
      workspaceIsVisible,
      createIsLoading
    } = this.props;

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'create_invoice_or_do_from_gds_rcpt' })}
          // style={{top:20}}
          width="80%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              key="create"
              disabled={selectedDocuments.length === 0}
              loading={createIsLoading}
              onClick={this.useOnCreateDocument}
            >
              {intl.formatMessage({ id: 'create' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocuments}
                loading={createIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_documents' })}</b>}
                summary={pageData => {
                  // summarise the table
                  const calcRow = pageData.reduce(
                    (ttlObj, object) => {
                      return {
                        rcpt_unit_qty:
                          ('rcpt_unit_qty' in ttlObj ? ttlObj.rcpt_unit_qty : 0) +
                          parseFloat(object.rcpt_unit_qty),
                        ord_unit_qty:
                          ('ord_unit_qty' in ttlObj ? ttlObj.ord_unit_qty : 0) +
                          parseFloat(object.ord_unit_qty)
                      };
                    },
                    { rcpt_unit_qty: 0, ord_unit_qty: 0 }
                  );

                  return (
                    <>
                      <tr className="ant-table-row">
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={2}>
                          {intl.formatMessage({ id: 'total' })}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.rcpt_unit_qty)}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.ord_unit_qty)}
                        </td>
                        <td />
                      </tr>
                    </>
                  );
                }}
              />
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                size="small"
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getNewDocumentColumns()}
                dataSource={newDocuments}
                loading={createIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'new_invoices_or_delivery_orders' })}</b>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

InvDoc03Dialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedDocuments: PropTypes.func,
  newDocuments: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  createIsLoading: PropTypes.bool,

  createInvDoc03: PropTypes.func
};

InvDoc03Dialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedDocuments() {},
  newDocuments: [],
  selectedDocuments: [],

  createIsLoading: false,

  createInvDoc03() {}
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.invDoc03.workspaceIsVisible,

  selectedDocuments: state.invDoc03.selectedDocuments,
  newDocuments: state.invDoc03.newDocuments,

  createIsLoading: state.invDoc03.createIsLoading
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean => dispatch(InvDoc03Actions.invDoc03SetWorkspaceVisible(boolean)),

  removeSelectedDocuments: selectedDocuments =>
    dispatch(InvDoc03Actions.invDoc03RemoveSelectedDocuments(selectedDocuments)),

  createInvDoc03: hdrIds => dispatch(InvDoc03Actions.invDoc03CreateInvDoc03(hdrIds))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(InvDoc03Dialog));
