/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { ItemBatchExcel01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const itemBatchExcel01ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const itemBatchExcel01UploadLoading = (state, { boolean }) => ({
  ...state,
  uploadIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ItemBatchExcel01Types.ITEM_BATCH_EXCEL01_SHOW_BATCH_JOB_STATUS_SUCCESS]: itemBatchExcel01ShowBatchJobStatusSuccess,
  [ItemBatchExcel01Types.ITEM_BATCH_EXCEL01_UPLOAD_LOADING]: itemBatchExcel01UploadLoading
});
