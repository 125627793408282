/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CountAdjFServ02Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const countAdjFServ02ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const countAdjFServ02ExportLoading = (state, { boolean }) => ({
  ...state,
  exportIsLoading: boolean
});

export const countAdjFServ02ExportSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const countAdjFServ02FetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const countAdjFServ02FetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const countAdjFServ02FetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const countAdjFServ02FetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const countAdjFServ02FetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const countAdjFServ02FetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CountAdjFServ02Types.COUNT_ADJ_F_SERV02_SHOW_BATCH_JOB_STATUS_SUCCESS]: countAdjFServ02ShowBatchJobStatusSuccess,

  [CountAdjFServ02Types.COUNT_ADJ_F_SERV02_EXPORT_LOADING]: countAdjFServ02ExportLoading,
  [CountAdjFServ02Types.COUNT_ADJ_F_SERV02_EXPORT_SUCCESS]: countAdjFServ02ExportSuccess,

  [CountAdjFServ02Types.COUNT_ADJ_F_SERV02_FETCH_COMPANY_OPTION_LOADING]: countAdjFServ02FetchCompanyOptionLoading,
  [CountAdjFServ02Types.COUNT_ADJ_F_SERV02_FETCH_COMPANY_OPTION_SUCCESS]: countAdjFServ02FetchCompanyOptionSuccess,

  [CountAdjFServ02Types.COUNT_ADJ_F_SERV02_FETCH_LOCATION_OPTION_LOADING]: countAdjFServ02FetchLocationOptionLoading,
  [CountAdjFServ02Types.COUNT_ADJ_F_SERV02_FETCH_LOCATION_OPTION_SUCCESS]: countAdjFServ02FetchLocationOptionSuccess,

  [CountAdjFServ02Types.COUNT_ADJ_F_SERV02_FETCH_ITEM_OPTION_LOADING]: countAdjFServ02FetchItemOptionLoading,
  [CountAdjFServ02Types.COUNT_ADJ_F_SERV02_FETCH_ITEM_OPTION_SUCCESS]: countAdjFServ02FetchItemOptionSuccess
});
