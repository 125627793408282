/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { UomDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const uomDetailResetModelTimestamp = state => ({
  ...state,
  modelTimestamp: Date.now()
});

export const uomDetailSetResId = (state, { resId }) => ({
  ...state,
  modelTimestamp: Date.now(),
  resId
});

export const uomDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const uomDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const uomDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [UomDetailTypes.UOM_DETAIL_RESET_MODEL_TIMESTAMP]: uomDetailResetModelTimestamp,
  [UomDetailTypes.UOM_DETAIL_SET_RES_ID]: uomDetailSetResId,
  [UomDetailTypes.UOM_DETAIL_SHOW_MODEL_LOADING]: uomDetailShowModelLoading,
  [UomDetailTypes.UOM_DETAIL_SHOW_MODEL_SUCCESS]: uomDetailShowModelSuccess,
  [UomDetailTypes.UOM_DETAIL_UPDATE_MODEL_SUCCESS]: uomDetailUpdateModelSuccess
});
