import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  rtnRcptIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  rtnRcptIndexFetchRtnRcptIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  rtnRcptIndexFetchRtnRcptIndexLoading: ['boolean'],
  rtnRcptIndexFetchRtnRcptIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  rtnRcptIndexAddSelectedDocuments: ['selectedDocuments'],
  rtnRcptIndexRemoveSelectedDocuments: ['selectedDocuments'],
  rtnRcptIndexSetWorkspaceVisible: ['boolean'],
  rtnRcptIndexGoToDocument: ['hdrId'],
  rtnRcptIndexGoToAudit: ['hdrId'],

  rtnRcptIndexWorkspaceLoading: ['boolean'],
  rtnRcptIndexResetRtnRcptSync01: ['hdrIds'],
  rtnRcptIndexResetRtnRcptSync01Success: null
});

export const RtnRcptIndexTypes = Types;
export default Creators;
