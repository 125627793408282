import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  prodSheetFServ01ShowSyncSetting: null,
  prodSheetFServ01ShowSyncSettingLoading: ['boolean'],
  prodSheetFServ01ShowSyncSettingSuccess: ['syncSetting'],
  prodSheetFServ01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  prodSheetFServ01ShowBatchJobStatus: null,
  prodSheetFServ01ShowBatchJobStatusSuccess: ['batchJobStatus'],

  prodSheetFServ01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prodSheetFServ01GoToDocument: ['hdrId'],
  prodSheetFServ01FetchProdSheetFServ01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prodSheetFServ01FetchProdSheetFServ01Loading: ['boolean'],
  prodSheetFServ01FetchProdSheetFServ01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  prodSheetFServ01AddSelectedDocuments: ['selectedDocuments'],
  prodSheetFServ01RemoveSelectedDocuments: ['selectedDocuments'],
  prodSheetFServ01SetWorkspaceVisible: ['boolean'],

  prodSheetFServ01Export: ['hdrIds'],
  prodSheetFServ01ExportSuccess: null,
  prodSheetFServ01ExportLoading: ['boolean']
});

export const ProdSheetFServ01Types = Types;
export default Creators;
