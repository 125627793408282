import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import OutbOrdReservedStockReportActions, {
  OutbOrdReservedStockReportTypes
} from '../Stores/OutbOrdReservedStockReport/Actions';

const getAppStore = state => state.app;

export function* outbOrdReservedStockReportInitOutbOrdReservedStock() {
  try {
    yield put(OutbOrdReservedStockReportActions.outbOrdReservedStockReportReportLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseReport/initOutbOrdReservedStock/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        OutbOrdReservedStockReportActions.outbOrdReservedStockReportInitOutbOrdReservedStockSuccess(
          result.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(OutbOrdReservedStockReportActions.outbOrdReservedStockReportReportLoading(false));
  }
}

export function* outbOrdReservedStockReportOutbOrdReservedStock({ formikBag, criteria }) {
  try {
    formikBag.setSubmitting(true);
    yield put(OutbOrdReservedStockReportActions.outbOrdReservedStockReportReportLoading(true));

    const objCriteria = {};
    if ('division_ids_select2' in criteria) {
      const values = criteria.division_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.division_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('delivery_point_ids_select2' in criteria) {
      const values = criteria.delivery_point_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.delivery_point_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_ids_select2' in criteria) {
      const values = criteria.item_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_01_ids_select2' in criteria) {
      const values = criteria.item_group_01_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_01_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_02_ids_select2' in criteria) {
      const values = criteria.item_group_02_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_02_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_03_ids_select2' in criteria) {
      const values = criteria.item_group_03_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_03_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    let columns = [];
    if (criteria.columns.target.length > 0) {
      columns = criteria.columns.target.map(entry => {
        return entry.column;
      });
    }

    const app = yield select(getAppStore);
    const postData = {
      sorts: [],
      criteria: objCriteria,
      columns
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseReport/outbOrdReservedStock/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(
        OutbOrdReservedStockReportActions.outbOrdReservedStockReportOutbOrdReservedStockSuccess(
          criteria,
          result.data.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(OutbOrdReservedStockReportActions.outbOrdReservedStockReportReportLoading(false));
  }
}

export function* outbOrdReservedStockReportFetchItemOptions({ search }) {
  try {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchItemOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchItemOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchItemOptionLoading(false)
    );
  }
}

export function* outbOrdReservedStockReportFetchItemGroup01Options({ search }) {
  try {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchItemGroup01OptionLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchItemGroup01OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchItemGroup01OptionLoading(
        false
      )
    );
  }
}

export function* outbOrdReservedStockReportFetchItemGroup02Options({ search }) {
  try {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchItemGroup02OptionLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup02/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchItemGroup02OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchItemGroup02OptionLoading(
        false
      )
    );
  }
}

export function* outbOrdReservedStockReportFetchItemGroup03Options({ search }) {
  try {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchItemGroup03OptionLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup03/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchItemGroup03OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchItemGroup03OptionLoading(
        false
      )
    );
  }
}

export function* outbOrdReservedStockReportFetchStorageBinOptions({ search }) {
  try {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchStorageBinOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchStorageBinOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchStorageBinOptionLoading(
        false
      )
    );
  }
}

export function* outbOrdReservedStockReportFetchStorageRowOptions({ search }) {
  try {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchStorageRowOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageRow/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchStorageRowOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchStorageRowOptionLoading(
        false
      )
    );
  }
}

export function* outbOrdReservedStockReportFetchStorageBayOptions({ search }) {
  try {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchStorageBayOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBay/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchStorageBayOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchStorageBayOptionLoading(
        false
      )
    );
  }
}

export function* outbOrdReservedStockReportFetchLocationOptions({ search }) {
  try {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchLocationOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `location/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchLocationOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchLocationOptionLoading(false)
    );
  }
}

export function* outbOrdReservedStockReportFetchDivisionOptions({ search }) {
  try {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchDivisionOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `division/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.name_01}`
      }));

      yield put(
        OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchDivisionOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchDivisionOptionLoading(false)
    );
  }
}

export function* outbOrdReservedStockReportFetchDeliveryPointOptions({ search }) {
  try {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchDeliveryPointOptionLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `deliveryPoint/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.company_name_01}`
      }));

      yield put(
        OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchDeliveryPointOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutbOrdReservedStockReportActions.outbOrdReservedStockReportFetchDeliveryPointOptionLoading(
        false
      )
    );
  }
}

export const saga = [
  takeLatest(
    OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_INIT_OUTB_ORD_RESERVED_STOCK,
    outbOrdReservedStockReportInitOutbOrdReservedStock
  ),
  takeLatest(
    OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_OUTB_ORD_RESERVED_STOCK,
    outbOrdReservedStockReportOutbOrdReservedStock
  ),
  takeLatest(
    OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_ITEM_OPTIONS,
    outbOrdReservedStockReportFetchItemOptions
  ),
  takeLatest(
    OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP01_OPTIONS,
    outbOrdReservedStockReportFetchItemGroup01Options
  ),
  takeLatest(
    OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP02_OPTIONS,
    outbOrdReservedStockReportFetchItemGroup02Options
  ),
  takeLatest(
    OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP03_OPTIONS,
    outbOrdReservedStockReportFetchItemGroup03Options
  ),
  takeLatest(
    OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_STORAGE_BIN_OPTIONS,
    outbOrdReservedStockReportFetchStorageBinOptions
  ),
  takeLatest(
    OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_STORAGE_ROW_OPTIONS,
    outbOrdReservedStockReportFetchStorageRowOptions
  ),
  takeLatest(
    OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_STORAGE_BAY_OPTIONS,
    outbOrdReservedStockReportFetchStorageBayOptions
  ),
  takeLatest(
    OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_LOCATION_OPTIONS,
    outbOrdReservedStockReportFetchLocationOptions
  ),

  takeLatest(
    OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_DIVISION_OPTIONS,
    outbOrdReservedStockReportFetchDivisionOptions
  ),
  takeLatest(
    OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_DELIVERY_POINT_OPTIONS,
    outbOrdReservedStockReportFetchDeliveryPointOptions
  )
];
