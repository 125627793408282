import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  pickFaceStrategyProcessFetchPickFaceStrategyProcess: null,
  pickFaceStrategyProcessFetchPickFaceStrategyProcessLoading: ['boolean'],
  pickFaceStrategyProcessFetchPickFaceStrategyProcessSuccess: ['process']
});

export const PickFaceStrategyProcessTypes = Types;
export default Creators;
