/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LspSlsOrdDetailBoardTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const lspSlsOrdDetailBoardResetWipLspSlsOrdTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipLspSlsOrdCurrentPage: currentPage || state.wipLspSlsOrdCurrentPage,
  wipLspSlsOrdSorts: sorts || state.wipLspSlsOrdSorts,
  wipLspSlsOrdFilters: filters || state.wipLspSlsOrdFilters,
  wipLspSlsOrdPageSize: pageSize || state.wipLspSlsOrdPageSize,
  wipLspSlsOrdTimestamp: Date.now()
});

export const lspSlsOrdDetailBoardFetchWipLspSlsOrdLoading = (state, { boolean }) => ({
  ...state,
  wipLspSlsOrdIsLoading: boolean
});

export const lspSlsOrdDetailBoardFetchWipLspSlsOrdSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipLspSlsOrds: documents,
  wipLspSlsOrdCurrentPage: currentPage,
  wipLspSlsOrdLastPage: lastPage,
  wipLspSlsOrdTotal: total,
  wipLspSlsOrdPageSize: pageSize
});

export const lspSlsOrdDetailBoardResetWipOutbOrdTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipOutbOrdCurrentPage: currentPage || state.wipOutbOrdCurrentPage,
  wipOutbOrdSorts: sorts || state.wipOutbOrdSorts,
  wipOutbOrdFilters: filters || state.wipOutbOrdFilters,
  wipOutbOrdPageSize: pageSize || state.wipOutbOrdPageSize,
  wipOutbOrdTimestamp: Date.now()
});

export const lspSlsOrdDetailBoardFetchWipOutbOrdLoading = (state, { boolean }) => ({
  ...state,
  wipOutbOrdIsLoading: boolean
});

export const lspSlsOrdDetailBoardFetchWipOutbOrdSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipOutbOrds: documents,
  wipOutbOrdCurrentPage: currentPage,
  wipOutbOrdLastPage: lastPage,
  wipOutbOrdTotal: total,
  wipOutbOrdPageSize: pageSize
});

export const lspSlsOrdDetailBoardResetWipInvDocTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipInvDocCurrentPage: currentPage || state.wipInvDocCurrentPage,
  wipInvDocSorts: sorts || state.wipInvDocSorts,
  wipInvDocFilters: filters || state.wipInvDocFilters,
  wipInvDocPageSize: pageSize || state.wipInvDocPageSize,
  wipInvDocTimestamp: Date.now()
});

export const lspSlsOrdDetailBoardFetchWipInvDocLoading = (state, { boolean }) => ({
  ...state,
  wipInvDocIsLoading: boolean
});

export const lspSlsOrdDetailBoardFetchWipInvDocSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipInvDocs: documents,
  wipInvDocCurrentPage: currentPage,
  wipInvDocLastPage: lastPage,
  wipInvDocTotal: total,
  wipInvDocPageSize: pageSize
});

export const lspSlsOrdDetailBoardResetCompleteLoadListTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  completeLoadListCurrentPage: currentPage || state.completeLoadListCurrentPage,
  completeLoadListSorts: sorts || state.completeLoadListSorts,
  completeLoadListFilters: filters || state.completeLoadListFilters,
  completeLoadListPageSize: pageSize || state.completeLoadListPageSize,
  completeLoadListTimestamp: Date.now()
});

export const lspSlsOrdDetailBoardFetchCompleteLoadListLoading = (state, { boolean }) => ({
  ...state,
  completeLoadListIsLoading: boolean
});

export const lspSlsOrdDetailBoardFetchCompleteLoadListSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  completeLoadLists: documents,
  completeLoadListCurrentPage: currentPage,
  completeLoadListLastPage: lastPage,
  completeLoadListTotal: total,
  completeLoadListPageSize: pageSize
});

export const lspSlsOrdDetailBoardShowWipLspSlsOrdSummary = (state, { docId }) => ({
  ...state,
  wipLspSlsOrdDocId: docId,
  wipLspSlsOrdDialogIsVisible: true
});

export const lspSlsOrdDetailBoardSetWipLspSlsOrdDialogVisible = (state, { boolean }) => ({
  ...state,
  wipLspSlsOrdDialogIsVisible: boolean
});

export const lspSlsOrdDetailBoardShowWipLspSlsOrdSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipLspSlsOrdDocumentIsLoading: boolean
});

export const lspSlsOrdDetailBoardShowWipLspSlsOrdSummarySuccess = (state, { document }) => ({
  ...state,
  wipLspSlsOrdDocument: document
});

export const lspSlsOrdDetailBoardShowWipOutbOrdSummary = (state, { docId }) => ({
  ...state,
  wipOutbOrdDocId: docId,
  wipOutbOrdDialogIsVisible: true
});

export const lspSlsOrdDetailBoardSetWipOutbOrdDialogVisible = (state, { boolean }) => ({
  ...state,
  wipOutbOrdDialogIsVisible: boolean
});

export const lspSlsOrdDetailBoardShowWipOutbOrdSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipOutbOrdDocumentIsLoading: boolean
});

export const lspSlsOrdDetailBoardShowWipOutbOrdSummarySuccess = (state, { document }) => ({
  ...state,
  wipOutbOrdDocument: document
});

export const lspSlsOrdDetailBoardShowWipInvDocSummary = (state, { docId }) => ({
  ...state,
  wipInvDocDocId: docId,
  wipInvDocDialogIsVisible: true
});

export const lspSlsOrdDetailBoardSetWipInvDocDialogVisible = (state, { boolean }) => ({
  ...state,
  wipInvDocDialogIsVisible: boolean
});

export const lspSlsOrdDetailBoardShowWipInvDocSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipInvDocDocumentIsLoading: boolean
});

export const lspSlsOrdDetailBoardShowWipInvDocSummarySuccess = (state, { document }) => ({
  ...state,
  wipInvDocDocument: document
});

export const lspSlsOrdDetailBoardShowCompleteLoadListSummary = (state, { docId }) => ({
  ...state,
  completeLoadListDocId: docId,
  completeLoadListDialogIsVisible: true
});

export const lspSlsOrdDetailBoardSetCompleteLoadListDialogVisible = (state, { boolean }) => ({
  ...state,
  completeLoadListDialogIsVisible: boolean
});

export const lspSlsOrdDetailBoardShowCompleteLoadListSummaryLoading = (state, { boolean }) => ({
  ...state,
  completeLoadListDocumentIsLoading: boolean
});

export const lspSlsOrdDetailBoardShowCompleteLoadListSummarySuccess = (state, { document }) => ({
  ...state,
  completeLoadListDocument: document
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_RESET_WIP_LSP_SLS_ORD_TIMESTAMP]: lspSlsOrdDetailBoardResetWipLspSlsOrdTimestamp,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_FETCH_WIP_LSP_SLS_ORD_LOADING]: lspSlsOrdDetailBoardFetchWipLspSlsOrdLoading,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_FETCH_WIP_LSP_SLS_ORD_SUCCESS]: lspSlsOrdDetailBoardFetchWipLspSlsOrdSuccess,

  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_RESET_WIP_OUTB_ORD_TIMESTAMP]: lspSlsOrdDetailBoardResetWipOutbOrdTimestamp,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_FETCH_WIP_OUTB_ORD_LOADING]: lspSlsOrdDetailBoardFetchWipOutbOrdLoading,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_FETCH_WIP_OUTB_ORD_SUCCESS]: lspSlsOrdDetailBoardFetchWipOutbOrdSuccess,

  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_RESET_WIP_INV_DOC_TIMESTAMP]: lspSlsOrdDetailBoardResetWipInvDocTimestamp,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_FETCH_WIP_INV_DOC_LOADING]: lspSlsOrdDetailBoardFetchWipInvDocLoading,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_FETCH_WIP_INV_DOC_SUCCESS]: lspSlsOrdDetailBoardFetchWipInvDocSuccess,

  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_RESET_COMPLETE_LOAD_LIST_TIMESTAMP]: lspSlsOrdDetailBoardResetCompleteLoadListTimestamp,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_FETCH_COMPLETE_LOAD_LIST_LOADING]: lspSlsOrdDetailBoardFetchCompleteLoadListLoading,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_FETCH_COMPLETE_LOAD_LIST_SUCCESS]: lspSlsOrdDetailBoardFetchCompleteLoadListSuccess,

  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SHOW_WIP_LSP_SLS_ORD_SUMMARY]: lspSlsOrdDetailBoardShowWipLspSlsOrdSummary,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SET_WIP_LSP_SLS_ORD_DIALOG_VISIBLE]: lspSlsOrdDetailBoardSetWipLspSlsOrdDialogVisible,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SHOW_WIP_LSP_SLS_ORD_SUMMARY_LOADING]: lspSlsOrdDetailBoardShowWipLspSlsOrdSummaryLoading,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SHOW_WIP_LSP_SLS_ORD_SUMMARY_SUCCESS]: lspSlsOrdDetailBoardShowWipLspSlsOrdSummarySuccess,

  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SHOW_WIP_OUTB_ORD_SUMMARY]: lspSlsOrdDetailBoardShowWipOutbOrdSummary,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SET_WIP_OUTB_ORD_DIALOG_VISIBLE]: lspSlsOrdDetailBoardSetWipOutbOrdDialogVisible,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SHOW_WIP_OUTB_ORD_SUMMARY_LOADING]: lspSlsOrdDetailBoardShowWipOutbOrdSummaryLoading,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SHOW_WIP_OUTB_ORD_SUMMARY_SUCCESS]: lspSlsOrdDetailBoardShowWipOutbOrdSummarySuccess,

  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SHOW_WIP_INV_DOC_SUMMARY]: lspSlsOrdDetailBoardShowWipInvDocSummary,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SET_WIP_INV_DOC_DIALOG_VISIBLE]: lspSlsOrdDetailBoardSetWipInvDocDialogVisible,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SHOW_WIP_INV_DOC_SUMMARY_LOADING]: lspSlsOrdDetailBoardShowWipInvDocSummaryLoading,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SHOW_WIP_INV_DOC_SUMMARY_SUCCESS]: lspSlsOrdDetailBoardShowWipInvDocSummarySuccess,

  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SHOW_COMPLETE_LOAD_LIST_SUMMARY]: lspSlsOrdDetailBoardShowCompleteLoadListSummary,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SET_COMPLETE_LOAD_LIST_DIALOG_VISIBLE]: lspSlsOrdDetailBoardSetCompleteLoadListDialogVisible,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SHOW_COMPLETE_LOAD_LIST_SUMMARY_LOADING]: lspSlsOrdDetailBoardShowCompleteLoadListSummaryLoading,
  [LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SHOW_COMPLETE_LOAD_LIST_SUMMARY_SUCCESS]: lspSlsOrdDetailBoardShowCompleteLoadListSummarySuccess
});
