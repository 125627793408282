import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob0601ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob0601GoToDocument: ['hdrId'],
  whseJob0601FetchWhseJob0601: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob0601FetchWhseJob0601Loading: ['boolean'],
  whseJob0601FetchWhseJob0601Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  whseJob0601AddSelectedDocuments: ['selectedDocuments'],
  whseJob0601RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob0601SetWorkspaceVisible: ['boolean'],
  whseJob0601CreateWhseJob0601: ['hdrIds', 'toStorageBinId'],
  whseJob0601CreateWhseJob0601Loading: ['boolean'],
  whseJob0601CreateWhseJob0601Success: ['newDocuments']
});

export const WhseJob0601Types = Types;
export default Creators;
