import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob06DetailResetTimestamp: null,
  whseJob06DetailSetHdrId: ['hdrId'],

  whseJob06DetailShowHeader: ['hdrId'],
  whseJob06DetailShowHeaderSuccess: ['documentHeader'],

  whseJob06DetailUpdateHeader: ['formikBag', 'documentHeader'],
  whseJob06DetailUpdateDocumentSuccess: ['documentHeader', 'outbOrdHdrs', 'inbOrdHdrs'],

  whseJob06DetailShowDocumentLoading: ['boolean'],
  whseJob06DetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  whseJob06DetailShowOutbOrdHdrs: ['hdrId'],
  whseJob06DetailShowOutbOrdHdrsSuccess: ['outbOrdHdrs'],

  whseJob06DetailShowInbOrdHdrs: ['hdrId'],
  whseJob06DetailShowInbOrdHdrsSuccess: ['inbOrdHdrs'],

  whseJob06DetailFetchTransportOptions: ['search'],
  whseJob06DetailFetchTransportOptionLoading: ['boolean'],
  whseJob06DetailFetchTransportOptionSuccess: ['options'],
  whseJob06DetailChangeTransport: ['formikBag', 'hdrId', 'itemId'],

  whseJob06DetailFetchDriver01Options: ['search'],
  whseJob06DetailFetchDriver01OptionLoading: ['boolean'],
  whseJob06DetailFetchDriver01OptionSuccess: ['options'],

  whseJob06DetailFetchDeliveryMan01Options: ['search'],
  whseJob06DetailFetchDeliveryMan01OptionLoading: ['boolean'],
  whseJob06DetailFetchDeliveryMan01OptionSuccess: ['options'],

  whseJob06DetailFetchDeliveryMan02Options: ['search'],
  whseJob06DetailFetchDeliveryMan02OptionLoading: ['boolean'],
  whseJob06DetailFetchDeliveryMan02OptionSuccess: ['options'],

  whseJob06DetailFetchWorkerOptions: ['search'],
  whseJob06DetailFetchWorkerOptionLoading: ['boolean'],
  whseJob06DetailFetchWorkerOptionSuccess: ['options'],

  whseJob06DetailFetchLoadListDetailLoading: ['boolean'],

  whseJob06DetailUpOutbOrd: ['hdrId', 'outbOrdHdr'],
  whseJob06DetailDownOutbOrd: ['hdrId', 'outbOrdHdr'],

  whseJob06DetailUpInbOrd: ['hdrId', 'inbOrdHdr'],
  whseJob06DetailDownInbOrd: ['hdrId', 'inbOrdHdr']
});

export const WhseJob06DetailTypes = Types;
export default Creators;
