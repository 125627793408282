import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcptSync01ShowSyncSetting: null,
  gdsRcptSync01ShowSyncSettingLoading: ['boolean'],
  gdsRcptSync01ShowSyncSettingSuccess: ['syncSetting'],
  gdsRcptSync01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  gdsRcptSync01ShowBatchJobStatus: null,
  gdsRcptSync01ShowBatchJobStatusSuccess: ['batchJobStatus'],

  gdsRcptSync01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptSync01GoToDocument: ['hdrId'],
  gdsRcptSync01FetchGdsRcptSync01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptSync01FetchGdsRcptSync01Loading: ['boolean'],
  gdsRcptSync01FetchGdsRcptSync01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  gdsRcptSync01AddSelectedDocuments: ['selectedDocuments'],
  gdsRcptSync01RemoveSelectedDocuments: ['selectedDocuments'],
  gdsRcptSync01SetWorkspaceVisible: ['boolean'],

  gdsRcptSync01Export: ['hdrIds'],
  gdsRcptSync01ExportSuccess: null,
  gdsRcptSync01ExportLoading: ['boolean'],

  gdsRcptSync01SetExpandedRows: ['expandedRows']
});

export const GdsRcptSync01Types = Types;
export default Creators;
