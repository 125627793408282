import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  inbOrd01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrd01GoToDocument: ['hdrId'],
  inbOrd01NewDocument: null,
  inbOrd01FetchInbOrd01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrd01FetchInbOrd01Loading: ['boolean'],
  inbOrd01FetchInbOrd01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  inbOrd01AddSelectedDocuments: ['selectedDocuments'],
  inbOrd01RemoveSelectedDocuments: ['selectedDocuments'],
  inbOrd01SetWorkspaceVisible: ['boolean'],
  inbOrd01CreateInbOrd01: ['hdrIds'],
  inbOrd01CreateInbOrd01Loading: ['boolean'],
  inbOrd01CreateInbOrd01Success: ['newDocuments'],
  inbOrd01TransitionToDraft: ['hdrIds'],

  inbOrd01SetExpandedRows: ['expandedRows'],

  inbOrd01SetSelectedDocument: ['document'],
  inbOrd01SetDetailVisible: ['boolean'],
  inbOrd01ShowDetails: ['hdrId'],
  inbOrd01ShowDetailsSuccess: ['documentDetails'],
  inbOrd01CloseDocument: ['hdrId'],
  inbOrd01CreateInbOrd01Partial: ['formikBag', 'hdrId', 'documentDetails'],
  inbOrd01CreateInbOrd01PartialSuccess: ['newDocument']
});

export const InbOrd01Types = Types;
export default Creators;
