/**
 * The initial values for the redux state.
 */
export default {
  wipPickListTimestamp: 0,
  wipPickLists: [],
  wipPickListIsLoading: false,
  wipPickListSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  wipPickListFilters: {},
  wipPickListPageSize: '20',
  wipPickListCurrentPage: 1,
  wipPickListLastPage: 10,
  wipPickListTotal: 0,

  wipLoadListTimestamp: 0,
  wipLoadLists: [],
  wipLoadListIsLoading: false,
  wipLoadListSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  wipLoadListFilters: {},
  wipLoadListPageSize: '20',
  wipLoadListCurrentPage: 1,
  wipLoadListLastPage: 10,
  wipLoadListTotal: 0,

  wipWhseJobTimestamp: 0,
  wipWhseJobs: [],
  wipWhseJobIsLoading: false,
  wipWhseJobSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  wipWhseJobFilters: {},
  wipWhseJobPageSize: '20',
  wipWhseJobCurrentPage: 1,
  wipWhseJobLastPage: 10,
  wipWhseJobTotal: 0,

  completeLoadListTimestamp: 0,
  completeLoadLists: [],
  completeLoadListIsLoading: false,
  completeLoadListSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  completeLoadListFilters: {},
  completeLoadListPageSize: '20',
  completeLoadListCurrentPage: 1,
  completeLoadListLastPage: 10,
  completeLoadListTotal: 0,

  wipPickListDialogIsVisible: false,
  wipPickListDocId: 0,
  wipPickListDocument: { doc_code: '', doc_status: 3 },
  wipPickListDocumentIsLoading: false,

  wipLoadListDialogIsVisible: false,
  wipLoadListDocId: 0,
  wipLoadListDocument: { doc_code: '', doc_status: 3 },
  wipLoadListDocumentIsLoading: false,

  wipWhseJobDialogIsVisible: false,
  wipWhseJobDocId: 0,
  wipWhseJobDocument: { doc_code: '', doc_status: 3 },
  wipWhseJobDocumentIsLoading: false,

  completeLoadListDialogIsVisible: false,
  completeLoadListDocId: 0,
  completeLoadListDocument: { doc_code: '', doc_status: 3 },
  completeLoadListDocumentIsLoading: false
};
