import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
// import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import CycleCount02DetailActions, {
  CycleCount02DetailTypes
} from '../Stores/CycleCount02Detail/Actions';

const getAppStore = state => state.app;

const getCycleCount02DetailStore = state => state.cycleCount02Detail;

export function* cycleCount02DetailShowDetails({ hdrId, currentPage, sorts, filters, pageSize }) {
  try {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `cycleCount/showRecountDetails/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        CycleCount02DetailActions.cycleCount02DetailShowDetailsSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(false));
  }
}

export function* cycleCount02DetailAutoConfirm({ hdrId }) {
  try {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `cycleCount/autoConfirmDetails/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      /*
      const cycleCount02Detail = yield select(getCycleCount02DetailStore);

      const { documentDetails: oldDocumentDetails } = cycleCount02Detail;

      const { document_details: retDocumentDetails } = result.data;

      const storageBinId = retDocumentDetails.reduce((lastObj, data) => {
        return data.storage_bin_id;
      }, {});

      const processedStorageBinDetails = oldDocumentDetails.map(oldStorageBinDetail => {
        if (oldStorageBinDetail.storage_bin_id === storageBinId) {
          const storageBinStrPhysicalCountStatusHash = {};

          const processedDetails = oldStorageBinDetail.details.map(oldDetail => {
            let a = -1;
            const retDocumentDetail = retDocumentDetails.reduce((lastObj, data, index) => {
              if (oldDetail.id === data.id) {
                a = index;
                return data;
              }
              return lastObj;
            }, {});

            if (a >= 0) {
              storageBinStrPhysicalCountStatusHash[retDocumentDetail.str_physical_count_status] =
                retDocumentDetail.str_physical_count_status;

              return {
                ...oldDetail,
                physical_count_status: retDocumentDetail.physical_count_status,
                str_physical_count_status: retDocumentDetail.str_physical_count_status
              };
            }
            storageBinStrPhysicalCountStatusHash[oldDetail.str_physical_count_status] =
              oldDetail.str_physical_count_status;

            return { ...oldDetail };
          });

          return {
            ...oldStorageBinDetail,
            str_physical_count_statuses: Object.keys(storageBinStrPhysicalCountStatusHash),
            details: processedDetails
          };
        }

        return { ...oldStorageBinDetail };
      });
      */

      yield put(CycleCount02DetailActions.cycleCount02DetailResetTimestamp());
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(false));
  }
}

export function* cycleCount02DetailAutoGroupConfirm({ hdrId }) {
  try {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `cycleCount/autoGroupConfirmDetails/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      /*
      const cycleCount02Detail = yield select(getCycleCount02DetailStore);

      const { documentDetails: oldDocumentDetails } = cycleCount02Detail;

      const { document_details: retDocumentDetails } = result.data;

      const storageBinId = retDocumentDetails.reduce((lastObj, data) => {
        return data.storage_bin_id;
      }, {});

      const processedStorageBinDetails = oldDocumentDetails.map(oldStorageBinDetail => {
        if (oldStorageBinDetail.storage_bin_id === storageBinId) {
          const storageBinStrPhysicalCountStatusHash = {};

          const processedDetails = oldStorageBinDetail.details.map(oldDetail => {
            let a = -1;
            const retDocumentDetail = retDocumentDetails.reduce((lastObj, data, index) => {
              if (oldDetail.id === data.id) {
                a = index;
                return data;
              }
              return lastObj;
            }, {});

            if (a >= 0) {
              storageBinStrPhysicalCountStatusHash[retDocumentDetail.str_physical_count_status] =
                retDocumentDetail.str_physical_count_status;

              return {
                ...oldDetail,
                physical_count_status: retDocumentDetail.physical_count_status,
                str_physical_count_status: retDocumentDetail.str_physical_count_status
              };
            }
            storageBinStrPhysicalCountStatusHash[oldDetail.str_physical_count_status] =
              oldDetail.str_physical_count_status;

            return { ...oldDetail };
          });

          return {
            ...oldStorageBinDetail,
            str_physical_count_statuses: Object.keys(storageBinStrPhysicalCountStatusHash),
            details: processedDetails
          };
        }

        return { ...oldStorageBinDetail };
      });
      */

      yield put(CycleCount02DetailActions.cycleCount02DetailResetTimestamp());
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(false));
  }
}

export function* cycleCount02DetailUpdateRecount({ dtlId, action }) {
  try {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `cycleCount/updateRecountDetails/${dtlId}/${action}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      const cycleCount02Detail = yield select(getCycleCount02DetailStore);

      const { documentDetails: oldDocumentDetails } = cycleCount02Detail;

      const { document_details: retDocumentDetails } = result.data;

      const storageBinId = retDocumentDetails.reduce((lastObj, data) => {
        return data.storage_bin_id;
      }, {});

      const processedStorageBinDetails = oldDocumentDetails.map(oldStorageBinDetail => {
        if (oldStorageBinDetail.storage_bin_id === storageBinId) {
          const storageBinStrPhysicalCountStatusHash = {};

          const processedDetails = oldStorageBinDetail.details.map(oldDetail => {
            let a = -1;
            const retDocumentDetail = retDocumentDetails.reduce((lastObj, data, index) => {
              if (oldDetail.id === data.id) {
                a = index;
                return data;
              }
              return lastObj;
            }, {});

            if (a >= 0) {
              storageBinStrPhysicalCountStatusHash[retDocumentDetail.str_physical_count_status] =
                retDocumentDetail.str_physical_count_status;

              return {
                ...oldDetail,
                physical_count_status: retDocumentDetail.physical_count_status,
                str_physical_count_status: retDocumentDetail.str_physical_count_status
              };
            }
            storageBinStrPhysicalCountStatusHash[oldDetail.str_physical_count_status] =
              oldDetail.str_physical_count_status;

            return { ...oldDetail };
          });

          return {
            ...oldStorageBinDetail,
            str_physical_count_statuses: Object.keys(storageBinStrPhysicalCountStatusHash),
            details: processedDetails
          };
        }

        return { ...oldStorageBinDetail };
      });

      yield put(
        CycleCount02DetailActions.cycleCount02DetailShowDetailsSuccess(processedStorageBinDetails)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(false));
  }
}

export function* cycleCount02DetailCreateCycleCount02({ groupCount, hdrId }) {
  try {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);

    const postData = {
      siteFlowId: app.curSiteFlowId,
      ids: [hdrId],
      data: {
        groupCount
      }
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `cycleCount/createProcess/CYCLE_COUNT_02`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(CycleCount02DetailActions.cycleCount02DetailCreateCycleCount02Success(result.data));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(false));
  }
}

export function* cycleCount02DetailDownload({ hdrId }) {
  try {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);

    const getData = {};

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `cycleCount/downloadDetails/${hdrId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, `CYCLE_COUNT_${hdrId}.XLSX`);
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CycleCount02DetailActions.cycleCount02DetailShowDocumentLoading(false));
  }
}

export const saga = [
  takeLatest(
    CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_SHOW_DETAILS,
    cycleCount02DetailShowDetails
  ),
  takeLatest(
    CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_UPDATE_RECOUNT,
    cycleCount02DetailUpdateRecount
  ),
  takeLatest(
    CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_AUTO_CONFIRM,
    cycleCount02DetailAutoConfirm
  ),
  takeLatest(
    CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_AUTO_GROUP_CONFIRM,
    cycleCount02DetailAutoGroupConfirm
  ),
  takeLatest(
    CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_CREATE_CYCLE_COUNT02,
    cycleCount02DetailCreateCycleCount02
  ),
  takeLatest(CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_DOWNLOAD, cycleCount02DetailDownload)
];
