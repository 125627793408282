import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  bizPartnerDetailResetModelTimestamp: null,
  bizPartnerDetailSetResId: ['resId'],

  bizPartnerDetailInitModel: ['debtorId', 'creditorId'],
  bizPartnerDetailShowModel: ['resId'],
  bizPartnerDetailShowModelSuccess: ['model'],

  bizPartnerDetailUpdateModel: ['formikBag', 'model'],
  bizPartnerDetailCreateModel: ['formikBag', 'model'],
  bizPartnerDetailUpdateModelSuccess: ['model'],

  bizPartnerDetailShowModelLoading: ['boolean']
});

export const BizPartnerDetailTypes = Types;
export default Creators;
