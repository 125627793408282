import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  companyList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  companyList01NewResource: null,
  companyList01GoToResource: ['resId'],
  companyList01FetchCompanyList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  companyList01FetchCompanyList01Loading: ['boolean'],
  companyList01FetchCompanyList01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ]
});

export const CompanyList01Types = Types;
export default Creators;
