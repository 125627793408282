import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsRtnProcessFetchSlsRtnProcess: null,
  slsRtnProcessFetchSlsRtnProcessLoading: ['boolean'],
  slsRtnProcessFetchSlsRtnProcessSuccess: ['process']
});

export const SlsRtnProcessTypes = Types;
export default Creators;
