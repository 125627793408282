/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { UserDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const userDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const userDetailSetResId = (state, { resId }) => ({
  ...state,
  timestamp: Date.now(),
  modelIsLoading: false,
  resId
});

export const userDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const userDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const userDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const userDetailShowUserSiteFlowLoading = (state, { boolean }) => ({
  ...state,
  userSiteFlowIsLoading: boolean
});

export const userDetailShowUserSiteFlowSuccess = (state, { userSiteFlows }) => ({
  ...state,
  userSiteFlows
});

export const userDetailFetchSiteFlowOptionLoading = (state, { boolean }) => ({
  ...state,
  siteFlowOptionIsLoading: boolean
});

export const userDetailFetchSiteFlowOptionSuccess = (state, { options }) => ({
  ...state,
  siteFlowOptions: options
});

export const userDetailSetSiteFlowOption = (state, { option }) => ({
  ...state,
  siteFlowOption: option
});

export const userDetailShowUserDivisionLoading = (state, { boolean }) => ({
  ...state,
  userDivisionIsLoading: boolean
});

export const userDetailShowUserDivisionSuccess = (state, { userDivisions }) => ({
  ...state,
  userDivisions
});

export const userDetailFetchDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  divisionOptionIsLoading: boolean
});

export const userDetailFetchDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  divisionOptions: options
});

export const userDetailSetDivisionOption = (state, { option }) => ({
  ...state,
  divisionOption: option
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [UserDetailTypes.USER_DETAIL_RESET_TIMESTAMP]: userDetailResetTimestamp,
  [UserDetailTypes.USER_DETAIL_SET_RES_ID]: userDetailSetResId,
  [UserDetailTypes.USER_DETAIL_SHOW_MODEL_LOADING]: userDetailShowModelLoading,
  [UserDetailTypes.USER_DETAIL_SHOW_MODEL_SUCCESS]: userDetailShowModelSuccess,
  [UserDetailTypes.USER_DETAIL_UPDATE_MODEL_SUCCESS]: userDetailUpdateModelSuccess,

  [UserDetailTypes.USER_DETAIL_SHOW_USER_SITE_FLOW_LOADING]: userDetailShowUserSiteFlowLoading,
  [UserDetailTypes.USER_DETAIL_SHOW_USER_SITE_FLOW_SUCCESS]: userDetailShowUserSiteFlowSuccess,

  [UserDetailTypes.USER_DETAIL_FETCH_SITE_FLOW_OPTION_LOADING]: userDetailFetchSiteFlowOptionLoading,
  [UserDetailTypes.USER_DETAIL_FETCH_SITE_FLOW_OPTION_SUCCESS]: userDetailFetchSiteFlowOptionSuccess,
  [UserDetailTypes.USER_DETAIL_SET_SITE_FLOW_OPTION]: userDetailSetSiteFlowOption,

  [UserDetailTypes.USER_DETAIL_SHOW_USER_DIVISION_LOADING]: userDetailShowUserDivisionLoading,
  [UserDetailTypes.USER_DETAIL_SHOW_USER_DIVISION_SUCCESS]: userDetailShowUserDivisionSuccess,

  [UserDetailTypes.USER_DETAIL_FETCH_DIVISION_OPTION_LOADING]: userDetailFetchDivisionOptionLoading,
  [UserDetailTypes.USER_DETAIL_FETCH_DIVISION_OPTION_SUCCESS]: userDetailFetchDivisionOptionSuccess,
  [UserDetailTypes.USER_DETAIL_SET_DIVISION_OPTION]: userDetailSetDivisionOption
});
