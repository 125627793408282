/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LocationDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const locationDetailResetModelTimestamp = state => ({
  ...state,
  modelTimestamp: Date.now()
});

export const locationDetailSetResId = (state, { resId }) => ({
  ...state,
  modelTimestamp: Date.now(),
  resId
});

export const locationDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const locationDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const locationDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LocationDetailTypes.LOCATION_DETAIL_RESET_MODEL_TIMESTAMP]: locationDetailResetModelTimestamp,
  [LocationDetailTypes.LOCATION_DETAIL_SET_RES_ID]: locationDetailSetResId,
  [LocationDetailTypes.LOCATION_DETAIL_SHOW_MODEL_LOADING]: locationDetailShowModelLoading,
  [LocationDetailTypes.LOCATION_DETAIL_SHOW_MODEL_SUCCESS]: locationDetailShowModelSuccess,
  [LocationDetailTypes.LOCATION_DETAIL_UPDATE_MODEL_SUCCESS]: locationDetailUpdateModelSuccess
});
