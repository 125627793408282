import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  trfToStoreProcessFetchTrfToStoreProcess: null,
  trfToStoreProcessFetchTrfToStoreProcessLoading: ['boolean'],
  trfToStoreProcessFetchTrfToStoreProcessSuccess: ['process']
});

export const TrfToStoreProcessTypes = Types;
export default Creators;
