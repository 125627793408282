import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import PrfDel01Actions, { PrfDel01Types } from '../Stores/PrfDel01/Actions';

const getAppStore = state => state.app;

const getPrfDel01Store = state => state.prfDel01;

export function* prfDel01ScanBarcode({ value }) {
  try {
    yield put(PrfDel01Actions.prfDel01FetchPrfDel01Loading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      value
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `prfDel/addDetailsByBarcode`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const prfDel01 = yield select(getPrfDel01Store);

      const { outbOrdHdrs: oldOutbOrdHdrs } = prfDel01;
      const { outb_ord_hdrs: retOutbOrdHdrs } = result.data;

      // remove outbOrdHdrs if existed
      const filterOutbOrdHdrs = oldOutbOrdHdrs.filter(oldOutbOrdHdr => {
        const foundHdrId = retOutbOrdHdrs.reduce((lastId, data, index) => {
          if (oldOutbOrdHdr.id === data.id) {
            retOutbOrdHdrs.splice(index, 1);
            return data.id;
          }
          return lastId;
        }, -1);

        return foundHdrId === -1;
      });

      // append the remaining retOutbOrdHdrs
      const newOutbOrdHdrs = [];
      filterOutbOrdHdrs.forEach(data => {
        newOutbOrdHdrs.push(data);
      });
      retOutbOrdHdrs.forEach(data => {
        newOutbOrdHdrs.push(data);
      });

      yield put(PrfDel01Actions.prfDel01ScanBarcodeSuccess(newOutbOrdHdrs));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PrfDel01Actions.prfDel01FetchPrfDel01Loading(false));
  }
}

export function* prfDel01SearchDocument({ search }) {
  if (search.length < 5) {
    return;
  }
  try {
    yield put(PrfDel01Actions.prfDel01SearchDocumentLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `prfDel/searchDocument`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: `${d.res_type}${d.id}`,
        label: `${d.doc_code} ${d.doc_date} ${d.ref_code_01}`,
        docCode: d.doc_code,
        docDate: d.doc_date,
        refCode01: d.ref_code_01
      }));

      yield put(PrfDel01Actions.prfDel01SearchDocumentSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PrfDel01Actions.prfDel01SearchDocumentLoading(false));
  }
}

export function* prfDel01UpOutbOrd({ outbOrdHdr }) {
  yield put(PrfDel01Actions.prfDel01FetchPrfDel01Loading(true));

  const prfDel01 = yield select(getPrfDel01Store);
  const { outbOrdHdrs } = prfDel01;

  const oldIndex = outbOrdHdrs.indexOf(outbOrdHdr);
  const newIndex = oldIndex - 1;

  if (newIndex >= 0) {
    outbOrdHdrs.splice(newIndex, 0, outbOrdHdrs.splice(oldIndex, 1)[0]);
    yield put(PrfDel01Actions.prfDel01UpdateOutbOrds(outbOrdHdrs));
  }

  yield put(PrfDel01Actions.prfDel01FetchPrfDel01Loading(false));
}

export function* prfDel01DownOutbOrd({ outbOrdHdr }) {
  yield put(PrfDel01Actions.prfDel01FetchPrfDel01Loading(true));

  const prfDel01 = yield select(getPrfDel01Store);
  const { outbOrdHdrs } = prfDel01;

  const oldIndex = outbOrdHdrs.indexOf(outbOrdHdr);
  const newIndex = oldIndex + 1;

  if (newIndex < outbOrdHdrs.length) {
    outbOrdHdrs.splice(newIndex, 0, outbOrdHdrs.splice(oldIndex, 1)[0]);
    yield put(PrfDel01Actions.prfDel01UpdateOutbOrds(outbOrdHdrs));
  }

  yield put(PrfDel01Actions.prfDel01FetchPrfDel01Loading(false));
}

export function* prfDel01CreatePrfDel01({ formikBag, formDetail, outbOrdHdrs: oldOutbOrdHdrs }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    let lineNo = 1;
    const outbOrdHdrs = oldOutbOrdHdrs.map(d => {
      lineNo += 1;
      return {
        line_no: lineNo,
        id: d.id
      };
    });

    const postData = {
      data: formDetail,
      outbOrdHdrs
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'prfDel/createProcess/PRF_DEL_01',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(PrfDel01Actions.prfDel01CreatePrfDel01Success(result.data));
      formikBag.resetForm();

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export const saga = [
  takeLatest(PrfDel01Types.PRF_DEL01_SCAN_BARCODE, prfDel01ScanBarcode),
  takeLatest(PrfDel01Types.PRF_DEL01_SEARCH_DOCUMENT, prfDel01SearchDocument),
  takeLatest(PrfDel01Types.PRF_DEL01_UP_OUTB_ORD, prfDel01UpOutbOrd),
  takeLatest(PrfDel01Types.PRF_DEL01_DOWN_OUTB_ORD, prfDel01DownOutbOrd),
  takeLatest(PrfDel01Types.PRF_DEL01_CREATE_PRF_DEL01, prfDel01CreatePrfDel01)
];
