import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { ArrowLeftOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import PrincipalDetailActions from '../../Stores/PrincipalDetail/Actions';
import AppActions from '../../Stores/App/Actions';

class DeliveryPointTable extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { resId, resetTimestamp, showDeliveryPointSuccess } = this.props;

    if (resId > 0) {
      resetTimestamp();
    } else {
      showDeliveryPointSuccess([], 1, 1, 0, 20);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      resId,
      resetTimestamp,
      showDeliveryPoints,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('resId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      showDeliveryPoints(resId, currentPage, sorts, filters, pageSize);
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl, sorts, filters, goToDeliveryPoint } = this.props;

    return [
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'delivery_point' }),
        // sort field
        dataIndex: 'code',
        ...AntDesignTable.getColumnSortProps(sorts, 'code'),
        // filter field
        key: 'code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'delivery_point' }),
          'code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.code}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'ref_code' }),
        // sort field
        dataIndex: 'ref_code_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'ref_code_01'),
        // filter field
        key: 'ref_code_01',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'ref_code' }),
          'ref_code_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.ref_code_01}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'name' }),
        // sort field
        dataIndex: 'company_name',
        ...AntDesignTable.getColumnSortProps(sorts, 'company_name'),
        // filter field
        key: 'company_name',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'name' }),
          'company_name',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.company_name_01}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'area' }),
        // sort field
        dataIndex: 'area_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'area_code'),
        // filter field
        key: 'area_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'area' }),
          'area_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            <b>{record.area_code}</b>
          </>
        )
      },
      {
        width: 130,
        align: 'left',
        title: intl.formatMessage({ id: 'category' }),
        // sort field
        dataIndex: 'debtor_group_01_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'debtor_group_01_code'),
        // filter field
        key: 'debtor_group_01_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'category' }),
          'debtor_group_01_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.debtor_group_01_code}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'chain' }),
        // sort field
        dataIndex: 'debtor_group_02_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'debtor_group_02_code'),
        // filter field
        key: 'debtor_group_02_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'chain' }),
          'debtor_group_02_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.debtor_group_02_code}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'channel' }),
        // sort field
        dataIndex: 'debtor_group_03_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'debtor_group_03_code'),
        // filter field
        key: 'debtor_group_03_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'channel' }),
          'debtor_group_03_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.debtor_group_03_code}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'unit_no' }),
        // sort field
        dataIndex: 'unit_no',
        ...AntDesignTable.getColumnSortProps(sorts, 'unit_no'),
        // filter field
        key: 'unit_no',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'unit_no' }),
          'unit_no',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.unit_no}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'building' }),
        // sort field
        dataIndex: 'building_name',
        ...AntDesignTable.getColumnSortProps(sorts, 'building_name'),
        // filter field
        key: 'building_name',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'building' }),
          'building_name',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.building_name}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'street_name' }),
        // sort field
        dataIndex: 'street_name',
        ...AntDesignTable.getColumnSortProps(sorts, 'street_name'),
        // filter field
        key: 'street_name',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'street_name' }),
          'street_name',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.street_name}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'district_01' }),
        // sort field
        dataIndex: 'district_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'district_01'),
        // filter field
        key: 'district_01',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'district_01' }),
          'district_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.district_01}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'district_02' }),
        // sort field
        dataIndex: 'district_02',
        ...AntDesignTable.getColumnSortProps(sorts, 'district_02'),
        // filter field
        key: 'district_02',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'district_02' }),
          'district_02',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.district_02}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'postcode' }),
        // sort field
        dataIndex: 'postcode',
        ...AntDesignTable.getColumnSortProps(sorts, 'postcode'),
        // filter field
        key: 'postcode',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'postcode' }),
          'postcode',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.postcode}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'state_name' }),
        // sort field
        dataIndex: 'state_name',
        ...AntDesignTable.getColumnSortProps(sorts, 'state_name'),
        // filter field
        key: 'state_name',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'state_name' }),
          'state_name',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.state_name}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'country_name' }),
        // sort field
        dataIndex: 'country_name',
        ...AntDesignTable.getColumnSortProps(sorts, 'country_name'),
        // filter field
        key: 'country_name',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'country_name' }),
          'country_name',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.country_name}</>
      },
      {
        width: 50,
        fixed: 'right',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Button
              type="dashed"
              icon={<EditOutlined />}
              onClick={() => goToDeliveryPoint(record.id)}
            />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  render() {
    const {
      intl,
      historyGoBack,
      resId,
      resetTimestamp,
      newDeliveryPoint,
      currentPage,
      pageSize,
      total,
      deliveryPoints,
      modelIsLoading
    } = this.props;

    return (
      <>
        <Table
          // title={() => intl.formatMessage({ id: 'deliveryPoints' })}
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={deliveryPoints}
          loading={modelIsLoading}
          bordered
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={modelIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
                <Col span={5}>
                  <Button
                    type="primary"
                    disabled={resId === 0}
                    loading={modelIsLoading}
                    onClick={() => newDeliveryPoint(resId)}
                    // icon={<PlusOutlined />}
                  >
                    {intl.formatMessage({ id: 'new' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 900 }}
        />
        <Button
          type="primary"
          // disabled={!isValid}
          loading={modelIsLoading}
          onClick={historyGoBack}
          icon={<ArrowLeftOutlined />}
        >
          {intl.formatMessage({
            id: 'back'
          })}
        </Button>
      </>
    );
  }
}

DeliveryPointTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDeliveryPoint: PropTypes.func,
  newDeliveryPoint: PropTypes.func,
  showDeliveryPoints: PropTypes.func,
  historyGoBack: PropTypes.func,
  deliveryPoints: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  modelIsLoading: PropTypes.bool,

  showDeliveryPointSuccess: PropTypes.func,

  resId: PropTypes.number
};

DeliveryPointTable.defaultProps = {
  intl: {},

  resetTimestamp() {},
  goToDeliveryPoint() {},
  newDeliveryPoint() {},
  showDeliveryPoints() {},
  historyGoBack() {},
  deliveryPoints: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  modelIsLoading: false,

  showDeliveryPointSuccess() {},

  resId: 0
};

const mapStateToProps = state => ({
  timestamp: state.principalDetail.deliveryPointTimestamp,
  resId: state.principalDetail.resId,
  deliveryPoints: state.principalDetail.deliveryPoints,

  sorts: state.principalDetail.deliveryPointSorts,
  filters: state.principalDetail.deliveryPointFilters,

  currentPage: state.principalDetail.deliveryPointCurrentPage,
  pageSize: state.principalDetail.deliveryPointPageSize,
  total: state.principalDetail.deliveryPointTotal,
  modelIsLoading: state.principalDetail.modelIsLoading
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      PrincipalDetailActions.principalDetailResetDeliveryPointTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  goToDeliveryPoint: deliveryPointId =>
    dispatch(PrincipalDetailActions.principalDetailGoToDeliveryPoint(deliveryPointId)),
  newDeliveryPoint: debtorId =>
    dispatch(PrincipalDetailActions.principalDetailNewDeliveryPoint(debtorId)),
  showDeliveryPoints: (resId, currentPage, sorts, filters, pageSize) =>
    dispatch(
      PrincipalDetailActions.principalDetailShowDeliveryPoints(
        resId,
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),

  showDeliveryPointSuccess: (deliveryPoints, currentPage, lastPage, total, pageSize) =>
    dispatch(
      PrincipalDetailActions.principalDetailShowDeliveryPointSuccess(
        deliveryPoints,
        currentPage,
        lastPage,
        total,
        pageSize
      )
    ),

  historyGoBack: () => dispatch(AppActions.appHistoryGoBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DeliveryPointTable));
