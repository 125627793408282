import { all } from 'redux-saga/effects';

import { saga as AdvShipDetailSaga } from './AdvShipDetailSaga';
import { saga as AdvShipExcel01Saga } from './AdvShipExcel01Saga';
import { saga as AdvShipFServ01Saga } from './AdvShipFServ01Saga';
import { saga as AdvShipIndexSaga } from './AdvShipIndexSaga';
import { saga as AdvShipSync01Saga } from './AdvShipSync01Saga';
import { saga as AdvShipSync02Saga } from './AdvShipSync02Saga';
import { saga as AdvShipFServ02Saga } from './AdvShipFServ02Saga';
import { saga as AppSaga } from './AppSaga';
import { saga as AreaDetailSaga } from './AreaDetailSaga';
import { saga as AreaList01Saga } from './AreaList01Saga';
import { saga as AreaProcessSaga } from './AreaProcessSaga';
import { saga as AuditRes01Saga } from './AuditRes01Saga';
import { saga as AuditUser01Saga } from './AuditUser01Saga';
import { saga as BinTrf01Saga } from './BinTrf01Saga';
import { saga as BinTrf02Saga } from './BinTrf02Saga';
import { saga as BinTrf03Saga } from './BinTrf03Saga';
import { saga as BinTrfDetailSaga } from './BinTrfDetailSaga';
import { saga as BinTrfIndexSaga } from './BinTrfIndexSaga';
import { saga as BinTrfProcessSaga } from './BinTrfProcessSaga';
import { saga as BizPartnerDetailSaga } from './BizPartnerDetailSaga';
import { saga as CompanyDetailSaga } from './CompanyDetailSaga';
import { saga as CompanyList01Saga } from './CompanyList01Saga';
import { saga as CompanyProcessSaga } from './CompanyProcessSaga';
import { saga as CountAdj01Saga } from './CountAdj01Saga';
import { saga as CountAdjAnalysisReportSaga } from './CountAdjAnalysisReportSaga';
import { saga as CountAdjDetailSaga } from './CountAdjDetailSaga';
import { saga as CountAdjIndexSaga } from './CountAdjIndexSaga';
import { saga as CountAdjFServ01Saga } from './CountAdjFServ01Saga';
import { saga as CountAdjFServ02Saga } from './CountAdjFServ02Saga';
import { saga as CountAdjReasonDetailSaga } from './CountAdjReasonDetailSaga';
import { saga as CountAdjReasonList01Saga } from './CountAdjReasonList01Saga';
import { saga as CountAdjReasonProcessSaga } from './CountAdjReasonProcessSaga';
import { saga as CountAdjSync01Saga } from './CountAdjSync01Saga';
import { saga as CountAdjSync02Saga } from './CountAdjSync02Saga';
import { saga as CreditorDetailSaga } from './CreditorDetailSaga';
import { saga as CreditorList01Saga } from './CreditorList01Saga';
import { saga as CreditorProcessSaga } from './CreditorProcessSaga';
import { saga as CycleCount02DetailSaga } from './CycleCount02DetailSaga';
import { saga as CycleCount02Saga } from './CycleCount02Saga';
import { saga as CycleCount03Saga } from './CycleCount03Saga';
import { saga as CycleCountAnalysisReportSaga } from './CycleCountAnalysisReportSaga';
import { saga as CycleCountDetailSaga } from './CycleCountDetailSaga';
import { saga as CycleCountIndexSaga } from './CycleCountIndexSaga';
import { saga as DailyStockMovementReportSaga } from './DailyStockMovementReportSaga';
import { saga as DebtorDetailSaga } from './DebtorDetailSaga';
import { saga as DebtorList01Saga } from './DebtorList01Saga';
import { saga as DebtorProcessSaga } from './DebtorProcessSaga';
import { saga as DeliveryPointDetailSaga } from './DeliveryPointDetailSaga';
import { saga as DeliveryPointList01Saga } from './DeliveryPointList01Saga';
import { saga as DeliveryPointProcessSaga } from './DeliveryPointProcessSaga';
import { saga as DelOrdDetailSaga } from './DelOrdDetailSaga';
import { saga as DelOrdFServ01Saga } from './DelOrdFServ01Saga';
import { saga as DelOrdIndexSaga } from './DelOrdIndexSaga';
import { saga as DelOrdSync01Saga } from './DelOrdSync01Saga';
import { saga as DivisionDetailSaga } from './DivisionDetailSaga';
import { saga as DivisionList01Saga } from './DivisionList01Saga';
import { saga as DivisionProcessSaga } from './DivisionProcessSaga';
import { saga as DocTypeDocNoIndexSaga } from './DocTypeDocNoIndexSaga';
import { saga as FailDel01Saga } from './FailDel01Saga';
import { saga as GdsDelProcessSaga } from './GdsDelProcessSaga';
import { saga as GdsIss01Saga } from './GdsIss01Saga';
import { saga as GdsIss0101Saga } from './GdsIss0101Saga';
import { saga as GdsIssDetailSaga } from './GdsIssDetailSaga';
import { saga as GdsIssFServ01Saga } from './GdsIssFServ01Saga';
import { saga as GdsIssSync01Saga } from './GdsIssSync01Saga';
import { saga as GdsIssIndexSaga } from './GdsIssIndexSaga';
import { saga as GdsRcptProcessSaga } from './GdsRcptProcessSaga';
import { saga as GdsRcpt0101Saga } from './GdsRcpt0101Saga';
import { saga as GdsRcpt0201Saga } from './GdsRcpt0201Saga';
import { saga as GdsRcpt01Saga } from './GdsRcpt01Saga';
import { saga as GdsRcpt02Saga } from './GdsRcpt02Saga';
import { saga as GdsRcptDetailSaga } from './GdsRcptDetailSaga';
import { saga as GdsRcptDetailBoardSaga } from './GdsRcptDetailBoardSaga';
import { saga as GdsRcptRtnDetailBoardSaga } from './GdsRcptRtnDetailBoardSaga';
import { saga as GdsRcptFServ01Saga } from './GdsRcptFServ01Saga';
import { saga as GdsRcptFServ02Saga } from './GdsRcptFServ02Saga';
import { saga as GdsRcptFServ03Saga } from './GdsRcptFServ03Saga';
import { saga as GdsRcptIndexSaga } from './GdsRcptIndexSaga';
import { saga as GdsRcptRtnIndexSaga } from './GdsRcptRtnIndexSaga';
import { saga as GdsRcptSync01Saga } from './GdsRcptSync01Saga';
import { saga as GdsRcptTrackingReportSaga } from './GdsRcptTrackingReportSaga';
import { saga as InbOrd01Saga } from './InbOrd01Saga';
import { saga as InbOrd02Saga } from './InbOrd02Saga';
import { saga as InbOrd03Saga } from './InbOrd03Saga';
import { saga as InbOrd04Saga } from './InbOrd04Saga';
import { saga as InbOrd05Saga } from './InbOrd05Saga';
import { saga as InbOrd06Saga } from './InbOrd06Saga';
import { saga as InbOrd07Saga } from './InbOrd07Saga';
import { saga as InbOrdDetailSaga } from './InbOrdDetailSaga';
import { saga as InbOrdIndexSaga } from './InbOrdIndexSaga';
import { saga as InvAuditProcessSaga } from './InvAuditProcessSaga';
import { saga as InvDoc0101Saga } from './InvDoc0101Saga';
import { saga as InvDoc0102Saga } from './InvDoc0102Saga';
import { saga as InvDoc01Saga } from './InvDoc01Saga';
import { saga as InvDoc02Saga } from './InvDoc02Saga';
import { saga as InvDoc03Saga } from './InvDoc03Saga';
import { saga as ItemBatchDetailSaga } from './ItemBatchDetailSaga';
import { saga as ItemBatchExcel01Saga } from './ItemBatchExcel01Saga';
import { saga as ItemDetailSaga } from './ItemDetailSaga';
import { saga as ItemExcel01Saga } from './ItemExcel01Saga';
import { saga as ItemManufacturerExcelSaga } from './ItemManufacturerExcelSaga';
import { saga as ItemList01Saga } from './ItemList01Saga';
import { saga as ItemList02Saga } from './ItemList02Saga';
import { saga as ItemSync01Saga } from './ItemSync01Saga';
import { saga as ItemSync0101Saga } from './ItemSync0101Saga';
import { saga as ItemProcessSaga } from './ItemProcessSaga';
import { saga as ItemManufactureProcessSaga } from './ItemManufactureProcessSaga';
import { saga as ManufactureItemListSaga } from './ManufactureItemListSaga';
import { saga as UomListSaga } from './UomListSaga';
import { saga as UomDetailSaga } from './UomDetailSaga';
import { saga as LayerFarmProcessSaga } from './LayerFarmProcessSaga';
import { saga as LayerFarmListSaga } from './LayerFarmListSaga';
import { saga as LayerFarmExcelSaga } from './LayerFarmExcelSaga';
import { saga as LocationDetailSaga } from './LocationDetailSaga';
import { saga as LocationList01Saga } from './LocationList01Saga';
import { saga as LoadList01Saga } from './LoadList01Saga';
import { saga as LoadList0101Saga } from './LoadList0101Saga';
import { saga as LoadListDetailSaga } from './LoadListDetailSaga';
import { saga as LoadListDetailBoardSaga } from './LoadListDetailBoardSaga';
import { saga as LoadListIndexSaga } from './LoadListIndexSaga';
import { saga as LoadListSync01Saga } from './LoadListSync01Saga';
import { saga as LspAdvShipExcel01Saga } from './LspAdvShipExcel01Saga';
import { saga as LspAdvShipDetailSaga } from './LspAdvShipDetailSaga';
import { saga as LspAdvShipIndexSaga } from './LspAdvShipIndexSaga';
import { saga as LspCustomerReturnProcessSaga } from './LspCustomerReturnProcessSaga';
import { saga as LspDeliveryProcessSaga } from './LspDeliveryProcessSaga';
import { saga as LspDelOrdDetailSaga } from './LspDelOrdDetailSaga';
import { saga as LspDelOrdIndexSaga } from './LspDelOrdIndexSaga';
import { saga as LspReceivingProcessSaga } from './LspReceivingProcessSaga';
import { saga as LspSlsOrdDetailBoardSaga } from './LspSlsOrdDetailBoardSaga';
import { saga as LspSlsOrdDetailSaga } from './LspSlsOrdDetailSaga';
import { saga as LspSlsOrdExcel01Saga } from './LspSlsOrdExcel01Saga';
import { saga as LspSlsOrdFulfillmentChartSaga } from './LspSlsOrdFulfillmentChartSaga';
import { saga as LspSlsOrdIndexSaga } from './LspSlsOrdIndexSaga';
import { saga as LspSlsOrdTrackingReportSaga } from './LspSlsOrdTrackingReportSaga';
import { saga as LspSlsRtnDetailSaga } from './LspSlsRtnDetailSaga';
import { saga as LspSlsRtnExcel01Saga } from './LspSlsRtnExcel01Saga';
import { saga as LspSlsRtnIndexSaga } from './LspSlsRtnIndexSaga';
import { saga as LspSupplierReturnProcessSaga } from './LspSupplierReturnProcessSaga';
import { saga as OutbOrd01Saga } from './OutbOrd01Saga';
import { saga as OutbOrd02Saga } from './OutbOrd02Saga';
import { saga as OutbOrd04Saga } from './OutbOrd04Saga';
import { saga as OutbOrd05Saga } from './OutbOrd05Saga';
import { saga as OutbOrd06Saga } from './OutbOrd06Saga';
import { saga as OutbOrd07Saga } from './OutbOrd07Saga';
import { saga as OutbOrd08Saga } from './OutbOrd08Saga';
import { saga as OutbOrdAnalysisReportSaga } from './OutbOrdAnalysisReportSaga';
import { saga as OutbOrdDetailSaga } from './OutbOrdDetailSaga';
import { saga as OutbOrdReservedStockReportSaga } from './OutbOrdReservedStockReportSaga';
import { saga as OutbOrdIndexSaga } from './OutbOrdIndexSaga';
import { saga as OutboundExpiryReportSaga } from './OutboundExpiryReportSaga';
import { saga as OutOfStockAnalysisReportSaga } from './OutOfStockAnalysisReportSaga';
import { saga as PackListDetailSaga } from './PackListDetailSaga';
import { saga as PackListIndexSaga } from './PackListIndexSaga';
import { saga as WorkerPackListAnalysisReportSaga } from './WorkerPackListAnalysisReportSaga';
import { saga as PalletLabelList01Saga } from './PalletLabelList01Saga';
import { saga as PalletLabelProcessSaga } from './PalletLabelProcessSaga';
import { saga as PickFaceStrategyExcel01Saga } from './PickFaceStrategyExcel01Saga';
import { saga as PickFaceStrategyList01Saga } from './PickFaceStrategyList01Saga';
import { saga as PickFaceStrategyProcessSaga } from './PickFaceStrategyProcessSaga';
import { saga as PickingAllocErrorReportSaga } from './PickingAllocErrorReportSaga';
import { saga as PickingCriteriaExcel01Saga } from './PickingCriteriaExcel01Saga';
import { saga as PickingCriteriaList01Saga } from './PickingCriteriaList01Saga';
import { saga as PickingCriteriaProcessSaga } from './PickingCriteriaProcessSaga';
import { saga as PickList0101Saga } from './PickList0101Saga';
import { saga as PickList01Saga } from './PickList01Saga';
import { saga as PickListDetailBoardSaga } from './PickListDetailBoardSaga';
import { saga as PickListDetailSaga } from './PickListDetailSaga';
import { saga as PickListReservedStockReportSaga } from './PickListReservedStockReportSaga';
import { saga as PickListIndexSaga } from './PickListIndexSaga';
import { saga as PrfDel01Saga } from './PrfDel01Saga';
import { saga as PrfDelDetailSaga } from './PrfDelDetailSaga';
import { saga as PrfDelIndexSaga } from './PrfDelIndexSaga';
import { saga as PrfDelSync01Saga } from './PrfDelSync01Saga';
import { saga as PrincipalDetailSaga } from './PrincipalDetailSaga';
import { saga as PrincipalList01Saga } from './PrincipalList01Saga';
import { saga as PrincipalProcessSaga } from './PrincipalProcessSaga';
import { saga as ProdIssDetailSaga } from './ProdIssDetailSaga';
import { saga as ProdIssFServ01Saga } from './ProdIssFServ01Saga';
import { saga as ProdIssIndexSaga } from './ProdIssIndexSaga';
import { saga as ProdOrdDetailSaga } from './ProdOrdDetailSaga';
import { saga as ProdOrdExcel01Saga } from './ProdOrdExcel01Saga';
import { saga as ProdOrdFServ01Saga } from './ProdOrdFServ01Saga';
import { saga as ProdOrdFServ02Saga } from './ProdOrdFServ02Saga';
import { saga as ProdOrdIndexSaga } from './ProdOrdIndexSaga';
import { saga as ProdRcpt01Saga } from './ProdRcpt01Saga';
import { saga as ProdRcptDetailSaga } from './ProdRcptDetailSaga';
import { saga as ProdSheet01Saga } from './ProdSheet01Saga';
import { saga as ProdSheetDetailSaga } from './ProdSheetDetailSaga';
import { saga as ProdSheetFServ01Saga } from './ProdSheetFServ01Saga';
import { saga as ProdSheetIndexSaga } from './ProdSheetIndexSaga';
import { saga as ProductionProcessSaga } from './ProductionProcessSaga';
import { saga as ProjectDetailSaga } from './ProjectDetailSaga';
import { saga as ProjectList01Saga } from './ProjectList01Saga';
import { saga as PurchaseProcessSaga } from './PurchaseProcessSaga';
import { saga as PurchaseReturnProcessSaga } from './PurchaseReturnProcessSaga';
import { saga as PurRtnDetailSaga } from './PurRtnDetailSaga';
import { saga as PurRtnFServ02Saga } from './PurRtnFServ02Saga';
import { saga as PurRtnIndexSaga } from './PurRtnIndexSaga';
import { saga as PurRtnSync01Saga } from './PurRtnSync01Saga';
import { saga as PutAway01Saga } from './PutAway01Saga';
import { saga as PutAway02Saga } from './PutAway02Saga';
import { saga as PutAway03Saga } from './PutAway03Saga';
import { saga as PutAwayDetailBoardSaga } from './PutAwayDetailBoardSaga';
import { saga as PutAwayDetailSaga } from './PutAwayDetailSaga';
import { saga as PutAwayFServ01Saga } from './PutAwayFServ01Saga';
import { saga as PutAwayIndexSaga } from './PutAwayIndexSaga';
import { saga as PutAwayRtnDetailBoardSaga } from './PutAwayRtnDetailBoardSaga';
import { saga as PutAwayRtnIndexSaga } from './PutAwayRtnIndexSaga';
import { saga as PutAwaySync01Saga } from './PutAwaySync01Saga';
import { saga as RoleExcel01Saga } from './RoleExcel01Saga';
import { saga as RoleList01Saga } from './RoleList01Saga';
import { saga as RoleProcessSaga } from './RoleProcessSaga';
import { saga as RtnDelProcessSaga } from './RtnDelProcessSaga';
import { saga as RtnRcptDetailSaga } from './RtnRcptDetailSaga';
import { saga as RtnRcptIndexSaga } from './RtnRcptIndexSaga';
import { saga as RtnRcptProcessSaga } from './RtnRcptProcessSaga';
import { saga as RtnRcptSync01Saga } from './RtnRcptSync01Saga';
import { saga as SalesProcessSaga } from './SalesProcessSaga';
import { saga as ShipmentTrackingReportSaga } from './ShipmentTrackingReportSaga';
import { saga as SiteDetailSaga } from './SiteDetailSaga';
import { saga as SiteFlowDetailSaga } from './SiteFlowDetailSaga';
import { saga as SiteFlowList01Saga } from './SiteFlowList01Saga';
import { saga as SiteList01Saga } from './SiteList01Saga';
import { saga as SiteProcessSaga } from './SiteProcessSaga';
import { saga as SiteTrfProcessSaga } from './SiteTrfProcessSaga';
import { saga as SlsOrdTrackingReportSaga } from './SlsOrdTrackingReportSaga';
import { saga as SlsInvDetailSaga } from './SlsInvDetailSaga';
import { saga as SlsInvIndexSaga } from './SlsInvIndexSaga';
import { saga as SlsInvSync01Saga } from './SlsInvSync01Saga';
import { saga as SlsOrdDetailSaga } from './SlsOrdDetailSaga';
import { saga as SlsOrdDetailBoardSaga } from './SlsOrdDetailBoardSaga';
import { saga as SlsOrdExcel01Saga } from './SlsOrdExcel01Saga';
import { saga as SlsOrdFulfillmentChartSaga } from './SlsOrdFulfillmentChartSaga';
import { saga as SlsOrdFServ01Saga } from './SlsOrdFServ01Saga';
import { saga as SlsOrdFServ02Saga } from './SlsOrdFServ02Saga';
import { saga as SlsOrdIndexSaga } from './SlsOrdIndexSaga';
import { saga as SlsOrdSync01Saga } from './SlsOrdSync01Saga';
import { saga as SlsRtnDetailSaga } from './SlsRtnDetailSaga';
import { saga as SlsRtnFServ02Saga } from './SlsRtnFServ02Saga';
import { saga as SlsRtnIndexSaga } from './SlsRtnIndexSaga';
import { saga as SlsRtnProcessSaga } from './SlsRtnProcessSaga';
import { saga as SlsRtnSync01Saga } from './SlsRtnSync01Saga';
import { saga as StartupSaga } from './StartupSaga';
import { saga as StockCardReportSaga } from './StockCardReportSaga';
import { saga as StockAgingReportSaga } from './StockAgingReportSaga';
import { saga as StockBalanceReportSaga } from './StockBalanceReportSaga';
import { saga as StockReorderPlanningReportSaga } from './StockReorderPlanningReportSaga';
import { saga as StockReorderPlanningV2ReportSaga } from './StockReorderPlanningV2ReportSaga';
import { saga as StorageBayDetailSaga } from './StorageBayDetailSaga';
import { saga as StorageBayList01Saga } from './StorageBayList01Saga';
import { saga as StorageBinDetailSaga } from './StorageBinDetailSaga';
import { saga as StorageBinExcel01Saga } from './StorageBinExcel01Saga';
import { saga as StorageBinList01Saga } from './StorageBinList01Saga';
import { saga as StorageBinProcessSaga } from './StorageBinProcessSaga';
import { saga as StorageRowDetailSaga } from './StorageRowDetailSaga';
import { saga as StorageRowList01Saga } from './StorageRowList01Saga';
import { saga as StorageTypeDetailSaga } from './StorageTypeDetailSaga';
import { saga as StorageTypeList01Saga } from './StorageTypeList01Saga';
import { saga as StkIssDetailSaga } from './StkIssDetailSaga';
import { saga as StkIssIndexSaga } from './StkIssIndexSaga';
import { saga as StkIssSync01Saga } from './StkIssSync01Saga';
import { saga as StkIssSync02Saga } from './StkIssSync02Saga';
import { saga as StkIssSync04Saga } from './StkIssSync04Saga';
import { saga as StkIssSync05Saga } from './StkIssSync05Saga';
import { saga as StkRcptDetailSaga } from './StkRcptDetailSaga';
import { saga as StkRcpt02Saga } from './StkRcpt02Saga';
import { saga as StkRcpt03Saga } from './StkRcpt03Saga';
import { saga as StkIssFServ01Saga } from './StkIssFServ01Saga';
import { saga as StkRcptIndexSaga } from './StkRcptIndexSaga';
import { saga as StkRcptSync01Saga } from './StkRcptSync01Saga';
import { saga as StkRcptSync02Saga } from './StkRcptSync02Saga';
import { saga as StkRcptSync04Saga } from './StkRcptSync04Saga';
import { saga as StkRcptSync05Saga } from './StkRcptSync05Saga';
import { saga as StkTrfDetailSaga } from './StkTrfDetailSaga';
import { saga as StkTrfIndexSaga } from './StkTrfIndexSaga';
import { saga as StkTrfSync01Saga } from './StkTrfSync01Saga';
import { saga as SummaryBoardSaga } from './SummaryBoardSaga';
import { saga as TransferInTrackingReportSaga } from './TransferInTrackingReportSaga';
import { saga as TransferOutTrackingReportSaga } from './TransferOutTrackingReportSaga';
import { saga as TransportDetailSaga } from './TransportDetailSaga';
import { saga as TransportList01Saga } from './TransportList01Saga';
import { saga as TransportProcessSaga } from './TransportProcessSaga';
import { saga as TrfOrdDetailSaga } from './TrfOrdDetailSaga';
import { saga as TrfOrdIndexSaga } from './TrfOrdIndexSaga';
import { saga as UserDetailSaga } from './UserDetailSaga';
import { saga as UserExcel01Saga } from './UserExcel01Saga';
import { saga as UserList01Saga } from './UserList01Saga';
import { saga as UserProcessSaga } from './UserProcessSaga';
import { saga as TrfFromStoreProcessSaga } from './TrfFromStoreProcessSaga';
import { saga as TrfFromVanProcessSaga } from './TrfFromVanProcessSaga';
import { saga as TrfToStoreProcessSaga } from './TrfToStoreProcessSaga';
import { saga as TrfToVanProcessSaga } from './TrfToVanProcessSaga';
import { saga as WarehouseMapSaga } from './WarehouseMapSaga';
import { saga as WhseJob03DetailSaga } from './WhseJob03DetailSaga';
import { saga as WhseJob03IndexSaga } from './WhseJob03IndexSaga';
import { saga as WhseJob06DetailSaga } from './WhseJob06DetailSaga';
import { saga as WhseJob06IndexSaga } from './WhseJob06IndexSaga';
import { saga as WhseJob14DetailSaga } from './WhseJob14DetailSaga';
import { saga as WhseJob1401IndexSaga } from './WhseJob1401IndexSaga';
import { saga as WhseJob1402IndexSaga } from './WhseJob1402IndexSaga';
import { saga as WhseJob15DetailSaga } from './WhseJob15DetailSaga';
import { saga as WhseJob1501IndexSaga } from './WhseJob1501IndexSaga';
import { saga as WhseJob1502IndexSaga } from './WhseJob1502IndexSaga';
import { saga as WhseJob030102Saga } from './WhseJob030102Saga';
import { saga as WhseJob0301Saga } from './WhseJob0301Saga';
import { saga as WhseJob060101Saga } from './WhseJob060101Saga';
import { saga as WhseJob0601Saga } from './WhseJob0601Saga';
import { saga as WhseJob060201Saga } from './WhseJob060201Saga';
import { saga as WhseJob0602Saga } from './WhseJob0602Saga';
import { saga as WhseJob140101Saga } from './WhseJob140101Saga';
import { saga as WhseJob1401Saga } from './WhseJob1401Saga';
import { saga as WhseJob1402Saga } from './WhseJob1402Saga';
import { saga as WhseJob140201Saga } from './WhseJob140201Saga';
import { saga as WhseJob1501Saga } from './WhseJob1501Saga';
import { saga as WhseJob150101Saga } from './WhseJob150101Saga';
import { saga as WhseJob1502Saga } from './WhseJob1502Saga';
import { saga as WhseJob150201Saga } from './WhseJob150201Saga';
import { saga as WhseJob160102Saga } from './WhseJob160102Saga';
import { saga as WhseJob1601Saga } from './WhseJob1601Saga';
import { saga as WhseJob16DetailSaga } from './WhseJob16DetailSaga';
import { saga as WhseJob16IndexSaga } from './WhseJob16IndexSaga';
import { saga as WhseJob170201Saga } from './WhseJob170201Saga';
import { saga as WhseJob1702Saga } from './WhseJob1702Saga';
import { saga as WhseJob17DetailSaga } from './WhseJob17DetailSaga';
import { saga as WhseJob17IndexSaga } from './WhseJob17IndexSaga';
import { saga as WhsePerformanceChartSaga } from './WhsePerformanceChartSaga';
import { saga as WorkerPerformanceChartSaga } from './WorkerPerformanceChartSaga';
import { saga as WorkerGdsRcptAnalysisReportSaga } from './WorkerGdsRcptAnalysisReportSaga';
import { saga as WorkerPutAwayAnalysisReportSaga } from './WorkerPutAwayAnalysisReportSaga';
import { saga as WorkerLoadListAnalysisReportSaga } from './WorkerLoadListAnalysisReportSaga';
import { saga as WorkerPickListAnalysisReportSaga } from './WorkerPickListAnalysisReportSaga';
import { saga as AppSettingSaga } from './AppSettingSaga';
import { saga as SystemSettingSaga } from './SystemSettingSaga';

export default function* root() {
  yield all([
    /**
     * @see https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html
     */
    ...AdvShipDetailSaga,
    ...AdvShipExcel01Saga,
    ...AdvShipFServ01Saga,
    ...AdvShipIndexSaga,
    ...AdvShipSync01Saga,
    ...AdvShipSync02Saga,
    ...AdvShipFServ02Saga,
    ...AppSaga,
    ...AreaDetailSaga,
    ...AreaList01Saga,
    ...AreaProcessSaga,
    ...AuditRes01Saga,
    ...AuditUser01Saga,
    ...BinTrf01Saga,
    ...BinTrf02Saga,
    ...BinTrf03Saga,
    ...BinTrfDetailSaga,
    ...BinTrfIndexSaga,
    ...BinTrfProcessSaga,
    ...BizPartnerDetailSaga,
    ...CompanyDetailSaga,
    ...CompanyList01Saga,
    ...CompanyProcessSaga,
    ...CountAdj01Saga,
    ...CountAdjAnalysisReportSaga,
    ...CountAdjDetailSaga,
    ...CountAdjIndexSaga,
    ...CountAdjFServ01Saga,
    ...CountAdjFServ02Saga,
    ...CountAdjReasonDetailSaga,
    ...CountAdjReasonList01Saga,
    ...CountAdjReasonProcessSaga,
    ...CountAdjSync01Saga,
    ...CountAdjSync02Saga,
    ...CreditorDetailSaga,
    ...CreditorList01Saga,
    ...CreditorProcessSaga,
    ...CycleCount02DetailSaga,
    ...CycleCount02Saga,
    ...CycleCount03Saga,
    ...CycleCountAnalysisReportSaga,
    ...CycleCountDetailSaga,
    ...CycleCountIndexSaga,
    ...DailyStockMovementReportSaga,
    ...DebtorDetailSaga,
    ...DebtorList01Saga,
    ...DebtorProcessSaga,
    ...DeliveryPointDetailSaga,
    ...DeliveryPointList01Saga,
    ...DeliveryPointProcessSaga,
    ...DelOrdDetailSaga,
    ...DelOrdFServ01Saga,
    ...DelOrdIndexSaga,
    ...DelOrdSync01Saga,
    ...DivisionDetailSaga,
    ...DivisionList01Saga,
    ...DivisionProcessSaga,
    ...DocTypeDocNoIndexSaga,
    ...FailDel01Saga,
    ...GdsDelProcessSaga,
    ...GdsIss01Saga,
    ...GdsIss0101Saga,
    ...GdsIssDetailSaga,
    ...GdsIssFServ01Saga,
    ...GdsIssSync01Saga,
    ...GdsIssIndexSaga,
    ...GdsRcpt0101Saga,
    ...GdsRcpt0201Saga,
    ...GdsRcpt01Saga,
    ...GdsRcpt02Saga,
    ...GdsRcptDetailSaga,
    ...GdsRcptDetailBoardSaga,
    ...GdsRcptRtnDetailBoardSaga,
    ...GdsRcptFServ01Saga,
    ...GdsRcptFServ02Saga,
    ...GdsRcptFServ03Saga,
    ...GdsRcptIndexSaga,
    ...GdsRcptRtnIndexSaga,
    ...GdsRcptProcessSaga,
    ...GdsRcptSync01Saga,
    ...GdsRcptTrackingReportSaga,
    ...InbOrd01Saga,
    ...InbOrd02Saga,
    ...InbOrd03Saga,
    ...InbOrd04Saga,
    ...InbOrd05Saga,
    ...InbOrd06Saga,
    ...InbOrd07Saga,
    ...InbOrdDetailSaga,
    ...InbOrdIndexSaga,
    ...InvAuditProcessSaga,
    ...InvDoc0101Saga,
    ...InvDoc0102Saga,
    ...InvDoc01Saga,
    ...InvDoc02Saga,
    ...InvDoc03Saga,
    ...ItemBatchDetailSaga,
    ...ItemBatchExcel01Saga,
    ...ItemDetailSaga,
    ...ItemExcel01Saga,
    ...ItemManufacturerExcelSaga,
    ...ItemList01Saga,
    ...ItemList02Saga,
    ...ItemSync01Saga,
    ...ItemSync0101Saga,
    ...ItemProcessSaga,
    ...ItemManufactureProcessSaga,
    ...ManufactureItemListSaga,
    ...UomListSaga,
    ...UomDetailSaga,
    ...LayerFarmProcessSaga,
    ...LayerFarmListSaga,
    ...LayerFarmExcelSaga,
    ...LocationDetailSaga,
    ...LocationList01Saga,
    ...LoadList01Saga,
    ...LoadList0101Saga,
    ...LoadListDetailSaga,
    ...LoadListDetailBoardSaga,
    ...LoadListIndexSaga,
    ...LoadListSync01Saga,
    ...LspAdvShipExcel01Saga,
    ...LspAdvShipDetailSaga,
    ...LspAdvShipIndexSaga,
    ...LspCustomerReturnProcessSaga,
    ...LspDeliveryProcessSaga,
    ...LspDelOrdDetailSaga,
    ...LspDelOrdIndexSaga,
    ...LspReceivingProcessSaga,
    ...LspSlsOrdDetailBoardSaga,
    ...LspSlsOrdDetailSaga,
    ...LspSlsOrdExcel01Saga,
    ...LspSlsOrdFulfillmentChartSaga,
    ...LspSlsOrdIndexSaga,
    ...LspSlsOrdTrackingReportSaga,
    ...LspSlsRtnDetailSaga,
    ...LspSlsRtnExcel01Saga,
    ...LspSlsRtnIndexSaga,
    ...LspSupplierReturnProcessSaga,
    ...OutbOrd01Saga,
    ...OutbOrd02Saga,
    ...OutbOrd04Saga,
    ...OutbOrd05Saga,
    ...OutbOrd06Saga,
    ...OutbOrd07Saga,
    ...OutbOrd08Saga,
    ...OutbOrdAnalysisReportSaga,
    ...OutbOrdDetailSaga,
    ...OutbOrdReservedStockReportSaga,
    ...OutbOrdIndexSaga,
    ...OutboundExpiryReportSaga,
    ...OutOfStockAnalysisReportSaga,
    ...PackListDetailSaga,
    ...PackListIndexSaga,
    ...PalletLabelList01Saga,
    ...PalletLabelProcessSaga,
    ...PickFaceStrategyExcel01Saga,
    ...PickFaceStrategyList01Saga,
    ...PickFaceStrategyProcessSaga,
    ...PickingAllocErrorReportSaga,
    ...PickingCriteriaExcel01Saga,
    ...PickingCriteriaList01Saga,
    ...PickingCriteriaProcessSaga,
    ...PickListDetailBoardSaga,
    ...PickList0101Saga,
    ...PickList01Saga,
    ...PickListDetailSaga,
    ...PickListReservedStockReportSaga,
    ...PickListIndexSaga,
    ...PrfDel01Saga,
    ...PrfDelDetailSaga,
    ...PrfDelIndexSaga,
    ...PrfDelSync01Saga,
    ...PrincipalDetailSaga,
    ...PrincipalList01Saga,
    ...PrincipalProcessSaga,
    ...ProdIssDetailSaga,
    ...ProdIssFServ01Saga,
    ...ProdIssIndexSaga,
    ...ProdOrdDetailSaga,
    ...ProdOrdExcel01Saga,
    ...ProdOrdFServ01Saga,
    ...ProdOrdFServ02Saga,
    ...ProdOrdIndexSaga,
    ...ProdRcpt01Saga,
    ...ProdRcptDetailSaga,
    ...ProdSheet01Saga,
    ...ProdSheetDetailSaga,
    ...ProdSheetFServ01Saga,
    ...ProdSheetIndexSaga,
    ...ProductionProcessSaga,
    ...ProjectDetailSaga,
    ...ProjectList01Saga,
    ...PurchaseProcessSaga,
    ...PurchaseReturnProcessSaga,
    ...PurRtnDetailSaga,
    ...PurRtnFServ02Saga,
    ...PurRtnIndexSaga,
    ...PurRtnSync01Saga,
    ...PutAway01Saga,
    ...PutAway02Saga,
    ...PutAway03Saga,
    ...PutAwayDetailBoardSaga,
    ...PutAwayDetailSaga,
    ...PutAwayFServ01Saga,
    ...PutAwayIndexSaga,
    ...PutAwayRtnDetailBoardSaga,
    ...PutAwayRtnIndexSaga,
    ...PutAwaySync01Saga,
    ...RoleExcel01Saga,
    ...RoleList01Saga,
    ...RoleProcessSaga,
    ...RtnDelProcessSaga,
    ...RtnRcptDetailSaga,
    ...RtnRcptIndexSaga,
    ...RtnRcptProcessSaga,
    ...RtnRcptSync01Saga,
    ...SalesProcessSaga,
    ...ShipmentTrackingReportSaga,
    ...SiteDetailSaga,
    ...SiteFlowDetailSaga,
    ...SiteFlowList01Saga,
    ...SiteList01Saga,
    ...SiteProcessSaga,
    ...SiteTrfProcessSaga,
    ...SlsOrdTrackingReportSaga,
    ...SlsInvDetailSaga,
    ...SlsInvIndexSaga,
    ...SlsInvSync01Saga,
    ...SlsOrdDetailSaga,
    ...SlsOrdDetailBoardSaga,
    ...SlsOrdExcel01Saga,
    ...SlsOrdFServ01Saga,
    ...SlsOrdFServ02Saga,
    ...SlsOrdFulfillmentChartSaga,
    ...SlsOrdIndexSaga,
    ...SlsOrdSync01Saga,
    ...SlsRtnDetailSaga,
    ...SlsRtnFServ02Saga,
    ...SlsRtnIndexSaga,
    ...SlsRtnProcessSaga,
    ...SlsRtnSync01Saga,
    ...StartupSaga,
    ...StockBalanceReportSaga,
    ...StockReorderPlanningReportSaga,
    ...StockReorderPlanningV2ReportSaga,
    ...StockCardReportSaga,
    ...StockAgingReportSaga,
    ...StorageBayDetailSaga,
    ...StorageBayList01Saga,
    ...StorageBinDetailSaga,
    ...StorageBinExcel01Saga,
    ...StorageBinList01Saga,
    ...StorageBinProcessSaga,
    ...StorageRowDetailSaga,
    ...StorageRowList01Saga,
    ...StorageTypeDetailSaga,
    ...StorageTypeList01Saga,
    ...StkIssDetailSaga,
    ...StkIssFServ01Saga,
    ...StkIssIndexSaga,
    ...StkIssSync01Saga,
    ...StkIssSync02Saga,
    ...StkIssSync04Saga,
    ...StkIssSync05Saga,
    ...StkRcpt02Saga,
    ...StkRcpt03Saga,
    ...StkRcptDetailSaga,
    ...StkRcptIndexSaga,
    ...StkRcptSync01Saga,
    ...StkRcptSync02Saga,
    ...StkRcptSync04Saga,
    ...StkRcptSync05Saga,
    ...StkTrfDetailSaga,
    ...StkTrfIndexSaga,
    ...StkTrfSync01Saga,
    ...SummaryBoardSaga,
    ...TransferInTrackingReportSaga,
    ...TransferOutTrackingReportSaga,
    ...TransportDetailSaga,
    ...TransportList01Saga,
    ...TransportProcessSaga,
    ...TrfFromStoreProcessSaga,
    ...TrfFromVanProcessSaga,
    ...TrfToStoreProcessSaga,
    ...TrfToVanProcessSaga,
    ...TrfOrdDetailSaga,
    ...TrfOrdIndexSaga,
    ...UserDetailSaga,
    ...UserExcel01Saga,
    ...UserList01Saga,
    ...UserProcessSaga,
    ...WarehouseMapSaga,
    ...WhseJob03DetailSaga,
    ...WhseJob03IndexSaga,
    ...WhseJob06DetailSaga,
    ...WhseJob06IndexSaga,
    ...WhseJob14DetailSaga,
    ...WhseJob1401IndexSaga,
    ...WhseJob1402IndexSaga,
    ...WhseJob15DetailSaga,
    ...WhseJob1501IndexSaga,
    ...WhseJob1502IndexSaga,
    ...WhseJob030102Saga,
    ...WhseJob0301Saga,
    ...WhseJob060101Saga,
    ...WhseJob0601Saga,
    ...WhseJob060201Saga,
    ...WhseJob0602Saga,
    ...WhseJob140101Saga,
    ...WhseJob1401Saga,
    ...WhseJob1402Saga,
    ...WhseJob140201Saga,
    ...WhseJob1501Saga,
    ...WhseJob150101Saga,
    ...WhseJob1502Saga,
    ...WhseJob150201Saga,
    ...WhseJob160102Saga,
    ...WhseJob1601Saga,
    ...WhseJob16DetailSaga,
    ...WhseJob16IndexSaga,
    ...WhseJob170201Saga,
    ...WhseJob1702Saga,
    ...WhseJob17DetailSaga,
    ...WhseJob17IndexSaga,
    ...WhsePerformanceChartSaga,
    ...WorkerPerformanceChartSaga,
    ...WorkerGdsRcptAnalysisReportSaga,
    ...WorkerPutAwayAnalysisReportSaga,
    ...WorkerLoadListAnalysisReportSaga,
    ...WorkerPackListAnalysisReportSaga,
    ...WorkerPickListAnalysisReportSaga,
    ...AppSettingSaga,
    ...SystemSettingSaga
  ]);
}
