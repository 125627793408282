/**
 * The initial values for the redux state.
 */
export default {
  resId: 0,

  modelTimestamp: 0,
  model: {
    id: 0,
    code: '',
    ref_code_01: '',
    desc_01: '',
    desc_02: ''
  },

  modelIsLoading: false,

  siteFlowOptions: [],
  siteFlowIsLoading: false,

  companyOptions: [],
  companyIsLoading: false,

  invTxnFlowTimestamp: 0,
  invTxnFlows: [],
  invTxnFlowsIsLoading: false,

  invTxnFlowsUploadIsLoading: false,

  printDocSettingTimestamp: 0,
  printDocSettings: [],
  printDocSettingsIsLoading: false,

  printDocSettingsUploadIsLoading: false,

  userDivisionTimestamp: 0,
  userDivisions: [],
  userDivisionsIsLoading: false,

  userDivisionsUploadIsLoading: false,

  arInvDocNoOptions: [],
  arInvDocNoIsLoading: false,
  arCnDocNoOptions: [],
  arCnDocNoIsLoading: false,
  arDnDocNoOptions: [],
  arDnDocNoIsLoading: false,
  arPaymentDocNoOptions: [],
  arPaymentDocNoIsLoading: false,
  arRefundDocNoOptions: [],
  arRefundDocNoIsLoading: false,
  arDepositDocNoOptions: [],
  arDepositDocNoIsLoading: false,
  arDepositForfeitDocNoOptions: [],
  arDepositForfeitDocNoIsLoading: false,
  arDepositRefundDocNoOptions: [],
  arDepositRefundDocNoIsLoading: false,

  apInvDocNoOptions: [],
  apInvDocNoIsLoading: false,
  apDnDocNoOptions: [],
  apDnDocNoIsLoading: false,
  apCnDocNoOptions: [],
  apCnDocNoIsLoading: false,
  apPaymentDocNoOptions: [],
  apPaymentDocNoIsLoading: false,
  apRefundDocNoOptions: [],
  apRefundDocNoIsLoading: false,
  apDepositDocNoOptions: [],
  apDepositDocNoIsLoading: false,
  apDepositForfeitDocNoOptions: [],
  apDepositForfeitDocNoIsLoading: false,
  apDepositRefundDocNoOptions: [],
  apDepositRefundDocNoIsLoading: false,

  paymentVoucherDocNoOptions: [],
  paymentVoucherDocNoIsLoading: false,
  receiptVoucherDocNoOptions: [],
  receiptVoucherDocNoIsLoading: false,
  journalVoucherDocNoOptions: [],
  journalVoucherDocNoIsLoading: false,

  slsOrdDocNoOptions: [],
  slsOrdDocNoIsLoading: false,

  slsRtnDocNoOptions: [],
  slsRtnDocNoIsLoading: false,

  delOrdDocNoOptions: [],
  delOrdDocNoIsLoading: false,

  slsInvDocNoOptions: [],
  slsInvDocNoIsLoading: false,

  rtnRcptDocNoOptions: [],
  rtnRcptDocNoIsLoading: false,

  advShipDocNoOptions: [],
  advShipDocNoIsLoading: false,

  purInvDocNoOptions: [],
  purInvDocNoIsLoading: false,

  purRtnDocNoOptions: [],
  purRtnDocNoIsLoading: false,

  lspSlsOrdDocNoOptions: [],
  lspSlsOrdDocNoIsLoading: false,

  lspSlsRtnDocNoOptions: [],
  lspSlsRtnDocNoIsLoading: false,

  lspAdvShipDocNoOptions: [],
  lspAdvShipDocNoIsLoading: false,

  lspDelOrdDocNoOptions: [],
  lspDelOrdDocNoIsLoading: false,

  prodOrdDocNoOptions: [],
  prodOrdDocNoIsLoading: false,

  prodIssDocNoOptions: [],
  prodIssDocNoIsLoading: false,

  prodRcptDocNoOptions: [],
  prodRcptDocNoIsLoading: false,

  prodSheetDocNoOptions: [],
  prodSheetDocNoIsLoading: false,

  outbOrdDocNoOptions: [],
  outbOrdDocNoIsLoading: false,

  inbOrdDocNoOptions: [],
  inbOrdDocNoIsLoading: false,

  trfOrdDocNoOptions: [],
  trfOrdDocNoIsLoading: false,

  stkIssDocNoOptions: [],
  stkIssDocNoIsLoading: false,

  stkRcptDocNoOptions: [],
  stkRcptDocNoIsLoading: false,

  stkTrfDocNoOptions: [],
  stkTrfDocNoIsLoading: false,

  workOrdDocNoOptions: [],
  workOrdDocNoIsLoading: false
};
