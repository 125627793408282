import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import DailyStockMovementReportActions, {
  DailyStockMovementReportTypes
} from '../Stores/DailyStockMovementReport/Actions';

const getAppStore = state => state.app;

export function* dailyStockMovementReportInitDailyStockMovement() {
  try {
    yield put(DailyStockMovementReportActions.dailyStockMovementReportReportLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseReport/initDailyStockMovement/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        DailyStockMovementReportActions.dailyStockMovementReportInitDailyStockMovementSuccess(
          result.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DailyStockMovementReportActions.dailyStockMovementReportReportLoading(false));
  }
}

export function* dailyStockMovementReportDailyStockMovement({ formikBag, criteria }) {
  try {
    formikBag.setSubmitting(true);
    yield put(DailyStockMovementReportActions.dailyStockMovementReportReportLoading(true));

    const objCriteria = {};
    if ('doc_date' in criteria) {
      objCriteria.doc_date = criteria.doc_date;
    }
    if ('item_ids_select2' in criteria) {
      const values = criteria.item_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_01_ids_select2' in criteria) {
      const values = criteria.item_group_01_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_01_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_02_ids_select2' in criteria) {
      const values = criteria.item_group_02_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_02_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_03_ids_select2' in criteria) {
      const values = criteria.item_group_03_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_03_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_bin_ids_select2' in criteria) {
      const values = criteria.storage_bin_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_bin_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_row_ids_select2' in criteria) {
      const values = criteria.storage_row_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_row_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_bay_ids_select2' in criteria) {
      const values = criteria.storage_bay_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_bay_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('location_ids_select2' in criteria) {
      const values = criteria.location_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.location_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    let columns = [];
    if (criteria.columns.target.length > 0) {
      columns = criteria.columns.target.map(entry => {
        return entry.column;
      });
    }

    const app = yield select(getAppStore);
    const postData = {
      sorts: [],
      criteria: objCriteria,
      columns
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseReport/dailyStockMovement/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(
        DailyStockMovementReportActions.dailyStockMovementReportDailyStockMovementSuccess(
          criteria,
          result.data.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(DailyStockMovementReportActions.dailyStockMovementReportReportLoading(false));
  }
}

export function* dailyStockMovementReportFetchItemOptions({ search }) {
  try {
    yield put(DailyStockMovementReportActions.dailyStockMovementReportFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        DailyStockMovementReportActions.dailyStockMovementReportFetchItemOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      DailyStockMovementReportActions.dailyStockMovementReportFetchItemOptionLoading(false)
    );
  }
}

export function* dailyStockMovementReportFetchItemGroup01Options({ search }) {
  try {
    yield put(
      DailyStockMovementReportActions.dailyStockMovementReportFetchItemGroup01OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        DailyStockMovementReportActions.dailyStockMovementReportFetchItemGroup01OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      DailyStockMovementReportActions.dailyStockMovementReportFetchItemGroup01OptionLoading(false)
    );
  }
}

export function* dailyStockMovementReportFetchItemGroup02Options({ search }) {
  try {
    yield put(
      DailyStockMovementReportActions.dailyStockMovementReportFetchItemGroup02OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup02/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        DailyStockMovementReportActions.dailyStockMovementReportFetchItemGroup02OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      DailyStockMovementReportActions.dailyStockMovementReportFetchItemGroup02OptionLoading(false)
    );
  }
}

export function* dailyStockMovementReportFetchItemGroup03Options({ search }) {
  try {
    yield put(
      DailyStockMovementReportActions.dailyStockMovementReportFetchItemGroup03OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup03/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        DailyStockMovementReportActions.dailyStockMovementReportFetchItemGroup03OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      DailyStockMovementReportActions.dailyStockMovementReportFetchItemGroup03OptionLoading(false)
    );
  }
}

export function* dailyStockMovementReportFetchStorageBinOptions({ search }) {
  try {
    yield put(
      DailyStockMovementReportActions.dailyStockMovementReportFetchStorageBinOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        DailyStockMovementReportActions.dailyStockMovementReportFetchStorageBinOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      DailyStockMovementReportActions.dailyStockMovementReportFetchStorageBinOptionLoading(false)
    );
  }
}

export function* dailyStockMovementReportFetchStorageRowOptions({ search }) {
  try {
    yield put(
      DailyStockMovementReportActions.dailyStockMovementReportFetchStorageRowOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageRow/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        DailyStockMovementReportActions.dailyStockMovementReportFetchStorageRowOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      DailyStockMovementReportActions.dailyStockMovementReportFetchStorageRowOptionLoading(false)
    );
  }
}

export function* dailyStockMovementReportFetchStorageBayOptions({ search }) {
  try {
    yield put(
      DailyStockMovementReportActions.dailyStockMovementReportFetchStorageBayOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBay/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        DailyStockMovementReportActions.dailyStockMovementReportFetchStorageBayOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      DailyStockMovementReportActions.dailyStockMovementReportFetchStorageBayOptionLoading(false)
    );
  }
}

export function* dailyStockMovementReportFetchLocationOptions({ search }) {
  try {
    yield put(
      DailyStockMovementReportActions.dailyStockMovementReportFetchLocationOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `location/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        DailyStockMovementReportActions.dailyStockMovementReportFetchLocationOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      DailyStockMovementReportActions.dailyStockMovementReportFetchLocationOptionLoading(false)
    );
  }
}

export const saga = [
  takeLatest(
    DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_INIT_DAILY_STOCK_MOVEMENT,
    dailyStockMovementReportInitDailyStockMovement
  ),
  takeLatest(
    DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_DAILY_STOCK_MOVEMENT,
    dailyStockMovementReportDailyStockMovement
  ),
  takeLatest(
    DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_ITEM_OPTIONS,
    dailyStockMovementReportFetchItemOptions
  ),
  takeLatest(
    DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_ITEM_GROUP01_OPTIONS,
    dailyStockMovementReportFetchItemGroup01Options
  ),
  takeLatest(
    DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_ITEM_GROUP02_OPTIONS,
    dailyStockMovementReportFetchItemGroup02Options
  ),
  takeLatest(
    DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_ITEM_GROUP03_OPTIONS,
    dailyStockMovementReportFetchItemGroup03Options
  ),
  takeLatest(
    DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_STORAGE_BIN_OPTIONS,
    dailyStockMovementReportFetchStorageBinOptions
  ),
  takeLatest(
    DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_STORAGE_ROW_OPTIONS,
    dailyStockMovementReportFetchStorageRowOptions
  ),
  takeLatest(
    DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_STORAGE_BAY_OPTIONS,
    dailyStockMovementReportFetchStorageBayOptions
  ),
  takeLatest(
    DailyStockMovementReportTypes.DAILY_STOCK_MOVEMENT_REPORT_FETCH_LOCATION_OPTIONS,
    dailyStockMovementReportFetchLocationOptions
  )
];
