import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stkIssSync01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkIssSync01GoToDocument: ['hdrId'],
  stkIssSync01FetchStkIssSync01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkIssSync01FetchStkIssSync01Loading: ['boolean'],
  stkIssSync01FetchStkIssSync01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  stkIssSync01AddSelectedDocuments: ['selectedDocuments'],
  stkIssSync01RemoveSelectedDocuments: ['selectedDocuments'],
  stkIssSync01SetWorkspaceVisible: ['boolean'],

  stkIssSync01SyncNow: ['hdrIds'],
  stkIssSync01SyncNowSuccess: null,
  stkIssSync01SyncLoading: ['boolean']
});

export const StkIssSync01Types = Types;
export default Creators;
