import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';

import { Card, Tabs } from 'antd';
import ModelForm from './ModelForm';
import WhseTxnFlowTable from './WhseTxnFlowTable';
import PrintDocSettingTable from './PrintDocSettingTable';
import SiteFlowDetailActions from '../../Stores/SiteFlowDetail/Actions';

const { TabPane } = Tabs;

class SiteFlowDetailScreen extends React.PureComponent {
  componentDidMount() {
    const { match, setReslId } = this.props;

    if (match.params.action === 'create') {
      setReslId(0);
    } else if (match.params.action === 'update') {
      setReslId(parseInt(match.params.id, 10));
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const { intl, match, model } = this.props;

    return (
      <Card title={`${intl.formatMessage({ id: 'site' })} ${model.code}`}>
        <ModelForm match={match} />
        {model.id > 0 ? (
          <Tabs defaultActiveKey="1" type="card">
            <TabPane tab={intl.formatMessage({ id: 'print_template' })} key="1">
              <PrintDocSettingTable match={match} />
            </TabPane>
            <TabPane tab={intl.formatMessage({ id: 'process' })} key="2">
              <WhseTxnFlowTable match={match} />
            </TabPane>
          </Tabs>
        ) : (
          ''
        )}
      </Card>
    );
  }
}

SiteFlowDetailScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,

  setReslId: PropTypes.func,
  model: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string
  })
};

SiteFlowDetailScreen.defaultProps = {
  intl: {},
  match: {},

  setReslId() {},
  model: { id: 0, code: '' }
};

const mapStateToProps = state => ({
  model: state.siteFlowDetail.model
});

const mapDispatchToProps = dispatch => ({
  setReslId: resId => dispatch(SiteFlowDetailActions.siteFlowDetailSetResId(resId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SiteFlowDetailScreen));
