import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import treeChanges from 'tree-changes';

import { Card, Collapse } from 'antd';
import CriteriaForm from './CriteriaForm';
import ReportTable from './ReportTable';

import PickListReservedStockReportActions from '../../Stores/PickListReservedStockReport/Actions';

const { Panel } = Collapse;

class PickListReservedStockReportScreen extends React.PureComponent {
  componentDidMount() {
    const { curSiteFlowId, initPickListReservedStock } = this.props;

    if (curSiteFlowId > 0) {
      initPickListReservedStock();
    }
  }

  componentDidUpdate(prevProps) {
    const { curSiteFlowId, initPickListReservedStock } = this.props;
    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      if (curSiteFlowId > 0) {
        initPickListReservedStock();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match } = this.props;

    return (
      <Card title={intl.formatMessage({ id: 'pick_list_reserved_stock_report' })}>
        <Collapse defaultActiveKey={['2']}>
          <Panel header={intl.formatMessage({ id: 'criteria' })} key="1">
            <CriteriaForm match={match} />
          </Panel>
          <Panel header={intl.formatMessage({ id: 'report' })} key="2">
            <ReportTable match={match} />
          </Panel>
        </Collapse>
      </Card>
    );
  }
}

PickListReservedStockReportScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,

  initPickListReservedStock: PropTypes.func,
  curSiteFlowId: PropTypes.number
};

PickListReservedStockReportScreen.defaultProps = {
  intl: {},
  match: {},

  initPickListReservedStock: {},
  curSiteFlowId: 0
};

const mapStateToProps = state => ({
  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  initPickListReservedStock: () =>
    dispatch(
      PickListReservedStockReportActions.pickListReservedStockReportInitPickListReservedStock()
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PickListReservedStockReportScreen));
