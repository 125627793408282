import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  outbOrd07ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  outbOrd07GoToDocument: ['hdrId'],
  outbOrd07NewDocument: null,
  outbOrd07FetchOutbOrd07: ['currentPage', 'sorts', 'filters', 'pageSize'],
  outbOrd07FetchOutbOrd07Loading: ['boolean'],
  outbOrd07FetchOutbOrd07Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  outbOrd07AddSelectedDocuments: ['selectedDocuments'],
  outbOrd07RemoveSelectedDocuments: ['selectedDocuments'],
  outbOrd07SetWorkspaceVisible: ['boolean'],
  outbOrd07CreateOutbOrd07: ['hdrIds'],
  outbOrd07CreateOutbOrd07Loading: ['boolean'],
  outbOrd07CreateOutbOrd07Success: ['newDocuments'],
  outbOrd07CheckStock: ['hdrIds'],

  outbOrd07SetExpandedRows: ['expandedRows']
});

export const OutbOrd07Types = Types;
export default Creators;
