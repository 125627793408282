import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  appSettingResetTimestamp: null,
  appSettingUpdateAppSetting: ['formikBag', 'newDetail'],
  appSettingFetchAppSetting: [],
  appSettingFetchAppSettingSuccess: ['appSettingData'],
  appSettingSetLoading: ['appSettingIsLoading']
});

export const AppSettingTypes = Types;
export default Creators;
