import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  userDetailResetTimestamp: null,
  userDetailSetResId: ['resId'],

  userDetailInitModel: null,
  userDetailShowModel: ['resId'],
  userDetailShowModelSuccess: ['model'],

  userDetailUpdateModel: ['formikBag', 'model'],
  userDetailCreateModel: ['formikBag', 'model'],
  userDetailUpdateModelSuccess: ['model'],

  userDetailResetPassword: null,

  userDetailShowModelLoading: ['boolean'],

  userDetailShowUserSiteFlows: ['resId'],
  userDetailShowUserSiteFlowSuccess: ['userSiteFlows'],
  userDetailShowUserSiteFlowLoading: ['boolean'],
  userDetailAddUserSiteFlow: ['resId', 'siteFlowId'],
  userDetailRemoveUserSiteFlow: ['resId', 'userSiteFlowId'],

  userDetailFetchSiteFlowOptions: ['resId', 'search'],
  userDetailFetchSiteFlowOptionLoading: ['boolean'],
  userDetailFetchSiteFlowOptionSuccess: ['options'],
  userDetailSetSiteFlowOption: ['option'],

  userDetailShowUserDivisions: ['resId'],
  userDetailShowUserDivisionSuccess: ['userDivisions'],
  userDetailShowUserDivisionLoading: ['boolean'],
  userDetailAddUserDivision: ['resId', 'divisionId'],
  userDetailRemoveUserDivision: ['resId', 'userDivisionId'],

  userDetailFetchDivisionOptions: ['resId', 'search'],
  userDetailFetchDivisionOptionLoading: ['boolean'],
  userDetailFetchDivisionOptionSuccess: ['options'],
  userDetailSetDivisionOption: ['option']
});

export const UserDetailTypes = Types;
export default Creators;
