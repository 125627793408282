import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { ReloadOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button, Upload, notification } from 'antd';

import SiteFlowDetailActions from '../../Stores/SiteFlowDetail/Actions';

class PrintDocSettingTable extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      fileList: []
    };

    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.useOnUpload = this.useOnUpload.bind(this);
    this.useOnDownload = this.useOnDownload.bind(this);
  }

  componentDidMount() {
    const { resetPrintDocSettingTimestamp } = this.props;

    resetPrintDocSettingTimestamp();
  }

  componentDidUpdate(prevProps) {
    const { resId, showPrintDocSettings, showPrintDocSettingsSuccess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('printDocSettingTimestamp')) {
      if (resId === 0) {
        showPrintDocSettingsSuccess([]);
      } else {
        showPrintDocSettings(resId);
      }
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        width: 80,
        align: 'left',
        title: intl.formatMessage({ id: 'document_type' }),
        // sort field
        dataIndex: 'doc_hdr_type',
        // filter field
        key: 'doc_hdr_type',
        render: (text, record) => <>{record.doc_hdr_type}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc',
        // filter field
        key: 'desc',
        render: (text, record) => <>{record.desc}</>
      },
      {
        width: 35,
        align: 'right',
        title: intl.formatMessage({ id: 'sequence' }),
        // sort field
        dataIndex: 'seq_no',
        // filter field
        key: 'seq_no',
        render: (text, record) => <>{record.seq_no}</>
      },
      {
        width: 80,
        align: 'left',
        title: intl.formatMessage({ id: 'template_name' }),
        // sort field
        dataIndex: 'view_name',
        // filter field
        key: 'view_name',
        render: (text, record) => <>{record.view_name}</>
      },
      {
        width: 50,
        align: 'right',
        title: intl.formatMessage({ id: 'orientation' }),
        // sort field
        dataIndex: 'page_orientation',
        // filter field
        key: 'page_orientation',
        render: (text, record) => <>{record.page_orientation}</>
      },
      {
        width: 50,
        align: 'right',
        title: intl.formatMessage({ id: 'width' }),
        // sort field
        dataIndex: 'page_width',
        // filter field
        key: 'page_width',
        render: (text, record) => <>{record.page_width}</>
      },
      {
        width: 50,
        align: 'right',
        title: intl.formatMessage({ id: 'height' }),
        // sort field
        dataIndex: 'page_height',
        // filter field
        key: 'page_height',
        render: (text, record) => <>{record.page_height}</>
      }
    ];
  }

  useOnUpload() {
    const { resId, uploadExcel } = this.props;
    const { fileList } = this.state;

    if (fileList.length > 0) {
      uploadExcel(resId, fileList[0]);
    }
  }

  useOnDownload() {
    const { resId, downloadExcel } = this.props;

    downloadExcel(resId);
  }

  render() {
    const {
      intl,
      resetPrintDocSettingTimestamp,
      resId,
      printDocSettings,
      printDocSettingsIsLoading,
      uploadIsLoading
    } = this.props;
    const { fileList } = this.state;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={false}
          columns={this.getDocumentColumns()}
          dataSource={printDocSettings}
          loading={printDocSettingsIsLoading}
          bordered
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={printDocSettingsIsLoading}
                    onClick={() => resetPrintDocSettingTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
                <Col span={8}>
                  <Upload
                    disabled={false}
                    onRemove={() => {
                      this.setState({
                        fileList: []
                      });
                    }}
                    beforeUpload={file => {
                      const validFileName = 'SITE_FLOW_PRINT_DOC_SETTING';
                      if (file && file.name.toLowerCase().includes(validFileName.toLowerCase())) {
                        this.setState({
                          fileList: [file]
                        });
                      } else {
                        notification.error({
                          message: `${intl.formatMessage({
                            id: 'invalid_file_name_must_contain'
                          })} ${validFileName}`,
                          duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
                        });
                      }

                      return false;
                    }}
                    fileList={fileList}
                  >
                    <Button type="primary" disabled={false} loading={printDocSettingsIsLoading}>
                      {intl.formatMessage({ id: 'browse' })}
                    </Button>
                  </Upload>
                </Col>
                <Col span={6}>
                  <Button
                    type="primary"
                    onClick={this.useOnUpload}
                    disabled={fileList.length === 0}
                    loading={uploadIsLoading}
                  >
                    {intl.formatMessage({ id: 'upload' })}
                  </Button>
                  <Button
                    type="primary"
                    disabled={printDocSettingsIsLoading || resId === 0}
                    onClick={this.useOnDownload}
                    loading={uploadIsLoading}
                  >
                    {intl.formatMessage({ id: 'download' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          // scroll={{ x: 950 }}
        />
      </>
    );
  }
}

PrintDocSettingTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetPrintDocSettingTimestamp: PropTypes.func,
  showPrintDocSettings: PropTypes.func,
  showPrintDocSettingsSuccess: PropTypes.func,
  resId: PropTypes.number,
  printDocSettingsIsLoading: PropTypes.bool,
  printDocSettings: PropTypes.arrayOf(PropTypes.object),

  uploadIsLoading: PropTypes.bool,
  uploadExcel: PropTypes.func,
  downloadExcel: PropTypes.func
};

PrintDocSettingTable.defaultProps = {
  intl: {},
  resetPrintDocSettingTimestamp() {},
  showPrintDocSettings() {},
  showPrintDocSettingsSuccess() {},
  resId: 0,
  printDocSettings: [],
  printDocSettingsIsLoading: false,

  uploadIsLoading: false,
  uploadExcel() {},
  downloadExcel() {}
};

const mapStateToProps = state => ({
  printDocSettingTimestamp: state.siteFlowDetail.printDocSettingTimestamp,
  resId: state.siteFlowDetail.resId,
  printDocSettings: state.siteFlowDetail.printDocSettings,
  printDocSettingsIsLoading: state.siteFlowDetail.printDocSettingsIsLoading,

  uploadIsLoading: state.siteFlowDetail.printDocSettingsUploadIsLoading
});

const mapDispatchToProps = dispatch => ({
  resetPrintDocSettingTimestamp: () =>
    dispatch(SiteFlowDetailActions.siteFlowDetailResetPrintDocSettingTimestamp()),
  showPrintDocSettings: resId =>
    dispatch(SiteFlowDetailActions.siteFlowDetailShowPrintDocSettings(resId)),
  showPrintDocSettingsSuccess: txnFlows =>
    dispatch(SiteFlowDetailActions.siteFlowDetailShowPrintDocSettingsSuccess(txnFlows)),

  uploadExcel: (resId, file) =>
    dispatch(SiteFlowDetailActions.siteFlowDetailPrintDocSettingsUploadExcel(resId, file)),
  downloadExcel: resId =>
    dispatch(SiteFlowDetailActions.siteFlowDetailPrintDocSettingsDownloadExcel(resId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PrintDocSettingTable));
