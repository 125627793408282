import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsIss01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsIss01GoToDocument: ['hdrId'],
  gdsIss01FetchGdsIss01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsIss01FetchGdsIss01Loading: ['boolean'],
  gdsIss01FetchGdsIss01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  gdsIss01AddSelectedDocuments: ['selectedDocuments'],
  gdsIss01RemoveSelectedDocuments: ['selectedDocuments'],
  gdsIss01SetWorkspaceVisible: ['boolean'],
  gdsIss01CreateGdsIss01: ['hdrIds', 'toStorageBinId'],
  gdsIss01CreateGdsIss01Loading: ['boolean'],
  gdsIss01CreateGdsIss01Success: ['newDocuments']
});

export const GdsIss01Types = Types;
export default Creators;
