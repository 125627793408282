import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stockReorderPlanningV2ReportInitStockReorderPlanningV2: null,
  stockReorderPlanningV2ReportInitStockReorderPlanningV2Success: ['criteria'],
  stockReorderPlanningV2ReportStockReorderPlanningV2: ['formikBag', 'criteria'],
  stockReorderPlanningV2ReportStockReorderPlanningV2Success: ['criteria', 'reportData'],
  stockReorderPlanningV2ReportReportLoading: ['boolean'],

  stockReorderPlanningV2ReportFetchCompanyOptions: ['search'],
  stockReorderPlanningV2ReportFetchCompanyOptionLoading: ['boolean'],
  stockReorderPlanningV2ReportFetchCompanyOptionSuccess: ['options'],

  stockReorderPlanningV2ReportFetchItemOptions: ['search'],
  stockReorderPlanningV2ReportFetchItemOptionLoading: ['boolean'],
  stockReorderPlanningV2ReportFetchItemOptionSuccess: ['options'],

  stockReorderPlanningV2ReportFetchItemGroup01Options: ['search'],
  stockReorderPlanningV2ReportFetchItemGroup01OptionLoading: ['boolean'],
  stockReorderPlanningV2ReportFetchItemGroup01OptionSuccess: ['options'],

  stockReorderPlanningV2ReportFetchItemGroup02Options: ['search'],
  stockReorderPlanningV2ReportFetchItemGroup02OptionLoading: ['boolean'],
  stockReorderPlanningV2ReportFetchItemGroup02OptionSuccess: ['options'],

  stockReorderPlanningV2ReportFetchItemGroup03Options: ['search'],
  stockReorderPlanningV2ReportFetchItemGroup03OptionLoading: ['boolean'],
  stockReorderPlanningV2ReportFetchItemGroup03OptionSuccess: ['options'],

  stockReorderPlanningV2ReportFetchLocationOptions: ['search'],
  stockReorderPlanningV2ReportFetchLocationOptionLoading: ['boolean'],
  stockReorderPlanningV2ReportFetchLocationOptionSuccess: ['options']
});

export const StockReorderPlanningV2ReportTypes = Types;
export default Creators;
