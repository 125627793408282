import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';

import { Card, Modal } from 'antd';
import DocNoTable from './DocNoTable';
import DocNoForm from './DocNoForm';
import DocTypeDocNoIndexActions from '../../Stores/DocTypeDocNoIndex/Actions';

class DocTypeDocNoIndexScreen extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    const { match, setDocType } = this.props;

    setDocType(match.params.doc_type);
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setFormVisible, tableIsLoading } = this.props;

    if (tableIsLoading === false) {
      setFormVisible(false);
    }
  }

  render() {
    const { intl, match, formIsVisible, docType } = this.props;

    return (
      <Card title={`${docType} ${intl.formatMessage({ id: 'document_codes' })}`}>
        <DocNoTable />
        <Modal
          visible={formIsVisible}
          title={intl.formatMessage({ id: 'code_detail' })}
          // style={{top:20}}
          width="80%"
          centered
          forceRender
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <DocNoForm match={match} />
        </Modal>
      </Card>
    );
  }
}

DocTypeDocNoIndexScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  setDocType: PropTypes.func,
  setFormVisible: PropTypes.func,
  tableIsLoading: PropTypes.bool,
  formIsVisible: PropTypes.bool,
  docType: PropTypes.string
};

DocTypeDocNoIndexScreen.defaultProps = {
  intl: {},
  match: {},
  setDocType() {},
  setFormVisible() {},
  tableIsLoading: false,
  formIsVisible: false,
  docType: ''
};

const mapStateToProps = state => ({
  docType: state.docTypeDocNoIndex.docType,
  tableIsLoading: state.docTypeDocNoIndex.tableIsLoading,
  formIsVisible: state.docTypeDocNoIndex.formIsVisible,
  appPath: state.app.appPath
});

const mapDispatchToProps = dispatch => ({
  setDocType: docType => dispatch(DocTypeDocNoIndexActions.docTypeDocNoIndexSetDocType(docType)),
  setFormVisible: boolean =>
    dispatch(DocTypeDocNoIndexActions.docTypeDocNoIndexSetFormVisible(boolean))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DocTypeDocNoIndexScreen));
