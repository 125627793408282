import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import CountAdjDetailActions, { CountAdjDetailTypes } from '../Stores/CountAdjDetail/Actions';

const getAppStore = state => state.app;

const getCountAdjDetailStore = state => state.countAdjDetail;

export function* countAdjDetailInitHeader() {
  try {
    yield put(CountAdjDetailActions.countAdjDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `countAdj/initHeader/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(CountAdjDetailActions.countAdjDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjDetailActions.countAdjDetailShowDocumentLoading(false));
  }
}

export function* countAdjDetailShowHeader({ hdrId }) {
  try {
    yield put(CountAdjDetailActions.countAdjDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `countAdj/showHeader/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(CountAdjDetailActions.countAdjDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    // yield put(CountAdjDetailActions.countAdjDetailShowDocumentLoading(false));
  }
}

export function* countAdjDetailTransitionToStatus({ formikBag, hdrId, docStatus }) {
  formikBag.setSubmitting(true);
  yield put(CountAdjDetailActions.countAdjDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      hdrId,
      docStatus
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'countAdj/transitionToStatus',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const countAdjDetail = yield select(getCountAdjDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = countAdjDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        CountAdjDetailActions.countAdjDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(CountAdjDetailActions.countAdjDetailShowDocumentLoading(false));
  }
}

export function* countAdjDetailUpdateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'countAdj/updateHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const countAdjDetail = yield select(getCountAdjDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = countAdjDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        CountAdjDetailActions.countAdjDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* countAdjDetailShowDetails({ hdrId }) {
  try {
    yield put(CountAdjDetailActions.countAdjDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `countAdj/showDetails/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(CountAdjDetailActions.countAdjDetailShowDetailsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjDetailActions.countAdjDetailShowDocumentLoading(false));
  }
}

export function* countAdjDetailUpdateDetails({ formikBag, hdrId, documentDetails }) {
  formikBag.setSubmitting(true);
  yield put(CountAdjDetailActions.countAdjDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetails
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `countAdj/updateDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const countAdjDetail = yield select(getCountAdjDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = countAdjDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        CountAdjDetailActions.countAdjDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(CountAdjDetailActions.countAdjDetailShowDocumentLoading(false));
  }
}

export function* countAdjDetailFetchCompanyOptions({ search }) {
  try {
    yield put(CountAdjDetailActions.countAdjDetailFetchCompanyOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `company/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(CountAdjDetailActions.countAdjDetailFetchCompanyOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjDetailActions.countAdjDetailFetchCompanyOptionLoading(false));
  }
}

export function* countAdjDetailFetchCountAdjReasonOptions({ search }) {
  try {
    yield put(CountAdjDetailActions.countAdjDetailFetchCountAdjReasonOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `countAdjReason/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(CountAdjDetailActions.countAdjDetailFetchCountAdjReasonOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjDetailActions.countAdjDetailFetchCountAdjReasonOptionLoading(false));
  }
}

export function* countAdjDetailFetchItemOptions({ search }) {
  try {
    yield put(CountAdjDetailActions.countAdjDetailFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01} ${d.unit_barcode} ${d.case_barcode}`
      }));

      yield put(CountAdjDetailActions.countAdjDetailFetchItemOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjDetailActions.countAdjDetailFetchItemOptionLoading(false));
  }
}

export function* countAdjDetailChangeItem({ formikBag, hdrId, itemId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `countAdj/changeItem`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });

      // reset the select2 cache
      formikBag.setFieldValue('uom_select2', {
        value: 0,
        label: ''
      });
      formikBag.setFieldValue('uom_rate', 1);

      yield put(CountAdjDetailActions.countAdjDetailFetchUomOptionSuccess([]));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* countAdjDetailFetchItemBatchOptions({ itemId, search }) {
  try {
    yield put(CountAdjDetailActions.countAdjDetailFetchItemBatchOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemBatch/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.batch_serial_no} | ${d.expiry_date} | ${d.receipt_date}`
      }));

      yield put(CountAdjDetailActions.countAdjDetailFetchItemBatchOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjDetailActions.countAdjDetailFetchItemBatchOptionLoading(false));
  }
}

export function* countAdjDetailChangeItemBatch({ formikBag, hdrId, itemBatchId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemBatchId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `countAdj/changeItemBatch`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* countAdjDetailFetchUomOptions({ itemId, search }) {
  try {
    yield put(CountAdjDetailActions.countAdjDetailFetchUomOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemUom/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.uom_id, label: `${d.uom_code}` }));

      yield put(CountAdjDetailActions.countAdjDetailFetchUomOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjDetailActions.countAdjDetailFetchUomOptionLoading(false));
  }
}

export function* countAdjDetailChangeUom({ formikBag, hdrId, itemId, uomId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId,
      uomId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `countAdj/changeItemUom`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* countAdjDetailFetchStorageBinOptions({ search }) {
  try {
    yield put(CountAdjDetailActions.countAdjDetailFetchStorageBinOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(CountAdjDetailActions.countAdjDetailFetchStorageBinOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjDetailActions.countAdjDetailFetchStorageBinOptionLoading(false));
  }
}

export function* countAdjDetailFetchHandlingUnitOptions({ search }) {
  try {
    yield put(CountAdjDetailActions.countAdjDetailFetchHandlingUnitOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'site_flow_id',
          value: app.curSiteFlowId
        },
        {
          field: 'status',
          value: 4 // NEW
        },
        {
          field: 'handling_type',
          value: 1 // PALLET
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `handlingUnit/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      let options = [{ value: 0, label: `` }];
      options = options.concat(result.data.data.map(d => ({ value: d.id, label: `${d.barcode}` })));

      yield put(CountAdjDetailActions.countAdjDetailFetchHandlingUnitOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjDetailActions.countAdjDetailFetchHandlingUnitOptionLoading(false));
  }
}

export function* countAdjDetailCreateDetail({ formikBag, hdrId, documentDetail }) {
  formikBag.setSubmitting(true);
  yield put(CountAdjDetailActions.countAdjDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetail
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `countAdj/createDetail/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const countAdjDetail = yield select(getCountAdjDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = countAdjDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        CountAdjDetailActions.countAdjDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );

      yield put(
        CountAdjDetailActions.countAdjDetailSetDocumentDetail(countAdjDetail.initDocumentDetail)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(CountAdjDetailActions.countAdjDetailShowDocumentLoading(false));
  }
}

export function* countAdjDetailDeleteDetail({ hdrId, documentDetail }) {
  yield put(CountAdjDetailActions.countAdjDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: [{ id: documentDetail.id }]
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `countAdj/deleteDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const countAdjDetail = yield select(getCountAdjDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = countAdjDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        CountAdjDetailActions.countAdjDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjDetailActions.countAdjDetailShowDocumentLoading(false));
  }
}

export function* countAdjDetailCreateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'countAdj/createHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/countAdjDetail/update/${result.data}`));
      yield put(CountAdjDetailActions.countAdjDetailSetHdrId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* countAdjDetailFetchStorageRowOptions({ search }) {
  try {
    yield put(CountAdjDetailActions.countAdjDetailFetchStorageRowOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageRow/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code} ${d.desc_01}` }));

      yield put(CountAdjDetailActions.countAdjDetailFetchStorageRowOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjDetailActions.countAdjDetailFetchStorageRowOptionLoading(false));
  }
}

export function* countAdjDetailFetchStorageBayOptions({ search }) {
  try {
    yield put(CountAdjDetailActions.countAdjDetailFetchStorageBayOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBay/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code} ${d.desc_01}` }));

      yield put(CountAdjDetailActions.countAdjDetailFetchStorageBayOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjDetailActions.countAdjDetailFetchStorageBayOptionLoading(false));
  }
}

export function* countAdjDetailFetchQuantBalOptions({ companyId, storageBinId, search }) {
  try {
    yield put(CountAdjDetailActions.countAdjDetailFetchQuantBalOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'company_id',
          value: companyId
        },
        {
          field: 'storage_bin_id',
          value: storageBinId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `quantBal/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: d.batch_serial_no
          ? `${d.item_code} | ${d.handling_unit_barcode} | BATCH: ${d.batch_serial_no} EXP: ${d.expiry_date} BAL: ${d.case_qty} ${d.item_case_uom_code} ${d.loose_qty} ${d.item_loose_uom_code} RSVD: (${d.reserved_case_qty} ${d.item_case_uom_code} ${d.reserved_loose_qty} ${d.item_loose_uom_code})`
          : `${d.item_code} | ${d.handling_unit_barcode} | EXP: ${d.expiry_date} BAL: ${d.case_qty} ${d.item_case_uom_code} ${d.loose_qty} ${d.item_loose_uom_code} RSVD: (${d.reserved_case_qty} ${d.item_case_uom_code} ${d.reserved_loose_qty} ${d.item_loose_uom_code})`
      }));

      yield put(CountAdjDetailActions.countAdjDetailFetchQuantBalOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjDetailActions.countAdjDetailFetchQuantBalOptionLoading(false));
  }
}

export function* countAdjDetailChangeQuantBal({ formikBag, hdrId, quantBalId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      quantBalId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `countAdj/changeQuantBal`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });

      // reset the select2 cache
      formikBag.setFieldValue('uom_select2', {
        value: 0,
        label: ''
      });
      formikBag.setFieldValue('uom_rate', 1);

      yield put(CountAdjDetailActions.countAdjDetailFetchUomOptionSuccess([]));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* countAdjDetailUploadExcel({ hdrId, file }) {
  try {
    yield put(CountAdjDetailActions.countAdjDetailUploadLoading(true));

    const app = yield select(getAppStore);

    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file', file);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `countAdj/uploadDetails/${hdrId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield put(CountAdjDetailActions.countAdjDetailResetTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjDetailActions.countAdjDetailUploadLoading(false));
  }
}

export function* countAdjDetailDownloadExcel({ hdrId }) {
  try {
    yield put(CountAdjDetailActions.countAdjDetailUploadLoading(true));

    const app = yield select(getAppStore);

    const getData = {};

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `countAdj/downloadDetails/${hdrId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      const countAdjDetail = yield select(getCountAdjDetailStore);

      const { documentHeader } = countAdjDetail;

      FileSaver.saveAs(result.data, `COUNT_ADJ_${documentHeader.doc_code}.XLSX`);
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjDetailActions.countAdjDetailUploadLoading(false));
  }
}

export const saga = [
  takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_INIT_HEADER, countAdjDetailInitHeader),
  takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_SHOW_HEADER, countAdjDetailShowHeader),

  takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_UPDATE_HEADER, countAdjDetailUpdateHeader),
  takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_CREATE_HEADER, countAdjDetailCreateHeader),

  takeLatest(
    CountAdjDetailTypes.COUNT_ADJ_DETAIL_TRANSITION_TO_STATUS,
    countAdjDetailTransitionToStatus
  ),

  takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_SHOW_DETAILS, countAdjDetailShowDetails),

  takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_UPDATE_DETAILS, countAdjDetailUpdateDetails),
  takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_DELETE_DETAIL, countAdjDetailDeleteDetail),
  takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_CREATE_DETAIL, countAdjDetailCreateDetail),

  takeLatest(
    CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_COMPANY_OPTIONS,
    countAdjDetailFetchCompanyOptions
  ),
  takeLatest(
    CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_COUNT_ADJ_REASON_OPTIONS,
    countAdjDetailFetchCountAdjReasonOptions
  ),

  takeLatest(
    CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_ITEM_OPTIONS,
    countAdjDetailFetchItemOptions
  ),
  takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_CHANGE_ITEM, countAdjDetailChangeItem),

  takeLatest(
    CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_ITEM_BATCH_OPTIONS,
    countAdjDetailFetchItemBatchOptions
  ),
  takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_CHANGE_ITEM_BATCH, countAdjDetailChangeItemBatch),

  takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_UOM_OPTIONS, countAdjDetailFetchUomOptions),
  takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_CHANGE_UOM, countAdjDetailChangeUom),

  takeLatest(
    CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_STORAGE_BIN_OPTIONS,
    countAdjDetailFetchStorageBinOptions
  ),

  takeLatest(
    CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_HANDLING_UNIT_OPTIONS,
    countAdjDetailFetchHandlingUnitOptions
  ),

  takeLatest(
    CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_STORAGE_ROW_OPTIONS,
    countAdjDetailFetchStorageRowOptions
  ),
  takeLatest(
    CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_STORAGE_BAY_OPTIONS,
    countAdjDetailFetchStorageBayOptions
  ),
  takeLatest(
    CountAdjDetailTypes.COUNT_ADJ_DETAIL_FETCH_QUANT_BAL_OPTIONS,
    countAdjDetailFetchQuantBalOptions
  ),
  takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_CHANGE_QUANT_BAL, countAdjDetailChangeQuantBal),
  takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_UPLOAD_EXCEL, countAdjDetailUploadExcel),
  takeLatest(CountAdjDetailTypes.COUNT_ADJ_DETAIL_DOWNLOAD_EXCEL, countAdjDetailDownloadExcel)
];
