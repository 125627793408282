import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import UomTable from './UomTable';

const { TabPane } = Tabs;

class UomIndexScreen extends React.PureComponent {
  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const { intl, match, appPath } = this.props;

    return (
      <Card>
        <Spin spinning={false}>
          <Tabs type="card" activeKey="uom">
            <TabPane
              tab={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Tooltip placement="bottom" title={intl.formatMessage({ id: `uom` })}>
                  <Link to={`${appPath}/uom/index/`}>
                    <FormattedMessage id="uom" />
                  </Link>
                </Tooltip>
              }
              key="uom"
            />
          </Tabs>
        </Spin>
        <UomTable match={match} />
      </Card>
    );
  }
}

UomIndexScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string
};

UomIndexScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: ''
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UomIndexScreen));
