import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  purRtnFServ02ShowSyncSetting: null,
  purRtnFServ02ShowSyncSettingLoading: ['boolean'],
  purRtnFServ02ShowSyncSettingSuccess: ['syncSetting'],
  purRtnFServ02UpdateSyncSetting: ['formikBag', 'syncSetting'],
  purRtnFServ02ShowBatchJobStatus: null,
  purRtnFServ02ShowBatchJobStatusSuccess: ['batchJobStatus'],

  purRtnFServ02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  purRtnFServ02GoToDocument: ['hdrId'],
  purRtnFServ02FetchPurRtnFServ02: ['currentPage', 'sorts', 'filters', 'pageSize'],
  purRtnFServ02FetchPurRtnFServ02Loading: ['boolean'],
  purRtnFServ02FetchPurRtnFServ02Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  purRtnFServ02AddSelectedDocuments: ['selectedDocuments'],
  purRtnFServ02RemoveSelectedDocuments: ['selectedDocuments'],
  purRtnFServ02SetWorkspaceVisible: ['boolean'],

  purRtnFServ02Export: ['hdrIds'],
  purRtnFServ02ExportSuccess: null,
  purRtnFServ02ExportLoading: ['boolean']
});

export const PurRtnFServ02Types = Types;
export default Creators;
