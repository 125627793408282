import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcptTrackingReportInitGdsRcptTracking: null,
  gdsRcptTrackingReportInitGdsRcptTrackingSuccess: ['criteria'],
  gdsRcptTrackingReportGdsRcptTracking: ['formikBag', 'criteria'],
  gdsRcptTrackingReportGdsRcptTrackingSuccess: ['criteria', 'reportData'],
  gdsRcptTrackingReportReportLoading: ['boolean'],

  gdsRcptTrackingReportFetchDivisionOptions: ['search'],
  gdsRcptTrackingReportFetchDivisionOptionLoading: ['boolean'],
  gdsRcptTrackingReportFetchDivisionOptionSuccess: ['options'],

  gdsRcptTrackingReportFetchBizPartnerOptions: ['search'],
  gdsRcptTrackingReportFetchBizPartnerOptionLoading: ['boolean'],
  gdsRcptTrackingReportFetchBizPartnerOptionSuccess: ['options'],

  gdsRcptTrackingReportFetchDebtorOptions: ['search'],
  gdsRcptTrackingReportFetchDebtorOptionLoading: ['boolean'],
  gdsRcptTrackingReportFetchDebtorOptionSuccess: ['options']
});

export const GdsRcptTrackingReportTypes = Types;
export default Creators;
