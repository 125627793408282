import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import SiteFlowDetailActions, { SiteFlowDetailTypes } from '../Stores/SiteFlowDetail/Actions';

const getAppStore = state => state.app;

export function* siteFlowDetailInitModel() {
  try {
    yield put(SiteFlowDetailActions.siteFlowDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `siteFlow/initModel`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(SiteFlowDetailActions.siteFlowDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SiteFlowDetailActions.siteFlowDetailShowModelLoading(false));
  }
}

export function* siteFlowDetailShowModel({ resId }) {
  try {
    yield put(SiteFlowDetailActions.siteFlowDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `siteFlow/showModel/${resId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(SiteFlowDetailActions.siteFlowDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SiteFlowDetailActions.siteFlowDetailShowModelLoading(false));
  }
}

export function* siteFlowDetailUpdateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'siteFlow/updateModel',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const { model: retModel } = result.data;

      yield put(SiteFlowDetailActions.siteFlowDetailUpdateModelSuccess(retModel));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* siteFlowDetailCreateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `siteFlow/createModel`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/siteFlowDetail/update/${result.data}`));
      yield put(SiteFlowDetailActions.siteFlowDetailSetResId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* siteFlowDetailFetchSiteOptions({ search }) {
  try {
    yield put(SiteFlowDetailActions.siteFlowDetailFetchSiteOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `site/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(SiteFlowDetailActions.siteFlowDetailFetchSiteOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SiteFlowDetailActions.siteFlowDetailFetchSiteOptionLoading(false));
  }
}

export function* siteFlowDetailShowWhseTxnFlows({ resId }) {
  try {
    yield put(SiteFlowDetailActions.siteFlowDetailShowWhseTxnFlowsLoading(true));

    let result = {
      isSuccess: true,
      data: []
    };

    if (resId > 0) {
      const app = yield select(getAppStore);
      const getData = {};

      result = yield call(
        ApiService.getApi, // function
        app.apiUrl,
        `whseTxnFlow/showTxnFlows/${resId}`,
        app.token,
        getData,
        'multipart/form-data' // params
      );
    }

    if (result.isSuccess === true) {
      yield put(SiteFlowDetailActions.siteFlowDetailShowWhseTxnFlowsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SiteFlowDetailActions.siteFlowDetailShowWhseTxnFlowsLoading(false));
  }
}

export function* siteFlowDetailWhseTxnFlowsUploadExcel({ resId, file }) {
  try {
    yield put(SiteFlowDetailActions.siteFlowDetailWhseTxnFlowsUploadLoading(true));

    const app = yield select(getAppStore);

    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file', file);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseTxnFlow/uploadTxnFlows/${resId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield put(SiteFlowDetailActions.siteFlowDetailResetWhseTxnFlowTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SiteFlowDetailActions.siteFlowDetailWhseTxnFlowsUploadLoading(false));
  }
}

export function* siteFlowDetailWhseTxnFlowsDownloadExcel({ resId }) {
  try {
    yield put(SiteFlowDetailActions.siteFlowDetailWhseTxnFlowsUploadLoading(true));

    const app = yield select(getAppStore);

    const getData = {};

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `whseTxnFlow/downloadTxnFlows/${resId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, `WHSE_TXN_FLOW.XLSX`);
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SiteFlowDetailActions.siteFlowDetailWhseTxnFlowsUploadLoading(false));
  }
}

export function* siteFlowDetailShowPrintDocSettings({ resId }) {
  try {
    yield put(SiteFlowDetailActions.siteFlowDetailShowPrintDocSettingsLoading(true));

    let result = {
      isSuccess: true,
      data: []
    };

    if (resId > 0) {
      const app = yield select(getAppStore);
      const getData = {};

      result = yield call(
        ApiService.getApi, // function
        app.apiUrl,
        `printDocSetting/showSiteFlowSettings/${resId}`,
        app.token,
        getData,
        'multipart/form-data' // params
      );
    }

    if (result.isSuccess === true) {
      yield put(SiteFlowDetailActions.siteFlowDetailShowPrintDocSettingsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SiteFlowDetailActions.siteFlowDetailShowPrintDocSettingsLoading(false));
  }
}

export function* siteFlowDetailPrintDocSettingsUploadExcel({ resId, file }) {
  try {
    yield put(SiteFlowDetailActions.siteFlowDetailPrintDocSettingsUploadLoading(true));

    const app = yield select(getAppStore);

    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file', file);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `printDocSetting/uploadSiteFlowSettings/${resId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield put(SiteFlowDetailActions.siteFlowDetailResetPrintDocSettingTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SiteFlowDetailActions.siteFlowDetailPrintDocSettingsUploadLoading(false));
  }
}

export function* siteFlowDetailPrintDocSettingsDownloadExcel({ resId }) {
  try {
    yield put(SiteFlowDetailActions.siteFlowDetailPrintDocSettingsUploadLoading(true));

    const app = yield select(getAppStore);

    const getData = {};

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `printDocSetting/downloadSiteFlowSettings/${resId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, `SITE_FLOW_PRINT_DOC_SETTING.XLSX`);
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SiteFlowDetailActions.siteFlowDetailPrintDocSettingsUploadLoading(false));
  }
}

export const saga = [
  takeLatest(SiteFlowDetailTypes.SITE_FLOW_DETAIL_INIT_MODEL, siteFlowDetailInitModel),
  takeLatest(SiteFlowDetailTypes.SITE_FLOW_DETAIL_SHOW_MODEL, siteFlowDetailShowModel),

  takeLatest(SiteFlowDetailTypes.SITE_FLOW_DETAIL_UPDATE_MODEL, siteFlowDetailUpdateModel),
  takeLatest(SiteFlowDetailTypes.SITE_FLOW_DETAIL_CREATE_MODEL, siteFlowDetailCreateModel),

  takeLatest(
    SiteFlowDetailTypes.SITE_FLOW_DETAIL_FETCH_SITE_OPTIONS,
    siteFlowDetailFetchSiteOptions
  ),

  takeLatest(
    SiteFlowDetailTypes.SITE_FLOW_DETAIL_SHOW_WHSE_TXN_FLOWS,
    siteFlowDetailShowWhseTxnFlows
  ),
  takeLatest(
    SiteFlowDetailTypes.SITE_FLOW_DETAIL_WHSE_TXN_FLOWS_UPLOAD_EXCEL,
    siteFlowDetailWhseTxnFlowsUploadExcel
  ),
  takeLatest(
    SiteFlowDetailTypes.SITE_FLOW_DETAIL_WHSE_TXN_FLOWS_DOWNLOAD_EXCEL,
    siteFlowDetailWhseTxnFlowsDownloadExcel
  ),

  takeLatest(
    SiteFlowDetailTypes.SITE_FLOW_DETAIL_SHOW_PRINT_DOC_SETTINGS,
    siteFlowDetailShowPrintDocSettings
  ),
  takeLatest(
    SiteFlowDetailTypes.SITE_FLOW_DETAIL_PRINT_DOC_SETTINGS_UPLOAD_EXCEL,
    siteFlowDetailPrintDocSettingsUploadExcel
  ),
  takeLatest(
    SiteFlowDetailTypes.SITE_FLOW_DETAIL_PRINT_DOC_SETTINGS_DOWNLOAD_EXCEL,
    siteFlowDetailPrintDocSettingsDownloadExcel
  )
];
