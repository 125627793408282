import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { Typography, Row, Col, Modal, Table, Button, Popover, Select } from 'antd';

import CycleCount02DetailActions from '../../Stores/CycleCount02Detail/Actions';

const { Option } = Select;
const { Title } = Typography;

class WorkspaceDialog extends React.PureComponent {
  constructor() {
    super();

    this.state = { groupCount: 1 };

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnCreateDocument = this.useOnCreateDocument.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  getSelectedDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        fixed: 'left',
        width: 50,
        align: 'right',
        title: '#',
        // sort field
        dataIndex: 'line_no',
        sorter: (a, b) => a.line_no - b.line_no,
        // filter field
        key: 'line_no',
        render: (text, record) => <span style={{ wordBreak: 'normal' }}>{record.line_no}</span>
      },
      {
        fixed: 'left',
        width: 70,
        align: 'right',
        title: (
          <Popover
            title={intl.formatMessage({ id: 'job' })}
            content={intl.formatMessage({ id: 'job_no' })}
            trigger="hover"
          >
            J
          </Popover>
        ),
        // sort field
        dataIndex: 'job_no',
        sorter: (a, b) => a.job_no - b.job_no,
        // filter field
        key: 'job_no',
        render: (text, record) => <>{record.job_no}</>
      },
      {
        fixed: 'left',
        width: 70,
        align: 'right',
        title: (
          <Popover
            title={intl.formatMessage({ id: 'group' })}
            content={intl.formatMessage({ id: 'group_no' })}
            trigger="hover"
          >
            G
          </Popover>
        ),
        // sort field
        dataIndex: 'group_no',
        sorter: (a, b) => a.group_no - b.group_no,
        // filter field
        key: 'group_no',
        render: (text, record) => record.group_no
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'storage_bin' }),
        // sort field
        dataIndex: 'storage_bin_code',
        sorter: (a, b) => `${a.storage_bin_code}`.localeCompare(b.storage_bin_code),
        // filter field
        key: 'storage_bin_code',
        render: (text, record) => <>{record.storage_bin_code}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'item_code',
        sorter: (a, b) => `${a.item_code}`.localeCompare(b.item_code),
        // filter field
        key: 'item_code',
        render: (text, record) => <>{record.item_code}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'pallet_id' }),
        // sort field
        dataIndex: 'handling_unit_barcode',
        sorter: (a, b) => `${a.handling_unit_barcode}`.localeCompare(b.handling_unit_barcode),
        // filter field
        key: 'handling_unit_barcode',
        render: (text, record) => <>{record.handling_unit_barcode}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        sorter: (a, b) => `${a.desc_01}`.localeCompare(b.desc_01),
        // filter field
        key: 'desc_01',
        render: (text, record) => (
          <>
            {record.desc_01}
            {record.desc_02 ? (
              <>
                <br />
                {record.desc_02}
              </>
            ) : (
              ''
            )}
            {record.desc_03 ? (
              <>
                <br />
                {record.desc_03}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'item_batch' }),
        // sort field
        dataIndex: 'expiry_date',
        sorter: (a, b) => new Date(a.expiry_date) - new Date(b.expiry_date),
        // filter field
        key: 'expiry_date',
        render: (text, record) => (
          <>
            <div style={{ fontWeight: 'bold' }}>{record.batch_serial_no}</div>
            {record.expiry_date ? <div>{record.expiry_date}</div> : ''}
            {record.receipt_date ? <>{record.receipt_date}</> : ''}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'balance_qty' }),
        // sort field
        dataIndex: 'balance_unit_qty',
        sorter: (a, b) => a.balance_unit_qty - b.balance_unit_qty,
        // filter field
        key: 'balance_unit_qty',
        render: (text, record) =>
          new Intl.NumberFormat([], {
            style: 'decimal',
            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
          }).format(record.balance_unit_qty)
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'count_qty' }),
        // sort field
        dataIndex: 'ttl_count_unit_qty',
        sorter: (a, b) => a.ttl_count_unit_qty - b.ttl_count_unit_qty,
        // filter field
        key: 'ttl_count_unit_qty',
        render: (text, record) =>
          new Intl.NumberFormat([], {
            style: 'decimal',
            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
          }).format(record.ttl_count_unit_qty)
      },
      {
        width: 100,
        align: 'right',
        title: `${intl.formatMessage({ id: 'variance' })} %`,
        // sort field
        dataIndex: 'variance_perc',
        sorter: (a, b) => a.variance_perc - b.variance_perc,
        // filter field
        key: 'variance_perc',
        render: (text, record) =>
          new Intl.NumberFormat([], {
            style: 'decimal',
            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
          }).format(record.variance_perc)
      }
    ];
  }

  getNewDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'str_doc_status',
        sorter: (a, b) => `${a.str_doc_status}`.localeCompare(b.str_doc_status),
        // filter field
        key: 'str_doc_status',
        render: (text, record) => (
          <>
            <b>{record.str_doc_status}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'created_date' }),
        // sort field
        dataIndex: 'created_at',
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        // filter field
        key: 'created_at',
        render: (text, record) => <>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'updated_date' }),
        // sort field
        dataIndex: 'updated_at',
        sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
        // filter field
        key: 'updated_at',
        render: (text, record) => <>{moment(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}</>
      }
    ];
  }

  useOnCreateDocument() {
    const { hdrId, createCycleCount02 } = this.props;
    const { groupCount } = this.state;

    createCycleCount02(groupCount, hdrId);
  }

  render() {
    const {
      intl,
      newDocuments,
      documentDetails,
      workspaceIsVisible,
      documentIsLoading
    } = this.props;

    const { groupCount } = this.state;

    const groupOptions = [
      <Option value={1} key={1}>
        1
      </Option>,
      <Option value={2} key={2}>
        2
      </Option>,
      <Option value={3} key={3}>
        3
      </Option>,
      <Option value={4} key={4}>
        4
      </Option>,
      <Option value={5} key={5}>
        5
      </Option>
    ];

    const recountDetails = [];
    documentDetails.forEach(storageBinDetail => {
      storageBinDetail.details.forEach(curValue => {
        if (curValue.str_physical_count_status === 'MARK_RECOUNT') {
          recountDetails.push(curValue);
        }
      });
    });

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'create_cycle_count_from_recount' })}
          // style={{top:20}}
          width="80%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              key="create"
              disabled={recountDetails.length === 0}
              loading={documentIsLoading}
              onClick={this.useOnCreateDocument}
            >
              {intl.formatMessage({ id: 'create_cycle_count' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={4}>
              <Title level={4}>{intl.formatMessage({ id: 'group' })}</Title>
            </Col>
            <Col span={1}>
              <Title level={4}>:</Title>
            </Col>
            <Col span={18}>
              <Select
                showSearch
                value={groupCount}
                style={{ width: 350 }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onChange={value => {
                  this.setState({ groupCount: value });
                }}
                notFoundContent={null}
              >
                {groupOptions}
              </Select>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                size="small"
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={recountDetails}
                loading={documentIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'recount_details' })}</b>}
              />
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                size="small"
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getNewDocumentColumns()}
                dataSource={newDocuments}
                loading={documentIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'new_cycle_counts' })}</b>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

WorkspaceDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  documentDetails: PropTypes.arrayOf(PropTypes.object),
  newDocuments: PropTypes.arrayOf(PropTypes.object),

  documentIsLoading: PropTypes.bool,
  hdrId: PropTypes.number,

  createCycleCount02: PropTypes.func
};

WorkspaceDialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  documentDetails: [],
  newDocuments: [],

  documentIsLoading: false,
  hdrId: 0,

  createCycleCount02() {}
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.cycleCount02Detail.workspaceIsVisible,

  documentDetails: state.cycleCount02Detail.documentDetails,

  hdrId: state.cycleCount02Detail.hdrId,
  newDocuments: state.cycleCount02Detail.newDocuments,
  documentIsLoading: state.cycleCount02Detail.documentIsLoading
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean =>
    dispatch(CycleCount02DetailActions.cycleCount02DetailSetWorkspaceVisible(boolean)),

  createCycleCount02: (groupCount, hdrId) =>
    dispatch(CycleCount02DetailActions.cycleCount02DetailCreateCycleCount02(groupCount, hdrId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WorkspaceDialog));
