import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  creditorDetailResetModelTimestamp: null,
  creditorDetailSetResId: ['resId'],

  creditorDetailInitModel: null,
  creditorDetailShowModel: ['resId'],
  creditorDetailShowModelSuccess: ['model'],

  creditorDetailUpdateModel: ['formikBag', 'model'],
  creditorDetailCreateModel: ['formikBag', 'model'],
  creditorDetailUpdateModelSuccess: ['model'],

  creditorDetailShowModelLoading: ['boolean'],

  creditorDetailResetBizPartnerTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  creditorDetailShowBizPartners: ['resId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  creditorDetailShowBizPartnerSuccess: [
    'bizPartners',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  creditorDetailGoToBizPartner: ['bizPartnerId'],
  creditorDetailNewBizPartner: ['creditorId']
});

export const CreditorDetailTypes = Types;
export default Creators;
