import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsIssIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsIssIndexGoToDocument: ['hdrId'],
  gdsIssIndexGoToAudit: ['hdrId'],
  gdsIssIndexFetchGdsIssIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsIssIndexFetchGdsIssIndexLoading: ['boolean'],
  gdsIssIndexFetchGdsIssIndexSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  gdsIssIndexAddSelectedDocuments: ['selectedDocuments'],
  gdsIssIndexRemoveSelectedDocuments: ['selectedDocuments'],
  gdsIssIndexSetWorkspaceVisible: ['boolean'],
  gdsIssIndexCreateGdsIssIndex: ['hdrIds'],
  gdsIssIndexCreateGdsIssIndexLoading: ['boolean'],
  gdsIssIndexCreateGdsIssIndexSuccess: ['newDocuments'],

  gdsIssIndexWorkspaceLoading: ['boolean'],
  gdsIssIndexResetGdsIssFServ01: ['hdrIds'],
  gdsIssIndexResetGdsIssFServ01Success: null
});

export const GdsIssIndexTypes = Types;
export default Creators;
