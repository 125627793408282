import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  userExcel01ShowBatchJobStatus: null,
  userExcel01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  userExcel01UploadExcel: ['file'],
  userExcel01UploadLoading: ['boolean'],
  userExcel01DownloadExcel: null
});

export const UserExcel01Types = Types;
export default Creators;
