import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import SalesProcessActions from '../../Stores/SalesProcess/Actions';

import SlsOrdSync01Form from '../SlsOrdSync01/SlsOrdSync01Form';
import OutbOrd01Table from '../OutbOrd01/OutbOrd01Table';
import SlsInvSync01Table from '../SlsInvSync01/SlsInvSync01Table';
import DelOrdFServ01Table from '../DelOrdFServ01/DelOrdFServ01Table';
import SlsOrdExcel01Form from '../SlsOrdExcel01/SlsOrdExcel01Form';
import SlsOrdFServ01Form from '../SlsOrdFServ01/SlsOrdFServ01Form';
import SlsOrdFServ02Table from '../SlsOrdFServ02/SlsOrdFServ02Table';
import DelOrdSync01Table from '../DelOrdSync01/DelOrdSync01Table';

const { TabPane } = Tabs;

class SalesProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curDivisionId, fetchSalesProcess } = this.props;

    if (curDivisionId > 0) {
      fetchSalesProcess();
    }
  }

  componentDidUpdate(prevProps) {
    const { curDivisionId, fetchSalesProcess } = this.props;
    const { changed } = treeChanges(prevProps, this.props);
    if (changed('curDivisionId')) {
      if (curDivisionId > 0) {
        fetchSalesProcess();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath, processIsLoading } = this.props;

    return (
      <Card>
        <Spin spinning={processIsLoading}>
          <Tabs type="card" activeKey={match.params.proc_type}>
            {process.map(value => (
              <TabPane
                tab={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                  >
                    <Link
                      style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                      to={`${appPath}/salesProcess/index/${value.proc_type}`}
                    >
                      <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                    </Link>
                  </Tooltip>
                }
                key={value.proc_type}
              />
            ))}
          </Tabs>
        </Spin>

        {match.params.proc_type === 'SLS_ORD_SYNC_01' && <SlsOrdSync01Form match={match} />}
        {match.params.proc_type === 'OUTB_ORD_01' && <OutbOrd01Table match={match} />}
        {match.params.proc_type === 'SLS_INV_SYNC_01' && <SlsInvSync01Table match={match} />}
        {match.params.proc_type === 'DEL_ORD_F_SERV_01' && <DelOrdFServ01Table match={match} />}
        {match.params.proc_type === 'SLS_ORD_EXCEL_01' && <SlsOrdExcel01Form match={match} />}
        {match.params.proc_type === 'SLS_ORD_F_SERV_01' && <SlsOrdFServ01Form match={match} />}
        {match.params.proc_type === 'SLS_ORD_F_SERV_02' && <SlsOrdFServ02Table match={match} />}
        {match.params.proc_type === 'DEL_ORD_SYNC_01' && <DelOrdSync01Table match={match} />}
      </Card>
    );
  }
}

SalesProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),
  processIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchSalesProcess: PropTypes.func
};

SalesProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],
  processIsLoading: false,

  curDivisionId: 0,
  fetchSalesProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.salesProcess.process,
  processIsLoading: state.salesProcess.processIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  fetchSalesProcess: () => dispatch(SalesProcessActions.salesProcessFetchSalesProcess())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SalesProcessScreen));
