/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { TransportDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const transportDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const transportDetailSetResId = (state, { resId }) => ({
  ...state,
  timestamp: Date.now(),
  modelIsLoading: false,
  resId
});

export const transportDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const transportDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const transportDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const transportDetailFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const transportDetailFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [TransportDetailTypes.TRANSPORT_DETAIL_RESET_TIMESTAMP]: transportDetailResetTimestamp,
  [TransportDetailTypes.TRANSPORT_DETAIL_SET_RES_ID]: transportDetailSetResId,
  [TransportDetailTypes.TRANSPORT_DETAIL_SHOW_MODEL_LOADING]: transportDetailShowModelLoading,
  [TransportDetailTypes.TRANSPORT_DETAIL_SHOW_MODEL_SUCCESS]: transportDetailShowModelSuccess,
  [TransportDetailTypes.TRANSPORT_DETAIL_UPDATE_MODEL_SUCCESS]: transportDetailUpdateModelSuccess,

  [TransportDetailTypes.TRANSPORT_DETAIL_FETCH_LOCATION_OPTION_LOADING]: transportDetailFetchLocationOptionLoading,
  [TransportDetailTypes.TRANSPORT_DETAIL_FETCH_LOCATION_OPTION_SUCCESS]: transportDetailFetchLocationOptionSuccess
});
