/**
 * The initial values for the redux state.
 * Naming Convention: ModuleSummaryInformation, eg slsOrdSummaryOutbOrds, pickListSummaryWhseJobs
 */
 export default {
    slsOrdSummarySlsOrdsTimestamp: 0,
    slsOrdSummarySlsOrds: { total: 0, date_oldest: "0 days" },
    slsOrdSummarySlsOrdsIsLoading: false,

    slsOrdSummaryOutbOrdsTimestamp: 0,
    slsOrdSummaryOutbOrds: { total: 0, date_oldest: "0 days" },
    slsOrdSummaryOutbOrdsIsLoading: false,
    
    slsOrdSummaryInvDocsTimestamp: 0,
    slsOrdSummaryInvDocs: { total: 0, date_oldest: "0 days" },
    slsOrdSummaryInvDocsIsLoading: false,

    slsOrdSummaryNoPrfDelsTimestamp: 0,
    slsOrdSummaryNoPrfDels: { total: 0, date_oldest: "0 days" },
    slsOrdSummaryNoPrfDelsIsLoading:  false,
    
    lspSlsOrdSummarySlsOrdsTimestamp: 0,
    lspSlsOrdSummarySlsOrds: { total: 0, date_oldest: "0 days" },
    lspSlsOrdSummarySlsOrdsIsLoading: false,
    
    lspSlsOrdSummaryOutbOrdsTimestamp: 0,
    lspSlsOrdSummaryOutbOrds: { total: 0, date_oldest: "0 days" },
    lspSlsOrdSummaryOutbOrdsIsLoading: false,
    
    lspSlsOrdSummaryInvDocsTimestamp: 0,
    lspSlsOrdSummaryInvDocs: { total: 0, date_oldest: "0 days" },
    lspSlsOrdSummaryInvDocsIsLoading: false,
    
    lspSlsOrdSummaryNoPrfDelsTimestamp: 0,
    lspSlsOrdSummaryNoPrfDels: { total: 0, date_oldest: "0 days" },
    lspSlsOrdSummaryNoPrfDelsIsLoading:  false,

    pickListSummaryOutbOrdsTimestamp: 0,
    pickListSummaryOutbOrds: { total: 0, date_oldest: "0 days" },
    pickListSummaryOutbOrdsIsLoading: false,

    pickListSummaryWipTimestamp: 0,
    pickListSummaryWip: { total: 0, date_oldest: "0 days" },
    pickListSummaryWipIsLoading: false,

    pickListSummaryWhseJobsTimestamp: 0,
    pickListSummaryWhseJobs: { total: 0, date_oldest: "0 days" },
    pickListSummaryWhseJobsIsLoading: false,

    pickListSummaryCompletedTimestamp: 0,
    pickListSummaryCompleted: { total: 0, date_oldest: "0 days" },
    pickListSummaryCompletedIsLoading:  false,

    loadListSummaryPendingTimestamp: 0,
    loadListSummaryPending: { total: 0, date_oldest: "0 days" },
    loadListSummaryPendingIsLoading: false,

    loadListSummaryWipTimestamp: 0,
    loadListSummaryWip: { total: 0, date_oldest: "0 days" },
    loadListSummaryWipIsLoading: false,
    
    loadListSummaryWhseJobsTimestamp: 0,
    loadListSummaryWhseJobs: { total: 0, date_oldest: "0 days" },
    loadListSummaryWhseJobsIsLoading: false,
    
    loadListSummaryCompletedTimestamp: 0,
    loadListSummaryCompleted: { total: 0, date_oldest: "0 days" },
    loadListSummaryCompletedIsLoading:  false,

  };
  