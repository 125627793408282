import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography } from 'antd';
import { FormikSelect, FormikButton, FormikInput } from '../../Components/Formik';
import CycleCountDetailActions from '../../Stores/CycleCountDetail/Actions';

const { Text } = Typography;

class JobDetailForm extends React.PureComponent {
  constructor() {
    super();

    this.handleCompanySearch = this.handleCompanySearch.bind(this);
    this.handleSearchStorageRow = this.handleSearchStorageRow.bind(this);
    this.handleSearchStorageBay = this.handleSearchStorageBay.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCompanySearch(form, value) {
    const { fetchCompanyOptions } = this.props;

    fetchCompanyOptions(value);
  }

  handleSearchStorageRow(form, value) {
    const { fetchStorageRowOptions } = this.props;

    fetchStorageRowOptions(value);
  }

  handleSearchStorageBay(form, value) {
    const { fetchStorageBayOptions } = this.props;

    fetchStorageBayOptions(value);
  }

  render() {
    const {
      intl,
      hdrId,
      documentHeader,
      createJobDetail,
      documentIsLoading,
      companyIsLoading,
      companyOptions,
      storageRowIsLoading,
      storageRowOptions,
      storageBayIsLoading,
      storageBayOptions
    } = this.props;

    const levelOptions = [
      {
        value: 1,
        label: 1
      },
      {
        value: 2,
        label: 2
      },
      {
        value: 3,
        label: 3
      },
      {
        value: 4,
        label: 4
      },
      {
        value: 5,
        label: 5
      },
      {
        value: 6,
        label: 6
      },
      {
        value: 7,
        label: 7
      },
      {
        value: 8,
        label: 8
      },
      {
        value: 9,
        label: 9
      },
      {
        value: 10,
        label: 10
      }
    ];

    const disabled = documentHeader && documentHeader.doc_status >= 50;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          company_select2: { value: 0, label: '' },
          desc: '',
          row_ids_select2: [],
          bay_ids_select2: [],
          levels_select2: []
        }}
        onSubmit={(values, formikBag) => {
          const companyId = values.company_select2.value;
          const rowIds = values.row_ids_select2.map(d => d.value);
          const bayIds = values.bay_ids_select2.map(d => d.value);
          const levels = values.levels_select2.map(d => d.value);
          createJobDetail(formikBag, hdrId, companyId, values.desc, rowIds, bayIds, levels);
        }}
        validationSchema={Yup.object().shape({
          company_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'company_is_required' }))
          }),
          desc: Yup.string().required(intl.formatMessage({ id: 'description_is_required' }))
          // row_ids_select2: Yup.array().min(1, intl.formatMessage({ id: 'storage_row_is_required' }))
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'company' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="company_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleCompanySearch}
                  notFoundContent={null}
                  loading={companyIsLoading}
                  options={companyOptions}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'description' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="desc" component={FormikInput} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'storage_row' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="row_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchStorageRow}
                  notFoundContent={null}
                  loading={storageRowIsLoading}
                  options={storageRowOptions}
                  disabled={disabled}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'storage_bay' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="bay_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchStorageBay}
                  notFoundContent={null}
                  loading={storageBayIsLoading}
                  options={storageBayOptions}
                  disabled={disabled}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'level' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="levels_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  // onSearch={this.handleSearchStorageBay}
                  notFoundContent={null}
                  options={levelOptions}
                  disabled={disabled}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="end" gutter={[0, 16]}>
              <Col span={5}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  type="primary"
                  name="submit_action"
                  value="update"
                  component={FormikButton}
                  disabled={disabled}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: values.id > 0 ? 'update' : 'create'
                  })}
                  icon={<SaveOutlined />}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

JobDetailForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  createJobDetail: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentIsLoading: PropTypes.bool,

  fetchCompanyOptions: PropTypes.func,
  companyIsLoading: PropTypes.bool,
  companyOptions: PropTypes.arrayOf(PropTypes.object),

  fetchStorageRowOptions: PropTypes.func,
  storageRowIsLoading: PropTypes.bool,
  storageRowOptions: PropTypes.arrayOf(PropTypes.object),

  fetchStorageBayOptions: PropTypes.func,
  storageBayIsLoading: PropTypes.bool,
  storageBayOptions: PropTypes.arrayOf(PropTypes.object)
};

JobDetailForm.defaultProps = {
  intl: {},
  createJobDetail() {},
  hdrId: 0,
  documentHeader: { doc_status: 3 },
  documentIsLoading: false,

  fetchCompanyOptions() {},
  companyIsLoading: false,
  companyOptions: [],

  fetchStorageRowOptions() {},
  storageRowIsLoading: false,
  storageRowOptions: [],

  fetchStorageBayOptions() {},
  storageBayIsLoading: false,
  storageBayOptions: []
};

const mapStateToProps = state => ({
  hdrId: state.cycleCountDetail.hdrId,

  companyIsLoading: state.cycleCountDetail.companyIsLoading,
  companyOptions: state.cycleCountDetail.companyOptions,

  storageRowIsLoading: state.cycleCountDetail.storageRowIsLoading,
  storageRowOptions: state.cycleCountDetail.storageRowOptions,

  storageBayIsLoading: state.cycleCountDetail.storageBayIsLoading,
  storageBayOptions: state.cycleCountDetail.storageBayOptions
});

const mapDispatchToProps = dispatch => ({
  createJobDetail: (formikBag, hdrId, companyId, desc, rowIds, bayIds, levels) =>
    dispatch(
      CycleCountDetailActions.cycleCountDetailCreateJobDetail(
        formikBag,
        hdrId,
        companyId,
        desc,
        rowIds,
        bayIds,
        levels
      )
    ),

  fetchCompanyOptions: search =>
    dispatch(CycleCountDetailActions.cycleCountDetailFetchCompanyOptions(search)),

  fetchStorageRowOptions: search =>
    dispatch(CycleCountDetailActions.cycleCountDetailFetchStorageRowOptions(search)),
  fetchStorageBayOptions: search =>
    dispatch(CycleCountDetailActions.cycleCountDetailFetchStorageBayOptions(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(JobDetailForm));
