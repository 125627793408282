import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  outbOrd04ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  outbOrd04GoToDocument: ['hdrId'],
  outbOrd04NewDocument: null,
  outbOrd04FetchOutbOrd04: ['currentPage', 'sorts', 'filters', 'pageSize'],
  outbOrd04FetchOutbOrd04Loading: ['boolean'],
  outbOrd04FetchOutbOrd04Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  outbOrd04AddSelectedDocuments: ['selectedDocuments'],
  outbOrd04RemoveSelectedDocuments: ['selectedDocuments'],
  outbOrd04SetWorkspaceVisible: ['boolean'],
  outbOrd04CreateOutbOrd04: ['hdrIds'],
  outbOrd04CreateOutbOrd04Loading: ['boolean'],
  outbOrd04CreateOutbOrd04Success: ['newDocuments'],
  outbOrd04CheckStock: ['hdrIds'],

  outbOrd04SetExpandedRows: ['expandedRows']
});

export const OutbOrd04Types = Types;
export default Creators;
