import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  delOrdDetailResetTimestamp: null,
  delOrdDetailSetHdrId: ['hdrId', 'itemId'],

  delOrdDetailInitHeader: null,
  delOrdDetailShowHeader: ['hdrId'],
  delOrdDetailShowHeaderSuccess: ['documentHeader'],

  delOrdDetailUpdateHeader: ['formikBag', 'documentHeader'],
  delOrdDetailCreateHeader: ['formikBag', 'documentHeader'],
  delOrdDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  delOrdDetailShowDocumentLoading: ['boolean'],
  delOrdDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  delOrdDetailShowDetails: ['hdrId'],
  delOrdDetailShowDetailsSuccess: ['documentDetails'],

  delOrdDetailSetDetailVisible: ['boolean'],
  delOrdDetailSetDocumentDetail: ['documentDetail'],

  delOrdDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  delOrdDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  delOrdDetailDeleteDetail: ['hdrId', 'documentDetail'],

  delOrdDetailFetchSalesmanOptions: ['search'],
  delOrdDetailFetchSalesmanOptionLoading: ['boolean'],
  delOrdDetailFetchSalesmanOptionSuccess: ['options'],

  delOrdDetailFetchDeliveryPointOptions: ['search'],
  delOrdDetailFetchDeliveryPointOptionLoading: ['boolean'],
  delOrdDetailFetchDeliveryPointOptionSuccess: ['options'],
  delOrdDetailChangeDeliveryPoint: ['formikBag', 'deliveryPointId'],

  delOrdDetailFetchCreditTermOptions: ['search'],
  delOrdDetailFetchCreditTermOptionLoading: ['boolean'],
  delOrdDetailFetchCreditTermOptionSuccess: ['options'],

  delOrdDetailFetchCurrencyOptions: ['search'],
  delOrdDetailFetchCurrencyOptionLoading: ['boolean'],
  delOrdDetailFetchCurrencyOptionSuccess: ['options'],
  delOrdDetailChangeCurrency: ['formikBag', 'currencyId'],

  delOrdDetailFetchLocationOptions: ['search'],
  delOrdDetailFetchLocationOptionLoading: ['boolean'],
  delOrdDetailFetchLocationOptionSuccess: ['options'],

  delOrdDetailFetchItemOptions: ['search'],
  delOrdDetailFetchItemOptionLoading: ['boolean'],
  delOrdDetailFetchItemOptionSuccess: ['options'],
  delOrdDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  delOrdDetailFetchUomOptions: ['itemId', 'search'],
  delOrdDetailFetchUomOptionLoading: ['boolean'],
  delOrdDetailFetchUomOptionSuccess: ['options'],
  delOrdDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  delOrdDetailFetchProjectOptions: ['search'],
  delOrdDetailFetchProjectOptionLoading: ['boolean'],
  delOrdDetailFetchProjectOptionSuccess: ['options'],

  delOrdDetailSetPickedIsVisible: ['boolean'],
  delOrdDetailShowPickedLoading: ['boolean'],
  delOrdDetailShowPickedDetails: ['hdrId'],
  delOrdDetailShowPickedDetailsSuccess: ['pickedDetails']
});

export const DelOrdDetailTypes = Types;
export default Creators;
