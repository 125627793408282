import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsInvDetailResetTimestamp: null,
  slsInvDetailSetHdrId: ['hdrId', 'itemId'],

  slsInvDetailInitHeader: null,
  slsInvDetailShowHeader: ['hdrId'],
  slsInvDetailShowHeaderSuccess: ['documentHeader'],

  slsInvDetailUpdateHeader: ['formikBag', 'documentHeader'],
  slsInvDetailCreateHeader: ['formikBag', 'documentHeader'],
  slsInvDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  slsInvDetailShowDocumentLoading: ['boolean'],
  slsInvDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  slsInvDetailShowDetails: ['hdrId'],
  slsInvDetailShowDetailsSuccess: ['documentDetails'],

  slsInvDetailSetDetailVisible: ['boolean'],
  slsInvDetailSetDocumentDetail: ['documentDetail'],

  slsInvDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  slsInvDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  slsInvDetailDeleteDetail: ['hdrId', 'documentDetail'],

  slsInvDetailFetchSalesmanOptions: ['search'],
  slsInvDetailFetchSalesmanOptionLoading: ['boolean'],
  slsInvDetailFetchSalesmanOptionSuccess: ['options'],

  slsInvDetailFetchDeliveryPointOptions: ['search'],
  slsInvDetailFetchDeliveryPointOptionLoading: ['boolean'],
  slsInvDetailFetchDeliveryPointOptionSuccess: ['options'],
  slsInvDetailChangeDeliveryPoint: ['formikBag', 'deliveryPointId'],

  slsInvDetailFetchCreditTermOptions: ['search'],
  slsInvDetailFetchCreditTermOptionLoading: ['boolean'],
  slsInvDetailFetchCreditTermOptionSuccess: ['options'],

  slsInvDetailFetchCurrencyOptions: ['search'],
  slsInvDetailFetchCurrencyOptionLoading: ['boolean'],
  slsInvDetailFetchCurrencyOptionSuccess: ['options'],
  slsInvDetailChangeCurrency: ['formikBag', 'currencyId'],

  slsInvDetailFetchLocationOptions: ['search'],
  slsInvDetailFetchLocationOptionLoading: ['boolean'],
  slsInvDetailFetchLocationOptionSuccess: ['options'],

  slsInvDetailFetchItemOptions: ['search'],
  slsInvDetailFetchItemOptionLoading: ['boolean'],
  slsInvDetailFetchItemOptionSuccess: ['options'],
  slsInvDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  slsInvDetailFetchUomOptions: ['itemId', 'search'],
  slsInvDetailFetchUomOptionLoading: ['boolean'],
  slsInvDetailFetchUomOptionSuccess: ['options'],
  slsInvDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  slsInvDetailFetchProjectOptions: ['search'],
  slsInvDetailFetchProjectOptionLoading: ['boolean'],
  slsInvDetailFetchProjectOptionSuccess: ['options'],

  slsInvDetailSetPickedIsVisible: ['boolean'],
  slsInvDetailShowPickedLoading: ['boolean'],
  slsInvDetailShowPickedDetails: ['hdrId'],
  slsInvDetailShowPickedDetailsSuccess: ['pickedDetails']
});

export const SlsInvDetailTypes = Types;
export default Creators;
