import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  purRtnSync01ShowSyncSetting: null,
  purRtnSync01ShowSyncSettingLoading: ['boolean'],
  purRtnSync01ShowSyncSettingSuccess: ['syncSetting'],
  purRtnSync01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  purRtnSync01ShowBatchJobStatus: null,
  purRtnSync01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  purRtnSync01SyncNow: ['formikBag'],
  purRtnSync01SyncLoading: ['boolean']
});

export const PurRtnSync01Types = Types;
export default Creators;
