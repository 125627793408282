/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { DebtorDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const debtorDetailResetModelTimestamp = state => ({
  ...state,
  modelTimestamp: Date.now()
});

export const debtorDetailSetResId = (state, { resId }) => ({
  ...state,
  modelTimestamp: Date.now(),
  resId
});

export const debtorDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const debtorDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const debtorDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const debtorDetailShowDeliveryPointSuccess = (
  state,
  { deliveryPoints, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  deliveryPoints,
  deliveryPointCurrentPage: currentPage,
  deliveryPointLastPage: lastPage,
  deliveryPointTotal: total,
  deliveryPointPageSize: pageSize
});

export const debtorDetailFetchAreaOptionLoading = (state, { boolean }) => ({
  ...state,
  areaIsLoading: boolean
});

export const debtorDetailFetchAreaOptionSuccess = (state, { options }) => ({
  ...state,
  areaOptions: options
});

export const debtorDetailResetDeliveryPointTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  deliveryPointCurrentPage: currentPage || state.deliveryPointCurrentPage,
  deliveryPointSorts: sorts || state.deliveryPointSorts,
  deliveryPointFilters: filters || state.deliveryPointFilters,
  deliveryPointPageSize: pageSize || state.deliveryPointPageSize,
  deliveryPointTimestamp: Date.now()
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [DebtorDetailTypes.DEBTOR_DETAIL_RESET_MODEL_TIMESTAMP]: debtorDetailResetModelTimestamp,
  [DebtorDetailTypes.DEBTOR_DETAIL_SET_RES_ID]: debtorDetailSetResId,
  [DebtorDetailTypes.DEBTOR_DETAIL_SHOW_MODEL_LOADING]: debtorDetailShowModelLoading,
  [DebtorDetailTypes.DEBTOR_DETAIL_SHOW_MODEL_SUCCESS]: debtorDetailShowModelSuccess,
  [DebtorDetailTypes.DEBTOR_DETAIL_UPDATE_MODEL_SUCCESS]: debtorDetailUpdateModelSuccess,

  [DebtorDetailTypes.DEBTOR_DETAIL_FETCH_AREA_OPTION_LOADING]: debtorDetailFetchAreaOptionLoading,
  [DebtorDetailTypes.DEBTOR_DETAIL_FETCH_AREA_OPTION_SUCCESS]: debtorDetailFetchAreaOptionSuccess,

  [DebtorDetailTypes.DEBTOR_DETAIL_RESET_DELIVERY_POINT_TIMESTAMP]: debtorDetailResetDeliveryPointTimestamp,
  [DebtorDetailTypes.DEBTOR_DETAIL_SHOW_DELIVERY_POINT_SUCCESS]: debtorDetailShowDeliveryPointSuccess
});
