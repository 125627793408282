import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  creditorProcessFetchCreditorProcess: null,
  creditorProcessFetchCreditorProcessLoading: ['boolean'],
  creditorProcessFetchCreditorProcessSuccess: ['process']
});

export const CreditorProcessTypes = Types;
export default Creators;
