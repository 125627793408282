/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { UserList01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const userList01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedResources: INITIAL_STATE.selectedResources
});

export const userList01FetchUserList01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const userList01FetchUserList01Success = (
  state,
  { resources, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  resources,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const userList01AddSelectedResources = (state, { selectedResources }) => {
  const selectIds = state.selectedResources.map(value => value.id);
  return {
    ...state,
    selectedResources: [
      ...state.selectedResources,
      ...selectedResources.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const userList01RemoveSelectedResources = (state, { selectedResources }) => {
  const selectIds = selectedResources.map(value => value.id);
  return {
    ...state,
    selectedResources: state.selectedResources.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const userList01SetManageRoleVisible = (state, { resource, boolean }) => ({
  ...state,
  selectedResource: resource,
  manageRoleIsVisible: boolean
});

export const userList01ManageRoleLoading = (state, { boolean }) => ({
  ...state,
  assignIsLoading: boolean
});

export const userList01ManageRoleSuccess = (state, { roles }) => ({
  ...state,
  selectedResource: {
    ...state.selectedResource,
    roles
  }
});

export const userList01FetchRoleOptionLoading = (state, { boolean }) => ({
  ...state,
  roleIsLoading: boolean
});

export const userList01FetchRoleOptionSuccess = (state, { options }) => ({
  ...state,
  roleOptions: options
});

export const userList01SetRoleOption = (state, { option }) => ({
  ...state,
  roleOption: option
});

export const userList01SetBarcodeVisible = (state, { boolean }) => ({
  ...state,
  barcodeDialogIsVisible: boolean
});

export const userList01ShowBarcode = (state, { barcode, username, firstName, lastName }) => ({
  ...state,
  barcodeDialogBarcode: barcode,
  barcodeDialogUsername: username,
  barcodeDialogFirstName: firstName,
  barcodeDialogLastName: lastName,
  barcodeDialogIsVisible: true
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [UserList01Types.USER_LIST01_RESET_TIMESTAMP]: userList01ResetTimestamp,
  [UserList01Types.USER_LIST01_FETCH_USER_LIST01_LOADING]: userList01FetchUserList01Loading,
  [UserList01Types.USER_LIST01_FETCH_USER_LIST01_SUCCESS]: userList01FetchUserList01Success,
  [UserList01Types.USER_LIST01_ADD_SELECTED_RESOURCES]: userList01AddSelectedResources,
  [UserList01Types.USER_LIST01_REMOVE_SELECTED_RESOURCES]: userList01RemoveSelectedResources,
  [UserList01Types.USER_LIST01_SET_MANAGE_ROLE_VISIBLE]: userList01SetManageRoleVisible,
  [UserList01Types.USER_LIST01_MANAGE_ROLE_LOADING]: userList01ManageRoleLoading,
  [UserList01Types.USER_LIST01_MANAGE_ROLE_SUCCESS]: userList01ManageRoleSuccess,

  [UserList01Types.USER_LIST01_FETCH_ROLE_OPTION_LOADING]: userList01FetchRoleOptionLoading,
  [UserList01Types.USER_LIST01_FETCH_ROLE_OPTION_SUCCESS]: userList01FetchRoleOptionSuccess,
  [UserList01Types.USER_LIST01_SET_ROLE_OPTION]: userList01SetRoleOption,
  [UserList01Types.USER_LIST01_SET_BARCODE_VISIBLE]: userList01SetBarcodeVisible,
  [UserList01Types.USER_LIST01_SHOW_BARCODE]: userList01ShowBarcode
});
