import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  delOrdIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  delOrdIndexFetchDelOrdIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  delOrdIndexFetchDelOrdIndexLoading: ['boolean'],
  delOrdIndexFetchDelOrdIndexSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  delOrdIndexAddSelectedDocuments: ['selectedDocuments'],
  delOrdIndexRemoveSelectedDocuments: ['selectedDocuments'],
  delOrdIndexSetWorkspaceVisible: ['boolean'],
  delOrdIndexGoToDocument: ['hdrId'],
  delOrdIndexGoToAudit: ['hdrId'],

  delOrdIndexWorkspaceLoading: ['boolean'],
  delOrdIndexResetDelOrdFServ01: ['hdrIds'],
  delOrdIndexResetDelOrdFServ01Success: null
});

export const DelOrdIndexTypes = Types;
export default Creators;
