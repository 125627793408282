/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { InvDoc02Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const invDoc02ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const invDoc02FetchInvDoc02Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const invDoc02FetchInvDoc02Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const invDoc02AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const invDoc02RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const invDoc02SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const invDoc02CreateInvDoc02Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const invDoc02CreateInvDoc02Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [InvDoc02Types.INV_DOC02_RESET_TIMESTAMP]: invDoc02ResetTimestamp,
  [InvDoc02Types.INV_DOC02_FETCH_INV_DOC02_LOADING]: invDoc02FetchInvDoc02Loading,
  [InvDoc02Types.INV_DOC02_FETCH_INV_DOC02_SUCCESS]: invDoc02FetchInvDoc02Success,
  [InvDoc02Types.INV_DOC02_ADD_SELECTED_DOCUMENTS]: invDoc02AddSelectedDocuments,
  [InvDoc02Types.INV_DOC02_REMOVE_SELECTED_DOCUMENTS]: invDoc02RemoveSelectedDocuments,
  [InvDoc02Types.INV_DOC02_SET_WORKSPACE_VISIBLE]: invDoc02SetWorkspaceVisible,
  [InvDoc02Types.INV_DOC02_CREATE_INV_DOC02_LOADING]: invDoc02CreateInvDoc02Loading,
  [InvDoc02Types.INV_DOC02_CREATE_INV_DOC02_SUCCESS]: invDoc02CreateInvDoc02Success
});
