import { put, select, call, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import WorkerPerformanceChartActions, {
  WorkerPerformanceChartTypes
} from '../Stores/WorkerPerformanceChart/Actions';

const getAppStore = state => state.app;

export function* workerPerformanceChartFetch({ criteria }) {
  try {
    yield put(WorkerPerformanceChartActions.workerPerformanceChartFetchIsLoading(true));

    const processedFilters = [];
    if ('whse_job_proc' in criteria) {
      const value = criteria.whse_job_proc;
      if (value !== null && value.length > 0) {
        processedFilters.push(`whse_job_proc:${value}`);
      }
    }

    if ('time_period_type' in criteria) {
      const value = criteria.time_period_type;
      if (value !== null && value.length > 0) {
        processedFilters.push(`time_period_type:${value}`);
      }
    }

    if ('query_date' in criteria) {
      const value = criteria.query_date; // stringify time period
      if (value !== null && value.length > 0) {
        processedFilters.push(`query_date:${value}`);
      }
    }
    const app = yield select(getAppStore);

    const postData = {
      criteria: processedFilters
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `workerReport/workerPerformanceAnalysis/${app.curSiteFlowId}`,
      app.token,
      postData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(WorkerPerformanceChartActions.workerPerformanceChartFetchSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WorkerPerformanceChartActions.workerPerformanceChartFetchIsLoading(false));
  }
}

export const saga = [
  takeLatest(
    WorkerPerformanceChartTypes.WORKER_PERFORMANCE_CHART_FETCH,
    workerPerformanceChartFetch
  )
];
