import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  transportDetailResetTimestamp: null,
  transportDetailSetResId: ['resId'],

  transportDetailInitModel: null,
  transportDetailShowModel: ['resId'],
  transportDetailShowModelSuccess: ['model'],

  transportDetailUpdateModel: ['formikBag', 'model'],
  transportDetailCreateModel: ['formikBag', 'model'],
  transportDetailUpdateModelSuccess: ['model'],

  transportDetailShowModelLoading: ['boolean'],

  transportDetailFetchLocationOptions: ['search'],
  transportDetailFetchLocationOptionLoading: ['boolean'],
  transportDetailFetchLocationOptionSuccess: ['options']
});

export const TransportDetailTypes = Types;
export default Creators;
