import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import PurchaseReturnProcessActions from '../../Stores/PurchaseReturnProcess/Actions';

import OutbOrd08Table from '../OutbOrd08/OutbOrd08Table';
import GdsIssFServ01Table from '../GdsIssFServ01/GdsIssFServ01Table';
import AdvShipFServ01Form from '../AdvShipFServ01/AdvShipFServ01Form';
import PurRtnFServ02Table from '../PurRtnFServ02/PurRtnFServ02Table';
import PurRtnSync01Form from '../PurRtnSync01/PurRtnSync01Form';
import GdsIssSync01Table from '../GdsIssSync01/GdsIssSync01Table';

const { TabPane } = Tabs;

class PurchaseReturnProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curDivisionId, fetchPurchaseReturnProcess } = this.props;

    if (curDivisionId > 0) {
      fetchPurchaseReturnProcess();
    }
  }

  componentDidUpdate(prevProps) {
    const { curDivisionId, fetchPurchaseReturnProcess } = this.props;
    const { changed } = treeChanges(prevProps, this.props);
    if (changed('curDivisionId')) {
      if (curDivisionId > 0) {
        fetchPurchaseReturnProcess();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath, processIsLoading } = this.props;

    return (
      <Card>
        <Spin spinning={processIsLoading}>
          <Tabs type="card" activeKey={match.params.proc_type}>
            {process.map(value => (
              <TabPane
                tab={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                  >
                    <Link
                      style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                      to={`${appPath}/purchaseReturnProcess/index/${value.proc_type}`}
                    >
                      <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                    </Link>
                  </Tooltip>
                }
                key={value.proc_type}
              />
            ))}
          </Tabs>
        </Spin>

        {match.params.proc_type === 'OUTB_ORD_08' && <OutbOrd08Table match={match} />}
        {match.params.proc_type === 'GDS_ISS_F_SERV_01' && <GdsIssFServ01Table match={match} />}
        {match.params.proc_type === 'ADV_SHIP_F_SERV_01' && <AdvShipFServ01Form match={match} />}
        {match.params.proc_type === 'PUR_RTN_F_SERV_02' && <PurRtnFServ02Table match={match} />}
        {match.params.proc_type === 'PUR_RTN_SYNC_01' && <PurRtnSync01Form match={match} />}
        {match.params.proc_type === 'GDS_ISS_SYNC_01' && <GdsIssSync01Table match={match} />}
      </Card>
    );
  }
}

PurchaseReturnProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),
  processIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchPurchaseReturnProcess: PropTypes.func
};

PurchaseReturnProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],
  processIsLoading: false,

  curDivisionId: 0,
  fetchPurchaseReturnProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.purchaseReturnProcess.process,
  processIsLoading: state.purchaseReturnProcess.processIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  fetchPurchaseReturnProcess: () =>
    dispatch(PurchaseReturnProcessActions.purchaseReturnProcessFetchPurchaseReturnProcess())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PurchaseReturnProcessScreen));
