import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { DeleteOutlined } from '@ant-design/icons';

import { Table, Popconfirm, Button, Row, Col, Typography, Select } from 'antd';

import UserDetailActions from '../../Stores/UserDetail/Actions';

const { Text } = Typography;
const { Option } = Select;

class DivisionTable extends React.PureComponent {
  constructor() {
    super();

    this.state = { pageSize: 20 };

    this.getDocumentColumns = this.getDocumentColumns.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.useOnAddDivision = this.useOnAddDivision.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { resId, showUserDivisions } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('timestamp')) {
      showUserDivisions(resId);
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl, resId, removeUserDivision } = this.props;

    return [
      {
        // fixed: 'left',
        width: 50,
        align: 'right',
        title: '#',
        key: 'line_no',
        render: (text, record, index) => <span style={{ wordBreak: 'normal' }}>{index + 1}</span>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        // filter field
        key: 'code',
        render: (text, record) => record.division_code
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'ref_code_01' }),
        // sort field
        dataIndex: 'ref_code_01',
        // filter field
        key: 'ref_code_01',
        render: (text, record) => record.division_ref_code_01
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'desc_01' }),
        // sort field
        dataIndex: 'desc_01',
        // filter field
        key: 'desc_01',
        render: (text, record) => record.division_desc_01
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'desc_02' }),
        // sort field
        dataIndex: 'desc_02',
        // filter field
        key: 'desc_02',
        render: (text, record) => record.division_desc_02
      },
      {
        width: 50,
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_division' })}
              onConfirm={() => {
                removeUserDivision(resId, record.id);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnAddDivision() {
    const { resId, divisionOption, addUserDivision } = this.props;

    if (divisionOption.value > 0) {
      addUserDivision(resId, divisionOption.value);
    }
  }

  handleSearch(value) {
    const { resId, fetchDivisionOptions } = this.props;
    fetchDivisionOptions(resId, value);
  }

  handleChange(value, option) {
    const { setDivisionOption } = this.props;
    const curOption = { value: parseInt(option.key, 10), label: option.props.children };
    setDivisionOption(curOption);
  }

  render() {
    const {
      intl,
      userDivisions,
      userDivisionIsLoading,
      divisionOption,
      divisionOptions,
      divisionOptionIsLoading
    } = this.props;
    const { pageSize: statePageSize } = this.state;

    const options = divisionOptions.map(d => <Option key={d.value}>{`${d.label}`}</Option>);

    return (
      <>
        <Row type="flex" justify="start" gutter={[0, 8]}>
          <Col span={3}>
            <Text>{intl.formatMessage({ id: 'new_division' })}</Text>
          </Col>
          <Col span={8}>
            <Select
              showSearch
              value={divisionOption.label}
              placeholder={intl.formatMessage({ id: 'key_in_code_or_description' })}
              style={{ width: 300 }}
              defaultActiveFirstOption={false}
              showArrow
              filterOption={false}
              onSearch={this.handleSearch}
              onChange={this.handleChange}
              notFoundContent={null}
              loading={divisionOptionIsLoading}
              onDropdownVisibleChange={open => {
                if (open && options.length === 0) {
                  this.handleSearch('');
                }
              }}
            >
              {options}
            </Select>
          </Col>
          <Col span={3}>
            <Button
              type="primary"
              disabled={divisionOption.value === 0}
              loading={userDivisionIsLoading}
              onClick={this.useOnAddDivision}
            >
              {intl.formatMessage({ id: 'add' })}
            </Button>
          </Col>
        </Row>
        <Row type="flex" justify="start" gutter={[0, 8]}>
          <Col span={24}>
            <Table
              // title={() => intl.formatMessage({ id: 'divisions' })}
              // rowSelection={rowSelection}
              rowKey="id"
              pagination={{
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50'],
                onShowSizeChange: (current, pageSize) => {
                  this.setState({
                    pageSize
                  });
                },
                pageSize: statePageSize
              }}
              columns={this.getDocumentColumns()}
              dataSource={userDivisions}
              loading={userDivisionIsLoading}
              bordered
              scroll={{ x: 900 }}
            />
          </Col>
        </Row>
      </>
    );
  }
}

DivisionTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  showUserDivisions: PropTypes.func,
  userDivisions: PropTypes.arrayOf(PropTypes.object),
  userDivisionIsLoading: PropTypes.bool,
  removeUserDivision: PropTypes.func,

  resId: PropTypes.number,

  divisionOption: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string
  }),
  divisionOptions: PropTypes.arrayOf(PropTypes.object),
  divisionOptionIsLoading: PropTypes.bool,
  fetchDivisionOptions: PropTypes.func,
  setDivisionOption: PropTypes.func,

  addUserDivision: PropTypes.func
};

DivisionTable.defaultProps = {
  intl: {},

  showUserDivisions() {},
  userDivisions: [],
  userDivisionIsLoading: false,
  removeUserDivision() {},

  resId: 0,

  divisionOption: { value: 0, label: '' },
  divisionOptions: [],
  divisionOptionIsLoading: false,
  fetchDivisionOptions() {},
  setDivisionOption() {},

  addUserDivision() {}
};

const mapStateToProps = state => ({
  timestamp: state.userDetail.timestamp,
  resId: state.userDetail.resId,
  userDivisions: state.userDetail.userDivisions,
  userDivisionIsLoading: state.userDetail.userDivisionIsLoading,

  divisionOption: state.userDetail.divisionOption,
  divisionOptions: state.userDetail.divisionOptions,
  divisionOptionIsLoading: state.userDetail.divisionOptionIsLoading
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: () => dispatch(UserDetailActions.userDetailResetTimestamp()),
  showUserDivisions: resId => dispatch(UserDetailActions.userDetailShowUserDivisions(resId)),

  fetchDivisionOptions: (resId, search) =>
    dispatch(UserDetailActions.userDetailFetchDivisionOptions(resId, search)),
  setDivisionOption: option => dispatch(UserDetailActions.userDetailSetDivisionOption(option)),

  addUserDivision: (resId, divisionId) =>
    dispatch(UserDetailActions.userDetailAddUserDivision(resId, divisionId)),
  removeUserDivision: (resId, userDivisionId) =>
    dispatch(UserDetailActions.userDetailRemoveUserDivision(resId, userDivisionId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DivisionTable));
