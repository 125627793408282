import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  outbOrd05ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  outbOrd05GoToPrint: ['document'],
  outbOrd05GoToDocument: ['hdrId'],
  outbOrd05NewDocument: null,
  outbOrd05FetchOutbOrd05: ['currentPage', 'sorts', 'filters', 'pageSize'],
  outbOrd05FetchOutbOrd05Loading: ['boolean'],
  outbOrd05FetchOutbOrd05Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  outbOrd05AddSelectedDocuments: ['selectedDocuments'],
  outbOrd05RemoveSelectedDocuments: ['selectedDocuments'],
  outbOrd05SetWorkspaceVisible: ['boolean'],
  outbOrd05CreateOutbOrd05: ['hdrIds'],
  outbOrd05CreateOutbOrd05Loading: ['boolean'],
  outbOrd05CreateOutbOrd05Success: ['newDocuments'],
  outbOrd05CheckStock: ['hdrIds'],

  outbOrd05SetExpandedRows: ['expandedRows']
});

export const OutbOrd05Types = Types;
export default Creators;
