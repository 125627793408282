/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { OutOfStockAnalysisReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const outOfStockAnalysisReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const outOfStockAnalysisReportInitOutOfStockAnalysisSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const outOfStockAnalysisReportOutOfStockAnalysisSuccess = (
  state,
  { criteria, reportData }
) => ({
  ...state,
  criteria,
  reportData
});

export const outOfStockAnalysisReportFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const outOfStockAnalysisReportFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const outOfStockAnalysisReportFetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const outOfStockAnalysisReportFetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const outOfStockAnalysisReportFetchItemGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup02IsLoading: boolean
});

export const outOfStockAnalysisReportFetchItemGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup02Options: options
});

export const outOfStockAnalysisReportFetchItemGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup03IsLoading: boolean
});

export const outOfStockAnalysisReportFetchItemGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup03Options: options
});

export const outOfStockAnalysisReportFetchDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  divisionIsLoading: boolean
});

export const outOfStockAnalysisReportFetchDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  divisionOptions: options
});

export const outOfStockAnalysisReportFetchDeliveryPointOptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryPointIsLoading: boolean
});

export const outOfStockAnalysisReportFetchDeliveryPointOptionSuccess = (state, { options }) => ({
  ...state,
  deliveryPointOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_REPORT_LOADING]: outOfStockAnalysisReportReportLoading,
  [OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_INIT_OUT_OF_STOCK_ANALYSIS_SUCCESS]: outOfStockAnalysisReportInitOutOfStockAnalysisSuccess,
  [OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_OUT_OF_STOCK_ANALYSIS_SUCCESS]: outOfStockAnalysisReportOutOfStockAnalysisSuccess,

  [OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_ITEM_OPTION_LOADING]: outOfStockAnalysisReportFetchItemOptionLoading,
  [OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_ITEM_OPTION_SUCCESS]: outOfStockAnalysisReportFetchItemOptionSuccess,

  [OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_ITEM_GROUP01_OPTION_LOADING]: outOfStockAnalysisReportFetchItemGroup01OptionLoading,
  [OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: outOfStockAnalysisReportFetchItemGroup01OptionSuccess,

  [OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_ITEM_GROUP02_OPTION_LOADING]: outOfStockAnalysisReportFetchItemGroup02OptionLoading,
  [OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_ITEM_GROUP02_OPTION_SUCCESS]: outOfStockAnalysisReportFetchItemGroup02OptionSuccess,

  [OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_ITEM_GROUP03_OPTION_LOADING]: outOfStockAnalysisReportFetchItemGroup03OptionLoading,
  [OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_ITEM_GROUP03_OPTION_SUCCESS]: outOfStockAnalysisReportFetchItemGroup03OptionSuccess,

  [OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_ITEM_OPTION_LOADING]: outOfStockAnalysisReportFetchItemOptionLoading,
  [OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_ITEM_OPTION_SUCCESS]: outOfStockAnalysisReportFetchItemOptionSuccess,

  [OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_DIVISION_OPTION_LOADING]: outOfStockAnalysisReportFetchDivisionOptionLoading,
  [OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_DIVISION_OPTION_SUCCESS]: outOfStockAnalysisReportFetchDivisionOptionSuccess,

  [OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_DELIVERY_POINT_OPTION_LOADING]: outOfStockAnalysisReportFetchDeliveryPointOptionLoading,
  [OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_DELIVERY_POINT_OPTION_SUCCESS]: outOfStockAnalysisReportFetchDeliveryPointOptionSuccess
});
