/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PurRtnDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const purRtnDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const purRtnDetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false,
  purchaserOptions: [],
  bizPartnerOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const purRtnDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const purRtnDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const purRtnDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const purRtnDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const purRtnDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const purRtnDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  itemOptions: [],
  itemBatchOptions: [],
  uomOptions: []
});

export const purRtnDetailFetchPurchaserOptionLoading = (state, { boolean }) => ({
  ...state,
  purchaserIsLoading: boolean
});

export const purRtnDetailFetchPurchaserOptionSuccess = (state, { options }) => ({
  ...state,
  purchaserOptions: options
});

export const purRtnDetailFetchBizPartnerOptionLoading = (state, { boolean }) => ({
  ...state,
  bizPartnerIsLoading: boolean
});

export const purRtnDetailFetchBizPartnerOptionSuccess = (state, { options }) => ({
  ...state,
  bizPartnerOptions: options
});

export const purRtnDetailFetchCreditTermOptionLoading = (state, { boolean }) => ({
  ...state,
  creditTermIsLoading: boolean
});

export const purRtnDetailFetchCreditTermOptionSuccess = (state, { options }) => ({
  ...state,
  creditTermOptions: options
});

export const purRtnDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const purRtnDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

export const purRtnDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const purRtnDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const purRtnDetailFetchItemBatchOptionLoading = (state, { boolean }) => ({
  ...state,
  itemBatchIsLoading: boolean
});

export const purRtnDetailFetchItemBatchOptionSuccess = (state, { options }) => ({
  ...state,
  itemBatchOptions: options
});

export const purRtnDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const purRtnDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const purRtnDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const purRtnDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const purRtnDetailFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const purRtnDetailFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const purRtnDetailFetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const purRtnDetailFetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PurRtnDetailTypes.PUR_RTN_DETAIL_RESET_TIMESTAMP]: purRtnDetailResetTimestamp,
  [PurRtnDetailTypes.PUR_RTN_DETAIL_SET_HDR_ID]: purRtnDetailSetHdrId,

  [PurRtnDetailTypes.PUR_RTN_DETAIL_SHOW_HEADER_SUCCESS]: purRtnDetailShowHeaderSuccess,

  [PurRtnDetailTypes.PUR_RTN_DETAIL_UPDATE_DOCUMENT_SUCCESS]: purRtnDetailUpdateDocumentSuccess,

  [PurRtnDetailTypes.PUR_RTN_DETAIL_SHOW_DOCUMENT_LOADING]: purRtnDetailShowDocumentLoading,

  [PurRtnDetailTypes.PUR_RTN_DETAIL_SHOW_DETAILS_SUCCESS]: purRtnDetailShowDetailsSuccess,

  [PurRtnDetailTypes.PUR_RTN_DETAIL_SET_DETAIL_VISIBLE]: purRtnDetailSetDetailVisible,
  [PurRtnDetailTypes.PUR_RTN_DETAIL_SET_DOCUMENT_DETAIL]: purRtnDetailSetDocumentDetail,

  [PurRtnDetailTypes.PUR_RTN_DETAIL_UPDATE_DETAILS]: purRtnDetailUpdateDetails,
  [PurRtnDetailTypes.PUR_RTN_DETAIL_CREATE_DETAIL]: purRtnDetailCreateDetail,

  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_PURCHASER_OPTION_LOADING]: purRtnDetailFetchPurchaserOptionLoading,
  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_PURCHASER_OPTION_SUCCESS]: purRtnDetailFetchPurchaserOptionSuccess,

  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_BIZ_PARTNER_OPTION_LOADING]: purRtnDetailFetchBizPartnerOptionLoading,
  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_BIZ_PARTNER_OPTION_SUCCESS]: purRtnDetailFetchBizPartnerOptionSuccess,

  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_CREDIT_TERM_OPTION_LOADING]: purRtnDetailFetchCreditTermOptionLoading,
  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_CREDIT_TERM_OPTION_SUCCESS]: purRtnDetailFetchCreditTermOptionSuccess,

  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: purRtnDetailFetchCurrencyOptionLoading,
  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: purRtnDetailFetchCurrencyOptionSuccess,

  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_ITEM_OPTION_LOADING]: purRtnDetailFetchItemOptionLoading,
  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: purRtnDetailFetchItemOptionSuccess,

  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_ITEM_BATCH_OPTION_LOADING]: purRtnDetailFetchItemBatchOptionLoading,
  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_ITEM_BATCH_OPTION_SUCCESS]: purRtnDetailFetchItemBatchOptionSuccess,

  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_UOM_OPTION_LOADING]: purRtnDetailFetchUomOptionLoading,
  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_UOM_OPTION_SUCCESS]: purRtnDetailFetchUomOptionSuccess,

  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_LOCATION_OPTION_LOADING]: purRtnDetailFetchLocationOptionLoading,
  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_LOCATION_OPTION_SUCCESS]: purRtnDetailFetchLocationOptionSuccess,

  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_PROJECT_OPTION_LOADING]: purRtnDetailFetchProjectOptionLoading,
  [PurRtnDetailTypes.PUR_RTN_DETAIL_FETCH_PROJECT_OPTION_SUCCESS]: purRtnDetailFetchProjectOptionSuccess
});
