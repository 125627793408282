import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  outbOrd06ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  outbOrd06GoToPrint: ['document'],
  outbOrd06GoToDocument: ['hdrId'],
  outbOrd06NewDocument: null,
  outbOrd06FetchOutbOrd06: ['currentPage', 'sorts', 'filters', 'pageSize'],
  outbOrd06FetchOutbOrd06Loading: ['boolean'],
  outbOrd06FetchOutbOrd06Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  outbOrd06AddSelectedDocuments: ['selectedDocuments'],
  outbOrd06RemoveSelectedDocuments: ['selectedDocuments'],
  outbOrd06SetWorkspaceVisible: ['boolean'],
  outbOrd06CreateOutbOrd06: ['hdrIds'],
  outbOrd06CreateOutbOrd06Loading: ['boolean'],
  outbOrd06CreateOutbOrd06Success: ['newDocuments'],
  outbOrd06CheckStock: ['hdrIds'],

  outbOrd06SetExpandedRows: ['expandedRows']
});

export const OutbOrd06Types = Types;
export default Creators;
