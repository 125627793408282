import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  outbOrd02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  outbOrd02GoToDocument: ['hdrId'],
  outbOrd02NewDocument: null,
  outbOrd02FetchOutbOrd02: ['currentPage', 'sorts', 'filters', 'pageSize'],
  outbOrd02FetchOutbOrd02Loading: ['boolean'],
  outbOrd02FetchOutbOrd02Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  outbOrd02AddSelectedDocuments: ['selectedDocuments'],
  outbOrd02RemoveSelectedDocuments: ['selectedDocuments'],
  outbOrd02SetWorkspaceVisible: ['boolean'],
  outbOrd02CreateOutbOrd02: ['hdrIds'],
  outbOrd02CreateOutbOrd02Loading: ['boolean'],
  outbOrd02CreateOutbOrd02Success: ['newDocuments'],
  outbOrd02CheckStock: ['hdrIds'],

  outbOrd02SetExpandedRows: ['expandedRows']
});

export const OutbOrd02Types = Types;
export default Creators;
