/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  fetchIsLoading: false,
  sorts: {
    est_del_date: 'ascend'
  },
  filters: {},
  pageSize: '15',
  currentPage: 1,
  lastPage: 10,
  total: 0,
  rowLevels: [],

  selectedStorageBin: {},
  selectedQuantBals: []
};
