export const ItemTypeMap = {
  STOCK_ITEM: 1,
  1: 'STOCK_ITEM',

  FINISHED_ITEM: 100,
  100: 'FINISHED_ITEM',

  SEMI_FINISHED_ITEM: 101,
  101: 'SEMI_FINISHED_ITEM',

  RAW_MATERIAL: 200,
  200: 'RAW_MATERIAL',

  PACKING_MATERIAL: 201,
  201: 'PACKING_MATERIAL',

  NON_STOCK_ITEM: 1000,
  1000: 'NON_STOCK_ITEM',

  ITEM_CHARGE_OR_REBATE: 1001,
  1001: 'ITEM_CHARGE_OR_REBATE'
};

export const ItemTypeEnumOptions = [
  { value: ItemTypeMap.STOCK_ITEM, label: 'STOCK_ITEM' },
  { value: ItemTypeMap.FINISHED_ITEM, label: 'FINISHED_ITEM' },
  { value: ItemTypeMap.SEMI_FINISHED_ITEM, label: 'SEMI_FINISHED_ITEM' },
  { value: ItemTypeMap.RAW_MATERIAL, label: 'RAW_MATERIAL' },
  { value: ItemTypeMap.PACKING_MATERIAL, label: 'PACKING_MATERIAL' },
  { value: ItemTypeMap.NON_STOCK_ITEM, label: 'NON_STOCK_ITEM' },
  { value: ItemTypeMap.ITEM_CHARGE_OR_REBATE, label: 'ITEM_CHARGE_OR_REBATE' }
];
