import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace, push } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import DebtorDetailActions, { DebtorDetailTypes } from '../Stores/DebtorDetail/Actions';

const getAppStore = state => state.app;

export function* debtorDetailInitModel() {
  try {
    yield put(DebtorDetailActions.debtorDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const debtorType = 0; // debtor

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `debtor/initModel/${debtorType}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DebtorDetailActions.debtorDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DebtorDetailActions.debtorDetailShowModelLoading(false));
  }
}

export function* debtorDetailShowModel({ resId }) {
  try {
    yield put(DebtorDetailActions.debtorDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const debtorType = 0; // debtor

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `debtor/showModel/${debtorType}/${resId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DebtorDetailActions.debtorDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DebtorDetailActions.debtorDetailShowModelLoading(false));
  }
}

export function* debtorDetailUpdateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'debtor/updateModel',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const { model: retModel } = result.data;

      yield put(DebtorDetailActions.debtorDetailUpdateModelSuccess(retModel));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* debtorDetailShowDeliveryPoints({ resId, currentPage, sorts, filters, pageSize }) {
  try {
    yield put(DebtorDetailActions.debtorDetailShowModelLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `debtor/showDeliveryPoints/${resId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        DebtorDetailActions.debtorDetailShowDeliveryPointSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DebtorDetailActions.debtorDetailShowModelLoading(false));
  }
}

export function* debtorDetailCreateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const debtorType = 0; // debtor
    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `debtor/createModel/${debtorType}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/debtorDetail/update/${result.data}/GENERAL`));
      yield put(DebtorDetailActions.debtorDetailSetResId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* debtorDetailFetchAreaOptions({ search }) {
  try {
    yield put(DebtorDetailActions.debtorDetailFetchAreaOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `area/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(DebtorDetailActions.debtorDetailFetchAreaOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DebtorDetailActions.debtorDetailFetchAreaOptionLoading(false));
  }
}

export function* debtorDetailGoToDeliveryPoint({ deliveryPointId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/deliveryPointDetail/update/${deliveryPointId}`));
}

export function* debtorDetailNewDeliveryPoint({ debtorId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/deliveryPointDetail/create/${debtorId}`));
}

export const saga = [
  takeLatest(DebtorDetailTypes.DEBTOR_DETAIL_INIT_MODEL, debtorDetailInitModel),
  takeLatest(DebtorDetailTypes.DEBTOR_DETAIL_SHOW_MODEL, debtorDetailShowModel),

  takeLatest(DebtorDetailTypes.DEBTOR_DETAIL_UPDATE_MODEL, debtorDetailUpdateModel),
  takeLatest(DebtorDetailTypes.DEBTOR_DETAIL_CREATE_MODEL, debtorDetailCreateModel),

  takeLatest(DebtorDetailTypes.DEBTOR_DETAIL_SHOW_DELIVERY_POINTS, debtorDetailShowDeliveryPoints),

  takeLatest(DebtorDetailTypes.DEBTOR_DETAIL_FETCH_AREA_OPTIONS, debtorDetailFetchAreaOptions),

  takeLatest(DebtorDetailTypes.DEBTOR_DETAIL_GO_TO_DELIVERY_POINT, debtorDetailGoToDeliveryPoint),

  takeLatest(DebtorDetailTypes.DEBTOR_DETAIL_NEW_DELIVERY_POINT, debtorDetailNewDeliveryPoint)
];
