import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import GdsRcpt02Actions, { GdsRcpt02Types } from '../Stores/GdsRcpt02/Actions';

const getAppStore = state => state.app;

export function* gdsRcpt02FetchGdsRcpt02({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(GdsRcpt02Actions.gdsRcpt02FetchGdsRcpt02Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `gdsRcpt/indexProcess/GDS_RCPT_02/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        GdsRcpt02Actions.gdsRcpt02FetchGdsRcpt02Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcpt02Actions.gdsRcpt02FetchGdsRcpt02Loading(false));
  }
}

export function* gdsRcpt02PrintGdsRcpt02({ hdrIds }) {
  try {
    yield put(GdsRcpt02Actions.gdsRcpt02CreateGdsRcpt02Loading(true));

    const app = yield select(getAppStore);

    const postData = {
      hdrIds
    };

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `gdsRcpt/printProcess/GDS_RCPT_02_02/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, 'GdsRcpt0202.pdf');
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcpt02Actions.gdsRcpt02CreateGdsRcpt02Loading(false));
  }
}

export function* gdsRcpt02CreateGdsRcpt02({ hdrIds, toStorageBinId, minPalletQtyPerc }) {
  try {
    yield put(GdsRcpt02Actions.gdsRcpt02CreateGdsRcpt02Loading(true));

    const app = yield select(getAppStore);

    const postData = {
      hdrIds,
      toStorageBinId,
      minPalletQtyPerc
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `gdsRcpt/createProcess/GDS_RCPT_02`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(GdsRcpt02Actions.gdsRcpt02CreateGdsRcpt02Success(result.data));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcpt02Actions.gdsRcpt02CreateGdsRcpt02Loading(false));
  }
}

export function* gdsRcpt02FetchToStorageBinOptions({ search }) {
  try {
    yield put(GdsRcpt02Actions.gdsRcpt02FetchToStorageBinOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: [
        {
          field: 'bin_types',
          value: [
            1, // UNLOADING_AREA
            3, // UNLOADING_LOADING_AREA
            4, // BULK_STORAGE
            5, // PALLET_STORAGE
            6, // BROKEN_PALLET_STORAGE
            7, // CASE_STORAGE
            8, // BROKEN_CASE_STORAGE
            11, // BAD_PALLET_STORAGE
            12 // BAD_BROKEN_CASE_STORAGE
          ]
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code} ${d.desc_01}` }));

      yield put(GdsRcpt02Actions.gdsRcpt02FetchToStorageBinOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcpt02Actions.gdsRcpt02FetchToStorageBinOptionLoading(false));
  }
}

export function* gdsRcpt02GoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/inbOrdDetail/update/${hdrId}`));
}

export const saga = [
  takeLatest(GdsRcpt02Types.GDS_RCPT02_GO_TO_DOCUMENT, gdsRcpt02GoToDocument),
  takeLatest(GdsRcpt02Types.GDS_RCPT02_FETCH_GDS_RCPT02, gdsRcpt02FetchGdsRcpt02),
  takeLatest(
    GdsRcpt02Types.GDS_RCPT02_FETCH_TO_STORAGE_BIN_OPTIONS,
    gdsRcpt02FetchToStorageBinOptions
  ),
  takeLatest(GdsRcpt02Types.GDS_RCPT02_PRINT_GDS_RCPT02, gdsRcpt02PrintGdsRcpt02),
  takeLatest(GdsRcpt02Types.GDS_RCPT02_CREATE_GDS_RCPT02, gdsRcpt02CreateGdsRcpt02)
];
