import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { ReloadOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button, Upload, notification } from 'antd';

import SiteFlowDetailActions from '../../Stores/SiteFlowDetail/Actions';

class TxnFlowTable extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      fileList: []
    };

    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.useOnUpload = this.useOnUpload.bind(this);
    this.useOnDownload = this.useOnDownload.bind(this);
  }

  componentDidMount() {
    const { resetWhseTxnFlowTimestamp } = this.props;

    resetWhseTxnFlowTimestamp();
  }

  componentDidUpdate(prevProps) {
    const { resId, showWhseTxnFlows, showWhseTxnFlowsSuccess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('whseTxnFlowTimestamp')) {
      if (resId === 0) {
        showWhseTxnFlowsSuccess([]);
      } else {
        showWhseTxnFlows(resId);
      }
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'flow' }),
        // sort field
        dataIndex: 'str_txn_flow_type',
        // filter field
        key: 'str_txn_flow_type',
        render: (text, record) => <>{record.str_txn_flow_type}</>
      },
      {
        width: 50,
        align: 'right',
        title: intl.formatMessage({ id: 'step' }),
        // sort field
        dataIndex: 'step',
        // filter field
        key: 'step',
        render: (text, record) => <>{record.step}</>
      },
      {
        width: 300,
        align: 'left',
        title: intl.formatMessage({ id: 'process' }),
        // sort field
        dataIndex: 'str_proc_type',
        // filter field
        key: 'str_proc_type',
        render: (text, record) => (
          <>{intl.formatMessage({ id: `${record.str_proc_type.toLowerCase()}_desc` })}</>
        )
      },
      {
        width: 50,
        align: 'right',
        title: intl.formatMessage({ id: 'mode' }),
        // sort field
        dataIndex: 'scan_mode',
        // filter field
        key: 'scan_mode',
        render: (text, record) => <>{record.scan_mode}</>
      }
    ];
  }

  useOnUpload() {
    const { resId, uploadExcel } = this.props;
    const { fileList } = this.state;

    if (fileList.length > 0) {
      uploadExcel(resId, fileList[0]);
    }
  }

  useOnDownload() {
    const { resId, downloadExcel } = this.props;

    downloadExcel(resId);
  }

  render() {
    const {
      intl,
      resetWhseTxnFlowTimestamp,
      resId,
      whseTxnFlows,
      whseTxnFlowsIsLoading,
      uploadIsLoading
    } = this.props;
    const { fileList } = this.state;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="proc_type"
          pagination={false}
          columns={this.getDocumentColumns()}
          dataSource={whseTxnFlows}
          loading={whseTxnFlowsIsLoading}
          bordered
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={whseTxnFlowsIsLoading}
                    onClick={() => resetWhseTxnFlowTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
                <Col span={8}>
                  <Upload
                    disabled={false}
                    onRemove={() => {
                      this.setState({
                        fileList: []
                      });
                    }}
                    beforeUpload={file => {
                      const validFileName = 'WHSE_TXN_FLOW';
                      if (file && file.name.toLowerCase().includes(validFileName.toLowerCase())) {
                        this.setState({
                          fileList: [file]
                        });
                      } else {
                        notification.error({
                          message: `${intl.formatMessage({
                            id: 'invalid_file_name_must_contain'
                          })} ${validFileName}`,
                          duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
                        });
                      }

                      return false;
                    }}
                    fileList={fileList}
                  >
                    <Button type="primary" disabled={false} loading={whseTxnFlowsIsLoading}>
                      {intl.formatMessage({ id: 'browse' })}
                    </Button>
                  </Upload>
                </Col>
                <Col span={6}>
                  <Button
                    type="primary"
                    onClick={this.useOnUpload}
                    disabled={fileList.length === 0}
                    loading={uploadIsLoading}
                  >
                    {intl.formatMessage({ id: 'upload' })}
                  </Button>
                  <Button
                    type="primary"
                    disabled={whseTxnFlowsIsLoading || resId === 0}
                    onClick={this.useOnDownload}
                    loading={uploadIsLoading}
                  >
                    {intl.formatMessage({ id: 'download' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          // scroll={{ x: 950 }}
        />
      </>
    );
  }
}

TxnFlowTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetWhseTxnFlowTimestamp: PropTypes.func,
  showWhseTxnFlows: PropTypes.func,
  showWhseTxnFlowsSuccess: PropTypes.func,
  resId: PropTypes.number,
  whseTxnFlowsIsLoading: PropTypes.bool,
  whseTxnFlows: PropTypes.arrayOf(PropTypes.object),

  uploadIsLoading: PropTypes.bool,
  uploadExcel: PropTypes.func,
  downloadExcel: PropTypes.func
};

TxnFlowTable.defaultProps = {
  intl: {},
  resetWhseTxnFlowTimestamp() {},
  showWhseTxnFlows() {},
  showWhseTxnFlowsSuccess() {},
  resId: 0,
  whseTxnFlows: [],
  whseTxnFlowsIsLoading: false,

  uploadIsLoading: false,
  uploadExcel() {},
  downloadExcel() {}
};

const mapStateToProps = state => ({
  whseTxnFlowTimestamp: state.siteFlowDetail.whseTxnFlowTimestamp,
  resId: state.siteFlowDetail.resId,
  whseTxnFlows: state.siteFlowDetail.whseTxnFlows,
  whseTxnFlowsIsLoading: state.siteFlowDetail.whseTxnFlowsIsLoading,

  uploadIsLoading: state.siteFlowDetail.whseTxnFlowsUploadIsLoading
});

const mapDispatchToProps = dispatch => ({
  resetWhseTxnFlowTimestamp: () =>
    dispatch(SiteFlowDetailActions.siteFlowDetailResetWhseTxnFlowTimestamp()),
  showWhseTxnFlows: resId => dispatch(SiteFlowDetailActions.siteFlowDetailShowWhseTxnFlows(resId)),
  showWhseTxnFlowsSuccess: txnFlows =>
    dispatch(SiteFlowDetailActions.siteFlowDetailShowWhseTxnFlowsSuccess(txnFlows)),

  uploadExcel: (resId, file) =>
    dispatch(SiteFlowDetailActions.siteFlowDetailWhseTxnFlowsUploadExcel(resId, file)),
  downloadExcel: resId =>
    dispatch(SiteFlowDetailActions.siteFlowDetailWhseTxnFlowsDownloadExcel(resId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TxnFlowTable));
