import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whsePerformanceChartResetTimestamp: ['criteria'],

  whsePerformanceChartFetchIsLoading: ['boolean'],
  whsePerformanceChartFetch: ['criteria'],
  whsePerformanceChartFetchSuccess: ['data'],

  whsePerformanceChartChangeProcess: ['formikBag', 'process'],

  whsePerformanceChartSetVisibleAverageKeys: ['visibleKeys']
});

export const WhsePerformanceChartTypes = Types;
export default Creators;
