import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  binTrf03ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  binTrf03GoToDocument: ['hdrId'],
  binTrf03NewDocument: null,
  binTrf03FetchBinTrf03: ['currentPage', 'sorts', 'filters', 'pageSize'],
  binTrf03FetchBinTrf03Loading: ['boolean'],
  binTrf03FetchBinTrf03Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  binTrf03AddSelectedDocuments: ['selectedDocuments'],
  binTrf03RemoveSelectedDocuments: ['selectedDocuments'],
  binTrf03SetWorkspaceVisible: ['boolean'],
  binTrf03CreateBinTrf03: ['hdrIds'],
  binTrf03CreateBinTrf03Loading: ['boolean'],
  binTrf03CreateBinTrf03Success: ['newDocuments']
});

export const BinTrf03Types = Types;
export default Creators;
