import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  prodIssDetailResetTimestamp: null,
  prodIssDetailSetHdrId: ['hdrId', 'itemId'],

  prodIssDetailInitHeader: null,
  prodIssDetailShowHeader: ['hdrId'],
  prodIssDetailShowHeaderSuccess: ['documentHeader'],

  prodIssDetailUpdateHeader: ['formikBag', 'documentHeader'],
  prodIssDetailCreateHeader: ['formikBag', 'documentHeader'],
  prodIssDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  prodIssDetailShowDocumentLoading: ['boolean'],
  prodIssDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  prodIssDetailShowDetails: ['hdrId'],
  prodIssDetailShowDetailsSuccess: ['documentDetails'],

  prodIssDetailSetDetailVisible: ['boolean'],
  prodIssDetailSetDocumentDetail: ['documentDetail'],

  prodIssDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  prodIssDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  prodIssDetailDeleteDetail: ['hdrId', 'documentDetail'],

  prodIssDetailFetchCurrencyOptions: ['search'],
  prodIssDetailFetchCurrencyOptionLoading: ['boolean'],
  prodIssDetailFetchCurrencyOptionSuccess: ['options'],
  prodIssDetailChangeCurrency: ['formikBag', 'currencyId'],

  prodIssDetailFetchDtlLocationOptions: ['search'],
  prodIssDetailFetchDtlLocationOptionLoading: ['boolean'],
  prodIssDetailFetchDtlLocationOptionSuccess: ['options'],

  prodIssDetailFetchProdStorageBinOptions: ['search'],
  prodIssDetailFetchProdStorageBinOptionLoading: ['boolean'],
  prodIssDetailFetchProdStorageBinOptionSuccess: ['options'],

  prodIssDetailFetchDtlItemOptions: ['search'],
  prodIssDetailFetchDtlItemOptionLoading: ['boolean'],
  prodIssDetailFetchDtlItemOptionSuccess: ['options'],
  prodIssDetailChangeDtlItem: ['formikBag', 'hdrId', 'itemId'],

  prodIssDetailFetchDtlUomOptions: ['itemId', 'search'],
  prodIssDetailFetchDtlUomOptionLoading: ['boolean'],
  prodIssDetailFetchDtlUomOptionSuccess: ['options'],
  prodIssDetailChangeDtlUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  prodIssDetailFetchProjectOptions: ['search'],
  prodIssDetailFetchProjectOptionLoading: ['boolean'],
  prodIssDetailFetchProjectOptionSuccess: ['options']
});

export const ProdIssDetailTypes = Types;
export default Creators;
