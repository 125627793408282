/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  hdrId: 0,

  documentHeader: {
    doc_status: 3,
    str_doc_status: 'DRAFT',
    doc_code: '',
    ref_code_01: '',
    ref_code_02: '',
    doc_date: '1970-01-01',
    desc_01: '',
    desc_02: '',
    doc_no_id: 0,
    doc_flows: []
  },

  outbOrdHdrs: [],

  documentOptions: [],
  documentIsLoading: false,

  fetchIsLoading: false,
  docPhotos: []
};
