import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import StockReorderPlanningV2ReportActions, {
  StockReorderPlanningV2ReportTypes
} from '../Stores/StockReorderPlanningV2Report/Actions';

const getAppStore = state => state.app;

export function* stockReorderPlanningV2ReportInitStockReorderPlanningV2() {
  try {
    yield put(StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportReportLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseReport/initStockReorderPlanningV2/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportInitStockReorderPlanningV2Success(
          result.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportReportLoading(false));
  }
}

export function* stockReorderPlanningV2ReportStockReorderPlanningV2({ formikBag, criteria }) {
  try {
    formikBag.setSubmitting(true);
    yield put(StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportReportLoading(true));

    const objCriteria = {};
    if ('is_show_case_qty' in criteria) {
      objCriteria.is_show_case_qty = criteria.is_show_case_qty;
    }
    if ('no_of_months' in criteria) {
      objCriteria.no_of_months = criteria.no_of_months;
    }
    if ('monthly_pick_options' in criteria) {
      objCriteria.monthly_pick_options = criteria.monthly_pick_options;
    }
    if ('item_ref_code_01' in criteria) {
      objCriteria.item_ref_code_01 = criteria.item_ref_code_01;
    }
    if ('company_ids_select2' in criteria) {
      const values = criteria.company_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.company_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_ids_select2' in criteria) {
      const values = criteria.item_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_01_ids_select2' in criteria) {
      const values = criteria.item_group_01_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_01_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_02_ids_select2' in criteria) {
      const values = criteria.item_group_02_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_02_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_03_ids_select2' in criteria) {
      const values = criteria.item_group_03_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_03_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('location_ids_select2' in criteria) {
      const values = criteria.location_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.location_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    let columns = [];
    if (criteria.columns.target.length > 0) {
      columns = criteria.columns.target.map(entry => {
        return entry.column;
      });
    }

    const app = yield select(getAppStore);
    const postData = {
      sorts: [],
      criteria: objCriteria,
      columns
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseReport/stockReorderPlanningV2/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(
        StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportStockReorderPlanningV2Success(
          criteria,
          result.data.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportReportLoading(false));
  }
}

export function* stockReorderPlanningV2ReportFetchItemOptions({ search }) {
  try {
    yield put(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchItemOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchItemOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchItemOptionLoading(false)
    );
  }
}

export function* stockReorderPlanningV2ReportFetchItemGroup01Options({ search }) {
  try {
    yield put(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchItemGroup01OptionLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchItemGroup01OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchItemGroup01OptionLoading(
        false
      )
    );
  }
}

export function* stockReorderPlanningV2ReportFetchItemGroup02Options({ search }) {
  try {
    yield put(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchItemGroup02OptionLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup02/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchItemGroup02OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchItemGroup02OptionLoading(
        false
      )
    );
  }
}

export function* stockReorderPlanningV2ReportFetchItemGroup03Options({ search }) {
  try {
    yield put(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchItemGroup03OptionLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup03/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchItemGroup03OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchItemGroup03OptionLoading(
        false
      )
    );
  }
}

export function* stockReorderPlanningV2ReportFetchLocationOptions({ search }) {
  try {
    yield put(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchLocationOptionLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `location/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchLocationOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchLocationOptionLoading(
        false
      )
    );
  }
}

export function* stockReorderPlanningV2ReportFetchCompanyOptions({ search }) {
  try {
    yield put(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchCompanyOptionLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `company/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} - ${d.name_01}`
      }));

      yield put(
        StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchCompanyOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchCompanyOptionLoading(
        false
      )
    );
  }
}

export const saga = [
  takeLatest(
    StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_INIT_STOCK_REORDER_PLANNING_V2,
    stockReorderPlanningV2ReportInitStockReorderPlanningV2
  ),
  takeLatest(
    StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_STOCK_REORDER_PLANNING_V2,
    stockReorderPlanningV2ReportStockReorderPlanningV2
  ),
  takeLatest(
    StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_COMPANY_OPTIONS,
    stockReorderPlanningV2ReportFetchCompanyOptions
  ),
  takeLatest(
    StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_ITEM_OPTIONS,
    stockReorderPlanningV2ReportFetchItemOptions
  ),
  takeLatest(
    StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_ITEM_GROUP01_OPTIONS,
    stockReorderPlanningV2ReportFetchItemGroup01Options
  ),
  takeLatest(
    StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_ITEM_GROUP02_OPTIONS,
    stockReorderPlanningV2ReportFetchItemGroup02Options
  ),
  takeLatest(
    StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_ITEM_GROUP03_OPTIONS,
    stockReorderPlanningV2ReportFetchItemGroup03Options
  ),
  takeLatest(
    StockReorderPlanningV2ReportTypes.STOCK_REORDER_PLANNING_V2_REPORT_FETCH_LOCATION_OPTIONS,
    stockReorderPlanningV2ReportFetchLocationOptions
  )
];
