import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcptProcessFetchGdsRcptProcess: null,
  gdsRcptProcessFetchGdsRcptProcessLoading: ['boolean'],
  gdsRcptProcessFetchGdsRcptProcessSuccess: ['process']
});

export const GdsRcptProcessTypes = Types;
export default Creators;
