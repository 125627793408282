import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsIss0101ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsIss0101GoToDocument: ['hdrId'],
  gdsIss0101FetchGdsIss0101: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsIss0101FetchGdsIss0101Loading: ['boolean'],
  gdsIss0101FetchGdsIss0101Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  gdsIss0101AddSelectedDocuments: ['selectedDocuments'],
  gdsIss0101RemoveSelectedDocuments: ['selectedDocuments'],
  gdsIss0101SetWorkspaceVisible: ['boolean'],
  gdsIss0101PrintGdsIss0101: ['hdrIds'],
  gdsIss0101PrintGdsIss0101Loading: ['boolean']
});

export const GdsIss0101Types = Types;
export default Creators;
