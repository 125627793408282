import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import './styles.css';

import { ResponsiveBar } from '@nivo/bar';
import { Card, Select, Row, Col, Typography } from 'antd';

import WorkerPerformanceChartActions from '../../Stores/WorkerPerformanceChart/Actions';
import CustomBar from './CustomBar';

const { Text } = Typography;
const { Option } = Select;

class WorkerPerformance extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      unit: 'case_qty',
      sortBy: 'desc'
    };

    this.formatData = this.formatData.bind(this);
    this.customToolTipRef = React.createRef(null);
    this.graphDivRef = React.createRef(null);
    this.handleChangeUnit = this.handleChangeUnit.bind(this);
    this.handleSortOrder = this.handleSortOrder.bind(this);
    this.renderCustomBar = this.renderCustomBar.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {
    const { updateChartWidth } = this.props;

    const divRef = this.graphDivRef;
    if (divRef.current) {
      const fullChartWidth = divRef.current.offsetWidth;
      // substract margin
      const maxBarWidth = fullChartWidth - 150;
      updateChartWidth(maxBarWidth);
    }
  }

  handleChangeUnit(value) {
    this.setState({ unit: value });
  }

  handleSortOrder(value) {
    this.setState({ sortBy: value });
  }

  // eslint-disable-next-line class-methods-use-this
  formatData(data) {
    if (!data) return data;
    const str = data.toString().split('.');
    if (str[0].length >= 3) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }

    return str.join('.');
  }

  // eslint-disable-next-line class-methods-use-this
  renderCustomBar(customBarProps, graphHeight, heightToValueRatio) {
    const { unit } = this.state;
    return (
      <CustomBar
        customBarProps={customBarProps}
        unit={unit}
        tooltipRef={this.customToolTipRef}
        graphHeight={graphHeight}
        heightToValueRatio={heightToValueRatio}
      />
    );
  }

  render() {
    const {
      intl,
      workerPerformanceDataIsLoading,
      workerPerformanceData,
      summaryData,
      chartWidth,
      startDate,
      endDate
    } = this.props;
    const graphHeight = Math.max(300, workerPerformanceData.length * 100);

    const { unit, sortBy } = this.state;

    /*
    The total width of the graph is 800, taken from the declared div width minus the left and right margin. 
    The nivo package will scale the largest data point to a width of 800.
    The next segment of code finds the maximum data point for the entire data set.
    From this, we can find out the ratio of height (in px) to value (unit, kg, case, etc)
    CustomBar gets rendered for each data point, and finding the height to value ratio for each point is imprecise causing multiple average lines. So, we fix 1 height to value ratio for precision.
     */

    const maxValue =
      // eslint-disable-next-line prefer-spread
      Math.max.apply(
        Math,
        workerPerformanceData.map(o => o[unit])
      ) || 0;

    const heightToValueRatio = chartWidth / maxValue;

    const getTspanGroups = (value, maxLineLength, maxLines = 2) => {
      const words = value.split(' ');

      // reduces the words into lines of maxLineLength
      const assembleLines = words.reduce(
        (acc, word) => {
          // if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
          if ((word + acc.currLine).length > maxLineLength && acc.currLine !== '') {
            return {
              lines: acc.lines.concat([acc.currLine]),
              currLine: word
            };
          }
          // otherwise add the word to the current line
          return {
            ...acc,
            currLine: `${acc.currLine} ${word}`
          };
        },
        { lines: [], currLine: '' }
      );

      // add the ending state of current line (the last line) to lines
      const allLines = assembleLines.lines.concat([assembleLines.currLine]);

      // for now, only take first 2 lines due to tick spacing and possible overflow
      const lines = allLines.slice(0, maxLines);
      const children = [];
      let dy = 0;

      lines.forEach((lineText, i) => {
        children.push(
          // eslint-disable-next-line react/no-array-index-key
          <tspan x={0} dy={dy} key={`tspan-${i}`}>
            {lineText}
          </tspan>
        );
        // increment dy to render next line text below
        dy += 15;
      });

      return children;
    };

    return (
      <Card
        loading={workerPerformanceDataIsLoading}
        title={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={2}>
                <Text>{intl.formatMessage({ id: 'unit' })}</Text>
              </Col>

              <Col span={3}>
                <Select value={unit} onChange={this.handleChangeUnit} style={{ width: 100 }}>
                  <Option value="unit_qty">
                    <Text>{intl.formatMessage({ id: 'unit' })}</Text>
                  </Option>
                  <Option value="case_qty">
                    <Text>{intl.formatMessage({ id: 'case' })}</Text>
                  </Option>
                  <Option value="gross_weight">
                    <Text>{intl.formatMessage({ id: 'gross_weight' })}</Text>
                  </Option>
                  <Option value="cubic_meter">
                    <Text>{intl.formatMessage({ id: 'cubic_meter' })}</Text>
                  </Option>
                </Select>
              </Col>
              <Col span={2}>
                <Text>{intl.formatMessage({ id: 'sort' })}</Text>
              </Col>
              <Col span={3}>
                <Select value={sortBy} onChange={this.handleSortOrder} style={{ width: 150 }}>
                  <Option value="asc">
                    <Text>{intl.formatMessage({ id: 'ascending' })}</Text>
                  </Option>
                  <Option value="desc">
                    <Text>{intl.formatMessage({ id: 'descending' })}</Text>
                  </Option>
                </Select>
              </Col>
              <Col span={4}>
                <Text> </Text>
              </Col>
              <Col span={2}>
                <Text>
                  <small>{intl.formatMessage({ id: 'start_date' })}</small>
                </Text>
              </Col>
              <Col span={3} justify="center">
                <Text>
                  <small>{startDate}</small>
                </Text>
              </Col>
              <Col span={2}>
                <Text>
                  <small>{intl.formatMessage({ id: 'end_date' })}</small>
                </Text>
              </Col>
              <Col span={3}>
                <Text>
                  <small>{endDate}</small>
                </Text>
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]} style={{ height: '10px' }} />

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={4}>
                <Text>{intl.formatMessage({ id: `total_${unit}` })}</Text>
              </Col>
              <Col span={20}>
                <Text>{this.formatData(summaryData[unit])}</Text>
              </Col>
            </Row>
          </>
        }
      >
        <div style={{ height: graphHeight, width: '100%', margin: '0 0' }} ref={this.graphDivRef}>
          <ResponsiveBar
            margin={{ left: 150, top: 40, bottom: 80, right: 0 }}
            data={workerPerformanceData.sort((a, b) =>
              sortBy === 'desc' ? a[unit] - b[unit] : b[unit] - a[unit]
            )}
            indexBy="name"
            keys={[unit]}
            layout="horizontal"
            barComponent={props => this.renderCustomBar(props, graphHeight, heightToValueRatio)}
            padding={0.4}
            axisLeft={{
              tickSize: 1,
              tickPadding: 10,
              renderTick: ({ textAnchor, textBaseline, textX, textY, value, x, y }) => {
                return (
                  <g transform={`translate(${x},${y})`}>
                    <text
                      alignmentBaseline={textBaseline}
                      textAnchor={textAnchor}
                      transform={`translate(${textX},${textY})`}
                      style={{
                        fontSize: 12
                      }}
                    >
                      {getTspanGroups(value, 15)}
                    </text>
                  </g>
                );
              }
            }}
            axisBottom={null}
            labelSkipHeight={16}
          />
          <div className="custom_tooltip" ref={this.customToolTipRef} />
        </div>
      </Card>
    );
  }
}

WorkerPerformance.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  summaryData: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  workerPerformanceData: PropTypes.arrayOf(PropTypes.object),
  workerPerformanceDataIsLoading: PropTypes.bool,
  chartWidth: PropTypes.number,
  updateChartWidth: PropTypes.func
};

WorkerPerformance.defaultProps = {
  intl: {},
  summaryData: {},
  startDate: '',
  endDate: '',
  workerPerformanceData: [{}],
  workerPerformanceDataIsLoading: false,
  chartWidth: 0,
  updateChartWidth: () => {}
};

const mapStateToProps = state => ({
  workerPerformanceData: state.workerPerformanceChart.workerPerformanceData,
  summaryData: state.workerPerformanceChart.summaryData,
  workerPerformanceDataIsLoading: state.workerPerformanceChart.workerPerformanceDataIsLoading,
  chartWidth: state.workerPerformanceChart.workerPerformanceChartWidth,
  startDate: state.workerPerformanceChart.workerPerformanceQueryStartDate,
  endDate: state.workerPerformanceChart.workerPerformanceQueryEndDate
});

const mapDispatchToProps = dispatch => ({
  updateChartWidth: chartWidth =>
    dispatch(WorkerPerformanceChartActions.workerPerformanceUpdateChartWidth(chartWidth))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WorkerPerformance));
