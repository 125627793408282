import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import LayerFarmExcelActions, { LayerFarmExcelTypes } from '../Stores/LayerFarmExcel/Actions';

const getAppStore = state => state.app;

export function* layerFarmExcelShowBatchJobStatus() {
  try {
    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `batchJobStatus/showBatchJobStatus/LAYER_FARM_EXCEL`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(LayerFarmExcelActions.layerFarmExcelShowBatchJobStatusSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  }
}

export function* layerFarmExcelUploadExcel({ file, procType }) {
  try {
    yield put(LayerFarmExcelActions.layerFarmExcelUploadLoading(true));

    const app = yield select(getAppStore);

    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file', file);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `layerFarm/uploadProcess/${procType}/${app.curSiteFlowId}/${app.curDivisionId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LayerFarmExcelActions.layerFarmExcelUploadLoading(false));
  }
}

export function* layerFarmExcelDownloadExcel() {
  try {
    yield put(LayerFarmExcelActions.layerFarmExcelUploadLoading(true));

    const app = yield select(getAppStore);

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `layerFarm/download/DOWNLOAD_LAYER_FARM/${app.curSiteFlowId}/${app.curDivisionId}`,
      app.token,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, 'LAYER_FARM_EXCEL.XLSX');
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LayerFarmExcelActions.layerFarmExcelUploadLoading(false));
  }
}

export const saga = [
  takeLatest(
    LayerFarmExcelTypes.LAYER_FARM_EXCEL_SHOW_BATCH_JOB_STATUS,
    layerFarmExcelShowBatchJobStatus
  ),
  takeLatest(LayerFarmExcelTypes.LAYER_FARM_EXCEL_UPLOAD_EXCEL, layerFarmExcelUploadExcel),
  takeLatest(LayerFarmExcelTypes.LAYER_FARM_EXCEL_DOWNLOAD_EXCEL, layerFarmExcelDownloadExcel)
];
