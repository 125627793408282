import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsIssFServ01ShowSyncSetting: null,
  gdsIssFServ01ShowSyncSettingLoading: ['boolean'],
  gdsIssFServ01ShowSyncSettingSuccess: ['syncSetting'],
  gdsIssFServ01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  gdsIssFServ01ShowBatchJobStatus: null,
  gdsIssFServ01ShowBatchJobStatusSuccess: ['batchJobStatus'],

  gdsIssFServ01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsIssFServ01GoToDocument: ['hdrId'],
  gdsIssFServ01FetchGdsIssFServ01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsIssFServ01FetchGdsIssFServ01Loading: ['boolean'],
  gdsIssFServ01FetchGdsIssFServ01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  gdsIssFServ01AddSelectedDocuments: ['selectedDocuments'],
  gdsIssFServ01RemoveSelectedDocuments: ['selectedDocuments'],
  gdsIssFServ01SetWorkspaceVisible: ['boolean'],

  gdsIssFServ01Export: ['hdrIds'],
  gdsIssFServ01ExportSuccess: null,
  gdsIssFServ01ExportLoading: ['boolean']
});

export const GdsIssFServ01Types = Types;
export default Creators;
