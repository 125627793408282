/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { RtnRcptSync01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const rtnRcptSync01ShowSyncSettingLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const rtnRcptSync01ShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const rtnRcptSync01ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const rtnRcptSync01SyncLoading = (state, { boolean }) => ({
  ...state,
  syncIsLoading: boolean
});

export const rtnRcptSync01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const rtnRcptSync01FetchRtnRcptSync01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const rtnRcptSync01FetchRtnRcptSync01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const rtnRcptSync01AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const rtnRcptSync01RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const rtnRcptSync01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const rtnRcptSync01SyncNowSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [RtnRcptSync01Types.RTN_RCPT_SYNC01_SHOW_SYNC_SETTING_LOADING]: rtnRcptSync01ShowSyncSettingLoading,
  [RtnRcptSync01Types.RTN_RCPT_SYNC01_SHOW_SYNC_SETTING_SUCCESS]: rtnRcptSync01ShowSyncSettingSuccess,
  [RtnRcptSync01Types.RTN_RCPT_SYNC01_SHOW_BATCH_JOB_STATUS_SUCCESS]: rtnRcptSync01ShowBatchJobStatusSuccess,
  // [RtnRcptSync01Types.RTN_RCPT_SYNC01_SYNC_LOADING]: rtnRcptSync01SyncLoading,

  [RtnRcptSync01Types.RTN_RCPT_SYNC01_RESET_TIMESTAMP]: rtnRcptSync01ResetTimestamp,
  [RtnRcptSync01Types.RTN_RCPT_SYNC01_FETCH_RTN_RCPT_SYNC01_LOADING]: rtnRcptSync01FetchRtnRcptSync01Loading,
  [RtnRcptSync01Types.RTN_RCPT_SYNC01_FETCH_RTN_RCPT_SYNC01_SUCCESS]: rtnRcptSync01FetchRtnRcptSync01Success,
  [RtnRcptSync01Types.RTN_RCPT_SYNC01_ADD_SELECTED_DOCUMENTS]: rtnRcptSync01AddSelectedDocuments,
  [RtnRcptSync01Types.RTN_RCPT_SYNC01_REMOVE_SELECTED_DOCUMENTS]: rtnRcptSync01RemoveSelectedDocuments,
  [RtnRcptSync01Types.RTN_RCPT_SYNC01_SET_WORKSPACE_VISIBLE]: rtnRcptSync01SetWorkspaceVisible,
  [RtnRcptSync01Types.RTN_RCPT_SYNC01_SYNC_LOADING]: rtnRcptSync01SyncLoading,
  [RtnRcptSync01Types.RTN_RCPT_SYNC01_SYNC_NOW_SUCCESS]: rtnRcptSync01SyncNowSuccess
});
