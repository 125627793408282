/**
 * The initial values for the redux state.
 */
export default {
  resId: 0,

  modelTimestamp: 0,
  model: {
    code: '',
    ref_code_01: '',
    desc_01: '',
    desc_02: ''
  },

  modelIsLoading: false,

  pickListDocNoOptions: [],
  pickListDocNoIsLoading: false,

  packListDocNoOptions: [],
  packListDocNoIsLoading: false,

  gdsRcptDocNoOptions: [],
  gdsRcptDocNoIsLoading: false,

  putAwayDocNoOptions: [],
  putAwayDocNoIsLoading: false,

  gdsIssDocNoOptions: [],
  gdsIssDocNoIsLoading: false,

  binTrfDocNoOptions: [],
  binTrfDocNoIsLoading: false,

  cycleCountDocNoOptions: [],
  cycleCountDocNoIsLoading: false,

  countAdjDocNoOptions: [],
  countAdjDocNoIsLoading: false,

  whseJobDocNoOptions: [],
  whseJobDocNoIsLoading: false,

  loadListDocNoOptions: [],
  loadListDocNoIsLoading: false,

  prfDelDocNoOptions: [],
  prfDelDocNoIsLoading: false,

  failDelDocNoOptions: [],
  failDelDocNoIsLoading: false
};
