import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  pickingCriteriaExcel01ShowBatchJobStatus: null,
  pickingCriteriaExcel01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  pickingCriteriaExcel01UploadExcel: ['file'],
  pickingCriteriaExcel01UploadLoading: ['boolean'],
  pickingCriteriaExcel01DownloadExcel: null
});

export const PickingCriteriaExcel01Types = Types;
export default Creators;
