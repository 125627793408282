/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PutAwayRtnDetailBoardTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const putAwayRtnDetailBoardResetWipGdsRcptTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipGdsRcptCurrentPage: currentPage || state.wipGdsRcptCurrentPage,
  wipGdsRcptSorts: sorts || state.wipGdsRcptSorts,
  wipGdsRcptFilters: filters || state.wipGdsRcptFilters,
  wipGdsRcptPageSize: pageSize || state.wipGdsRcptPageSize,
  wipGdsRcptTimestamp: Date.now()
});

export const putAwayRtnDetailBoardFetchWipGdsRcptLoading = (state, { boolean }) => ({
  ...state,
  wipGdsRcptIsLoading: boolean
});

export const putAwayRtnDetailBoardFetchWipGdsRcptSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipGdsRcpts: documents,
  wipGdsRcptCurrentPage: currentPage,
  wipGdsRcptLastPage: lastPage,
  wipGdsRcptTotal: total,
  wipGdsRcptPageSize: pageSize
});

export const putAwayRtnDetailBoardResetWipPutAwayTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipPutAwayCurrentPage: currentPage || state.wipPutAwayCurrentPage,
  wipPutAwaySorts: sorts || state.wipPutAwaySorts,
  wipPutAwayFilters: filters || state.wipPutAwayFilters,
  wipPutAwayPageSize: pageSize || state.wipPutAwayPageSize,
  wipPutAwayTimestamp: Date.now()
});

export const putAwayRtnDetailBoardFetchWipPutAwayLoading = (state, { boolean }) => ({
  ...state,
  wipPutAwayIsLoading: boolean
});

export const putAwayRtnDetailBoardFetchWipPutAwaySuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipPutAways: documents,
  wipPutAwayCurrentPage: currentPage,
  wipPutAwayLastPage: lastPage,
  wipPutAwayTotal: total,
  wipPutAwayPageSize: pageSize
});

export const putAwayRtnDetailBoardResetWipWhseJobTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipWhseJobCurrentPage: currentPage || state.wipWhseJobCurrentPage,
  wipWhseJobSorts: sorts || state.wipWhseJobSorts,
  wipWhseJobFilters: filters || state.wipWhseJobFilters,
  wipWhseJobPageSize: pageSize || state.wipWhseJobPageSize,
  wipWhseJobTimestamp: Date.now()
});

export const putAwayRtnDetailBoardFetchWipWhseJobLoading = (state, { boolean }) => ({
  ...state,
  wipWhseJobIsLoading: boolean
});

export const putAwayRtnDetailBoardFetchWipWhseJobSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipWhseJobs: documents,
  wipWhseJobCurrentPage: currentPage,
  wipWhseJobLastPage: lastPage,
  wipWhseJobTotal: total,
  wipWhseJobPageSize: pageSize
});

export const putAwayRtnDetailBoardResetCompletePutAwayTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  completePutAwayCurrentPage: currentPage || state.completePutAwayCurrentPage,
  completePutAwaySorts: sorts || state.completePutAwaySorts,
  completePutAwayFilters: filters || state.completePutAwayFilters,
  completePutAwayPageSize: pageSize || state.completePutAwayPageSize,
  completePutAwayTimestamp: Date.now()
});

export const putAwayRtnDetailBoardFetchCompletePutAwayLoading = (state, { boolean }) => ({
  ...state,
  completePutAwayIsLoading: boolean
});

export const putAwayRtnDetailBoardFetchCompletePutAwaySuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  completePutAways: documents,
  completePutAwayCurrentPage: currentPage,
  completePutAwayLastPage: lastPage,
  completePutAwayTotal: total,
  completePutAwayPageSize: pageSize
});

export const putAwayRtnDetailBoardShowWipGdsRcptSummary = (state, { docId }) => ({
  ...state,
  wipGdsRcptDocId: docId,
  wipGdsRcptDialogIsVisible: true
});

export const putAwayRtnDetailBoardSetWipGdsRcptDialogVisible = (state, { boolean }) => ({
  ...state,
  wipGdsRcptDialogIsVisible: boolean
});

export const putAwayRtnDetailBoardShowWipGdsRcptSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipGdsRcptDocumentIsLoading: boolean
});

export const putAwayRtnDetailBoardShowWipGdsRcptSummarySuccess = (state, { document }) => ({
  ...state,
  wipGdsRcptDocument: document
});

export const putAwayRtnDetailBoardShowWipPutAwaySummary = (state, { docId }) => ({
  ...state,
  wipPutAwayDocId: docId,
  wipPutAwayDialogIsVisible: true
});

export const putAwayRtnDetailBoardSetWipPutAwayDialogVisible = (state, { boolean }) => ({
  ...state,
  wipPutAwayDialogIsVisible: boolean
});

export const putAwayRtnDetailBoardShowWipPutAwaySummaryLoading = (state, { boolean }) => ({
  ...state,
  wipPutAwayDocumentIsLoading: boolean
});

export const putAwayRtnDetailBoardShowWipPutAwaySummarySuccess = (state, { document }) => ({
  ...state,
  wipPutAwayDocument: document
});

export const putAwayRtnDetailBoardShowWipWhseJobSummary = (state, { docId }) => ({
  ...state,
  wipWhseJobDocId: docId,
  wipWhseJobDialogIsVisible: true
});

export const putAwayRtnDetailBoardSetWipWhseJobDialogVisible = (state, { boolean }) => ({
  ...state,
  wipWhseJobDialogIsVisible: boolean
});

export const putAwayRtnDetailBoardShowWipWhseJobSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipWhseJobDocumentIsLoading: boolean
});

export const putAwayRtnDetailBoardShowWipWhseJobSummarySuccess = (state, { document }) => ({
  ...state,
  wipWhseJobDocument: document
});

export const putAwayRtnDetailBoardShowCompletePutAwaySummary = (state, { docId }) => ({
  ...state,
  completePutAwayDocId: docId,
  completePutAwayDialogIsVisible: true
});

export const putAwayRtnDetailBoardSetCompletePutAwayDialogVisible = (state, { boolean }) => ({
  ...state,
  completePutAwayDialogIsVisible: boolean
});

export const putAwayRtnDetailBoardShowCompletePutAwaySummaryLoading = (state, { boolean }) => ({
  ...state,
  completePutAwayDocumentIsLoading: boolean
});

export const putAwayRtnDetailBoardShowCompletePutAwaySummarySuccess = (state, { document }) => ({
  ...state,
  completePutAwayDocument: document
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_RESET_WIP_GDS_RCPT_TIMESTAMP]: putAwayRtnDetailBoardResetWipGdsRcptTimestamp,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_FETCH_WIP_GDS_RCPT_LOADING]: putAwayRtnDetailBoardFetchWipGdsRcptLoading,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_FETCH_WIP_GDS_RCPT_SUCCESS]: putAwayRtnDetailBoardFetchWipGdsRcptSuccess,

  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_RESET_WIP_PUT_AWAY_TIMESTAMP]: putAwayRtnDetailBoardResetWipPutAwayTimestamp,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_FETCH_WIP_PUT_AWAY_LOADING]: putAwayRtnDetailBoardFetchWipPutAwayLoading,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_FETCH_WIP_PUT_AWAY_SUCCESS]: putAwayRtnDetailBoardFetchWipPutAwaySuccess,

  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_RESET_WIP_WHSE_JOB_TIMESTAMP]: putAwayRtnDetailBoardResetWipWhseJobTimestamp,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_FETCH_WIP_WHSE_JOB_LOADING]: putAwayRtnDetailBoardFetchWipWhseJobLoading,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_FETCH_WIP_WHSE_JOB_SUCCESS]: putAwayRtnDetailBoardFetchWipWhseJobSuccess,

  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_RESET_COMPLETE_PUT_AWAY_TIMESTAMP]: putAwayRtnDetailBoardResetCompletePutAwayTimestamp,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_FETCH_COMPLETE_PUT_AWAY_LOADING]: putAwayRtnDetailBoardFetchCompletePutAwayLoading,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_FETCH_COMPLETE_PUT_AWAY_SUCCESS]: putAwayRtnDetailBoardFetchCompletePutAwaySuccess,

  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_SHOW_WIP_GDS_RCPT_SUMMARY]: putAwayRtnDetailBoardShowWipGdsRcptSummary,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_SET_WIP_GDS_RCPT_DIALOG_VISIBLE]: putAwayRtnDetailBoardSetWipGdsRcptDialogVisible,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_SHOW_WIP_GDS_RCPT_SUMMARY_LOADING]: putAwayRtnDetailBoardShowWipGdsRcptSummaryLoading,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_SHOW_WIP_GDS_RCPT_SUMMARY_SUCCESS]: putAwayRtnDetailBoardShowWipGdsRcptSummarySuccess,

  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_SHOW_WIP_PUT_AWAY_SUMMARY]: putAwayRtnDetailBoardShowWipPutAwaySummary,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_SET_WIP_PUT_AWAY_DIALOG_VISIBLE]: putAwayRtnDetailBoardSetWipPutAwayDialogVisible,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_SHOW_WIP_PUT_AWAY_SUMMARY_LOADING]: putAwayRtnDetailBoardShowWipPutAwaySummaryLoading,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_SHOW_WIP_PUT_AWAY_SUMMARY_SUCCESS]: putAwayRtnDetailBoardShowWipPutAwaySummarySuccess,

  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY]: putAwayRtnDetailBoardShowWipWhseJobSummary,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_SET_WIP_WHSE_JOB_DIALOG_VISIBLE]: putAwayRtnDetailBoardSetWipWhseJobDialogVisible,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY_LOADING]: putAwayRtnDetailBoardShowWipWhseJobSummaryLoading,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY_SUCCESS]: putAwayRtnDetailBoardShowWipWhseJobSummarySuccess,

  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_SHOW_COMPLETE_PUT_AWAY_SUMMARY]: putAwayRtnDetailBoardShowCompletePutAwaySummary,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_SET_COMPLETE_PUT_AWAY_DIALOG_VISIBLE]: putAwayRtnDetailBoardSetCompletePutAwayDialogVisible,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_SHOW_COMPLETE_PUT_AWAY_SUMMARY_LOADING]: putAwayRtnDetailBoardShowCompletePutAwaySummaryLoading,
  [PutAwayRtnDetailBoardTypes.PUT_AWAY_RTN_DETAIL_BOARD_SHOW_COMPLETE_PUT_AWAY_SUMMARY_SUCCESS]: putAwayRtnDetailBoardShowCompletePutAwaySummarySuccess
});
