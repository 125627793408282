/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WorkerPerformanceChartTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const workerPerformanceChartResetTimestamp = (state, { criteria }) => ({
  ...state,
  criteria: criteria || state.criteria,
  workerPerformanceChartTimestamp: Date.now()
});

export const workerPerformanceChartFetchIsLoading = (state, { boolean }) => ({
  ...state,
  workerPerformanceDataIsLoading: boolean
});

export const workerPerformanceChartFetchSuccess = (state, { data }) => {
  return {
    ...state,
    workerPerformanceData: data.workerData,
    summaryData: data.summaryData,
    avgData: data.avgData,
    workerPerformanceQueryStartDate: data.startDate,
    workerPerformanceQueryEndDate: data.endDate
  };
};

export const workerPerformanceUpdateChartWidth = (state, { chartWidth }) => ({
  ...state,
  workerPerformanceChartWidth: chartWidth
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WorkerPerformanceChartTypes.WORKER_PERFORMANCE_CHART_RESET_TIMESTAMP]: workerPerformanceChartResetTimestamp,
  [WorkerPerformanceChartTypes.WORKER_PERFORMANCE_CHART_FETCH_IS_LOADING]: workerPerformanceChartFetchIsLoading,
  [WorkerPerformanceChartTypes.WORKER_PERFORMANCE_CHART_FETCH_SUCCESS]: workerPerformanceChartFetchSuccess,
  [WorkerPerformanceChartTypes.WORKER_PERFORMANCE_UPDATE_CHART_WIDTH]: workerPerformanceUpdateChartWidth
});
