/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WorkerLoadListAnalysisReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const workerLoadListAnalysisReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const workerLoadListAnalysisReportInitWorkerLoadListAnalysisSuccess = (
  state,
  { criteria }
) => ({
  ...state,
  criteria
});

export const workerLoadListAnalysisReportWorkerLoadListAnalysisSuccess = (
  state,
  { criteria, reportData }
) => ({
  ...state,
  criteria,
  reportData
});

export const workerLoadListAnalysisReportFetchUserOptionLoading = (state, { boolean }) => ({
  ...state,
  userIsLoading: boolean
});

export const workerLoadListAnalysisReportFetchUserOptionSuccess = (state, { options }) => ({
  ...state,
  userOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WorkerLoadListAnalysisReportTypes.WORKER_LOAD_LIST_ANALYSIS_REPORT_REPORT_LOADING]: workerLoadListAnalysisReportReportLoading,
  [WorkerLoadListAnalysisReportTypes.WORKER_LOAD_LIST_ANALYSIS_REPORT_INIT_WORKER_LOAD_LIST_ANALYSIS_SUCCESS]: workerLoadListAnalysisReportInitWorkerLoadListAnalysisSuccess,
  [WorkerLoadListAnalysisReportTypes.WORKER_LOAD_LIST_ANALYSIS_REPORT_WORKER_LOAD_LIST_ANALYSIS_SUCCESS]: workerLoadListAnalysisReportWorkerLoadListAnalysisSuccess,

  [WorkerLoadListAnalysisReportTypes.WORKER_LOAD_LIST_ANALYSIS_REPORT_FETCH_USER_OPTION_LOADING]: workerLoadListAnalysisReportFetchUserOptionLoading,
  [WorkerLoadListAnalysisReportTypes.WORKER_LOAD_LIST_ANALYSIS_REPORT_FETCH_USER_OPTION_SUCCESS]: workerLoadListAnalysisReportFetchUserOptionSuccess
});
