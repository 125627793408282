import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';

import { ArrowLeftOutlined, LockOutlined } from '@ant-design/icons';

import { Card, Row, Col, Button, Typography } from 'antd';
import { FormikPassword } from '../../Components/Formik';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class ChangePasswordScreen extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      currentPassword: '',
      newPassword: '',
      retypePassword: ''
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const { intl, changePassword, historyGoBack } = this.props;
    const { currentPassword, newPassword, retypePassword } = this.state;

    const initialValues = {
      currentPassword,
      newPassword,
      retypePassword
    };

    return (
      <Card title={intl.formatMessage({ id: 'luuwu_login' })}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, formikBag) => {
            const {
              currentPassword: curCurrentPassword,
              newPassword: curNewPassword,
              retypePassword: curRetypePassword
            } = values;
            // save to state
            this.setState({
              currentPassword: curCurrentPassword,
              newPassword: curNewPassword,
              retypePassword: curRetypePassword
            });
            // dispatch the action
            changePassword(formikBag, curCurrentPassword, curNewPassword);
          }}
          validationSchema={Yup.object().shape({
            currentPassword: Yup.string().required(
              intl.formatMessage({ id: 'current_password_is_required' })
            ),
            newPassword: Yup.string().required(
              intl.formatMessage({ id: 'new_password_is_required' })
            ),
            retypePassword: Yup.string().when('newPassword', {
              is: val => !!(val && val.length > 0),
              then: Yup.string().oneOf(
                [Yup.ref('newPassword')],
                intl.formatMessage({ id: 'passwords_do_not_match' })
              )
            })
          })}
        >
          {({
            // values,
            // handleChange,
            // errors,
            // setFieldTouched,
            // dirty,
            // touched,
            isSubmitting,
            isValid,
            handleSubmit
          }) => (
            <Form>
              <Row type="flex" justify="center" gutter={[0, 8]}>
                <Col span={3}>
                  <Text strong>{intl.formatMessage({ id: 'current' })}</Text>
                </Col>
                <Col span={1}>
                  <Text strong>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="currentPassword"
                    component={FormikPassword}
                    placeholder={intl.formatMessage({ id: 'current_password' })}
                    addonBefore={<LockOutlined />}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[0, 8]}>
                <Col span={3}>
                  <Text strong>{intl.formatMessage({ id: 'new' })}</Text>
                </Col>
                <Col span={1}>
                  <Text strong>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="newPassword"
                    component={FormikPassword}
                    placeholder={intl.formatMessage({ id: 'new_password' })}
                    addonBefore={<LockOutlined />}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[0, 8]}>
                <Col span={3}>
                  <Text strong>{intl.formatMessage({ id: 'retype' })}</Text>
                </Col>
                <Col span={1}>
                  <Text strong>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="retypePassword"
                    component={FormikPassword}
                    placeholder={intl.formatMessage({ id: 'retype_password' })}
                    addonBefore={<LockOutlined />}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="start" gutter={[8, 8]}>
                <Col span={3}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={isSubmitting}
                    onClick={historyGoBack}
                    icon={<ArrowLeftOutlined />}
                  >
                    {intl.formatMessage({
                      id: 'back'
                    })}
                  </Button>
                </Col>
                <Col span={3}>
                  <Button
                    type="primary"
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {intl.formatMessage({ id: 'submit' })}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card>
    );
  }
}

ChangePasswordScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  changePassword: PropTypes.func,

  historyGoBack: PropTypes.func
};

ChangePasswordScreen.defaultProps = {
  intl: {},
  changePassword() {},

  historyGoBack() {}
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl
});

const mapDispatchToProps = dispatch => ({
  changePassword: (formikBag, username, password) =>
    dispatch(AppActions.appChangePassword(formikBag, username, password)),

  historyGoBack: () => dispatch(AppActions.appHistoryGoBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChangePasswordScreen));
