import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspReceivingProcessFetchLspReceivingProcess: null,
  lspReceivingProcessFetchLspReceivingProcessLoading: ['boolean'],
  lspReceivingProcessFetchLspReceivingProcessSuccess: ['process']
});

export const LspReceivingProcessTypes = Types;
export default Creators;
