import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  prodOrdDetailResetTimestamp: null,
  prodOrdDetailSetHdrId: ['hdrId', 'itemId'],

  prodOrdDetailInitHeader: null,
  prodOrdDetailShowHeader: ['hdrId'],
  prodOrdDetailShowHeaderSuccess: ['documentHeader'],

  prodOrdDetailUpdateHeader: ['formikBag', 'documentHeader'],
  prodOrdDetailCreateHeader: ['formikBag', 'documentHeader'],
  prodOrdDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  prodOrdDetailShowDocumentLoading: ['boolean'],
  prodOrdDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  prodOrdDetailShowDetails: ['hdrId'],
  prodOrdDetailShowDetailsSuccess: ['documentDetails'],

  prodOrdDetailSetDetailVisible: ['boolean'],
  prodOrdDetailSetDocumentDetail: ['documentDetail'],

  prodOrdDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  prodOrdDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  prodOrdDetailDeleteDetail: ['hdrId', 'documentDetail'],

  prodOrdDetailFetchCurrencyOptions: ['search'],
  prodOrdDetailFetchCurrencyOptionLoading: ['boolean'],
  prodOrdDetailFetchCurrencyOptionSuccess: ['options'],
  prodOrdDetailChangeCurrency: ['formikBag', 'currencyId'],

  prodOrdDetailFetchDtlLocationOptions: ['search'],
  prodOrdDetailFetchDtlLocationOptionLoading: ['boolean'],
  prodOrdDetailFetchDtlLocationOptionSuccess: ['options'],

  prodOrdDetailFetchProdStorageBinOptions: ['search'],
  prodOrdDetailFetchProdStorageBinOptionLoading: ['boolean'],
  prodOrdDetailFetchProdStorageBinOptionSuccess: ['options'],

  prodOrdDetailFetchDtlItemOptions: ['search'],
  prodOrdDetailFetchDtlItemOptionLoading: ['boolean'],
  prodOrdDetailFetchDtlItemOptionSuccess: ['options'],
  prodOrdDetailChangeDtlItem: ['formikBag', 'hdrId', 'itemId'],

  prodOrdDetailFetchDtlItemBatchOptions: ['itemId', 'search'],
  prodOrdDetailFetchDtlItemBatchOptionLoading: ['boolean'],
  prodOrdDetailFetchDtlItemBatchOptionSuccess: ['options'],
  prodOrdDetailChangeDtlItemBatch: ['formikBag', 'hdrId', 'itemBatchId'],

  prodOrdDetailFetchDtlUomOptions: ['itemId', 'search'],
  prodOrdDetailFetchDtlUomOptionLoading: ['boolean'],
  prodOrdDetailFetchDtlUomOptionSuccess: ['options'],
  prodOrdDetailChangeDtlUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  prodOrdDetailFetchProjectOptions: ['search'],
  prodOrdDetailFetchProjectOptionLoading: ['boolean'],
  prodOrdDetailFetchProjectOptionSuccess: ['options']
});

export const ProdOrdDetailTypes = Types;
export default Creators;
