import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import LoginScreen from '../Containers/Login/LoginScreen';
import DashboardScreen from '../Containers/Dashboard/DashboardScreen';
import HomeScreen from '../Containers/Root/HomeScreen';
import SalesProcessScreen from '../Containers/SalesProcess/SalesProcessScreen';
import GdsDelProcessScreen from '../Containers/GdsDelProcess/GdsDelProcessScreen';
import RtnDelProcessScreen from '../Containers/RtnDelProcess/RtnDelProcessScreen';
import GdsRcptProcessScreen from '../Containers/GdsRcptProcess/GdsRcptProcessScreen';
import PackListDetailScreen from '../Containers/PackListDetail/PackListDetailScreen';
import PackListIndexScreen from '../Containers/PackListIndex/PackListIndexScreen';
import WorkerPackListAnalysisReportScreen from '../Containers/WorkerPackListAnalysisReport/WorkerPackListAnalysisReportScreen';
import PickListDetailScreen from '../Containers/PickListDetail/PickListDetailScreen';
import PickListIndexScreen from '../Containers/PickListIndex/PickListIndexScreen';
import AdvShipDetailScreen from '../Containers/AdvShipDetail/AdvShipDetailScreen';
import PurRtnDetailScreen from '../Containers/PurRtnDetail/PurRtnDetailScreen';
import OutbOrdDetailScreen from '../Containers/OutbOrdDetail/OutbOrdDetailScreen';
import InbOrdDetailScreen from '../Containers/InbOrdDetail/InbOrdDetailScreen';
import GdsRcptDetailScreen from '../Containers/GdsRcptDetail/GdsRcptDetailScreen';
import RtnRcptProcessScreen from '../Containers/RtnRcptProcess/RtnRcptProcessScreen';
import InvAuditProcessScreen from '../Containers/InvAuditProcess/InvAuditProcessScreen';
import CycleCountDetailScreen from '../Containers/CycleCountDetail/CycleCountDetailScreen';
import CycleCount02DetailScreen from '../Containers/CycleCount02Detail/CycleCount02DetailScreen';
import StockBalanceReportScreen from '../Containers/StockBalanceReport/StockBalanceReportScreen';
import StockReorderPlanningReportScreen from '../Containers/StockReorderPlanningReport/StockReorderPlanningReportScreen';
import DailyStockMovementReportScreen from '../Containers/DailyStockMovementReport/DailyStockMovementReportScreen';
import WarehouseMapScreen from '../Containers/WarehouseMap/WarehouseMapScreen';
import ItemProcessScreen from '../Containers/ItemProcess/ItemProcessScreen';
import StorageBinProcessScreen from '../Containers/StorageBinProcess/StorageBinProcessScreen';
import PalletLabelProcessScreen from '../Containers/PalletLabelProcess/PalletLabelProcessScreen';
import PickFaceStrategyProcessScreen from '../Containers/PickFaceStrategyProcess/PickFaceStrategyProcessScreen';
import PickingCriteriaProcessScreen from '../Containers/PickingCriteriaProcess/PickingCriteriaProcessScreen';
import UserProcessScreen from '../Containers/UserProcess/UserProcessScreen';
import RoleProcessScreen from '../Containers/RoleProcess/RoleProcessScreen';
import SlsRtnProcessScreen from '../Containers/SlsRtnProcess/SlsRtnProcessScreen';
import OutbOrdAnalysisReportScreen from '../Containers/OutbOrdAnalysisReport/OutbOrdAnalysisReportScreen';
import OutboundExpiryReportScreen from '../Containers/OutboundExpiryReport/OutboundExpiryReportScreen';
import OutOfStockAnalysisReportScreen from '../Containers/OutOfStockAnalysisReport/OutOfStockAnalysisReportScreen';
import PurchaseProcessScreen from '../Containers/PurchaseProcess/PurchaseProcessScreen';
import PurchaseReturnProcessScreen from '../Containers/PurchaseReturnProcess/PurchaseReturnProcessScreen';
import WhseJob03DetailScreen from '../Containers/WhseJob03Detail/WhseJob03DetailScreen';
import WhseJob14DetailScreen from '../Containers/WhseJob14Detail/WhseJob14DetailScreen';
import PutAwayIndexScreen from '../Containers/PutAwayIndex/PutAwayIndexScreen';
import PutAwayRtnIndexScreen from '../Containers/PutAwayRtnIndex/PutAwayRtnIndexScreen';
import PutAwayDetailScreen from '../Containers/PutAwayDetail/PutAwayDetailScreen';
import WhseJob15DetailScreen from '../Containers/WhseJob15Detail/WhseJob15DetailScreen';
import AdvShipIndexScreen from '../Containers/AdvShipIndex/AdvShipIndexScreen';
import SlsOrdIndexScreen from '../Containers/SlsOrdIndex/SlsOrdIndexScreen';
import SlsOrdDetailScreen from '../Containers/SlsOrdDetail/SlsOrdDetailScreen';
import SlsInvIndexScreen from '../Containers/SlsInvIndex/SlsInvIndexScreen';
import SlsInvDetailScreen from '../Containers/SlsInvDetail/SlsInvDetailScreen';
import SlsRtnIndexScreen from '../Containers/SlsRtnIndex/SlsRtnIndexScreen';
import SlsRtnDetailScreen from '../Containers/SlsRtnDetail/SlsRtnDetailScreen';
import RtnRcptIndexScreen from '../Containers/RtnRcptIndex/RtnRcptIndexScreen';
import RtnRcptDetailScreen from '../Containers/RtnRcptDetail/RtnRcptDetailScreen';
import OutbOrdIndexScreen from '../Containers/OutbOrdIndex/OutbOrdIndexScreen';
import InbOrdIndexScreen from '../Containers/InbOrdIndex/InbOrdIndexScreen';
import GdsRcptIndexScreen from '../Containers/GdsRcptIndex/GdsRcptIndexScreen';
import GdsRcptRtnIndexScreen from '../Containers/GdsRcptRtnIndex/GdsRcptRtnIndexScreen';
import CycleCountIndexScreen from '../Containers/CycleCountIndex/CycleCountIndexScreen';
import CountAdjIndexScreen from '../Containers/CountAdjIndex/CountAdjIndexScreen';
import CountAdjDetailScreen from '../Containers/CountAdjDetail/CountAdjDetailScreen';
import BinTrfIndexScreen from '../Containers/BinTrfIndex/BinTrfIndexScreen';
import BinTrfDetailScreen from '../Containers/BinTrfDetail/BinTrfDetailScreen';
import BinTrfProcessScreen from '../Containers/BinTrfProcess/BinTrfProcessScreen';
import WhseJob17DetailScreen from '../Containers/WhseJob17Detail/WhseJob17DetailScreen';
import StockCardReportScreen from '../Containers/StockCardReport/StockCardReportScreen';
import StockAgingReportScreen from '../Containers/StockAgingReport/StockAgingReportScreen';
import PickListReservedStockReportScreen from '../Containers/PickListReservedStockReport/PickListReservedStockReportScreen';
import PickingAllocErrorReportScreen from '../Containers/PickingAllocErrorReport/PickingAllocErrorReportScreen';
import CycleCountAnalysisReportScreen from '../Containers/CycleCountAnalysisReport/CycleCountAnalysisReportScreen';
import CountAdjAnalysisReportScreen from '../Containers/CountAdjAnalysisReport/CountAdjAnalysisReportScreen';
import DeliveryPointProcessScreen from '../Containers/DeliveryPointProcess/DeliveryPointProcessScreen';
import ChangePasswordScreen from '../Containers/ChangePassword/ChangePasswordScreen';
import AuditRes01Screen from '../Containers/AuditRes01/AuditRes01Screen';
import AuditUser01Screen from '../Containers/AuditUser01/AuditUser01Screen';
import WhseJob16DetailScreen from '../Containers/WhseJob16Detail/WhseJob16DetailScreen';
import LspDeliveryProcessScreen from '../Containers/LspDeliveryProcess/LspDeliveryProcessScreen';
import LspDelOrdDetailScreen from '../Containers/LspDelOrdDetail/LspDelOrdDetailScreen';
import LspDelOrdIndexScreen from '../Containers/LspDelOrdIndex/LspDelOrdIndexScreen';
import LspReceivingProcessScreen from '../Containers/LspReceivingProcess/LspReceivingProcessScreen';
import LspCustomerReturnProcessScreen from '../Containers/LspCustomerReturnProcess/LspCustomerReturnProcessScreen';
import LspSupplierReturnProcessScreen from '../Containers/LspSupplierReturnProcess/LspSupplierReturnProcessScreen';
import LspSlsOrdDetailScreen from '../Containers/LspSlsOrdDetail/LspSlsOrdDetailScreen';
import LspAdvShipDetailScreen from '../Containers/LspAdvShipDetail/LspAdvShipDetailScreen';
import LspSlsRtnDetailScreen from '../Containers/LspSlsRtnDetail/LspSlsRtnDetailScreen';
import LspSlsOrdIndexScreen from '../Containers/LspSlsOrdIndex/LspSlsOrdIndexScreen';
import LspSlsOrdTrackingReportScreen from '../Containers/LspSlsOrdTrackingReport/LspSlsOrdTrackingReportScreen';
import LspSlsRtnIndexScreen from '../Containers/LspSlsRtnIndex/LspSlsRtnIndexScreen';
import LspAdvShipIndexScreen from '../Containers/LspAdvShipIndex/LspAdvShipIndexScreen';
import LoadListIndexScreen from '../Containers/LoadListIndex/LoadListIndexScreen';
import LoadListDetailScreen from '../Containers/LoadListDetail/LoadListDetailScreen';
import LocationDetailScreen from '../Containers/LocationDetail/LocationDetailScreen';
import PrfDelIndexScreen from '../Containers/PrfDelIndex/PrfDelIndexScreen';
import PrfDelDetailScreen from '../Containers/PrfDelDetail/PrfDelDetailScreen';
import PrincipalProcessScreen from '../Containers/PrincipalProcess/PrincipalProcessScreen';
import PrincipalDetailScreen from '../Containers/PrincipalDetail/PrincipalDetailScreen';
import TransportProcessScreen from '../Containers/TransportProcess/TransportProcessScreen';
import TransportDetailScreen from '../Containers/TransportDetail/TransportDetailScreen';
import UserDetailScreen from '../Containers/UserDetail/UserDetailScreen';
import SlsOrdTrackingReportScreen from '../Containers/SlsOrdTrackingReport/SlsOrdTrackingReportScreen';
import ShipmentTrackingReportScreen from '../Containers/ShipmentTrackingReport/ShipmentTrackingReportScreen';
import TransferInTrackingReportScreen from '../Containers/TransferInTrackingReport/TransferInTrackingReportScreen';
import TransferOutTrackingReportScreen from '../Containers/TransferOutTrackingReport/TransferOutTrackingReportScreen';
import DeliveryPointDetailScreen from '../Containers/DeliveryPointDetail/DeliveryPointDetailScreen';
import BizPartnerDetailScreen from '../Containers/BizPartnerDetail/BizPartnerDetailScreen';
import ItemDetailScreen from '../Containers/ItemDetail/ItemDetailScreen';
import ItemBatchDetailScreen from '../Containers/ItemBatchDetail/ItemBatchDetailScreen';
import DelOrdIndexScreen from '../Containers/DelOrdIndex/DelOrdIndexScreen';
import DelOrdDetailScreen from '../Containers/DelOrdDetail/DelOrdDetailScreen';
import GdsRcptTrackingReportScreen from '../Containers/GdsRcptTrackingReport/GdsRcptTrackingReportScreen';
import StorageBinDetailScreen from '../Containers/StorageBinDetail/StorageBinDetailScreen';
import StorageRowDetailScreen from '../Containers/StorageRowDetail/StorageRowDetailScreen';
import StorageBayDetailScreen from '../Containers/StorageBayDetail/StorageBayDetailScreen';
import StorageTypeDetailScreen from '../Containers/StorageTypeDetail/StorageTypeDetailScreen';
import DebtorProcessScreen from '../Containers/DebtorProcess/DebtorProcessScreen';
import DebtorDetailScreen from '../Containers/DebtorDetail/DebtorDetailScreen';
import CreditorProcessScreen from '../Containers/CreditorProcess/CreditorProcessScreen';
import CreditorDetailScreen from '../Containers/CreditorDetail/CreditorDetailScreen';
import ProductionProcessScreen from '../Containers/ProductionProcess/ProductionProcessScreen';
import ItemManufactureProcessScreen from '../Containers/ItemManufactureProcess/ItemManufactureProcessScreen';
import UomIndexScreen from '../Containers/Uom/UomIndexScreen';
import UomDetailScreen from '../Containers/UomDetail/UomDetailScreen';
import LayerFarmProcessScreen from '../Containers/LayerFarmProcess/LayerFarmProcessScreen';
import ProdOrdDetailScreen from '../Containers/ProdOrdDetail/ProdOrdDetailScreen';
import ProdOrdIndexScreen from '../Containers/ProdOrdIndex/ProdOrdIndexScreen';
import ProdIssDetailScreen from '../Containers/ProdIssDetail/ProdIssDetailScreen';
import ProdIssIndexScreen from '../Containers/ProdIssIndex/ProdIssIndexScreen';
import ProdSheetDetailScreen from '../Containers/ProdSheetDetail/ProdSheetDetailScreen';
import ProdSheetIndexScreen from '../Containers/ProdSheetIndex/ProdSheetIndexScreen';
import ProdRcptDetailScreen from '../Containers/ProdRcptDetail/ProdRcptDetailScreen';
import WhseJob06DetailScreen from '../Containers/WhseJob06Detail/WhseJob06DetailScreen';
import SiteProcessScreen from '../Containers/SiteProcess/SiteProcessScreen';
import SiteDetailScreen from '../Containers/SiteDetail/SiteDetailScreen';
import DocTypeDocNoIndexScreen from '../Containers/DocTypeDocNoIndex/DocTypeDocNoIndexScreen';
import SiteFlowDetailScreen from '../Containers/SiteFlowDetail/SiteFlowDetailScreen';
import DivisionProcessScreen from '../Containers/DivisionProcess/DivisionProcessScreen';
import DivisionDetailScreen from '../Containers/DivisionDetail/DivisionDetailScreen';
import CompanyProcessScreen from '../Containers/CompanyProcess/CompanyProcessScreen';
import CompanyDetailScreen from '../Containers/CompanyDetail/CompanyDetailScreen';
import AreaProcessScreen from '../Containers/AreaProcess/AreaProcessScreen';
import AreaDetailScreen from '../Containers/AreaDetail/AreaDetailScreen';
import CountAdjReasonProcessScreen from '../Containers/CountAdjReasonProcess/CountAdjReasonProcessScreen';
import CountAdjReasonDetailScreen from '../Containers/CountAdjReasonDetail/CountAdjReasonDetailScreen';
import PickListDetailBoardScreen from '../Containers/PickListDetailBoard/PickListDetailBoardScreen';
import LoadListDetailBoardScreen from '../Containers/LoadListDetailBoard/LoadListDetailBoardScreen';
import LspSlsOrdDetailBoardScreen from '../Containers/LspSlsOrdDetailBoard/LspSlsOrdDetailBoardScreen';
import SlsOrdDetailBoardScreen from '../Containers/SlsOrdDetailBoard/SlsOrdDetailBoardScreen';
import GdsRcptDetailBoardScreen from '../Containers/GdsRcptDetailBoard/GdsRcptDetailBoardScreen';
import GdsRcptRtnDetailBoardScreen from '../Containers/GdsRcptRtnDetailBoard/GdsRcptRtnDetailBoardScreen';
import PutAwayDetailBoardScreen from '../Containers/PutAwayDetailBoard/PutAwayDetailBoardScreen';
import PutAwayRtnDetailBoardScreen from '../Containers/PutAwayRtnDetailBoard/PutAwayRtnDetailBoardScreen';
import SummaryBoardScreen from '../Containers/SummaryBoard/SummaryBoardScreen';
import SiteTrfProcessScreen from '../Containers/SiteTrfProcess/SiteTrfProcessScreen';
import TrfOrdDetailScreen from '../Containers/TrfOrdDetail/TrfOrdDetailScreen';
import StkIssDetailScreen from '../Containers/StkIssDetail/StkIssDetailScreen';
import TrfOrdIndexScreen from '../Containers/TrfOrdIndex/TrfOrdIndexScreen';
import StkIssIndexScreen from '../Containers/StkIssIndex/StkIssIndexScreen';
import StkRcptIndexScreen from '../Containers/StkRcptIndex/StkRcptIndexScreen';
import StkRcptDetailScreen from '../Containers/StkRcptDetail/StkRcptDetailScreen';
import TrfToVanProcessScreen from '../Containers/TrfToVanProcess/TrfToVanProcessScreen';
import StkTrfIndexScreen from '../Containers/StkTrfIndex/StkTrfIndexScreen';
import StkTrfDetailScreen from '../Containers/StkTrfDetail/StkTrfDetailScreen';
import TrfFromVanProcessScreen from '../Containers/TrfFromVanProcess/TrfFromVanProcessScreen';
import TrfToStoreProcessScreen from '../Containers/TrfToStoreProcess/TrfToStoreProcessScreen';
import TrfFromStoreProcessScreen from '../Containers/TrfFromStoreProcess/TrfFromStoreProcessScreen';
import GeneralManagementScreen from '../Containers/GeneralManagement/GeneralManagementScreen';
import ProjectDetailScreen from '../Containers/ProjectDetail/ProjectDetailScreen';
import OutbOrdReservedStockReportScreen from '../Containers/OutbOrdReservedStockReport/OutbOrdReservedStockReportScreen';
import PurRtnIndexScreen from '../Containers/PurRtnIndex/PurRtnIndexScreen';
import GdsIssIndexScreen from '../Containers/GdsIssIndex/GdsIssIndexScreen';
import GdsIssDetailScreen from '../Containers/GdsIssDetail/GdsIssDetailScreen';
import WhseJob03IndexScreen from '../Containers/WhseJob03Index/WhseJob03IndexScreen';
import WhseJob06IndexScreen from '../Containers/WhseJob06Index/WhseJob06IndexScreen';
import WhseJob1401IndexScreen from '../Containers/WhseJob1401Index/WhseJob1401IndexScreen';
import WhseJob1402IndexScreen from '../Containers/WhseJob1402Index/WhseJob1402IndexScreen';
import WhseJob1501IndexScreen from '../Containers/WhseJob1501Index/WhseJob1501IndexScreen';
import WhseJob1502IndexScreen from '../Containers/WhseJob1502Index/WhseJob1502IndexScreen';
import WhseJob16IndexScreen from '../Containers/WhseJob16Index/WhseJob16IndexScreen';
import WhseJob17IndexScreen from '../Containers/WhseJob17Index/WhseJob17IndexScreen';
import WorkerPerformanceChartScreen from '../Containers/WorkerPerformanceChart/WorkerPerformanceChartScreen';
import WorkerGdsRcptAnalysisReport from '../Containers/WorkerGdsRcptAnalysisReport/WorkerGdsRcptAnalysisReportScreen';
import WorkerPutAwayAnalysisReport from '../Containers/WorkerPutAwayAnalysisReport/WorkerPutAwayAnalysisReportScreen';
import WorkerLoadListAnalysisReport from '../Containers/WorkerLoadListAnalysisReport/WorkerLoadListAnalysisReportScreen';
import WorkerPickListAnalysisReport from '../Containers/WorkerPickListAnalysisReport/WorkerPickListAnalysisReportScreen';
import StockReorderPlanningV2Report from '../Containers/StockReorderPlanningV2Report/StockReorderPlanningV2ReportScreen';
import SlsOrdFulfillmentChartScreen from '../Containers/SlsOrdFulfillmentChart/SlsOrdFulfillmentChartScreen';
import WhsePerformanceChartScreen from '../Containers/WhsePerformanceChart/WhsePerformanceChartScreen';
import LspSlsOrdFulfillmentChartScreen from '../Containers/LspSlsOrdFulfillmentChart/LspSlsOrdFulfillmentChartScreen';
import AppSettingScreen from '../Containers/AppSetting/AppSettingScreen';
import ShippingDocSettingScreen from '../Containers/ShippingDocSetting/ShippingDocSettingScreen';
import GdsRcptSettingScreen from '../Containers/GdsRcptSetting/GdsRcptSettingScreen';

export default function AppNavigator(props) {
  const { appPath } = props;

  return (
    <Switch>
      <Route path={`${appPath}/login`} component={LoginScreen} exact />
      <Route path={`${appPath}/dashboard`} component={DashboardScreen} exact />

      <Route
        path={`${appPath}/salesProcess/index/:proc_type`}
        component={SalesProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/gdsDelProcess/index/:proc_type`}
        component={GdsDelProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/rtnDelProcess/index/:proc_type`}
        component={RtnDelProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/gdsRcptProcess/index/:proc_type`}
        component={GdsRcptProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/pickListDetail/:action/:id`}
        component={PickListDetailScreen}
        exact
      />

      <Route path={`${appPath}/pickListIndex`} component={PickListIndexScreen} exact />

      <Route
        path={`${appPath}/packListDetail/:action/:id`}
        component={PackListDetailScreen}
        exact
      />

      <Route path={`${appPath}/packListIndex`} component={PackListIndexScreen} exact />

      <Route
        path={`${appPath}/workerPackListAnalysisReport`}
        component={WorkerPackListAnalysisReportScreen}
        exact
      />

      <Route path={`${appPath}/advShipDetail/:action/:id`} component={AdvShipDetailScreen} exact />

      <Route path={`${appPath}/purRtnDetail/:action/:id`} component={PurRtnDetailScreen} exact />

      <Route
        path={`${appPath}/outbOrdDetail/:action/:id/:item_id?`}
        component={OutbOrdDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/inbOrdDetail/:action/:id/:item_id?`}
        component={InbOrdDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/gdsRcptDetail/:action/:id/:item_id?`}
        component={GdsRcptDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/rtnRcptProcess/index/:proc_type`}
        component={RtnRcptProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/invAuditProcess/index/:proc_type`}
        component={InvAuditProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/cycleCountDetail/:action/:id/:item_id?`}
        component={CycleCountDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/cycleCount02Detail/:action/:id/:item_id?`}
        component={CycleCount02DetailScreen}
        exact
      />

      <Route path={`${appPath}/stockBalanceReport`} component={StockBalanceReportScreen} exact />

      <Route
        path={`${appPath}/stockReorderPlanningReport`}
        component={StockReorderPlanningReportScreen}
        exact
      />

      <Route
        path={`${appPath}/dailyStockMovementReport`}
        component={DailyStockMovementReportScreen}
        exact
      />

      <Route path={`${appPath}/warehouseMap`} component={WarehouseMapScreen} exact />

      <Route path={`${appPath}/itemProcess/index/:proc_type`} component={ItemProcessScreen} exact />

      <Route
        path={`${appPath}/storageBinProcess/index/:proc_type`}
        component={StorageBinProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/palletLabelProcess/index/:proc_type`}
        component={PalletLabelProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/pickFaceStrategyProcess/index/:proc_type`}
        component={PickFaceStrategyProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/pickingCriteriaProcess/index/:proc_type`}
        component={PickingCriteriaProcessScreen}
        exact
      />

      <Route path={`${appPath}/userProcess/index/:proc_type`} component={UserProcessScreen} exact />

      <Route path={`${appPath}/roleProcess/index/:proc_type`} component={RoleProcessScreen} exact />

      <Route
        path={`${appPath}/slsRtnProcess/index/:proc_type`}
        component={SlsRtnProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/outbOrdAnalysisReport`}
        component={OutbOrdAnalysisReportScreen}
        exact
      />

      <Route
        path={`${appPath}/outboundExpiryReport`}
        component={OutboundExpiryReportScreen}
        exact
      />

      <Route
        path={`${appPath}/outOfStockAnalysisReport`}
        component={OutOfStockAnalysisReportScreen}
        exact
      />

      <Route
        path={`${appPath}/purchaseProcess/index/:proc_type`}
        component={PurchaseProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/purchaseReturnProcess/index/:proc_type`}
        component={PurchaseReturnProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/whseJob03Detail/:action/:id`}
        component={WhseJob03DetailScreen}
        exact
      />

      <Route
        path={`${appPath}/whseJob14Detail/:action/:id`}
        component={WhseJob14DetailScreen}
        exact
      />

      <Route path={`${appPath}/putAwayIndex`} component={PutAwayIndexScreen} exact />

      <Route path={`${appPath}/putAwayRtnIndex`} component={PutAwayRtnIndexScreen} exact />

      <Route
        path={`${appPath}/putAwayDetail/:action/:id/:item_id?`}
        component={PutAwayDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/whseJob15Detail/:action/:id`}
        component={WhseJob15DetailScreen}
        exact
      />

      <Route path={`${appPath}/advShipIndex`} component={AdvShipIndexScreen} exact />

      <Route path={`${appPath}/slsOrdIndex`} component={SlsOrdIndexScreen} exact />

      <Route path={`${appPath}/slsOrdDetail/:action/:id`} component={SlsOrdDetailScreen} exact />

      <Route path={`${appPath}/slsInvIndex`} component={SlsInvIndexScreen} exact />

      <Route path={`${appPath}/slsInvDetail/:action/:id`} component={SlsInvDetailScreen} exact />

      <Route path={`${appPath}/slsRtnIndex`} component={SlsRtnIndexScreen} exact />

      <Route path={`${appPath}/slsRtnDetail/:action/:id`} component={SlsRtnDetailScreen} exact />

      <Route path={`${appPath}/rtnRcptIndex`} component={RtnRcptIndexScreen} exact />

      <Route path={`${appPath}/rtnRcptDetail/:action/:id`} component={RtnRcptDetailScreen} exact />

      <Route path={`${appPath}/outbOrdIndex`} component={OutbOrdIndexScreen} exact />

      <Route path={`${appPath}/inbOrdIndex`} component={InbOrdIndexScreen} exact />

      <Route path={`${appPath}/gdsRcptIndex`} component={GdsRcptIndexScreen} exact />

      <Route path={`${appPath}/gdsRcptRtnIndex`} component={GdsRcptRtnIndexScreen} exact />

      <Route path={`${appPath}/cycleCountIndex`} component={CycleCountIndexScreen} exact />

      <Route path={`${appPath}/countAdjIndex`} component={CountAdjIndexScreen} exact />

      <Route
        path={`${appPath}/countAdjDetail/:action/:id`}
        component={CountAdjDetailScreen}
        exact
      />

      <Route path={`${appPath}/binTrfIndex`} component={BinTrfIndexScreen} exact />

      <Route path={`${appPath}/binTrfDetail/:action/:id`} component={BinTrfDetailScreen} exact />

      <Route
        path={`${appPath}/binTrfProcess/index/:proc_type`}
        component={BinTrfProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/whseJob17Detail/:action/:id`}
        component={WhseJob17DetailScreen}
        exact
      />

      <Route path={`${appPath}/stockCardReport`} component={StockCardReportScreen} exact />

      <Route path={`${appPath}/stockAgingReport`} component={StockAgingReportScreen} exact />

      <Route
        path={`${appPath}/pickListReservedStockReport`}
        component={PickListReservedStockReportScreen}
        exact
      />

      <Route
        path={`${appPath}/pickingAllocErrorReport`}
        component={PickingAllocErrorReportScreen}
        exact
      />

      <Route
        path={`${appPath}/cycleCountAnalysisReport`}
        component={CycleCountAnalysisReportScreen}
        exact
      />

      <Route
        path={`${appPath}/countAdjAnalysisReport`}
        component={CountAdjAnalysisReportScreen}
        exact
      />

      <Route
        path={`${appPath}/deliveryPointProcess/index/:proc_type`}
        component={DeliveryPointProcessScreen}
        exact
      />

      <Route path={`${appPath}/changePassword`} component={ChangePasswordScreen} exact />

      <Route
        path={`${appPath}/auditResource/:res_type/:res_id/:audit_type`}
        component={AuditRes01Screen}
        exact
      />

      <Route path={`${appPath}/auditUser/:user_id`} component={AuditUser01Screen} exact />

      <Route
        path={`${appPath}/whseJob16Detail/:action/:id/:item_id?`}
        component={WhseJob16DetailScreen}
        exact
      />

      <Route
        path={`${appPath}/lspDelOrdDetail/:action/:id`}
        component={LspDelOrdDetailScreen}
        exact
      />

      <Route path={`${appPath}/lspDelOrdIndex`} component={LspDelOrdIndexScreen} exact />

      <Route
        path={`${appPath}/lspDeliveryProcess/index/:proc_type`}
        component={LspDeliveryProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/lspReceivingProcess/index/:proc_type`}
        component={LspReceivingProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/lspCustomerReturnProcess/index/:proc_type`}
        component={LspCustomerReturnProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/lspSupplierReturnProcess/index/:proc_type`}
        component={LspSupplierReturnProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/lspSlsOrdDetail/:action/:id`}
        component={LspSlsOrdDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/lspSlsOrdTrackingReport`}
        component={LspSlsOrdTrackingReportScreen}
        exact
      />

      <Route
        path={`${appPath}/lspAdvShipDetail/:action/:id`}
        component={LspAdvShipDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/lspSlsRtnDetail/:action/:id`}
        component={LspSlsRtnDetailScreen}
        exact
      />

      <Route path={`${appPath}/lspSlsOrdIndex`} component={LspSlsOrdIndexScreen} exact />
      <Route path={`${appPath}/lspSlsRtnIndex`} component={LspSlsRtnIndexScreen} exact />
      <Route path={`${appPath}/lspAdvShipIndex`} component={LspAdvShipIndexScreen} exact />

      <Route path={`${appPath}/loadListIndex`} component={LoadListIndexScreen} exact />

      <Route
        path={`${appPath}/loadListDetail/:action/:id`}
        component={LoadListDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/locationDetail/:action/:id`}
        component={LocationDetailScreen}
        exact
      />

      <Route path={`${appPath}/prfDelIndex`} component={PrfDelIndexScreen} exact />

      <Route path={`${appPath}/prfDelDetail/:action/:id`} component={PrfDelDetailScreen} exact />

      <Route
        path={`${appPath}/principalProcess/index/:proc_type`}
        component={PrincipalProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/principalDetail/:action/:id/:proc_type`}
        component={PrincipalDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/transportProcess/index/:proc_type`}
        component={TransportProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/transportDetail/:action/:id`}
        component={TransportDetailScreen}
        exact
      />

      <Route path={`${appPath}/userDetail/:action/:id`} component={UserDetailScreen} exact />

      <Route
        path={`${appPath}/shipmentTrackingReport`}
        component={ShipmentTrackingReportScreen}
        exact
      />

      <Route
        path={`${appPath}/transferInTrackingReport`}
        component={TransferInTrackingReportScreen}
        exact
      />

      <Route
        path={`${appPath}/transferOutTrackingReport`}
        component={TransferOutTrackingReportScreen}
        exact
      />

      <Route
        path={`${appPath}/deliveryPointDetail/:action/:id`}
        component={DeliveryPointDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/bizPartnerDetail/:action/:id/:creditor_id?`}
        component={BizPartnerDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/itemDetail/:action/:id/:proc_type`}
        component={ItemDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/itemBatchDetail/:action/:id`}
        component={ItemBatchDetailScreen}
        exact
      />

      <Route path={`${appPath}/delOrdIndex`} component={DelOrdIndexScreen} exact />

      <Route path={`${appPath}/delOrdDetail/:action/:id`} component={DelOrdDetailScreen} exact />

      <Route
        path={`${appPath}/gdsRcptTrackingReport`}
        component={GdsRcptTrackingReportScreen}
        exact
      />

      <Route
        path={`${appPath}/storageBinDetail/:action/:id`}
        component={StorageBinDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/storageRowDetail/:action/:id`}
        component={StorageRowDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/storageBayDetail/:action/:id`}
        component={StorageBayDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/storageTypeDetail/:action/:id`}
        component={StorageTypeDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/debtorProcess/index/:proc_type`}
        component={DebtorProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/debtorDetail/:action/:id/:proc_type`}
        component={DebtorDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/creditorProcess/index/:proc_type`}
        component={CreditorProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/creditorDetail/:action/:id/:proc_type`}
        component={CreditorDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/productionProcess/index/:proc_type`}
        component={ProductionProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/itemManufactureProcess/index/:proc_type`}
        component={ItemManufactureProcessScreen}
        exact
      />

      <Route path={`${appPath}/uom/index`} component={UomIndexScreen} exact />
      <Route path={`${appPath}/uom/detail/:action/:id`} component={UomDetailScreen} exact />

      <Route
        path={`${appPath}/layerFarm/index/:proc_type`}
        component={LayerFarmProcessScreen}
        exact
      />

      <Route path={`${appPath}/prodOrdDetail/:action/:id`} component={ProdOrdDetailScreen} exact />

      <Route path={`${appPath}/prodOrdIndex`} component={ProdOrdIndexScreen} exact />

      <Route path={`${appPath}/prodIssDetail/:action/:id`} component={ProdIssDetailScreen} exact />

      <Route path={`${appPath}/prodIssIndex`} component={ProdIssIndexScreen} exact />

      <Route path={`${appPath}/prodSheetIndex`} component={ProdSheetIndexScreen} exact />

      <Route
        path={`${appPath}/prodSheetDetail/:action/:id`}
        component={ProdSheetDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/prodRcptDetail/:action/:id`}
        component={ProdRcptDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/whseJob06Detail/:action/:id`}
        component={WhseJob06DetailScreen}
        exact
      />

      <Route path={`${appPath}/siteProcess/index/:proc_type`} component={SiteProcessScreen} exact />

      <Route path={`${appPath}/siteDetail/:action/:id`} component={SiteDetailScreen} exact />

      <Route
        path={`${appPath}/docTypeDocNoIndex/:doc_type`}
        component={DocTypeDocNoIndexScreen}
        exact
      />

      <Route
        path={`${appPath}/siteFlowDetail/:action/:id`}
        component={SiteFlowDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/divisionProcess/index/:proc_type`}
        component={DivisionProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/divisionDetail/:action/:id`}
        component={DivisionDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/companyProcess/index/:proc_type`}
        component={CompanyProcessScreen}
        exact
      />

      <Route path={`${appPath}/companyDetail/:action/:id`} component={CompanyDetailScreen} exact />

      <Route path={`${appPath}/areaProcess/index/:proc_type`} component={AreaProcessScreen} exact />

      <Route path={`${appPath}/areaDetail/:action/:id`} component={AreaDetailScreen} exact />

      <Route path={`${appPath}/pickListDetailBoard`} component={PickListDetailBoardScreen} exact />

      <Route path={`${appPath}/loadListDetailBoard`} component={LoadListDetailBoardScreen} exact />

      <Route
        path={`${appPath}/lspSlsOrdDetailBoard`}
        component={LspSlsOrdDetailBoardScreen}
        exact
      />

      <Route path={`${appPath}/slsOrdDetailBoard`} component={SlsOrdDetailBoardScreen} exact />

      <Route path={`${appPath}/gdsRcptDetailBoard`} component={GdsRcptDetailBoardScreen} exact />

      <Route
        path={`${appPath}/gdsRcptRtnDetailBoard`}
        component={GdsRcptRtnDetailBoardScreen}
        exact
      />

      <Route path={`${appPath}/putAwayDetailBoard`} component={PutAwayDetailBoardScreen} exact />

      <Route
        path={`${appPath}/putAwayRtnDetailBoard`}
        component={PutAwayRtnDetailBoardScreen}
        exact
      />

      <Route path={`${appPath}/summaryBoard`} component={SummaryBoardScreen} exact />

      <Route
        path={`${appPath}/siteTrfProcess/index/:proc_type`}
        component={SiteTrfProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/trfOrdDetail/:action/:id/:doc_sub_type?`}
        component={TrfOrdDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/stkIssDetail/:action/:id/:doc_sub_type?`}
        component={StkIssDetailScreen}
        exact
      />

      <Route path={`${appPath}/trfOrdIndex`} component={TrfOrdIndexScreen} exact />

      <Route path={`${appPath}/stkIssIndex`} component={StkIssIndexScreen} exact />

      <Route path={`${appPath}/stkRcptIndex`} component={StkRcptIndexScreen} exact />

      <Route
        path={`${appPath}/stkRcptDetail/:action/:id/:doc_sub_type?`}
        component={StkRcptDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/trfToVanProcess/index/:proc_type`}
        component={TrfToVanProcessScreen}
        exact
      />

      <Route path={`${appPath}/stkTrfIndex`} component={StkTrfIndexScreen} exact />

      <Route
        path={`${appPath}/stkTrfDetail/:action/:id/:doc_sub_type?`}
        component={StkTrfDetailScreen}
        exact
      />

      <Route
        path={`${appPath}/trfFromVanProcess/index/:proc_type`}
        component={TrfFromVanProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/trfToStoreProcess/index/:proc_type`}
        component={TrfToStoreProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/trfFromStoreProcess/index/:proc_type`}
        component={TrfFromStoreProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/generalManagement/index/:proc_type`}
        component={GeneralManagementScreen}
        exact
      />

      <Route path={`${appPath}/appSetting`} component={AppSettingScreen} exact />
      <Route path={`${appPath}/shippingDocPrint`} component={ShippingDocSettingScreen} exact />
      <Route path={`${appPath}/gdsRcptSetting`} component={GdsRcptSettingScreen} exact />

      <Route path={`${appPath}/projectDetail/:action/:id`} component={ProjectDetailScreen} exact />

      <Route
        path={`${appPath}/outbOrdReservedStockReport`}
        component={OutbOrdReservedStockReportScreen}
        exact
      />

      <Route path={`${appPath}/purRtnIndex`} component={PurRtnIndexScreen} exact />

      <Route path={`${appPath}/gdsIssIndex`} component={GdsIssIndexScreen} exact />

      <Route
        path={`${appPath}/gdsIssDetail/:action/:id/:item_id?`}
        component={GdsIssDetailScreen}
        exact
      />

      <Route path={`${appPath}/whseJob03Index`} component={WhseJob03IndexScreen} exact />

      <Route path={`${appPath}/whseJob06Index`} component={WhseJob06IndexScreen} exact />

      <Route path={`${appPath}/whseJob1401Index`} component={WhseJob1401IndexScreen} exact />

      <Route path={`${appPath}/whseJob1402Index`} component={WhseJob1402IndexScreen} exact />

      <Route path={`${appPath}/whseJob1501Index`} component={WhseJob1501IndexScreen} exact />

      <Route path={`${appPath}/whseJob1502Index`} component={WhseJob1502IndexScreen} exact />

      <Route path={`${appPath}/whseJob16Index`} component={WhseJob16IndexScreen} exact />

      <Route path={`${appPath}/whseJob17Index`} component={WhseJob17IndexScreen} exact />

      <Route
        path={`${appPath}/slsOrdTrackingReport`}
        component={SlsOrdTrackingReportScreen}
        exact
      />

      <Route
        path={`${appPath}/slsOrdFulfillmentChart`}
        component={SlsOrdFulfillmentChartScreen}
        exact
      />

      <Route
        path={`${appPath}/whsePerformanceChart`}
        component={WhsePerformanceChartScreen}
        exact
      />

      <Route
        path={`${appPath}/workerPerformanceChart`}
        component={WorkerPerformanceChartScreen}
        exact
      />
      <Route
        path={`${appPath}/lspSlsOrdFulfillmentChart`}
        component={LspSlsOrdFulfillmentChartScreen}
        exact
      />
      <Route
        path={`${appPath}/workerGdsRcptAnalysisReport`}
        component={WorkerGdsRcptAnalysisReport}
        exact
      />
      <Route
        path={`${appPath}/workerPutAwayAnalysisReport`}
        component={WorkerPutAwayAnalysisReport}
        exact
      />
      <Route
        path={`${appPath}/workerLoadListAnalysisReport`}
        component={WorkerLoadListAnalysisReport}
        exact
      />
      <Route
        path={`${appPath}/workerPickListAnalysisReport`}
        component={WorkerPickListAnalysisReport}
        exact
      />
      <Route
        path={`${appPath}/stockReorderPlanningReportV2`}
        component={StockReorderPlanningV2Report}
        exact
      />

      <Route
        path={`${appPath}/countAdjReasonProcess/index/:proc_type`}
        component={CountAdjReasonProcessScreen}
        exact
      />

      <Route
        path={`${appPath}/countAdjReasonDetail/:action/:id`}
        component={CountAdjReasonDetailScreen}
        exact
      />

      <Route path={`${appPath}/`} name="Home" component={HomeScreen} />
    </Switch>
  );
}

AppNavigator.propTypes = {
  appPath: PropTypes.string
};

AppNavigator.defaultProps = {
  appPath: ''
};
