import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography } from 'antd';
import { FormikButton, FormikInputNumber, FormikInput } from '../../Components/Formik';

import ItemDetailActions from '../../Stores/ItemDetail/Actions';

const { Text } = Typography;

class ItemDocNoForm extends React.PureComponent {
  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const { intl, docNo, updateDocNo, createDocNo, docNoIsLoading, resId } = this.props;

    const disabled = false;
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={docNo}
          onSubmit={(values, formikBag) => {
            if (values.id > 0) {
              updateDocNo(formikBag, values, resId);
            } else {
              createDocNo(formikBag, values, resId);
            }
          }}
          validationSchema={Yup.object().shape({
            doc_prefix: Yup.string().required(intl.formatMessage({ id: 'doc_prefix_is_required' })),
            running_no: Yup.number().moreThan(
              0,
              intl.formatMessage({ id: 'running_number_must_be_greater_than_0' })
            ),
            running_no_length: Yup.number().moreThan(
              0,
              intl.formatMessage({ id: 'length_must_be_greater_than_0' })
            )
          })}
        >
          {({
            values,
            // handleChange,
            // errors,
            // setFieldTouched,
            dirty,
            // touched,
            isSubmitting,
            // isValid,
            handleSubmit,
            handleReset
          }) => (
            <Form>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'doc_prefix' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="doc_prefix" component={FormikInput} disabled={disabled} />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'running_number' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field name="running_no" component={FormikInputNumber} disabled={disabled} />
                </Col>
                <Col span={12} />
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'length' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="running_no_length"
                    component={FormikInputNumber}
                    disabled={disabled}
                  />
                </Col>
                <Col span={12} />
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'suffix' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="doc_suffix" component={FormikInput} disabled={disabled} />
                </Col>
              </Row>

              <Row type="flex" justify="end" gutter={[0, 16]}>
                <Col span={5}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting || docNoIsLoading}
                    onClick={handleReset}
                    icon={<UndoOutlined />}
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="update"
                    component={FormikButton}
                    disabled={disabled}
                    loading={isSubmitting || docNoIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: values.id > 0 ? 'update' : 'create'
                    })}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

ItemDocNoForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  updateDocNo: PropTypes.func,
  createDocNo: PropTypes.func,

  docNo: PropTypes.shape({}),
  docNoIsLoading: PropTypes.bool,
  resId: PropTypes.number
};

ItemDocNoForm.defaultProps = {
  intl: {},
  updateDocNo() {},
  createDocNo() {},
  docNo: {},
  docNoIsLoading: false,
  resId: 0
};

const mapStateToProps = state => ({
  docNo: state.itemDetail.itemDocNoDtl,
  docNoIsLoading: state.itemDetail.modelIsLoading,
  resId: state.itemDetail.resId
});

const mapDispatchToProps = dispatch => ({
  updateDocNo: (formikBag, itemDocNoDtl, resId) =>
    dispatch(ItemDetailActions.itemDetailUpdateItemDocNo(formikBag, itemDocNoDtl, resId)),
  createDocNo: (formikBag, itemDocNoDtl, resId) =>
    dispatch(ItemDetailActions.itemDetailCreateItemDocNo(formikBag, itemDocNoDtl, resId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ItemDocNoForm));
