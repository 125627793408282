import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import PickListReservedStockReportActions, {
  PickListReservedStockReportTypes
} from '../Stores/PickListReservedStockReport/Actions';

const getAppStore = state => state.app;

export function* pickListReservedStockReportInitPickListReservedStock() {
  try {
    yield put(PickListReservedStockReportActions.pickListReservedStockReportReportLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseReport/initPickListReservedStock/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        PickListReservedStockReportActions.pickListReservedStockReportInitPickListReservedStockSuccess(
          result.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PickListReservedStockReportActions.pickListReservedStockReportReportLoading(false));
  }
}

export function* pickListReservedStockReportPickListReservedStock({ formikBag, criteria }) {
  try {
    formikBag.setSubmitting(true);
    yield put(PickListReservedStockReportActions.pickListReservedStockReportReportLoading(true));

    const objCriteria = {};
    if ('date' in criteria) {
      objCriteria.date = criteria.date;
    }
    if ('item_ids_select2' in criteria) {
      const values = criteria.item_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_01_ids_select2' in criteria) {
      const values = criteria.item_group_01_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_01_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_02_ids_select2' in criteria) {
      const values = criteria.item_group_02_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_02_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_03_ids_select2' in criteria) {
      const values = criteria.item_group_03_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_03_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_bin_ids_select2' in criteria) {
      const values = criteria.storage_bin_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_bin_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_row_ids_select2' in criteria) {
      const values = criteria.storage_row_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_row_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_bay_ids_select2' in criteria) {
      const values = criteria.storage_bay_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_bay_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    let columns = [];
    if (criteria.columns.target.length > 0) {
      columns = criteria.columns.target.map(entry => {
        return entry.column;
      });
    }

    const app = yield select(getAppStore);
    const postData = {
      sorts: [],
      criteria: objCriteria,
      columns
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseReport/pickListReservedStock/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(
        PickListReservedStockReportActions.pickListReservedStockReportPickListReservedStockSuccess(
          criteria,
          result.data.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(PickListReservedStockReportActions.pickListReservedStockReportReportLoading(false));
  }
}

export function* pickListReservedStockReportFetchItemOptions({ search }) {
  try {
    yield put(
      PickListReservedStockReportActions.pickListReservedStockReportFetchItemOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        PickListReservedStockReportActions.pickListReservedStockReportFetchItemOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      PickListReservedStockReportActions.pickListReservedStockReportFetchItemOptionLoading(false)
    );
  }
}

export function* pickListReservedStockReportFetchItemGroup01Options({ search }) {
  try {
    yield put(
      PickListReservedStockReportActions.pickListReservedStockReportFetchItemGroup01OptionLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        PickListReservedStockReportActions.pickListReservedStockReportFetchItemGroup01OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      PickListReservedStockReportActions.pickListReservedStockReportFetchItemGroup01OptionLoading(
        false
      )
    );
  }
}

export function* pickListReservedStockReportFetchItemGroup02Options({ search }) {
  try {
    yield put(
      PickListReservedStockReportActions.pickListReservedStockReportFetchItemGroup02OptionLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup02/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        PickListReservedStockReportActions.pickListReservedStockReportFetchItemGroup02OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      PickListReservedStockReportActions.pickListReservedStockReportFetchItemGroup02OptionLoading(
        false
      )
    );
  }
}

export function* pickListReservedStockReportFetchItemGroup03Options({ search }) {
  try {
    yield put(
      PickListReservedStockReportActions.pickListReservedStockReportFetchItemGroup03OptionLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup03/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        PickListReservedStockReportActions.pickListReservedStockReportFetchItemGroup03OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      PickListReservedStockReportActions.pickListReservedStockReportFetchItemGroup03OptionLoading(
        false
      )
    );
  }
}

export function* pickListReservedStockReportFetchStorageBinOptions({ search }) {
  try {
    yield put(
      PickListReservedStockReportActions.pickListReservedStockReportFetchStorageBinOptionLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        PickListReservedStockReportActions.pickListReservedStockReportFetchStorageBinOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      PickListReservedStockReportActions.pickListReservedStockReportFetchStorageBinOptionLoading(
        false
      )
    );
  }
}

export function* pickListReservedStockReportFetchStorageRowOptions({ search }) {
  try {
    yield put(
      PickListReservedStockReportActions.pickListReservedStockReportFetchStorageRowOptionLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageRow/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        PickListReservedStockReportActions.pickListReservedStockReportFetchStorageRowOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      PickListReservedStockReportActions.pickListReservedStockReportFetchStorageRowOptionLoading(
        false
      )
    );
  }
}

export function* pickListReservedStockReportFetchStorageBayOptions({ search }) {
  try {
    yield put(
      PickListReservedStockReportActions.pickListReservedStockReportFetchStorageBayOptionLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBay/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        PickListReservedStockReportActions.pickListReservedStockReportFetchStorageBayOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      PickListReservedStockReportActions.pickListReservedStockReportFetchStorageBayOptionLoading(
        false
      )
    );
  }
}

export function* pickListReservedStockReportFetchLocationOptions({ search }) {
  try {
    yield put(
      PickListReservedStockReportActions.pickListReservedStockReportFetchLocationOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `location/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        PickListReservedStockReportActions.pickListReservedStockReportFetchLocationOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      PickListReservedStockReportActions.pickListReservedStockReportFetchLocationOptionLoading(
        false
      )
    );
  }
}

export const saga = [
  takeLatest(
    PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_INIT_PICK_LIST_RESERVED_STOCK,
    pickListReservedStockReportInitPickListReservedStock
  ),
  takeLatest(
    PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_PICK_LIST_RESERVED_STOCK,
    pickListReservedStockReportPickListReservedStock
  ),
  takeLatest(
    PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_ITEM_OPTIONS,
    pickListReservedStockReportFetchItemOptions
  ),
  takeLatest(
    PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP01_OPTIONS,
    pickListReservedStockReportFetchItemGroup01Options
  ),
  takeLatest(
    PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP02_OPTIONS,
    pickListReservedStockReportFetchItemGroup02Options
  ),
  takeLatest(
    PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP03_OPTIONS,
    pickListReservedStockReportFetchItemGroup03Options
  ),
  takeLatest(
    PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_STORAGE_BIN_OPTIONS,
    pickListReservedStockReportFetchStorageBinOptions
  ),
  takeLatest(
    PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_STORAGE_ROW_OPTIONS,
    pickListReservedStockReportFetchStorageRowOptions
  ),
  takeLatest(
    PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_STORAGE_BAY_OPTIONS,
    pickListReservedStockReportFetchStorageBayOptions
  ),
  takeLatest(
    PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_LOCATION_OPTIONS,
    pickListReservedStockReportFetchLocationOptions
  )
];
