/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LspDelOrdDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const lspDelOrdDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const lspDelOrdDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  salesmanOptions: [],
  deliveryPointOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const lspDelOrdDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const lspDelOrdDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const lspDelOrdDetailUpdateDocumentSuccess = (
  state,
  { documentHeader, documentDetails }
) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const lspDelOrdDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const lspDelOrdDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const lspDelOrdDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  itemOptions: [],
  uomOptions: []
});

export const lspDelOrdDetailFetchSalesmanOptionLoading = (state, { boolean }) => ({
  ...state,
  salesmanIsLoading: boolean
});

export const lspDelOrdDetailFetchSalesmanOptionSuccess = (state, { options }) => ({
  ...state,
  salesmanOptions: options
});

export const lspDelOrdDetailFetchDeliveryPointOptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryPointIsLoading: boolean
});

export const lspDelOrdDetailFetchDeliveryPointOptionSuccess = (state, { options }) => ({
  ...state,
  deliveryPointOptions: options
});

export const lspDelOrdDetailFetchCreditTermOptionLoading = (state, { boolean }) => ({
  ...state,
  creditTermIsLoading: boolean
});

export const lspDelOrdDetailFetchCreditTermOptionSuccess = (state, { options }) => ({
  ...state,
  creditTermOptions: options
});

export const lspDelOrdDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const lspDelOrdDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

export const lspDelOrdDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const lspDelOrdDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const lspDelOrdDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const lspDelOrdDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const lspDelOrdDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const lspDelOrdDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const lspDelOrdDetailFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const lspDelOrdDetailFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const lspDelOrdDetailFetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const lspDelOrdDetailFetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

export const lspDelOrdDetailSetPickedIsVisible = (state, { boolean }) => ({
  ...state,
  pickedIsVisible: boolean,
  pickedTimestamp: boolean === true ? Date.now() : state.pickedTimestamp
});

export const lspDelOrdDetailShowPickedLoading = (state, { boolean }) => ({
  ...state,
  pickedIsLoading: boolean
});

export const lspDelOrdDetailShowPickedDetailsSuccess = (state, { pickedDetails }) => ({
  ...state,
  pickedDetails
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_RESET_TIMESTAMP]: lspDelOrdDetailResetTimestamp,
  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_SET_HDR_ID]: lspDelOrdDetailSetHdrId,

  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_SHOW_HEADER_SUCCESS]: lspDelOrdDetailShowHeaderSuccess,

  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_UPDATE_DOCUMENT_SUCCESS]: lspDelOrdDetailUpdateDocumentSuccess,

  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_SHOW_DOCUMENT_LOADING]: lspDelOrdDetailShowDocumentLoading,

  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_SHOW_DETAILS_SUCCESS]: lspDelOrdDetailShowDetailsSuccess,

  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_SET_DETAIL_VISIBLE]: lspDelOrdDetailSetDetailVisible,
  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_SET_DOCUMENT_DETAIL]: lspDelOrdDetailSetDocumentDetail,

  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_UPDATE_DETAILS]: lspDelOrdDetailUpdateDetails,
  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_CREATE_DETAIL]: lspDelOrdDetailCreateDetail,

  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_FETCH_SALESMAN_OPTION_LOADING]: lspDelOrdDetailFetchSalesmanOptionLoading,
  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_FETCH_SALESMAN_OPTION_SUCCESS]: lspDelOrdDetailFetchSalesmanOptionSuccess,

  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_FETCH_DELIVERY_POINT_OPTION_LOADING]: lspDelOrdDetailFetchDeliveryPointOptionLoading,
  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_FETCH_DELIVERY_POINT_OPTION_SUCCESS]: lspDelOrdDetailFetchDeliveryPointOptionSuccess,

  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_FETCH_CREDIT_TERM_OPTION_LOADING]: lspDelOrdDetailFetchCreditTermOptionLoading,
  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_FETCH_CREDIT_TERM_OPTION_SUCCESS]: lspDelOrdDetailFetchCreditTermOptionSuccess,

  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: lspDelOrdDetailFetchCurrencyOptionLoading,
  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: lspDelOrdDetailFetchCurrencyOptionSuccess,

  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_FETCH_ITEM_OPTION_LOADING]: lspDelOrdDetailFetchItemOptionLoading,
  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: lspDelOrdDetailFetchItemOptionSuccess,

  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_FETCH_UOM_OPTION_LOADING]: lspDelOrdDetailFetchUomOptionLoading,
  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_FETCH_UOM_OPTION_SUCCESS]: lspDelOrdDetailFetchUomOptionSuccess,

  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_FETCH_LOCATION_OPTION_LOADING]: lspDelOrdDetailFetchLocationOptionLoading,
  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_FETCH_LOCATION_OPTION_SUCCESS]: lspDelOrdDetailFetchLocationOptionSuccess,

  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_FETCH_PROJECT_OPTION_LOADING]: lspDelOrdDetailFetchProjectOptionLoading,
  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_FETCH_PROJECT_OPTION_SUCCESS]: lspDelOrdDetailFetchProjectOptionSuccess,

  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_SET_PICKED_IS_VISIBLE]: lspDelOrdDetailSetPickedIsVisible,
  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_SHOW_PICKED_LOADING]: lspDelOrdDetailShowPickedLoading,

  [LspDelOrdDetailTypes.LSP_DEL_ORD_DETAIL_SHOW_PICKED_DETAILS_SUCCESS]: lspDelOrdDetailShowPickedDetailsSuccess
});
